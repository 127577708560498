import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  DialogProps,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";

type PrepItemDialogProps = DialogProps & {
  onConfirm: () => void;
};

export function PrepItemDialog({
  onConfirm,
  ...dialogProps
}: PrepItemDialogProps) {
  const [itemMovedToCorrectBay, setItemMovedToCorrectBay] = useState(false);
  const [itemAssembled, setItemAssembled] = useState(false);
  const [itemInspected, setItemInspected] = useState(false);
  const [itemTagged, setItemTagged] = useState(false);

  useEffect(() => {
    setItemMovedToCorrectBay(false);
    setItemAssembled(false);
    setItemInspected(false);
    setItemTagged(false);
  }, [dialogProps.isOpen]);

  return (
    <Dialog title="Prep Inventory Item" {...dialogProps}>
      <div className={Classes.DIALOG_BODY}>
        <p>
          To record this inventory item as prepped, please verify the following
          tasks have been completed...
        </p>

        <div className="space-y-2 rounded bg-gray-50 p-2 border">
          <Checkbox
            label="Item has been moved to the correct bay"
            checked={itemMovedToCorrectBay}
            onChange={(e: any) => setItemMovedToCorrectBay(e.target.checked)}
          />

          <Checkbox
            label="Item has been assembled (if applicable)"
            checked={itemAssembled}
            onChange={(e: any) => setItemAssembled(e.target.checked)}
          />

          <Checkbox
            label="Item has been inspected for any issues"
            checked={itemInspected}
            onChange={(e: any) => setItemInspected(e.target.checked)}
          />

          <Checkbox
            label="Item has been tagged for prep"
            checked={itemTagged}
            onChange={(e: any) => setItemTagged(e.target.checked)}
          />
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={dialogProps.onClose} />
          <Button
            text="Save"
            intent="primary"
            disabled={
              !itemMovedToCorrectBay ||
              !itemAssembled ||
              !itemInspected ||
              !itemTagged
            }
            onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  );
}
