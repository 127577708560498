import {
  Button,
  Checkbox,
  FormGroup,
  HTMLSelect,
  Intent,
  Menu,
  MenuItem,
  NumericInput,
  Popover,
  Position,
} from "@blueprintjs/core";
import {
  UpdateVendorAddressPayload,
  VendorAddressType,
} from "../../../types/ApiTypes";
import { formatAddress } from "../../../helpers/AddressHelper";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";

type UpdateAddressSiteInformationPayload = {
  addressId: number;
  hasDock?: boolean;
  numberOfInsideStairs?: number;
  numberOfOutsideStairs?: number;
  hasFreightElevator?: boolean;
  hasPassengerElevator?: boolean;
  isCertificateOfInsuranceRequired?: boolean;
};

const UpdateAddressSiteInformation = gql`
  mutation UpdateAddressSiteInformation(
    $payload: UpdateAddressSiteInformationPayloadInput!
  ) {
    updateAddressSiteInformation(payload: $payload) {
      id
    }
  }
`;

export type VendorAddressItemProps = {
  vendorAddress: {
    id: number;
    type: VendorAddressType;
    vendorId: number;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
      addressSiteInformation?: {
        id: number;
        hasDock: boolean;
        numberOfInsideStairs: number;
        numberOfOutsideStairs: number;
        hasFreightElevator: boolean;
        hasPassengerElevator: boolean;
        isCertificateOfInsuranceRequired: boolean;
      };
    };
  };
  onRemove: () => void;
  onChange: (payload: UpdateVendorAddressPayload) => void;
};

export function VendorAddressItem({
  vendorAddress,
  onChange,
  onRemove,
}: VendorAddressItemProps) {
  const [insideStairs, setInsideStairs] = useState<string>(
    vendorAddress.address.addressSiteInformation?.numberOfInsideStairs.toString() ??
      "0"
  );
  const [outsideStairs, setOutsideStairs] = useState<string>(
    vendorAddress.address.addressSiteInformation?.numberOfOutsideStairs.toString() ??
      "0"
  );
  const [hasLoadingDock, setHasLoadingDock] = useState<boolean>(
    vendorAddress.address.addressSiteInformation?.hasDock ?? false
  );
  const [hasPassengerElevator, setHasPassengerElevator] = useState<boolean>(
    vendorAddress.address.addressSiteInformation?.hasPassengerElevator ?? false
  );
  const [hasFreightElevator, setHasFreightElevator] = useState<boolean>(
    vendorAddress.address.addressSiteInformation?.hasFreightElevator ?? false
  );
  const [certificateOfInsuranceRequired, setCertificateOfInsuranceRequired] =
    useState<boolean>(
      vendorAddress.address.addressSiteInformation
        ?.isCertificateOfInsuranceRequired ?? false
    );

  const [updateAddressSiteInformation] = useMutation<
    { updateAddressSiteMutation: { id: number } },
    { payload: UpdateAddressSiteInformationPayload }
  >(UpdateAddressSiteInformation);

  return (
    <div className={"divide-y border-b"}>
      <div className="p-2 flex">
        <div className="flex-1">
          <address
            className="not-italic"
            dangerouslySetInnerHTML={{
              __html: formatAddress(vendorAddress.address).replaceAll(
                "\n",
                "<br />"
              ),
            }}
          />
        </div>
        <HTMLSelect
          value={vendorAddress.type.toString()}
          onChange={(e) =>
            onChange({
              id: vendorAddress.id,
              type: e.target.value as VendorAddressType,
            })
          }
          className="mr-2"
        >
          <option value="">Choose Type</option>
          <option value="Primary">Primary</option>
          <option value="Billing">Billing</option>
          <option value="Fulfillment">Fulfillment</option>
          <option value="Other">Other</option>
        </HTMLSelect>
        <Popover position={Position.BOTTOM_RIGHT} minimal>
          <Button minimal icon="more" />
          <Menu>
            <MenuItem
              text="Remove from Vendor"
              icon="minus"
              intent={Intent.DANGER}
              onClick={() => onRemove()}
            />
          </Menu>
        </Popover>
      </div>
      {vendorAddress.type === VendorAddressType.Fulfillment && (
        <div className="p-2 bg-gray-50 space-y-2">
          <h4 className="font-medium uppercase">Site Information</h4>
          <div className="flex space-x-2">
            <FormGroup label="# Inside Stairs" className="flex-1">
              <NumericInput
                value={insideStairs}
                onValueChange={async (e, v) => {
                  setInsideStairs(v);

                  await updateAddressSiteInformation({
                    variables: {
                      payload: {
                        addressId: vendorAddress.address.id,
                        numberOfInsideStairs: parseInt(v),
                      },
                    },
                  });
                }}
                stepSize={1}
                minorStepSize={1}
                majorStepSize={1}
                min={0}
                fill
              />
            </FormGroup>
            <FormGroup label="# Outside Stairs" className="flex-1">
              <NumericInput
                value={outsideStairs}
                onValueChange={async (e, v) => {
                  setOutsideStairs(v);

                  await updateAddressSiteInformation({
                    variables: {
                      payload: {
                        addressId: vendorAddress.address.id,
                        numberOfOutsideStairs: parseInt(v),
                      },
                    },
                  });
                }}
                stepSize={1}
                minorStepSize={1}
                majorStepSize={1}
                min={0}
                fill
              />
            </FormGroup>
          </div>
          <Checkbox
            label="Has Loading Dock"
            checked={hasLoadingDock}
            onChange={async (e) => {
              const checked = (e.target as any).checked;
              setHasLoadingDock(checked);

              await updateAddressSiteInformation({
                variables: {
                  payload: {
                    addressId: vendorAddress.address.id,
                    hasDock: checked,
                  },
                },
              });
            }}
          />
          <Checkbox
            label="Has Freight Elevator"
            checked={hasFreightElevator}
            onChange={async (e) => {
              const checked = (e.target as any).checked;
              setHasFreightElevator(checked);

              await updateAddressSiteInformation({
                variables: {
                  payload: {
                    addressId: vendorAddress.address.id,
                    hasFreightElevator: checked,
                  },
                },
              });
            }}
          />
          <Checkbox
            label="Has Passenger Elevator"
            checked={hasPassengerElevator}
            onChange={async (e) => {
              const checked = (e.target as any).checked;
              setHasPassengerElevator(checked);

              await updateAddressSiteInformation({
                variables: {
                  payload: {
                    addressId: vendorAddress.address.id,
                    hasPassengerElevator: checked,
                  },
                },
              });
            }}
          />
          <Checkbox
            label="Certificate of Insurance Required"
            checked={certificateOfInsuranceRequired}
            onChange={async (e) => {
              const checked = (e.target as any).checked;
              setCertificateOfInsuranceRequired(checked);

              await updateAddressSiteInformation({
                variables: {
                  payload: {
                    addressId: vendorAddress.address.id,
                    isCertificateOfInsuranceRequired: checked,
                  },
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
}
