import { InputGroup, Tree } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useProductTreeData } from "./useProductTreeData";
import { CatalogManufacturer, CatalogProduct } from "../../../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";

type ProductTreeProps = {
  manufacturerId: number;
  onChoose: (product: CatalogProduct) => void;
};

export function ProductTree({ manufacturerId, onChoose }: ProductTreeProps) {
  const { contents, selectedNode, onExpand, onCollapse, onSelect } =
    useProductTreeData(manufacturerId);

  useEffect(() => {
    if (!selectedNode) return;
    onChoose(selectedNode.nodeData! as CatalogProduct);
  }, [selectedNode]);

  return (
    <Tree
      contents={contents}
      onNodeExpand={onExpand}
      onNodeCollapse={onCollapse}
      onNodeClick={onSelect}
    />
  );
}
