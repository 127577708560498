import React, { useState } from "react";
import classNames from "classnames";
import { Icon } from "@blueprintjs/core";

type InternalExternalNotesEditorProps = {
  readOnly?: boolean;
  internalNotesPlaceholder?: string;
  externalNotesPlaceholder?: string;
  internalNotesValue: string;
  externalNotesValue: string;
  onInternalNotesChange: (value: string) => void;
  onExternalNotesChange: (value: string) => void;
};

export function InternalExternalNotesEditor({
  readOnly = false,
  internalNotesPlaceholder,
  externalNotesPlaceholder,
  internalNotesValue,
  externalNotesValue,
  onInternalNotesChange,
  onExternalNotesChange,
}: InternalExternalNotesEditorProps) {
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  return (
    <div className="rounded border bg-white overflow-hidden divide-y">
      <div className="flex items-center bg-gray-50 p-1 space-x-1">
        <button
          className={classNames(
            "rounded-sm p-1 px-2 text-indigo-600 text-xs font-medium transition-colors",
            {
              "bg-indigo-100": !isEditingInternal,
              "hover:bg-indigo-100": isEditingInternal,
            }
          )}
          onClick={() => setIsEditingInternal(false)}
        >
          <span>External</span>

          {externalNotesValue?.length > 0 && (
            <Icon icon="dot" className="text-indigo-300" />
          )}
        </button>

        <button
          className={classNames(
            "rounded-sm p-1 px-2 text-indigo-600 text-xs font-medium transition-colors",
            {
              "bg-indigo-100": isEditingInternal,
              "hover:bg-indigo-100": !isEditingInternal,
            }
          )}
          onClick={() => setIsEditingInternal(true)}
        >
          <span>Internal</span>

          {internalNotesValue?.length > 0 && (
            <Icon icon="dot" className="text-indigo-300" />
          )}
        </button>

        <span className="flex-1" />

        <div className="pr-1 text-gray-300">
          <Icon icon="comment" />
        </div>
      </div>

      <div className="p-1">
        {isEditingInternal && (
          <textarea
            placeholder={
              !readOnly
                ? internalNotesPlaceholder ?? "Type internal notes here..."
                : ""
            }
            value={internalNotesValue}
            onChange={(e) => onInternalNotesChange(e.target.value)}
            className={classNames(
              "w-full h-full rounded transition-colors p-1 text-sm text-red-600",
              {
                "hover:bg-gray-50 focus:bg-gray-50": !readOnly,
              }
            )}
            disabled={readOnly}
            rows={4}
          />
        )}

        {!isEditingInternal && (
          <textarea
            placeholder={
              !readOnly
                ? externalNotesPlaceholder ?? "Type external notes here..."
                : ""
            }
            value={externalNotesValue}
            onChange={(e) => onExternalNotesChange(e.target.value)}
            className={classNames(
              "w-full h-full rounded transition-colors p-1 text-sm",
              {
                "hover:bg-gray-50 focus:bg-gray-50": !readOnly,
              }
            )}
            disabled={readOnly}
            rows={4}
          />
        )}
      </div>

      {isEditingInternal && (
        <div className="flex items-center space-x-2 bg-red-50 text-red-600 p-1">
          <Icon icon="eye-off" />
          <p className="text-xs m-0">
            Internal notes are only visible to employees.
          </p>
        </div>
      )}

      {!isEditingInternal && (
        <div className="flex items-center space-x-2 bg-gray-100 text-gray-600 p-1">
          <Icon icon="eye-open" />
          <p className="text-xs m-0">
            External notes are visible to the customer.
          </p>
        </div>
      )}
    </div>
  );
}
