import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { PersonAddressItem } from "./PersonAddressItem";
import { ChooseAddressDialog } from "../../shared/AddressInputGroup/ChooseAddressDialog";
import { useState } from "react";
import {
  Address,
  PersonAddressType,
  UpdatePersonAddressPayload,
} from "../../../types/ApiTypes";

type GetPersonAddressesResult = {
  people: {
    id: number;
    personAddresses: {
      id: number;
      type: PersonAddressType;
      personId: number;
      address: {
        id: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        postalCode: string;
        notes: string;
        addressSiteInformation?: {
          id: number;
          hasDock: boolean;
          numberOfInsideStairs: number;
          numberOfOutsideStairs: number;
          hasFreightElevator: boolean;
          hasPassengerElevator: boolean;
          isCertificateOfInsuranceRequired: boolean;
        };
      };
    }[];
  }[];
};

const GetPersonAddresses = gql`
  query GetPersonAddresses($id: Int!) {
    people(where: { id: { eq: $id } }) {
      id
      personAddresses {
        id
        type
        personId
        address {
          id
          street1
          street2
          city
          state
          postalCode
          notes
          addressSiteInformation {
            id
            hasDock
            numberOfInsideStairs
            numberOfOutsideStairs
            hasFreightElevator
            hasPassengerElevator
            isCertificateOfInsuranceRequired
          }
        }
      }
    }
  }
`;

const AddAddressToPerson = gql`
  mutation AddAddressToPerson(
    $addressId: Int!
    $personId: Int!
    $type: PersonAddressType!
  ) {
    addAddressToPerson(
      addressId: $addressId
      personId: $personId
      type: $type
    ) {
      id
    }
  }
`;

const RemoveAddressFromPerson = gql`
  mutation RemoveAddressFromPerson($personAddressId: Int!) {
    removeAddressFromPerson(personAddressId: $personAddressId) {
      id
    }
  }
`;

const UpdatePersonAddress = gql`
  mutation UpdatePersonAddress($payload: UpdatePersonAddressPayloadInput!) {
    updatePersonAddress(payload: $payload) {
      id
    }
  }
`;

export type PersonAddressListProps = {
  personId: number;
};

export function PersonAddressList({ personId }: PersonAddressListProps) {
  const [isChooseOpen, setIsChooseOpen] = useState(false);

  const getPersonAddresses = useQuery<GetPersonAddressesResult, { id: number }>(
    GetPersonAddresses,
    {
      variables: {
        id: personId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [addAddressToPerson] = useMutation<
    {
      addAddressToPerson: { id: number };
    },
    {
      addressId: number;
      personId: number;
      type: PersonAddressType;
    }
  >(AddAddressToPerson, {
    refetchQueries: ["GetPersonAddresses"],
  });

  const [removeAddressFromPerson] = useMutation<
    {
      removeAddressFromPerson: { id: number };
    },
    {
      personAddressId: number;
    }
  >(RemoveAddressFromPerson, {
    refetchQueries: ["GetPersonAddresses"],
  });

  const [updatePersonAddress] = useMutation<
    {
      updatePersonAddress: { id: number };
    },
    {
      payload: UpdatePersonAddressPayload;
    }
  >(UpdatePersonAddress, {
    refetchQueries: ["GetPersonAddresses"],
  });

  return (
    <>
      <ChooseAddressDialog
        isOpen={isChooseOpen}
        onClose={() => setIsChooseOpen(false)}
        onChoose={async (address: Address) => {
          await addAddressToPerson({
            variables: {
              personId,
              addressId: address.id!,
              type: PersonAddressType.Other,
            },
          });
          setIsChooseOpen(false);
        }}
      />

      <div className="flex flex-col border rounded overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          {getPersonAddresses.loading && (
            <div className="p-20 text-center">
              <Spinner size={20} />
            </div>
          )}
          {!getPersonAddresses.loading &&
            getPersonAddresses.data!.people[0].personAddresses.length === 0 && (
              <div className="p-5">
                <NonIdealState icon="map" title="No Addresses" />
              </div>
            )}
          {!getPersonAddresses.loading &&
            getPersonAddresses.data!.people[0].personAddresses.length > 0 &&
            getPersonAddresses.data!.people[0].personAddresses.map((c) => (
              <PersonAddressItem
                key={c.id}
                personAddress={c}
                onChange={async (payload: UpdatePersonAddressPayload) => {
                  await updatePersonAddress({
                    variables: { payload },
                  });
                }}
                onRemove={async () => {
                  await removeAddressFromPerson({
                    variables: {
                      personAddressId: c.id,
                    },
                  });
                }}
              />
            ))}
        </div>
        <div className="flex border-t items-center justify-between p-1 bg-gray-50">
          <Button icon="plus" onClick={() => setIsChooseOpen(true)} />
        </div>
      </div>
    </>
  );
}
