import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FileInput,
  Icon,
  InputGroup,
  Intent,
  MenuDivider,
  NonIdealState,
} from "@blueprintjs/core";
import styled from "styled-components";
import tw from "twin.macro";
import React, { FormEventHandler, useState } from "react";
import { Project, Attachment } from "../../../types/ApiTypes";
import { useProjectAttachments } from "../../../hooks/useProjectAttachments";
import { UploadAttachmentDialog } from "../../projects/[projectId]/attachments/UploadAttachmentDialog";

export type FileLocationButtonProps = {
  active: boolean;
};

const FileLocationButton = styled(Button).attrs({
  alignText: "left",
  fill: true,
  minimal: true,
})`
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const FileLocationList = tw.div`flex flex-col w-52 border bg-white rounded-l-md p-2`;
export const FileListWrapper = tw.div`flex-1 rounded-r-md border border-l-0 bg-gray-50 overflow-hidden`;
export const FileGrid = tw.div`grid grid-flow-row grid-cols-4 gap-4 p-4 overflow-y-auto max-h-96`;
export const FileItem = styled.button((p: any) => [
  tw`h-40 bg-blue-50 rounded-md p-2 flex flex-col items-center justify-center text-blue-500 hover:bg-blue-100`,
  p.active && tw`ring-2`,
]);

export type FileListProps = {
  title: string;
  files: Attachment[];
  selectedFile?: Attachment;
  onSelectedFileChange?: (newSelectedFile: Attachment) => void;
};

export function FileList({
  title,
  files,
  selectedFile,
  onSelectedFileChange,
}: FileListProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredFiles = files.filter((f) => {
    const normalizedQuery = searchQuery.trim().toLowerCase();
    if (normalizedQuery.length === 0) return true;
    const normalizedName = f.name.trim().toLowerCase();
    return normalizedName.includes(normalizedQuery);
  });

  return (
    <FileListWrapper>
      <div className="py-2 px-4 bg-white border-b flex items-center">
        <h1 className="m-0 font-medium text-base flex-1">{title}</h1>
        <InputGroup
          type="search"
          leftIcon="search"
          placeholder="Search files..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <FileGrid>
        {filteredFiles.map((file) => (
          <FileItem
            key={file.id}
            active={file === selectedFile}
            onClick={() => onSelectedFileChange && onSelectedFileChange(file)}
          >
            <Icon icon="document" iconSize={36} />
            <p className="m-0 mt-4 font-medium break-all">{file.name}</p>
          </FileItem>
        ))}
      </FileGrid>
      {filteredFiles.length === 0 && (
        <div className="flex items-center justify-center p-10 flex-1">
          <NonIdealState
            title="No Files"
            icon="document"
            description={
              searchQuery.length > 0
                ? "Your search didn't return any results"
                : "This location doesn't contain any files"
            }
          />
        </div>
      )}
    </FileListWrapper>
  );
}

export type ChooseAttachmentDialogProps = DialogProps & {
  projectId?: number;
  onChooseAttachment: (attachment: Attachment) => void;
};

export function ChooseAttachmentDialog({
  projectId,
  isOpen,
  onChooseAttachment,
  onClose,
}: ChooseAttachmentDialogProps) {
  const { projectAttachments, allAttachments, loading, createAttachment } =
    useProjectAttachments(projectId);
  const [activeTab, setActiveTab] = useState(projectId ? "project" : "all");
  const [selectedFile, setSelectedFile] = useState<Attachment>();
  const [uploadAttachmentOpen, setUploadAttachmentOpen] = useState(false);

  const uploadAttachment = async (file: File) => {
    const attachment: Attachment = {
      name: file.name,
      contentType: file.type,
      projectId,
    };

    await createAttachment(attachment, file);
  };

  return (
    <>
      <UploadAttachmentDialog
        isOpen={uploadAttachmentOpen}
        onSubmit={(f) => uploadAttachment(f)}
        onClose={() => setUploadAttachmentOpen(false)}
      />
      <Dialog
        title="Choose Attachment"
        style={{ width: "100%" }}
        className="max-w-4xl"
        isOpen={isOpen}
        isCloseButtonShown={false}
      >
        <div className={Classes.DIALOG_BODY}>
          {!loading && (
            <div className="flex">
              <FileLocationList>
                {projectId && (
                  <FileLocationButton
                    icon="star"
                    text="Project Documents"
                    active={activeTab === "project"}
                    onClick={() => setActiveTab("project")}
                  />
                )}
                <FileLocationButton
                  icon="cloud"
                  text="All Documents"
                  active={activeTab === "all"}
                  onClick={() => setActiveTab("all")}
                />
                <MenuDivider className="flex-1" />
                <FileLocationButton
                  text="Upload from PC"
                  onClick={() => setUploadAttachmentOpen(true)}
                  icon="upload"
                />
              </FileLocationList>
              {projectId && activeTab === "project" && (
                <FileList
                  title="Project Documents"
                  files={projectAttachments!}
                  selectedFile={selectedFile}
                  onSelectedFileChange={(file) => setSelectedFile(file)}
                />
              )}
              {activeTab === "all" && (
                <FileList
                  title="All Documents"
                  files={allAttachments!}
                  selectedFile={selectedFile}
                  onSelectedFileChange={(file) => setSelectedFile(file)}
                />
              )}
            </div>
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={onClose} />
            <Button
              type="button"
              intent={Intent.PRIMARY}
              text="Choose"
              disabled={!selectedFile}
              onClick={() => onChooseAttachment(selectedFile!)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}
