import { useReportDataState } from "../../../../hooks/useReportDataState";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../../../components/reports/EntityLink";
import React from "react";
import { formatAccountName } from "../../../../helpers/AccountHelper";
import { Account } from "../../../../types/ApiTypes";
import { formatDate } from "../../../../helpers/DateHelper";

type EODsTableProps = {
  eods: {
    id: number;
    title: string;
    project?: {
      id: number;
    };
    salesOrder?: {
      id: number;
      code: string;
      project: {
        id: number;
      };
    };
    mostRecentComment?: {
      id: number;
      message: string;
      createdBy: Account;
      createdAt: string;
    };
    resolvedBy?: Account;
    resolvedAt?: string;
    createdBy: Account;
  }[];
};

export function EODsTable({ eods }: EODsTableProps) {
  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const eodItems = eods.map((eod) => ({
    ...eod,
    createdByName: formatAccountName(eod.createdBy),
    statusText: eod.resolvedAt
      ? `Resolved ${formatDate(
          new Date(eod.resolvedAt)
        )} by ${formatAccountName(eod.resolvedBy)}`
      : "Open",
    mostRecentCommentText: eod.mostRecentComment
      ? `On ${formatDate(eod.mostRecentComment.createdAt)}, ${formatAccountName(
          eod.mostRecentComment.createdBy
        )}: ${eod.mostRecentComment.message}`
      : "",
  }));

  return (
    <Grid
      data={process(eodItems, dataState)}
      {...dataState}
      onDataStateChange={(e) => setDataState(e.dataState)}
      sortable
      reorderable
      resizable
    >
      <GridColumn
        columnMenu={ReportColumnMenu}
        field="id"
        title="EOD #"
        width={130}
        cell={(props) => (
          <td>
            <EntityLink
              url={`/eods/${props.dataItem.id}`}
              text={`EOD-${props.dataItem.id}`}
            />
          </td>
        )}
      />

      <GridColumn
        columnMenu={ReportColumnMenu}
        field="project.id"
        title="Project #"
        width={200}
        cell={(props) => {
          const dataItem = props.dataItem as typeof eodItems[0];

          return dataItem.project ? (
            <td>
              <EntityLink
                url={`/projects/${dataItem.project.id}/quotes`}
                text={`PR-${dataItem.project.id}`}
              />
            </td>
          ) : (
            <td>Not Specified</td>
          );
        }}
      />

      <GridColumn
        columnMenu={ReportColumnMenu}
        field="salesOrder.code"
        title="SO #"
        width={200}
        cell={(props) => {
          const dataItem = props.dataItem as typeof eodItems[0];

          return dataItem.salesOrder ? (
            <td>
              <EntityLink
                url={`/projects/${dataItem.salesOrder.project.id}/sales-orders/${dataItem.salesOrder.id}`}
                text={dataItem.salesOrder.code}
              />
            </td>
          ) : (
            <td>Not Specified</td>
          );
        }}
      />

      <GridColumn columnMenu={ReportColumnMenu} field="title" title="Title" />

      <GridColumn
        columnMenu={ReportColumnMenu}
        field="statusText"
        title="Status"
        width={300}
      />

      <GridColumn
        columnMenu={ReportColumnMenu}
        field="createdByName"
        title="Reported By"
        width={200}
      />

      <GridColumn
        columnMenu={ReportColumnMenu}
        field="mostRecentCommentText"
        title="Most Recent Comment"
      />
    </Grid>
  );
}
