import { useCallback, useState } from "react";
import { CatalogProduct } from "../../../types/ApiTypes";
import { gql, useApolloClient } from "@apollo/client";

// export type RecentCatalogProduct = {
//   id: number;
//   code: string;
//   description: string;
//   vendor: {
//     id: number;
//     name: string;
//   };
// };

type GetProductByIdResult = {
  catalogProducts: CatalogProduct[];
};

type GetProductByIdParams = {
  id: number;
};

const GetProductById = gql`
  query GetProductById($id: Int!) {
    catalogProducts(where: { id: { eq: $id } }) {
      id
      code
      description
      vendor {
        id
        name
      }
    }
  }
`;

export function useRecentProducts(maxRecentProducts: number = 10) {
  const apolloClient = useApolloClient();

  const serializedRecentProducts =
    window.localStorage.getItem("recent_products");

  const [recentProducts, setRecentProducts] = useState<CatalogProduct[]>(
    serializedRecentProducts ? JSON.parse(serializedRecentProducts) : []
  );

  const addRecentProduct = useCallback(
    async (newProductId: number) => {
      if (recentProducts.some((p) => p.id === newProductId)) return;

      const result = await apolloClient.query<
        GetProductByIdResult,
        GetProductByIdParams
      >({
        query: GetProductById,
        variables: { id: newProductId },
      });

      const newRecentProducts = [
        result.data.catalogProducts[0],
        ...recentProducts,
      ].slice(0, maxRecentProducts);

      setRecentProducts(newRecentProducts);

      window.localStorage.setItem(
        "recent_products",
        JSON.stringify(newRecentProducts)
      );
    },
    [recentProducts, setRecentProducts, apolloClient]
  );

  const clearRecentProducts = useCallback(() => {
    setRecentProducts([]);
    window.localStorage.setItem("recent_products", "[]");
  }, [setRecentProducts]);

  return {
    recentProducts,
    addRecentProduct,
    clearRecentProducts,
  };
}
