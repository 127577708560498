import { gql, useQuery } from "@apollo/client";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { Select } from "@blueprintjs/select";
import { Icon, MenuItem, Position } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";

const GetSalesOrders = gql`
  ${FormatContactNameContactFragment}

  query GetSalesOrders {
    salesOrders(order: { createdAt: DESC }) {
      id
      code
      project {
        id
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
      }
    }
  }
`;

type GetSalesOrdersResult = {
  salesOrders: {
    id: number;
    code: string;
    project: {
      id: number;
      primaryContact?: FormatContactNameContact;
    };
  }[];
};

type SalesOrderDetailItemProps = {
  eod: {
    id: number;
    salesOrder?: {
      id: number;
      code: string;
      project: {
        id: number;
      };
    };
  };
  onSelectSalesOrder: (salesOrderId: number) => void;
};

const SalesOrderSelect =
  Select.ofType<GetSalesOrdersResult["salesOrders"][0]>();

export function SalesOrderDetailItem({
  eod,
  onSelectSalesOrder,
}: SalesOrderDetailItemProps) {
  const getSalesOrders = useQuery<GetSalesOrdersResult>(GetSalesOrders, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="flex space-x-2 items-center">
      <p className="m-0 font-medium text-xs w-20">SO #</p>

      <SalesOrderSelect
        popoverProps={{
          minimal: true,
          position: Position.BOTTOM_LEFT,
          className: "flex-1",
          targetClassName: "w-full",
        }}
        items={getSalesOrders.data?.salesOrders ?? []}
        onItemSelect={(item) => onSelectSalesOrder(item.id)}
        itemPredicate={(query, item) => {
          const normalizedQuery = query.trim().toLowerCase();
          const itemCode = item.code.toLowerCase();
          return itemCode.toString().includes(normalizedQuery);
        }}
        itemRenderer={(item, props) => (
          <MenuItem
            text={item!.code}
            label={
              item!.project.primaryContact
                ? formatContactName(item!.project.primaryContact)
                : undefined
            }
            onClick={props.handleClick}
            active={props.modifiers.active}
          />
        )}
      >
        <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center hover:bg-gray-50 cursor-pointer">
          <span className="flex-1">
            {eod.salesOrder?.code ?? "Not Specified"}
          </span>
          <Icon icon="chevron-down" />
        </div>
      </SalesOrderSelect>

      {eod.salesOrder && (
        <Link
          to={`/projects/${eod.salesOrder.project.id}/sales-orders/${eod.salesOrder.id}`}
          target="_blank"
        >
          <Icon icon="circle-arrow-right" />
        </Link>
      )}
    </div>
  );
}
