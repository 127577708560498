import { Prompt, useHistory, useParams } from "react-router-dom";
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  TextArea,
} from "@blueprintjs/core";
import { useEffect } from "react";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import dayjs from "dayjs";
import { CompanyInputGroup } from "../../../components/projects/CreateProjectDialog/CompanyInputGroup";
import {
  CompanyType,
  UpdateCompanyPayloadInput,
  UpdateVendorPayloadInput,
} from "../../../types/ApiTypes";
import { CompanyContactList } from "../../../components/contacts/companies/CompanyContactList";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CompanyAddressList } from "../../../components/contacts/companies/CompanyAddressList";
import { gql, useMutation, useQuery } from "@apollo/client";
import { VendorAddressList } from "../../../components/contacts/vendors/VendorAddressList";
import { VendorContactList } from "../../../components/contacts/vendors/VendorContactList";

type GetVendorsResult = {
  vendors: {
    id: number;
    name: string;
  }[];
};

const GetVendors = gql`
  query GetVendors {
    vendors(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetVendorByIdResult = {
  vendors: {
    id: string;
    name: string;
    email: string;
    phone: string;
    type: CompanyType;
    pricingZone: number;
    listPriceMultiplier: number;
    grossProfitPercent: number;
    freightRules: string;
    notes: string;
    parentVendor?: {
      id: number;
      name: string;
    };
    updatedAt: string;
  }[];
};

const GetVendorById = gql`
  query GetVendorById($id: Int!) {
    vendors(where: { id: { eq: $id } }) {
      id
      name
      email
      phone
      type
      pricingZone
      listPriceMultiplier
      grossProfitPercent
      freightRules
      notes
      parentVendor {
        id
        name
      }
      updatedAt
    }
  }
`;

const UpdateVendor = gql`
  mutation UpdateVendor($payload: UpdateVendorPayloadInput!) {
    updateVendor(payload: $payload) {
      id
    }
  }
`;

const DeleteVendor = gql`
  mutation DeleteVendor($id: Int!) {
    deleteVendor(vendorId: $id) {
      id
    }
  }
`;

type VendorFormFields = {
  name: string;
  email: string;
  phone: string;
  phoneExtension: string;
  parentVendor?: {
    id: number;
    name: string;
  };
  type: CompanyType;
  pricingZone: string;
  listPriceMultiplier: string;
  grossProfitPercent: string;
  freightRules: string;
  notes: string;
};

export type VendorCompanyDetailsRouteParams = {
  vendorId: string;
};

export function VendorCompanyDetails() {
  const history = useHistory();
  const { vendorId } = useParams<VendorCompanyDetailsRouteParams>();

  const getVendorById = useQuery<GetVendorByIdResult, { id: number }>(
    GetVendorById,
    {
      variables: {
        id: parseInt(vendorId),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const getVendors = useQuery<GetVendorsResult>(GetVendors, {
    fetchPolicy: "cache-and-network",
  });

  const [updateVendor] = useMutation<
    { updateVendor: { id: number } },
    { payload: UpdateVendorPayloadInput }
  >(UpdateVendor, {
    refetchQueries: ["GetVendorById", "GetVendors"],
  });

  const [deleteVendor] = useMutation<
    { deleteVendor: { id: number } },
    { id: number }
  >(DeleteVendor, {
    refetchQueries: ["GetVendorById", "GetVendors"],
  });

  const formik = useFormik<VendorFormFields>({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      phoneExtension: "",
      parentVendor: undefined,
      type: CompanyType.Corporation,
      listPriceMultiplier: "1",
      freightRules: "",
      grossProfitPercent: "20",
      notes: "",
      pricingZone: "1",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      email: Yup.string().email("Email is invalid"),
      phone: Yup.string(),
      phoneExtension: Yup.string(),
      parentCompany: Yup.object().nullable(),
      type: Yup.string().required("Type is required"),
    }),
    onSubmit: async (values) => {
      await updateVendor({
        variables: {
          payload: {
            id: parseInt(vendorId),
            name: values.name,
            email: values.email,
            phone: (
              values.phone.replaceAll("_", "") +
              " " +
              values.phoneExtension.replaceAll("_", "")
            ).trim(),
            type: values.type,
            parentVendorId: values.parentVendor?.id,
            listPriceMultiplier: parseInt(values.listPriceMultiplier),
            grossProfitPercent: parseInt(values.grossProfitPercent),
            pricingZone: values.pricingZone,
            freightRules: values.freightRules,
            notes: values.notes,
          },
        },
      });

      DefaultToaster.show({
        message: "Vendor changes saved",
        intent: "success",
        icon: "tick",
      });
    },
  });

  useEffect(() => {
    if (getVendorById.loading) return;
    const vendor = getVendorById.data!.vendors[0];

    formik.resetForm({
      values: {
        name: vendor.name ?? "",
        email: vendor.email ?? "",
        phone: vendor.phone.split(" ")[0] ?? "",
        phoneExtension: vendor.phone.split(" ")[1] ?? "",
        parentVendor: vendor.parentVendor,
        type: vendor.type ?? CompanyType.Corporation,
        pricingZone: vendor.pricingZone?.toString() ?? "1",
        listPriceMultiplier: vendor.listPriceMultiplier?.toString() ?? "1",
        grossProfitPercent: vendor.grossProfitPercent?.toString() ?? "20",
        freightRules: vendor.freightRules ?? "",
        notes: vendor.notes ?? "",
      },
    });
  }, [getVendorById.loading, getVendorById.data]);

  const removeCompany = async () => {
    await deleteVendor({
      variables: {
        id: parseInt(vendorId),
      },
    });

    history.replace("/contacts/vendors");
  };

  useEffect(() => {
    if (formik.dirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [formik.dirty]);

  return (
    <>
      <Prompt
        when={formik.dirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      {!getVendorById.loading && (
        <form className="p-5 flex items-start" onSubmit={formik.handleSubmit}>
          <div className="flex flex-1 space-x-10">
            <div className="w-36 h-36 rounded-lg bg-gray-700 flex items-center justify-center">
              <p className="m-0 text-6xl text-white font-semibold">
                {formik.values.name.toUpperCase().substring(0, 1)}
              </p>
            </div>

            <div className="flex-1 max-w-lg">
              <FormGroup
                label="Vendor Name"
                helperText={formik.errors.name}
                intent={formik.errors.name ? Intent.DANGER : Intent.NONE}
              >
                <InputGroup
                  large
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </FormGroup>

              <p className="text-gray-400 mb-5">
                Last updated{" "}
                {dayjs(getVendorById.data!.vendors[0].updatedAt).format(
                  "MM/DD/YYYY"
                )}
              </p>

              <div className="mb-4">
                <FormGroup
                  label="Parent Vendor"
                  helperText={formik.errors.parentVendor}
                  intent={
                    formik.errors.parentVendor ? Intent.DANGER : Intent.NONE
                  }
                >
                  <CompanyInputGroup
                    companies={getVendors.data?.vendors ?? []}
                    loading={getVendors.loading}
                    value={formik.values.parentVendor}
                    onChange={(e) => formik.setFieldValue("parentVendor", e)}
                  />
                </FormGroup>
              </div>

              <div className="mb-4">
                <FormGroup
                  label="Vendor Type"
                  helperText={formik.errors.type}
                  intent={formik.errors.type ? Intent.DANGER : Intent.NONE}
                >
                  <HTMLSelect
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    fill
                    large
                  >
                    <option value="" disabled>
                      Select a type...
                    </option>
                    <option value={CompanyType.Corporation}>Corporation</option>
                    <option value={CompanyType.University}>University</option>
                    <option value={CompanyType.NonProfit}>Non-Profit</option>
                    <option value={CompanyType.Other}>Other</option>
                  </HTMLSelect>
                </FormGroup>
              </div>

              <div className="flex mb-4">
                <FormGroup
                  label="Email"
                  className="flex-1 pr-2"
                  helperText={formik.errors.email}
                  intent={formik.errors.email ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    large
                  />
                </FormGroup>

                <FormGroup
                  label="Phone"
                  className="flex-1 pl-2"
                  helperText={
                    formik.errors.phone ?? formik.errors.phoneExtension
                  }
                  intent={
                    formik.errors.phone || formik.errors.phoneExtension
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                >
                  <ControlGroup fill>
                    <HTMLSelect large className="w-24">
                      <option value="">+1</option>
                    </HTMLSelect>
                    <InputMask
                      id="phone"
                      name="phone"
                      mask={"999-999-9999"}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                    <InputMask
                      mask={"9999"}
                      id="phoneExtension"
                      name="phoneExtension"
                      value={formik.values.phoneExtension}
                      onChange={formik.handleChange}
                      className="w-28"
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                  </ControlGroup>
                </FormGroup>
              </div>

              <FormGroup label="Addresses">
                <VendorAddressList vendorId={parseInt(vendorId)} />
              </FormGroup>

              <FormGroup label="Contacts">
                <VendorContactList vendorId={parseInt(vendorId)} />
              </FormGroup>
            </div>

            <div className="flex-1 max-w-lg">
              <div className="space-y-2">
                <FormGroup
                  label="Pricing Zone"
                  helperText={formik.errors.pricingZone}
                  intent={
                    formik.errors.pricingZone ? Intent.DANGER : Intent.NONE
                  }
                >
                  <InputGroup
                    id="pricingZone"
                    name="pricingZone"
                    value={formik.values.pricingZone}
                    onChange={formik.handleChange}
                  />
                </FormGroup>

                <FormGroup
                  label="Discount List Price Multiplier"
                  helperText={formik.errors.listPriceMultiplier}
                  intent={
                    formik.errors.listPriceMultiplier
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                >
                  <InputGroup
                    id="listPriceMultiplier"
                    name="listPriceMultiplier"
                    value={formik.values.listPriceMultiplier}
                    onChange={formik.handleChange}
                  />
                </FormGroup>

                <FormGroup
                  label="Gross Profit %"
                  helperText={formik.errors.grossProfitPercent}
                  intent={
                    formik.errors.grossProfitPercent
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                >
                  <InputGroup
                    id="grossProfitPercent"
                    name="grossProfitPercent"
                    value={formik.values.grossProfitPercent}
                    onChange={formik.handleChange}
                  />
                </FormGroup>

                <FormGroup
                  label="Freight Rules"
                  helperText={formik.errors.freightRules}
                  intent={
                    formik.errors.freightRules ? Intent.DANGER : Intent.NONE
                  }
                >
                  <TextArea
                    id="freightRules"
                    name="freightRules"
                    value={formik.values.freightRules}
                    onChange={formik.handleChange}
                    rows={6}
                    fill
                  />
                </FormGroup>

                <FormGroup
                  label="Notes"
                  helperText={formik.errors.notes}
                  intent={formik.errors.notes ? Intent.DANGER : Intent.NONE}
                >
                  <TextArea
                    id="notes"
                    name="notes"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    rows={6}
                    fill
                  />
                </FormGroup>
              </div>
            </div>
          </div>

          <Button
            intent={Intent.PRIMARY}
            text="Save Changes"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            loading={formik.isSubmitting}
          />

          <Popover position={Position.BOTTOM_RIGHT} minimal>
            <Button icon="more" className="ml-2" minimal />
            <Menu>
              <MenuItem
                text="Delete Vendor"
                icon="trash"
                intent={Intent.DANGER}
                onClick={removeCompany}
              />
            </Menu>
          </Popover>
        </form>
      )}
    </>
  );
}
