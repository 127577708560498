import { PurchaseOrder } from "../types/ApiTypes";
import { useApiError } from "./useApiError";
import axios from "axios";
import { gql, useQuery } from "@apollo/client";
import {
  GetFullConfigurationCodeFragment,
  GetFullConfigurationDescriptionFragment,
} from "../helpers/CatalogHelper";

const GET_SHIPMENTS = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetShipments($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      purchaseOrderShipments {
        id
        trackingNumber
        expectedShipDate
        actualShipDate
        expectedArrivalDate
        actualArrivalDate
        lineItems {
          id
          quantity
          inventoryItems {
            id
          }
          purchaseOrderLineItem {
            id
            quantity
            configuration {
              id
              ...GetFullConfigurationCodeFragment
              ...GetFullConfigurationDescriptionFragment
            }
          }
        }
      }
    }
  }
`;

export function usePurchaseOrderShipments(purchaseOrderId: number) {
  const handleFetchError = useApiError("fetch shipments");
  const handleUpdateError = useApiError("update shipment");
  const handleDeleteError = useApiError("delete shipment");

  const getShipments = useQuery<
    { purchaseOrders: PurchaseOrder[] },
    { purchaseOrderId: number }
  >(GET_SHIPMENTS, {
    variables: { purchaseOrderId },
    onError: handleFetchError,
  });

  const deleteShipment = (shipmentId) => {
    axios
      .delete(`/purchaseordershipments/${shipmentId}`)
      .catch((err) => handleDeleteError(err))
      .finally(() => getShipments.refetch());
  };

  const updateShipment = (shipmentId, shipmentDto) => {
    axios
      .put(`/purchaseordershipments/${shipmentId}`, shipmentDto)
      .catch((err) => handleUpdateError(err))
      .finally(() => getShipments.refetch());
  };

  return {
    shipments:
      getShipments.data?.purchaseOrders[0].purchaseOrderShipments ?? [],
    loading: getShipments.loading,
    error: getShipments.error,
    refresh: () => getShipments.refetch(),
    updateShipment,
    deleteShipment,
  };
}
