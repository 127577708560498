import {
  Button,
  Checkbox,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  MenuItem,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Select } from "@blueprintjs/select";
import { BasicTable } from "../../components/shared/Table/BasicTable";

type GetPrepTicketsResult = {
  prepTickets: {
    id: number;
    lineItems: {
      id: number;
    }[];
    createdAt: string;
  }[];
};

const GetPrepTickets = gql`
  query GetPrepTickets {
    prepTickets(order: { createdAt: DESC }) {
      id
      lineItems {
        id
      }
      createdAt
    }
  }
`;

const PrepTicketSelect =
  Select.ofType<GetPrepTicketsResult["prepTickets"][0]>();

type AttachPrepTicketDialogProps = DialogProps & {
  onAttach: (prepTicketId: number, lineItemIds: number[]) => void;
};

export function AttachPrepTicketDialog({
  onAttach,
  ...dialogProps
}: AttachPrepTicketDialogProps) {
  const getPrepTickets = useQuery<GetPrepTicketsResult>(GetPrepTickets);
  const [selectedPrepTicket, setSelectedPrepTicket] =
    useState<GetPrepTicketsResult["prepTickets"][0]>();
  const [selectedLineItems, setSelectedLineItems] = useState<
    GetPrepTicketsResult["prepTickets"][0]["lineItems"][0][]
  >([]);

  useEffect(() => {
    setSelectedLineItems([]);
  }, [selectedPrepTicket?.id]);

  return (
    <Dialog title="Attach Prep Ticket" {...dialogProps}>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label="Prep Ticket">
          <PrepTicketSelect
            items={getPrepTickets.data?.prepTickets ?? []}
            itemRenderer={(prepTicket, { handleClick }) => (
              <MenuItem text={`PT-${prepTicket.id}`} onClick={handleClick} />
            )}
            itemPredicate={(query, prepTicket) =>
              prepTicket.id.toString().includes(query)
            }
            onItemSelect={(prepTicket) => setSelectedPrepTicket(prepTicket)}
            popoverProps={{ minimal: true, targetClassName: "w-full" }}
          >
            <Button
              text={
                selectedPrepTicket
                  ? `PT-${selectedPrepTicket.id}`
                  : "Select Prep Ticket"
              }
              rightIcon="chevron-down"
              alignText="left"
              fill
            />
          </PrepTicketSelect>
        </FormGroup>

        {selectedPrepTicket && selectedPrepTicket.lineItems.length > 0 && (
          <FormGroup label="Line Items">
            <BasicTable>
              <thead>
                <tr>
                  <th style={{ width: 25 }}>
                    <Checkbox
                      style={{ margin: 0 }}
                      checked={
                        selectedLineItems.length > 0 &&
                        selectedLineItems.length ===
                          selectedPrepTicket.lineItems.length
                      }
                      indeterminate={
                        selectedLineItems.length > 0 &&
                        selectedLineItems.length <
                          selectedPrepTicket.lineItems.length
                      }
                      onClick={() => {
                        if (selectedLineItems.length > 0) {
                          setSelectedLineItems([]);
                        } else {
                          setSelectedLineItems(selectedPrepTicket.lineItems);
                        }
                      }}
                    />
                  </th>
                  <th>SO #</th>
                  <th>PO #</th>
                  <th>Inventory #</th>
                  <th>Product Code</th>
                </tr>
              </thead>
              <tbody>
                {selectedPrepTicket.lineItems.map((lineItem) => (
                  <tr key={lineItem.id}>
                    <td>
                      <Checkbox
                        style={{ margin: 0 }}
                        checked={selectedLineItems.some(
                          (selectedLineItem) =>
                            selectedLineItem.id === lineItem.id
                        )}
                        onChange={(e) => {
                          if ((e.target as any).checked) {
                            setSelectedLineItems([
                              ...selectedLineItems,
                              lineItem,
                            ]);
                          } else {
                            setSelectedLineItems(
                              selectedLineItems.filter(
                                (selectedLineItem) =>
                                  selectedLineItem.id !== lineItem.id
                              )
                            );
                          }
                        }}
                      />
                    </td>
                    <td>{lineItem.id}</td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </FormGroup>
        )}
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={dialogProps.onClose} />
          <Button
            intent="primary"
            onClick={() =>
              onAttach(
                selectedPrepTicket!.id,
                selectedLineItems.map((li) => li.id)
              )
            }
            disabled={!selectedPrepTicket}
          >
            {selectedPrepTicket
              ? selectedLineItems.length > 0
                ? `Attach PT-${selectedPrepTicket.id} and ${selectedLineItems.length} Line Items`
                : `Attach PT-${selectedPrepTicket.id}`
              : "Attach"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
