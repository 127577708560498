import {
  Button,
  EditableText,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import classNames from "classnames";
import { LineItemEditorDefaultColumn } from "./LineItemEditor";

type QuoteLineItemGroupToolbarProps = {
  readOnly: boolean;
  name: string;
  isModified: boolean;
  isRemoved: boolean;
  isExpanded: boolean;
  sortIndex: number;
  totalGroups: number;
  onNameChange: (newName: string) => void;
  onExpand: () => void;
  onCollapse: () => void;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
  onUndoRemove: () => void;
};

export function LineItemGroupToolbar({
  readOnly,
  name,
  isModified,
  isRemoved,
  isExpanded,
  sortIndex,
  totalGroups,
  onNameChange,
  onExpand,
  onCollapse,
  onMoveUp,
  onMoveDown,
  onRemove,
  onUndoRemove,
}: QuoteLineItemGroupToolbarProps) {
  return (
    <div
      className={classNames("p-1 flex items-center space-x-1", {
        "bg-gray-50": !isModified,
        "bg-yellow-50": isModified,
      })}
    >
      <Button
        icon={isExpanded ? "chevron-down" : "chevron-right"}
        onClick={() => (isExpanded ? onCollapse() : onExpand())}
        minimal
      />

      <EditableText
        value={name}
        onChange={onNameChange}
        className="flex-1 font-medium text-sm"
        disabled={readOnly}
      />

      {!readOnly && (
        <Popover position="bottom" minimal>
          <Button icon="more" minimal />

          <Menu>
            {sortIndex > 0 && (
              <MenuItem
                text="Move Group Up"
                icon="chevron-up"
                onClick={onMoveUp}
              />
            )}

            {sortIndex < totalGroups - 1 && (
              <MenuItem
                text="Move Group Down"
                icon="chevron-down"
                onClick={onMoveDown}
              />
            )}

            {totalGroups > 0 && <MenuDivider />}

            {isRemoved ? (
              <MenuItem
                text="Undo Remove Group"
                icon="undo"
                onClick={onUndoRemove}
              />
            ) : (
              <MenuItem
                text="Remove Group"
                intent="danger"
                icon="trash"
                onClick={onRemove}
              />
            )}
          </Menu>
        </Popover>
      )}
    </div>
  );
}
