import React, { useEffect, useMemo, useState } from "react";
import {Button, Intent, Popover, Spinner, Tag} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { SalesOrderReport } from "../../types/ApiTypes";
import { encodePriceAsInteger, formatPrice } from "../../helpers/PriceHelper";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { formatAddress } from "../../helpers/AddressHelper";

const GET_SALES_ORDERS = gql`
  query GetSalesOrderReport {
    salesOrderReport {
      id
      code
      projectId
      eODs {
        id
        title
        description
        resolvedAt
        createdBy {
          id
          name
          email
        }
        projectId
      }
      quoteId
      quoteCode
      status
      primaryContactPersonName
      primaryContactPersonId
      primaryContactCompanyName
      primaryContactCompanyId
      billingContactPersonName
      billingContactPersonId
      billingContactCompanyName
      billingContactCompanyId
      fulfillmentContactPersonName
      fulfillmentContactPersonId
      fulfillmentContactCompanyName
      fulfillmentContactCompanyId
      totalQuantity
      totalAmount
      deliveryAddress
      conversionDate
      installationDate
      preparationDate
    }
  }
`;

export function PurchasingSalesOrders() {
  const getSalesOrders =
    useQuery<{ salesOrderReport: SalesOrderReport[] }>(GET_SALES_ORDERS);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const reportLines = useMemo(
    () =>
      getSalesOrders.data?.salesOrderReport.map((so) => ({
        ...so,
        projectCode: `P-${so.projectId}`,
        totalAmount: so.totalAmount / 100,
        conversionDate: new Date(so.conversionDate),
        preparationDate: new Date(so.preparationDate),
        installationDate: new Date(so.installationDate),
          closedEODsCount: so.eODs.filter((eod) => eod.resolvedAt != undefined).length,
          hasOpenEODS: so.eODs.filter((eod) => eod.resolvedAt != undefined).length != so.eODs.length,
          eODs: so.eODs.map((eod) => ({
              ...eod,
              isResolved: eod.resolvedAt != undefined
          }))
      })) ?? [],
    [getSalesOrders.data]
  );

  return (
    <div className="flex flex-col h-full">
      <ProjectSubnavbar
        title="Sales Orders"
        style={{ top: "unset" }}
        actions={
          <>
            <Popover minimal>
              <Button minimal icon="share" text="Share Report" />
              <ShareReportMenu dataState={dataState} />
            </Popover>
          </>
        }
      />

      <div className="h-full relative">
        {getSalesOrders.loading ? (
          <Spinner />
        ) : (
          <Grid
            className="absolute top-0 bottom-0 w-full"
            data={process(reportLines, dataState)}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            reorderable
            resizable
          >
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="id"
              title="Sales Order#"
              width={160}
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.projectId}/sales-orders/${props.dataItem.id}`}
                    text={props.dataItem.code}
                  />
                </td>
              )}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="projectCode"
              title="Project #"
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.projectId}/quotes`}
                    text={props.dataItem.projectCode}
                  />
                </td>
              )}
              width={120}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="quoteCode"
              title="Quote #"
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.projectId}/quotes/${props.dataItem.quoteId}`}
                    text={props.dataItem.quoteCode}
                  />
                </td>
              )}
              width={150}
            />
              <GridColumn
                  title="EODs"
                  width={100}
                  cell={(props) => (
                      <td>
                          {(props.dataItem.eODs.length == 0) ? <Tag intent={Intent.SUCCESS}>No EODs</Tag> : <>

                              <Popover interactionKind="hover" position="bottom-left">
                                  <Tag icon={props.dataItem.hasOpenEODS ? "high-priority" : "small-tick"} intent={props.dataItem.hasOpenEODS ? Intent.WARNING : Intent.SUCCESS}>{ (props.dataItem.hasOpenEODS ? `${props.dataItem.eODs.length} EODs` : `${props.dataItem.eODs.length} EODs`)  }</Tag>
                                  <div className="p-2 space-y-2">
                                      <h4 className="font-medium">EODs</h4>
                                      {props.dataItem.eODs.map((eod) => (
                                          <div className={`flex space-x-4 items-center p-2 ${(eod.isResolved ? "bg-green-100" : "bg-red-100")} rounded`}>
                                              <div className="space-y-1" style={{ width: 250 }}>
                                                  <p className="m-0 font-medium">{eod.title}</p>
                                                  <p className="text-xs text-gray-700">
                                                      Created by {eod.createdBy.name}
                                                  </p>
                                                  <p className="text-xs text-gray-700 font-bold">
                                                      {eod.resolvedAt == undefined ? "Unresolved" : "Resolved"}
                                                  </p>
                                              </div>

                                              <Button
                                                  small
                                                  text="View"
                                                  rightIcon="chevron-right"
                                                  onClick={() =>
                                                      window.open(
                                                          `/eods/${eod.id}`,
                                                          "_blank"
                                                      )
                                                  }
                                              />
                                          </div>))}
                                  </div>
                              </Popover>
                          </>}
                      </td>
                  )}
                  sortable={false}
                  filterable={false}
              />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="primaryContactPersonName"
              title="Primary Contact"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="primaryContactCompanyName"
              title="Primary Contact Company"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="billingContactPersonName"
              title="Billing Contact Person"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="billingContactCompanyName"
              title="Billing Contact Company"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Delivery Address"
              cell={(props) => (
                <td>{formatAddress(props.dataItem.deliveryAddress)}</td>
              )}
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Fufillment Method"
              cell={() => <td>?</td>}
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentContactPersonName"
              title="Fulfillment Contact Person"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentContactCompanyName"
              title="Fulfillment Contact Company"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="numeric"
              field="totalQuantity"
              title="Total Items"
              width={120}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="totalAmount"
              title="Total Amount"
              filter="numeric"
              cell={(props) => (
                <td>
                  {props.field && props.dataItem[props.field]
                    ? formatPrice(
                        encodePriceAsInteger(props.dataItem[props.field])
                      )
                    : undefined}
                </td>
              )}
              width={150}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              title="GP %"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="GP $ Total"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="conversionDate"
              title="Conversion Date"
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="preparationDate"
              title="Prep Date"
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Prep By"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Prepped"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="installationDate"
              title="Installation Date"
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Shipped"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Active/Partial/Invoice"
              cell={() => <td>?</td>}
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="salesRepName"
              title="Sales Rep"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="customerServiceRepName"
              title="Customer Service Rep"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="designerName"
              title="Designer"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="projectManagerName"
              title="Project Manager"
              width={200}
            />
          </Grid>
        )}
      </div>
    </div>
  );
}
