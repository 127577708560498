import { IItemRendererProps, Suggest } from "@blueprintjs/select";
import { CatalogOption, CatalogOptionGroup } from "../../../types/ApiTypes";
import { Button, Icon, MenuItem, Spinner } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { Dispatch, SetStateAction, useState } from "react";
import produce from "immer";
import {
  CatalogItemType,
  getCatalogImageUrl,
} from "../../../helpers/CatalogHelper";
import classNames from "classnames";
import {
  EditableProductConfiguration,
  EditableProductFeatureSelection,
} from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";

const GET_OPTION_GROUP_DETAILS = gql`
  query GetOptionGroupDetails($optionGroupId: Int!) {
    catalogOptionGroups(where: { id: { eq: $optionGroupId } }) {
      id
      code
      description
      options {
        id
        code
        description
        optionGroups {
          id
          code
          description
        }
      }
    }
  }
`;

type OptionSelection = {
  configuration: EditableProductConfiguration;
  setConfiguration: Dispatch<SetStateAction<EditableProductConfiguration>>;
  rootOptionGroupId: number;
  selection: EditableProductFeatureSelection;
  selectionIndex: number;
};

export function OptionSelection({
  rootOptionGroupId,
  configuration,
  setConfiguration,
  selection,
  selectionIndex,
}: OptionSelection) {
  const OptionSuggest = Suggest.ofType<CatalogOption>();

  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const getOptionGroupDetails = useQuery<
    { catalogOptionGroups: CatalogOptionGroup[] },
    { optionGroupId: number }
  >(GET_OPTION_GROUP_DETAILS, {
    variables: { optionGroupId: selection.catalogOptionGroupId },
  });

  const renderItem = (item: CatalogOption, props: IItemRendererProps) => {
    return (
      <MenuItem
        text={item.description}
        label={item.code}
        onClick={props.handleClick}
        active={props.modifiers.active}
        disabled={props.modifiers.disabled}
      />
    );
  };

  const filterItem = (query: string, item: CatalogOption) => {
    const normalizedQuery = query.toLowerCase().trim();
    const normalizedDescription = item.description.toLowerCase().trim();
    const normalizedCode = item.code.toLowerCase().trim();

    return (
      normalizedDescription.includes(normalizedQuery) ||
      normalizedCode.includes(normalizedQuery)
    );
  };

  const renderRightElement = () => {
    if (getOptionGroupDetails.loading) {
      return <Spinner size={15} />;
    } else {
      return (
        <Button
          icon="cross"
          onClick={() =>
            setConfiguration((s) =>
              produce(s, (draft) => {
                const feature = draft.features.find(
                  (f) => f.catalogOptionGroupId === rootOptionGroupId
                )!;

                feature.selections.splice(selectionIndex);

                feature.selections.push({
                  catalogOptionGroupId: selection.catalogOptionGroupId,
                  catalogOptionGroupCode: selection.catalogOptionGroupCode,
                });
              })
            )
          }
          minimal
        />
      );
    }
  };

  const optionGroup = getOptionGroupDetails.data?.catalogOptionGroups[0];

  const selectedItem =
    optionGroup && selection?.catalogOptionId
      ? optionGroup.options.find((o) => o.id === selection.catalogOptionId)
      : null;

  return (
    <>
      <div
        className={classNames("p-1 space-x-1 flex items-center p-2", {
          "bg-red-50": !selectedItem,
        })}
      >
        {selectedItem && !imageFailedToLoad && (
          <img
            src={getCatalogImageUrl(selectedItem.id, CatalogItemType.Option)}
            className="border rounded object-cover overflow-hidden"
            style={{ height: 30, width: 30, borderStyle: "solid" }}
            alt={selectedItem.code}
            onError={() => setImageFailedToLoad(true)}
          />
        )}
        {(!selectedItem || imageFailedToLoad) && (
          <div
            className="flex items-center justify-center border rounded object-cover overflow-hidden text-gray-200 bg-white"
            style={{ height: 30, width: 30, borderStyle: "solid" }}
          >
            <Icon icon="media" />
          </div>
        )}
        <OptionSuggest
          items={optionGroup?.options ?? []}
          itemRenderer={renderItem}
          itemPredicate={filterItem}
          inputValueRenderer={(i) => `${i.description} (${i.code})`}
          inputProps={{
            placeholder: "Choose an option...",
            rightElement: renderRightElement(),
          }}
          popoverProps={{ minimal: true }}
          onItemSelect={(c) => {
            setConfiguration((s) =>
              produce(s, (draft) => {
                const feature = draft.features.find(
                  (f) => f.catalogOptionGroupId === rootOptionGroupId
                )!;

                feature.selections.splice(selectionIndex);

                feature.selections.push({
                  catalogOptionGroupId: selection.catalogOptionGroupId,
                  catalogOptionGroupCode: selection.catalogOptionGroupCode,
                  catalogOptionId: c.id,
                  catalogOptionCode: c.code,
                  catalogOptionDescription: c.description,
                });

                if (c.optionGroups.length > 0) {
                  feature.selections.push({
                    catalogOptionGroupId: c.optionGroups[0].id,
                    catalogOptionGroupCode: c.optionGroups[0].code,
                  });
                }
              })
            );
          }}
          noResults={() => <MenuItem text="No Results" />}
          disabled={getOptionGroupDetails.loading}
          selectedItem={selectedItem}
          fill
        />
      </div>
    </>
  );
}
