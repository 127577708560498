import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
} from "@blueprintjs/core";
import { useState } from "react";
import {
  Attachment,
  ContactRole,
  CreatePurchaseOrderSubmissionPayload,
  PurchaseOrderSubmissionMethod,
} from "../../../../types/ApiTypes";
import { useApiError } from "../../../../hooks/useApiError";
import { gql, useMutation, useQuery } from "@apollo/client";
import { AttachmentsInput } from "../../../shared/AttachmentsInput/AttachmentsInput";

type GetPurchaseOrderVendorContactsParams = {
  purchaseOrderId: number;
};

type GetPurchaseOrderVendorContactsResult = {
  purchaseOrders: {
    id: number;
    manufacturerId: number;
    vendor: {
      id: number;
      contacts: {
        id: number;
        role: ContactRole;
        person: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
        };
      }[];
    };
  }[];
};

const GetPurchaseOrderVendorContacts = gql`
  query GetPurchaseOrderVendorContacts($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      manufacturerId
      vendor {
        id
        contacts {
          id
          role
          person {
            id
            firstName
            lastName
            email
            phone
          }
        }
      }
    }
  }
`;

const CreatePurchaseOrderSubmission = gql`
  mutation CreatePurchaseOrderSubmission(
    $input: CreatePurchaseOrderSubmissionPayloadInput
  ) {
    createPurchaseOrderSubmission(payload: $input) {
      id
    }
  }
`;

export type PurchaseOrderSubmissionDialogProps = DialogProps & {
  projectId?: number;
  purchaseOrderId?: number;
  onSubmit: () => void;
};

export function PurchaseOrderSubmissionDialog({
  projectId,
  purchaseOrderId,
  isOpen,
  onSubmit,
  onClose,
}: PurchaseOrderSubmissionDialogProps) {
  const [submissionMethod, setSubmissionMethod] = useState("");
  const [submissionDestination, setSubmissionDestination] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const handleCreateError = useApiError("create purchase order submission");

  const getPurchaseOrderVendorContacts = useQuery<
    GetPurchaseOrderVendorContactsResult,
    GetPurchaseOrderVendorContactsParams
  >(GetPurchaseOrderVendorContacts, {
    variables: {
      purchaseOrderId: purchaseOrderId!,
    },
    fetchPolicy: "cache-and-network",
    skip: !purchaseOrderId,
  });

  const [createPurchaseOrderSubmission] = useMutation<
    { createPurchaseOrderSubmission: { id: number } },
    { input: CreatePurchaseOrderSubmissionPayload }
  >(CreatePurchaseOrderSubmission, {
    onError: handleCreateError,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    await createPurchaseOrderSubmission({
      variables: {
        input: {
          purchaseOrderId: purchaseOrderId!,
          submissionMethod: submissionMethod as PurchaseOrderSubmissionMethod,
          submissionDestination,
          submissionAttachmentId: attachments[0]?.id,
          notes: "",
        },
      },
    });

    onSubmit();
  };

  const submissionContacts =
    getPurchaseOrderVendorContacts.data?.purchaseOrders[0].vendor.contacts.filter(
      (c) => c.role === ContactRole.VendorSubmission
    ) ?? [];

  return (
    <Dialog
      title="Record Submission"
      isOpen={isOpen}
      isCloseButtonShown={false}
      lazy
      usePortal
    >
      <div className={Classes.DIALOG_BODY}>
        {submissionContacts.length > 0 && (
          <FormGroup label="Submission Contacts">
            <div className="border rounded bg-white">
              <Menu>
                {submissionContacts.map((c) => (
                  <>
                    <MenuDivider
                      title={`${c.person.firstName} ${c.person.lastName}`}
                    />
                    {c.person.phone && (
                      <>
                        <MenuItem
                          icon="phone"
                          text={c.person.phone}
                          label="Phone"
                          onClick={() => {
                            setSubmissionDestination(c.person.phone);
                            setSubmissionMethod("Phone");
                          }}
                          active={
                            submissionDestination === c.person.phone &&
                            submissionMethod === "Phone"
                          }
                        />
                        <MenuItem
                          icon="phone"
                          text={c.person.phone}
                          label="Fax"
                          onClick={() => {
                            setSubmissionDestination(c.person.phone);
                            setSubmissionMethod("Fax");
                          }}
                          active={
                            submissionDestination === c.person.phone &&
                            submissionMethod === "Fax"
                          }
                        />
                      </>
                    )}
                    {c.person.email && (
                      <MenuItem
                        icon="envelope"
                        text={c.person.email}
                        onClick={() => {
                          setSubmissionDestination(c.person.email);
                          setSubmissionMethod("Email");
                        }}
                        active={
                          submissionDestination === c.person.email &&
                          submissionMethod === "Email"
                        }
                      />
                    )}
                  </>
                ))}
              </Menu>
            </div>
          </FormGroup>
        )}

        <FormGroup label="Submission Method">
          <HTMLSelect
            value={submissionMethod}
            onChange={(e) => setSubmissionMethod(e.target.value)}
            fill
          >
            <option value="" disabled>
              Select a submission method...
            </option>
            <option value={PurchaseOrderSubmissionMethod.Email}>Email</option>
            <option value={PurchaseOrderSubmissionMethod.Fax}>Fax</option>
            <option value={PurchaseOrderSubmissionMethod.Phone}>Phone</option>
            <option value={PurchaseOrderSubmissionMethod.Other}>Other</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup
          label="Submission Destination"
          helperText="Provide the email, address, or telephone number the submission was sent to."
        >
          <InputGroup
            type="text"
            value={submissionDestination}
            onChange={(e) => setSubmissionDestination(e.target.value)}
          />
        </FormGroup>
        <FormGroup label="Attachment">
          <AttachmentsInput
            single
            projectId={projectId}
            attachments={attachments}
            onAddAttachment={(a) => setAttachments([...attachments, a])}
            onRemoveAttachment={(a) =>
              setAttachments(attachments.filter((x) => x.id !== a.id))
            }
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} type="button" />
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            onClick={handleSubmit}
            disabled={!submissionMethod || !submissionDestination}
          />
        </div>
      </div>
    </Dialog>
  );
}
