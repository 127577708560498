import {
  Button,
  Classes,
  FormGroup,
  IDialogProps,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { QuoteVersionGroup } from "../../../../types/ApiTypes";

export type AddQuoteVersionGroupFormProps = {
  projectId: number;
  onSubmit: (quoteVersionGroup: QuoteVersionGroup) => void;
  onClose: () => void;
};

export function AddQuoteVersionGroupForm({
  projectId,
  onSubmit,
  onClose,
}: AddQuoteVersionGroupFormProps) {
  const [name, setName] = useState("");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      onSubmit({
        name,
        projectId,
      });

      if (onClose) onClose();
    },
    [name, projectId]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label="Name">
          <InputGroup
            placeholder="Version Group Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
            large
            fill
            required
          />
        </FormGroup>

        <div className="bg-white rounded p-4 mt-2">
          <p className="m-0 text-sm">
            Organize quotes which are alternative options for the customer
            together into separate groups. For example, Quote Group 1 "Reception
            Options" contains 3 different alternative Quotes from which the
            customer may choose. In addition Quote Group 2 "Conference Room
            Options" contains 2 different alternative quotes. The customer may
            only choose one quote from a quote group.
          </p>
        </div>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={onClose} text="Cancel" type="button" minimal />
          <Button intent={Intent.PRIMARY} text="Create" type="submit" />
        </div>
      </div>
    </form>
  );
}
