import { formatPrice } from "../../../../helpers/PriceHelper";
import {
  Button,
  ButtonGroup,
  Callout,
  InputGroup,
  Intent,
  Popover,
  Position,
} from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { EditableProductConfiguration } from "../../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import { getTaxCode } from "../../../../helpers/TaxHelper";

type TaxCellProps = {
  readOnly?: boolean;
  configuration?: EditableProductConfiguration;
  totalSellPrice: number;
  taxAmount: number;
  taxCode?: string;
  onTaxCodeChange: (newTaxCode: string) => void;
};

export function TaxCell({
  readOnly,
  configuration,
  totalSellPrice,
  taxAmount,
  taxCode,
  onTaxCodeChange,
}: TaxCellProps) {
  const apolloClient = useApolloClient();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [taxCodeValue, setTaxCodeValue] = useState(taxCode ?? "");
  const taxPercent = ((taxAmount / totalSellPrice) * 100).toFixed(3);

  const useProductTaxCode = useCallback(async () => {
    if (!configuration) return;
    setTaxCodeValue(await getTaxCode(configuration, apolloClient));
  }, [configuration?.catalogProductId]);

  return (
    <div className="space-y-1">
      <InputGroup
        value={`${formatPrice(taxAmount)} (${taxPercent}%)`}
        rightElement={
          <ButtonGroup>
            <Popover
              isOpen={popoverOpen}
              onClose={() => setPopoverOpen(false)}
              lazy
              usePortal
              minimal
              position="bottom-right"
            >
              <Button icon="cog" onClick={() => setPopoverOpen(true)} />
              <div className="p-2 max-w-sm space-y-2">
                <Callout icon="info-sign">
                  Tax is automatically recalculated when you save changes.
                </Callout>
                {!taxCode && (
                  <Callout icon="warning-sign" intent={Intent.DANGER}>
                    Tax cannot be calculated for this line item because no tax
                    code has been specified.
                  </Callout>
                )}
                <div className="flex space-x-2 items-center w-full">
                  <label className="mr-2">Tax Code</label>
                  <InputGroup
                    className="flex-1"
                    disabled={readOnly}
                    value={taxCodeValue}
                    onChange={(e) => setTaxCodeValue(e.target.value)}
                    placeholder="No Tax Code"
                  />
                  <Button
                    text="Update"
                    disabled={readOnly}
                    intent={Intent.PRIMARY}
                    onClick={() => {
                      onTaxCodeChange(taxCodeValue);
                      setPopoverOpen(false);
                    }}
                  />
                </div>
                {configuration && (
                  <Button
                    text="Use Product Tax Code"
                    onClick={useProductTaxCode}
                    fill
                  />
                )}
              </div>
            </Popover>
          </ButtonGroup>
        }
        readOnly
        disabled
      />
      <p className="text-xs text-gray-500 m-0">
        Tax Code: {taxCode || "Not Specified"}
      </p>
      {!taxCode && (
        <p className="text-xs text-yellow-500 mt-1">
          Tax cannot be calculated for this line item because it does not have a
          tax code.
        </p>
      )}
    </div>
  );
}
