import { Avatar } from "@progress/kendo-react-layout";
import { formatDate, formatDateTime } from "../../helpers/DateHelper";
import {
  formatContactName,
  FormatContactNameContact,
} from "../../helpers/ContactHelper";
import { BasicTable } from "../../components/shared/Table/BasicTable";
import { getFullConfigurationCode } from "../../helpers/CatalogHelper";
import React from "react";
import { ProductConfiguration } from "../../types/ApiTypes";
import { Link } from "react-router-dom";
import { Icon } from "@blueprintjs/core";

export type FulfillmentActivityItemProps = {
  id: number;
  fulfillment: {
    id: number;
    project: {
      id: number;
      primaryContact?: FormatContactNameContact;
    };
    deliveryStartsAt?: string;
    deliveryEndsAt?: string;
    requestedAt?: string;
    installStartsAt?: string;
    installEndsAt?: string;
  };
  lineItems: {
    id: number;
    fulfillmentLineItem: {
      id: number;
      salesOrderLineItem: {
        id: number;
        configuration: ProductConfiguration;
        salesOrderLineItemGroup: {
          id: number;
          salesOrder: {
            id: number;
            code: string;
          };
        };
        inventoryItems: {
          id: number;
          location?: {
            id: number;
            name: string;
          };
          purchaseOrderShipmentLineItem: {
            id: number;
            purchaseOrderLineItem: {
              id: number;
              purchaseOrder: {
                id: number;
                code: string;
              };
            };
          };
        }[];
      };
    };
  }[];
  createdAt: Date;
  createdBy: {
    id: number;
    name: string;
  };
  __typename: "EODFulfillment";
};

export function FulfillmentActivityItem(
  fulfillment: FulfillmentActivityItemProps
) {
  return (
    <div className="flex space-x-2">
      <div className="p-1">
        <Avatar>{fulfillment.createdBy.name.substring(0, 1)}</Avatar>
      </div>
      <div className="rounded border p-3 flex-1 bg-white shadow space-y-1">
        <div className="space-x-2 text-xs">
          <span className="font-medium text-gray-700">
            {fulfillment.createdBy.name}
          </span>
          <span className="text-gray-500">
            {formatDateTime(fulfillment.createdAt)}
          </span>
        </div>
        <div key={fulfillment.id} className="border rounded divide-y">
          <div className="p-2 bg-gray-50 flex space-x-4 items-center">
            <Link
              to={`/projects/${fulfillment.fulfillment.project.id}/fulfillments/${fulfillment.fulfillment.id}`}
              target="_blank"
            >
              <h5 className="font-medium text-base w-20 flex items-center space-x-1">
                <span>F-{fulfillment.fulfillment.id}</span>
                <Icon icon="arrow-top-right" iconSize={12} />
              </h5>
            </Link>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">
                Requested Date
              </h6>
              <p className="m-0">
                {fulfillment.fulfillment.requestedAt
                  ? formatDate(fulfillment.fulfillment.requestedAt)
                  : "N/A"}
              </p>
            </div>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">
                Install Start
              </h6>
              <p className="m-0">
                {fulfillment.fulfillment.installStartsAt
                  ? formatDate(fulfillment.fulfillment.installStartsAt)
                  : "N/A"}
              </p>
            </div>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">
                Install End
              </h6>
              <p className="m-0">
                {fulfillment.fulfillment.installEndsAt
                  ? formatDate(fulfillment.fulfillment.installEndsAt)
                  : "N/A"}
              </p>
            </div>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">
                Delivery Start
              </h6>
              <p className="m-0">
                {fulfillment.fulfillment.deliveryStartsAt
                  ? formatDate(fulfillment.fulfillment.deliveryStartsAt)
                  : "N/A"}
              </p>
            </div>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">
                Delivery End
              </h6>
              <p className="m-0">
                {fulfillment.fulfillment.deliveryEndsAt
                  ? formatDate(fulfillment.fulfillment.deliveryEndsAt)
                  : "N/A"}
              </p>
            </div>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">Client</h6>
              <p className="m-0">
                {fulfillment.fulfillment.project.primaryContact
                  ? formatContactName(
                      fulfillment.fulfillment.project.primaryContact
                    )
                  : "N/A"}
              </p>
            </div>
          </div>
          {fulfillment.lineItems && (
            <BasicTable header footer>
              <thead>
                <tr>
                  <th>SO #</th>
                  <th>PO #</th>
                  <th>Inventory #</th>
                  <th>Product Code</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {fulfillment.lineItems.map((lineItem) => (
                  <tr key={lineItem.id}>
                    <td>
                      {
                        lineItem.fulfillmentLineItem.salesOrderLineItem
                          .salesOrderLineItemGroup.salesOrder.code
                      }
                    </td>
                    <td>
                      {lineItem.fulfillmentLineItem.salesOrderLineItem.inventoryItems
                        .map(
                          (i) =>
                            i.purchaseOrderShipmentLineItem
                              .purchaseOrderLineItem.purchaseOrder.code
                        )
                        .join(", ")}
                    </td>
                    <td>
                      {lineItem.fulfillmentLineItem.salesOrderLineItem.inventoryItems
                        .map((i) => `INV-${i.id}`)
                        .join(", ")}
                    </td>
                    <td>
                      {getFullConfigurationCode(
                        lineItem.fulfillmentLineItem.salesOrderLineItem
                          .configuration
                      )}
                    </td>
                    <td>
                      {lineItem.fulfillmentLineItem.salesOrderLineItem.inventoryItems
                        .map((i) => i.location?.name)
                        .filter((i) => !!i)
                        .join(", ")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          )}
        </div>
      </div>
    </div>
  );
}
