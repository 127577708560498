import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Callout, Popover, Spinner, Tag } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { useReportDataState } from "../../hooks/useReportDataState";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  FormatContactNameContactFragment,
  FormatContactNameContact,
  formatContactName,
} from "../../helpers/ContactHelper";
import { process } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridFilterCellProps,
} from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { formatDate } from "../../helpers/DateHelper";
import { PropsWithChildren, useCallback } from "react";
import { formatAccountName } from "../../helpers/AccountHelper";
import {
  Account,
  UpdateConversionRequirementInput,
} from "../../types/ApiTypes";
import { MultiSelect } from "@progress/kendo-react-dropdowns";

type UpdateConversionRequirementParams = {
  payload: UpdateConversionRequirementInput;
};

type UpdateConversionRequirementResult = {
  updateConversionRequirement: {
    id: number;
  };
};

const UpdateConversionRequirement = gql`
  mutation UpdateRequirement(
    $payload: UpdateConversionRequirementPayloadInput!
  ) {
    updateConversionRequirement(payload: $payload) {
      id
    }
  }
`;

const ConvertQuoteToSalesOrder = gql`
  mutation ConvertQuoteToSalesOrder($quoteId: Int!) {
    convertQuoteToSalesOrder(quoteId: $quoteId) {
      id
    }
  }
`;

type ConversionCellProps = PropsWithChildren<GridCellProps> & {
  onConvert: () => void;
};

function ConversionCell({ onConvert, ...props }: ConversionCellProps) {
  const dataItem =
    props.dataItem as GetQuoteConversionReportResult["quotes"][0];
  const isConverted = !!dataItem.salesOrder;
  const completeRequirements = dataItem.conversionRequirements.filter(
    (cr) => !!cr.completedAt
  );

  if (isConverted) {
    return (
      <td>
        <Tag intent="success" minimal fill>
          Converted {formatDate(new Date(dataItem.salesOrder!.createdAt))}
        </Tag>
      </td>
    );
  } else if (
    completeRequirements.length === dataItem.conversionRequirements.length
  ) {
    return (
      <td className="box-border space-y-2">
        <Callout intent="success" icon="tick-circle">
          All conversion requirements have been satisfied, and this quote is
          ready to be converted.
        </Callout>
        <Button
          text="Convert Quote"
          intent="primary"
          onClick={onConvert}
          fill
          small
        />
      </td>
    );
  } else {
    return (
      <td className="space-y-2 box-border">
        <Callout intent="warning" icon="circle">
          {completeRequirements.length} of{" "}
          {dataItem.conversionRequirements.length} conversion requirements have
          been completed.
        </Callout>
      </td>
    );
  }
}

type ConversionRequirementCellsProps = GridCellProps & {
  onRequirementComplete: (requirementId: number) => void;
  onRequirementIncomplete: (requirementId: number) => void;
};

function ConversionRequirementCells({
  onRequirementComplete,
  onRequirementIncomplete,
  ...props
}: ConversionRequirementCellsProps) {
  const dataItem =
    props.dataItem as GetQuoteConversionReportResult["quotes"][0];

  return (
    <td className="box-border space-y-2">
      <div className="space-y-1">
        {dataItem.conversionRequirements.map((ir) => (
          <div className="flex items-center space-x-2">
            <Button
              icon={ir.completedAt ? "tick-circle" : "circle"}
              onClick={() =>
                ir.completedAt
                  ? onRequirementIncomplete(ir.id)
                  : onRequirementComplete(ir.id)
              }
              minimal
              small
            />
            <span>{ir.title}</span>
          </div>
        ))}
      </div>

      <Button
        text="View Conversion Requirements"
        rightIcon="arrow-right"
        onClick={() =>
          window.open(
            `/projects/${dataItem.quoteVersionGroup.project.id}/quotes/${dataItem.id}/conversion`,
            "_blank"
          )
        }
        small
        fill
      />
    </td>
  );
}

const filterConversionOperator = (field, value) => {
  if (value.includes("Converted") && field.isConverted) return true;

  if (
    value.includes("Ready to Convert") &&
    field.isReadyToConvert &&
    !field.isConverted
  )
    return true;

  if (
    value.includes("Not Ready to Convert") &&
    !field.isConverted &&
    !field.isReadyToConvert
  )
    return true;

  return false;
};

const CategoryFilterCell = (props: GridFilterCellProps) => {
  console.log(props);
  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <label className="text-xs">Status</label>
        <MultiSelect
          data={["Converted", "Ready to Convert", "Not Ready to Convert"]}
          value={props.value}
          onChange={(e) =>
            props.onChange({
              value: e.value,
              operator: filterConversionOperator,
              syntheticEvent: e.syntheticEvent,
            })
          }
        />
      </div>
    </div>
  );
};

type GetQuoteConversionReportResult = {
  quotes: {
    id: number;
    code: string;
    requestedFulfillmentDate?: string;
    salesOrder?: {
      id: number;
      code: string;
      createdAt: string;
    };
    conversionRequirements: {
      id: number;
      title: string;
      completedAt?: string;
    }[];
    quoteVersionGroup: {
      id: number;
      project: {
        id: number;
        primaryContact: FormatContactNameContact;
        salesRepAccount: Account;
        designerAccount: Account;
        customerServiceRepAccount: Account;
      };
    };
  }[];
};

const GetQuoteConversionReport = gql`
  ${FormatContactNameContactFragment}

  query GetQuoteConversionReport {
    quotes {
      id
      code
      requestedFulfillmentDate
      salesOrder {
        id
        code
        createdAt
      }
      conversionRequirements {
        id
        title
        completedAt
      }
      quoteVersionGroup {
        id
        project {
          id
          primaryContact {
            id
            ...FormatContactNameContactFragment
          }
          salesRepAccount {
            id
            name
          }
          designerAccount {
            id
            name
          }
          customerServiceRepAccount {
            id
            name
          }
        }
      }
    }
  }
`;

export default function QuoteConversionDashboard() {
  const getQuoteConversionReport = useQuery<GetQuoteConversionReportResult>(
    GetQuoteConversionReport,
    { fetchPolicy: "cache-and-network" }
  );

  const [convertQuoteToSalesOrder] = useMutation<
    { convertQuoteToSalesOrder: { id: number } },
    { quoteId: number }
  >(ConvertQuoteToSalesOrder, {
    refetchQueries: [GetQuoteConversionReport],
  });

  const [updateConversionRequirement] = useMutation<
    UpdateConversionRequirementResult,
    UpdateConversionRequirementParams
  >(UpdateConversionRequirement, {
    refetchQueries: [GetQuoteConversionReport],
  });

  const convertQuote = useCallback(
    async (quote: GetQuoteConversionReportResult["quotes"][0]) => {
      const result = await convertQuoteToSalesOrder({
        variables: { quoteId: quote.id },
      });
      const salesOrderId = result.data!.convertQuoteToSalesOrder.id;
      window.open(
        `/projects/${quote.quoteVersionGroup.project.id}/sales-orders/${salesOrderId}`,
        "_blank"
      );
    },
    []
  );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
    filter: {
      filters: [
        {
          field: "conversion",
          value: ["Ready to Convert", "Not Ready to Convert"],
          operator: filterConversionOperator,
        },
      ],
      logic: "or",
    },
  });

  const quotes =
    getQuoteConversionReport.data?.quotes.map((q) => ({
      ...q,
      primaryContactName: formatContactName(
        q.quoteVersionGroup.project.primaryContact
      ),
      salesRepName: formatAccountName(
        q.quoteVersionGroup.project.salesRepAccount
      ),
      designerName: formatAccountName(
        q.quoteVersionGroup.project.designerAccount
      ),
      customerServiceRepName: formatAccountName(
        q.quoteVersionGroup.project.customerServiceRepAccount
      ),
      conversion: {
        isConverted: !!q.salesOrder,
        isReadyToConvert: q.conversionRequirements.every(
          (cr) => !!cr.completedAt
        ),
      },
      requestedFulfillmentDate: q.requestedFulfillmentDate
        ? new Date(q.requestedFulfillmentDate)
        : undefined,
    })) ?? [];

  return (
    <div className="flex flex-col h-full">
      <ProjectSubnavbar
        title="Quote Conversion"
        style={{ top: "unset" }}
        actions={
          <>
            <Popover minimal>
              <Button minimal icon="share" text="Share Report" />
              <ShareReportMenu dataState={dataState} />
            </Popover>
          </>
        }
      />

      <div className="h-full relative">
        {getQuoteConversionReport.loading ? (
          <Spinner />
        ) : (
          <Grid
            className="absolute top-0 bottom-0 w-full"
            data={process(quotes, dataState)}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            reorderable
            resizable
            filterable
          >
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="code"
              title="Quote #"
              width={200}
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.quoteVersionGroup.project.id}/quotes/${props.dataItem.id}`}
                    text={props.dataItem.code}
                  />
                </td>
              )}
              filterable
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="salesOrder.code"
              title="SO #"
              width={200}
              cell={(props) => (
                <td>
                  {props.dataItem.salesOrder ? (
                    <EntityLink
                      url={`/projects/${
                        props.dataItem.quoteVersionGroup.project.id
                      }/sales-orders/${props.dataItem.salesOrder!.id}`}
                      text={props.dataItem.salesOrder!.code}
                    />
                  ) : (
                    <>Not Converted</>
                  )}
                </td>
              )}
            />
            <GridColumn
              field="conversion"
              title="Conversion Status"
              cell={(props) => (
                <ConversionCell
                  onConvert={() => convertQuote(props.dataItem)}
                  {...props}
                />
              )}
              filterCell={CategoryFilterCell}
              sortable={false}
              width={500}
            />
            <GridColumn
              title="Conversion Requirements"
              cell={(props) => (
                <ConversionRequirementCells
                  {...props}
                  onRequirementComplete={async (requirementId) => {
                    await updateConversionRequirement({
                      variables: {
                        payload: {
                          id: requirementId,
                          completed: true,
                        },
                      },
                    });
                  }}
                  onRequirementIncomplete={async (requirementId) => {
                    await updateConversionRequirement({
                      variables: {
                        payload: {
                          id: requirementId,
                          completed: false,
                        },
                      },
                    });
                  }}
                />
              )}
              filterable={false}
              sortable={false}
              width={500}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Primary Contact"
              field="primaryContactName"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Sales Rep"
              field="salesRepName"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Designer"
              field="designerName"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Customer Service Rep"
              field="customerServiceRepName"
            />
            <GridColumn
              title="Customer Requested Delivery Date"
              field="requestedFulfillmentDate"
              filter="date"
            />
          </Grid>
        )}
      </div>
    </div>
  );
}
