import {
  Project,
  Attachment,
  CreateProjectAttachment,
} from "../types/ApiTypes";
import { useApiError } from "./useApiError";
import axios from "axios";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";

const GET_PROJECT_ATTACHMENTS = gql`
  query GetProjectAttachments($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      attachments {
        id
        name
        contentType
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_ALL_ATTACHMENTS = gql`
  query GetAllAttachments {
    attachments {
      id
      name
      contentType
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_ATTACHMENT = gql`
  mutation UpdateAttachment($input: UpdateAttachmentPayloadInput!) {
    updateAttachment(payload: $input) {
      id
    }
  }
`;

const DELETE_ATTACHMENT = gql`
  mutation UpdateAttachment($id: Int!) {
    deleteAttachment(attachmentId: $id) {
      id
    }
  }
`;

export function useProjectAttachments(projectId?: number) {
  const getProjectAttachments = useQuery<{ projects: Project[] }>(
    GET_PROJECT_ATTACHMENTS,
    {
      variables: {
        projectId,
      },
      skip: !projectId,
    }
  );

  const getAllAttachments =
    useQuery<{ attachments: Attachment[] }>(GET_ALL_ATTACHMENTS);

  const [updateAttachmentMutation] = useMutation(UPDATE_ATTACHMENT, {
    refetchQueries: [
      {
        query: GET_PROJECT_ATTACHMENTS,
        variables: { projectId },
      },
      {
        query: GET_ALL_ATTACHMENTS,
      },
    ],
  });

  const [deleteAttachmentMutation] = useMutation(DELETE_ATTACHMENT, {
    refetchQueries: [
      {
        query: GET_PROJECT_ATTACHMENTS,
        variables: { projectId },
      },
      {
        query: GET_ALL_ATTACHMENTS,
      },
    ],
  });

  const handleFetchError = useApiError("fetch attachments");
  const handleCreateError = useApiError("create attachment");
  const handleUploadError = useApiError("upload attachment");
  const handleUpdateError = useApiError("update attachment");
  const handleDeleteError = useApiError("delete attachment");

  const createAttachment = (attachment: Attachment, file: File) => {
    return axios
      .post(`/attachments`, attachment)
      .then((r) => r.data as CreateProjectAttachment)
      .then((a) => uploadAttachment(a.uploadUrl, file))
      .catch((err) => handleCreateError(err))
      .finally(() =>
        Promise.all([
          getAllAttachments.refetch(),
          getProjectAttachments.refetch(),
        ])
      );
  };

  const updateAttachment = (attachment: Attachment) => {
    return updateAttachmentMutation({
      variables: {
        input: {
          id: attachment.id,
          name: attachment.name,
        },
      },
    }).catch((err) => handleUpdateError(err));
  };

  const removeAttachment = (attachment: Attachment) => {
    return deleteAttachmentMutation({
      variables: {
        id: attachment.id,
      },
    }).catch((err) => handleDeleteError(err));
  };

  const uploadAttachment = (url: string, file: File) => {
    return axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((err) => handleUploadError(err));
  };

  useEffect(() => {
    if (getAllAttachments.error) {
      handleFetchError(getAllAttachments.error);
    }
    if (getProjectAttachments.error) {
      handleFetchError(getProjectAttachments.error);
    }
  }, [getAllAttachments.error, getProjectAttachments.error]);

  return {
    projectAttachments: getProjectAttachments.data?.projects[0].attachments,
    allAttachments: getAllAttachments.data?.attachments,
    loading: getProjectAttachments.loading || getAllAttachments.loading,
    error: getAllAttachments.error || getProjectAttachments.error,
    createAttachment,
    updateAttachment,
    removeAttachment,
  };
}
