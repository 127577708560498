import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import LabelWofLogo from "../../../../../assets/images/label_wof_logo.png";
import { InventoryItemLabel } from "../../../../../types/ApiTypes";
import { formatContactName } from "../../../../../helpers/ContactHelper";
import {
  EntityCodeType,
  getEntityCode,
} from "../../../../../helpers/EntityCodeHelper";
import { getVendorName } from "../../../../../helpers/PurchaseOrderHelpers";
import { getFullConfigurationCode } from "../../../../../helpers/CatalogHelper";

export type InventoryItemLabelWithQRCode = InventoryItemLabel & {
  qrCodeDataUrl: string;
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  wrapper: {
    flexDirection: "column",
    padding: 5,
    flexGrow: 1,
  },
  topSection: {
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    borderBottomWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  middleSection: {
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    flexGrow: 1,
    flexDirection: "row",
  },
  bottomSection: {
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  smallText: {
    textTransform: "uppercase",
    fontSize: 9,
  },
  mediumText: {
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: "bold",
  },
  largeText: {
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: "bold",
  },
  barcodeText: {
    letterSpacing: 3,
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: "bold",
  },
  labelDetails: {
    flex: 1,
    marginRight: 10,
  },
  qrCode: {
    height: 145,
    width: 145,
    borderWidth: 2,
    borderColor: "#000",
    borderStyle: "dashed",
    padding: 0,
  },
});

export type LabelPageProps = {
  label: InventoryItemLabelWithQRCode;
};

function LabelPage({ label }: LabelPageProps) {
  return (
    <Page size={[374, 230]} style={styles.page}>
      <View style={styles.wrapper}>
        <View style={styles.topSection}>
          <Image src={LabelWofLogo} style={{ width: 70, height: 20 }} />
          <Text style={styles.smallText}>
            2720 Market Street{"\n"}St. Louis, MO 63103
          </Text>
        </View>
        <View style={styles.middleSection}>
          <View style={styles.labelDetails}>
            <View
              style={{
                margin: -5,
                marginBottom: 5,
                backgroundColor: "#000",
                padding: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ ...styles.largeText, color: "#FFF", flexGrow: 1 }}>
                {
                  label.inventoryItem.purchaseOrderShipmentLineItem
                    .purchaseOrderShipment.purchaseOrder.code
                }
              </Text>

              <Text style={{ ...styles.largeText, color: "#FFF" }}>
                #
                {label.inventoryItem.purchaseOrderShipmentLineItem
                  .purchaseOrderLineItem.sortIndex + 1}
              </Text>

              {label.totalLabelsInSequence > 1 && (
                <Text
                  style={{ ...styles.largeText, color: "#FFF", marginLeft: 20 }}
                >
                  {label.sequenceNumber}/{label.totalLabelsInSequence}
                </Text>
              )}
            </View>
            <View style={{ marginBottom: 5 }}>
              <Text style={styles.smallText}>Product Code</Text>
              <Text style={styles.mediumText}>
                {getFullConfigurationCode(
                  label.inventoryItem.configuration
                ).replaceAll("\n", " ")}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                {label.inventoryItem.purchaseOrderShipmentLineItem
                  .purchaseOrderShipment.purchaseOrder.project && (
                  <View style={{ marginBottom: 5, marginRight: 5 }}>
                    <Text style={styles.smallText}>Project #</Text>
                    <Text style={styles.mediumText}>
                      {getEntityCode(
                        label.inventoryItem.purchaseOrderShipmentLineItem
                          .purchaseOrderShipment.purchaseOrder.project.id,
                        EntityCodeType.Project
                      )}
                    </Text>
                  </View>
                )}
                <View style={{ marginBottom: 5, marginRight: 5 }}>
                  <Text style={styles.smallText}>Inventory #</Text>
                  <Text style={styles.mediumText}>
                    {getEntityCode(
                      label.inventoryItem.id,
                      EntityCodeType.InventoryItem
                    )}
                  </Text>
                </View>
                <View style={{ marginBottom: 5, marginRight: 5 }}>
                  <Text style={styles.smallText}># of items in pkg</Text>
                  <Text style={styles.mediumText}>
                    {label.numberOfItemsInPackage}
                  </Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View style={{ marginBottom: 5 }}>
                  <Text style={styles.smallText}>SO #</Text>
                  <Text style={styles.mediumText}>
                    {
                      label.inventoryItem.purchaseOrderShipmentLineItem
                        .purchaseOrderShipment.purchaseOrder.salesOrder!.code
                    }
                  </Text>
                </View>
                {label.inventoryItem.purchaseOrderShipmentLineItem
                  .purchaseOrderShipment.purchaseOrder.project && (
                  <View style={{ marginBottom: 5 }}>
                    <Text style={styles.smallText}>Customer</Text>
                    <Text style={styles.mediumText}>
                      {formatContactName(
                        label.inventoryItem.purchaseOrderShipmentLineItem
                          .purchaseOrderShipment.purchaseOrder.project
                          .primaryContact
                      )}
                    </Text>
                  </View>
                )}
                <View style={{ marginBottom: 5 }}>
                  <Text style={styles.smallText}>Vendor</Text>
                  <Text style={styles.mediumText}>
                    {getVendorName(
                      label.inventoryItem.purchaseOrderShipmentLineItem
                        .purchaseOrderShipment.purchaseOrder
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.qrCode}>
            <Image src={label.qrCodeDataUrl} />
          </View>
        </View>
      </View>
    </Page>
  );
}

export type PrintLabelsPdfProps = {
  labels: InventoryItemLabelWithQRCode[];
};

export function PrintLabelsPdf({ labels }: PrintLabelsPdfProps) {
  return (
    <Document>
      {labels.map((label) => (
        <LabelPage key={label.id} label={label} />
      ))}
    </Document>
  );
}
