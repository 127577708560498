import { Helmet } from "react-helmet";
import { Button, Intent, Popover } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { CreateProjectDialog } from "../../components/projects/CreateProjectDialog/CreateProjectDialog";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { useReportDataState } from "../../hooks/useReportDataState";
import { formatContactName } from "../../helpers/ContactHelper";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { gql, useQuery } from "@apollo/client";

const GetProjects = gql`
  query GetProjects {
    projects {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      billingContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      fulfillmentContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      salesRepAccount {
        id
        name
      }
      createdAt
    }
  }
`;

type GetProjectsResult = {
  projects: {
    id: number;
    name: string;
    primaryContact: {
      id: number;
      person: {
        id: number;
        firstName: string;
        lastName: string;
      };
      company: {
        id: number;
        name: string;
      };
    };
    billingContact: {
      id: number;
      person: {
        id: number;
        firstName: string;
        lastName: string;
      };
      company: {
        id: number;
        name: string;
      };
    };
    fulfillmentContact: {
      id: number;
      person: {
        id: number;
        firstName: string;
        lastName: string;
      };
      company: {
        id: number;
        name: string;
      };
    };
    salesRepAccount: {
      id: number;
      name: string;
    };
    createdAt: string;
  }[];
};

export default function Projects() {
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const getProjects = useQuery<GetProjectsResult>(GetProjects);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const projectItems = useMemo(
    () =>
      (getProjects.data?.projects ?? []).map((project) => ({
        ...project,
        primaryContactName: formatContactName(project.primaryContact),
        billingContactName: formatContactName(project.billingContact),
        fulfillmentContactName: formatContactName(project.fulfillmentContact),
        createdAt: new Date(project.createdAt),
      })) ?? [],
    [getProjects.data]
  );

  return (
    <>
      <CreateProjectDialog
        isOpen={createProjectOpen}
        onClose={() => setCreateProjectOpen(false)}
      />

      <Helmet>
        <title>Projects</title>
      </Helmet>

      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Projects"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
              <Button
                intent={Intent.PRIMARY}
                text="New Project"
                icon="add"
                onClick={() => setCreateProjectOpen(true)}
              />
            </>
          }
        />
        <div className="h-full relative">
          <Grid
            className="absolute top-0 bottom-0 w-full"
            data={process(projectItems, dataState)}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            reorderable
            resizable
          >
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="id"
              title="Project #"
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.id}/quotes`}
                    text={`PR-${props.dataItem.id}`}
                  />
                </td>
              )}
              width={130}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="name"
              title="Name"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="salesRepAccount.name"
              title="Sales Rep"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="primaryContactName"
              title="Primary Contact"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="billingContactName"
              title="Billing Contact"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentContactName"
              title="Fulfillment Contact"
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="createdAt"
              title="Created"
              width={120}
            />
          </Grid>
        </div>
      </div>
    </>
  );
}
