import { Select } from "@blueprintjs/select";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Icon, MenuItem } from "@blueprintjs/core";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { Link } from "react-router-dom";

const GetPeople = gql`
  query GetPeople {
    people(order: { firstName: ASC }) {
      id
      firstName
      lastName
    }
  }
`;

type GetPeopleResult = {
  people: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
};

type PersonDetailItemProps = {
  eod: {
    id: number;
    person?: {
      id: number;
      firstName: string;
      lastName: string;
    };
  };
  onSelectPerson: (personId: number) => void;
};

const PersonSelect = Select.ofType<GetPeopleResult["people"][0]>();

export function PersonDetailItem({
  eod,
  onSelectPerson,
}: PersonDetailItemProps) {
  const getPeople = useQuery<GetPeopleResult>(GetPeople, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="flex space-x-2 items-center">
      <p className="m-0 font-medium text-xs w-20">Person</p>

      <PersonSelect
        items={getPeople.data?.people ?? []}
        onItemSelect={(item) => onSelectPerson(item!.id)}
        itemRenderer={(item, props) => (
          <MenuItem
            text={[item.firstName, item.lastName].join(" ")}
            onClick={props.handleClick}
            active={props.modifiers.active}
          />
        )}
        itemPredicate={(query, item) => {
          const normalizedQuery = query.trim().toLowerCase();
          const contactName = [item.firstName, item.lastName]
            .join(" ")
            .toLowerCase();
          return contactName.toString().includes(normalizedQuery);
        }}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
          className: "flex-1",
          targetClassName: "w-full",
        }}
      >
        <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center hover:bg-gray-50 cursor-pointer">
          <span className="flex-1">
            {eod.person
              ? [eod.person.firstName, eod.person.lastName].join(" ")
              : "Not Specified"}
          </span>
          <Icon icon="chevron-down" />
        </div>
      </PersonSelect>

      {eod.person && (
        <Link to={`/contacts/people/${eod.person.id}`} target="_blank">
          <Icon icon="circle-arrow-right" />
        </Link>
      )}
    </div>
  );
}
