import { CatalogProduct, InventoryItem } from "../../../types/ApiTypes";
import { useState } from "react";
import classNames from "classnames";
import { useRecentProducts } from "./useRecentProducts";
import { ChooseFromInventoryPanel } from "./ChooseFromInventoryPanel";
import { ChooseFromCatalogPanel } from "./ChooseFromCatalogPanel";
import { RecentProductsPanel } from "./RecentProductsPanel";
import { ChooseServicePanel } from "./ChooseServicePanel";

export type ChooseProductMenuProps = {
  allowInventoryItems: boolean;
  onChooseProduct: (product: CatalogProduct) => void;
  onChooseInventoryItem: (inventoryItem: InventoryItem) => void;
  onChooseCustomProduct?: () => void;
};

export function ChooseProductMenu({
  allowInventoryItems,
  onChooseInventoryItem,
  onChooseProduct,
  onChooseCustomProduct,
}: ChooseProductMenuProps) {
  const [activePanel, setActivePanel] = useState("recent");
  const { recentProducts, addRecentProduct, clearRecentProducts } =
    useRecentProducts();

  return (
    <div style={{ minWidth: 500 }}>
      {activePanel === "recent" && (
        <RecentProductsPanel
          recentProducts={recentProducts}
          onChoose={async (product) => {
            await addRecentProduct(product.id);
            onChooseProduct(product);
          }}
          onClearRecentProducts={() => clearRecentProducts()}
          onChooseFromCatalog={() => setActivePanel("catalog")}
          onChooseFromInventory={() => setActivePanel("inventory")}
          onChooseService={() => setActivePanel("services")}
          onChooseCustomProduct={() =>
            onChooseCustomProduct && onChooseCustomProduct()
          }
          allowInventoryItems={allowInventoryItems}
        />
      )}

      {activePanel === "catalog" && (
        <ChooseFromCatalogPanel
          onChoose={async (product) => {
            await addRecentProduct(product.id);
            setActivePanel("recent");
            onChooseProduct(product);
          }}
          onGoBack={() => setActivePanel("recent")}
        />
      )}

      {activePanel === "services" && (
        <ChooseServicePanel
          onChoose={(product) => onChooseProduct(product)}
          onGoBack={() => setActivePanel("recent")}
        />
      )}

      {allowInventoryItems && activePanel === "inventory" && (
        <ChooseFromInventoryPanel
          onChoose={(inventoryItem) => {
            setActivePanel("recent");
            onChooseInventoryItem(inventoryItem);
          }}
          onGoBack={() => setActivePanel("recent")}
        />
      )}
    </div>
  );
}
