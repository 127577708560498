import { Avatar } from "@progress/kendo-react-layout";
import { formatDateTime } from "../../helpers/DateHelper";
import React from "react";
import { Account } from "../../types/ApiTypes";

export type CommentActivityItemProps = {
  id: number;
  message: string;
  createdBy: Account;
  createdAt: Date;
  __typename: "EODComment";
};

export function CommentActivityItem(comment: CommentActivityItemProps) {
  return (
    <div className="flex space-x-2">
      <div className="p-1">
        <Avatar>{comment.createdBy.name.substring(0, 1)}</Avatar>
      </div>
      <div className="rounded border p-3 flex-1 bg-white shadow space-y-1">
        <div className="space-x-2 text-xs">
          <span className="font-medium text-gray-700">
            {comment.createdBy.name}
          </span>
          <span className="text-gray-500">
            {formatDateTime(comment.createdAt)}
          </span>
        </div>
        <p className="text-base text-gray-900">{comment.message}</p>
      </div>
    </div>
  );
}
