import { gql, useQuery } from "@apollo/client";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { process } from "@progress/kendo-data-query";
import { useReportDataState } from "../../hooks/useReportDataState";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";

type GetQuickbooksInvoicesResult = {
  quickbooksInvoices: {
    refNumber: string;
    isPaid: boolean;
    timeCreated: string;
    customerFullName: string;
    balanceRemaining: number;
    appliedAmount: number;
    subtotal: number;
    salesTaxTotal: number;
    dueDate: string;
  }[];
};

const GetQuickbooksInvoices = gql`
  query GetQuickbooksInvoices {
    quickbooksInvoices(limit: 50) {
      refNumber
      isPaid
      timeCreated
      customerFullName
      balanceRemaining
      appliedAmount
      subtotal
      salesTaxTotal
      dueDate
    }
  }
`;

export default function InvoicesDashboard() {
  const getInvoices = useQuery<GetQuickbooksInvoicesResult>(
    GetQuickbooksInvoices,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "timeCreated", dir: "desc" }],
  });

  const invoices =
    getInvoices.data?.quickbooksInvoices.map((i) => ({
      ...i,
      timeCreated: new Date(i.timeCreated),
      dueDate: new Date(i.dueDate),
    })) ?? [];

  return (
    <>
      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Invoices"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getInvoices.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(invoices, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
              filterable
              pageable
              pageSize={25}
            >
              <GridColumn
                field="refNumber"
                title="Invoice #"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="isPaid"
                title="Is Paid"
                filter="boolean"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="customerFullName"
                title="Customer"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="balanceRemaining"
                title="Balance Remaining"
                filter="numeric"
                format="{0:c}"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="appliedAmount"
                title="Applied Amount"
                filter="numeric"
                format="{0:c}"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="subtotal"
                title="Subtotal"
                filter="numeric"
                format="{0:c}"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="salesTaxTotal"
                title="Sales Tax"
                filter="numeric"
                format="{0:c}"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="dueDate"
                title="Due Date"
                filter="date"
                format="{0:d}"
                columnMenu={ReportColumnMenu}
              />
              <GridColumn
                field="timeCreated"
                title="Created"
                filter="date"
                format="{0:d}"
                columnMenu={ReportColumnMenu}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
