import React, { PropsWithChildren } from "react";
import { ProjectSubnavbar } from "../../ProjectSubnavbar";
import { Icon, MenuDivider } from "@blueprintjs/core";
import { NavLink, useParams } from "react-router-dom";

export type QuoteSubnavbarProps = {
  title: string;
  loading?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
};

export type QuoteRouteParams = {
  projectId: string;
  quoteId: string;
};

export function QuoteSubnavbar({
  title,
  loading,
  actions,
}: QuoteSubnavbarProps) {
  const { projectId, quoteId } = useParams<QuoteRouteParams>();

  return (
    <ProjectSubnavbar
      title={title}
      actions={actions}
      loading={loading}
      tabs={
        <ul>
          <li>
            <NavLink to={`/projects/${projectId}/quotes/${quoteId}`} exact>
              <Icon icon="add-row-bottom" />
              Line Items
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/projects/${projectId}/quotes/${quoteId}/conversion`}
              exact
            >
              <Icon icon="arrows-horizontal" />
              Conversion
            </NavLink>
          </li>
        </ul>
      }
    />
  );
}
