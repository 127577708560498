import React from "react";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import { Person } from "../../../types/ApiTypes";
import {
  Button,
  ButtonGroup,
  Classes,
  InputGroup,
  MenuItem,
  Position,
} from "@blueprintjs/core";
import classNames from "classnames";

type PersonInputGroupPerson = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type PersonInputGroupProps = {
  people: PersonInputGroupPerson[];
  loading: boolean;
  value?: PersonInputGroupPerson;
  onChange?: (e?: PersonInputGroupPerson) => void;
};

export function PersonInputGroup({
  people,
  loading,
  value,
  onChange,
}: PersonInputGroupProps) {
  const PersonSelect = Select.ofType<PersonInputGroupPerson>();

  const filterCompany: ItemPredicate<PersonInputGroupPerson> = (
    query,
    person
  ) => {
    if (query === "") return true;
    const normalizedQuery = query.toLowerCase().trim();
    const normalizedName = `${person.firstName} ${person.lastName}`
      .toLowerCase()
      .trim();
    return (
      normalizedName.indexOf(normalizedQuery) >= 0 ||
      person.email.indexOf(normalizedQuery) >= 0
    );
  };

  const renderCompany: ItemRenderer<PersonInputGroupPerson> = (
    person,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <Button
        key={person.id}
        onClick={handleClick}
        fill
        minimal
        alignText="left"
        className={classNames(Classes.MENU_ITEM)}
      >
        {(person.firstName || person.lastName) && (
          <p className="m-0 font-bold">
            {[person.firstName, person.lastName].filter((x) => x).join(" ")}
          </p>
        )}
        {person.email && <p className="m-0">{person.email}</p>}
        {person.phone && <p className="m-0">{person.phone}</p>}
      </Button>
    );
  };

  return (
    <InputGroup
      rightElement={
        <PersonSelect
          items={people}
          itemPredicate={filterCompany}
          itemRenderer={renderCompany}
          noResults={<MenuItem disabled={true} text="No results." />}
          activeItem={value}
          onItemSelect={(e) => onChange && onChange(e)}
          popoverProps={{ minimal: true, position: Position.BOTTOM_RIGHT }}
        >
          <ButtonGroup>
            <Button text={value ? "Change" : "Choose"} />
            {value && (
              <Button
                icon="cross"
                onClick={() => onChange && onChange(undefined)}
              />
            )}
          </ButtonGroup>
        </PersonSelect>
      }
      value={value ? `${value.firstName} ${value.lastName}` : "Choose person"}
      large
      readOnly
    />
  );
}
