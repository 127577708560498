import {
  QuoteEditableLineItem,
  QuoteEditableLineItemGroup,
  QuoteEditorState,
} from "../reducers/QuoteEditor/QuoteEditorTypes";
import {
  CommitQuoteDraftPayloadInput,
  CreateQuoteLineItemGroupPayloadInput,
  CreateQuoteLineItemPayloadInput,
  UpdateQuoteLineItemGroupPayloadInput,
  UpdateQuoteLineItemPayloadInput,
} from "../../../../types/ApiTypes";
import _ from "lodash";
import { buildCreateProductConfigurationPayload } from "../reducers/LineItemEditor/LineItemEditorHelpers";

export function buildCreateQuoteLineItemGroupPayload(
  lineItemGroup: QuoteEditableLineItemGroup
): CreateQuoteLineItemGroupPayloadInput {
  return {
    name: lineItemGroup.name,
    sortIndex: lineItemGroup.sortIndex,
    temporaryId: lineItemGroup.id,
  };
}

export function buildUpdateQuoteLineItemGroupPayload(
  lineItemGroup: QuoteEditableLineItemGroup
): UpdateQuoteLineItemGroupPayloadInput {
  return {
    sortIndex: lineItemGroup.sortIndex,
    quoteLineItemGroupId: lineItemGroup.id,
    name: lineItemGroup.name,
  };
}

export function buildCreateQuoteLineItemPayload(
  lineItem: QuoteEditableLineItem
): CreateQuoteLineItemPayloadInput {
  return {
    sortIndex: lineItem.sortIndex,
    quoteLineItemGroupId: lineItem.lineItemGroupId,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    grossProfitPercent: lineItem.grossProfitPercent,
    sellPrice: Math.round(lineItem.sellPrice),
    taxCode: lineItem.taxCode,
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildUpdateQuoteLineItemPayload(
  lineItem: QuoteEditableLineItem
): UpdateQuoteLineItemPayloadInput {
  return {
    id: lineItem.id!,
    sortIndex: lineItem.sortIndex,
    quoteLineItemGroupId: lineItem.lineItemGroupId,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    grossProfitPercent: lineItem.grossProfitPercent,
    sellPrice: Math.round(lineItem.sellPrice),
    taxCode: lineItem.taxCode,
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildCommitQuoteDraftPayload(
  quoteId: number,
  state: QuoteEditorState
): CommitQuoteDraftPayloadInput {
  const createQuoteLineItemGroups = state.lineItemGroups
    .filter((g) => g.isCreated)
    .map(buildCreateQuoteLineItemGroupPayload);
  const updateQuoteLineItemGroups = state.lineItemGroups
    .filter((g) => !g.isCreated && g.isModified)
    .map(buildUpdateQuoteLineItemGroupPayload);
  const deleteQuoteLineItemGroups = state.lineItemGroups
    .filter((g) => g.isRemoved)
    .map((g) => g.id);

  const createQuoteLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => li.isCreated)
        .map(buildCreateQuoteLineItemPayload)
    )
  );
  const updateQuoteLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => !li.isCreated && li.isModified)
        .map(buildUpdateQuoteLineItemPayload)
    )
  );
  const deleteQuoteLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems.filter((li) => li.isRemoved).map((li) => li.id!)
    )
  );

  return {
    quoteId: quoteId,
    requestedFulfillmentDate:
      state.quickSettings.requestedFulfillmentDate?.toISOString(),
    requestedFulfillmentDateNotes:
      state.quickSettings.requestedFulfillmentDateNotes,
    requestedFulfillmentDateFlagged:
      state.quickSettings.requestedFulfillmentDateFlagged,
    primaryContactPersonId:
      state.quickSettings.primaryContact?.person?.id ?? -1,
    primaryContactCompanyId:
      state.quickSettings.primaryContact?.company?.id ?? -1,
    billingAddressId: state.quickSettings.billingAddress?.id ?? -1,
    shipToAddressId: state.quickSettings.shipToAddress?.id ?? -1,
    salesRepAccountId: state.quickSettings.salesRepAccount?.id ?? -1,
    createQuoteLineItemPayloads: createQuoteLineItems,
    updateQuoteLineItemPayloads: updateQuoteLineItems,
    deleteQuoteLineItemPayloads: deleteQuoteLineItems,
    createQuoteLineItemGroupPayloads: createQuoteLineItemGroups,
    updateQuoteLineItemGroupPayloads: updateQuoteLineItemGroups,
    deleteQuoteLineItemGroupPayloads: deleteQuoteLineItemGroups,
  };
}
