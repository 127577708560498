import { Drawer, DrawerProps, DrawerSize, Position } from "@blueprintjs/core";
import { ShipmentDetails } from "./ShipmentDetails";

export type ShipmentDetailsDrawerProps = DrawerProps & {
  shipmentId?: number;
};

export function ShipmentDetailsDrawer({
  isOpen,
  onClose,
  shipmentId,
}: ShipmentDetailsDrawerProps) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
      position={Position.RIGHT}
      size={DrawerSize.SMALL}
      lazy
      usePortal
    >
      {shipmentId && (
        <ShipmentDetails shipmentId={shipmentId} onClose={onClose} />
      )}
    </Drawer>
  );
}
