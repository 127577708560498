import { Classes, Dialog, DialogProps } from "@blueprintjs/core";
import { CreateQuote } from "./CreateQuote";

type CreateQuoteDialogProps = DialogProps & {};

export function CreateQuoteDialog({ ...dialogProps }: CreateQuoteDialogProps) {
  return (
    <Dialog
      title="Create Quote"
      {...dialogProps}
      lazy
      usePortal
      style={{ paddingBottom: 0 }}
    >
      <div className={Classes.DIALOG_BODY}>
        <CreateQuote
          onClose={() => {
            if (dialogProps.onClose) dialogProps.onClose(null!);
          }}
        />
      </div>
    </Dialog>
  );
}
