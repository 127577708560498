import {
  ProductSellPriceMultiplier,
  UpdateProductSellPriceMultiplierPayload,
} from "../../../types/ApiTypes";
import React, { useCallback, useEffect, useState } from "react";
import { Button, FormGroup, Intent, NumericInput } from "@blueprintjs/core";

type ProductOverrideItemProps = {
  productSellPriceMultiplier: ProductSellPriceMultiplier;
  onChange: (payload: UpdateProductSellPriceMultiplierPayload) => void;
  onRemove: () => void;
  loading: boolean;
};

export function ProductOverrideItem({
  productSellPriceMultiplier,
  loading,
  onChange,
  onRemove,
}: ProductOverrideItemProps) {
  const [sellPriceMultiplier, setSellPriceMultiplier] = useState(
    productSellPriceMultiplier.sellPriceMultiplier.toString()
  );
  const [defaultGrossProfit, setDefaultGrossProfit] = useState(
    productSellPriceMultiplier.defaultGrossProfit.toString()
  );
  const [discount, setDiscount] = useState(
    productSellPriceMultiplier.discount.toString()
  );

  useEffect(() => {
    setSellPriceMultiplier(
      productSellPriceMultiplier.sellPriceMultiplier.toString()
    );
    setDefaultGrossProfit(
      productSellPriceMultiplier.defaultGrossProfit.toString()
    );
    setDiscount(productSellPriceMultiplier.discount.toString());
  }, [
    productSellPriceMultiplier.sellPriceMultiplier,
    productSellPriceMultiplier.defaultGrossProfit,
    productSellPriceMultiplier.discount,
  ]);

  const resetChanges = useCallback(() => {
    setSellPriceMultiplier(
      productSellPriceMultiplier.sellPriceMultiplier.toString()
    );
    setDefaultGrossProfit(
      productSellPriceMultiplier.defaultGrossProfit.toString()
    );
    setDiscount(productSellPriceMultiplier.discount.toString());
  }, [
    setSellPriceMultiplier,
    setDefaultGrossProfit,
    setDiscount,
    productSellPriceMultiplier.sellPriceMultiplier,
    productSellPriceMultiplier.defaultGrossProfit,
    productSellPriceMultiplier.discount,
  ]);

  const saveChanges = useCallback(() => {
    onChange({
      id: productSellPriceMultiplier.id,
      defaultGrossProfit: parseFloat(defaultGrossProfit),
      sellPriceMultiplier: parseFloat(sellPriceMultiplier),
      discount: parseFloat(discount),
    });
  }, [
    sellPriceMultiplier,
    defaultGrossProfit,
    discount,
    productSellPriceMultiplier.id,
    onChange,
  ]);

  const dirty =
    sellPriceMultiplier !==
      productSellPriceMultiplier.sellPriceMultiplier.toString() ||
    defaultGrossProfit !==
      productSellPriceMultiplier.defaultGrossProfit.toString() ||
    discount !== productSellPriceMultiplier.discount.toString();

  return (
    <div className="border rounded-md bg-white flex items-stretch divide-x">
      <div className="p-2 w-96">
        <h1 className="text-sm m-0 text-indigo-600 font-medium">
          {productSellPriceMultiplier.product.code}
        </h1>
        <p className="text-sm m-0 text-gray-600 text-ellipsis overflow-hidden truncate">
          {productSellPriceMultiplier.product.description}
        </p>
      </div>
      <div className="flex items-center p-2">
        <FormGroup label="Sell Price Multiplier" style={{ margin: 0 }}>
          <NumericInput
            value={sellPriceMultiplier}
            onValueChange={(e, v) => setSellPriceMultiplier(v)}
            stepSize={0.01}
            minorStepSize={0.01}
            majorStepSize={0.1}
            min={0}
          />
        </FormGroup>
      </div>
      <div className="flex items-center p-2">
        <FormGroup label="Sell Price Multiplier" style={{ margin: 0 }}>
          <NumericInput
            value={discount}
            onValueChange={(e, v) => setDiscount(v)}
            stepSize={0.01}
            minorStepSize={0.01}
            majorStepSize={0.1}
            min={0}
          />
        </FormGroup>
      </div>
      <div className="flex items-center p-2">
        <FormGroup label="Sell Price Multiplier" style={{ margin: 0 }}>
          <NumericInput
            value={defaultGrossProfit}
            onValueChange={(e, v) => setDefaultGrossProfit(v)}
            stepSize={0.01}
            minorStepSize={0.01}
            majorStepSize={0.1}
            min={0}
          />
        </FormGroup>
      </div>

      <div className="flex flex-1 p-2 justify-end items-start space-x-2">
        <Button
          text="Remove Override"
          disabled={loading}
          intent={Intent.DANGER}
          onClick={onRemove}
          icon="cross"
          minimal
        />
        {dirty && (
          <>
            <Button
              text="Reset"
              disabled={loading}
              onClick={resetChanges}
              minimal
            />
            <Button
              text="Save Changes"
              intent={Intent.PRIMARY}
              loading={loading}
              disabled={loading}
              onClick={saveChanges}
            />
          </>
        )}
      </div>
    </div>
  );
}
