import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  Icon,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { PurchaseOrder } from "../../../../types/ApiTypes";
import { useApiError } from "../../../../hooks/useApiError";
import { DefaultToaster } from "../../../shared/DefaultToaster/DefaultToaster";

const GET_PURCHASE_ORDER_DETAILS = gql`
  query GetPurchaseOrderDetails($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      quickbooksPurchaseOrderId
      code
    }
  }
`;

const EXPORT_PURCHASE_ORDER = gql`
  mutation ExportPurchaseOrder($purchaseOrderId: Int!) {
    exportPurchaseOrderToQuickbooksEnterprise(
      purchaseOrderId: $purchaseOrderId
    ) {
      purchaseOrderId
    }
  }
`;

type ExportPurchaseOrderToQuickbooksDialogProps = DialogProps & {
  purchaseOrderId: number;
};

export function ExportPurchaseOrderToQuickbooksDialog({
  purchaseOrderId,
  ...dialogProps
}: ExportPurchaseOrderToQuickbooksDialogProps) {
  const handleExportError = useApiError("export quote");

  const getPurchaseOrderDetails = useQuery<
    { purchaseOrders: PurchaseOrder[] },
    { purchaseOrderId: number }
  >(GET_PURCHASE_ORDER_DETAILS, {
    variables: {
      purchaseOrderId,
    },
  });

  const [exportPurchaseOrder, { loading: exportingPurchaseOrder }] =
    useMutation<{ id: number }, { purchaseOrderId: number }>(
      EXPORT_PURCHASE_ORDER,
      {
        onError: handleExportError,
        refetchQueries: [
          {
            query: GET_PURCHASE_ORDER_DETAILS,
            variables: { purchaseOrderId: purchaseOrderId },
          },
        ],
      }
    );

  async function exportPurchaseOrderToQuickbooks() {
    await exportPurchaseOrder({ variables: { purchaseOrderId } });
    DefaultToaster.show({
      icon: "tick-circle",
      intent: "success",
      message: "Purchase Order exported to Quickbooks successfully!",
    });
  }

  return (
    <Dialog title="Export Purchase Order to Quickbooks" {...dialogProps}>
      {getPurchaseOrderDetails.loading && (
        <div className="h-40 flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!getPurchaseOrderDetails.loading && (
        <>
          <div className={Classes.DIALOG_BODY}>
            <h4 className="font-medium uppercase tracking-wide text-gray-500 mb-2">
              Export Status
            </h4>
            {getPurchaseOrderDetails.data?.purchaseOrders[0]
              .quickbooksPurchaseOrderId ? (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getPurchaseOrderDetails.data?.purchaseOrders[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-700">
                        PO #
                        {
                          getPurchaseOrderDetails.data?.purchaseOrders[0]
                            .quickbooksPurchaseOrderId
                        }
                      </p>
                    </div>
                    <Icon icon="tick-circle" className="text-green-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getPurchaseOrderDetails.data?.purchaseOrders[0].code} has
                  been exported as PO #
                  {
                    getPurchaseOrderDetails.data?.purchaseOrders[0]
                      .quickbooksPurchaseOrderId
                  }
                  . If you need to re-export this purchase order, please contact
                  support.
                </p>
              </div>
            ) : (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getPurchaseOrderDetails.data?.purchaseOrders[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center opacity-50">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-500">Not Exported</p>
                    </div>
                    <Icon icon="help" className="text-gray-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getPurchaseOrderDetails.data?.purchaseOrders[0].code} has not
                  been exported. To export this purchase order to Quickbooks,
                  click the button below.
                </p>
              </div>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!getPurchaseOrderDetails.data?.purchaseOrders[0]
                .quickbooksPurchaseOrderId ? (
                <>
                  <Button
                    text="Cancel"
                    onClick={dialogProps.onClose}
                    disabled={exportingPurchaseOrder}
                  />
                  <Button
                    text={
                      exportingPurchaseOrder
                        ? "Exporting..."
                        : "Export to Quickbooks"
                    }
                    intent={Intent.PRIMARY}
                    onClick={exportPurchaseOrderToQuickbooks}
                    disabled={exportingPurchaseOrder}
                  />
                </>
              ) : (
                <Button
                  text="Close"
                  onClick={dialogProps.onClose}
                  disabled={exportingPurchaseOrder}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
}
