import { CatalogProduct } from "../../../types/ApiTypes";
import { gql } from "@apollo/client";
import { Position, Tooltip } from "@blueprintjs/core";
import {
  CatalogItemType,
  getCatalogImageUrl,
} from "../../../helpers/CatalogHelper";

export const PRODUCT_INFO_PRODUCT_FRAGMENT = gql`
  fragment ProductInfoProductFragment on Product {
    id
    description
    catalog {
      id
      manufacturer {
        id
        name
        code
      }
    }
  }
`;

type ProductInfoProps = {
  catalogProduct: CatalogProduct;
};

export function ProductInfo({ catalogProduct }: ProductInfoProps) {
  return (
    <div className="p-2 flex flex-row items-start space-x-2">
      <img
        className="w-36 h-36 rounded overflow-hidden bg-white ring-1 ring-gray-200"
        src={getCatalogImageUrl(catalogProduct.id, CatalogItemType.Product)}
      />
      <div className="flex-1 flex flex-col space-y-2">
        <div className="flex items-center">
          <h1 className="m-0 text-xl flex-1">{catalogProduct.code}</h1>
          <Tooltip
            position={Position.BOTTOM}
            content={catalogProduct.catalog!.manufacturer.name}
          >
            <p className="cursor-default text-sm font-bold uppercase m-0 px-2 py-1 bg-indigo-200 text-indigo-600 rounded">
              {catalogProduct.catalog!.manufacturer.code}
            </p>
          </Tooltip>
        </div>
        <div className="flex-1 p-2 rounded bg-white shadow ring-1 ring-gray-200">
          <p className="text-sm m-0">{catalogProduct.description}</p>
        </div>
      </div>
    </div>
  );
}
