import { SIFEntry } from "../hooks/useDecodeSifLineItems";

export function formatPrice(price: number) {
  return new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: "currency",
  }).format(price / 100);
}

export function encodePriceAsInteger(price: number): number {
  return parseInt((parseFloat(price.toFixed(2)) * 100).toFixed(0));
}

export function calculateSIFSalesPrice(entry: SIFEntry): number {
  const { purchasePrice, marginPercent } = entry;
  const marginInverse = 1 - marginPercent / 100;
  return Math.round(purchasePrice / marginInverse);
}
