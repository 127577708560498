import {
  CalculateConfigurationPricePayloadInput,
  CatalogProduct,
  ProductAndOptionPricingResult,
  ProductConfiguration,
} from "../types/ApiTypes";
import {
  EditableProductConfiguration,
  EditableProductFeature,
} from "../components/shared/LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import { SIFEntry, SIFEntryFeature } from "../hooks/useDecodeSifLineItems";
import * as _ from "lodash";
import { ApolloClient, gql } from "@apollo/client";

export enum CatalogItemType {
  Product,
  Option,
}

const GetCatalogProductInfo = gql`
  query GetCatalogProductInfo(
    $productId: Int!
    $calculateProductAndOptionPricingPayload: CalculateProductAndOptionPricingPayloadInput
  ) {
    catalogProducts(where: { id: { eq: $productId } }) {
      id
      taxCode
      optionGroups {
        id
      }
      vendor {
        id
        listPriceMultiplier
        grossProfitPercent
      }
    }
    calculateProductAndOptionPricing(
      payload: $calculateProductAndOptionPricingPayload
    ) {
      totalListPrice
    }
  }
`;

export async function getCatalogProductSuggestedPricingInfo(
  configuration: EditableProductConfiguration,
  apolloClient: ApolloClient<object>
) {
  const response = await apolloClient.query<
    {
      catalogProducts: CatalogProduct[];
      calculateProductAndOptionPricing: ProductAndOptionPricingResult;
    },
    {
      productId: number;
      calculateProductAndOptionPricingPayload: CalculateConfigurationPricePayloadInput;
    }
  >({
    query: GetCatalogProductInfo,
    variables: {
      productId: configuration.catalogProductId!,
      calculateProductAndOptionPricingPayload: {
        catalogProductId: configuration.catalogProductId!,
        catalogOptionIds: _.flatten(
          configuration.features.map((f) =>
            f.selections.map((s) => s.catalogOptionId!)
          )
        ).filter((i) => !!i),
      },
    },
  });

  return {
    suggestedListPriceMultiplier:
      response.data.catalogProducts[0].vendor.listPriceMultiplier,
    suggestedGrossProfitPercent:
      response.data.catalogProducts[0].vendor.grossProfitPercent,
    totalListPrice:
      response.data.calculateProductAndOptionPricing.totalListPrice,
  };
}

export function getCatalogImageUrl(
  catalogItemId: number,
  catalogItemType: CatalogItemType
) {
  switch (catalogItemType) {
    case CatalogItemType.Product:
      return `https://storage.googleapis.com/wof-catalog-images/product-${catalogItemId}.jpg`;
    case CatalogItemType.Option:
      return `https://storage.googleapis.com/wof-catalog-images/option-${catalogItemId}.jpg`;
  }
}

export const GetFullConfigurationCodeFragment = gql`
  fragment GetFullConfigurationCodeFragment on ProductConfiguration {
    isCustomConfiguration
    customCode
    catalogProductId
    catalogProduct {
      id
      code
    }
    features {
      id
      selections {
        id
        catalogOption {
          id
          code
        }
      }
    }
  }
`;

export function getFullConfigurationCode(configuration: ProductConfiguration) {
  if (configuration.isCustomConfiguration) {
    return configuration.customCode ?? "";
  }

  if (!configuration.catalogProductId) {
    throw new Error(
      "Product Configuration is not marked as custom, but does not contain a CatalogProductId."
    );
  }

  return [
    configuration.catalogProduct!.code,
    ...configuration.features.map((feature) =>
      feature.selections
        .map((selection) => {
          return selection.catalogOption == null
            ? "..."
            : selection.catalogOption.code ?? "TBD";
        })
        .join("\n")
    ),
  ].join("\n");
}

export const GetFullConfigurationDescriptionFragment = gql`
  fragment GetFullConfigurationDescriptionFragment on ProductConfiguration {
    isCustomConfiguration
    customDescription
    catalogProductId
    catalogProduct {
      id
      description
    }
    features {
      id
      selections {
        id
        catalogOption {
          id
          code
          description
        }
        catalogOptionGroup {
          id
          description
        }
      }
    }
  }
`;

export function getFullConfigurationDescription(
  configuration: ProductConfiguration
) {
  if (configuration.isCustomConfiguration) {
    return configuration.customDescription ?? "";
  }

  if (!configuration.catalogProductId) {
    throw new Error(
      "Product Configuration is not marked as custom, but does not contain a CatalogProductId."
    );
  }

  return [
    configuration.catalogProduct!.description,
    ...configuration.features.map((feature) =>
      feature.selections
        .map((selection) => {
          const optionGroupDescription =
            feature.selections[0].catalogOptionGroup?.description ?? "";

          const optionDescription = selection.catalogOption
            ? selection.catalogOption.description
            : "To Be Determined";

          return [optionGroupDescription, optionDescription].join(": ");
        })
        .join("\n")
    ),
  ].join("\n");
}

export function getFullEditableConfigurationCode(
  configuration: EditableProductConfiguration,
  sifEntry?: SIFEntry
) {
  if (configuration.isCustomConfiguration) {
    return configuration.customCode ?? "";
  }

  function getFeatureCode(
    feature: EditableProductFeature,
    sifEntryFeature?: SIFEntryFeature
  ) {
    return [
      ...feature.selections.map((selection) => selection.catalogOptionCode),
      ...(sifEntryFeature?.unresolvedSelections.map(
        (selection) => selection.optionCode
      ) ?? []),
    ];
  }

  return _.flatten([
    configuration.catalogProductCode,
    ...configuration.features.map((feature, i) =>
      getFeatureCode(
        feature,
        sifEntry?.decodings[0].features.find(
          (f) => f.selections[0].optionGroupId === feature.catalogOptionGroupId
        )
      )
    ),
  ]).join("\n");
}

export function getFullEditableConfigurationDescription(
  configuration: EditableProductConfiguration,
  sifEntry?: SIFEntry
) {
  if (configuration.isCustomConfiguration) {
    return configuration.customDescription ?? "";
  }

  function getFeatureDescription(
    feature: EditableProductFeature,
    sifEntryFeature?: SIFEntryFeature
  ) {
    return [
      ...feature.selections.map((selection) => {
        return [
          feature.description,
          selection.catalogOptionDescription ?? "To Be Determined",
        ].join(": ");
      }),
      ...(sifEntryFeature?.unresolvedSelections.map(
        (selection) =>
          `${selection.optionGroupDescription}: ${selection.optionDescription}`
      ) ?? []),
    ];
  }

  return _.flatten([
    configuration.catalogProductDescription,
    ...configuration.features.map((feature) =>
      getFeatureDescription(
        feature,
        sifEntry?.decodings[0].features.find(
          (f) => f.selections[0].optionGroupId === feature.catalogOptionGroupId
        )
      )
    ),
  ]).join("\n");
}
