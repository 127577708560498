import {
  Button,
  Checkbox,
  FormGroup,
  Intent,
  Popover,
  TextArea,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

export default function FufillmentDateNotes({
  flagged: defaultFlagged,
  body: defaultBody,
  onSubmit,
}: {
  flagged?: boolean;
  body?: string;
  onSubmit: ({ flagged: boolean, body: string }) => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [description, setDescription] = useState(defaultBody ?? "");
  const [isFlagged, setIsFlagged] = useState(defaultFlagged ?? false);

  useEffect(() => {
    setDescription(defaultBody ?? "");
    setIsFlagged(defaultFlagged ?? false);
  }, [defaultBody, defaultFlagged]);

  return (
    <Popover
      position="bottom-left"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <Button
        icon={defaultFlagged ? "issue" : "clipboard"}
        intent={defaultFlagged ? Intent.WARNING : Intent.NONE}
        onClick={() => setIsOpen(true)}
        minimal
      />
      <div>
        <div className="p-2 space-y-2">
          <FormGroup label="Notes">
            <TextArea
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-80"
            />
          </FormGroup>

          <FormGroup>
            <Checkbox
              checked={isFlagged}
              onChange={() => setIsFlagged(!isFlagged)}
              label="Flagged"
            />
          </FormGroup>

          <div className="flex space-x-1">
            <Button
              intent="primary"
              text="Save Notes"
              onClick={() => {
                onSubmit({ flagged: isFlagged, body: description });
                setIsOpen(false);
              }}
            />
            <Button
              intent="none"
              text="Cancel"
              onClick={() => {
                setIsFlagged(defaultFlagged ?? false);
                setDescription(defaultBody ?? "");
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      </div>
    </Popover>
  );
}
