import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export function formatDate(date: Date | string): string {
  if (typeof date == "string") date = new Date(date);
  return dayjs.utc(date).local().format("MM/DD/YYYY");
}

export function formatDateTime(date: Date): string {
  return dayjs.utc(date).local().format("MM/DD/YYYY, h:mm A");
}
