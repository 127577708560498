import React, { useState } from "react";
import {
  PurchaseOrder,
  PurchaseOrderSubmission,
} from "../../../../types/ApiTypes";
import dayjs from "dayjs";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Button, Intent } from "@blueprintjs/core";
import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import { PurchaseOrderSubmissionDialog } from "./PurchaseOrderSubmissionDialog";
import { PurchaseOrderSubmissionResultDialog } from "./PurchaseOrderSubmissionResultDialog";
import { PurchaseOrdersListSubmissionStatusCell } from "./PurchaseOrdersListSubmissionStatusCell";
import { PurchaseOrdersListShippingStatusCell } from "./PurchaseOrdersListShippingStatusCell";
import { PurchaseOrderListReceivingStatusCell } from "./PurchaseOrderListReceivingStatusCell";
import { PurchaseOrderListInspectionStatusCell } from "./PurchaseOrderListInspectionStatusCell";
import { PurchaseOrderListStockingStatusCell } from "./PurchaseOrderListStockingStatusCell";
import { BasicTable } from "../../../shared/Table/BasicTable";

export type SalesOrdersTableProps = {
  purchaseOrders: PurchaseOrder[];
  onRefreshPurchaseOrders: () => void;
};

export function PurchaseOrdersTable({
  purchaseOrders,
  onRefreshPurchaseOrders,
}: SalesOrdersTableProps) {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const [recordSubmissionOpen, setRecordSubmissionOpen] = useState(false);
  const [purchaseOrderToRecord, setPurchaseOrderToRecord] =
    useState<PurchaseOrder>();
  const [submissionResultToRecord, setSubmissionResultToRecord] =
    useState<PurchaseOrderSubmission>();

  return (
    <>
      <PurchaseOrderSubmissionDialog
        projectId={projectId}
        purchaseOrderId={purchaseOrderToRecord?.id}
        isOpen={recordSubmissionOpen}
        onClose={() => {
          setRecordSubmissionOpen(false);
        }}
        onSubmit={() => {
          setRecordSubmissionOpen(false);
          onRefreshPurchaseOrders();
        }}
      />

      <PurchaseOrderSubmissionResultDialog
        projectId={projectId}
        purchaseOrderSubmissionId={submissionResultToRecord?.id}
        isOpen={Boolean(submissionResultToRecord)}
        onClose={() => {
          setSubmissionResultToRecord(undefined);
        }}
        onSubmit={() => {
          setSubmissionResultToRecord(undefined);
          onRefreshPurchaseOrders();
        }}
      />

      <BasicTable>
        <thead>
          <tr>
            <th>PO #</th>
            <th style={{ width: 300 }}>Creation</th>
            <th style={{ width: 300 }}>Submission</th>
            <th style={{ width: 300 }}>Shipping</th>
            <th style={{ width: 300 }}>Receiving</th>
            <th style={{ width: 300 }}>Inspection</th>
            <th style={{ width: 300 }}>Stocking</th>
            <th>
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {purchaseOrders.map((purchaseOrder) => (
            <tr key={purchaseOrder.id}>
              <td>
                <div>
                  <p className="m-0">
                    <Link
                      to={`/projects/${projectId}/purchase-orders/${purchaseOrder.id}`}
                    >
                      <strong>{purchaseOrder.code}</strong>
                    </Link>
                  </p>
                  <p className="m-0 text-gray-400">
                    {purchaseOrder.manufacturer!.name}
                  </p>
                </div>
              </td>
              <td>
                <PurchaseOrderStatusCell
                  text={`Created ${dayjs(purchaseOrder.createdAt).format(
                    "MM/DD/YY"
                  )}`}
                  intent={Intent.SUCCESS}
                />
              </td>
              <td>
                <PurchaseOrdersListSubmissionStatusCell
                  submission={purchaseOrder.mostRecentSubmission}
                  onRecordSubmissionClicked={() => {
                    setPurchaseOrderToRecord(purchaseOrder);
                    setRecordSubmissionOpen(true);
                  }}
                  onRecordConfirmationClicked={() => {
                    setSubmissionResultToRecord(
                      purchaseOrder.mostRecentSubmission
                    );
                  }}
                />
              </td>
              <td>
                <PurchaseOrdersListShippingStatusCell
                  purchaseOrder={purchaseOrder}
                />
              </td>
              <td>
                <PurchaseOrderListReceivingStatusCell
                  purchaseOrderId={purchaseOrder.id}
                  projectId={projectId}
                />
              </td>
              <td>
                <PurchaseOrderListInspectionStatusCell
                  projectId={projectId}
                  purchaseOrderId={purchaseOrder.id}
                />
              </td>
              <td>
                <PurchaseOrderListStockingStatusCell
                  projectId={projectId}
                  purchaseOrderId={purchaseOrder.id}
                />
              </td>
              <td>
                <Button
                  text="View PO"
                  rightIcon="arrow-right"
                  onClick={() =>
                    history.push(
                      `/projects/${projectId}/purchase-orders/${purchaseOrder.id}`
                    )
                  }
                  fill
                />
              </td>
            </tr>
          ))}
        </tbody>
      </BasicTable>
    </>
  );
}
