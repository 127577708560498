import React from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { usePurchaseOrder } from "../../../../../hooks/usePurchaseOrder";
import { useMetadata } from "../../../../../hooks/useMetadata";
import { MetadataItemType, Project } from "../../../../../types/ApiTypes";
import { MetadataEditor } from "../../../../../components/shared/MetadataEditor/MetadataEditor";
import { PurchaseOrderSubnavbar } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderSubnavbar";
import { getVendorName } from "../../../../../helpers/PurchaseOrderHelpers";
import { gql, useQuery } from "@apollo/client";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export function ProjectPurchaseOrderMetadata() {
  const match = useRouteMatch<{ projectId: string; purchaseOrderId: string }>();
  const projectId = parseInt(match.params.projectId);
  const purchaseOrderId = parseInt(match.params.purchaseOrderId);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const { purchaseOrder, loading: purchaseOrderLoading } =
    usePurchaseOrder(purchaseOrderId);

  const {
    metadataItems,
    loading: metadataLoading,
    error: metadataError,
    createItem,
    updateItem,
    removeItem,
  } = useMetadata(MetadataItemType.PurchaseOrder, purchaseOrderId);

  const projectName = getProjectById.loading
    ? `Project #${projectId}`
    : getProjectById.data!.projects[0].name;

  const purchaseOrderName = purchaseOrder
    ? `${purchaseOrder!.code} (${purchaseOrder!.vendor!.name})`
    : "";

  return (
    <>
      <Helmet>
        <title>{`${purchaseOrderName} - ${projectName}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Purchase Orders",
            href: `/projects/${projectId}/purchase-orders`,
          },
          {
            text: purchaseOrderName,
            href: `/projects/${projectId}/purchase-orders/${purchaseOrderId}`,
          },
        ]}
      />

      <PurchaseOrderSubnavbar title="Purchase Order Metadata" />

      <PortalContainer>
        <MetadataEditor
          metadataItems={metadataItems}
          schema={[
            {
              label: "Review Status",
              type: "text",
            },
            {
              label: "Reviewed By",
              type: "text",
            },
            {
              label: "Review Date and Time",
              type: "text",
            },
            {
              label: "Approval Status",
              type: "text",
            },
            {
              label: "Approved By",
              type: "text",
            },
            {
              label: "Approval Date and Time",
              type: "text",
            },
            {
              label: "Cancellation Status",
              type: "text",
            },
            {
              label: "Canceled By",
              type: "text",
            },
            {
              label: "Cancellation Reason",
              type: "text",
            },
            {
              label: "Canceled Date and Time",
              type: "text",
            },
            {
              label: "WOF Account Number",
              type: "text",
            },
            {
              label: "Notes",
              type: "text",
            },
            {
              label: "Pricing Notes",
              type: "text",
            },
            {
              label: "Vendor Notes",
              type: "text",
            },
          ]}
          onCreateItem={(e) => createItem(e)}
          onUpdateItem={(e) => updateItem(e)}
          onRemoveItem={(e) => removeItem(e)}
        />
      </PortalContainer>
    </>
  );
}
