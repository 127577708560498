import React from "react";
import { Contact } from "../../../../types/ApiTypes";
import { Button } from "@blueprintjs/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { BasicTable } from "../../../shared/Table/BasicTable";
import { formatDate } from "../../../../helpers/DateHelper";
import { formatContactName } from "../../../../helpers/ContactHelper";

export type SalesOrdersTableProps = {
  salesOrders: {
    id: number;
    code: string;
    name: string;
    project: {
      id: number;
      primaryContact?: Contact;
    };
    quote: {
      id: number;
      code: string;
    };
    createdAt: string;
  }[];
};

export function SalesOrdersTable({ salesOrders }: SalesOrdersTableProps) {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = match.params.projectId;

  return (
    <BasicTable>
      <thead>
        <tr>
          <th>SO #</th>
          <th>Name</th>
          <th>Primary Contact</th>
          <th>Quote #</th>
          <th>Created</th>
          <th style={{ width: 200 }}>
            <span className="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {salesOrders.map((so) => (
          <tr key={so.id}>
            <td>{so.code}</td>
            <td>{so.name}</td>
            <td>{formatContactName(so.project.primaryContact)}</td>
            <td>{so.quote.code}</td>
            <td>{formatDate(so.createdAt!)}</td>
            <td>
              <Button
                text="View Sales Order"
                rightIcon="arrow-right"
                onClick={() =>
                  history.push(`/projects/${projectId}/sales-orders/${so.id}`)
                }
                small
                fill
              />
            </td>
          </tr>
        ))}
      </tbody>
    </BasicTable>
  );
}
