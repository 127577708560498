import produce from "immer";
import {
  PurchaseOrderEditorActions,
  PurchaseOrderEditorState,
} from "./PurchaseOrderEditorTypes";
import {
  SalesOrderEditorActions,
  SalesOrderEditorState,
} from "../SalesOrderEditor/SalesOrderEditorTypes";

export * from "../LineItemEditor/LineItemEditorActions";

export function addLineItem(
  state: PurchaseOrderEditorState,
  action: PurchaseOrderEditorActions
): PurchaseOrderEditorState {
  if (action.type !== "addLineItem") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups[0].lineItems.push({
      id: undefined,
      sortIndex: state.lineItemGroups[0].lineItems.length,
      quantity: 1,
      lineItemGroupId: 0,
      listPriceMultiplier: 1,
      purchasePrice: 0,
      sellPrice: 0,
      grossProfitPercent: 0,
      taxAmount: 0,
      description: "",
      internalDescription: "",
      isCreated: true,
      isModified: false,
      isRemoved: false,
      modifiedFields: {
        quantity: false,
        listPriceMultiplier: false,
        purchasePrice: false,
        product: false,
        description: false,
        grossProfitPercent: false,
        sellPrice: false,
        tax: false,
      },
    });
  });
}

export function setLineItemPurchasePrice(
  state: PurchaseOrderEditorState,
  action: PurchaseOrderEditorActions
): PurchaseOrderEditorState {
  if (action.type !== "setLineItemPurchasePrice") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups[action.lineItemGroupIndex].lineItems[
      action.lineItemIndex
    ].purchasePrice = action.purchasePrice;
    draft.lineItemGroups[action.lineItemGroupIndex].lineItems[
      action.lineItemIndex
    ].isModified = true;
    draft.lineItemGroups[action.lineItemGroupIndex].lineItems[
      action.lineItemIndex
    ].modifiedFields.purchasePrice = true;
  });
}

export function setRequestedShipDate(
  state: PurchaseOrderEditorState,
  action: PurchaseOrderEditorActions
): PurchaseOrderEditorState {
  if (action.type !== "setRequestedShipDate") return state;

  return produce(state, (draft) => {
    draft.requestedShipDate = action.requestedShipDate;
    draft.quickSettings.isModified = true;
  });
}

export function initializeWithPurchaseOrder(
  state: PurchaseOrderEditorState,
  action: PurchaseOrderEditorActions
): PurchaseOrderEditorState {
  if (action.type !== "initializeWithPurchaseOrder") return state;

  return {
    quickSettings: {
      isModified: false,
      primaryContact: action.purchaseOrder.project.primaryContact,
      billingAddress: action.purchaseOrder.project.billingAddress,
      shipToAddress: action.purchaseOrder.project.shipToAddress,
      salesRepAccount: action.purchaseOrder.project.salesRepAccount,
      requestedFulfillmentDate: action.purchaseOrder.requestedFulfillmentDate
        ? new Date(action.purchaseOrder.requestedFulfillmentDate)
        : undefined,
      requestedFulfillmentDateNotes:
        action.purchaseOrder.requestedFulfillmentDateNotes,
      requestedFulfillmentDateFlagged:
        action.purchaseOrder.requestedFulfillmentDateFlagged,
    },
    requestedShipDate: action.purchaseOrder.requestedShipDate
      ? new Date(action.purchaseOrder.requestedShipDate)
      : undefined,
    purchaseOrderCode: action.purchaseOrder.code,
    selectedItems: [],
    lineItemGroups: [
      {
        id: 0,
        sortIndex: 0,
        name: "Purchase Order Line Items",
        lineItems: [...action.purchaseOrder.lineItems]
          .sort((a, b) => a.sortIndex - b.sortIndex)
          .map((li) => ({
            id: li.id!,
            sortIndex: li.sortIndex,
            lineItemGroupId: 0,
            discountingStructure: li.discountingStructure,
            listPriceMultiplier: li.listPriceMultiplier,
            grossProfitPercent: 0,
            sellPrice: 0,
            purchasePrice: li.purchasePrice,
            quantity: li.quantity,
            description: li.description,
            internalDescription: li.internalDescription,
            taxAmount: 0,
            taxCode: "",
            configuration: li.configuration
              ? {
                  id: li.configuration.id,
                  isCustomConfiguration: li.configuration.isCustomConfiguration,
                  customCode: li.configuration.customCode,
                  customDescription: li.configuration.customDescription,
                  customListPrice: li.configuration.customListPrice,
                  customVendorId: li.configuration.customVendorId,
                  catalogProductCode:
                    li.configuration.catalogProduct?.code ?? "",
                  catalogProductDescription:
                    li.configuration.catalogProduct?.description ?? "",
                  catalogProductId: li.configuration.catalogProductId,
                  features: li.configuration.features.map((feature) => ({
                    catalogOptionGroupId: feature.catalogOptionGroupId,
                    description: feature.catalogOptionGroup.description,
                    selections: feature.selections.map((selection) => ({
                      catalogOptionId: selection.catalogOptionId,
                      catalogOptionCode: selection.catalogOption.code,
                      catalogOptionDescription:
                        selection.catalogOption.description,
                      catalogOptionGroupId: selection.catalogOptionGroupId,
                      catalogOptionGroupCode: selection.catalogOptionGroup.code,
                      catalogOptionGroupDescription:
                        selection.catalogOptionGroup.description,
                    })),
                  })),
                }
              : undefined,
            modifiedFields: {
              product: false,
              description: false,
              listPriceMultiplier: false,
              grossProfitPercent: false,
              sellPrice: false,
              purchasePrice: false,
              quantity: false,
              tax: false,
            },
            isModified: false,
            isCreated: false,
            isRemoved: false,
          })),
        isOpen: true,
        isCreated: false,
        isModified: false,
        isRemoved: false,
        modifiedFields: { name: false },
      },
    ],
  };
}
