import {
  Button,
  HTMLSelect,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import {
  ContactRole,
  UpdateCompanyContactPayload,
} from "../../../types/ApiTypes";

type CompanyContactItemContact = {
  id: number;
  person: {
    id: number;
    firstName: string;
    lastName: string;
  };
  role: ContactRole;
};

export type CompanyContactItemProps = {
  contact: CompanyContactItemContact;
  onRemove: () => void;
  onChange: (payload: UpdateCompanyContactPayload) => void;
};

export function CompanyContactItem({
  contact,
  onChange,
  onRemove,
}: CompanyContactItemProps) {
  const history = useHistory();

  return (
    <div className={"p-2 flex items-center border-b"}>
      <div className="w-10 bg-gray-600 h-10 border-none rounded mr-2 flex items-center justify-center">
        <p className="m-0 font-semibold text-lg text-center text-white">
          {contact.person!.firstName.substring(0, 1)}
        </p>
      </div>
      <div className="flex-1">
        <h3 className="font-semibold m-0">
          {contact.person!.firstName} {contact.person!.lastName}
        </h3>
      </div>
      <HTMLSelect
        value={contact.role!.toString()}
        onChange={(e) =>
          onChange({ id: contact.id, role: e.target.value as ContactRole })
        }
        className="mr-2"
      >
        <option value="" disabled>
          Choose Role
        </option>
        <option value="Primary">Primary</option>
        <option value="Billing">Billing</option>
        <option value="Fulfillment">Fulfillment</option>
        <option value="Other">Other</option>
      </HTMLSelect>
      <Popover position={Position.BOTTOM_RIGHT} minimal>
        <Button minimal icon="more" />
        <Menu>
          <MenuItem
            text="Go to Person"
            icon="person"
            onClick={() =>
              history.push(`/contacts/people/${contact.person!.id}`)
            }
          />
          <MenuDivider />
          <MenuItem
            text="Remove from Company"
            icon="minus"
            intent={Intent.DANGER}
            onClick={() => onRemove()}
          />
        </Menu>
      </Popover>
    </div>
  );
}
