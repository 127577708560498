import { useState } from "react";
import dayjs from "dayjs";

export type CommissionReportItem = {
  customerName: string;
  timePaid: string;
  estimateNumber: string;
  projectTotal: number;
  usedTotal: number;
  internalServiceTotal: number;
  purchaseOrderTotal: number;
  unrecognizedLineItems: string[];
};

export type CommissionReport = {
  salesRepName: string;
  startsAt: string;
  endsAt: string;
  createdAt: string;
  reportItems: CommissionReportItem[];
};

export function useCommissionReport(
  salesperson: string,
  startsAt: Date,
  endsAt: Date
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [report, setReport] = useState<CommissionReport>();

  async function refresh() {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_QUICKBOOKS_API_URL
        }/reports/commission/${salesperson}?startsAt=${dayjs(startsAt).format(
          "MM-DD-YYYY"
        )}&endsAt=${dayjs(endsAt).format("MM-DD-YYYY")}`
      );
      const report = await response.json();
      setReport(report);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { loading, error, report, refresh };
}
