import React from "react";
import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import {
  PurchaseOrderSubmission,
  PurchaseOrderSubmissionResult,
} from "../../../../types/ApiTypes";
import {
  getSubmissionStatusIntent,
  getSubmissionStatusText,
} from "../../../../helpers/PurchaseOrderHelpers";
import { Button, FormGroup, Icon } from "@blueprintjs/core";
import { formatDate } from "../../../../helpers/DateHelper";
import { getAttachmentDownloadUrl } from "../../../../helpers/AttachmentsHelper";

export type PurchaseOrdersListSubmissionStatusCellProps = {
  submission?: PurchaseOrderSubmission;
  onRecordSubmissionClicked: () => void;
  onRecordConfirmationClicked: () => void;
};

export function PurchaseOrdersListSubmissionStatusCell({
  submission,
  onRecordSubmissionClicked,
  onRecordConfirmationClicked,
}: PurchaseOrdersListSubmissionStatusCellProps) {
  const hasSubmission = Boolean(submission);
  const hasConfirmation = Boolean(submission?.result);

  const intent = getSubmissionStatusIntent(submission);
  const text = getSubmissionStatusText(submission);

  const description = !hasSubmission
    ? "Purchase order has not been submitted."
    : submission?.result === null
    ? `Purchase order was submitted on ${new Date(
        submission!.createdAt!
      ).toLocaleDateString()}, and no result has been received yet.`
    : submission?.result === PurchaseOrderSubmissionResult.Confirmed
    ? `Purchase order was submitted on ${formatDate(
        new Date(submission!.createdAt!)
      )}, and confirmed on ${formatDate(
        new Date(submission!.resultReceivedAt!)
      )}.`
    : submission?.result === PurchaseOrderSubmissionResult.ChangesNeeded
    ? `Purchase order was submitted on ${formatDate(
        new Date(submission!.createdAt!)
      )}, and the vendor requested changes on ${formatDate(
        new Date(submission!.resultReceivedAt!)
      )}.`
    : submission?.result === PurchaseOrderSubmissionResult.ErrorWhenSending
    ? `Purchase order was submitted unsuccessfully on ${formatDate(
        new Date(submission!.createdAt!)
      )}, as an error occurred during sending.`
    : "Internal error, submission result status is not recognised.";

  const promptForSubmission =
    !hasSubmission ||
    (hasConfirmation &&
      submission?.result !== PurchaseOrderSubmissionResult.Confirmed);
  const promptForConfirmation = hasSubmission && !hasConfirmation;

  const willShowButtons = promptForSubmission || promptForConfirmation;

  const styling = "mb-2 max-w-sm" + (willShowButtons ? " pb-2 border-b" : "");

  return (
    <PurchaseOrderStatusCell intent={intent} text={text}>
      <div className="p-2">
        <div className={styling}>
          <h3 className="font-bold mb-2">Submission</h3>
          <p>{description}</p>
          {submission?.submissionAttachment && (
            <FormGroup label="Submission Attachment">
              <div className="flex items-center space-x-1">
                <Icon icon="paperclip" />
                <p className="m-0 flex-1">
                  {submission.submissionAttachment.name}
                </p>
                <Button
                  small
                  minimal
                  icon="download"
                  onClick={() =>
                    window.open(
                      getAttachmentDownloadUrl(submission.submissionAttachment!)
                    )
                  }
                />
              </div>
            </FormGroup>
          )}
          {submission?.confirmationAttachment && (
            <FormGroup label="Confirmation Attachment">
              <div className="flex items-center space-x-1">
                <Icon icon="paperclip" />
                <p className="m-0 flex-1">
                  {submission.confirmationAttachment.name}
                </p>
                <Button
                  small
                  minimal
                  icon="download"
                  onClick={() =>
                    window.open(
                      getAttachmentDownloadUrl(
                        submission.confirmationAttachment!
                      )
                    )
                  }
                />
              </div>
            </FormGroup>
          )}
        </div>
        {promptForSubmission && (
          <Button
            fill
            rightIcon="chevron-right"
            text="Record a Submission"
            onClick={onRecordSubmissionClicked}
          />
        )}
        {promptForConfirmation && (
          <Button
            fill
            rightIcon="chevron-right"
            text="Record a Confirmation"
            onClick={onRecordConfirmationClicked}
          />
        )}
      </div>
    </PurchaseOrderStatusCell>
  );
}
