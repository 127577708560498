import {
  Button,
  ButtonProps,
  Classes,
  Dialog,
  DialogProps,
  InputGroup,
  Spinner,
} from "@blueprintjs/core";
import { AddAddressForm } from "./AddAddressForm";
import { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Address, CreateAddressPayloadInput } from "../../../types/ApiTypes";
import { formatAddress } from "../../../helpers/AddressHelper";

function AddressButton({
  address,
  ...buttonProps
}: ButtonProps & { address: Address }) {
  return (
    <Button fill alignText="left" minimal {...buttonProps}>
      <span
        dangerouslySetInnerHTML={{
          __html: formatAddress(address).replaceAll("\n", "<br />"),
        }}
      />
    </Button>
  );
}

const CreateAddress = gql`
  mutation CreateAddress($payload: CreateAddressPayloadInput!) {
    createAddress(payload: $payload) {
      id
      street1
      street2
      city
      state
      postalCode
    }
  }
`;

type SearchAddressesResult = {
  addresses: Address[];
};

const SearchAddresses = gql`
  query SearchAddress($query: String!) {
    addresses(
      where: {
        or: [
          { street1: { contains: $query } }
          { street2: { contains: $query } }
          { city: { contains: $query } }
          { state: { contains: $query } }
          { postalCode: { contains: $query } }
        ]
      }
    ) {
      id
      street1
      street2
      city
      state
      postalCode
    }
  }
`;

type ChooseAddressDialogProps = DialogProps & {
  suggestedAddresses?: Address[];
  onChoose: (value: Address) => void;
};

export function ChooseAddressDialog({
  suggestedAddresses,
  onChoose,
  ...dialogProps
}: ChooseAddressDialogProps) {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const searchAddresses = useQuery<SearchAddressesResult, { query: string }>(
    SearchAddresses,
    {
      variables: { query: searchQuery },
      skip: searchQuery.length === 0,
    }
  );

  const [createAddress] = useMutation<
    { createAddress: Address },
    { payload: CreateAddressPayloadInput }
  >(CreateAddress, {
    refetchQueries: ["SearchAddresses", "GetAddresses"],
  });

  return (
    <>
      <Dialog
        title="Add an Address"
        isOpen={isAddOpen && dialogProps.isOpen}
        onClose={() => setIsAddOpen(false)}
        lazy
        usePortal
      >
        <div className={Classes.DIALOG_BODY} style={{ marginBottom: 0 }}>
          <AddAddressForm
            onCreateAddress={async (address) => {
              const result = await createAddress({
                variables: {
                  payload: {
                    street1: address.street1,
                    street2: address.street2,
                    city: address.city,
                    state: address.state,
                    postalCode: address.postalCode,
                  },
                },
              });

              onChoose(result.data!.createAddress);
            }}
          />
        </div>
      </Dialog>

      <Dialog
        title="Choose an Address"
        isOpen={!isAddOpen && dialogProps.isOpen}
        onClose={dialogProps.onClose}
        lazy
        usePortal
      >
        <div className={Classes.DIALOG_BODY} style={{ marginBottom: 0 }}>
          <div className="space-y-2">
            <div className="space-x-2 flex items-center">
              <InputGroup
                type="search"
                leftIcon="search"
                placeholder="Search for an address..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex-1"
                autoFocus
              />
              <Button
                icon="plus"
                text="New Address"
                onClick={() => setIsAddOpen(true)}
              />
            </div>

            {searchQuery.length > 0 && (
              <>
                <h4 className="font-medium">Search Results</h4>
                <div className="bg-white rounded p-1 divide-y">
                  {searchAddresses.loading && <Spinner />}

                  {!searchAddresses.loading &&
                    searchAddresses.data!.addresses.length > 0 &&
                    searchAddresses.data?.addresses.map((address) => (
                      <AddressButton
                        key={address.id}
                        address={address}
                        onClick={() => onChoose(address)}
                      />
                    ))}

                  {!searchAddresses.loading &&
                    searchAddresses.data!.addresses.length === 0 && (
                      <div className="text-center p-2">
                        <p className="text-gray-500 m-0">No addresses found.</p>
                        <a
                          href="javascript:void(0)"
                          onClick={() => setIsAddOpen(true)}
                        >
                          Create New
                        </a>
                      </div>
                    )}
                </div>
              </>
            )}

            {suggestedAddresses && suggestedAddresses.length > 0 && (
              <>
                <h4 className="font-medium">Suggested Addresses</h4>

                <div className="bg-white rounded p-1 divide-y">
                  {suggestedAddresses!.map((address) => (
                    <AddressButton
                      key={address.id}
                      address={address}
                      onClick={() => onChoose(address)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}
