import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import {
  ProductConfiguration,
  Project,
  UpdatePrepTicketLineItemPayload,
} from "../../../../../types/ApiTypes";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormGroup,
  Menu,
  MenuItem,
  NumericInput,
  Popover,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
} from "../../../../../helpers/CatalogHelper";
import { TableHeader } from "../../../../../components/shared/Table/TableHeader";
import { TableFooter } from "../../../../../components/shared/Table/TableFooter";
import { AddPrepTicketLineItemDrawer } from "./AddPrepTicketLineItemDrawer";
import { DeleteConfirmationDialog } from "../../../../../components/shared/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { DefaultToaster } from "../../../../../components/shared/DefaultToaster/DefaultToaster";
import { PrepItemDialog } from "./PrepItemDialog";
import { QuickSettings } from "../../../../../components/shared/QuickSettings/QuickSettings";
import { QuickSettingItem } from "../../../../../components/shared/QuickSettings/QuickSettingItem";

const CreateEOD = gql`
  mutation CreateEOD($payload: CreateEODPayloadInput!) {
    createEOD(payload: $payload) {
      id
    }
  }
`;

type CreateEODResult = {
  createEOD: {
    id: number;
  };
};

type CreateEODParams = {
  payload: {
    projectId: number;
    title?: string;
    description?: string;
  };
};

const AttachPrepTicketToEOD = gql`
  mutation AttachPrepTicketToEOD(
    $eodId: Int!
    $prepTicketId: Int!
    $lineItemIds: [Int!]!
  ) {
    attachPrepTicketToEOD(
      eodId: $eodId
      prepTicketId: $prepTicketId
      lineItemIds: $lineItemIds
    ) {
      id
    }
  }
`;

type AttachPrepTicketToEODResult = {
  attachPrepTicketToEOD: {
    id: number;
  };
};

type AttachPrepTicketToEODParams = {
  eodId: number;
  prepTicketId: number;
  lineItemIds: number[];
};

const UpdatePrepTicket = gql`
  mutation UpdatePrepTicket($payload: UpdatePrepTicketPayloadInput!) {
    updatePrepTicket(payload: $payload) {
      id
    }
  }
`;

type UpdatePrepTicketResult = {
  updatePrepTicket: {
    id: number;
  };
};

type UpdatePrepTicketParams = {
  payload: {
    id: number;
    estimatedHours?: number;
  };
};

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const GetPrepTicketById = gql`
  ${GetFullConfigurationCodeFragment}

  query GetPrepTicketById($prepTicketId: Int!) {
    prepTickets(where: { id: { eq: $prepTicketId } }) {
      id
      estimatedHours
      lineItems {
        id
        isPrepped
        inventoryItem {
          id
          configuration {
            id
            ...GetFullConfigurationCodeFragment
          }
          purchaseOrderShipmentLineItem {
            id
            purchaseOrderShipment {
              id
              purchaseOrder {
                id
                code
                projectId
                salesOrder {
                  id
                  code
                  projectId
                }
              }
            }
          }
        }
      }
    }
  }
`;

type GetPrepTicketByIdResult = {
  prepTickets: {
    id: number;
    estimatedHours?: number;
    lineItems: {
      id: number;
      isPrepped: boolean;
      inventoryItem: {
        id: number;
        configuration: ProductConfiguration;
        purchaseOrderShipmentLineItem: {
          id: number;
          purchaseOrderShipment: {
            id: number;
            purchaseOrder: {
              id: number;
              code: string;
              projectId: string;
              salesOrder: {
                id: number;
                code: string;
                projectId: string;
              };
            };
          };
        };
      };
    }[];
  }[];
};

const UpdatePrepTicketLineItem = gql`
  mutation UpdatePrepTicketLineItem(
    $input: UpdatePrepTicketLineItemPayloadInput!
  ) {
    updatePrepTicketLineItem(payload: $input) {
      id
    }
  }
`;

const DeletePrepTicketLineItem = gql`
  mutation DeletePrepTicketLineItem($id: Int!) {
    deletePrepTicketLineItem(id: $id) {
      id
    }
  }
`;

export default function PrepTicketDetails() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string; prepTicketId: string }>();
  const projectId = parseInt(match.params.projectId);
  const prepTicketId = parseInt(match.params.prepTicketId);

  const [addLineItemsOpen, setAddLineItemsOpen] = useState(false);
  const [lineItemToRemove, setLineItemToRemove] =
    useState<GetPrepTicketByIdResult["prepTickets"][0]["lineItems"][0]>();
  const [lineItemToPrep, setLineItemToPrep] =
    useState<GetPrepTicketByIdResult["prepTickets"][0]["lineItems"][0]>();
  const [estimatedHours, setEstimatedHours] = useState<string>("");
  const [selectedUnpreppedLineItems, setSelectedUnpreppedLineItems] = useState<
    GetPrepTicketByIdResult["prepTickets"][0]["lineItems"][0][]
  >([]);
  const [selectedPreppedLineItems, setSelectedPreppedLineItems] = useState<
    GetPrepTicketByIdResult["prepTickets"][0]["lineItems"][0][]
  >([]);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GetProjectById, {
    variables: {
      projectId,
    },
  });

  const getPrepTicketById = useQuery<
    GetPrepTicketByIdResult,
    { prepTicketId: number }
  >(GetPrepTicketById, {
    variables: { prepTicketId },
  });

  const [updatePrepTicket] = useMutation<
    UpdatePrepTicketResult,
    UpdatePrepTicketParams
  >(UpdatePrepTicket, {
    refetchQueries: [GetPrepTicketById],
  });

  const [updatePrepTicketLineItem] = useMutation<
    { id: number },
    { input: UpdatePrepTicketLineItemPayload }
  >(UpdatePrepTicketLineItem, {
    refetchQueries: [GetPrepTicketById],
  });

  const [deletePrepTicketLineItem] = useMutation<
    { id: number },
    { id: number }
  >(DeletePrepTicketLineItem, {
    refetchQueries: [GetPrepTicketById],
  });

  const [createEOD] = useMutation<CreateEODResult, CreateEODParams>(CreateEOD);

  const [attachFulfillmentToEOD] = useMutation<
    AttachPrepTicketToEODResult,
    AttachPrepTicketToEODParams
  >(AttachPrepTicketToEOD);

  async function removeMultipleLineItems(lineItemIds: number[]) {
    await Promise.all(
      lineItemIds.map((lineItemId) =>
        deletePrepTicketLineItem({
          variables: {
            id: lineItemId,
          },
        })
      )
    );

    await getPrepTicketById.refetch();
  }

  async function createEODFromLineItems(lineItemIds: number[]) {
    const createEODResult = await createEOD({
      variables: {
        payload: {
          projectId,
          title: `Issue with Prep Ticket PT-${prepTicketId}`,
        },
      },
    });

    const createdEODId = createEODResult.data!.createEOD.id;

    await attachFulfillmentToEOD({
      variables: {
        eodId: createdEODId,
        prepTicketId,
        lineItemIds,
      },
    });

    history.push(`/eods/${createdEODId}`);
  }

  const prepTicket = getPrepTicketById.data?.prepTickets[0];
  const unpreppedLineItems =
    prepTicket?.lineItems.filter((li) => !li.isPrepped) ?? [];
  const preppedLineItems =
    prepTicket?.lineItems.filter((li) => li.isPrepped) ?? [];

  useEffect(() => {
    setEstimatedHours(prepTicket?.estimatedHours?.toString() ?? "");
  }, [prepTicket?.estimatedHours]);

  return (
    <>
      <PrepItemDialog
        isOpen={!!lineItemToPrep}
        onClose={() => setLineItemToPrep(undefined)}
        onConfirm={async () => {
          await updatePrepTicketLineItem({
            variables: {
              input: {
                prepTicketLineItemId: lineItemToPrep!.id,
                isPrepped: true,
              },
            },
          });

          setLineItemToPrep(undefined);

          DefaultToaster.show({
            message: "Line item prepped!",
            icon: "tick-circle",
            intent: "success",
          });
        }}
      />

      <DeleteConfirmationDialog
        isOpen={!!lineItemToRemove}
        title={`Remove INV-${lineItemToRemove?.inventoryItem.id} From Prep Ticket`}
        message={`Are you sure you want to remove INV-${lineItemToRemove?.inventoryItem.id} from the prep ticket? It will need to be re-added to another prep ticket and re-prepped.`}
        onConfirm={async () => {
          await deletePrepTicketLineItem({
            variables: { id: lineItemToRemove!.id },
          });

          setLineItemToRemove(undefined);

          DefaultToaster.show({
            message: "Line item removed from prep ticket!",
            icon: "tick-circle",
            intent: "success",
          });
        }}
        onClose={() => setLineItemToRemove(undefined)}
      />

      <AddPrepTicketLineItemDrawer
        isOpen={addLineItemsOpen}
        onClose={() => setAddLineItemsOpen(false)}
        projectId={projectId}
        prepTicketId={prepTicketId}
      />

      <Helmet>
        <title>{`Prep Ticket ${prepTicketId} - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Prep Tickets",
            href: `/projects/${projectId}/prep-tickets`,
          },
          {
            text: `Prep Ticket #${prepTicketId}`,
            href: `/projects/${projectId}/prep-tickets/${prepTicketId}`,
          },
        ]}
      />

      <QuickSettings>
        <FormGroup label="Estimated Hours">
          <QuickSettingItem>
            <NumericInput
              value={estimatedHours}
              onValueChange={(e, v) => setEstimatedHours(v)}
              onBlur={async () => {
                if (isNaN(parseFloat(estimatedHours))) return;

                await updatePrepTicket({
                  variables: {
                    payload: {
                      id: prepTicketId,
                      estimatedHours: parseFloat(estimatedHours),
                    },
                  },
                });
              }}
              onButtonClick={async (e, v) => {
                if (isNaN(parseFloat(v))) return;

                await updatePrepTicket({
                  variables: {
                    payload: {
                      id: prepTicketId,
                      estimatedHours: parseFloat(v),
                    },
                  },
                });
              }}
              intent={isNaN(parseFloat(estimatedHours)) ? "danger" : "none"}
            />
          </QuickSettingItem>
        </FormGroup>
      </QuickSettings>

      <PortalContainer>
        {getPrepTicketById.loading && <Spinner />}

        {!getPrepTicketById.loading && (
          <div className="space-y-4">
            <div>
              <TableHeader className="p-3">
                <h2 className="text-sm font-medium">Un-prepped Items</h2>
              </TableHeader>

              <BasicTable header footer>
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>
                      <Checkbox
                        style={{ margin: 0 }}
                        checked={
                          selectedUnpreppedLineItems.length > 0 &&
                          selectedUnpreppedLineItems.length ===
                            unpreppedLineItems.length
                        }
                        indeterminate={
                          selectedUnpreppedLineItems.length > 0 &&
                          selectedUnpreppedLineItems.length <
                            unpreppedLineItems.length
                        }
                        onChange={(e) => {
                          if ((e.target as any).checked) {
                            setSelectedUnpreppedLineItems(unpreppedLineItems);
                          } else {
                            setSelectedUnpreppedLineItems([]);
                          }
                        }}
                      />
                    </th>
                    <th style={{ width: 250 }}>Is Prepped</th>
                    <th>Inventory Item #</th>
                    <th>Product Code</th>
                    <th>PO #</th>
                    <th>SO #</th>
                    <th style={{ width: 200 }}>
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {unpreppedLineItems.map((lineItem) => (
                    <tr key={lineItem.id}>
                      <td>
                        <Checkbox
                          style={{ margin: 0 }}
                          checked={selectedUnpreppedLineItems.some(
                            (li) => li.id === lineItem.id
                          )}
                          onChange={(e) => {
                            if ((e.target as any).checked) {
                              setSelectedUnpreppedLineItems([
                                ...selectedUnpreppedLineItems,
                                lineItem,
                              ]);
                            } else {
                              setSelectedUnpreppedLineItems(
                                selectedUnpreppedLineItems.filter(
                                  (li) => li.id !== lineItem.id
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Tag fill minimal icon="cross" intent="warning">
                          Inventory Item Not Prepped
                        </Tag>
                      </td>
                      <td>{`INV-${lineItem.inventoryItem.id}`}</td>
                      <td>
                        {getFullConfigurationCode(
                          lineItem.inventoryItem.configuration
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/projects/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.projectId}/purchase-orders/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.id}`}
                        >
                          {
                            lineItem.inventoryItem.purchaseOrderShipmentLineItem
                              .purchaseOrderShipment.purchaseOrder.code
                          }
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/projects/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.salesOrder.projectId}/sales-orders/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.salesOrder.id}`}
                        >
                          {
                            lineItem.inventoryItem.purchaseOrderShipmentLineItem
                              .purchaseOrderShipment.purchaseOrder.salesOrder
                              .code
                          }
                        </Link>
                      </td>
                      <td>
                        <ButtonGroup fill>
                          <Button
                            small
                            fill
                            disabled={lineItem.isPrepped}
                            text="Prep Inventory Item"
                            onClick={() => setLineItemToPrep(lineItem)}
                          />
                          <Popover minimal position="bottom">
                            <Button small icon="more" />
                            <Menu>
                              <MenuItem
                                intent="danger"
                                icon="minus"
                                text="Remove from Prep Ticket"
                                onClick={() => setLineItemToRemove(lineItem)}
                              />
                            </Menu>
                          </Popover>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>

              <TableFooter className="p-1 space-x-1">
                <Button
                  icon="plus"
                  text="Add Inventory Item"
                  onClick={() => setAddLineItemsOpen(true)}
                />
                <Button
                  text="Create EOD from Selected"
                  disabled={selectedUnpreppedLineItems.length === 0}
                  onClick={async () => {
                    await createEODFromLineItems(
                      selectedUnpreppedLineItems.map((li) => li.id)
                    );
                    setSelectedUnpreppedLineItems([]);
                  }}
                />
                <Button
                  text="Remove from Prep Ticket"
                  disabled={selectedUnpreppedLineItems.length === 0}
                  onClick={async () => {
                    await removeMultipleLineItems(
                      selectedUnpreppedLineItems.map((li) => li.id)
                    );
                    setSelectedUnpreppedLineItems([]);
                  }}
                />
              </TableFooter>
            </div>

            <div>
              <TableHeader className="p-3">
                <h2 className="text-sm font-medium">Prepped Items</h2>
              </TableHeader>

              <BasicTable header footer>
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>
                      <Checkbox
                        style={{ margin: 0 }}
                        checked={
                          selectedPreppedLineItems.length > 0 &&
                          selectedPreppedLineItems.length ===
                            preppedLineItems.length
                        }
                        indeterminate={
                          selectedPreppedLineItems.length > 0 &&
                          selectedPreppedLineItems.length <
                            preppedLineItems.length
                        }
                        onChange={(e) => {
                          if ((e.target as any).checked) {
                            setSelectedPreppedLineItems(preppedLineItems);
                          } else {
                            setSelectedPreppedLineItems([]);
                          }
                        }}
                      />
                    </th>
                    <th style={{ width: 250 }}>Is Prepped</th>
                    <th>Inventory Item #</th>
                    <th>Product Code</th>
                    <th>PO #</th>
                    <th>SO #</th>
                    <th style={{ width: 20 }}>
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {preppedLineItems.map((lineItem) => (
                    <tr key={lineItem.id}>
                      <td>
                        <Checkbox
                          style={{ margin: 0 }}
                          checked={selectedPreppedLineItems.some(
                            (li) => li.id === lineItem.id
                          )}
                          onChange={(e) => {
                            if ((e.target as any).checked) {
                              setSelectedPreppedLineItems([
                                ...selectedPreppedLineItems,
                                lineItem,
                              ]);
                            } else {
                              setSelectedPreppedLineItems(
                                selectedPreppedLineItems.filter(
                                  (li) => li.id !== lineItem.id
                                )
                              );
                            }
                          }}
                        />
                      </td>
                      <td>
                        <Tag fill minimal icon="tick" intent="success">
                          Inventory Item Prepped
                        </Tag>
                      </td>
                      <td>{`INV-${lineItem.inventoryItem.id}`}</td>
                      <td>
                        {getFullConfigurationCode(
                          lineItem.inventoryItem.configuration
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/projects/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.projectId}/purchase-orders/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.id}`}
                        >
                          {
                            lineItem.inventoryItem.purchaseOrderShipmentLineItem
                              .purchaseOrderShipment.purchaseOrder.code
                          }
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/projects/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.salesOrder.projectId}/sales-orders/${lineItem.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.purchaseOrder.salesOrder.id}`}
                        >
                          {
                            lineItem.inventoryItem.purchaseOrderShipmentLineItem
                              .purchaseOrderShipment.purchaseOrder.salesOrder
                              .code
                          }
                        </Link>
                      </td>
                      <td>
                        <Popover minimal position="bottom">
                          <Button small fill icon="more" />
                          <Menu>
                            <MenuItem
                              intent="danger"
                              icon="minus"
                              text="Remove from Prep Ticket"
                              onClick={() => setLineItemToRemove(lineItem)}
                            />
                          </Menu>
                        </Popover>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>
              <TableFooter className="p-1 space-x-1">
                <Button
                  text="Create EOD from Selected"
                  disabled={selectedPreppedLineItems.length === 0}
                  onClick={async () => {
                    await createEODFromLineItems(
                      selectedPreppedLineItems.map((li) => li.id)
                    );
                    setSelectedPreppedLineItems([]);
                  }}
                />
                <Button
                  text="Remove from Prep Ticket"
                  disabled={selectedPreppedLineItems.length === 0}
                  onClick={async () => {
                    await removeMultipleLineItems(
                      selectedPreppedLineItems.map((li) => li.id)
                    );
                    setSelectedPreppedLineItems([]);
                  }}
                />
              </TableFooter>
            </div>
          </div>
        )}
      </PortalContainer>
    </>
  );
}
