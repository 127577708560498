import {
  Button,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import {
  CompanyType,
  CreateCompanyPayloadInput,
  CreatePersonPayloadInput,
} from "../../../types/ApiTypes";
import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { CreateFulfillmentDialog } from "../CreateFulfillmentDialog/CreateFulfillmentDialog";
import { CreateQuoteDialog } from "../CreateQuoteDialog/CreateQuoteDialog";

const CreateEOD = gql`
  mutation CreateEOD($payload: CreateEODPayloadInput!) {
    createEOD(payload: $payload) {
      id
    }
  }
`;

const CreateCompany = gql`
  mutation CreateCompany($payload: CreateCompanyPayloadInput!) {
    createCompany(payload: $payload) {
      id
    }
  }
`;

const CreatePerson = gql`
  mutation CreatePerson($payload: CreatePersonPayloadInput!) {
    createPerson(payload: $payload) {
      id
    }
  }
`;

export function QuickCreateButton() {
  const history = useHistory();

  const [isCreateQuoteOpen, setIsCreateQuoteOpen] = useState(false);
  const [isCreateFulfillmentOpen, setIsCreateFulfillmentOpen] = useState(false);

  const [createCompany] = useMutation<
    { createCompany: { id: number } },
    { payload: CreateCompanyPayloadInput }
  >(CreateCompany, {
    refetchQueries: ["GetCompanies", "GetVendorCompanies"],
  });

  const [createPerson] = useMutation<
    { createPerson: { id: number } },
    { payload: CreatePersonPayloadInput }
  >(CreatePerson, {
    refetchQueries: ["GetPeople"],
  });

  const [createEOD] = useMutation<
    {
      createEOD: {
        id: string;
      };
    },
    {
      payload: {
        projectId?: number;
        title?: string;
        description?: string;
      };
    }
  >(CreateEOD, {
    refetchQueries: ["GetProjectAndEODs"],
  });

  const addEOD = async () => {
    const result = await createEOD({
      variables: {
        payload: {},
      },
    });
    history.push(`/eods/${result.data!.createEOD.id}`);
  };

  const addPerson = async () => {
    const newPerson = await createPerson({
      variables: {
        payload: {
          firstName: "New",
          lastName: "User",
          email: "",
          phone: "",
        },
      },
    });

    history.push(`/contacts/people/${newPerson.data!.createPerson.id}`);
  };

  const addCompany = useCallback(async () => {
    const newCompany = await createCompany({
      variables: {
        payload: {
          name: "New Company",
          type: CompanyType.Other,
          email: "",
          phone: "",
        },
      },
    });

    history.push(`/contacts/companies/${newCompany.data!.createCompany.id}`);
  }, [createCompany, history]);

  return (
    <>
      <CreateQuoteDialog
        isOpen={isCreateQuoteOpen}
        onClose={() => setIsCreateQuoteOpen(false)}
      />

      <CreateFulfillmentDialog
        isOpen={isCreateFulfillmentOpen}
        onClose={() => setIsCreateFulfillmentOpen(false)}
      />

      <Popover popoverClassName="z-60">
        <Button
          icon="add"
          text="Create New"
          style={{ borderRadius: 20 }}
          intent={Intent.SUCCESS}
        />
        <Menu large>
          <MenuDivider title="Sales" />
          <MenuItem
            icon="tag"
            text="Quote"
            onClick={() => setIsCreateQuoteOpen(true)}
          />
          <MenuItem
            icon="truck"
            text="Fulfillment"
            onClick={() => setIsCreateFulfillmentOpen(true)}
          />
          <MenuItem icon="warning-sign" text="EOD" onClick={addEOD} />
          <MenuDivider title="Contacts" />
          <MenuItem icon="person" text="Person" onClick={addPerson} />
          <MenuItem icon="office" text="Company" onClick={addCompany} />
        </Menu>
      </Popover>
    </>
  );
}
