import {
  Callout,
  Drawer,
  DrawerProps,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { DrawerTitleToolbar } from "../../../../shared/DrawerTitleToolbar/DrawerTitleToolbar";
import { SyntheticEvent } from "react";
import { PrintLabelsPdf } from "./PrintLabelsPdf";
import { PDFViewer } from "@react-pdf/renderer";
import { useInventoryItemLabelsWithQrCodes } from "../../../../../hooks/useInventoryItemLabelsWithQrCodes";

export type PrintLabelsProps = {
  inventoryItemId: number;
  onClose?: React.EventHandler<SyntheticEvent<HTMLElement, Event>>;
};

export function PrintLabels({ inventoryItemId, onClose }: PrintLabelsProps) {
  const { labels, loading, error } =
    useInventoryItemLabelsWithQrCodes(inventoryItemId);

  return (
    <>
      <DrawerTitleToolbar title="Print Labels" onClose={onClose} />

      {loading && (
        <div className="flex items-center justify-center p-2">
          <Spinner />
        </div>
      )}

      {!loading && error && (
        <div className="p-2">
          <Callout
            intent={Intent.DANGER}
            title="Couldn't load inventory item labels"
          >
            We had trouble loading the labels for this inventory item. Please
            try refreshing the page and if the problem persists, contact
            support.
          </Callout>
        </div>
      )}

      {!loading && !error && (
        <PDFViewer width="100%" height="100%">
          <PrintLabelsPdf labels={labels!} />
        </PDFViewer>
      )}
    </>
  );
}

export type PrintLabelsDrawerProps = DrawerProps & {
  inventoryItemId?: number;
};

export function PrintLabelsDrawer({
  inventoryItemId,
  isOpen,
  onClose,
}: PrintLabelsDrawerProps) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} lazy usePortal>
      {inventoryItemId && (
        <PrintLabels onClose={onClose} inventoryItemId={inventoryItemId} />
      )}
    </Drawer>
  );
}
