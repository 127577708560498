import { Button, Icon, InputGroup } from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../../helpers/ContactHelper";
import { useCallback, useState } from "react";
import { formatAccountName } from "../../../helpers/AccountHelper";
import { useHistory } from "react-router-dom";

type CreateFulfillmentParams = {
  projectId: number;
};

type CreateFulfillmentResult = {
  createFulfillment: {
    id: number;
  };
};

const CreateFulfillmentMutation = gql`
  mutation CreateFulfillment($projectId: Int!) {
    createFulfillment(payload: { projectId: $projectId }) {
      id
    }
  }
`;

type GetSalesOrdersResult = {
  salesOrders: {
    id: number;
    code: string;
    project: {
      id: number;
      primaryContact: FormatContactNameContact;
      salesRepAccount: { id: number; name: string };
      createdAt: string;
    };
    createdAt: string;
  }[];
};

const GetSalesOrders = gql`
  ${FormatContactNameContactFragment}

  query GetSalesOrders {
    salesOrders(order: { createdAt: DESC }) {
      id
      code
      project {
        id
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
        salesRepAccount {
          id
          name
        }
        createdAt
      }
      createdAt
    }
  }
`;

export function CreateFulfillment({ onClose }: { onClose: () => void }) {
  const history = useHistory();

  const getSalesOrders = useQuery<GetSalesOrdersResult>(GetSalesOrders, {
    fetchPolicy: "cache-and-network",
  });

  const [createFulfillment] = useMutation<
    CreateFulfillmentResult,
    CreateFulfillmentParams
  >(CreateFulfillmentMutation);

  const [query, setQuery] = useState("");
  const [selectedSalesOrder, setSelectedSalesOrder] =
    useState<GetSalesOrdersResult["salesOrders"][0]>();

  const filterSalesOrders = useCallback(
    (salesOrders: GetSalesOrdersResult["salesOrders"]) => {
      return salesOrders.filter(
        (so) =>
          so.id.toString().includes(query) ||
          formatContactName(so.project.primaryContact)
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          formatAccountName(so.project.salesRepAccount)
            .toLowerCase()
            .includes(query.toLowerCase())
      );
    },
    [query]
  );

  async function createFulfillmentForProject() {
    if (selectedSalesOrder) {
      const result = await createFulfillment({
        variables: {
          projectId: selectedSalesOrder.project.id,
        },
      });

      onClose();

      history.push(
        `/projects/${selectedSalesOrder.project.id}/fulfillments/${result.data?.createFulfillment.id}`
      );
    }
  }

  return (
    <div className="space-y-2">
      <p>Choose a project the fulfillment should belong to...</p>

      <div className="rounded border bg-white divide-y">
        <div className="p-2">
          <InputGroup
            type="search"
            placeholder="Search projects..."
            leftIcon="search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fill
          />
        </div>

        <div className="p-1 overflow-y-auto max-h-52">
          {getSalesOrders.loading && (
            <div className="text-center text-gray-500">Loading...</div>
          )}

          {!getSalesOrders.loading &&
            filterSalesOrders(getSalesOrders.data?.salesOrders ?? []).map(
              (salesOrder) => (
                <Button
                  text={
                    <div className="space-y-1">
                      <p className="m-0 font-medium">{salesOrder.code}</p>

                      <p className="m-0">
                        Primary Contact:{" "}
                        {formatContactName(salesOrder.project.primaryContact)}
                      </p>

                      <p className="m-0">
                        Sales Rep:{" "}
                        {salesOrder.project.salesRepAccount
                          ? formatAccountName(
                              salesOrder.project.salesRepAccount
                            )
                          : "N/A"}
                      </p>
                    </div>
                  }
                  key={salesOrder.id}
                  active={selectedSalesOrder?.id === salesOrder.id}
                  minimal
                  fill
                  alignText="left"
                  onClick={() => setSelectedSalesOrder(salesOrder)}
                />
              )
            )}
        </div>
      </div>

      <div className="flex justify-between items-center">
        {selectedSalesOrder ? (
          <div className="flex items-center space-x-2 text-gray-500">
            <Icon icon="info-sign" />
            <p className="m-0">
              Fulfillment will be created for
              <br />
              sales order <strong>{selectedSalesOrder?.code}</strong> in project{" "}
              <strong>{`P-${selectedSalesOrder?.project.id}`}</strong>.
            </p>
          </div>
        ) : (
          <span>&nbsp;</span>
        )}

        <Button
          intent="primary"
          text="Create Fulfillment"
          onClick={createFulfillmentForProject}
          disabled={!selectedSalesOrder}
        />
      </div>
    </div>
  );
}
