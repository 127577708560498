import {
  EditableSalesOrderLineItem,
  EditableSalesOrderLineItemGroup,
  SalesOrderEditorState,
} from "../reducers/SalesOrderEditor/SalesOrderEditorTypes";
import {
  CommitQuoteDraftPayloadInput,
  CommitSalesOrderDraftPayloadInput,
  CreateSalesOrderLineItemGroupPayloadInput,
  CreateSalesOrderLineItemPayloadInput,
  UpdateSalesOrderLineItemGroupPayloadInput,
  UpdateSalesOrderLineItemPayloadInput,
} from "../../../../types/ApiTypes";
import { QuoteEditorState } from "../reducers/QuoteEditor/QuoteEditorTypes";
import _ from "lodash";
import {
  buildCreateQuoteLineItemGroupPayload,
  buildCreateQuoteLineItemPayload,
  buildUpdateQuoteLineItemGroupPayload,
  buildUpdateQuoteLineItemPayload,
} from "./QuoteEditorHelpers";
import { buildCreateProductConfigurationPayload } from "../reducers/LineItemEditor/LineItemEditorHelpers";

export function buildCreateSalesOrderLineItemGroupPayload(
  lineItemGroup: EditableSalesOrderLineItemGroup
): CreateSalesOrderLineItemGroupPayloadInput {
  return {
    sortIndex: lineItemGroup.sortIndex,
    name: lineItemGroup.name,
    temporaryId: lineItemGroup.id,
  };
}

export function buildUpdateSalesOrderLineItemGroupPayload(
  lineItemGroup: EditableSalesOrderLineItemGroup
): UpdateSalesOrderLineItemGroupPayloadInput {
  return {
    salesOrderLineItemGroupId: lineItemGroup.id,
    sortIndex: lineItemGroup.sortIndex,
    name: lineItemGroup.name,
  };
}

export function buildCreateSalesOrderLineItemPayload(
  lineItem: EditableSalesOrderLineItem
): CreateSalesOrderLineItemPayloadInput {
  return {
    salesOrderLineItemGroupId: lineItem.lineItemGroupId,
    sortIndex: lineItem.sortIndex,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    grossProfitPercent: lineItem.grossProfitPercent,
    sellPrice: Math.round(lineItem.sellPrice),
    taxCode: lineItem.taxCode,
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildUpdateSalesOrderLineItemPayload(
  lineItem: EditableSalesOrderLineItem
): UpdateSalesOrderLineItemPayloadInput {
  return {
    id: lineItem.id!,
    sortIndex: lineItem.sortIndex,
    salesOrderLineItemGroupId: lineItem.lineItemGroupId,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    grossProfitPercent: lineItem.grossProfitPercent,
    sellPrice: Math.round(lineItem.sellPrice),
    taxCode: lineItem.taxCode,
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildCommitSalesOrderDraftPayload(
  salesOrderId: number,
  state: SalesOrderEditorState
): CommitSalesOrderDraftPayloadInput {
  const createSalesOrderLineItemGroups = state.lineItemGroups
    .filter((g) => g.isCreated)
    .map(buildCreateSalesOrderLineItemGroupPayload);
  const updateSalesOrderLineItemGroups = state.lineItemGroups
    .filter((g) => !g.isCreated && g.isModified)
    .map(buildUpdateSalesOrderLineItemGroupPayload);
  const deleteSalesOrderLineItemGroups = state.lineItemGroups
    .filter((g) => g.isRemoved)
    .map((g) => g.id);

  const createSalesOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => li.isCreated)
        .map(buildCreateSalesOrderLineItemPayload)
    )
  );
  const updateSalesOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => !li.isCreated && li.isModified)
        .map(buildUpdateSalesOrderLineItemPayload)
    )
  );
  const deleteSalesOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems.filter((li) => li.isRemoved).map((li) => li.id!)
    )
  );

  return {
    salesOrderId: salesOrderId,
    requestedFulfillmentDate:
      state.quickSettings.requestedFulfillmentDate?.toISOString(),
    requestedFulfillmentDateNotes:
      state.quickSettings.requestedFulfillmentDateNotes,
    requestedFulfillmentDateFlagged:
      state.quickSettings.requestedFulfillmentDateFlagged,
    primaryContactPersonId:
      state.quickSettings.primaryContact?.person?.id ?? -1,
    primaryContactCompanyId:
      state.quickSettings.primaryContact?.company?.id ?? -1,
    billingAddressId: state.quickSettings.billingAddress?.id ?? -1,
    shipToAddressId: state.quickSettings.shipToAddress?.id ?? -1,
    salesRepAccountId: state.quickSettings.salesRepAccount?.id ?? -1,
    createSalesOrderLineItemPayloads: createSalesOrderLineItems,
    updateSalesOrderLineItemPayloads: updateSalesOrderLineItems,
    deleteSalesOrderLineItemPayloads: deleteSalesOrderLineItems,
    createSalesOrderLineItemGroupPayloads: createSalesOrderLineItemGroups,
    updateSalesOrderLineItemGroupPayloads: updateSalesOrderLineItemGroups,
    deleteSalesOrderLineItemGroupPayloads: deleteSalesOrderLineItemGroups,
  };
}
