import { NonIdealState, Spinner } from "@blueprintjs/core";
import { VendorContactItem } from "./VendorContactItem";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ContactRole,
  UpdateVendorContactPayload,
} from "../../../types/ApiTypes";
import { AddPersonButton } from "../companies/AddPersonButton";

type GetVendorContactsResult = {
  vendors: {
    id: number;
    contacts: {
      id: number;
      person: {
        id: number;
        firstName: string;
        lastName: string;
      };
      role: ContactRole;
    }[];
  }[];
};

const GetVendorContacts = gql`
  query GetVendorContacts($vendorId: Int!) {
    vendors(where: { id: { eq: $vendorId } }) {
      id
      contacts {
        id
        person {
          id
          firstName
          lastName
        }
        role
      }
    }
  }
`;

const AddPersonToVendor = gql`
  mutation AddPersonToVendor(
    $personId: Int!
    $vendorId: Int!
    $role: ContactRole!
  ) {
    addPersonToVendor(personId: $personId, vendorId: $vendorId, role: $role) {
      id
    }
  }
`;

const UpdateVendorContact = gql`
  mutation UpdateVendorContact($payload: UpdateVendorContactPayloadInput!) {
    updateVendorContact(payload: $payload) {
      id
    }
  }
`;

const RemovePersonFromVendor = gql`
  mutation RemovePersonFromVendor($vendorContactId: Int!) {
    removePersonFromVendor(vendorContactId: $vendorContactId) {
      id
    }
  }
`;

export type VendorContactListProps = {
  vendorId: number;
};

export function VendorContactList({ vendorId }: VendorContactListProps) {
  const getVendorContacts = useQuery<
    GetVendorContactsResult,
    { vendorId: number }
  >(GetVendorContacts, {
    variables: {
      vendorId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [addPersonToVendor] = useMutation<
    { addPersonToVendor: { id: number } },
    { vendorId: number; personId: number; role: ContactRole }
  >(AddPersonToVendor, {
    refetchQueries: ["GetVendorContacts"],
  });

  const [updateVendorContact] = useMutation<
    { updateVendorContact: { id: number } },
    { payload: UpdateVendorContactPayload }
  >(UpdateVendorContact, {
    refetchQueries: ["GetVendorContacts"],
  });

  const [removePersonFromVendor] = useMutation<
    { removePersonFromVendor: { id: number } },
    { vendorContactId: number }
  >(RemovePersonFromVendor, {
    refetchQueries: ["GetVendorContacts"],
  });

  return (
    <div className="flex flex-col border rounded overflow-hidden">
      <div className="flex-1 max-h-60 overflow-y-auto">
        {getVendorContacts.loading && (
          <div className="p-20 text-center">
            <Spinner size={20} />
          </div>
        )}
        {!getVendorContacts.loading &&
          getVendorContacts.data!.vendors[0].contacts.length === 0 && (
            <div className="p-5">
              <NonIdealState icon="user" title="No Contacts" />
            </div>
          )}
        {!getVendorContacts.loading &&
          getVendorContacts.data!.vendors[0].contacts.length > 0 &&
          getVendorContacts.data!.vendors[0].contacts.map((c) => (
            <VendorContactItem
              key={c.id}
              contact={c}
              onChange={async (v) => {
                await updateVendorContact({
                  variables: {
                    payload: {
                      id: c.id!,
                      role: v.role,
                    },
                  },
                });
              }}
              onRemove={async () => {
                await removePersonFromVendor({
                  variables: {
                    vendorContactId: c.id,
                  },
                });
              }}
            />
          ))}
      </div>
      <div className="flex border-t items-center justify-between p-1 bg-gray-50">
        <AddPersonButton
          onAddPerson={async (person) => {
            await addPersonToVendor({
              variables: {
                vendorId: vendorId,
                personId: person.id,
                role: ContactRole.Other,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
