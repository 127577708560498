import { gql, useMutation, useQuery } from "@apollo/client";
import { useApiError } from "../../../hooks/useApiError";
import {
  CreateProductSellPriceMultiplierPayload,
  ProductSellPriceMultiplier,
  UpdateProductSellPriceMultiplierPayload,
} from "../../../types/ApiTypes";

const GET_PRODUCT_SELL_PRICE_MULTIPLIERS = gql`
  query GetProductSellPriceMultipliers {
    productSellPriceMultipliers {
      id
      productId
      product {
        id
        code
        description
        catalog {
          id
          manufacturer {
            id
            name
          }
        }
      }
      defaultGrossProfit
      discount
      sellPriceMultiplier
    }
  }
`;

const CREATE_PRODUCT_SELL_PRICE_MULTIPLIER = gql`
  mutation CreateProductSellPriceMultiplier(
    $input: CreateProductSellPriceMultiplierPayloadInput
  ) {
    createProductSellPriceMultiplier(payload: $input) {
      id
    }
  }
`;

const UPDATE_PRODUCT_SELL_PRICE_MULTIPLIER = gql`
  mutation UpdateProductSellPriceMultiplier(
    $input: UpdateProductSellPriceMultiplierPayloadInput
  ) {
    updateProductSellPriceMultiplier(payload: $input) {
      id
    }
  }
`;

const DELETE_PRODUCT_SELL_PRICE_MULTIPLIER = gql`
  mutation DeleteProductSellPriceMultiplier($id: Int!) {
    deleteProductSellPriceMultiplier(id: $id) {
      id
    }
  }
`;

export function useProductSellPriceMultipliers() {
  const handleFetchError = useApiError("fetch product pricing info");
  const handleCreateError = useApiError("create product pricing info");
  const handleUpdateError = useApiError("update product pricing info");
  const handleDeleteError = useApiError("delete product pricing info");

  const getProductSellPriceMultipliers = useQuery<{
    productSellPriceMultipliers: ProductSellPriceMultiplier[];
  }>(GET_PRODUCT_SELL_PRICE_MULTIPLIERS, {
    onError: handleFetchError,
  });

  const [createSellPriceMultiplier, createSellPriceMultiplierMutation] =
    useMutation<
      { createProductSellPriceMultiplier: ProductSellPriceMultiplier },
      { input: CreateProductSellPriceMultiplierPayload }
    >(CREATE_PRODUCT_SELL_PRICE_MULTIPLIER, {
      refetchQueries: [
        {
          query: GET_PRODUCT_SELL_PRICE_MULTIPLIERS,
        },
      ],
      onError: handleCreateError,
    });

  const [updateSellPriceMultiplier, updateSellPriceMultiplierMutation] =
    useMutation<
      { updateProductSellPriceMultiplier: ProductSellPriceMultiplier },
      { input: UpdateProductSellPriceMultiplierPayload }
    >(UPDATE_PRODUCT_SELL_PRICE_MULTIPLIER, {
      refetchQueries: [
        {
          query: GET_PRODUCT_SELL_PRICE_MULTIPLIERS,
        },
      ],
      onError: handleUpdateError,
    });

  const [deleteSellPriceMultiplier, deleteSellPriceMultiplierMutation] =
    useMutation<
      { deleteProductSellPriceMultiplier: ProductSellPriceMultiplier },
      { id: number }
    >(DELETE_PRODUCT_SELL_PRICE_MULTIPLIER, {
      refetchQueries: [
        {
          query: GET_PRODUCT_SELL_PRICE_MULTIPLIERS,
        },
      ],
      onError: handleDeleteError,
    });

  return {
    productSellPriceMultipliers:
      getProductSellPriceMultipliers.data?.productSellPriceMultipliers,
    loading: getProductSellPriceMultipliers.loading,
    createSellPriceMultiplier,
    createSellPriceMultiplierLoading: createSellPriceMultiplierMutation.loading,
    updateSellPriceMultiplier,
    updateSellPriceMultiplierLoading: updateSellPriceMultiplierMutation.loading,
    deleteSellPriceMultiplier,
    deleteSellPriceMultiplierLoading: deleteSellPriceMultiplierMutation.loading,
    error: getProductSellPriceMultipliers.error,
  };
}
