import { useHistory } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Icon,
  Menu,
  MenuItem,
  Popover,
} from "@blueprintjs/core";
import React, { useContext } from "react";
import { ManagerApprovalContext } from "../../../../../context/ManagerApprovalContext/ManagerApprovalContext";

type QuoteConvertedWarningProps = {
  projectId: number;
  salesOrderId: number;
  salesOrderCode: string;
  onOverrideEdit?: () => void;
};

export function QuoteConvertedWarning({
  projectId,
  salesOrderId,
  salesOrderCode,
  onOverrideEdit,
}: QuoteConvertedWarningProps) {
  const history = useHistory();
  const { requestManagerApproval } = useContext(ManagerApprovalContext);

  return (
    <div
      className="sticky z-10 bg-yellow-100 text-yellow-700 border-b border-t border-yellow-700 py-2 px-2 pl-4 flex items-center space-x-4"
      style={{ top: 77 }}
    >
      <Icon icon="info-sign" />

      <span className="flex-1">
        This quote has been converted into {salesOrderCode} and can no longer be
        modified.
      </span>

      <ButtonGroup>
        <Button
          text="Go to Sales Order"
          rightIcon="arrow-right"
          onClick={() =>
            history.push(`/projects/${projectId}/sales-orders/${salesOrderId}`)
          }
        />
        {onOverrideEdit && (
          <Popover minimal position="bottom">
            <Button icon="chevron-down" />
            <Menu>
              <MenuItem
                text="Edit Quote"
                labelElement={<Icon icon="shield" color="#fab" />}
                onClick={() => requestManagerApproval(() => onOverrideEdit())}
              />
            </Menu>
          </Popover>
        )}
      </ButtonGroup>
    </div>
  );
}
