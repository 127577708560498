import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import { GetVendors, GetVendorsResult } from "./queries";

type AddSpiffCodeDialogProps = DialogProps & {
  onSubmit: (code: string, vendorId: number) => void;
};

type AddSpiffCodeFormFields = {
  code: string;
  vendorId: string;
};

export function AddSpiffCodeDialog({
  onSubmit,
  ...dialogProps
}: AddSpiffCodeDialogProps) {
  const getVendors = useQuery<GetVendorsResult>(GetVendors, {
    fetchPolicy: "cache-and-network",
  });

  const formik = useFormik<AddSpiffCodeFormFields>({
    onSubmit: (values) => onSubmit(values.code, parseInt(values.vendorId)),
    initialValues: {
      code: "",
      vendorId: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Required"),
      vendorId: Yup.number().required("Required"),
    }),
  });

  return (
    <Dialog title="Add SPIFF Code" {...dialogProps} lazy usePortal>
      <form onSubmit={formik.handleSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Vendor"
            helperText={formik.errors.vendorId}
            intent={formik.errors.vendorId ? "danger" : "none"}
          >
            <HTMLSelect
              value={formik.values.vendorId}
              onChange={formik.handleChange}
              name="vendorId"
              fill
            >
              <option value="" disabled>
                Select a vendor
              </option>

              {getVendors.data?.vendors.map((vendor) => (
                <option key={vendor.id} value={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>

          <FormGroup
            label="Code"
            helperText={formik.errors.code}
            intent={formik.errors.code ? "danger" : "none"}
          >
            <InputGroup
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={dialogProps.onClose} />
            <Button
              text="Create"
              disabled={!formik.isValid}
              intent="primary"
              type="submit"
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
