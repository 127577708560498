import { LineItemGroupToolbar } from "./LineItemGroupToolbar";
import { Button, Checkbox, Collapse } from "@blueprintjs/core";
import { BasicTable } from "../Table/BasicTable";
import { LineItem } from "./LineItem";
import classNames from "classnames";
import {
  EditableLineItem,
  EditableLineItemGroup,
  LineItemEditorActions,
  LineItemEditorState,
} from "./reducers/LineItemEditor/LineItemEditorTypes";
import React from "react";
import { LineItemEditorDefaultColumn } from "./LineItemEditor";

type LineItemGroupProps<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
> = {
  readOnly: boolean;
  groupsReadOnly: boolean;
  lineItemGroupIndex: number;
  state: TS;
  dispatch: React.Dispatch<LineItemEditorActions | TA>;
  groupFooterActions?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
    lineItemGroupIndex: number;
  }) => React.ReactNode;
  visibleDefaultColumns: LineItemEditorDefaultColumn[];
  additionalColumnHeaders?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
  }) => React.ReactNode;
  additionalColumns?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
    lineItemGroupIndex: number;
    lineItemIndex: number;
  }) => React.ReactNode;
  shipToAddressId?: number;
  fulfillmentContactId?: number;
};

export function LineItemGroup<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
>({
  readOnly,
  groupsReadOnly,
  lineItemGroupIndex,
  state,
  dispatch,
  groupFooterActions,
  visibleDefaultColumns,
  additionalColumnHeaders,
  additionalColumns,
  shipToAddressId,
  fulfillmentContactId,
}: LineItemGroupProps<TS, TA>) {
  const lineItemGroup = state.lineItemGroups[lineItemGroupIndex];

  const GroupFooterActions = groupFooterActions?.({
    state,
    dispatch,
    readOnly,
    lineItemGroupIndex,
  });

  const AdditionalColumnHeaders = additionalColumnHeaders?.({
    state,
    dispatch,
    readOnly,
  });

  return (
    <div
      className={classNames("rounded border divide-y overflow-hidden", {
        "opacity-20": lineItemGroup.isRemoved,
      })}
    >
      <LineItemGroupToolbar
        readOnly={readOnly || groupsReadOnly}
        name={lineItemGroup.name}
        isModified={lineItemGroup.isModified}
        isRemoved={lineItemGroup.isRemoved}
        isExpanded={lineItemGroup.isOpen}
        sortIndex={lineItemGroup.sortIndex}
        totalGroups={state.lineItemGroups.length}
        onNameChange={(name) =>
          dispatch({
            type: "setLineItemGroupName",
            lineItemGroupIndex,
            name,
          })
        }
        onExpand={() =>
          dispatch({
            type: "expandGroup",
            lineItemGroupIndex,
          })
        }
        onCollapse={() =>
          dispatch({
            type: "collapseGroup",
            lineItemGroupIndex,
          })
        }
        onMoveUp={() =>
          dispatch({
            type: "moveLineItemGroupUp",
            lineItemGroupIndex,
          })
        }
        onMoveDown={() =>
          dispatch({
            type: "moveLineItemGroupDown",
            lineItemGroupIndex,
          })
        }
        onRemove={() =>
          dispatch({ type: "removeLineItemGroup", lineItemGroupIndex })
        }
        onUndoRemove={() =>
          dispatch({ type: "undoRemoveLineItemGroup", lineItemGroupIndex })
        }
      />

      <Collapse isOpen={lineItemGroup.isOpen}>
        <div className="overflow-x-auto">
          <BasicTable borderless>
            <thead>
              <tr>
                <th
                  style={{
                    left: "-1px",
                    boxShadow: "inset -1px 0 0px #e3e3e3",
                    minWidth: 25,
                  }}
                  className="sticky bg-gray-50"
                >
                  #
                </th>

                {/*<th*/}
                {/*  style={{*/}
                {/*    left: "24px",*/}
                {/*    boxShadow: "inset -1px 0 0px #e3e3e3",*/}
                {/*    minWidth: 25,*/}
                {/*  }}*/}
                {/*  className="sticky bg-gray-50"*/}
                {/*></th>*/}

                {visibleDefaultColumns.includes("product") && (
                  <th style={{ minWidth: 300 }}>Product</th>
                )}
                {visibleDefaultColumns.includes("description") && (
                  <th style={{ minWidth: 500 }}>Description</th>
                )}
                {visibleDefaultColumns.includes("quantity") && (
                  <th style={{ minWidth: 100 }}>QTY</th>
                )}
                {visibleDefaultColumns.includes("vendorListPrice") && (
                  <th style={{ minWidth: 150 }}>Vendor List Price</th>
                )}
                {visibleDefaultColumns.includes(
                  "discountListPriceMultiplier"
                ) && (
                  <th style={{ minWidth: 150 }}>
                    Discount List Price Multiplier
                  </th>
                )}
                {visibleDefaultColumns.includes("purchasePrice") && (
                  <th style={{ minWidth: 150 }}>Purchase Price</th>
                )}
                {visibleDefaultColumns.includes("grossProfitPercent") && (
                  <th style={{ minWidth: 150 }}>Gross Profit %</th>
                )}
                {visibleDefaultColumns.includes("suggestedSellPrice") && (
                  <th style={{ minWidth: 150 }}>Suggested Sell Price</th>
                )}
                {visibleDefaultColumns.includes("actualSellPrice") && (
                  <th style={{ minWidth: 150 }}>Actual Sell Price</th>
                )}
                {visibleDefaultColumns.includes("tax") && (
                  <th style={{ minWidth: 150 }}>Tax</th>
                )}
                {visibleDefaultColumns.includes("totalSellPrice") && (
                  <th style={{ minWidth: 150 }}>Total Sell Price</th>
                )}
                {AdditionalColumnHeaders}
                <th
                  style={{
                    minWidth: 25,
                    right: "-1px",
                    boxShadow: "inset 1px 0 0px #e3e3e3",
                  }}
                  className="sticky bg-gray-50"
                >
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>

            <tbody>
              {lineItemGroup.lineItems.map((_, index) => (
                <LineItem<TS, TA>
                  visibleDefaultColumns={visibleDefaultColumns}
                  key={index}
                  lineItemIndex={index}
                  lineItemGroupIndex={lineItemGroupIndex}
                  readOnly={readOnly}
                  state={state}
                  dispatch={dispatch}
                  additionalColumns={additionalColumns}
                  shipToAddressId={shipToAddressId}
                  fulfillmentContactId={fulfillmentContactId}
                />
              ))}
            </tbody>
          </BasicTable>
        </div>
      </Collapse>

      <div
        className={classNames("p-1 flex items-center space-x-1", {
          "bg-gray-50": !lineItemGroup.isModified,
          "bg-yellow-50": lineItemGroup.isModified,
        })}
      >
        <Button
          icon="add-row-bottom"
          text="Add Line Item"
          onClick={() => {
            dispatch({
              type: "addLineItem",
              lineItemGroupIndex,
            });
            dispatch({
              type: "expandGroup",
              lineItemGroupIndex,
            });
          }}
        />

        {GroupFooterActions}
      </div>
    </div>
  );
}
