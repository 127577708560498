import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { gql, useMutation } from "@apollo/client";
import { CreatePersonPayloadInput, Person } from "../../../types/ApiTypes";
import { useState } from "react";
import InputMask from "react-input-mask";
import { State } from "country-state-city";

const CREATE_PERSON = gql`
  mutation CreatePerson($payload: CreatePersonPayloadInput) {
    createPerson(payload: $payload) {
      id
      firstName
      lastName
    }
  }
`;

type InlineCreatePersonFormProps = {
  onPersonCreated: (person: Person) => void;
};

export function InlineCreatePersonForm({
  onPersonCreated,
}: InlineCreatePersonFormProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneExtension, setPhoneExtension] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [createPerson] = useMutation<
    { createPerson: Person },
    { payload: CreatePersonPayloadInput }
  >(CREATE_PERSON);

  async function handleSubmit(e) {
    e.preventDefault();

    const createPersonResult = await createPerson({
      variables: {
        payload: {
          firstName,
          lastName,
          email,
          phone: (phone + " " + phoneExtension).trim(),
        },
      },
    });

    onPersonCreated(createPersonResult.data!.createPerson);
  }

  return (
    <div>
      <div className="p-2 border-b">
        <h4 className="text-center font-medium text-gray-700">
          Create a Person
        </h4>
      </div>
      <div className="p-3 w-96">
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <FormGroup label="First Name" className="flex-1 pr-1">
              <InputGroup
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <FormGroup label="Last Name" className="flex-1 pl-1">
              <InputGroup
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="flex">
            <FormGroup label="Email" className="flex-1 pr-1">
              <InputGroup
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="flex">
            <FormGroup label="Phone" className="flex-1 pl-1">
              <ControlGroup fill>
                <HTMLSelect className="w-24">
                  <option value="">+1</option>
                </HTMLSelect>
                <InputMask
                  mask={"999-999-9999"}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                >
                  {(inputProps) => <InputGroup type="tel" {...inputProps} />}
                </InputMask>
                <InputMask
                  mask={"9999"}
                  value={phoneExtension}
                  onChange={(e) => setPhoneExtension(e.target.value)}
                  className="w-28"
                >
                  {(inputProps) => <InputGroup type="tel" {...inputProps} />}
                </InputMask>
              </ControlGroup>
            </FormGroup>
          </div>

          <div className="flex">
            <FormGroup label="Address">
              <InputGroup
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                fill
              />
            </FormGroup>
            <FormGroup label="City" className="pl-2 flex-1">
              <InputGroup
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
          </div>

          <div className="flex mb-4">
            <FormGroup label="State" className="pl-2 pr-2 w-40">
              <HTMLSelect
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                {State.getStatesOfCountry("US").map((state) => (
                  <option value={state.name}>{state.name}</option>
                ))}
              </HTMLSelect>
            </FormGroup>
            <FormGroup label="Postal Code" className="pl-2 w-40">
              <InputGroup
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              text="Create Person"
              intent={Intent.PRIMARY}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
