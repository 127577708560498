import { gql, useQuery } from "@apollo/client";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../helpers/CatalogHelper";
import { ProductConfiguration } from "../../../types/ApiTypes";
import {
  Button,
  Checkbox,
  Icon,
  NumericInput,
  Popover,
  Spinner,
} from "@blueprintjs/core";
import { BasicTable } from "../Table/BasicTable";
import { useEffect, useState } from "react";
import produce from "immer";
import { DrawerTitleToolbar } from "../DrawerTitleToolbar/DrawerTitleToolbar";
import axios from "axios";
import { ErrorDialog } from "../ErrorDialog/ErrorDialog";

type GetShipmentDetailsParams = {
  shipmentId: number;
};

type GetShipmentDetailsResult = {
  purchaseOrderShipments: {
    id: number;
    lineItems: {
      id: number;
      quantity: number;
      purchaseOrderLineItem: {
        id: number;
        quantity: number;
        configuration: ProductConfiguration;
      };
    }[];
  }[];
};

const GetShipmentDetails = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetShipmentDetails($shipmentId: Int!) {
    purchaseOrderShipments(where: { id: { eq: $shipmentId } }) {
      id
      lineItems {
        id
        quantity
        purchaseOrderLineItem {
          id
          quantity
          configuration {
            id
            ...GetFullConfigurationCodeFragment
            ...GetFullConfigurationDescriptionFragment
          }
        }
      }
    }
  }
`;

type ReceiveShipmentProps = {
  shipmentId: number;
  onReceived: () => void;
  onClose: () => void;
};

export function ReceiveShipment({
  shipmentId,
  onReceived,
  onClose,
}: ReceiveShipmentProps) {
  const getShipmentDetails = useQuery<
    GetShipmentDetailsResult,
    GetShipmentDetailsParams
  >(GetShipmentDetails, {
    variables: {
      shipmentId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<Error>();

  const [lineItems, setLineItems] = useState<
    {
      id: number;
      numberOfItems: number;
      numberOfLabels: number;
      isIncluded: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (getShipmentDetails.loading) return;
    if (getShipmentDetails.error) return;

    setLineItems(
      getShipmentDetails.data!.purchaseOrderShipments[0].lineItems.map(
        (li) => ({
          id: li.id,
          numberOfItems: li.quantity,
          numberOfLabels: 1,
          isIncluded: true,
        })
      )
    );
  }, [getShipmentDetails.loading, getShipmentDetails.data]);

  const numberOfItems = lineItems.reduce(
    (acc, li) => acc + (li.isIncluded ? li.numberOfItems : 0),
    0
  );

  const numberOfLabels = lineItems.reduce(
    (acc, li) =>
      acc + (li.isIncluded ? li.numberOfItems * li.numberOfLabels : 0),
    0
  );

  async function generateLabels() {
    setIsGenerating(true);

    try {
      await Promise.all(
        lineItems
          .filter((li) => li.isIncluded)
          .map((lineItem) =>
            axios.post(
              `/purchaseordershipmentlineitems/${lineItem.id}/receive`,
              {
                numberOfItems: lineItem.numberOfItems,
                numberOfItemsInPackage: 1,
                numberOfLabels: lineItem.numberOfLabels,
              }
            )
          )
      );

      onReceived();
    } catch (err) {
      setError(err);
    } finally {
      setIsGenerating(false);
    }
  }

  return (
    <>
      <ErrorDialog
        isOpen={!!error}
        error={error}
        onClose={() => setError(undefined)}
      />

      <DrawerTitleToolbar
        title={`Receive Shipment ${shipmentId}`}
        onClose={() => onClose()}
      >
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2 text-indigo-600">
            <Icon icon="info-sign" />
            <p className="m-0">
              <span className="text-indigo-600 font-bold">
                {numberOfItems} inventory items
              </span>{" "}
              and{" "}
              <span className="text-indigo-600 font-bold">
                {numberOfLabels} labels
              </span>{" "}
              will be generated
            </p>
          </div>

          <Button
            intent="primary"
            text="Receive Shipment"
            onClick={generateLabels}
            disabled={isGenerating}
            loading={isGenerating}
          />
        </div>
      </DrawerTitleToolbar>

      <div>
        {getShipmentDetails.loading && <Spinner />}

        {!getShipmentDetails.loading && (
          <div className="p-2">
            <BasicTable>
              <thead>
                <tr>
                  <th style={{ width: 25 }}>
                    <span className="sr-only">Selected</span>
                  </th>
                  <th>#</th>
                  <th>Code</th>
                  <th>Description</th>
                  <th># in Shipment</th>
                  <th># Ordered</th>
                  <th>
                    <div className="flex items-center">
                      <span className="flex-1"># of Items to Label</span>
                      <Popover minimal position="bottom">
                        <Button minimal small icon="help" />
                        <div className="p-2 w-80">
                          <p className="m-0 text-sm text-gray-500">
                            Usually each item should be labeled, but if you have
                            multiple items packed in a single box (e.g. 4 chairs
                            in a box), you may just want to label the packages
                            rather than the individual items.
                          </p>
                        </div>
                      </Popover>
                    </div>
                  </th>
                  <th>
                    <div className="flex items-center">
                      <span className="flex-1"># of Labels Per Item</span>
                      <Popover minimal position="bottom">
                        <Button minimal small icon="help" />
                        <div className="p-2 w-80">
                          <p className="m-0 text-sm text-gray-500">
                            If an item ships with multiple boxes (e.g. a desk
                            that ships in multiple parts), you can generate
                            multiple sequential labels for the same inventory
                            item.
                          </p>
                        </div>
                      </Popover>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {lineItems.map((lineItem, index) => (
                  <tr
                    key={lineItem.id}
                    className={
                      lineItem.isIncluded ? "opacity-100" : "opacity-50"
                    }
                  >
                    <td>
                      <Checkbox
                        style={{ margin: "0 -6px 0 2px" }}
                        checked={lineItem.isIncluded}
                        onClick={() =>
                          setLineItems((prev) =>
                            produce(prev, (draft) => {
                              draft[index].isIncluded =
                                !draft[index].isIncluded;
                            })
                          )
                        }
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>
                      <p
                        className="m-0 font-medium"
                        dangerouslySetInnerHTML={{
                          __html: getFullConfigurationCode(
                            getShipmentDetails.data!.purchaseOrderShipments[0]
                              .lineItems[index].purchaseOrderLineItem
                              .configuration
                          ).replaceAll("\n", "<br />"),
                        }}
                      />
                    </td>
                    <td>
                      <p
                        className="m-0"
                        dangerouslySetInnerHTML={{
                          __html: getFullConfigurationDescription(
                            getShipmentDetails.data!.purchaseOrderShipments[0]
                              .lineItems[index].purchaseOrderLineItem
                              .configuration
                          ).replaceAll("\n", "<br />"),
                        }}
                      />
                    </td>
                    <td>
                      {
                        getShipmentDetails.data!.purchaseOrderShipments[0]
                          .lineItems[index].quantity
                      }
                    </td>
                    <td>
                      {
                        getShipmentDetails.data!.purchaseOrderShipments[0]
                          .lineItems[index].purchaseOrderLineItem.quantity
                      }
                    </td>
                    <td>
                      <NumericInput
                        value={lineItem.numberOfItems}
                        onValueChange={(e) =>
                          setLineItems((prev) =>
                            produce(prev, (draft) => {
                              draft[index].numberOfItems = e;
                            })
                          )
                        }
                        max={
                          getShipmentDetails.data!.purchaseOrderShipments[0]
                            .lineItems[index].purchaseOrderLineItem.quantity
                        }
                        min={1}
                        fill
                      />
                    </td>
                    <td>
                      <NumericInput
                        value={lineItem.numberOfLabels}
                        onValueChange={(e) =>
                          setLineItems((prev) =>
                            produce(prev, (draft) => {
                              draft[index].numberOfLabels = e;
                            })
                          )
                        }
                        min={1}
                        fill
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </div>
        )}
      </div>
    </>
  );
}
