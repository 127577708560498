import { gql, useMutation, useQuery } from "@apollo/client";
import { ProductConfiguration } from "../../../../../types/ApiTypes";
import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useReportDataState } from "../../../../../hooks/useReportDataState";
import { Button, Checkbox, NonIdealState, Spinner } from "@blueprintjs/core";
import { ReportColumnMenu } from "../../../../../components/reports/ReportColumnMenu";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import { DrawerTitleToolbar } from "../../../../../components/shared/DrawerTitleToolbar/DrawerTitleToolbar";
import { useState } from "react";

type GetProjectInventoryItemsResult = {
  inventoryItems: {
    id: number;
    prepTicketLineItem?: {
      id: number;
    };
    location: {
      id: number;
      name: string;
    };
    configuration: ProductConfiguration;
    purchaseOrderShipmentLineItem: {
      id: number;
      purchaseOrderLineItem: {
        id: number;
        purchaseOrder: {
          id: number;
          code: string;
        };
        saleOrderLineItem: {
          id: number;
          salesOrderLineItemGroup: {
            id: number;
            salesOrder: {
              id: number;
              code: string;
            };
          };
        };
      };
    };
  }[];
};

const GetProjectInventoryItems = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetProjectInventoryItems($projectId: Int!) {
    inventoryItems(
      where: {
        purchaseOrderShipmentLineItem: {
          purchaseOrderLineItem: {
            purchaseOrder: { projectId: { eq: $projectId } }
          }
        }
      }
    ) {
      id
      prepTicketLineItem {
        id
      }
      location {
        id
        name
      }
      configuration {
        id
        ...GetFullConfigurationCodeFragment
        ...GetFullConfigurationDescriptionFragment
      }
      purchaseOrderShipmentLineItem {
        id
        purchaseOrderLineItem {
          id
          purchaseOrder {
            id
            code
          }
          salesOrderLineItem {
            id
            salesOrderLineItemGroup {
              id
              salesOrder {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`;

type CreatePrepTicketLineItemPayload = {
  prepTicketId: number;
  inventoryItemId: number;
  isPrepped?: boolean;
};

const CreatePrepTicketLineItem = gql`
  mutation CreatePrepTicketLineItem(
    $payload: CreatePrepTicketLineItemPayloadInput!
  ) {
    createPrepTicketLineItem(payload: $payload) {
      id
    }
  }
`;

type AddPrepTicketLineItemProps = {
  projectId: number;
  prepTicketId: number;
  onClose: () => void;
};

export function AddPrepTicketLineItem({
  projectId,
  prepTicketId,
  onClose,
}: AddPrepTicketLineItemProps) {
  const [selectedInventoryItemIds, setSelectedInventoryItemIds] = useState<
    number[]
  >([]);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "index", dir: "asc" }],
  });

  const getProjectInventoryItems = useQuery<
    GetProjectInventoryItemsResult,
    { projectId: number }
  >(GetProjectInventoryItems, {
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const [createPrepTicketLineItem] = useMutation<
    { createPrepTicketLineItem: { id: number } },
    { payload: CreatePrepTicketLineItemPayload }
  >(CreatePrepTicketLineItem, {
    refetchQueries: [
      "GetPrepTicket",
      "GetPrepTicketLineItems",
      "GetPrepTicketById",
    ],
  });

  const inventoryItems =
    getProjectInventoryItems.data?.inventoryItems
      .filter((li) => !li.prepTicketLineItem)
      .map((li, i) => ({
        ...li,
        index: i + 1,
        configurationCode: getFullConfigurationCode(li.configuration),
        configurationDescription: getFullConfigurationDescription(
          li.configuration
        ),
      })) ?? [];

  return (
    <div>
      <DrawerTitleToolbar title="Add Inventory Items" onClose={onClose}>
        <Button
          intent="primary"
          text={
            selectedInventoryItemIds.length > 0
              ? `Add ${selectedInventoryItemIds.length} Items`
              : "No Items Selected"
          }
          disabled={selectedInventoryItemIds.length === 0}
          onClick={async () => {
            await Promise.all(
              selectedInventoryItemIds.map((id) =>
                createPrepTicketLineItem({
                  variables: {
                    payload: {
                      prepTicketId,
                      inventoryItemId: id,
                    },
                  },
                })
              )
            );
            onClose();
          }}
        />
      </DrawerTitleToolbar>

      <div className="p-2">
        {getProjectInventoryItems.loading && (
          <div className="p-4">
            <Spinner />
          </div>
        )}

        {!getProjectInventoryItems.loading && inventoryItems.length > 0 && (
          <Grid
            data={process(inventoryItems, dataState)}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            reorderable
            resizable
            scrollable="none"
          >
            <GridColumn
              cell={(p) => (
                <td>
                  <Checkbox
                    style={{ margin: 0 }}
                    checked={selectedInventoryItemIds.includes(p.dataItem.id)}
                    onClick={() => {
                      if (selectedInventoryItemIds.includes(p.dataItem.id)) {
                        setSelectedInventoryItemIds(
                          selectedInventoryItemIds.filter(
                            (i) => i !== p.dataItem.id
                          )
                        );
                      } else {
                        setSelectedInventoryItemIds([
                          ...selectedInventoryItemIds,
                          p.dataItem.id,
                        ]);
                      }
                    }}
                  />
                </td>
              )}
              headerCell={() => (
                <Checkbox
                  style={{ margin: 0 }}
                  checked={
                    selectedInventoryItemIds.length > 0 &&
                    selectedInventoryItemIds.length === inventoryItems.length
                  }
                  indeterminate={
                    selectedInventoryItemIds.length > 0 &&
                    selectedInventoryItemIds.length !== inventoryItems.length
                  }
                  onClick={() => {
                    if (
                      selectedInventoryItemIds.length === inventoryItems.length
                    ) {
                      setSelectedInventoryItemIds([]);
                    } else {
                      setSelectedInventoryItemIds([
                        ...inventoryItems.map((i) => i.id),
                      ]);
                    }
                  }}
                />
              )}
              resizable={false}
              sortable={false}
              filterable={false}
              width={45}
            />
            <GridColumn field="index" title="#" width={60} />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="purchaseOrderShipmentLineItem.purchaseOrderLineItem.purchaseOrder.code"
              title="PO #"
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="purchaseOrderShipmentLineItem.purchaseOrderLineItem.salesOrderLineItem.salesOrderLineItemGroup.salesOrder.code"
              title="SO #"
              width={150}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Code"
              field="configurationCode"
              cell={(p) => (
                <td
                  dangerouslySetInnerHTML={{
                    __html: p.dataItem["configurationCode"].replaceAll(
                      "\n",
                      "<br />"
                    ),
                  }}
                />
              )}
              width={250}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="Description"
              field="configurationDescription"
              cell={(p) => (
                <td
                  dangerouslySetInnerHTML={{
                    __html: p.dataItem["configurationDescription"].replaceAll(
                      "\n",
                      "<br />"
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        {!getProjectInventoryItems.loading && inventoryItems.length === 0 && (
          <div className="p-4">
            <NonIdealState
              title="No Inventory Items"
              description="All inventory items in this project have been added to other prep tickets."
              action={<Button text="Cancel" onClick={onClose} />}
            />
          </div>
        )}
      </div>
    </div>
  );
}
