import { State } from "@progress/kendo-data-query";
import { DefaultToaster } from "../shared/DefaultToaster/DefaultToaster";
import { Button, InputGroup } from "@blueprintjs/core";

export function ShareReportMenu({ dataState }: { dataState: State }) {
  const hrefWithoutQuery = window.location.href.split("?")[0];
  const url = `${hrefWithoutQuery}?dataState=${encodeURIComponent(
    JSON.stringify(dataState)
  )}`;

  const copyToClipboard = () => {
    window.navigator.clipboard
      .writeText(url)
      .then(() =>
        DefaultToaster.show({
          message: "Report URL copied to clipboard!",
          intent: "success",
          icon: "tick-circle",
        })
      )
      .catch((err) =>
        DefaultToaster.show({
          message: "Unable to copy Report URL to clipboard.",
          intent: "danger",
          icon: "error",
        })
      );
  };

  return (
    <div className="p-4">
      <h4 className="font-medium">Share Report</h4>
      <p className="mr-4 mb-2 text-sm text-gray-500">
        You can use the following link to return to this report...
      </p>
      <InputGroup
        value={url}
        leftIcon="share"
        rightElement={<Button icon="clipboard" onClick={copyToClipboard} />}
        readOnly
      />
    </div>
  );
}
