import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Collapse,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import {
  ProductConfiguration,
  PurchaseOrderShipment as ApiPurchaseOrderShipment,
  PurchaseOrderShipmentLineItem,
} from "../../../../../types/ApiTypes";
import { TableHeader } from "../../../../shared/Table/TableHeader";
import { TableFooter } from "../../../../shared/Table/TableFooter";
import { usePurchaseOrderShipmentLineItems } from "../../../../../hooks/usePurchaseOrderShipmentLineItems";
import { FlexTable } from "../../../../shared/Table/FlexTable";
import { DateInput } from "@blueprintjs/datetime";
import dayjs from "dayjs";
import classNames from "classnames";
import {
  getFullConfigurationCode,
  getFullConfigurationDescription,
} from "../../../../../helpers/CatalogHelper";
import { useHistory } from "react-router-dom";

export type PurchaseOrderShipmentDateProps = {
  title: string;
  value?: Date | null;
  last?: boolean;
  disabled?: boolean;
  onChange: (d: Date) => void;
};

function PurchaseOrderShipmentDate({
  title,
  value,
  last = false,
  disabled = false,
  onChange,
}: PurchaseOrderShipmentDateProps) {
  return (
    <div
      className={classNames(
        "bg-white border-l p-2 flex items-center justify-center",
        { "border-r": last }
      )}
    >
      <label>
        <span className="block mb-1 text-xs font-semibold uppercase tracking-wide">
          {title}
        </span>
        <DateInput
          inputProps={{ leftIcon: "calendar" }}
          placeholder="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          formatDate={(date) => date.toLocaleDateString()}
          parseDate={(str) => new Date(str)}
          disabled={disabled}
        />
      </label>
    </div>
  );
}

export type PurchaseOrderShipmentProps = {
  purchaseOrderId: number;
  shipmentId: number;
  projectId: number;
  shipment: ApiPurchaseOrderShipment;
  onShipmentChange: (shipmentId: number, dto: ApiPurchaseOrderShipment) => void;
  onCancelShipment: () => void;
  onShowDetails: () => void;
  onReceiveShipment: () => void;
};

export function PurchaseOrderShipment({
  purchaseOrderId,
  shipmentId,
  projectId,
  shipment,
  onShipmentChange,
  onShowDetails,
  onCancelShipment,
  onReceiveShipment,
}: PurchaseOrderShipmentProps) {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);

  const [expectedShipmentDate, setExpectedShipmentDate] = useState<Date | null>(
    shipment.expectedShipDate ? dayjs(shipment.expectedShipDate).toDate() : null
  );
  const [actualShipmentDate, setActualShipmentDate] = useState<Date | null>(
    shipment.actualShipDate ? dayjs(shipment.actualShipDate).toDate() : null
  );
  const [expectedArrivalDate, setExpectedArrivalDate] = useState<Date | null>(
    shipment.expectedArrivalDate
      ? dayjs(shipment.expectedArrivalDate).toDate()
      : null
  );
  const [actualArrivalDate, setActualArrivalDate] = useState<Date | null>(
    shipment.actualArrivalDate
      ? dayjs(shipment.actualArrivalDate).toDate()
      : null
  );

  const handleDateChangedUsing = (setValue, fieldName) => {
    return (value) => {
      setValue(value);

      onShipmentChange(shipmentId, {
        ...shipment,
        expectedShipDate: expectedShipmentDate?.toISOString(),
        actualShipDate: actualShipmentDate?.toISOString(),
        expectedArrivalDate: expectedArrivalDate?.toISOString(),
        actualArrivalDate: actualArrivalDate?.toISOString(),
        [fieldName]: value,
      });
    };
  };

  const columns = [
    {
      Header: "Line",
      accessor: (_, index) => index + 1,
      Cell: ({ value }) => <p className="m-0 text-center">{value}</p>,
      maxWidth: 5,
    },
    {
      Header: "Code",
      Cell: ({ row }) => (
        <div className="space-y-2">
          <p
            className="m-0 font-bold"
            dangerouslySetInnerHTML={{
              __html: getFullConfigurationCode(
                row.original.purchaseOrderLineItem
                  .configuration as ProductConfiguration
              ).replaceAll("\n", "<br />"),
            }}
          />
        </div>
      ),
      maxWidth: 25,
    },
    {
      Header: "Description",
      Cell: ({ row }) => (
        <div className="space-y-2">
          <p
            className="m-0 text-gray-400"
            dangerouslySetInnerHTML={{
              __html: getFullConfigurationDescription(
                row.original.purchaseOrderLineItem
                  .configuration as ProductConfiguration
              ).replaceAll("\n", "<br />"),
            }}
          />

          {Boolean(row.original.purchaseOrderLineItem.description) && (
            <p className="m-0 text-gray-400">{row.original.description}</p>
          )}
        </div>
      ),
    },
    {
      Header: "Quantity Ordered",
      Cell: ({ row }) => (
        <div>
          <p className="m-0 text-gray-400">
            {row.original.purchaseOrderLineItem.quantity}
          </p>
        </div>
      ),
      maxWidth: 20,
    },
    {
      Header: "Quantity in Shipment",
      Cell: ({ row }) => (
        <div>
          <p className="m-0 text-gray-400">{row.original.quantity}</p>
        </div>
      ),
      className: "with-button",
      maxWidth: 20,
    },
  ];

  return (
    <>
      <div key={shipmentId} className="mb-2">
        <TableHeader className="px-1" collapsed={collapsed}>
          <Button
            minimal
            icon={collapsed ? "chevron-right" : "chevron-down"}
            className="mr-2"
            onClick={() => setCollapsed(!collapsed)}
          />

          <h4 className="text-xl font-semibold m-0 mr-5">
            Shipment {shipmentId}
          </h4>

          <div className="flex flex-1 items-stretch mx-10">
            <PurchaseOrderShipmentDate
              title={"Expected Ship Date"}
              value={expectedShipmentDate}
              onChange={handleDateChangedUsing(
                setExpectedShipmentDate,
                "expectedShipDate"
              )}
            />
            <PurchaseOrderShipmentDate
              title={"Actual Ship Date"}
              value={actualShipmentDate}
              onChange={handleDateChangedUsing(
                setActualShipmentDate,
                "actualShipDate"
              )}
            />
            <PurchaseOrderShipmentDate
              title={"Expected Arrival Date"}
              value={expectedArrivalDate}
              onChange={handleDateChangedUsing(
                setExpectedArrivalDate,
                "expectedArrivalDate"
              )}
            />
            <PurchaseOrderShipmentDate
              title={"Actual Arrival Date"}
              value={actualArrivalDate}
              onChange={handleDateChangedUsing(
                setActualArrivalDate,
                "actualArrivalDate"
              )}
              disabled
              last
            />
          </div>

          <ButtonGroup>
            <Button text="Receive Shipment" onClick={onReceiveShipment} />
            <Popover position={Position.BOTTOM_RIGHT} minimal>
              <Button icon="more" />
              <Menu>
                <MenuItem text="View Details" onClick={onShowDetails} />
                <MenuDivider />
                <MenuItem
                  icon="remove"
                  text="Cancel Shipment"
                  intent={Intent.DANGER}
                  onClick={onCancelShipment}
                />
              </Menu>
            </Popover>
          </ButtonGroup>
        </TableHeader>
        <Collapse isOpen={!collapsed}>
          <FlexTable columns={columns} data={shipment.lineItems} header />
        </Collapse>
      </div>
    </>
  );
}
