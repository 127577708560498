import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { VendorAddressItem } from "./VendorAddressItem";
import { ChooseAddressDialog } from "../../shared/AddressInputGroup/ChooseAddressDialog";
import { useState } from "react";
import {
  Address,
  CompanyAddressType,
  UpdateVendorAddressPayload,
  VendorAddressType,
} from "../../../types/ApiTypes";

type GetVendorAddressesResult = {
  vendors: {
    id: number;
    vendorAddresses: {
      id: number;
      type: VendorAddressType;
      vendorId: number;
      address: {
        id: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        postalCode: string;
        addressSiteInformation?: {
          id: number;
          hasDock: boolean;
          numberOfInsideStairs: number;
          numberOfOutsideStairs: number;
          hasFreightElevator: boolean;
          hasPassengerElevator: boolean;
          isCertificateOfInsuranceRequired: boolean;
        };
      };
    }[];
  }[];
};

const GetVendorAddresses = gql`
  query GetVendorAddresses($id: Int!) {
    vendors(where: { id: { eq: $id } }) {
      id
      vendorAddresses {
        id
        type
        vendorId
        address {
          id
          street1
          street2
          city
          state
          postalCode
          addressSiteInformation {
            id
            hasDock
            numberOfInsideStairs
            numberOfOutsideStairs
            hasFreightElevator
            hasPassengerElevator
            isCertificateOfInsuranceRequired
          }
        }
      }
    }
  }
`;

const AddAddressToVendor = gql`
  mutation AddAddressToVendor(
    $addressId: Int!
    $vendorId: Int!
    $type: VendorAddressType!
  ) {
    addAddressToVendor(
      addressId: $addressId
      vendorId: $vendorId
      type: $type
    ) {
      id
    }
  }
`;

const RemoveAddressFromVendor = gql`
  mutation RemoveAddressFromVendor($vendorAddressId: Int!) {
    removeAddressFromVendor(vendorAddressId: $vendorAddressId) {
      id
    }
  }
`;

const UpdateVendorAddress = gql`
  mutation UpdateVendorAddress($payload: UpdateVendorAddressPayloadInput!) {
    updateVendorAddress(payload: $payload) {
      id
    }
  }
`;

export type VendorAddressListProps = {
  vendorId: number;
};

export function VendorAddressList({ vendorId }: VendorAddressListProps) {
  const [isChooseOpen, setIsChooseOpen] = useState(false);

  const getVendorAddresses = useQuery<GetVendorAddressesResult, { id: number }>(
    GetVendorAddresses,
    {
      variables: {
        id: vendorId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [addAddressToVendor] = useMutation<
    {
      addAddressToVendor: { id: number };
    },
    {
      addressId: number;
      vendorId: number;
      type: VendorAddressType;
    }
  >(AddAddressToVendor, {
    refetchQueries: ["GetVendorAddresses"],
  });

  const [removeAddressFromVendor] = useMutation<
    {
      removeAddressFromVendor: { id: number };
    },
    {
      vendorAddressId: number;
    }
  >(RemoveAddressFromVendor, {
    refetchQueries: ["GetVendorAddresses"],
  });

  const [updateVendorAddress] = useMutation<
    {
      updateVendorAddress: { id: number };
    },
    {
      payload: UpdateVendorAddressPayload;
    }
  >(UpdateVendorAddress, {
    refetchQueries: ["GetVendorAddresses"],
  });

  return (
    <>
      <ChooseAddressDialog
        isOpen={isChooseOpen}
        onClose={() => setIsChooseOpen(false)}
        onChoose={async (address: Address) => {
          await addAddressToVendor({
            variables: {
              vendorId: vendorId,
              addressId: address.id!,
              type: VendorAddressType.Other,
            },
          });
          setIsChooseOpen(false);
        }}
      />

      <div className="flex flex-col border rounded overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          {getVendorAddresses.loading && (
            <div className="p-20 text-center">
              <Spinner size={20} />
            </div>
          )}
          {!getVendorAddresses.loading &&
            getVendorAddresses.data!.vendors[0].vendorAddresses.length ===
              0 && (
              <div className="p-5">
                <NonIdealState icon="map" title="No Addresses" />
              </div>
            )}
          {!getVendorAddresses.loading &&
            getVendorAddresses.data!.vendors[0].vendorAddresses.length > 0 &&
            getVendorAddresses.data!.vendors[0].vendorAddresses.map((c) => (
              <VendorAddressItem
                key={c.id}
                vendorAddress={c}
                onChange={async (payload: UpdateVendorAddressPayload) => {
                  await updateVendorAddress({
                    variables: { payload },
                  });
                }}
                onRemove={async () => {
                  await removeAddressFromVendor({
                    variables: {
                      vendorAddressId: c.id,
                    },
                  });
                }}
              />
            ))}
        </div>
        <div className="flex border-t items-center justify-between p-1 bg-gray-50">
          <Button icon="plus" onClick={() => setIsChooseOpen(true)} />
        </div>
      </div>
    </>
  );
}
