import { DrawerProps, Drawer } from "@blueprintjs/core";
import { AddPrepTicketLineItem } from "./AddPrepTicketLineItem";

type AddPrepTicketLineItemDrawerProps = DrawerProps & {
  projectId: number;
  prepTicketId: number;
};

export function AddPrepTicketLineItemDrawer({
  projectId,
  prepTicketId,
  ...drawerProps
}: AddPrepTicketLineItemDrawerProps) {
  return (
    <Drawer {...drawerProps} position="bottom" size="100%">
      <AddPrepTicketLineItem
        projectId={projectId}
        prepTicketId={prepTicketId}
        onClose={() => drawerProps.onClose && drawerProps.onClose(null!)}
      />
    </Drawer>
  );
}
