import React, { PropsWithChildren } from "react";
import { ProjectSubnavbar } from "../../ProjectSubnavbar";
import { Icon } from "@blueprintjs/core";
import { NavLink, useParams } from "react-router-dom";

export type PurchaseOrderSubnavbarProps = {
  title: string;
  loading?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
};

export type PurchaseOrderRouteParams = {
  projectId?: string;
  purchaseOrderId: string;
};

export function PurchaseOrderSubnavbar({
  title,
  loading,
  actions,
}: PurchaseOrderSubnavbarProps) {
  const { projectId, purchaseOrderId } = useParams<PurchaseOrderRouteParams>();

  return (
    <ProjectSubnavbar
      title={title}
      actions={actions}
      loading={loading}
      tabs={
        <ul>
          <li>
            <NavLink
              to={
                projectId
                  ? `/projects/${projectId}/purchase-orders/${purchaseOrderId}`
                  : `/purchase-orders/${purchaseOrderId}`
              }
              exact
            >
              <Icon icon="info-sign" />
              Details
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                projectId
                  ? `/projects/${projectId}/purchase-orders/${purchaseOrderId}/shipments`
                  : `/purchase-orders/${purchaseOrderId}/shipments`
              }
              exact
            >
              <Icon icon="truck" />
              Shipments
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                projectId
                  ? `/projects/${projectId}/purchase-orders/${purchaseOrderId}/inventory`
                  : `/purchase-orders/${purchaseOrderId}/inventory`
              }
              exact
            >
              <Icon icon="box" />
              Inventory
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                projectId
                  ? `/projects/${projectId}/purchase-orders/${purchaseOrderId}/metadata`
                  : `/purchase-orders/${purchaseOrderId}/metadata`
              }
              exact
            >
              <Icon icon="more" />
              Metadata
            </NavLink>
          </li>
        </ul>
      }
    />
  );
}
