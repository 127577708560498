import React, { useEffect, useMemo, useState } from "react";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";

import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";

import {
  FormatContactNameContactFragment,
  formatContactPersonWithoutCompany,
  formatContactCompanyName,
  FormatContactNameContact,
} from "../../helpers/ContactHelper";
import { FulfillmentMethodType } from "../../types/ApiTypes";
import { formatFulfillmentMethodType } from "../../helpers/FulfillmentHelper";

export type GetFulfillmentReportResult = {
  id: string;
  lineItems: {
    id: number;
  }[];
  projectId: number;
  project: {
    id: number;
    primaryContact: FormatContactNameContact;
    billingContact: FormatContactNameContact;
    fulfillmentContact: FormatContactNameContact;
    salesRepAccount: {
      name: string;
    };
  };
  fulfillmentMethodType?: FulfillmentMethodType;
  fulfillmentMethodNotes?: string;
  installStartsAt: string;
  installEndsAt: string;
  deliveryStartsAt: string;
  deliveryEndsAt: string;
  estimatedHours: number;
  createdAt: string;
};

export const GET_FULFILLMENT_REPORT = gql`
  ${FormatContactNameContactFragment}

  query GetFulfillmentReport {
    fulfillmentReport {
      id
      lineItems {
        id
      }
      projectId
      project {
        id
        primaryContactId
        primaryContact {
          ...FormatContactNameContactFragment
        }
        billingContactId
        billingContact {
          ...FormatContactNameContactFragment
        }
        fulfillmentContactId
        fulfillmentContact {
          ...FormatContactNameContactFragment
        }
        salesRepAccountId
        salesRepAccount {
          id
          name
        }
      }
      fulfillmentMethodType
      fulfillmentMethodNotes
      installStartsAt
      installEndsAt
      deliveryStartsAt
      deliveryEndsAt
      estimatedHours
      createdAt
    }
  }
`;

export function FulfillmentFulfillmentsReport() {
  const getFulfillments = useQuery<{
    fulfillmentReport: GetFulfillmentReportResult[];
  }>(GET_FULFILLMENT_REPORT);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const reportLines = useMemo(
    () =>
      getFulfillments.data?.fulfillmentReport.map((entry) => ({
        ...entry,
        projectCode: `P-${entry.projectId}`,
        deliveryStartsAt: new Date(entry.deliveryStartsAt),
        deliveryEndsAt: new Date(entry.deliveryEndsAt),
        installStartsAt: new Date(entry.installStartsAt),
        installEndsAt: new Date(entry.installEndsAt),
        createdAt: new Date(entry.createdAt),
        primaryContactPersonName: formatContactPersonWithoutCompany(
          entry.project.primaryContact
        ),
        primaryContactCompanyName: formatContactCompanyName(
          entry.project.primaryContact
        ),
        fulfillmentContactPersonName: formatContactPersonWithoutCompany(
          entry.project.fulfillmentContact
        ),
        fulfillmentContactCompanyName: formatContactCompanyName(
          entry.project.fulfillmentContact
        ),
        salesRepName: entry.project.salesRepAccount
          ? entry.project.salesRepAccount.name
          : "Unspecified",
        fulfillmentMethod: entry.fulfillmentMethodType
          ? [
              formatFulfillmentMethodType(entry.fulfillmentMethodType),
              entry.fulfillmentMethodNotes,
            ]
              .filter(Boolean)
              .join(" - ")
          : "",
      })) ?? [],
    [getFulfillments.data]
  );

  return (
    <div className="flex flex-col h-full">
      <ProjectSubnavbar
        title="Fulfillments"
        style={{ top: "unset" }}
        actions={
          <>
            <Popover minimal>
              <Button minimal icon="share" text="Share Report" />
              <ShareReportMenu dataState={dataState} />
            </Popover>
          </>
        }
      />

      <div className="h-full relative">
        {getFulfillments.loading ? (
          <Spinner />
        ) : (
          <Grid
            className="absolute top-0 bottom-0 w-full"
            data={process(reportLines, dataState)}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            sortable
            reorderable
            resizable
          >
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="id"
              title="Fulfillment #"
              width={130}
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.projectId}/fulfillments/${props.dataItem.id}`}
                    text={`F-${props.dataItem.id}`}
                  />
                </td>
              )}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              field="id"
              title="Project #"
              width={130}
              cell={(props) => (
                <td>
                  <EntityLink
                    url={`/projects/${props.dataItem.projectId}`}
                    text={props.dataItem.projectCode}
                  />
                </td>
              )}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              field="primaryContactPersonName"
              title="Customer"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="primaryContactCompanyName"
              title="Customer Company"
              width={200}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="deliveryStartsAt"
              title="Begin Delivery"
              width={150}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="deliveryEndsAt"
              title="End Delivery"
              width={130}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="installStartsAt"
              title="Begin Install"
              width={130}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="installEndsAt"
              title="End Install"
              width={115}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="numeric"
              field="estimatedHours"
              title="Est. Hours"
              width={120}
            />

            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentMethod"
              title="Fufillment Method"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentContactPersonName"
              title="Fulfillment Person"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="fulfillmentContactCompanyName"
              title="Fulfillment Company"
              width={200}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              field="salesRepName"
              title="Sales Rep"
              width={140}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              title="In Full"
              cell={() => <td>?</td>}
              width={90}
            />
            <GridColumn
              columnMenu={ReportColumnMenu}
              filter="date"
              format="{0:d}"
              field="createdAt"
              title="Creation Date"
              width={150}
            />
          </Grid>
        )}
      </div>
    </div>
  );
}
