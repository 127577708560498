import {
  Button,
  Checkbox,
  InputGroup,
  Popover,
  Tooltip,
} from "@blueprintjs/core";
import { LineItemProductDetails } from "./LineItemProductDetails";
import { LineItemMenu } from "./LineItemMenu";
import { InternalExternalNotesEditor } from "../InternalExternalNotesEditor";
import { FreightRules } from "../FreightRules/FreightRules";
import { QuantityCell } from "../Table/TableCells/QuantityCell";
import { ApolloClient, gql, useApolloClient, useQuery } from "@apollo/client";
import {
  CalculateConfigurationPricePayloadInput,
  CatalogProduct,
  ProductAndOptionPricingResult,
} from "../../../types/ApiTypes";
import _ from "lodash";
import { formatPrice } from "../../../helpers/PriceHelper";
import classNames from "classnames";
import { NumberCell } from "../Table/TableCells/NumberCell";
import React from "react";
import { PRODUCT_CELL_PRODUCT_FRAGMENT } from "../Table/TableCells/ProductCell";
import { DeviantValueWarning } from "../DeviantValueWarning/DeviantValueWarning";
import { PriceCell } from "../Table/TableCells/PriceCell";
import { TaxCell } from "../Table/TableCells/TaxCell";
import {
  EditableLineItem,
  EditableLineItemGroup,
  EditableProductConfiguration,
  LineItemEditorActions,
  LineItemEditorState,
} from "./reducers/LineItemEditor/LineItemEditorTypes";
import { getTaxCode } from "../../../helpers/TaxHelper";
import { getCatalogProductSuggestedPricingInfo } from "../../../helpers/CatalogHelper";
import { applyConfigurationChange } from "./helpers/LineItemEditorHelpers";
import { LineItemEditorDefaultColumn } from "./LineItemEditor";

type GetLineItemDetailsResult = {
  catalogProducts?: CatalogProduct[];
  calculateProductAndOptionPricing?: ProductAndOptionPricingResult;
};

type GetLineItemDetailsParams = {
  includeProductAndOptionPricing: boolean;
  includeCatalogProduct: boolean;
  catalogProductId?: number;
  calculateProductAndOptionPricingPayload?: CalculateConfigurationPricePayloadInput;
};

const GetLineItemDetails = gql`
  ${PRODUCT_CELL_PRODUCT_FRAGMENT}

  query GetLineItemDetails(
    $includeCatalogProduct: Boolean!
    $includeProductAndOptionPricing: Boolean!
    $catalogProductId: Int
    $calculateProductAndOptionPricingPayload: CalculateProductAndOptionPricingPayloadInput
  ) {
    catalogProducts(where: { id: { eq: $catalogProductId } })
      @include(if: $includeCatalogProduct) {
      id
      description
      vendor {
        id
        listPriceMultiplier
        grossProfitPercent
      }
      ...ProductCellProductFragment
    }
    calculateProductAndOptionPricing(
      payload: $calculateProductAndOptionPricingPayload
    ) @include(if: $includeProductAndOptionPricing) {
      totalListPrice
    }
  }
`;

type LineItemProps<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
> = {
  readOnly: boolean;
  lineItemIndex: number;
  lineItemGroupIndex: number;
  state: TS;
  dispatch: React.Dispatch<LineItemEditorActions | TA>;
  visibleDefaultColumns: LineItemEditorDefaultColumn[];
  additionalColumns?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
    lineItemGroupIndex: number;
    lineItemIndex: number;
  }) => React.ReactNode;
  shipToAddressId?: number;
  fulfillmentContactId?: number;
};

export function LineItem<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
>({
  readOnly,
  lineItemIndex,
  lineItemGroupIndex,
  state,
  dispatch,
  visibleDefaultColumns,
  additionalColumns,
  shipToAddressId,
  fulfillmentContactId,
}: LineItemProps<TS, TA>) {
  const apolloClient = useApolloClient();
  const lineItemGroup = state.lineItemGroups[lineItemGroupIndex];
  const lineItem = lineItemGroup.lineItems[lineItemIndex];

  const AdditionalColumns = additionalColumns?.({
    state,
    dispatch,
    readOnly,
    lineItemGroupIndex,
    lineItemIndex,
  });

  const getLineItemDetails = useQuery<
    GetLineItemDetailsResult,
    GetLineItemDetailsParams
  >(GetLineItemDetails, {
    variables: {
      includeProductAndOptionPricing: !!lineItem.configuration,
      includeCatalogProduct: !!lineItem.configuration,
      catalogProductId: lineItem.configuration?.catalogProductId,
      calculateProductAndOptionPricingPayload: lineItem.configuration
        ? {
            catalogProductId: lineItem.configuration.catalogProductId!,
            catalogOptionIds: _.flatten(
              lineItem.configuration.features.map((f) =>
                f.selections.map((s) => s.catalogOptionId!)
              )
            ).filter((i) => !!i),
          }
        : undefined,
    },
  });

  const lineItemCatalogProduct = getLineItemDetails.data?.catalogProducts?.[0];
  const vendorListPrice = lineItem.configuration?.isCustomConfiguration
    ? lineItem.configuration.customListPrice ?? 0
    : getLineItemDetails.data?.calculateProductAndOptionPricing
        ?.totalListPrice ?? 0;
  const purchasePrice = lineItem.listPriceMultiplier * vendorListPrice;
  const suggestedSellPrice =
    purchasePrice + (lineItem.grossProfitPercent / 100) * purchasePrice;

  return (
    <tr
      className={classNames({
        "opacity-20": lineItem.isRemoved,
        "bg-green-50": lineItem.isCreated,
      })}
    >
      {/*** Line Item # ***/}
      <td
        className="sticky bg-white"
        style={{ left: "-1px", boxShadow: "inset -1px 0 0px #e3e3e3" }}
      >
        {lineItem.sortIndex + 1}
      </td>

      {/*** Selection ***/}

      {/*<td*/}
      {/*  className="sticky bg-white"*/}
      {/*  style={{ left: "24px", boxShadow: "inset -1px 0 0px #e3e3e3" }}*/}
      {/*>*/}
      {/*  <Checkbox*/}
      {/*    style={{ margin: "0 -4px 0 4px" }}*/}
      {/*    checked={*/}
      {/*      !!state.selectedItems.find(*/}
      {/*        (si) =>*/}
      {/*          si.lineItemIndex === lineItemIndex &&*/}
      {/*          si.lineItemGroupIndex === lineItemGroupIndex*/}
      {/*      )*/}
      {/*    }*/}
      {/*    onClick={() => {*/}
      {/*      if (*/}
      {/*        state.selectedItems.find(*/}
      {/*          (si) =>*/}
      {/*            si.lineItemIndex === lineItemIndex &&*/}
      {/*            si.lineItemGroupIndex === lineItemGroupIndex*/}
      {/*        )*/}
      {/*      ) {*/}
      {/*        dispatch({*/}
      {/*          type: "removeSelectedItem",*/}
      {/*          lineItemGroupIndex,*/}
      {/*          lineItemIndex,*/}
      {/*        });*/}
      {/*      } else {*/}
      {/*        dispatch({*/}
      {/*          type: "addSelectedItem",*/}
      {/*          lineItemGroupIndex,*/}
      {/*          lineItemIndex,*/}
      {/*        });*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</td>*/}

      {/*** Product ***/}
      {visibleDefaultColumns.includes("product") && (
        <td className="space-y-2">
          <LineItemProductDetails
            readOnly={readOnly}
            configuration={lineItem.configuration}
            onConfigurationChange={async (c) => {
              await applyConfigurationChange({
                configuration: c,
                lineItemGroupIndex,
                lineItemIndex,
                apolloClient,
                state,
                dispatch,
                shipToAddressId,
                fulfillmentContactId,
              });
            }}
            onEdit={() => {
              dispatch({
                type: "beginEditingConfiguration",
                lineItemGroupIndex,
                lineItemIndex,
              });
            }}
            onRemove={() => {
              dispatch({
                type: "removeLineItemConfiguration",
                lineItemGroupIndex,
                lineItemIndex,
              });
            }}
          />

          <h4 className="font-medium">Freight Rules</h4>

          <FreightRules
            hasConfiguration={Boolean(lineItem.configuration)}
            isCustomConfiguration={
              lineItem.configuration?.isCustomConfiguration
            }
            catalogProductId={lineItem.configuration?.catalogProductId}
            customVendorId={lineItem.configuration?.customVendorId}
          />
        </td>
      )}

      {/*** Description ***/}

      {visibleDefaultColumns.includes("description") && (
        <td>
          <InternalExternalNotesEditor
            readOnly={readOnly}
            internalNotesValue={lineItem.internalDescription}
            externalNotesValue={lineItem.description}
            onInternalNotesChange={(internalDescription) =>
              dispatch({
                type: "setLineItemInternalDescription",
                lineItemGroupIndex,
                lineItemIndex,
                internalDescription,
              })
            }
            onExternalNotesChange={(description) =>
              dispatch({
                type: "setLineItemDescription",
                lineItemGroupIndex,
                lineItemIndex,
                description,
              })
            }
          />
        </td>
      )}

      {/*** Quantity ***/}
      {visibleDefaultColumns.includes("quantity") && (
        <td>
          <QuantityCell
            value={lineItem.quantity}
            disabled={readOnly}
            onChange={(quantity) =>
              dispatch({
                type: "setLineItemQuantity",
                lineItemGroupIndex,
                lineItemIndex,
                quantity,
              })
            }
            onConfirm={() => {}}
          />
        </td>
      )}

      {/*** Vendor List Price ***/}
      {visibleDefaultColumns.includes("vendorListPrice") && (
        <td>
          <InputGroup
            disabled
            readOnly
            value={formatPrice(vendorListPrice)}
            rightElement={
              <Tooltip content="Vendor list price is determined from CAP catalog data.">
                <Button icon="info-sign" minimal />
              </Tooltip>
            }
          />
        </td>
      )}

      {/*** Discount List Price Multiplier ***/}
      {visibleDefaultColumns.includes("discountListPriceMultiplier") && (
        <td>
          <NumberCell
            value={lineItem.listPriceMultiplier}
            disabled={readOnly}
            onChange={(e) =>
              dispatch({
                type: "setLineItemListPriceMultiplier",
                lineItemGroupIndex,
                lineItemIndex,
                listPriceMultiplier: e,
              })
            }
            rightElement={
              <>
                <DeviantValueWarning
                  isDeviant={
                    !!lineItemCatalogProduct &&
                    lineItemCatalogProduct.vendor.listPriceMultiplier !==
                      lineItem.listPriceMultiplier
                  }
                  readOnly={readOnly}
                  label="discount list price multiplier"
                  expectedValue={`${lineItemCatalogProduct?.vendor.listPriceMultiplier}`}
                  onApplyRecommendedValue={() =>
                    dispatch({
                      type: "setLineItemListPriceMultiplier",
                      lineItemGroupIndex,
                      lineItemIndex,
                      listPriceMultiplier:
                        lineItemCatalogProduct!.vendor.listPriceMultiplier,
                    })
                  }
                />
              </>
            }
          />
        </td>
      )}

      {/*** Purchase Price ***/}
      {visibleDefaultColumns.includes("purchasePrice") && (
        <td>
          <InputGroup
            disabled
            readOnly
            value={formatPrice(purchasePrice)}
            rightElement={
              <Tooltip content="Purchase Price = Vendor List Price × Discount List Price Multiplier">
                <Button icon="info-sign" minimal />
              </Tooltip>
            }
          />
        </td>
      )}

      {/*** Gross Profit % ***/}
      {visibleDefaultColumns.includes("grossProfitPercent") && (
        <td>
          <NumberCell
            disabled={readOnly}
            value={lineItem.grossProfitPercent}
            onChange={(e) =>
              dispatch({
                type: "setLineItemGrossProfitPercent",
                lineItemGroupIndex,
                lineItemIndex,
                grossProfitPercent: e,
              })
            }
            rightElement={
              <DeviantValueWarning
                isDeviant={
                  !!lineItemCatalogProduct &&
                  lineItemCatalogProduct.vendor.grossProfitPercent !==
                    lineItem.grossProfitPercent
                }
                readOnly={readOnly}
                label="gross profit percentage"
                expectedValue={`${lineItemCatalogProduct?.vendor.grossProfitPercent}%`}
                onApplyRecommendedValue={() =>
                  dispatch({
                    type: "setLineItemGrossProfitPercent",
                    lineItemGroupIndex,
                    lineItemIndex,
                    grossProfitPercent:
                      lineItemCatalogProduct!.vendor.grossProfitPercent,
                  })
                }
              />
            }
          />
        </td>
      )}

      {/*** Suggested Sell Price ***/}
      {visibleDefaultColumns.includes("suggestedSellPrice") && (
        <td>
          <InputGroup
            disabled
            readOnly
            value={formatPrice(suggestedSellPrice)}
            rightElement={
              <Tooltip content="Suggested Sell Price = Purchase Price + Gross Profit %">
                <Button icon="info-sign" minimal />
              </Tooltip>
            }
          />
        </td>
      )}

      {/*** Actual Sell Price ***/}
      {visibleDefaultColumns.includes("actualSellPrice") && (
        <td>
          <PriceCell
            disabled={readOnly}
            value={lineItem.sellPrice}
            onChange={(e) =>
              dispatch({
                type: "setLineItemSellPrice",
                lineItemGroupIndex,
                lineItemIndex,
                sellPrice: e,
              })
            }
            rightElement={
              <DeviantValueWarning
                isDeviant={
                  !!lineItemCatalogProduct &&
                  suggestedSellPrice !== lineItem.sellPrice
                }
                readOnly={readOnly}
                label="sell price"
                expectedValue={formatPrice(suggestedSellPrice)}
                onApplyRecommendedValue={() =>
                  dispatch({
                    type: "setLineItemSellPrice",
                    lineItemGroupIndex,
                    lineItemIndex,
                    sellPrice: suggestedSellPrice,
                  })
                }
              />
            }
          />
        </td>
      )}

      {/*** Tax ***/}
      {visibleDefaultColumns.includes("tax") && (
        <td>
          <TaxCell
            configuration={lineItem.configuration}
            totalSellPrice={lineItem.sellPrice * lineItem.quantity}
            taxAmount={lineItem.taxAmount}
            taxCode={lineItem.taxCode}
            readOnly={readOnly}
            onTaxCodeChange={(taxCode) =>
              dispatch({
                type: "setLineItemTaxCode",
                lineItemGroupIndex,
                lineItemIndex,
                taxCode,
              })
            }
          />
        </td>
      )}

      {/*** Total Sell Price ***/}
      {visibleDefaultColumns.includes("totalSellPrice") && (
        <td>
          <InputGroup
            disabled
            readOnly
            value={formatPrice(lineItem.sellPrice * lineItem.quantity)}
            rightElement={
              <Tooltip content="Total Sell Price = (Sell Price × Quantity) + Tax">
                <Button icon="info-sign" minimal />
              </Tooltip>
            }
          />
        </td>
      )}

      {/*** Additional Columns ***/}
      {AdditionalColumns}

      {/*** Actions ***/}
      <td
        className="sticky bg-white"
        style={{ right: "-1px", boxShadow: "inset 1px 0 0px #e3e3e3" }}
      >
        {!readOnly && (
          <Popover minimal position="bottom" targetClassName="w-full">
            <Button minimal small fill icon="more" />
            <LineItemMenu
              sortIndex={lineItem.sortIndex}
              isRemoved={lineItem.isRemoved}
              totalLineItems={lineItemGroup.lineItems.length}
              onMoveUp={() =>
                dispatch({
                  type: "moveLineItemUp",
                  lineItemGroupIndex,
                  lineItemIndex,
                })
              }
              onMoveDown={() =>
                dispatch({
                  type: "moveLineItemDown",
                  lineItemGroupIndex,
                  lineItemIndex,
                })
              }
              onRemove={() =>
                dispatch({
                  type: "removeLineItem",
                  lineItemGroupIndex,
                  lineItemIndex,
                })
              }
              onUndoRemove={() =>
                dispatch({
                  type: "undoRemoveLineItem",
                  lineItemGroupIndex,
                  lineItemIndex,
                })
              }
            />
          </Popover>
        )}
      </td>
    </tr>
  );
}
