import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { usePurchaseOrder } from "../../../../../hooks/usePurchaseOrder";
import {
  Project,
  PurchaseOrderShipmentLineItem,
} from "../../../../../types/ApiTypes";
import { PurchaseOrderSubnavbar } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderSubnavbar";
import { Button, Spinner } from "@blueprintjs/core";
import { RecordPurchaseOrderShipmentDrawer } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/RecordPurchaseOrderShipmentDrawer";
import { usePurchaseOrderShipments } from "../../../../../hooks/usePurchaseOrderShipments";
import { PurchaseOrderShipment } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderShipment";
import { CancelPurchaseOrderShipmentDialog } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/shipments/CancelPurchaseOrderShipmentDialog";
import { GenerateInventoryLabelsDialog } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/shipments/GenerateInventoryLabelsDialog";
import { ShipmentDetailsDrawer } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/shipments/ShipmentDetailsDrawer";
import { getVendorName } from "../../../../../helpers/PurchaseOrderHelpers";
import { gql, useQuery } from "@apollo/client";
import { DefaultToaster } from "../../../../../components/shared/DefaultToaster/DefaultToaster";
import { ReceiveShipmentDrawer } from "../../../../../components/shared/ReceiveShipmentDialog/ReceiveShipmentDrawer";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export function ProjectPurchaseOrderShipments() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string; purchaseOrderId: string }>();
  const projectId = parseInt(match.params.projectId);
  const purchaseOrderId = parseInt(match.params.purchaseOrderId);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const { purchaseOrder, loading: purchaseOrderLoading } =
    usePurchaseOrder(purchaseOrderId);

  const {
    shipments,
    loading: shipmentsLoading,
    updateShipment,
    refresh,
  } = usePurchaseOrderShipments(purchaseOrderId);

  const [recordPurchaseOrderShipmentOpen, setRecordPurchaseOrderShipmentOpen] =
    useState<boolean>(false);
  const [purchaseOrderShipmentToCancel, setPurchaseOrderShipmentToCancel] =
    useState<number>();
  const [shipmentToShowDetailsFor, setShipmentToShowDetailsFor] =
    useState<number>();
  const [shipmentIdToGenerateLabelsFor, setShipmentIdToGenerateLabelsFor] =
    useState<number>();

  const projectName = getProjectById.loading
    ? `Project #${projectId}`
    : getProjectById.data!.projects[0].name;

  const purchaseOrderName = purchaseOrder
    ? `${purchaseOrder!.code} (${purchaseOrder!.vendor!.name})`
    : "";

  return (
    <>
      <ReceiveShipmentDrawer
        isOpen={!!shipmentIdToGenerateLabelsFor}
        shipmentId={shipmentIdToGenerateLabelsFor}
        onClose={() => setShipmentIdToGenerateLabelsFor(undefined)}
        onReceived={() => {
          history.push(
            `/projects/${projectId}/purchase-orders/${purchaseOrderId}/inventory`
          );
          setShipmentIdToGenerateLabelsFor(undefined);
        }}
      />

      <Helmet>
        <title>{`Shipments - ${purchaseOrderName} - ${projectName}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Purchase Orders",
            href: `/projects/${projectId}/purchase-orders`,
          },
          {
            text: purchaseOrderName,
            href: `/projects/${projectId}/purchase-orders/${purchaseOrderId}`,
          },
        ]}
      />

      <PurchaseOrderSubnavbar title="Purchase Order Shipments" />

      <PortalContainer>
        <RecordPurchaseOrderShipmentDrawer
          isOpen={recordPurchaseOrderShipmentOpen}
          purchaseOrderId={purchaseOrderId}
          onSubmit={() => {
            setRecordPurchaseOrderShipmentOpen(false);
            refresh();
          }}
          onClose={() => setRecordPurchaseOrderShipmentOpen(false)}
        />

        <CancelPurchaseOrderShipmentDialog
          isOpen={!!purchaseOrderShipmentToCancel}
          onSubmit={() => {
            setPurchaseOrderShipmentToCancel(undefined);
            refresh();
          }}
          onClose={() => setPurchaseOrderShipmentToCancel(undefined)}
          purchaseOrderShipmentId={purchaseOrderShipmentToCancel}
        />

        <ShipmentDetailsDrawer
          shipmentId={shipmentToShowDetailsFor}
          isOpen={!!shipmentToShowDetailsFor}
          onClose={() => setShipmentToShowDetailsFor(undefined)}
        />

        {shipmentsLoading && <Spinner />}

        {shipmentsLoading ||
          shipments.map((shipment) => (
            <PurchaseOrderShipment
              purchaseOrderId={purchaseOrderId}
              shipmentId={shipment.id}
              projectId={projectId}
              shipment={shipment}
              onShipmentChange={updateShipment}
              onCancelShipment={() =>
                setPurchaseOrderShipmentToCancel(shipment.id)
              }
              onReceiveShipment={() =>
                setShipmentIdToGenerateLabelsFor(shipment.id)
              }
              onShowDetails={() => setShipmentToShowDetailsFor(shipment.id)}
            />
          ))}

        <Button
          icon="folder-new"
          text="Record Shipment"
          onClick={() => setRecordPurchaseOrderShipmentOpen(true)}
        />
      </PortalContainer>
    </>
  );
}
