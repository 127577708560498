import { Address } from "../types/ApiTypes";

export function formatAddress(address?: Address) {
  if (!address) return "";

  return [
    address.street1,
    address.street2,
    [address.city, address.state, address.postalCode]
      .filter(Boolean)
      .join(", "),
  ]
    .filter(Boolean)
    .join("\n");
}
