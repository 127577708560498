import { PurchaseOrderShipment } from "../../../../types/ApiTypes";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/DateHelper";

export type AnnotatedPurchaseOrderShipment = PurchaseOrderShipment & {
  message: string;
  elapsed: boolean;
};

const ACTUAL_ARRIVAL_DATE = 1;
const EXPECTED_ARRIVAL_DATE = 2;
const ACTUAL_SHIP_DATE = 3;
const EXPECTED_SHIP_DATE = 4;
const NO_DATE = 5;

const getMostPertinentDate = (shipment: PurchaseOrderShipment) => {
  if (shipment.actualArrivalDate)
    return [ACTUAL_ARRIVAL_DATE, new Date(shipment.actualArrivalDate)];
  if (shipment.expectedArrivalDate)
    return [EXPECTED_ARRIVAL_DATE, new Date(shipment.expectedArrivalDate)];
  if (shipment.actualShipDate)
    return [ACTUAL_SHIP_DATE, new Date(shipment.actualShipDate)];
  if (shipment.expectedShipDate)
    return [EXPECTED_ARRIVAL_DATE, new Date(shipment.expectedShipDate)];
  return [NO_DATE, null];
};

export const getAnnotatedShipments = (
  shipments: PurchaseOrderShipment[]
): { oneWasElapsed: boolean; results: AnnotatedPurchaseOrderShipment[] } => {
  var oneWasElapsed = false;

  const annotateShipment = (
    shipment: PurchaseOrderShipment
  ): AnnotatedPurchaseOrderShipment => {
    var [category, dateObject] = getMostPertinentDate(shipment);
    var date = dateObject != null ? (dateObject as Date) : new Date();
    var elapsed = category == NO_DATE || date < new Date();
    var elapsedMessage = elapsed ? " (overdue)" : "";
    switch (category) {
      case ACTUAL_SHIP_DATE:
        return {
          ...shipment,
          message: "Shipped on " + formatDate(date),
          elapsed: false,
        };
      case EXPECTED_SHIP_DATE:
        if (elapsed) oneWasElapsed = true;
        return {
          ...shipment,
          message: "Expected to ship on " + formatDate(date) + elapsedMessage,
          elapsed,
        };
      case ACTUAL_ARRIVAL_DATE:
        return {
          ...shipment,
          message: "Arrived on " + formatDate(date) + elapsedMessage,
          elapsed: false,
        };
      case EXPECTED_ARRIVAL_DATE:
        if (elapsed) oneWasElapsed = true;
        return {
          ...shipment,
          message: "Expected to arrive on " + formatDate(date) + elapsedMessage,
          elapsed,
        };
      default:
        return {
          ...shipment,
          message: "No information provided.",
          elapsed: false,
        };
    }
  };

  return {
    results: shipments.map(annotateShipment),
    oneWasElapsed: oneWasElapsed,
  };
};

export type PurchaseOrderShipmentTimelineProps = {
  shipments: AnnotatedPurchaseOrderShipment[];
};

export function PurchaseOrderShipmentTimeline({
  shipments,
}: PurchaseOrderShipmentTimelineProps) {
  return (
    <>
      {shipments.length > 0 ? (
        <ul>
          {shipments?.map((shipment) => (
            <li key={shipment?.id} className="pb-1">
              <span className="font-medium pr-2">Shipment {shipment?.id}</span>{" "}
              <span className={shipment?.elapsed ? "text-red-500" : ""}>
                {shipment?.message}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p>
          Nothing in this purchase order is recorded as having been shipped yet.
        </p>
      )}
    </>
  );
}
