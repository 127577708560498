import React, { useState } from "react";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import {
  InventoryItemInspectionStatus,
  ProductConfiguration,
} from "../../types/ApiTypes";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { EntityCodeType, getEntityCode } from "../../helpers/EntityCodeHelper";
import { encodePriceAsInteger, formatPrice } from "../../helpers/PriceHelper";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../helpers/CatalogHelper";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { EntityLink } from "../../components/reports/EntityLink";
import { PrintLabelsDrawer } from "../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PrintLabelsDrawer";

type GetInventoryReportResult = {
  inventoryItems: {
    id: number;
    used: boolean;
    quantity: number;
    mostRecentInspectionStatus?: InventoryItemInspectionStatus;
    fulfillmentLineItems: {
      id: number;
      fulfillment: {
        id: number;
        project: {
          id: number;
        };
      };
    }[];
    configuration: ProductConfiguration;
    location: {
      id: number;
      name: string;
    };
    inventoryItemLabels: {
      id: number;
      createdAt: string;
    }[];
    purchaseOrderShipmentLineItem?: {
      id: number;
      purchaseOrderShipment: {
        id: number;
      };
      purchaseOrderLineItem: {
        id: number;
        salesOrderLineItem: {
          id: number;
          sortIndex: number;
          sellPrice: number;
          salesOrderLineItemGroup: {
            id: number;
            salesOrder: {
              id: number;
              code: string;
              sortIndex: number;
              project: {
                id: number;
                primaryContact?: FormatContactNameContact;
              };
            };
          };
        };
      };
    };
  }[];
};

const GetInventoryReport = gql`
  ${GetFullConfigurationCodeFragment}
  ${FormatContactNameContactFragment}

  query GetInventoryReport {
    inventoryItems(order: { createdAt: DESC }) {
      id
      used
      mostRecentInspectionStatus
      fulfillmentLineItems {
        id
        fulfillment {
          id
          project {
            id
          }
        }
      }
      configuration {
        id
        ...GetFullConfigurationCodeFragment
        productAndOptionPricing {
          totalListPrice
        }
        vendor {
          id
          name
          listPriceMultiplier
          grossProfitPercent
        }
      }
      location {
        id
        name
      }
      quantity
      inventoryItemLabels {
        id
        createdAt
      }
      purchaseOrderShipmentLineItem {
        id
        purchaseOrderShipment {
          id
        }
        purchaseOrderLineItem {
          id
          salesOrderLineItem {
            id
            sortIndex
            sellPrice
            salesOrderLineItemGroup {
              id
              salesOrder {
                id
                code
                project {
                  id
                  primaryContact {
                    id
                    ...FormatContactNameContactFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function formatInspectionStatus(status?: InventoryItemInspectionStatus) {
  switch (status) {
    case InventoryItemInspectionStatus.NoDamage:
      return "No Damage";
    case InventoryItemInspectionStatus.MajorDamage:
      return "Major Damage";
    case InventoryItemInspectionStatus.MinorDamage:
      return "Minor Damage";
    case InventoryItemInspectionStatus.Uninspected:
    case undefined:
      return "Not Inspected";
    default:
      return "Unknown";
  }
}

function calculateSuggestedSellPrice(
  inventoryItem: GetInventoryReportResult["inventoryItems"][0]
) {
  const listPrice =
    inventoryItem.configuration.productAndOptionPricing.totalListPrice;
  const listPriceMultiplier =
    inventoryItem.configuration.vendor.listPriceMultiplier;
  const grossProfitPercent =
    inventoryItem.configuration.vendor.grossProfitPercent;

  return listPrice * listPriceMultiplier * (1 + grossProfitPercent / 100);
}

export function PurchasingInventory() {
  const getInventoryReport = useQuery<GetInventoryReportResult>(
    GetInventoryReport,
    { fetchPolicy: "cache-and-network" }
  );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const [printLabelsForInventoryItemId, setPrintLabelsForInventoryItemId] =
    useState<number>();

  const reportLines =
    getInventoryReport.data?.inventoryItems.map((inventoryItem) => ({
      ...inventoryItem,
      type: inventoryItem.used ? "Used" : "New",
      fulfillmentStatus:
        inventoryItem.fulfillmentLineItems.length > 0
          ? "Fulfilled"
          : "Not Fulfilled",
      inspectionStatus: formatInspectionStatus(
        inventoryItem.mostRecentInspectionStatus
      ),
      listPrice:
        inventoryItem.configuration.productAndOptionPricing.totalListPrice /
        100,
      suggestedSellPrice: calculateSuggestedSellPrice(inventoryItem) / 100,
      location: inventoryItem.location?.name ?? "Unknown",
      vendor: inventoryItem.configuration.vendor.name,
      productCode: getFullConfigurationCode(inventoryItem.configuration),
      salesOrderCode:
        inventoryItem.purchaseOrderShipmentLineItem?.purchaseOrderLineItem
          .salesOrderLineItem.salesOrderLineItemGroup.salesOrder.code ?? "N/A",
      fulfillmentCodes: inventoryItem.fulfillmentLineItems
        .map((f) => `F-${f.fulfillment.id}`)
        .join(", "),
      shipmentCode: inventoryItem.purchaseOrderShipmentLineItem
        ?.purchaseOrderShipment
        ? `SHIP-${inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment.id}`
        : "N/A",
      customerName: inventoryItem.purchaseOrderShipmentLineItem
        ?.purchaseOrderLineItem.salesOrderLineItem.salesOrderLineItemGroup
        .salesOrder.project.primaryContact
        ? formatContactName(
            inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderLineItem
              .salesOrderLineItem.salesOrderLineItemGroup.salesOrder.project
              .primaryContact
          )
        : "N/A",
      receivedOn: new Date(inventoryItem.inventoryItemLabels[0].createdAt),
    })) ?? [];

  return (
    <>
      <PrintLabelsDrawer
        isOpen={!!printLabelsForInventoryItemId}
        inventoryItemId={printLabelsForInventoryItemId}
        onClose={() => setPrintLabelsForInventoryItemId(undefined)}
      />

      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Inventory Items"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getInventoryReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(reportLines, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
              filterable
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="INV #"
                width={150}
                field="id"
                cell={(props) => (
                  <td>
                    {getEntityCode(
                      props.dataItem.id,
                      EntityCodeType.InventoryItem
                    )}
                  </td>
                )}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Type"
                width={150}
                field="type"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Fulfillment Status"
                width={150}
                field="fulfillmentStatus"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Sales Order"
                width={250}
                field="salesOrderCode"
                cell={(props) => {
                  const dataItem = props.dataItem as typeof reportLines[0];

                  const salesOrderLineItem =
                    dataItem.purchaseOrderShipmentLineItem
                      ?.purchaseOrderLineItem.salesOrderLineItem;

                  if (!salesOrderLineItem) {
                    return <td>N/A</td>;
                  }

                  const salesOrder =
                    salesOrderLineItem.salesOrderLineItemGroup.salesOrder;

                  return (
                    <td>
                      <EntityLink
                        url={`/projects/${salesOrder.project.id}/sales-orders/${salesOrder.id}`}
                        text={`${salesOrder.code} (Line ${
                          salesOrderLineItem.sortIndex + 1
                        })`}
                      />
                    </td>
                  );
                }}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Shipment"
                width={250}
                field="shipmentCode"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Fulfillments"
                width={250}
                field="fulfillmentCodes"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Product Code"
                width={400}
                field="productCode"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Customer"
                width={300}
                field="customerName"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Inspection Status"
                width={200}
                field="inspectionStatus"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="List Price"
                width={150}
                field="listPrice"
                cell={(props) => (
                  <td>
                    {formatPrice(
                      encodePriceAsInteger(props.dataItem.listPrice)
                    )}
                  </td>
                )}
                filter="numeric"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Suggested Sell Price"
                width={150}
                field="suggestedSellPrice"
                cell={(props) => (
                  <td>
                    {formatPrice(
                      encodePriceAsInteger(props.dataItem.suggestedSellPrice)
                    )}
                  </td>
                )}
                filter="numeric"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Location"
                width={150}
                field="location"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Vendor"
                width={200}
                field="vendor"
              />

              <GridColumn
                title="Labels"
                width={150}
                cell={(props) => (
                  <td>
                    <Button
                      text="Print Labels"
                      small
                      icon="print"
                      onClick={() =>
                        setPrintLabelsForInventoryItemId(props.dataItem.id)
                      }
                    />
                  </td>
                )}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
