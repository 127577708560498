import { ProjectSubnavbar } from "../../../components/projects/[projectId]/ProjectSubnavbar";
import { NavLink } from "react-router-dom";
import React, { useCallback, useState } from "react";
import {
  CatalogProduct,
  ProductSellPriceMultiplier,
  UpdateProductSellPriceMultiplierPayload,
} from "../../../types/ApiTypes";
import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import { Button, Intent, Spinner } from "@blueprintjs/core";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import { useProductSellPriceMultipliers } from "./useProductSellPriceMultipliers";
import { ChooseProductDialog } from "../../../components/shared/ChooseProductMenu/ChooseProductDialog";
import { ProductOverrideItem } from "../../../components/admin/pricing/ProductOverrideItem";
import { DeleteConfirmationDialog } from "../../../components/shared/DeleteConfirmationDialog/DeleteConfirmationDialog";

export function ProductPricing() {
  const [chooseProductOpen, setChooseProductOpen] = useState(false);
  const [confirmRemoveOverride, setConfirmRemoveOverride] =
    useState<ProductSellPriceMultiplier>();

  const {
    productSellPriceMultipliers,
    createSellPriceMultiplier,
    updateSellPriceMultiplier,
    updateSellPriceMultiplierLoading,
    deleteSellPriceMultiplier,
    deleteSellPriceMultiplierLoading,
    loading,
  } = useProductSellPriceMultipliers();

  const addOverride = useCallback(
    async (product: CatalogProduct) => {
      setChooseProductOpen(false);

      await createSellPriceMultiplier({
        variables: {
          input: {
            productId: product.id,
          },
        },
      });

      DefaultToaster.show({
        message: "Created pricing override",
        intent: "success",
        icon: "tick-circle",
      });
    },
    [setChooseProductOpen]
  );

  const savePricingOverrideChanges = useCallback(
    async (payload: UpdateProductSellPriceMultiplierPayload) => {
      await updateSellPriceMultiplier({
        variables: {
          input: payload,
        },
      });
      DefaultToaster.show({
        message: "Pricing override updated",
        intent: "success",
        icon: "tick-circle",
      });
    },
    [updateSellPriceMultiplier]
  );

  const deletePricingOverride = useCallback(
    async (id: number) => {
      await deleteSellPriceMultiplier({
        variables: {
          id,
        },
      });
      DefaultToaster.show({
        message: "Pricing override deleted",
        intent: "success",
        icon: "tick-circle",
      });
    },
    [deleteSellPriceMultiplier]
  );

  return (
    <>
      <DeleteConfirmationDialog
        isOpen={!!confirmRemoveOverride}
        title={`Remove pricing override`}
        message={`Are you sure you want to remove the pricing override for ${confirmRemoveOverride?.product.code}?`}
        onConfirm={() => {
          deletePricingOverride(confirmRemoveOverride!.id);
          setConfirmRemoveOverride(undefined);
        }}
        onClose={() => {
          setConfirmRemoveOverride(undefined);
        }}
      />

      <ChooseProductDialog
        isOpen={chooseProductOpen}
        onClose={() => setChooseProductOpen(false)}
        onChooseProduct={(p) => addOverride(p)}
      />

      <ProjectSubnavbar
        title="Product Pricing"
        tabs={
          <ul>
            <li>
              <NavLink to="/admin/pricing/vendors" exact>
                Vendors
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/pricing/products" exact>
                Products
              </NavLink>
            </li>
          </ul>
        }
        actions={
          <>
            <Button
              intent={Intent.PRIMARY}
              text="Add Pricing Override"
              onClick={() => setChooseProductOpen(true)}
            />
          </>
        }
      />
      <PortalContainer>
        {loading && (
          <div className="flex items-center justify-center p-10">
            <Spinner />
          </div>
        )}

        {!loading && productSellPriceMultipliers && (
          <div className="space-y-2">
            {productSellPriceMultipliers.map((productSellPriceMultiplier) => (
              <ProductOverrideItem
                key={productSellPriceMultiplier.id}
                productSellPriceMultiplier={productSellPriceMultiplier}
                onChange={savePricingOverrideChanges}
                onRemove={() =>
                  setConfirmRemoveOverride(productSellPriceMultiplier)
                }
                loading={updateSellPriceMultiplierLoading}
              />
            ))}
          </div>
        )}
      </PortalContainer>
    </>
  );
}
