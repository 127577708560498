import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import { Button, Intent, Tag } from "@blueprintjs/core";
import { FlexTable } from "../../../components/shared/Table/FlexTable";
import { ProjectNavbar } from "../../../components/projects/[projectId]/ProjectNavbar";
import { useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { Project } from "../../../types/ApiTypes";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export default function Invoices() {
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const invoices = useMemo(
    () => [
      {
        id: 1,
        vendor: "9 to 5 Seating",
        recipient: "John Smith",
        orderNumber: "SO-02041995",
        amount: "$259.25",
        status: "Unpaid",
      },
      {
        id: 2,
        vendor: "9 to 5 Seating",
        recipient: "John Smith",
        orderNumber: "SO-02041995",
        amount: "$259.25",
        status: "Not Sent",
      },
      {
        id: 2,
        vendor: "9 to 5 Seating",
        recipient: "John Smith",
        orderNumber: "SO-02041995",
        amount: "$259.25",
        status: "Unpaid",
      },
      {
        id: 2,
        vendor: "9 to 5 Seating",
        recipient: "John Smith",
        orderNumber: "SO-02041995",
        amount: "$259.25",
        status: "Paid",
      },
    ],
    []
  );

  const columns = useMemo(
    () => [
      {
        id: "quoteNumber",
        Header: () => <div className="text-center">#</div>,
        Cell: ({ value }) => (
          <div className="text-center">
            <strong>{value}</strong>
          </div>
        ),
        accessor: (p) => p.id,
        maxWidth: 5,
      },
      {
        Header: "Recipient",
        accessor: "recipient",
      },
      {
        Header: "Order #",
        accessor: "orderNumber",
      },
      {
        Header: "Amount",
        accessor: "amount",
        maxWidth: 30,
      },
      {
        Header: "Status",
        accessor: "status",
        maxWidth: 30,
        className: "with-button",
        Cell: ({ row }) => (
          <Tag
            icon={row.original.status === "Paid" ? "tick" : "dot"}
            intent={
              row.original.status === "Paid" ? Intent.SUCCESS : Intent.NONE
            }
            round
            fill
            large
          >
            {row.original.status}
          </Tag>
        ),
      },
      {
        id: "actions",
        maxWidth: 35,
        className: "with-button",
        Cell: ({ row }) => (
          <Button text="View Invoice" rightIcon="chevron-right" fill />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Helmet>
        <title>Invoices - Project #1234</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
      />

      <PortalContainer>
        <FlexTable columns={columns} data={invoices} />
      </PortalContainer>
    </>
  );
}
