export enum EntityCodeType {
  Project,
  InventoryItem,
  Shipment,
}

export function getEntityLetterIdentifier(type: EntityCodeType) {
  switch (type) {
    case EntityCodeType.Project:
      return "PR";
    case EntityCodeType.InventoryItem:
      return "INV";
    case EntityCodeType.Shipment:
      return "S";
    default:
      throw new Error("Unknown EntityCodeType: " + type);
  }
}

export function getEntityCode(entityId: number, type: EntityCodeType) {
  const letterIdentifier = getEntityLetterIdentifier(type);
  return `${letterIdentifier}-${entityId}`;
}
