import { SyntheticEvent, useEffect, useState } from "react";
import { DrawerTitleToolbar } from "../../../../../shared/DrawerTitleToolbar/DrawerTitleToolbar";
import {
  EntityCodeType,
  getEntityCode,
} from "../../../../../../helpers/EntityCodeHelper";
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Popover,
  Position,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  PurchaseOrderShipment,
  UpdatePurchaseOrderShipmentInput,
} from "../../../../../../types/ApiTypes";
import dayjs from "dayjs";
import classNames from "classnames";
import { AttachmentsInput } from "../../../../../shared/AttachmentsInput/AttachmentsInput";

const CREATE_NOTE = gql`
  mutation CreateNote($input: CreatePurchaseOrderShipmentNotePayloadInput) {
    createPurchaseOrderShipmentNote(payload: $input) {
      id
    }
  }
`;

const UPDATE_SHIPMENT = gql`
  mutation UpdateShipment($input: UpdatePurchaseOrderShipmentPayloadInput) {
    updatePurchaseOrderShipment(payload: $input) {
      id
    }
  }
`;

const ADD_ATTACHMENT_TO_SHIPMENT = gql`
  mutation AddAttachmentToShipment($shipmentId: Int!, $attachmentId: Int!) {
    addAttachmentToPurchaseOrderShipment(
      purchaseOrderShipmentId: $shipmentId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

const REMOVE_ATTACHMENT_FROM_SHIPMENT = gql`
  mutation RemoveAttachmentFromShipment(
    $shipmentId: Int!
    $attachmentId: Int!
  ) {
    removeAttachmentFromPurchaseOrderShipment(
      purchaseOrderShipmentId: $shipmentId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

export type AddShipmentNoteProps = {
  purchaseOrderShipmentId: number;
  onSubmit: () => void;
};

export function AddShipmentNote({
  purchaseOrderShipmentId,
  onSubmit,
}: AddShipmentNoteProps) {
  const [createNote] = useMutation(CREATE_NOTE, {
    refetchQueries: [
      { query: GET_SHIPMENT, variables: { id: purchaseOrderShipmentId } },
    ],
  });

  const [note, setNote] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    await createNote({
      variables: { input: { purchaseOrderShipmentId, note } },
    });
    onSubmit();
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup label="Note">
        <TextArea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          fill
          required
        />
      </FormGroup>
      <Button type="submit" intent={Intent.PRIMARY}>
        Save
      </Button>
    </form>
  );
}

const GET_SHIPMENT = gql`
  query GetShipment($id: Int!) {
    purchaseOrderShipments(where: { id: { eq: $id } }) {
      id
      expectedArrivalDate
      actualArrivalDate
      expectedShipDate
      actualShipDate
      trackingNumber
      purchaseOrderShipmentAttachments {
        id
        attachment {
          id
          name
          contentType
          createdAt
          updatedAt
        }
      }
      purchaseOrder {
        id
        projectId
      }
      notes(order: { createdAt: DESC }) {
        id
        note
        createdAt
      }
      createdAt
    }
  }
`;

export type ShipmentDetailsProps = {
  shipmentId: number;
  onClose?: React.EventHandler<SyntheticEvent<HTMLElement, Event>>;
};

export function ShipmentDetails({ shipmentId, onClose }: ShipmentDetailsProps) {
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState("");

  const [updateShipment] = useMutation<
    PurchaseOrderShipment,
    { input: UpdatePurchaseOrderShipmentInput }
  >(UPDATE_SHIPMENT, {
    refetchQueries: [{ query: GET_SHIPMENT, variables: { id: shipmentId } }],
  });

  const [addAttachmentToShipment] = useMutation<
    PurchaseOrderShipment,
    { shipmentId: number; attachmentId: number }
  >(ADD_ATTACHMENT_TO_SHIPMENT, {
    refetchQueries: [{ query: GET_SHIPMENT, variables: { id: shipmentId } }],
  });

  const [removeAttachmentFromShipment] = useMutation<
    PurchaseOrderShipment,
    { shipmentId: number; attachmentId: number }
  >(REMOVE_ATTACHMENT_FROM_SHIPMENT, {
    refetchQueries: [{ query: GET_SHIPMENT, variables: { id: shipmentId } }],
  });

  const { data, loading } = useQuery<{
    purchaseOrderShipments: PurchaseOrderShipment[];
  }>(GET_SHIPMENT, {
    variables: {
      id: shipmentId,
    },
  });

  const shipment = data?.purchaseOrderShipments[0];

  useEffect(() => {
    if (loading) return;
    setTrackingNumber(shipment!.trackingNumber ?? "");
  }, [shipment, loading]);

  async function saveChanges() {
    await updateShipment({
      variables: {
        input: {
          id: shipmentId,
          trackingNumber,
        },
      },
    });
  }

  return (
    <>
      <DrawerTitleToolbar
        title={`${getEntityCode(shipmentId, EntityCodeType.Shipment)} Details`}
        onClose={onClose}
      />
      {loading && (
        <div className="p-2 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div>
          <div className="flex px-2 pt-2">
            <FormGroup label="Expected Ship Date" className="flex-1 pr-1">
              <DateInput
                value={
                  shipment!.expectedShipDate
                    ? new Date(shipment!.expectedShipDate)
                    : null
                }
                formatDate={(d) => d.toLocaleDateString()}
                parseDate={(s) => new Date(s)}
                placeholder="MM/DD/YYYY"
                fill
                disabled
              />
            </FormGroup>
            <FormGroup label="Actual Ship Date" className="flex-1 pl-1">
              <DateInput
                value={
                  shipment!.actualShipDate
                    ? new Date(shipment!.actualShipDate)
                    : null
                }
                formatDate={(d) => d.toLocaleDateString()}
                parseDate={(s) => new Date(s)}
                placeholder="MM/DD/YYYY"
                fill
                disabled
              />
            </FormGroup>
          </div>
          <div className="flex px-2">
            <FormGroup label="Expected Ship Date" className="flex-1 pr-1">
              <DateInput
                value={
                  shipment!.expectedArrivalDate
                    ? new Date(shipment!.expectedArrivalDate)
                    : null
                }
                formatDate={(d) => d.toLocaleDateString()}
                parseDate={(s) => new Date(s)}
                placeholder="MM/DD/YYYY"
                fill
                disabled
              />
            </FormGroup>
            <FormGroup label="Actual Ship Date" className="flex-1 pl-1">
              <DateInput
                value={
                  shipment!.actualArrivalDate
                    ? new Date(shipment!.actualArrivalDate)
                    : null
                }
                formatDate={(d) => d.toLocaleDateString()}
                parseDate={(s) => new Date(s)}
                placeholder="MM/DD/YYYY"
                fill
                disabled
              />
            </FormGroup>
          </div>
          <div className="px-2">
            <FormGroup label="Tracking #">
              <InputGroup
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                onBlur={saveChanges}
              />
            </FormGroup>
          </div>
          <div className="px-2">
            <FormGroup label="Attachments">
              <AttachmentsInput
                projectId={shipment!.purchaseOrder.projectId}
                attachments={shipment!.purchaseOrderShipmentAttachments.map(
                  (a) => a.attachment
                )}
                onAddAttachment={(a) =>
                  addAttachmentToShipment({
                    variables: {
                      attachmentId: a.id!,
                      shipmentId: shipment!.id,
                    },
                  })
                }
                onRemoveAttachment={(a) =>
                  removeAttachmentFromShipment({
                    variables: {
                      attachmentId: a.id!,
                      shipmentId: shipment!.id,
                    },
                  })
                }
              />
            </FormGroup>

            <FormGroup label="Notes">
              <div className="rounded border bg-white overflow-hidden">
                {shipment!.notes.length === 0 && (
                  <div className="py-4 text-center">
                    <p className="m-0 text-gray-500">No notes</p>
                  </div>
                )}
                {shipment!.notes.length !== 0 && (
                  <div className="p-1">
                    {shipment!.notes.map((note, index) => (
                      <div
                        key={note.id}
                        className={classNames("p-2 bg-gray-100 rounded flex", {
                          "mb-1": index < shipment!.notes.length - 1,
                        })}
                      >
                        <p className="m-0 flex-1">{note.note}</p>
                        <p className="text-sm m-0 font-medium text-gray-500">
                          {dayjs(note.createdAt).format("MM/DD/YYYY")}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
                <div className="border-t bg-gray-50 p-1">
                  <Popover
                    isOpen={addNoteOpen}
                    onClose={() => setAddNoteOpen(false)}
                    position={Position.TOP}
                    minimal
                  >
                    <Button
                      icon="plus"
                      onClick={() => setAddNoteOpen(true)}
                      small
                      minimal
                    />
                    <div className="p-2 w-80">
                      <AddShipmentNote
                        purchaseOrderShipmentId={shipmentId}
                        onSubmit={() => setAddNoteOpen(false)}
                      />
                    </div>
                  </Popover>
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
      )}
    </>
  );
}
