import { gql, useQuery } from "@apollo/client";
import {
  Dialog,
  DialogProps,
  InputGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { CatalogProduct } from "../../../types/ApiTypes";
import React, { useState } from "react";
import { ChooseFromCatalogPanel } from "./ChooseFromCatalogPanel";

const SEARCH_PRODUCTS = gql`
  query SearchProducts($query: String!) {
    catalogProducts(where: { code: { contains: $query } }) {
      id
      description
      code
    }
  }
`;

type ChooseProductDialogProps = DialogProps & {
  onChooseProduct: (product: CatalogProduct) => void;
};

export function ChooseProductDialog({
  onChooseProduct,
  ...dialogProps
}: ChooseProductDialogProps) {
  const [productCodeQuery, setProductCodeQuery] = useState("");

  const searchProducts = useQuery<
    { catalogProducts: CatalogProduct[] },
    { query: string }
  >(SEARCH_PRODUCTS, {
    variables: {
      query: productCodeQuery.trim(),
    },
    skip: productCodeQuery.trim().length === 0,
  });

  return (
    <Dialog title="Choose a Product" {...dialogProps}>
      <div className="bg-white border-b divide-y">
        <div className="p-2">
          <InputGroup
            type="search"
            leftIcon="search"
            placeholder="Search by product code..."
            value={productCodeQuery}
            onChange={(e) => setProductCodeQuery(e.target.value)}
          />
        </div>
        {searchProducts.loading && (
          <div className="flex items-center justify-center p-2">
            <Spinner size={20} />
          </div>
        )}
        {!searchProducts.loading && searchProducts.data?.catalogProducts && (
          <Menu>
            <MenuDivider title="Search results" />
            {searchProducts.data.catalogProducts.map((product) => (
              <MenuItem
                text={product.description}
                icon="tag"
                label={product.code}
                onClick={() => onChooseProduct(product)}
              />
            ))}
          </Menu>
        )}
      </div>
      <div className="bg-white">
        <ChooseFromCatalogPanel
          onChoose={(product) => onChooseProduct(product)}
          onGoBack={() => {}}
          rootLevel
        />
      </div>
    </Dialog>
  );
}
