import { Menu, MenuDivider, MenuItem } from "@blueprintjs/core";

type LineItemMenuProps = {
  sortIndex: number;
  totalLineItems: number;
  isRemoved: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemove: () => void;
  onUndoRemove: () => void;
};

export function LineItemMenu({
  sortIndex,
  totalLineItems,
  isRemoved,
  onMoveUp,
  onMoveDown,
  onRemove,
  onUndoRemove,
}: LineItemMenuProps) {
  return (
    <Menu>
      {sortIndex > 0 && (
        <MenuItem icon="chevron-up" text="Move Up" onClick={onMoveUp} />
      )}

      {sortIndex < totalLineItems - 1 && (
        <MenuItem icon="chevron-down" text="Move Down" onClick={onMoveDown} />
      )}

      {totalLineItems > 1 && <MenuDivider />}

      {isRemoved ? (
        <MenuItem icon="undo" text="Undo Remove" onClick={onUndoRemove} />
      ) : (
        <MenuItem
          icon="trash"
          text="Remove"
          intent="danger"
          onClick={onRemove}
        />
      )}
    </Menu>
  );
}
