import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Quote, SalesOrder } from "../types/ApiTypes";
import { useApiError } from "./useApiError";
import { useHistory } from "react-router-dom";

export type UseQuoteVersionGroupQuotesArgs = {
  projectId: number;
  quoteVersionGroupId: number;
};

export function useQuoteVersionGroupQuotes({
  projectId,
  quoteVersionGroupId,
}: UseQuoteVersionGroupQuotesArgs) {
  const history = useHistory();

  const handleConvertError = useApiError("convert quote to sales order");
  const handleCreateError = useApiError("create quote");
  const handleUpdateError = useApiError("update quote");
  const handleDeleteError = useApiError("delete quote");

  const convertQuote = useCallback(
    (quote: Quote) => {
      axios
        .post(`quotes/${quote.id}/converttosalesorder`)
        .then((r) => r.data as SalesOrder)
        .then((salesOrder) =>
          history.push(`/projects/${projectId}/sales-orders/${salesOrder.id}`)
        )
        .catch(handleConvertError);
    },
    [quoteVersionGroupId, history]
  );

  function createQuote(quote: Quote): Promise<Quote> {
    return axios
      .post("quotes", quote)
      .then((r) => r.data as Quote)
      .catch(handleCreateError);
  }

  const updateQuote = useCallback((quote: Quote) => {
    axios
      .put(`quotes/${quote.id}`, quote)
      .then((r) => r.data as Quote)
      .catch(handleUpdateError);
  }, []);

  const deleteQuote = useCallback((quote: Quote) => {
    axios
      .delete(`quotes/${quote.id}`)
      .then((r) => r.data as Quote)
      .catch(handleDeleteError);
  }, []);

  return {
    convertQuote,
    createQuote,
    updateQuote,
    deleteQuote,
  };
}
