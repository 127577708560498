import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  InventoryItem,
  PurchaseOrder,
  PurchaseOrderShipment,
} from "../../../../types/ApiTypes";
import { useHistory, useParams } from "react-router-dom";

const GET_PURCHASE_ORDER_INVENTORY_ITEMS = gql`
  query GetPurchaseOrderInventoryItems($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      inventoryItems {
        id
        location {
          id
          name
        }
      }
    }
  }
`;

export type PurchaseOrderListStockingStatusCellProp = {
  projectId: number;
  purchaseOrderId: number;
};

export function PurchaseOrderListStockingStatusCell({
  projectId,
  purchaseOrderId,
}: PurchaseOrderListStockingStatusCellProp) {
  const history = useHistory();

  const { data, loading, error } = useQuery<{
    purchaseOrders: PurchaseOrder[];
  }>(GET_PURCHASE_ORDER_INVENTORY_ITEMS, {
    variables: {
      purchaseOrderId: purchaseOrderId,
    },
    fetchPolicy: "network-only",
  });

  const purchaseOrder = data?.purchaseOrders[0];
  const inventoryItems = purchaseOrder?.inventoryItems;

  function getStockedItems() {
    return inventoryItems!.filter((i) => i.location !== null);
  }

  function getIntent() {
    const stockedItems = getStockedItems();

    if (stockedItems.length === 0) {
      return Intent.NONE;
    } else if (
      stockedItems.length > 0 &&
      stockedItems.length === inventoryItems!.length
    ) {
      return Intent.SUCCESS;
    } else {
      return Intent.WARNING;
    }
  }

  function getText() {
    const stockedItems = getStockedItems();

    if (stockedItems.length === 0) {
      return "Begin Stocking";
    } else {
      return `${stockedItems.length}/${inventoryItems!.length} Stocked`;
    }
  }

  function getButtonLabel() {
    const stockedItems = getStockedItems();

    if (stockedItems.length === 0) {
      return "Begin Stocking";
    } else if (
      stockedItems.length > 0 &&
      stockedItems.length === inventoryItems!.length
    ) {
      return "Manage Inventory";
    } else {
      return "Continue Stocking";
    }
  }

  return (
    <PurchaseOrderStatusCell
      text={loading ? "" : getText()}
      intent={loading ? Intent.NONE : getIntent()}
      loading={loading}
    >
      {!loading && (
        <div className="p-2">
          <div className="border-b pb-2 mb-2 max-w-sm">
            <h3 className="font-bold mb-2">Inspection</h3>
            {getStockedItems().length === 0 && (
              <div>
                <p className="m-0">
                  No items on the purchase order have been stocked.
                </p>
              </div>
            )}
            {getStockedItems().length > 0 && (
              <div>
                <p className="m-0">
                  {getStockedItems().length}/{inventoryItems!.length} items on
                  the purchase order have been stocked.
                </p>
              </div>
            )}
          </div>
          <Button
            fill
            rightIcon="chevron-right"
            text={getButtonLabel()}
            intent={Intent.PRIMARY}
            onClick={() =>
              history.push(
                `/projects/${projectId}/purchase-orders/${purchaseOrderId}/inventory`
              )
            }
          />
        </div>
      )}
    </PurchaseOrderStatusCell>
  );
}
