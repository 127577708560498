import { CatalogProduct } from "../../../types/ApiTypes";
import { useState } from "react";
import { Button, Intent, Menu, MenuItem, Spinner } from "@blueprintjs/core";
import { ProductTree } from "./ProductTree";
import { gql, useQuery } from "@apollo/client";

const GET_SERVICE_ITEMS = gql`
  query GetServiceItems($serviceCatalogId: Int!) {
    catalogProducts(where: { catalogId: { eq: $serviceCatalogId } }) {
      id
      description
      code
    }
  }
`;

export function useServiceItems() {
  if (!process.env.REACT_APP_SERVICE_CATALOG_ID) {
    throw new Error(
      "REACT_APP_SERVICE_CATALOG_ID is undefined. Check the .env file"
    );
  }

  const getServiceItems = useQuery<
    { catalogProducts: CatalogProduct[] },
    { serviceCatalogId: number }
  >(GET_SERVICE_ITEMS, {
    variables: {
      serviceCatalogId: parseInt(process.env.REACT_APP_SERVICE_CATALOG_ID),
    },
  });

  return {
    loading: getServiceItems.loading,
    error: getServiceItems.error,
    serviceItems: getServiceItems.data?.catalogProducts,
  };
}

export type ChooseServicePanelProps = {
  onChoose: (product: CatalogProduct) => void;
  onGoBack: () => void;
};

export function ChooseServicePanel({
  onChoose,
  onGoBack,
}: ChooseServicePanelProps) {
  const { serviceItems, loading } = useServiceItems();

  return (
    <div>
      <div className="p-1 flex items-center border-b">
        <Button
          icon="chevron-left"
          onClick={onGoBack}
          className="mr-2"
          minimal
          small
        />
        <h2 className="font-medium">Choose Service</h2>
      </div>
      <div className="max-h-96 overflow-y-auto">
        {loading && <Spinner />}
        {!loading && (
          <Menu>
            {serviceItems!.map((serviceItem) => (
              <MenuItem
                key={serviceItem.id}
                text={serviceItem.description}
                label={serviceItem.code}
                onClick={() => onChoose(serviceItem)}
              />
            ))}
          </Menu>
        )}
      </div>
    </div>
  );
}
