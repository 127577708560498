import React from "react";
import classNames from "classnames";
import styles from "./Table.module.scss";

export type TableHeaderProps = {
  children: React.ReactNode | React.ReactNode[];
  collapsed?: boolean;
  className?: string;
};

export function TableHeader({
  children,
  collapsed,
  className,
}: TableHeaderProps) {
  return (
    <div
      className={classNames(
        styles.tableHeader,
        { [styles.collapsed]: collapsed },
        className
      )}
    >
      {children}
    </div>
  );
}
