import "normalize.css/normalize.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "handsontable/dist/handsontable.full.min.css";
import "./styles/index.css";
import { registerAllModules } from "handsontable/registry";
import { FocusStyleManager, Icon, Spinner } from "@blueprintjs/core";
import { PortalNavbar } from "./components/shared/PortalNavbar/PortalNavbar";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Projects from "./pages/projects";
import Quotes from "./pages/projects/[projectId]/quotes";
import SalesOrders from "./pages/projects/[projectId]/sales-orders";
import Invoices from "./pages/projects/[projectId]/invoices";
import QuoteDetails from "./pages/projects/[projectId]/quotes/[quoteId]";
import SalesOrderDetails from "./pages/projects/[projectId]/sales-orders/[salesOrderId]";
import { Index } from "./pages";
import { useAuth0 } from "@auth0/auth0-react";
import PurchaseOrders from "./pages/projects/[projectId]/purchase-orders";
import { Companies } from "./pages/contacts/companies";
import { People } from "./pages/contacts/people";
import { Attachments } from "./pages/projects/[projectId]/attachments";
import QuoteConversion from "./pages/projects/[projectId]/quotes/[quoteId]/conversion";
import { ProjectPurchaseOrderMetadata } from "./pages/projects/[projectId]/purchase-orders/[purchaseOrderId]/metadata";
import { ProjectSettings } from "./pages/projects/[projectId]/settings";
import { ProjectPurchaseOrderDetails } from "./pages/projects/[projectId]/purchase-orders/[purchaseOrderId]";
import { ProjectPurchaseOrderShipments } from "./pages/projects/[projectId]/purchase-orders/[purchaseOrderId]/shipments";
import { ProjectPurchaseOrderInventoryItems } from "./pages/projects/[projectId]/purchase-orders/[purchaseOrderId]/inventory";
import { PurchaseOrderPrintable } from "./pages/projects/[projectId]/purchase-orders/[purchaseOrderId]/printable";
import { PurchasingPurchaseOrders } from "./pages/purchasing/purchase-orders.tsx";
import { PurchasingSalesOrders } from "./pages/sales/sales-orders";
import { PurchasingInventory } from "./pages/inventory/browse";
import { QuotePrintable } from "./pages/projects/[projectId]/quotes/[quoteId]/printable";
import { CustomApolloProvider } from "./components/shared/CustomApolloProvider";
import { ProjectContacts } from "./pages/projects/[projectId]/contacts";
import { SalesOrderPrintable } from "./pages/projects/[projectId]/sales-orders/[salesOrderId]/printable";
import { Catalogs } from "./pages/admin/catalogs";
import { VendorPricing } from "./pages/admin/pricing/vendors";
import { ProductPricing } from "./pages/admin/pricing/products";
import { Vendors } from "./pages/admin/vendors";
import ProjectFulfillments from "./pages/projects/[projectId]/fulfillments";
import FulfillmentDetails from "./pages/projects/[projectId]/fulfillments/[fulfillmentId]";
import { FulfillmentFulfillmentsReport } from "./pages/fulfillment/fulfillments";
import ProjectPrepTickets from "./pages/projects/[projectId]/prep-tickets";
import PrepTicketDetails from "./pages/projects/[projectId]/prep-tickets/[prepTicketId]";
import QuotesReport from "./pages/sales/quotes";
import { VendorCompanies } from "./pages/contacts/vendors";

import PrintableFulfillment from "./pages/projects/[projectId]/fulfillments/[fulfillmentId]/printable";
import QuoteConversionDashboard from "./pages/dashboards/quote-conversion";
import PurchasingDashboard from "./pages/dashboards/purchasing";
import ReceivingDashboard from "./pages/dashboards/receiving";
import PrepDashboard from "./pages/dashboards/prep";
import SchedulingDashboard from "./pages/dashboards/scheduling";
import ProjectEODs from "./pages/projects/[projectId]/eods";
import EODDetails from "./pages/eods/[eodId]";
import EODDashboard from "./pages/fulfillment/eods";
import InvoicesDashboard from "./pages/sales/invoices";
import { CommissionReport } from "./pages/reports/commission";
import { ManagerApprovalDialog } from "./components/shared/ManagerApprovalDialog/ManagerApprovalDialog";
import { ManagerApprovalProvider } from "./context/ManagerApprovalContext/ManagerApprovalContext";
import { InvoiceDetails } from "./pages/projects/[projectId]/invoices/[invoiceId]";
import { Accounts } from "./pages/admin/accounts";
import { SalesDashboard } from "./pages/dashboards/sales";

export default function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  FocusStyleManager.onlyShowFocusOnTabs();
  registerAllModules();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Spinner />
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <ManagerApprovalProvider>
        <CustomApolloProvider>
          <Router>
            <PortalNavbar />

            <div className="content-container">
              <Switch>
                <Route path="/" exact component={Index} />

                <Route path="/contacts/companies" exact component={Companies} />

                <Route
                  path="/contacts/companies/:companyId"
                  component={Companies}
                />

                <Route
                  path="/contacts/vendors"
                  exact
                  component={VendorCompanies}
                />

                <Route
                  path="/contacts/vendors/:vendorId"
                  component={VendorCompanies}
                />

                <Route path="/contacts/people" exact component={People} />
                <Route path="/contacts/people/:personId" component={People} />

                <Route path="/projects" exact component={Projects} />

                <Route
                  path="/projects/:projectId/quotes"
                  exact
                  component={Quotes}
                />
                <Route
                  path="/projects/:projectId/quotes/:quoteId"
                  exact
                  component={QuoteDetails}
                />
                <Route
                  path="/projects/:projectId/quotes/:quoteId/printable"
                  exact
                  component={QuotePrintable}
                />
                <Route
                  path="/projects/:projectId/quotes/:quoteId/conversion"
                  exact
                  component={QuoteConversion}
                />

                <Route
                  path="/projects/:projectId/sales-orders"
                  exact
                  component={SalesOrders}
                />
                <Route
                  path="/projects/:projectId/sales-orders/:salesOrderId"
                  exact
                  component={SalesOrderDetails}
                />
                <Route
                  path="/projects/:projectId/sales-orders/:salesOrderId/printable"
                  exact
                  component={SalesOrderPrintable}
                />

                <Route
                  path="/projects/:projectId/purchase-orders"
                  exact
                  component={PurchaseOrders}
                />
                <Route
                  path="/projects/:projectId/purchase-orders/:purchaseOrderId"
                  exact
                  component={ProjectPurchaseOrderDetails}
                />
                <Route
                  path="/projects/:projectId/purchase-orders/:purchaseOrderId/shipments"
                  exact
                  component={ProjectPurchaseOrderShipments}
                />
                <Route
                  path="/projects/:projectId/purchase-orders/:purchaseOrderId/inventory"
                  exact
                  component={ProjectPurchaseOrderInventoryItems}
                />
                <Route
                  path="/projects/:projectId/purchase-orders/:purchaseOrderId/metadata"
                  exact
                  component={ProjectPurchaseOrderMetadata}
                />
                <Route
                  path="/projects/:projectId/purchase-orders/:purchaseOrderId/printable"
                  exact
                  component={PurchaseOrderPrintable}
                />
                <Route
                  path="/projects/:projectId/attachments"
                  exact
                  component={Attachments}
                />
                <Route
                  path="/projects/:projectId/contacts"
                  exact
                  component={ProjectContacts}
                />
                <Route
                  path="/projects/:projectId/settings"
                  exact
                  component={ProjectSettings}
                />
                <Route
                  path="/projects/:projectId/invoices"
                  exact
                  component={Invoices}
                />
                <Route
                  path="/projects/:projectId/fulfillments"
                  exact
                  component={ProjectFulfillments}
                />
                <Route
                  path="/projects/:projectId/fulfillments/:fulfillmentId"
                  exact
                  component={FulfillmentDetails}
                />
                <Route
                  path="/projects/:projectId/fulfillments/:fulfillmentId/printable"
                  exact
                  component={PrintableFulfillment}
                />

                <Route
                  path="/projects/:projectId/prep-tickets"
                  exact
                  component={ProjectPrepTickets}
                />

                <Route
                  path="/projects/:projectId/prep-tickets/:prepTicketId"
                  exact
                  component={PrepTicketDetails}
                />

                <Route
                  path="/projects/:projectId/eods"
                  exact
                  component={ProjectEODs}
                />

                <Route path="/eods/:eodId" exact component={EODDetails} />

                <Route
                  path="/purchasing/purchase-orders"
                  exact
                  component={PurchasingPurchaseOrders}
                />

                <Route path="/sales/quotes" exact component={QuotesReport} />

                <Route
                  path="/sales/invoices"
                  exact
                  component={InvoicesDashboard}
                />
                <Route
                  path="/sales/sales-orders"
                  exact
                  component={PurchasingSalesOrders}
                />

                <Route
                  path="/inventory/browse"
                  exact
                  component={PurchasingInventory}
                />

                <Route
                  path="/fulfillment/fulfillments"
                  exact
                  component={FulfillmentFulfillmentsReport}
                />

                <Route
                  path="/fulfillment/eods"
                  exact
                  component={EODDashboard}
                />

                <Route path="/admin/catalogs" exact component={Catalogs} />

                <Route
                  path="/admin/pricing"
                  exact
                  render={() => <Redirect to="/admin/pricing/vendors" />}
                />

                <Route
                  path="/admin/pricing/vendors"
                  exact
                  component={VendorPricing}
                />

                <Route
                  path="/admin/pricing/products"
                  exact
                  component={ProductPricing}
                />

                <Route path="/admin/vendors" exact component={Vendors} />

                <Route path="/admin/accounts" exact component={Accounts} />

                <Route
                  path="/dashboards/sales"
                  exact
                  component={SalesDashboard}
                />

                <Route
                  path="/dashboards/quote-conversion"
                  exact
                  component={QuoteConversionDashboard}
                />
                <Route
                  path="/dashboards/purchasing"
                  exact
                  component={PurchasingDashboard}
                />
                <Route
                  path="/dashboards/receiving"
                  exact
                  component={ReceivingDashboard}
                />
                <Route
                  path="/dashboards/prep"
                  exact
                  component={PrepDashboard}
                />
                <Route
                  path="/dashboards/scheduling"
                  exact
                  component={SchedulingDashboard}
                />

                <Route
                  path="/reports/commission"
                  component={CommissionReport}
                  exact
                />

                <Route
                  path="/invoices/:invoiceId"
                  component={InvoiceDetails}
                  exact
                />
              </Switch>
            </div>

            {process.env.NODE_ENV === "development" && (
              <div className="fixed right-0 bottom-0 rounded-tl-xl bg-red-400 font-medium text-red-800 py-2 px-4">
                <Icon icon="code" /> <span>Development Mode</span>
              </div>
            )}
          </Router>
        </CustomApolloProvider>
      </ManagerApprovalProvider>
    );
  }
  return (
    <Router>
      <Switch>
        <Route
          render={() => {
            loginWithRedirect();
          }}
        />
      </Switch>
    </Router>
  );
}
