import { useEffect, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import {
  CatalogItemType,
  getCatalogImageUrl,
} from "../../../helpers/CatalogHelper";

type LineItemProductIconProps = {
  isCustom: boolean;
  catalogProductId?: number;
};

export function LineItemProductIcon({
  isCustom,
  catalogProductId,
}: LineItemProductIconProps) {
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageRef.current) return;
    imageRef.current.addEventListener("error", () => setIsError(true));
  }, [imageRef.current]);

  if (isCustom) {
    return (
      <div className="border rounded bg-indigo-50 border-indigo-400 flex items-center justify-center w-16 h-16 text-indigo-400">
        <Icon icon="new-object" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="border rounded bg-indigo-50 border-indigo-400 flex items-center justify-center w-16 h-16 text-indigo-400">
        <Icon icon="help" />
      </div>
    );
  }

  return (
    <div className="border rounded bg-indigo-50 border-indigo-400 flex items-center justify-center w-16 h-16 overflow-hidden">
      <img
        src={getCatalogImageUrl(catalogProductId!, CatalogItemType.Product)}
        alt=""
        ref={imageRef}
      />
    </div>
  );
}
