import { gql, useMutation, useQuery } from "@apollo/client";
import { useApiError } from "./useApiError";
import {
  ConversionRequirement,
  Project,
  Quote,
  SalesOrder,
  UpdateConversionRequirementInput,
} from "../types/ApiTypes";

const GET_CONVERSION_DETAILS = gql`
  query GetConversionDetails($projectId: Int!, $quoteId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }

    quotes(where: { id: { eq: $quoteId } }) {
      id
      name
      code
      salesOrder {
        id
        code
      }
      conversionRequirements {
        id
        title
        description
        conversionRequirementAttachments {
          id
          attachmentId
          attachment {
            id
            name
          }
        }
        notes
        completedAt
      }
    }
  }
`;

const UPDATE_CONVERSION_REQUIREMENT = gql`
  mutation UpdateConversionRequirement(
    $input: UpdateConversionRequirementPayloadInput
  ) {
    updateConversionRequirement(payload: $input) {
      id
    }
  }
`;

const ADD_ATTACHMENT_TO_CONVERSION_REQUIREMENT = gql`
  mutation AddAttachmentToConversionRequirement(
    $conversionRequirementId: Int!
    $attachmentId: Int!
  ) {
    addAttachmentToConversionRequirement(
      conversionRequirementId: $conversionRequirementId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

const REMOVE_ATTACHMENT_FROM_CONVERSION_REQUIREMENT = gql`
  mutation RemoveAttachmentFromConversionRequirement(
    $conversionRequirementId: Int!
    $attachmentId: Int!
  ) {
    removeAttachmentFromConversionRequirement(
      conversionRequirementId: $conversionRequirementId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

const CONVERT_QUOTE_TO_SALES_ORDER = gql`
  mutation ConvertQuoteToSalesOrder($quoteId: Int!) {
    convertQuoteToSalesOrder(quoteId: $quoteId) {
      id
    }
  }
`;

export function useQuoteConversionRequirements(
  projectId: number,
  quoteId: number
) {
  const handleFetchError = useApiError("fetch conversion requirements");
  const handleUpdateError = useApiError("update conversion requirement");
  const handleConversionError = useApiError("convert quote to sales order");

  const getConversionDetails = useQuery<
    { projects: Project[]; quotes: Quote[] },
    { projectId: number; quoteId: number }
  >(GET_CONVERSION_DETAILS, {
    variables: {
      projectId,
      quoteId,
    },
    onError: handleFetchError,
  });

  const [updateConversionRequirement] = useMutation<
    { updateConversionRequirement: ConversionRequirement },
    { input: UpdateConversionRequirementInput }
  >(UPDATE_CONVERSION_REQUIREMENT, {
    refetchQueries: [
      {
        query: GET_CONVERSION_DETAILS,
        variables: { quoteId, projectId },
      },
      "GetQuoteConversionReport",
    ],
    onError: (err) => handleUpdateError(err),
  });

  const [addAttachmentToConversionRequirement] = useMutation<
    { addAttachmentToConversionRequirement: ConversionRequirement },
    { conversionRequirementId: number; attachmentId: number }
  >(ADD_ATTACHMENT_TO_CONVERSION_REQUIREMENT, {
    refetchQueries: [
      {
        query: GET_CONVERSION_DETAILS,
        variables: { quoteId, projectId },
      },
    ],
    onError: (err) => handleUpdateError(err),
  });

  const [removeAttachmentFromConversionRequirement] = useMutation<
    { removeAttachmentFromConversionRequirement: ConversionRequirement },
    { conversionRequirementId: number; attachmentId: number }
  >(REMOVE_ATTACHMENT_FROM_CONVERSION_REQUIREMENT, {
    refetchQueries: [
      {
        query: GET_CONVERSION_DETAILS,
        variables: { quoteId, projectId },
      },
    ],
    onError: (err) => handleUpdateError(err),
  });

  const [convertQuoteToSalesOrder] = useMutation<
    { convertQuoteToSalesOrder: SalesOrder },
    { quoteId: number }
  >(CONVERT_QUOTE_TO_SALES_ORDER, {
    refetchQueries: [
      {
        query: GET_CONVERSION_DETAILS,
        variables: { quoteId, projectId },
      },
      "GetProjectSalesOrders",
    ],
    onError: (err) => handleConversionError(err),
  });

  return {
    getConversionDetails,
    updateConversionRequirement,
    addAttachmentToConversionRequirement,
    removeAttachmentFromConversionRequirement,
    convertQuoteToSalesOrder,
  };
}
