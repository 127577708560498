import { ProductConfiguration } from "../../../types/ApiTypes";
import {
  getFullConfigurationCode,
  getFullConfigurationDescription,
  getFullEditableConfigurationCode,
  getFullEditableConfigurationDescription,
} from "../../../helpers/CatalogHelper";
import {
  Button,
  ButtonGroup,
  Icon,
  Popover,
  Position,
} from "@blueprintjs/core";
import { LineItemProductIcon } from "./LineItemProductIcon";
import { ChooseProductMenu } from "../ChooseProductMenu/ChooseProductMenu";
import React, { useState } from "react";
import { EditableProductConfiguration } from "./reducers/LineItemEditor/LineItemEditorTypes";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { SKELETON } from "@blueprintjs/core/lib/esnext/common/classes";

type GetVendorParams = {
  id: number;
};

type GetVendorResult = {
  vendors: {
    id: number;
    name: string;
  }[];
};

const GetVendor = gql`
  query GetVendor($id: Int!) {
    vendors(where: { id: { eq: $id } }) {
      id
      name
    }
  }
`;

type GetCatalogProductParams = {
  id: number;
};

type GetCatalogProductResult = {
  catalogProducts: {
    id: number;
    catalog: {
      id: number;
      name: string;
    };
  }[];
};

const GetCatalogProduct = gql`
  query GetCatalogProduct($id: Int!) {
    catalogProducts(where: { id: { eq: $id } }) {
      id
      catalog {
        id
        name
      }
    }
  }
`;

type LineItemProductDetailsProps = {
  readOnly: boolean;
  configuration?: EditableProductConfiguration;
  onConfigurationChange: (
    newConfiguration: EditableProductConfiguration
  ) => void;
  onEdit: () => void;
  onRemove: () => void;
};

export function LineItemProductDetails({
  readOnly,
  configuration,
  onConfigurationChange,
  onEdit,
  onRemove,
}: LineItemProductDetailsProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const getCatalogProduct = useQuery<
    GetCatalogProductResult,
    GetCatalogProductParams
  >(GetCatalogProduct, {
    variables: {
      id: configuration?.catalogProductId ?? -1,
    },
    fetchPolicy: "cache-and-network",
    skip: !configuration,
  });

  const getVendor = useQuery<GetVendorResult, GetVendorParams>(GetVendor, {
    variables: {
      id: configuration?.customVendorId ?? -1,
    },
    fetchPolicy: "cache-and-network",
    skip: !configuration?.customVendorId,
  });

  const catalogProduct = getCatalogProduct.data?.catalogProducts[0];
  const vendor = getVendor.data?.vendors[0];

  return configuration ? (
    <div className="space-y-2">
      <div className="flex items-start space-x-4">
        <LineItemProductIcon
          isCustom={configuration.isCustomConfiguration}
          catalogProductId={configuration.catalogProductId}
        />

        <div className="space-y-1 flex-1">
          <div className="flex-1 flex space-x-4">
            <p
              className="text-sm font-medium text-gray-900 font-mono whitespace-nowrap"
              dangerouslySetInnerHTML={{
                __html: getFullEditableConfigurationCode(configuration)
                  .split("\n")
                  .map((i) => i.trim())
                  .filter((i) => i.length > 0)
                  .join("<br />"),
              }}
            />

            <div className="overflow-x-auto flex-1" style={{ width: 450 }}>
              <p
                className="text-sm text-gray-600 font-mono whitespace-nowrap"
                dangerouslySetInnerHTML={{
                  __html: getFullEditableConfigurationDescription(configuration)
                    .split("\n")
                    .map((i) => i.trim())
                    .filter((i) => i.length > 0)
                    .join("<br />"),
                }}
              />
            </div>
          </div>
        </div>

        {!readOnly && (
          <ButtonGroup>
            <Button outlined small icon="edit" text="Edit" onClick={onEdit} />
            <Button outlined small icon="cross" onClick={onRemove} />
          </ButtonGroup>
        )}
      </div>
      <div className="space-x-2 flex py-1 px-2 bg-gray-100 rounded">
        <p
          className={classNames("text-sm text-gray-900 font-medium m-0", {
            "bp3-skeleton": getCatalogProduct.loading || getVendor.loading,
          })}
        >
          {catalogProduct?.catalog.name ?? vendor?.name}
        </p>

        {configuration.isCustomConfiguration && (
          <p className="text-sm text-gray-600 m-0">(Custom Configuration)</p>
        )}
      </div>
    </div>
  ) : (
    <div className="pl-2 flex items-center space-x-2">
      <Icon icon="help" className="text-gray-400" />

      <div className="flex-1">
        <p className="m-0 text-gray-600">No Product Selected</p>
      </div>

      {!readOnly && (
        <Popover
          isOpen={popoverOpen}
          onClose={() => setPopoverOpen(false)}
          minimal
        >
          <Button
            outlined
            small
            icon="edit"
            text="Choose Product"
            onClick={() => setPopoverOpen(true)}
          />

          <ChooseProductMenu
            allowInventoryItems={false}
            onChooseProduct={(p) => {
              onConfigurationChange({
                isCustomConfiguration: false,
                catalogProductId: p.id,
                catalogProductCode: p.code,
                features: [],
                catalogProductDescription: p.description,
              });

              setPopoverOpen(false);
            }}
            onChooseInventoryItem={() => {}}
            onChooseCustomProduct={() => {
              onConfigurationChange({
                isCustomConfiguration: true,
                customCode: "TBD",
                customDescription: "TBD",
                customListPrice: 0,
                features: [],
              });
            }}
          />
        </Popover>
      )}
    </div>
  );
}
