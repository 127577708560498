import { Address } from "../../../types/ApiTypes";
import { formatAddress } from "../../../helpers/AddressHelper";
import { useState } from "react";
import {
  QuickSettingItem,
  QuickSettingsAction,
} from "../QuickSettings/QuickSettingItem";
import { ChooseAddressDialog } from "./ChooseAddressDialog";

type AddressInputGroupProps = {
  suggestedAddresses?: Address[];
  value?: Address;
  onChange: (value?: Address) => void;
};

export function AddressInputGroup({
  suggestedAddresses = [],
  value,
  onChange,
}: AddressInputGroupProps) {
  const [isChangeOpen, setIsChangeOpen] = useState(false);

  return (
    <>
      <ChooseAddressDialog
        isOpen={isChangeOpen}
        onClose={() => setIsChangeOpen(false)}
        onChoose={(address) => {
          setIsChangeOpen(false);
          onChange(address);
        }}
        suggestedAddresses={suggestedAddresses}
      />

      <QuickSettingItem
        actions={
          <>
            <QuickSettingsAction
              text="Change"
              onClick={() => setIsChangeOpen(true)}
            />
            {!!value && (
              <QuickSettingsAction
                text="Remove"
                onClick={() => onChange(undefined)}
              />
            )}
          </>
        }
      >
        {value ? (
          <div>
            <address
              className="not-italic"
              dangerouslySetInnerHTML={{
                __html: formatAddress(value).replaceAll("\n", "<br />"),
              }}
            />
          </div>
        ) : (
          <div>
            <p className="m-0 text-gray-400">No Address Selected</p>
          </div>
        )}
      </QuickSettingItem>
    </>
  );
}
