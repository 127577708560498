import { gql, useQuery } from "@apollo/client";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Popover, Spinner, Tag } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { process } from "@progress/kendo-data-query";
import { useReportDataState } from "../../hooks/useReportDataState";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { formatAccountName } from "../../helpers/AccountHelper";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { formatFulfillmentMethodType } from "../../helpers/FulfillmentHelper";
import { Account } from "../../types/ApiTypes";
import { formatDate } from "../../helpers/DateHelper";
import dayjs from "dayjs";
import classNames from "classnames";

type GetPrepReportResult = {
  prepTickets: {
    id: number;
    estimatedHours?: number;
    salesOrders: {
      id: number;
      code: string;
      requestedFulfillmentDate?: string;
    }[];
    project: {
      id: number;
      primaryContact: FormatContactNameContact;
      salesRepAccount: Account;
      designerAccount: Account;
    };
    lineItems: {
      id: number;
      isPrepped: boolean;
    }[];
  }[];
};

const GetPrepReport = gql`
  ${FormatContactNameContactFragment}

  query GetPrepReport {
    prepTickets {
      id
      estimatedHours
      salesOrders {
        id
        code
        requestedFulfillmentDate
      }
      project {
        id
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
        salesRepAccount {
          id
          name
        }
        designerAccount {
          id
          name
        }
      }
      lineItems {
        id
        isPrepped
      }
    }
  }
`;

function SalesOrdersCell(props: GridCellProps) {
  const dataItem = props.dataItem as GetPrepReportResult["prepTickets"][0];
  const salesOrders = dataItem.salesOrders;
  const uniqueSalesOrders = salesOrders.filter(
    (so, index) => salesOrders.findIndex((s) => s.id === so.id) === index
  );

  return uniqueSalesOrders.length > 0 ? (
    <td className="space-x-1">
      {uniqueSalesOrders.map((so) => (
        <Tag
          key={so.id}
          rightIcon="chevron-right"
          onClick={() => {
            window.open(
              `/projects/${dataItem.project.id}/sales-orders/${so.id}`,
              "_blank"
            );
          }}
          className="cursor-pointer"
          minimal
        >
          {so.code}
        </Tag>
      ))}
    </td>
  ) : (
    <td>No Sales Orders</td>
  );
}

export default function PrepDashboard() {
  const getPrepReport = useQuery<GetPrepReportResult>(GetPrepReport, {
    fetchPolicy: "cache-and-network",
  });

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const prepTickets =
    getPrepReport.data?.prepTickets.map((pt) => ({
      ...pt,
      primaryContactName: formatContactName(pt.project.primaryContact),
      salesRepName: pt.project.salesRepAccount
        ? formatAccountName(pt.project.salesRepAccount)
        : undefined,
      designerName: pt.project.designerAccount
        ? formatAccountName(pt.project.designerAccount)
        : undefined,
      numberOfItems: pt.lineItems.length,
      numberOfPreppedItems: pt.lineItems.filter((li) => li.isPrepped).length,
      requestedDate: pt.salesOrders
        .filter((so) => !!so.requestedFulfillmentDate)
        .map((so) => new Date(so.requestedFulfillmentDate!))
        .sort((a, b) => (a > b ? -1 : 1))[0],
    })) ?? [];

  return (
    <>
      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Fulfillment Prep"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getPrepReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(prepTickets, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
            >
              <GridColumn
                field="id"
                title="Prep Ticket #"
                columnMenu={ReportColumnMenu}
                cell={(props) => {
                  const dataItem =
                    props.dataItem as GetPrepReportResult["prepTickets"][0];
                  return (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.project.id}/prep-tickets/${dataItem.id}`}
                        text={`PT-${dataItem.id}`}
                      />
                    </td>
                  );
                }}
              />

              <GridColumn title="SO #s" cell={SalesOrdersCell} />

              <GridColumn
                title="Customer"
                field="primaryContactName"
                columnMenu={ReportColumnMenu}
              />

              <GridColumn
                title="Sales Rep"
                field="salesRepName"
                columnMenu={ReportColumnMenu}
              />

              <GridColumn
                title="Designer"
                field="designerName"
                columnMenu={ReportColumnMenu}
              />

              <GridColumn
                field="numberOfItems"
                title="# of Items"
                columnMenu={ReportColumnMenu}
                filter="numeric"
              />

              <GridColumn
                field="numberOfPreppedItems"
                title="# of Items Prepped"
                columnMenu={ReportColumnMenu}
                filter="numeric"
              />

              <GridColumn
                field="estimatedHours"
                title="Estimated Prep Time (hrs)"
                columnMenu={ReportColumnMenu}
                filter="numeric"
              />

              <GridColumn
                field="requestedDate"
                title="Requested Date"
                columnMenu={ReportColumnMenu}
                filter="date"
                cell={(props) => {
                  const dataItem = props.dataItem as typeof prepTickets[0];
                  if (dataItem.requestedDate) {
                    const diffDays = dayjs(dataItem.requestedDate).diff(
                      dayjs(),
                      "days"
                    );

                    return (
                      <td>
                        {formatDate(dataItem.requestedDate)}{" "}
                        {diffDays === 0 && (
                          <span
                            className={classNames({
                              "text-red-500":
                                dataItem.numberOfPreppedItems <
                                dataItem.numberOfItems,
                            })}
                          >
                            (Due Today)
                          </span>
                        )}
                        {diffDays > 0 && (
                          <span>({diffDays} Days Remaining)</span>
                        )}
                        {diffDays < 0 &&
                          dataItem.numberOfPreppedItems <
                            dataItem.numberOfItems && (
                            <span className="text-red-500">
                              ({Math.abs(diffDays)} Days Overdue)
                            </span>
                          )}
                      </td>
                    );
                  } else {
                    return <td>--</td>;
                  }
                }}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
