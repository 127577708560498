import { IItemRendererProps, Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";

type AddPersonButtonPerson = {
  id: number;
  firstName: string;
  lastName: string;
};

export type AddPersonButtonProps = {
  onAddPerson: (person: AddPersonButtonPerson) => void;
};

const GetPeople = gql`
  query GetPeople {
    people(order: { firstName: ASC }) {
      id
      firstName
      lastName
    }
  }
`;

type GetPeopleResult = {
  people: {
    id: number;
    firstName: string;
    lastName: string;
  }[];
};

const PersonSelect = Select.ofType<AddPersonButtonPerson>();

export function AddPersonButton({ onAddPerson }: AddPersonButtonProps) {
  const getPeople = useQuery<GetPeopleResult>(GetPeople, {
    fetchPolicy: "cache-and-network",
  });

  const filterPerson = (query: string, item: AddPersonButtonPerson) => {
    const normalizedQuery = query.toLowerCase().trim();
    if (normalizedQuery.length === 0) return true;

    const normalizedName = `${item.firstName} ${item.lastName}`
      .toLowerCase()
      .trim();
    return normalizedName.includes(normalizedQuery);
  };

  const renderPerson = (
    item: AddPersonButtonPerson,
    props: IItemRendererProps
  ) => {
    return (
      <MenuItem
        text={`${item.firstName} ${item.lastName}`}
        active={props.modifiers.active}
        disabled={props.modifiers.disabled}
        onClick={props.handleClick}
      />
    );
  };

  return (
    <PersonSelect
      items={getPeople.data?.people ?? []}
      itemPredicate={filterPerson}
      itemRenderer={renderPerson}
      onItemSelect={(person: AddPersonButtonPerson) => onAddPerson(person)}
    >
      <Button icon="plus" />
    </PersonSelect>
  );
}
