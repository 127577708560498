import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Attachment,
  InventoryItemInspection,
  ProductConfiguration,
  PurchaseOrderInventoryItem,
} from "../../../../../../types/ApiTypes";
import { useApiError } from "../../../../../../hooks/useApiError";
import { AttachmentsInput } from "../../../../../shared/AttachmentsInput/AttachmentsInput";
import { gql, useMutation } from "@apollo/client";
import { getFullConfigurationCode } from "../../../../../../helpers/CatalogHelper";

const ADD_ATTACHMENT_TO_INSPECTION = gql`
  mutation AddAttachmentToInspection($inspectionId: Int!, $attachmentId: Int!) {
    addAttachmentToInventoryItemInspection(
      inventoryItemInspectionId: $inspectionId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

export type InventoryItemInspectionDialogProps = DialogProps & {
  projectId?: number;
  inventoryItem?: {
    id: number;
    purchaseOrderShipmentLineItem: {
      id: number;
      purchaseOrderLineItem: {
        id: number;
        quantity: number;
        configuration: ProductConfiguration;
      };
    };
  };
  onSubmit: () => void;
};

export function InventoryItemInspectionDialog({
  projectId,
  inventoryItem,
  isOpen,
  onSubmit,
  onClose,
}: InventoryItemInspectionDialogProps) {
  const [loading, setLoading] = useState(false);
  const [inspectionStatus, setInspectionStatus] = useState("");
  const [notes, setNotes] = useState<string>("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const handleCreateError = useApiError("create inspection");

  const [addAttachmentToInspection] = useMutation<
    InventoryItemInspection,
    { inspectionId: number; attachmentId: number }
  >(ADD_ATTACHMENT_TO_INSPECTION, {
    onError: (err) => handleCreateError(err),
  });

  useEffect(() => {
    setLoading(false);
  }, [inventoryItem]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inventoryItem == undefined) return;

    setLoading(true);

    const inspectionDto = {
      inventoryItemId: inventoryItem.id,
      status: inspectionStatus,
      notes: notes,
    };

    const createdInspection = await axios
      .post(`/inventoryiteminspection`, inspectionDto)
      .then((r) => r.data as InventoryItemInspection)
      .catch((err) => handleCreateError(err));

    await Promise.all(
      attachments.map((a) =>
        addAttachmentToInspection({
          variables: {
            inspectionId: createdInspection.id,
            attachmentId: a.id!,
          },
        })
      )
    );

    setLoading(false);
    onSubmit();
  };

  return (
    <Dialog
      title={"Record Inspection"}
      isOpen={isOpen}
      isCloseButtonShown={false}
      lazy
      usePortal
    >
      {inventoryItem != undefined && (
        <form onSubmit={handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            <FormGroup label="Description">
              <TextArea
                value={getFullConfigurationCode(
                  inventoryItem.purchaseOrderShipmentLineItem
                    .purchaseOrderLineItem.configuration
                )}
                disabled
                fill
              />
            </FormGroup>
            <FormGroup label="Quantity">
              <InputGroup
                value={inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderLineItem.quantity.toString()}
                disabled
                fill
              />
            </FormGroup>
            <FormGroup label="Status">
              <HTMLSelect
                value={inspectionStatus}
                onChange={(e) => setInspectionStatus(e.target.value)}
                disabled={loading}
                fill
              >
                <option value="Uninspected">Select a status...</option>
                <option value="NoDamage">No Damage</option>
                <option value="MinorDamage">Minor Damage</option>
                <option value="MajorDamage">Major Damage</option>
              </HTMLSelect>
            </FormGroup>
            <FormGroup label="Notes">
              <TextArea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={loading}
                fill
              />
            </FormGroup>
            <FormGroup label="Attachment">
              <AttachmentsInput
                projectId={projectId}
                attachments={attachments}
                onAddAttachment={(a) =>
                  setAttachments((state) => [...state, a])
                }
                onRemoveAttachment={(a) =>
                  setAttachments((state) => state.filter((s) => s.id !== a.id))
                }
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                text="Cancel"
                onClick={onClose}
                type="button"
                disabled={loading}
              />
              <Button
                intent={Intent.PRIMARY}
                text={loading ? "Savings Inspection" : "Save Inspection"}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </form>
      )}
    </Dialog>
  );
}
