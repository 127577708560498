import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import { useQuery } from "@apollo/client";
import { GetAccounts, GetAccountsResult } from "./queries";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useReportDataState } from "../../../hooks/useReportDataState";
import { ReportColumnMenu } from "../../../components/reports/ReportColumnMenu";
import { AccountDetail } from "./AccountDetail";
import { useExpandableRows } from "../../../hooks/useExpandableRows";
import { ShareReportMenu } from "../../../components/reports/ShareReportMenu";
import { ProjectSubnavbar } from "../../../components/projects/[projectId]/ProjectSubnavbar";

export function Accounts() {
  const getAccounts = useQuery<GetAccountsResult>(GetAccounts, {
    fetchPolicy: "cache-and-network",
  });

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "name", dir: "asc" }],
  });

  const { isExpanded, onExpandChange } = useExpandableRows();

  return (
    <>
      <ProjectSubnavbar
        title="Accounts"
        style={{ top: "unset" }}
        actions={
          <>
            <Popover minimal>
              <Button minimal icon="share" text="Share Report" />
              <ShareReportMenu dataState={dataState} />
            </Popover>
          </>
        }
      />
      <PortalContainer>
        {getAccounts.loading ? (
          <Spinner />
        ) : (
          <Grid
            data={process(
              getAccounts.data?.accounts.map((a) => ({
                ...a,
                spiffCodeCount: a.spiffCodes.length,
                expanded: isExpanded(a.id),
              })) ?? [],
              dataState
            )}
            {...dataState}
            onDataStateChange={(e) => setDataState(e.dataState)}
            detail={AccountDetail}
            sortable
            reorderable
            resizable
            expandField="expanded"
            onExpandChange={onExpandChange}
          >
            <GridColumn field="id" title="ID" columnMenu={ReportColumnMenu} />
            <GridColumn
              field="name"
              title="Name"
              columnMenu={ReportColumnMenu}
            />
            <GridColumn
              field="email"
              title="Email"
              columnMenu={ReportColumnMenu}
            />
            <GridColumn
              field="phoneNumber"
              title="Phone"
              columnMenu={ReportColumnMenu}
            />
            <GridColumn
              field="spiffCodeCount"
              title="# SPIFF Codes"
              columnMenu={ReportColumnMenu}
            />
            <GridColumn
              field="auth0Id"
              title="Auth0 ID"
              columnMenu={ReportColumnMenu}
            />
          </Grid>
        )}
      </PortalContainer>
    </>
  );
}
