import { AttachmentsInput } from "../../../../../shared/AttachmentsInput/AttachmentsInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  InventoryItem,
  InventoryItemAttachment,
} from "../../../../../../types/ApiTypes";

const GET_INVENTORY_ITEM_ATTACHMENTS = gql`
  query GetInventoryItemAttachments($inventoryItemId: Int!) {
    inventoryItems(where: { id: { eq: $inventoryItemId } }) {
      id
      inventoryItemAttachments {
        id
        attachmentId
        attachment {
          id
          name
          contentType
        }
      }
    }
  }
`;

const ADD_ATTACHMENT_TO_INVENTORY_ITEM = gql`
  mutation AddAttachmentToInventoryItem(
    $inventoryItemId: Int!
    $attachmentId: Int!
  ) {
    addAttachmentToInventoryItem(
      inventoryItemId: $inventoryItemId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

const REMOVE_ATTACHMENT_FROM_INVENTORY_ITEM = gql`
  mutation RemoveAttachmentFromInventoryItem(
    $inventoryItemId: Int!
    $attachmentId: Int!
  ) {
    removeAttachmentFromInventoryItem(
      inventoryItemId: $inventoryItemId
      attachmentId: $attachmentId
    ) {
      id
    }
  }
`;

type ManageInventoryItemAttachmentsProps = {
  inventoryItemId: number;
};

export function ManageInventoryItemAttachments({
  inventoryItemId,
}: ManageInventoryItemAttachmentsProps) {
  const getInventoryItemAttachments = useQuery<
    { inventoryItems: InventoryItem[] },
    { inventoryItemId: number }
  >(GET_INVENTORY_ITEM_ATTACHMENTS, {
    variables: {
      inventoryItemId,
    },
  });

  const [addAttachmentToInventoryItem] = useMutation<
    InventoryItemAttachment,
    {
      attachmentId: number;
      inventoryItemId: number;
    }
  >(ADD_ATTACHMENT_TO_INVENTORY_ITEM, {
    refetchQueries: [
      {
        query: GET_INVENTORY_ITEM_ATTACHMENTS,
        variables: { inventoryItemId },
      },
    ],
  });

  const [removeAttachmentFromInventoryItem] = useMutation<
    InventoryItemAttachment,
    {
      attachmentId: number;
      inventoryItemId: number;
    }
  >(REMOVE_ATTACHMENT_FROM_INVENTORY_ITEM, {
    refetchQueries: [
      {
        query: GET_INVENTORY_ITEM_ATTACHMENTS,
        variables: { inventoryItemId },
      },
    ],
  });

  return (
    <div>
      {!getInventoryItemAttachments.loading && (
        <AttachmentsInput
          attachments={getInventoryItemAttachments.data!.inventoryItems[0].inventoryItemAttachments.map(
            (i) => i.attachment
          )}
          onAddAttachment={(a) =>
            addAttachmentToInventoryItem({
              variables: { attachmentId: a.id!, inventoryItemId },
            })
          }
          onRemoveAttachment={(a) =>
            removeAttachmentFromInventoryItem({
              variables: { attachmentId: a.id!, inventoryItemId },
            })
          }
        />
      )}
    </div>
  );
}
