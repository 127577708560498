import React from "react";
import styles from "./ProjectNavbar.module.scss";
import {
  Breadcrumb,
  Breadcrumbs,
  IBreadcrumbProps,
  Icon,
  Tooltip,
} from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import { Contact } from "../../../types/ApiTypes";
import { ProjectNavbarItem } from "./ProjectNavbarItem";

export type ProjectNavbarPropTypes = {
  additionalHeaderItems?: IBreadcrumbProps[];
  projectLoading: boolean;
  projectId: number;
  projectName: string;
  projectPrimaryContact?: Contact;
};

export function ProjectNavbar({
  additionalHeaderItems = [],
  projectLoading,
  projectId,
  projectName,
  projectPrimaryContact,
}: ProjectNavbarPropTypes) {
  useHistory();
  const fullProjectName = projectLoading
    ? `Project #${projectId}`
    : projectName;

  return (
    <div className="bg-gray-50 flex items-center space-x-4 border-b sticky top-0 z-10">
      <h1 className="text-sm font-bold m-0 ml-4">{fullProjectName}</h1>
      <Breadcrumbs
        className="flex-1 border-l"
        breadcrumbRenderer={({ text, href, ...restProps }: IBreadcrumbProps) =>
          href ? (
            <Link to={href}>
              <Breadcrumb {...restProps}>
                <h5 className="m-4 text-gray-400">{text}</h5>
              </Breadcrumb>
            </Link>
          ) : (
            <Breadcrumb {...restProps}>
              <h5 className="m-4 text-gray-400">{text}</h5>
            </Breadcrumb>
          )
        }
        currentBreadcrumbRenderer={({
          text,
          href,
          ...restProps
        }: IBreadcrumbProps) => (
          <Link to={href!}>
            <Breadcrumb {...restProps}>
              <h5 className="m-4 text-gray-700 font-medium">{text}</h5>
            </Breadcrumb>
          </Link>
        )}
        items={additionalHeaderItems}
      />

      <div className="flex items-center">
        <ProjectNavbarItem
          icon="tag"
          label="Quotes"
          href={`/projects/${projectId}/quotes`}
        />
        <ProjectNavbarItem
          icon="shopping-cart"
          label="Sales Orders"
          href={`/projects/${projectId}/sales-orders`}
        />
        <ProjectNavbarItem
          icon="truck"
          label="Purchase Orders"
          href={`/projects/${projectId}/purchase-orders`}
        />
        <ProjectNavbarItem
          icon="star"
          label="Prep Tickets"
          href={`/projects/${projectId}/prep-tickets`}
        />
        <ProjectNavbarItem
          icon="star"
          label="Fulfillments"
          href={`/projects/${projectId}/fulfillments`}
        />
        <ProjectNavbarItem
          icon="warning-sign"
          label="EODs"
          href={`/projects/${projectId}/eods`}
        />
        <Tooltip content="Attachments">
          <NavLink
            to={`/projects/${projectId}/attachments`}
            className={styles.projectNavItem}
          >
            <Icon icon="paperclip" />
          </NavLink>
        </Tooltip>
        <Tooltip content="Contacts">
          <NavLink
            to={`/projects/${projectId}/contacts`}
            className={styles.projectNavItem}
          >
            <Icon icon="people" />
          </NavLink>
        </Tooltip>
        <Tooltip content="Project Settings">
          <NavLink
            to={`/projects/${projectId}/settings`}
            className={styles.projectNavItem}
          >
            <Icon icon="cog" />
          </NavLink>
        </Tooltip>
      </div>
    </div>
  );
}
