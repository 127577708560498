import {
  PurchaseOrderSubmissionResult,
  PurchaseOrderSubmission,
  PurchaseOrderConfirmation,
  PurchaseOrderConfirmationStatus,
  EstimatedShipDateAcknowledgement,
  ActualShipDateAcknowledgement,
  PurchaseOrder,
} from "../types/ApiTypes";
import dayjs from "dayjs";
import { Intent } from "@blueprintjs/core";

export function getActualShipDateStatusText(
  ack?: ActualShipDateAcknowledgement
) {
  if (ack && ack.lineItems) {
    const lineItemsByDate = ack.lineItems
      .filter((li) => !!li.actualShipDate)
      .sort((a, b) =>
        dayjs(a.actualShipDate).isAfter(b.actualShipDate!) ? -1 : 1
      );

    return dayjs(lineItemsByDate[0].actualShipDate).format("MM/DD/YYYY");
  } else {
    return "Record";
  }
}

export function getEstimatedShipDateStatusText(
  ack?: EstimatedShipDateAcknowledgement
) {
  if (ack && ack.lineItems) {
    const lineItemsByDate = ack.lineItems
      .filter((li) => !!li.estimatedShipDate)
      .sort((a, b) =>
        dayjs(a.estimatedShipDate).isAfter(b.estimatedShipDate!) ? -1 : 1
      );

    return dayjs(lineItemsByDate[0].estimatedShipDate).format("MM/DD/YYYY");
  } else {
    return "Record";
  }
}

export function getSubmissionStatusText(pos?: PurchaseOrderSubmission) {
  if (!pos) {
    return "Record Submission";
  } else if (!pos.result) {
    return `Submitted ${new Date(pos.createdAt!).toLocaleDateString()}`;
  } else if (pos.result === PurchaseOrderSubmissionResult.ChangesNeeded) {
    return "Changes Needed";
  } else if (pos.result === PurchaseOrderSubmissionResult.ErrorWhenSending) {
    return "Error Submitting";
  } else if (pos.result === PurchaseOrderSubmissionResult.Confirmed) {
    return `Confirmed ${dayjs(pos.resultReceivedAt).format("MM/DD/YYYY")}`;
  }

  throw new Error("Invalid purchase order submission result: " + pos.result);
}

export function getSubmissionStatusIntent(
  pos?: PurchaseOrderSubmission
): Intent {
  if (!pos) {
    return Intent.NONE;
  } else if (!pos.result) {
    return Intent.WARNING;
  } else if (
    pos.result === PurchaseOrderSubmissionResult.ChangesNeeded ||
    pos.result === PurchaseOrderSubmissionResult.ErrorWhenSending
  ) {
    return Intent.DANGER;
  } else if (pos.result === PurchaseOrderSubmissionResult.Confirmed) {
    return Intent.SUCCESS;
  }

  throw new Error("Invalid purchase order submission result: " + pos.result);
}

export function getConfirmationStatusText(pos: PurchaseOrderConfirmation) {
  if (!pos) {
    return "Record";
  } else if (pos.status === PurchaseOrderConfirmationStatus.Confirmed) {
    return `Confirmed ${dayjs(pos.updatedAt).format("MM/DD/YY")}`;
  } else if (
    pos.status === PurchaseOrderConfirmationStatus.ReceivedWithEditsNeeded
  ) {
    return `Received (Edits Needed) ${dayjs(pos.updatedAt).format("MM/DD/YY")}`;
  } else {
    return `Received ${dayjs(pos.updatedAt).format("MM/DD/YY")}`;
  }
}

export function getConfirmationStatusIntent(
  pos: PurchaseOrderConfirmation
): Intent {
  if (!pos) {
    return Intent.NONE;
  } else if (pos.status === PurchaseOrderConfirmationStatus.Confirmed) {
    return Intent.SUCCESS;
  } else if (
    pos.status === PurchaseOrderConfirmationStatus.ReceivedWithEditsNeeded
  ) {
    return Intent.DANGER;
  } else {
    return Intent.WARNING;
  }
}

export function getVendorName(purchaseOrder: PurchaseOrder) {
  if (purchaseOrder.manufacturer) {
    return purchaseOrder.manufacturer.name;
  } else if (purchaseOrder.company && purchaseOrder.person) {
    return `${purchaseOrder.person.firstName} ${purchaseOrder.person.lastName} (${purchaseOrder.company.name})`;
  } else if (purchaseOrder.company) {
    return purchaseOrder.company.name;
  } else if (purchaseOrder.person) {
    return `${purchaseOrder.person.firstName} ${purchaseOrder.person.lastName}`;
  } else {
    return "Unknown";
  }
}
