import { Attachment } from "../../../types/ApiTypes";
import { Button, ButtonGroup, InputGroup } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ChooseAttachmentDialog } from "../ChooseAttachmentDialog/ChooseAttachmentDialog";
import { getAttachmentDownloadUrl } from "../../../helpers/AttachmentsHelper";

export type AttachmentValueCellProps = {
  value: string;
  onChange: (attachmentId: string) => void;
};

export function AttachmentValueCell({
  value,
  onChange,
}: AttachmentValueCellProps) {
  const params = useParams<{ projectId: string; quoteId: string }>();
  const projectId = parseInt(params.projectId);
  const [attachment, setAttachment] = useState<Attachment>();
  const [chooseAttachmentOpen, setChooseAttachmentOpen] = useState(false);

  useEffect(() => {
    if (value) {
      axios
        .get(`/attachments/${value}`)
        .then((r) => r.data as Attachment)
        .then((a) => setAttachment(a));
    } else {
      setAttachment(undefined);
    }
  }, [value]);

  return (
    <>
      <ChooseAttachmentDialog
        isOpen={chooseAttachmentOpen}
        projectId={projectId}
        onChooseAttachment={(f) => {
          onChange(`${f.id!}`);
          setChooseAttachmentOpen(false);
        }}
        onClose={() => {
          setChooseAttachmentOpen(false);
        }}
        lazy
        usePortal
      />
      {!attachment && (
        <Button
          text="Choose an Attachment"
          onClick={() => setChooseAttachmentOpen(true)}
          icon="paperclip"
          fill
        />
      )}

      {attachment && (
        <div className="flex items-center">
          <InputGroup
            value={attachment.name}
            readOnly
            leftIcon="document"
            fill
            rightElement={
              <ButtonGroup>
                <Button
                  icon="download"
                  text="Download"
                  onClick={() => {
                    window.open(getAttachmentDownloadUrl(attachment));
                  }}
                />
                <Button
                  icon="edit"
                  text="Change"
                  onClick={() => setChooseAttachmentOpen(true)}
                />
              </ButtonGroup>
            }
          />
        </div>
      )}
    </>
  );
}
