import React, { Reducer, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import {
  Button,
  FormGroup,
  Position,
  Spinner,
  Tooltip,
} from "@blueprintjs/core";
import { PurchaseOrderSubnavbar } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderSubnavbar";
import {
  Account,
  Address,
  CommitPurchaseOrderDraftPayloadInput,
  Contact,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";

import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import {
  GetFullConfigurationCodeFragment,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";

import { parseInt } from "lodash";
import { ExportPurchaseOrderToQuickbooksDialog } from "../../../../../components/projects/[projectId]/purchase-orders/ExportPurchaseOrderToQuickbooksDialog";
import { QuickSettings } from "../../../../../components/shared/QuickSettings/QuickSettings";
import { QuickSettingItem } from "../../../../../components/shared/QuickSettings/QuickSettingItem";
import { DateInput } from "@blueprintjs/datetime";
import { formatDate } from "../../../../../helpers/DateHelper";
import FufillmentDateNotes from "../../../../../components/shared/FufillmentDateNotes";
import { LineItemEditor } from "../../../../../components/shared/LineItemEditor/LineItemEditor";
import {
  PurchaseOrderEditorActions,
  PurchaseOrderEditorState,
} from "../../../../../components/shared/LineItemEditor/reducers/PurchaseOrderEditor/PurchaseOrderEditorTypes";
import * as actions from "../../../../../components/shared/LineItemEditor/reducers/PurchaseOrderEditor/PurchaseOrderEditorActions";
import * as baseActions from "../../../../../components/shared/LineItemEditor/reducers/LineItemEditor/LineItemEditorActions";
import { FormatContactNameContactFragment } from "../../../../../helpers/ContactHelper";
import { UnsavedChangesWarning } from "../../../../../components/shared/LineItemEditor/UnsavedChangesWarning";
import { buildCommitPurchaseOrderDraftPayload } from "../../../../../components/shared/LineItemEditor/helpers/PurchaseOrderEditorHelper";
import { ErrorDialog } from "../../../../../components/shared/ErrorDialog/ErrorDialog";
import { PriceCell } from "../../../../../components/shared/Table/TableCells/PriceCell";
import { ContactInputGroup } from "../../../../../components/shared/ContactInputGroup";
import { AddressInputGroup } from "../../../../../components/shared/AddressInputGroup/AddressInputGroup";
import { AccountInputGroup } from "../../../../../components/shared/AccountInputGroup";
import { updatePrimaryContactAndApplyDefaults } from "../../../../../components/shared/LineItemEditor/helpers/LineItemEditorHelpers";

type CommitPurchaseOrderDraftParams = {
  input: CommitPurchaseOrderDraftPayloadInput;
};

type CommitPurchaseOrderDraftResult = {
  commitPurchaseOrderDraft: {
    createdPurchaseOrderLineItems: {
      id: number;
    }[];
  };
};

const CommitPurchaseOrderDraft = gql`
  mutation CommitPurchaseOrderDraft(
    $input: CommitPurchaseOrderDraftPayloadInput!
  ) {
    commitPurchaseOrderDraft(payload: $input) {
      createdPurchaseOrderLineItems {
        id
      }
    }
  }
`;

type GetPurchaseOrderDetailsParams = {
  purchaseOrderId: number;
};

type GetPurchaseOrderDetailsResult = {
  purchaseOrders: {
    id: number;
    code: string;
    manufacturerId: number;
    vendor: {
      id: number;
      name: string;
    };
    project: {
      id: number;
      taxOriginAddressId?: number;
      taxDestinationAddressId?: number;
      primaryContact?: Contact;
      fulfillmentContact?: Contact;
      billingAddress?: Address;
      shipToAddress?: Address;
      salesRepAccount?: Account;
    };
    quickbooksPurchaseOrderId?: string;
    requestedShipDate?: string;
    requestedFulfillmentDate?: string;
    requestedFulfillmentDateNotes?: string;
    requestedFulfillmentDateFlagged?: boolean;
    lineItems: {
      id: number;
      sortIndex: number;
      discountingStructure: string;
      listPriceMultiplier: number;
      quantity: number;
      description: string;
      internalDescription: string;
      purchasePrice: number;
      configuration: ProductConfiguration;
    }[];
  }[];
};

export const GetPurchaseOrderDetails = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}
  ${FormatContactNameContactFragment}

  query GetPurchaseOrderDetails($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      code
      quickbooksPurchaseOrderId
      requestedShipDate
      requestedFulfillmentDate
      requestedFulfillmentDateNotes
      requestedFulfillmentDateFlagged
      manufacturerId
      project {
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
          }
          company {
            id
            name
          }
          ...FormatContactNameContactFragment
        }
        fulfillmentContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
          }
          company {
            id
            name
          }
          ...FormatContactNameContactFragment
        }
        billingAddress {
          id
          street1
          street2
          city
          state
          postalCode
        }
        shipToAddress {
          id
          street1
          street2
          city
          state
          postalCode
        }
        salesRepAccount {
          id
          name
          email
        }
      }
      vendor {
        id
        name
      }
      lineItems(order: { sortIndex: ASC }) {
        id
        sortIndex
        discountingStructure
        listPriceMultiplier
        quantity
        description
        purchasePrice
        internalDescription
        configuration {
          id
          isCustomConfiguration
          customCode
          customDescription
          customListPrice
          customVendorId
          catalogProductId
          catalogProduct {
            id
            code
            description
          }
          features {
            id
            catalogOptionGroupId
            catalogOptionGroup {
              id
              description
            }
            selections {
              id
              catalogOptionId
              catalogOption {
                id
                code
                description
              }
              catalogOptionGroupId
              catalogOptionGroup {
                id
                code
                description
              }
            }
          }
          vendor {
            id
            name
            freightRules
          }
          ...GetFullConfigurationCodeFragment
          ...GetFullConfigurationDescriptionFragment
        }
      }
    }
  }
`;

export function ProjectPurchaseOrderDetails() {
  const apolloClient = useApolloClient();
  const { projectId, purchaseOrderId } =
    useParams<{ projectId: string; purchaseOrderId: string }>();

  const [saveError, setSaveError] = useState<Error>();
  const [exportPurchaseOrderOpen, setExportPurchaseOrderOpen] = useState(false);

  const [state, dispatch] = useReducer<
    Reducer<PurchaseOrderEditorState, PurchaseOrderEditorActions>,
    PurchaseOrderEditorState
  >(
    (state, action) =>
      (actions[action.type] ?? baseActions[action.type])(state, action),
    {
      quickSettings: { isModified: false },
      purchaseOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    },
    () => ({
      quickSettings: { isModified: false },
      purchaseOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    })
  );

  const isModified =
    state.quickSettings.isModified ||
    state.lineItemGroups.some(
      (lig) =>
        lig.isModified ||
        lig.isCreated ||
        lig.isRemoved ||
        lig.lineItems.some(
          (li) => li.isModified || li.isCreated || li.isRemoved
        )
    );

  const getPurchaseOrderDetails = useQuery<
    GetPurchaseOrderDetailsResult,
    GetPurchaseOrderDetailsParams
  >(GetPurchaseOrderDetails, {
    variables: {
      purchaseOrderId: parseInt(purchaseOrderId),
    },
    fetchPolicy: "cache-and-network",
  });

  const [commitPurchaseOrderDraft, { loading: committingPurchaseOrderDraft }] =
    useMutation<CommitPurchaseOrderDraftResult, CommitPurchaseOrderDraftParams>(
      CommitPurchaseOrderDraft,
      { refetchQueries: [GetPurchaseOrderDetails] }
    );

  useEffect(() => {
    if (getPurchaseOrderDetails.loading) return;
    dispatch({
      type: "initializeWithPurchaseOrder",
      purchaseOrder: getPurchaseOrderDetails.data!.purchaseOrders[0],
    });
  }, [getPurchaseOrderDetails.data, getPurchaseOrderDetails.loading]);

  async function saveChanges() {
    try {
      const payload = buildCommitPurchaseOrderDraftPayload(
        parseInt(purchaseOrderId),
        state
      );
      await commitPurchaseOrderDraft({ variables: { input: payload } });
    } catch (err) {
      setSaveError(err);
    }
  }

  if (getPurchaseOrderDetails.loading) {
    return (
      <PortalContainer>
        <Spinner />
      </PortalContainer>
    );
  }

  const purchaseOrder = getPurchaseOrderDetails.data!.purchaseOrders[0];

  return (
    <>
      <ExportPurchaseOrderToQuickbooksDialog
        isOpen={exportPurchaseOrderOpen}
        purchaseOrderId={parseInt(purchaseOrderId)}
        onClose={() => setExportPurchaseOrderOpen(false)}
      />

      <Helmet>
        <title>{purchaseOrder.code}</title>
      </Helmet>

      <ErrorDialog
        isOpen={!!getPurchaseOrderDetails.error}
        error={getPurchaseOrderDetails.error}
      />

      <ErrorDialog
        isOpen={!!saveError}
        error={saveError}
        onClose={() => setSaveError(undefined)}
      />

      <ProjectNavbar
        projectLoading={getPurchaseOrderDetails.loading}
        projectName={`P-${purchaseOrder.project.id}`}
        projectPrimaryContact={purchaseOrder.project.primaryContact}
        projectId={parseInt(projectId)}
        additionalHeaderItems={[
          {
            text: "Purchase Orders",
            href: `/projects/${projectId}/purchase-orders`,
          },
          {
            text: purchaseOrder?.code ?? "...",
            href: `/projects/${projectId}/purchase-orders/${purchaseOrderId}`,
          },
        ]}
      />

      <PurchaseOrderSubnavbar
        title={`${purchaseOrder.code} (${purchaseOrder.vendor.name})`}
        loading={getPurchaseOrderDetails.loading}
        actions={
          <>
            <Button
              text="Export to Quickbooks"
              icon="export"
              onClick={async () => {
                if (isModified) await saveChanges();
                setExportPurchaseOrderOpen(true);
              }}
            />
            <Tooltip
              content="Print or Export Purchase Order"
              position={Position.BOTTOM}
            >
              <Button
                minimal
                icon="print"
                onClick={async () => {
                  if (isModified) await saveChanges();
                  window.open(
                    "/projects/" +
                      projectId +
                      "/purchase-orders/" +
                      purchaseOrderId +
                      "/printable",
                    "_blank"
                  );
                }}
              />
            </Tooltip>
          </>
        }
      />

      {isModified && (
        <UnsavedChangesWarning
          resourceType={"purchase order"}
          onSave={saveChanges}
          saving={committingPurchaseOrderDraft}
        />
      )}

      <QuickSettings>
        <FormGroup label="Primary Contact">
          <ContactInputGroup
            value={state.quickSettings.primaryContact}
            onChange={(v) =>
              updatePrimaryContactAndApplyDefaults({
                state,
                dispatch,
                primaryContact: v,
                apolloClient,
              })
            }
          />
        </FormGroup>

        <FormGroup label="Billing Address">
          <AddressInputGroup
            value={state.quickSettings.billingAddress}
            onChange={(v) =>
              dispatch({ type: "setBillingAddress", billingAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Ship To Address">
          <AddressInputGroup
            value={state.quickSettings.shipToAddress}
            onChange={(v) =>
              dispatch({ type: "setShipToAddress", shipToAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Sales Rep">
          <AccountInputGroup
            value={state.quickSettings.salesRepAccount}
            onChange={(v) =>
              dispatch({ type: "setSalesRepAccount", salesRepAccount: v })
            }
          />
        </FormGroup>

        <FormGroup label="Requested Fulfillment Date">
          <QuickSettingItem>
            <div className="space-x-1">
              <DateInput
                value={state.quickSettings.requestedFulfillmentDate}
                onChange={(v) =>
                  dispatch({
                    type: "setRequestedFulfillmentDate",
                    requestedFulfillmentDate: v,
                  })
                }
                parseDate={(d) => new Date(d)}
                formatDate={formatDate}
              />

              <FufillmentDateNotes
                flagged={state.quickSettings.requestedFulfillmentDateFlagged}
                body={state.quickSettings.requestedFulfillmentDateNotes}
                onSubmit={(status) => {
                  dispatch({
                    type: "setRequestedFulfillmentDateFlagged",
                    requestedFulfillmentDateFlagged: status.flagged,
                  });
                  dispatch({
                    type: "setRequestedFulfillmentDateNotes",
                    requestedFulfillmentDateNotes: status.body,
                  });
                }}
              />
            </div>
          </QuickSettingItem>
        </FormGroup>

        <FormGroup label="Requested Ship Date">
          <QuickSettingItem>
            <DateInput
              value={state.requestedShipDate}
              onChange={(v) =>
                dispatch({ type: "setRequestedShipDate", requestedShipDate: v })
              }
              parseDate={(d) => new Date(d)}
              formatDate={formatDate}
              fill
            />
          </QuickSettingItem>
        </FormGroup>
      </QuickSettings>

      <PortalContainer>
        <LineItemEditor<PurchaseOrderEditorState, PurchaseOrderEditorActions>
          visibleDefaultColumns={[
            "product",
            "description",
            "quantity",
            "vendorListPrice",
            "discountListPriceMultiplier",
          ]}
          additionalColumnHeaders={() => (
            <>
              <th>Purchase Price</th>
            </>
          )}
          additionalColumns={({
            state,
            dispatch,
            lineItemIndex,
            lineItemGroupIndex,
            readOnly,
          }) => {
            const lineItem =
              state.lineItemGroups[lineItemGroupIndex].lineItems[lineItemIndex];

            return (
              <>
                <td>
                  <PriceCell
                    disabled={readOnly}
                    value={lineItem.purchasePrice}
                    onChange={(e) =>
                      dispatch({
                        type: "setLineItemPurchasePrice",
                        lineItemGroupIndex,
                        lineItemIndex,
                        purchasePrice: e,
                      })
                    }
                  />
                </td>
              </>
            );
          }}
          shipToAddressId={purchaseOrder.project.shipToAddress?.id}
          fulfillmentContactId={
            purchaseOrder.project.fulfillmentContact?.id ??
            purchaseOrder.project.primaryContact?.id
          }
          groupsReadOnly
          state={state}
          dispatch={dispatch}
        />
      </PortalContainer>
    </>
  );
}
