import {
  Printable,
  PrintableHeaderColumn,
  PrintableHeaderColumnItem,
} from "../../../../../components/shared/Printable/Printable";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import styles from "../../../../../components/shared/Printable/Printable.module.scss";
import { gql, useQuery } from "@apollo/client";
import { formatContactName } from "../../../../../helpers/ContactHelper";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { Spinner } from "@blueprintjs/core";
import {
  CompanyAddressType,
  PersonAddressType,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";
import { formatAddress } from "../../../../../helpers/AddressHelper";
import { formatDate } from "../../../../../helpers/DateHelper";
import { formatAccountName } from "../../../../../helpers/AccountHelper";
import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import { formatPrice } from "../../../../../helpers/PriceHelper";

type GetSalesOrderDetailsResult = {
  salesOrders: {
    id: number;
    code: string;
    requestedFulfillmentDate: string;
    createdBy: {
      id: number;
      name: string;
    };
    quote: {
      id: number;
      code: string;
    };
    salesOrderLineItemGroups: {
      id: number;
      sortIndex: number;
      name: string;
      salesOrderLineItems: {
        id: number;
        sortIndex: number;
        quantity: number;
        configuration?: ProductConfiguration;
        description: string;
        sellPrice: number;
        taxAmount: number;
      }[];
    }[];
    project: {
      id: number;
      salesRepAccount?: {
        id: number;
        name: string;
      };
      primaryContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
      fulfillmentContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
    };
  }[];
};

type GetSalesOrderDetailsParams = {
  salesOrderId: number;
};

const GetSalesOrderDetails = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetSalesOrderDetails($salesOrderId: Int!) {
    salesOrders(where: { id: { eq: $salesOrderId } }) {
      id
      code
      requestedFulfillmentDate
      quote {
        id
        code
      }
      createdBy {
        id
        name
      }
      project {
        id
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            phone
            email
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            phone
            email
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
        fulfillmentContact {
          id
          person {
            id
            firstName
            lastName
            phone
            email
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            phone
            email
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
      }
      salesOrderLineItemGroups {
        id
        name
        sortIndex
        salesOrderLineItems {
          id
          sortIndex
          quantity
          configuration {
            id
            ...GetFullConfigurationCodeFragment
            ...GetFullConfigurationDescriptionFragment
          }
          description
          sellPrice
          taxAmount
        }
      }
    }
  }
`;

function getPersonPrimaryAddress(
  addresses?: {
    id: number;
    type: PersonAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === PersonAddressType.Primary)?.address;
}

function getCompanyPrimaryAddress(
  addresses?: {
    id: number;
    type: CompanyAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === CompanyAddressType.Primary)?.address;
}

export function SalesOrderPrintable() {
  const { salesOrderId } = useParams<{ salesOrderId: string }>();

  const getSalesOrderDetails = useQuery<
    GetSalesOrderDetailsResult,
    GetSalesOrderDetailsParams
  >(GetSalesOrderDetails, {
    fetchPolicy: "cache-and-network",
    variables: { salesOrderId: parseInt(salesOrderId) },
  });

  if (getSalesOrderDetails.loading) {
    return (
      <PortalContainer>
        <Spinner />
      </PortalContainer>
    );
  }

  const salesOrder = getSalesOrderDetails.data!.salesOrders[0];

  const subtotalAmount = salesOrder.salesOrderLineItemGroups.reduce(
    (subtotal, group) =>
      subtotal +
      group.salesOrderLineItems.reduce(
        (subtotal, lineItem) =>
          subtotal + lineItem.sellPrice * lineItem.quantity,
        0
      ),
    0
  );

  const taxAmount = salesOrder.salesOrderLineItemGroups.reduce(
    (subtotal, group) =>
      subtotal +
      group.salesOrderLineItems.reduce(
        (subtotal, lineItem) => subtotal + lineItem.taxAmount,

        0
      ),
    0
  );

  const totalAmount = subtotalAmount + taxAmount;

  return (
    <div className={styles.printoutPage}>
      <div className={styles.printoutPageContent}>
        <Helmet>
          <title>
            {[
              salesOrder.code,
              salesOrder.project.primaryContact
                ? formatContactName(salesOrder.project.primaryContact)
                : undefined,
            ]
              .filter(Boolean)
              .join(" - ")}
          </title>
        </Helmet>

        <Printable
          title={salesOrder.code}
          headerColumns={
            <>
              <PrintableHeaderColumn title="Customer Info">
                {salesOrder?.project.primaryContact ? (
                  <>
                    {salesOrder.project.primaryContact.person && (
                      <PrintableHeaderColumnItem label="Contact">
                        {salesOrder.project.primaryContact.person.firstName}
                        &nbsp;
                        {salesOrder.project.primaryContact.person.lastName}
                      </PrintableHeaderColumnItem>
                    )}
                    {salesOrder.project.primaryContact.company && (
                      <PrintableHeaderColumnItem label="Company">
                        {salesOrder.project.primaryContact.company.name}
                      </PrintableHeaderColumnItem>
                    )}
                    <PrintableHeaderColumnItem label="Email">
                      {salesOrder.project.primaryContact.person?.email ??
                        salesOrder.project.primaryContact.company?.email}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Phone">
                      {salesOrder.project.primaryContact.person?.phone ??
                        salesOrder.project.primaryContact.company?.phone}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Address">
                      {formatAddress(
                        getPersonPrimaryAddress(
                          salesOrder.project.primaryContact.person
                            ?.personAddresses
                        ) ??
                          getCompanyPrimaryAddress(
                            salesOrder.project.primaryContact.company
                              ?.companyAddresses
                          )
                      )}
                    </PrintableHeaderColumnItem>
                  </>
                ) : (
                  <PrintableHeaderColumnItem>
                    <span className="text-red-400">
                      Primary contact not specified.{" "}
                      <Link to={`/projects/${salesOrder!.project.id}/contacts`}>
                        Fix
                      </Link>
                    </span>
                  </PrintableHeaderColumnItem>
                )}
              </PrintableHeaderColumn>

              <PrintableHeaderColumn title="Fulfillment Contact">
                {salesOrder.project.fulfillmentContact ? (
                  <>
                    {salesOrder.project.fulfillmentContact.person && (
                      <PrintableHeaderColumnItem label="Contact">
                        {salesOrder.project.fulfillmentContact.person.firstName}
                        &nbsp;
                        {salesOrder.project.fulfillmentContact.person.lastName}
                      </PrintableHeaderColumnItem>
                    )}
                    {salesOrder.project.fulfillmentContact.company && (
                      <PrintableHeaderColumnItem label="Company">
                        {salesOrder.project.fulfillmentContact.company.name}
                      </PrintableHeaderColumnItem>
                    )}
                    <PrintableHeaderColumnItem label="Email">
                      {salesOrder.project.fulfillmentContact.person?.email ??
                        salesOrder.project.fulfillmentContact.company?.email}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Phone">
                      {salesOrder.project.fulfillmentContact.person?.phone ??
                        salesOrder.project.fulfillmentContact.company?.phone}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Address">
                      {formatAddress(
                        getPersonPrimaryAddress(
                          salesOrder.project.fulfillmentContact.person
                            ?.personAddresses
                        ) ??
                          getCompanyPrimaryAddress(
                            salesOrder.project.fulfillmentContact.company
                              ?.companyAddresses
                          )
                      )}
                    </PrintableHeaderColumnItem>
                  </>
                ) : (
                  <PrintableHeaderColumnItem>
                    <span className="text-red-400">
                      Fulfillment contact not specified.{" "}
                      <Link to={`/projects/${salesOrder.project.id}/contacts`}>
                        Fix
                      </Link>
                    </span>
                  </PrintableHeaderColumnItem>
                )}
              </PrintableHeaderColumn>

              <PrintableHeaderColumn>
                <PrintableHeaderColumnItem label="Sales Order #">
                  {salesOrder.code}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Requested at">
                  {salesOrder.requestedFulfillmentDate
                    ? formatDate(salesOrder.requestedFulfillmentDate)
                    : "-"}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Written by">
                  {formatAccountName(salesOrder.createdBy)}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Sales rep">
                  {salesOrder.project.salesRepAccount
                    ? formatAccountName(salesOrder.project.salesRepAccount)
                    : "-"}
                </PrintableHeaderColumnItem>
              </PrintableHeaderColumn>
            </>
          }
        >
          <div className="space-y-4 text-xs">
            <BasicTable>
              <thead>
                <tr>
                  <th style={{ width: 40 }}>#</th>
                  <th style={{ width: 40 }}>QTY</th>
                  <th style={{ width: 120 }}>Code</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Tax</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {[...salesOrder.salesOrderLineItemGroups]
                  .sort((a, b) => a.sortIndex - b.sortIndex)
                  .map((salesOrderLineItemGroup) => (
                    <>
                      <tr className="bg-gray-700 text-white">
                        <td colSpan={7}>{salesOrderLineItemGroup.name}</td>
                      </tr>

                      {[...salesOrderLineItemGroup.salesOrderLineItems]
                        .sort((a, b) => a.sortIndex - b.sortIndex)
                        .map((salesOrderLineItem) => (
                          <tr key={salesOrderLineItem.id}>
                            <td>{salesOrderLineItem.sortIndex + 1}</td>
                            <td>{salesOrderLineItem.quantity}</td>
                            <td>
                              {salesOrderLineItem.configuration && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getFullConfigurationCode(
                                      salesOrderLineItem.configuration
                                    ).replaceAll("\n", "<br />"),
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              {salesOrderLineItem.configuration && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getFullConfigurationDescription(
                                      salesOrderLineItem.configuration
                                    ).replaceAll("\n", "<br />"),
                                  }}
                                />
                              )}
                            </td>
                            <td>{formatPrice(salesOrderLineItem.sellPrice)}</td>
                            <td>{formatPrice(salesOrderLineItem.taxAmount)}</td>
                            <td>
                              {formatPrice(
                                (salesOrderLineItem.sellPrice +
                                  salesOrderLineItem.taxAmount) *
                                  salesOrderLineItem.quantity
                              )}
                            </td>
                          </tr>
                        ))}
                    </>
                  ))}
              </tbody>
            </BasicTable>

            <div className="flex space-x-4">
              <div className="rounded border space-y-1 p-1 flex-1">
                <h4 className="font-medium">Memo</h4>
                {salesOrder.quote && <p>Quote #: {salesOrder.quote.code}</p>}
              </div>
              <div className="border-2 rounded divide-y w-52">
                <div className="divide-x flex items-center">
                  <div className="w-24 p-1 font-medium">Subtotal</div>
                  <div className="p-1">{formatPrice(subtotalAmount)}</div>
                </div>
                <div className="divide-x flex items-center">
                  <div className="w-24 p-1 font-medium">Tax</div>
                  <div className="p-1">{formatPrice(taxAmount)}</div>
                </div>
                <div className="divide-x flex items-center bg-gray-100">
                  <div className="w-24 p-1 font-medium">Grand Total</div>
                  <div className="p-1">{formatPrice(totalAmount)}</div>
                </div>
              </div>
            </div>

            <div className="space-y-4 p-1 rounded border">
              <div className="space-y-2">
                <h4 className="font-medium">Payment Terms</h4>
                <ul className="list-disc ml-6 space-y-2">
                  <li>
                    All credit card payments will be charged a 3% convenience
                    fee.
                  </li>
                  <li>
                    Warehouse of Fixtures –All furniture orders require payment
                    in full unless credit is approved by the Warehouse of
                    Fixtures, TNG Finance Department. A credit check will be
                    required prior to terms being extended. The balance is due
                    on delivery or will call unless prior arrangements have been
                    made. If a product is left off the delivery or will call due
                    to back order, or other issues the customer is entitled to
                    withhold the value of missing products. Exceptions can be
                    made prior to the client signing the contract.
                  </li>
                  <li>
                    All special orders products require payment in full prior to
                    placement of order. We will hold new and used furniture that
                    has a zero balance for one month past the original delivery
                    date without charging a storage fee. After the first month
                    $.50 per square foot will be assessed. When dealing with
                    both new and used systems furniture there will often be a
                    punch list of things that need to be finished after
                    delivery. In this case, the order total minus 10% is due
                    after delivery. On completion of the punch list the
                    remaining 10% is due.
                  </li>
                </ul>
              </div>

              <div className="space-y-2">
                <h4 className="font-medium">Delivery Policies</h4>
                <ul className="list-disc ml-6 space-y-2">
                  <li>
                    Incorrect Delivery Information—In the event that any of the
                    delivery information provided to us is incorrect we reserve
                    the right to either refuse to complete the delivery or
                    assess an additional charge. This would include stairs where
                    there are no stairs listed on the site conditions.
                  </li>
                  <li>
                    Area not ready and/or Delay Orders—If the area where the
                    product is being delivered is not ready for the delivery
                    crew, a fee of $30 per man hour will be assessed while the
                    crew is waiting.
                  </li>
                  <li>
                    Delays - If construction delays or other causes not within
                    Warehouse of Fixtures control force postponement of delivery
                    and/or installation, the product will be stored until
                    delivery or installation can be resumed and will be
                    considered accepted by the Buyer for purpose of payment. In
                    such an event, the Buyer shall reserve the right to withhold
                    10% of the purchase price (for 60 days maximum) against the
                    completion of the contract.
                  </li>
                </ul>
              </div>

              <div className="space-y-2">
                <h4 className="font-medium">
                  Cancellation &amp; Return Policy
                </h4>
                <p>
                  After an order has been placed for a new furniture or
                  non-returnable product no cancellations or returns are
                  permitted. If a product being cancelled can be restocked there
                  will be a restocking fee of 20% plus freight. Used furniture
                  being returned must be brought back to us in the same
                  condition as received within 5 business days of the sale.
                </p>
              </div>
            </div>

            <p>
              Client signature confirms that the above items are correct and
              that they understand WOF’s Terms & Conditions.
            </p>

            <div className="divide-y rounded border">
              <div className="divide-x flex items-center">
                <div className="flex-1 p-1 space-y-2 h-20">
                  <h4 className="font-medium">Client Signature</h4>
                </div>
                <div className="flex-1 p-1 space-y-2 h-20">
                  <h4 className="font-medium">Date</h4>
                </div>
              </div>
              <div className="divide-x flex items-center">
                <div className="flex-1 p-1 space-y-2 h-20">
                  <h4 className="font-medium">Sales Rep Initials</h4>
                </div>
                <div className="flex-1 p-1 space-y-2 h-20">
                  <h4 className="font-medium">Spec Check</h4>
                </div>
              </div>
            </div>
          </div>
        </Printable>
      </div>
    </div>
  );
}
