import React from "react";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import {
  Button,
  ButtonGroup,
  InputGroup,
  MenuItem,
  Position,
} from "@blueprintjs/core";

type CompanyInputGroupCompany = {
  id: number;
  name: string;
};

export type CompanyInputGroupProps = {
  companies: CompanyInputGroupCompany[];
  loading: boolean;
  value?: CompanyInputGroupCompany;
  onChange?: (e?: CompanyInputGroupCompany) => void;
};

export function CompanyInputGroup({
  companies,
  value,
  onChange,
}: CompanyInputGroupProps) {
  const CompanySelect = Select.ofType<CompanyInputGroupCompany>();

  const filterCompany: ItemPredicate<CompanyInputGroupCompany> = (
    query,
    company
  ) => {
    if (query === "") return true;
    const normalizedQuery = query.toLowerCase().trim();
    const normalizedName = company.name.toLowerCase().trim();
    return normalizedName.indexOf(normalizedQuery) >= 0;
  };

  const renderCompany: ItemRenderer<CompanyInputGroupCompany> = (
    company,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        key={company.id}
        onClick={handleClick}
        text={company.name}
      />
    );
  };

  return (
    <InputGroup
      rightElement={
        <CompanySelect
          items={companies}
          itemPredicate={filterCompany}
          itemRenderer={renderCompany}
          noResults={<MenuItem disabled={true} text="No results." />}
          activeItem={value}
          onItemSelect={(e) => onChange && onChange(e)}
          popoverProps={{ minimal: true, position: Position.BOTTOM_RIGHT }}
        >
          <ButtonGroup>
            <Button text={value ? "Change" : "Choose"} />
            {value && (
              <Button
                icon="cross"
                onClick={() => onChange && onChange(undefined)}
              />
            )}
          </ButtonGroup>
        </CompanySelect>
      }
      value={value ? `${value.name}` : "Choose company"}
      large
      readOnly
    />
  );
}
