import {
  Button,
  ButtonGroup,
  InputGroup,
  Popover,
  Position,
} from "@blueprintjs/core";
import { gql } from "@apollo/client";
import { CatalogProduct } from "../../../../types/ApiTypes";
import { ChooseProductMenu } from "../../ChooseProductMenu/ChooseProductMenu";
import React, { useState } from "react";
import { getFullEditableConfigurationCode } from "../../../../helpers/CatalogHelper";
import { EditableProductConfiguration } from "../../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";

export const PRODUCT_CELL_PRODUCT_FRAGMENT = gql`
  fragment ProductCellProductFragment on Product {
    code
    description
    catalog {
      id
      name
    }
    vendor {
      id
      freightRules
    }
    defaultPrice {
      id
      amount
    }
  }
`;

export const PRODUCT_CELL_OPTION_FRAGMENT = gql`
  fragment ProductCellOptionFragment on Option {
    code
  }
`;

export const PRODUCT_CELL_INVENTORY_ITEM_FRAGMENT = gql`
  fragment ProductCellInventoryItemFragment on InventoryItem {
    catalogProductId
    catalogProduct {
      id
      code
      description
      vendor {
        id
        freightRules
      }
    }
  }
`;

export type ProductCellProps = {
  configuration?: EditableProductConfiguration;
  catalogProduct?: CatalogProduct;
  onConfigurationChange: (
    newConfiguration: EditableProductConfiguration
  ) => void;
  onRemoveProduct: () => void;
  onEditProduct: () => void;
};

export function ProductCell({
  configuration,
  catalogProduct,
  onConfigurationChange,
  onRemoveProduct,
  onEditProduct,
}: ProductCellProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (configuration) {
    return (
      <div className="space-y-2">
        <InputGroup
          leftIcon={configuration.isCustomConfiguration ? "new-object" : "book"}
          value={getFullEditableConfigurationCode(configuration)}
          rightElement={
            <ButtonGroup>
              <Button text="Configure" onClick={onEditProduct} />
              <Button icon="cross" onClick={onRemoveProduct} />
            </ButtonGroup>
          }
          disabled
          readOnly
        />
        {catalogProduct?.catalog && (
          <p className="m-0 font-medium">{catalogProduct.catalog.name}</p>
        )}
      </div>
    );
  }

  return (
    <Popover
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      targetClassName="w-full"
      position={Position.TOP_LEFT}
      minimal
    >
      <Button
        text="Choose a Product"
        icon="book"
        alignText="left"
        onClick={() => setPopoverOpen(true)}
        fill
        minimal
      />
      <ChooseProductMenu
        allowInventoryItems={false}
        onChooseProduct={(p) => {
          onConfigurationChange({
            isCustomConfiguration: false,
            catalogProductId: p.id,
            catalogProductCode: p.code,
            features: [],
            catalogProductDescription: p.description,
          });
          setPopoverOpen(false);
        }}
        onChooseCustomProduct={() => {
          onConfigurationChange({
            isCustomConfiguration: true,
            customCode: "TBD",
            customDescription: "TBD",
            customListPrice: 0,
            features: [],
          });
          setPopoverOpen(false);
        }}
        onChooseInventoryItem={() => {}}
      />
    </Popover>
  );
}
