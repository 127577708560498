import {
  EditableLineItem,
  EditableLineItemGroup,
  EditableProductConfiguration,
  LineItemEditorActions,
  LineItemEditorState,
} from "../reducers/LineItemEditor/LineItemEditorTypes";
import React from "react";
import { ApolloClient, gql } from "@apollo/client";
import { getCatalogProductSuggestedPricingInfo } from "../../../../helpers/CatalogHelper";
import { getTaxCode } from "../../../../helpers/TaxHelper";
import {
  Account,
  Address,
  CompanyAddressType,
  PersonAddressType,
} from "../../../../types/ApiTypes";
import { ContactInputGroupContact } from "../../ContactInputGroup";
import { formatAddress } from "../../../../helpers/AddressHelper";
import { formatContactName } from "../../../../helpers/ContactHelper";

type GetContactDefaultsParams = {
  personId?: number;
  companyId?: number;
  includePerson: boolean;
  includeCompany: boolean;
};

type GetContactDefaultsResult = {
  people?: {
    id: number;
    personAddresses: {
      id: number;
      type: PersonAddressType;
      address: Address;
    }[];
  }[];
  companies?: {
    id: number;
    companyAddresses: {
      id: number;
      type: CompanyAddressType;
      address: Address;
    }[];
    defaultSalesRep?: Account;
  }[];
};

const GetContactDefaults = gql`
  query GetContactDefaults(
    $personId: Int
    $companyId: Int
    $includePerson: Boolean!
    $includeCompany: Boolean!
  ) {
    people(where: { id: { eq: $personId } }) @include(if: $includePerson) {
      personAddresses {
        id
        type
        address {
          id
          street1
          street2
          city
          state
          postalCode
        }
      }
    }
    companies(where: { id: { eq: $companyId } }) @include(if: $includeCompany) {
      id
      companyAddresses {
        id
        type
        address {
          id
          street1
          street2
          city
          state
          postalCode
        }
      }
      defaultSalesRep {
        id
        name
        email
      }
    }
  }
`;

type GetFulfillmentInfoParams = {
  addressId?: number;
  contactId?: number;
  includeAddress: boolean;
  includeContact: boolean;
};

type GetFulfillmentInfoResult = {
  contacts?: {
    id: number;
    person: {
      id: number;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
    };
    company: {
      id: number;
      name: string;
      email: string;
      phone: string;
    };
  }[];
  addresses?: {
    id: number;
    street1: string;
    street2: string;
    city: string;
    state: string;
    postalCode: string;
    addressSiteInformation: {
      id: number;
      numberOfInsideStairs: number;
      numberOfOutsideStairs: number;
      hasDock: boolean;
      hasFreightElevator: boolean;
      hasPassengerElevator: boolean;
      isCertificateOfInsuranceRequired: boolean;
    };
  }[];
};

const GetFulfillmentInfo = gql`
  query GetFulfillmentAddressSiteInfo(
    $addressId: Int
    $contactId: Int
    $includeAddress: Boolean!
    $includeContact: Boolean!
  ) {
    contacts(where: { id: { eq: $contactId } }) @include(if: $includeContact) {
      id
      person {
        id
        firstName
        lastName
        email
        phone
      }
      company {
        id
        name
        email
        phone
      }
    }
    addresses(where: { id: { eq: $addressId } }) @include(if: $includeAddress) {
      id
      street1
      street2
      city
      state
      postalCode
      addressSiteInformation {
        id
        numberOfInsideStairs
        numberOfOutsideStairs
        hasDock
        hasFreightElevator
        hasPassengerElevator
        isCertificateOfInsuranceRequired
      }
    }
  }
`;

export async function updatePrimaryContactAndApplyDefaults({
  primaryContact,
  state,
  dispatch,
  apolloClient,
}: {
  primaryContact?: ContactInputGroupContact;
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>;
  dispatch: React.Dispatch<LineItemEditorActions>;
  apolloClient: ApolloClient<object>;
}) {
  dispatch({ type: "setPrimaryContact", primaryContact });
  if (!primaryContact) return;

  const getContactDefaultsResult = await apolloClient.query<
    GetContactDefaultsResult,
    GetContactDefaultsParams
  >({
    query: GetContactDefaults,
    variables: {
      personId: primaryContact.person?.id,
      companyId: primaryContact.company?.id,
      includeCompany: !!primaryContact.company,
      includePerson: !!primaryContact.person,
    },
    fetchPolicy: "network-only",
  });

  const firstCompany = getContactDefaultsResult.data.companies?.[0];
  const firstPerson = getContactDefaultsResult.data.people?.[0];

  if (!state.quickSettings.billingAddress) {
    const personBillingAddress = firstPerson?.personAddresses.filter(
      (a) => a.type === PersonAddressType.Billing
    )[0];

    const companyBillingAddress = firstCompany?.companyAddresses.filter(
      (a) => a.type === CompanyAddressType.Billing
    )[0];

    if (companyBillingAddress || personBillingAddress) {
      dispatch({
        type: "setBillingAddress",
        billingAddress:
          companyBillingAddress?.address ?? personBillingAddress?.address,
      });
    }
  }

  if (!state.quickSettings.shipToAddress) {
    const personShippingAddress = firstPerson?.personAddresses.filter(
      (a) => a.type === PersonAddressType.Fulfillment
    )[0];

    const companyShippingAddress = firstCompany?.companyAddresses.filter(
      (a) => a.type === CompanyAddressType.Fulfillment
    )[0];

    if (companyShippingAddress || personShippingAddress) {
      dispatch({
        type: "setShipToAddress",
        shipToAddress:
          companyShippingAddress?.address ?? personShippingAddress?.address,
      });
    }
  }

  if (!state.quickSettings.salesRepAccount) {
    const salesRepAccount = firstCompany?.defaultSalesRep;
    if (salesRepAccount) {
      dispatch({
        type: "setSalesRepAccount",
        salesRepAccount: firstCompany?.defaultSalesRep,
      });
    }
  }
}

export async function applyConfigurationChange({
  shipToAddressId,
  fulfillmentContactId,
  configuration,
  lineItemIndex,
  lineItemGroupIndex,
  state,
  dispatch,
  apolloClient,
}: {
  shipToAddressId?: number;
  fulfillmentContactId?: number;
  configuration: EditableProductConfiguration;
  lineItemGroupIndex: number;
  lineItemIndex: number;
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>;
  dispatch: React.Dispatch<LineItemEditorActions>;
  apolloClient: ApolloClient<object>;
}) {
  const lineItem =
    state.lineItemGroups[lineItemGroupIndex].lineItems[lineItemIndex];

  dispatch({
    type: "setLineItemConfiguration",
    lineItemGroupIndex,
    lineItemIndex,
    configuration: configuration,
  });

  if (!configuration.isCustomConfiguration) {
    const {
      totalListPrice,
      suggestedListPriceMultiplier,
      suggestedGrossProfitPercent,
    } = await getCatalogProductSuggestedPricingInfo(
      configuration,
      apolloClient
    );

    const taxCode = await getTaxCode(configuration, apolloClient);

    const purchasePrice = lineItem.listPriceMultiplier * totalListPrice;
    const suggestedSellPrice =
      purchasePrice / (1 - lineItem.grossProfitPercent / 100);

    if (lineItem.sellPrice !== suggestedSellPrice && lineItem.sellPrice === 0) {
      dispatch({
        type: "setLineItemSellPrice",
        lineItemGroupIndex,
        lineItemIndex,
        sellPrice: suggestedSellPrice,
      });
    }

    dispatch({
      type: "setLineItemTaxCode",
      lineItemGroupIndex,
      lineItemIndex,
      taxCode,
    });

    if (
      lineItem.grossProfitPercent !== suggestedGrossProfitPercent &&
      lineItem.grossProfitPercent === 0
    ) {
      dispatch({
        type: "setLineItemGrossProfitPercent",
        lineItemGroupIndex,
        lineItemIndex,
        grossProfitPercent: suggestedGrossProfitPercent,
      });
    }

    if (
      lineItem.listPriceMultiplier !== suggestedListPriceMultiplier &&
      lineItem.listPriceMultiplier === 1
    ) {
      dispatch({
        type: "setLineItemListPriceMultiplier",
        lineItemGroupIndex,
        lineItemIndex,
        listPriceMultiplier: suggestedListPriceMultiplier,
      });
    }
  } else {
    dispatch({
      type: "setLineItemTaxCode",
      lineItemGroupIndex,
      lineItemIndex,
      taxCode: "PA3000100",
    });
  }

  if (configuration.catalogProductCode === "WOFSV-DI") {
    await applyDeliveryAndInstallDescription({
      shipToAddressId,
      fulfillmentContactId,
      lineItemIndex,
      lineItemGroupIndex,
      state,
      dispatch,
      apolloClient,
    });
  }
}

export async function applyDeliveryAndInstallDescription({
  shipToAddressId,
  fulfillmentContactId,
  lineItemIndex,
  lineItemGroupIndex,
  state,
  dispatch,
  apolloClient,
}: {
  shipToAddressId?: number;
  fulfillmentContactId?: number;
  lineItemGroupIndex: number;
  lineItemIndex: number;
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>;
  dispatch: React.Dispatch<LineItemEditorActions>;
  apolloClient: ApolloClient<object>;
}) {
  const lineItem =
    state.lineItemGroups[lineItemGroupIndex].lineItems[lineItemIndex];

  if (lineItem.description.trim() !== "") {
    alert(
      "Delivery and Install line item description was not empty, so site information was not automatically updated. To have site information updated/provided, please clear the description and try again."
    );
    return;
  }

  const getFulfillmentInfo = await apolloClient.query<
    GetFulfillmentInfoResult,
    GetFulfillmentInfoParams
  >({
    query: GetFulfillmentInfo,
    variables: {
      addressId: shipToAddressId,
      contactId: fulfillmentContactId,
      includeContact: !!fulfillmentContactId,
      includeAddress: !!shipToAddressId,
    },
  });

  const fulfillmentAddress = getFulfillmentInfo.data.addresses?.[0];
  const fulfillmentContact = getFulfillmentInfo.data.contacts?.[0];

  const template = `
Delivery, Installation, and Assembly-
Installation will be performed during Normal Business Hours
Non – Union Labor

** Site Conditions **

Dock:

Inside Stairs    ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.numberOfInsideStairs > 0
      ? "Yes"
      : "No"
  }         * Quantity: ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.numberOfInsideStairs
  }          * Landing Yes/No

Outside Stairs   ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.numberOfOutsideStairs > 0
      ? "Yes"
      : "No"
  }         * Quantity: ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.numberOfOutsideStairs
  }          * Landing Yes/No

Elevator         ${
    (fulfillmentAddress &&
      fulfillmentAddress.addressSiteInformation.hasFreightElevator) ||
    (fulfillmentAddress &&
      fulfillmentAddress.addressSiteInformation.hasPassengerElevator)
      ? "Yes"
      : "No"
  }         * Freight or Passenger:  ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.hasFreightElevator
      ? "Frt"
      : "Pass"
  }

Is Certificate Of Insurance required?  ${
    fulfillmentAddress &&
    fulfillmentAddress.addressSiteInformation.isCertificateOfInsuranceRequired
      ? "Yes"
      : "No"
  }

INSTALLATION CONDITIONS:
* Space to be free and clear of construction materials, personal items and existing furniture.
* Installation quote is based on one delivery to job site.
* Electrical connections are not included. Customer must provide their own electrician.
* Product will be installed per final floorplan provided by WOF.

** Any onsite reconfigurations will be subject to a change order fee and an additional trip charge. **

DAY OF SITE CONTACT:
${formatContactName(fulfillmentContact)}
${
  fulfillmentContact
    ? `Phone: ${
        fulfillmentContact.company.phone ??
        fulfillmentContact.person.phone ??
        "N/A"
      }`
    : ""
}
${
  fulfillmentContact
    ? `Email: ${
        fulfillmentContact.company.email ??
        fulfillmentContact.person.email ??
        "N/A"
      }`
    : ""
}

DELIVERY & INSTALLATION ADDRESS:
${formatAddress(fulfillmentAddress)}

** Job Scope **

DRAWING AVAILABLE: Yes/No

Please Describe Job Requirements in Detail as set with Client`.trim();

  dispatch({
    type: "setLineItemDescription",
    lineItemGroupIndex,
    lineItemIndex,
    description: template,
  });

  alert(
    "Delivery & Installation line description has been updated with site information. Please double check it."
  );
}
