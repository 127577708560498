import { Select } from "@blueprintjs/select";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Icon, MenuItem } from "@blueprintjs/core";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { Link } from "react-router-dom";

const GetProjects = gql`
  ${FormatContactNameContactFragment}
  query GetProjects {
    projects(order: { createdAt: DESC }) {
      id
      primaryContact {
        id
        ...FormatContactNameContactFragment
      }
    }
  }
`;

type GetProjectsResult = {
  projects: {
    id: number;
    primaryContact?: FormatContactNameContact;
  }[];
};

type ProjectDetailItemProps = {
  eod: {
    id: number;
    project?: {
      id: number;
      primaryContact?: FormatContactNameContact;
    };
  };
  onSelectProject: (projectId: number) => void;
};

const ProjectSelect = Select.ofType<GetProjectsResult["projects"][0]>();

export function ProjectDetailItem({
  eod,
  onSelectProject,
}: ProjectDetailItemProps) {
  const getProjects = useQuery<GetProjectsResult>(GetProjects, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="flex space-x-2 items-center">
      <p className="m-0 font-medium text-xs w-20">Project #</p>

      <ProjectSelect
        items={getProjects.data?.projects ?? []}
        onItemSelect={(item) => onSelectProject(item!.id)}
        itemRenderer={(item, props) => (
          <MenuItem
            text={`PR-${item!.id}`}
            label={
              item!.primaryContact
                ? formatContactName(item!.primaryContact)
                : undefined
            }
            onClick={props.handleClick}
            active={props.modifiers.active}
          />
        )}
        itemPredicate={(query, item) => {
          const normalizedQuery = query.trim().toLowerCase();
          const itemCode = `PR-${item!.id}`.toLowerCase();
          return itemCode.toString().includes(normalizedQuery);
        }}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
          className: "flex-1",
          targetClassName: "w-full",
        }}
      >
        <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center hover:bg-gray-50 cursor-pointer">
          <span className="flex-1">
            {eod.project ? `PR-${eod.project.id}` : "Not Specified"}
          </span>
          <Icon icon="chevron-down" />
        </div>
      </ProjectSelect>

      {eod.project && (
        <Link to={`/projects/${eod.project.id}/quotes`} target="_blank">
          <Icon icon="circle-arrow-right" />
        </Link>
      )}
    </div>
  );
}
