import { Classes, Dialog, DialogProps } from "@blueprintjs/core";
import { GeneratePurchaseOrdersForm } from "./GeneratePurchaseOrdersForm";

type GeneratePurchaseOrdersDialogProps = DialogProps & {
  salesOrderId?: number;
  onSubmit: () => void;
};

export function GeneratePurchaseOrdersDialog({
  salesOrderId,
  onSubmit,
  ...dialogProps
}: GeneratePurchaseOrdersDialogProps) {
  return (
    <Dialog
      title="Generate Purchase Orders"
      style={{ padding: 0 }}
      {...dialogProps}
    >
      {salesOrderId && (
        <div className={Classes.DIALOG_BODY}>
          <GeneratePurchaseOrdersForm
            salesOrderId={salesOrderId}
            onSubmit={onSubmit}
          />
        </div>
      )}
    </Dialog>
  );
}
