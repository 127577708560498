import React from "react";
import {
  Alignment,
  Button,
  InputGroup,
  Menu,
  MenuItem,
  Navbar,
  Popover,
  Tag,
} from "@blueprintjs/core";
import classNames from "classnames";
import styles from "./PortalNavbar.module.scss";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { UserMenuItem } from "./UserMenuItem";
import { QuickCreateButton } from "../DraftManager/QuickCreateButton";

export function PortalNavbar() {
  const history = useHistory();
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <Navbar className={classNames("bp3-dark", styles.navbar)}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <img src="/logo_white.svg" style={{ height: 30 }} />
        </Navbar.Heading>
        <Navbar.Divider />
        <Button
          className="bp3-minimal"
          icon="home"
          text="Home"
          onClick={() => history.push("/dashboards/sales")}
        />
        <Button
          className="bp3-minimal"
          icon="page-layout"
          text="Projects"
          onClick={() => history.push("/projects")}
        />
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Quote Conversion"
                onClick={() => history.push("/dashboards/quote-conversion")}
              />
              <MenuItem
                text="Purchasing"
                onClick={() => history.push("/dashboards/purchasing")}
              />
              <MenuItem
                text="Receiving"
                onClick={() => history.push("/dashboards/receiving")}
              />
              <MenuItem
                text="Prep"
                onClick={() => history.push("/dashboards/prep")}
              />
              <MenuItem
                text="Scheduling"
                onClick={() => history.push("/dashboards/scheduling")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="dashboard"
            rightIcon="chevron-down"
            text="Dashboards"
          />
        </Popover>
        <Navbar.Divider />
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="People"
                onClick={() => history.push("/contacts/people")}
              />
              <MenuItem
                text="Companies"
                onClick={() => history.push("/contacts/companies")}
              />
              <MenuItem
                text="Vendors"
                onClick={() => history.push("/contacts/vendors")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="people"
            rightIcon="chevron-down"
            text="Contacts"
          />
        </Popover>
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Quotes"
                onClick={() => history.push("/sales/quotes")}
              />
              <MenuItem
                text="Sales Orders"
                onClick={() => history.push("/sales/sales-orders")}
              />
              <MenuItem
                text="Invoices"
                onClick={() => history.push("/sales/invoices")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="dollar"
            rightIcon="chevron-down"
            text="Sales"
          />
        </Popover>
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Purchase Orders"
                onClick={() => history.push("/purchasing/purchase-orders")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="shopping-cart"
            rightIcon="chevron-down"
            text="Purchasing"
          />
        </Popover>
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Fulfillments"
                onClick={() => history.push("/fulfillment/fulfillments")}
              />
              <MenuItem
                text="EODs"
                onClick={() => history.push("/fulfillment/eods")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="truck"
            rightIcon="chevron-down"
            text="Fulfillment"
          />
        </Popover>
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Browse Inventory"
                onClick={() => history.push("/inventory/browse")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="box"
            rightIcon="chevron-down"
            text="Inventory"
          />
        </Popover>
        <Popover
          minimal
          content={
            <Menu>
              <MenuItem
                text="Catalogs"
                onClick={() => history.push("/admin/catalogs")}
              />
              <MenuItem
                text="Vendors"
                onClick={() => history.push("/admin/vendors")}
              />
              <MenuItem
                text="Accounts"
                onClick={() => history.push("/admin/accounts")}
              />
            </Menu>
          }
        >
          <Button
            className="bp3-minimal"
            icon="lock"
            rightIcon="chevron-down"
            text="Admin"
          />
        </Popover>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <div className="flex items-center space-x-4">
          {/*<InputGroup*/}
          {/*  leftIcon="search"*/}
          {/*  placeholder="Search everything..."*/}
          {/*  rightElement={<Tag minimal>Cmd+K</Tag>}*/}
          {/*/>*/}

          <QuickCreateButton />

          {isAuthenticated && <UserMenuItem />}
        </div>
      </Navbar.Group>
    </Navbar>
  );
}
