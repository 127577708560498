import { FulfillmentMethodType } from "../types/ApiTypes";

export function formatFulfillmentMethodType(type: FulfillmentMethodType) {
  switch (type) {
    case FulfillmentMethodType.InternalCrew:
      return "Internal Crew";
    case FulfillmentMethodType.ExternalCrew:
      return "External Crew";
    case FulfillmentMethodType.FreightShipment:
      return "Freight Shipment";
    case FulfillmentMethodType.WillCall:
      return "Will Call/Pickup";
    default:
      return "Unknown";
  }
}
