import React, { useCallback, useState } from "react";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import {
  AccountInputGroup,
  AccountInputGroupAccount,
} from "../../../../components/shared/AccountInputGroup";
import { gql, useMutation } from "@apollo/client";
import { Project, UpdateProjectPayloadInput } from "../../../../types/ApiTypes";
import { DefaultToaster } from "../../../../components/shared/DefaultToaster/DefaultToaster";

const UpdateProject = gql`
  mutation UpdateProject($input: UpdateProjectPayloadInput) {
    updateProject(payload: $input) {
      id
    }
  }
`;

type TeamProjectContactsProps = {
  project: {
    id: number;
    salesRepAccount?: {
      id: number;
      name: string;
      email: string;
    };
    designerAccount?: {
      id: number;
      name: string;
      email: string;
    };
    customerServiceRepAccount?: {
      id: number;
      name: string;
      email: string;
    };
  };
};

export function TeamProjectContacts({ project }: TeamProjectContactsProps) {
  const [salesRepAccount, setSalesRepAccount] = useState<
    AccountInputGroupAccount | undefined
  >(project.salesRepAccount);

  const [designerAccount, setDesignerAccount] = useState<
    AccountInputGroupAccount | undefined
  >(project.designerAccount);

  const [customerServiceRepAccount, setCustomerServiceRepAccount] = useState<
    AccountInputGroupAccount | undefined
  >(project.customerServiceRepAccount);

  const [updateProject] = useMutation<
    { updateProject: Project },
    { input: UpdateProjectPayloadInput }
  >(UpdateProject, {
    refetchQueries: ["GetProjectById"],
  });

  const saveChanges = useCallback(async () => {
    await updateProject({
      variables: {
        input: {
          projectId: project.id,
          salesRepAccountId: salesRepAccount?.id ?? -1,
          designerAccountId: designerAccount?.id ?? -1,
          customerServiceRepAccountId: customerServiceRepAccount?.id ?? -1,
        },
      },
    });

    DefaultToaster.show({
      icon: "tick-circle",
      intent: "success",
      message: "Project contacts saved!",
    });
  }, [project, salesRepAccount, designerAccount, customerServiceRepAccount]);

  return (
    <div className="p-10 overflow-y-auto">
      <div className="md:w-1/2">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Team Contacts
              </h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <FormGroup label="Sales Rep">
                  <AccountInputGroup
                    value={salesRepAccount}
                    onChange={setSalesRepAccount}
                  />
                </FormGroup>
                <FormGroup label="Designer">
                  <AccountInputGroup
                    value={designerAccount}
                    onChange={setDesignerAccount}
                  />
                </FormGroup>
                <FormGroup label="Customer Service Rep">
                  <AccountInputGroup
                    value={customerServiceRepAccount}
                    onChange={setCustomerServiceRepAccount}
                  />
                </FormGroup>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <Button
                  intent={Intent.PRIMARY}
                  large
                  text="Save"
                  onClick={saveChanges}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
