import React, { useState } from "react";
import {
  Classes,
  Dialog,
  InputGroup,
  Menu,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import {
  QuickSettingItem,
  QuickSettingsAction,
} from "./QuickSettings/QuickSettingItem";

export type AccountInputGroupAccount = {
  id: number;
  name: string;
  email: string;
};

type GetAccountsResult = {
  accounts: AccountInputGroupAccount[];
};

const GetAccounts = gql`
  query GetAccounts {
    accounts(order: { name: ASC }) {
      id
      name
      email
    }
  }
`;

type AccountInputGroupProps = {
  value?: AccountInputGroupAccount;
  onChange: (v: AccountInputGroupAccount | undefined) => void;
};

function filterAccount(query: string, account: AccountInputGroupAccount) {
  const normalizedQuery = query.toLowerCase().trim();

  if (normalizedQuery.length == 0)
    return true;

  if (account.name == null || account.email == null)
    return false;

  const normalizedAccountName = account.name.toLowerCase().trim();
  const normalizedAccountEmail = account.email.toLowerCase().trim();

  return (
    normalizedAccountName.includes(normalizedQuery) ||
    normalizedAccountEmail.includes(normalizedQuery)
  );
}

export function AccountInputGroup({ value, onChange }: AccountInputGroupProps) {
  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [query, setQuery] = useState("");

  const getAccounts = useQuery<GetAccountsResult>(GetAccounts, {
    fetchPolicy: "cache-and-network",
    skip: !isChangeOpen,
  });

  return (
    <>
      <Dialog
        title="Choose an Account"
        isOpen={isChangeOpen}
        onClose={() => setIsChangeOpen(false)}
        lazy
        usePortal
      >
        <div className={Classes.DIALOG_BODY}>
          {getAccounts.loading ? (
            <Spinner />
          ) : (
            <div className="rounded border divide-y bg-white">
              <div className="p-2">
                <InputGroup
                  type="search"
                  placeholder="Search by name or email..."
                  leftIcon="search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  autoFocus
                  fill
                />
              </div>
              <Menu className="p-2 divide-y">
                {getAccounts.data?.accounts
                  .filter((a) => filterAccount(query, a))
                  .map((account) => (
                    <MenuItem
                      key={account.id}
                      text={`${account.name} (${account.email})`}
                      onClick={() => {
                        onChange(account);
                        setIsChangeOpen(false);
                      }}
                    />
                  ))}
              </Menu>
            </div>
          )}
        </div>
      </Dialog>

      <QuickSettingItem
        actions={
          <>
            <QuickSettingsAction
              text="Change"
              onClick={() => setIsChangeOpen(true)}
            />
            {value && (
              <QuickSettingsAction
                text="Remove"
                onClick={() => onChange(undefined)}
              />
            )}
          </>
        }
      >
        {!value && (
          <div>
            <p className="m-0 text-gray-400">No Account Selected</p>
          </div>
        )}

        {value && (
          <div>
            <p className="m-0 font-bold">{value.name}</p>
            <p className="m-0">{value.email}</p>
          </div>
        )}
      </QuickSettingItem>
    </>
  );
}
