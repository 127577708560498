import React, { PropsWithChildren } from "react";

type QuickSettingsActionProps = {
  text: string;
  onClick: () => void;
};

export function QuickSettingsAction({
  text,
  onClick,
}: QuickSettingsActionProps) {
  return (
    <a href="javascript:void(0)" onClick={onClick}>
      {text}
    </a>
  );
}

export function QuickSettingItem({
  children,
  actions,
}: PropsWithChildren<{
  actions?: React.ReactNode;
}>) {
  return (
    <div
      className="bg-gray-100 rounded p-2 space-y-2"
      style={{ minWidth: 200 }}
    >
      {children}
      {actions && <div className="space-x-2">{actions}</div>}
    </div>
  );
}
