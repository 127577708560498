import { gql, useQuery } from "@apollo/client";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { FulfillmentMethodType } from "../../types/ApiTypes";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { formatAccountName } from "../../helpers/AccountHelper";
import { formatFulfillmentMethodType } from "../../helpers/FulfillmentHelper";

type GetSchedulingReportResult = {
  salesOrders: {
    id: number;
    code: string;
    requestedFulfillmentDate?: string;
    salesOrderLineItemGroups: {
      id: number;
      salesOrderLineItems: {
        id: number;
        inventoryItems: {
          id: number;
        }[];
      }[];
    }[];
    project: {
      id: number;
      salesRepAccount?: {
        id: number;
        name: string;
      };
      designerAccount?: {
        id: number;
        name: string;
      };
      primaryContact: FormatContactNameContact;
      fulfillmentContact: FormatContactNameContact;
    };
    fulfillments: {
      id: number;
      deliveryStartsAt?: string;
      estimatedHours?: number;
      fulfillmentMethodType?: FulfillmentMethodType;
      fulfillmentMethodNotes?: string;
    }[];
    purchaseOrders: {
      id: number;
      purchaseOrderShipments: {
        id: number;
        expectedArrivalDate?: string;
        actualArrivalDate?: string;
      }[];
    }[];
  }[];
};

const GetSchedulingReport = gql`
  ${FormatContactNameContactFragment}

  query GetSchedulingReport {
    salesOrders {
      id
      code
      requestedFulfillmentDate

      project {
        id
        salesRepAccount {
          id
          name
        }
        designerAccount {
          id
          name
        }
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
        fulfillmentContact {
          id
          ...FormatContactNameContactFragment
        }
      }
      fulfillments {
        id
        deliveryStartsAt
        estimatedHours
        fulfillmentMethodType
        fulfillmentMethodNotes
      }
      purchaseOrders {
        id
        purchaseOrderShipments {
          id
          expectedArrivalDate
          actualArrivalDate
        }
      }
      salesOrderLineItemGroups {
        id
        salesOrderLineItems {
          id
          inventoryItems {
            id
          }
        }
      }
    }
  }
`;

export default function SchedulingDashboard() {
  const getSchedulingReport = useQuery<GetSchedulingReportResult>(
    GetSchedulingReport,
    { fetchPolicy: "cache-and-network" }
  );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const salesOrders = (getSchedulingReport.data?.salesOrders ?? []).map(
    (so) => ({
      ...so,
      salesRepName: formatAccountName(so.project.salesRepAccount),
      designerName: formatAccountName(so.project.designerAccount),
      primaryContactName: formatContactName(so.project.primaryContact),
      fulfillmentContactName: formatContactName(so.project.fulfillmentContact),
      requestedFulfillmentDate: so.requestedFulfillmentDate
        ? new Date(so.requestedFulfillmentDate)
        : undefined,
      totalEstimatedHours: so.fulfillments.reduce(
        (a, b) => a + (b.estimatedHours ?? 0),
        0
      ),
      fulfillmentMethod: so.fulfillments
        .map((f) =>
          f.fulfillmentMethodType
            ? [
                formatFulfillmentMethodType(f.fulfillmentMethodType),
                f.fulfillmentMethodNotes,
              ]
                .filter(Boolean)
                .join(" - ")
            : undefined
        )
        .filter((f) => !!f)
        .join(", "),
    })
  );

  return (
    <>
      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Scheduling"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getSchedulingReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(salesOrders, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="code"
                title="SO #"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Schedule Status"
                width={200}
                cell={(props) => <td>Scheduled</td>}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="salesRepName"
                title="Sales Rep"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="designerName"
                title="Designer"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="primaryContactName"
                title="Primary Contact"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="fulfillmentContactName"
                title="Fulfillment Contact"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Items Received"
                cell={(props) => <td>10/10</td>}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="requestedFulfillmentDate"
                title="Client Requested Fulfillment Date"
                filter="date"
                format="{0:d}"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="scheduledFulfillmentDate"
                title="Scheduled Fulfillment Date"
                filter="date"
                format="{0:d}"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="totalEstimatedHours"
                title="Estimated Fulfillment Time Required"
                filter="numeric"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="fulfillmentMethod"
                title="Fulfillment Method"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Projected Date of Last Arrival"
                cell={(props) => <td>-</td>}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
