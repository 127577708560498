import React, { PropsWithChildren, SyntheticEvent } from "react";
import { Button } from "@blueprintjs/core";

export type DrawerTitleToolbarProps = PropsWithChildren<{
  title: string;
  onClose?: React.EventHandler<SyntheticEvent<HTMLElement, Event>>;
}>;

export function DrawerTitleToolbar({
  title,
  children,
  onClose,
}: DrawerTitleToolbarProps) {
  return (
    <div className="bg-white p-2 border-b flex items-center">
      <h4 className="text-lg m-0 font-semibold text-gray-900 flex-1">
        {title}
      </h4>

      {children}

      <Button icon="cross" className="ml-4" minimal onClick={onClose} />
    </div>
  );
}
