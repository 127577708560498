import React from "react";
import classNames from "classnames";

export type PortalContainerProps = {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
};

export function PortalContainer({ children, className }: PortalContainerProps) {
  return <main className={classNames(className, "m-2")}>{children}</main>;
}
