import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { useState } from "react";
import axios from "axios";
import { useApiError } from "../../../../hooks/useApiError";
import { Attachment } from "../../../../types/ApiTypes";
import { AttachmentsInput } from "../../../shared/AttachmentsInput/AttachmentsInput";

export type PurchaseOrderSubmissionResultDialogProps = DialogProps & {
  projectId?: number;
  purchaseOrderSubmissionId?: number;
  onSubmit: () => void;
};

export function PurchaseOrderSubmissionResultDialog({
  projectId,
  purchaseOrderSubmissionId,
  isOpen,
  onSubmit,
  onClose,
}: PurchaseOrderSubmissionResultDialogProps) {
  const [submissionResult, setSubmissionResult] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const handleUpdateError = useApiError("update purchase order submission");

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .put(`/purchaseordersubmissions/${purchaseOrderSubmissionId}/result`, {
        result: parseInt(submissionResult),
        confirmationAttachmentId: attachments[0]?.id,
      })
      .then(() => onSubmit())
      .catch((err) => handleUpdateError(err));

    onSubmit();
  };

  return (
    <Dialog
      title="Record Submission Result"
      isOpen={isOpen}
      isCloseButtonShown={false}
      lazy
      usePortal
    >
      <div className={Classes.DIALOG_BODY}>
        <FormGroup label="Submission Result">
          <HTMLSelect
            value={submissionResult}
            onChange={(e) => setSubmissionResult(e.target.value)}
            fill
          >
            <option value="" disabled>
              Select a submission result...
            </option>
            <option value="1">Error When Sending</option>
            <option value="3">Changes Needed</option>
            <option value="2">Confirmed</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Attachment">
          <AttachmentsInput
            single
            projectId={projectId}
            attachments={attachments}
            onAddAttachment={(a) => setAttachments([...attachments, a])}
            onRemoveAttachment={(a) =>
              setAttachments(attachments.filter((x) => x.id !== a.id))
            }
          />
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} type="button" />
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            type="submit"
            onClick={handleSubmit}
            disabled={!submissionResult}
          />
        </div>
      </div>
    </Dialog>
  );
}
