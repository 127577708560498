import { useHistory, useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import React, { useCallback, useEffect, useState } from "react";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import {
  FulfillmentMethodType,
  ProductConfiguration,
  Project,
} from "../../../../../types/ApiTypes";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import {
  Button,
  FormGroup,
  InputGroup,
  NumericInput,
  Spinner,
  Tag,
  TextArea,
} from "@blueprintjs/core";
import { ErrorDialog } from "../../../../../components/shared/ErrorDialog/ErrorDialog";
import { GetFullConfigurationCodeFragment } from "../../../../../helpers/CatalogHelper";
import { FulfillmentLineItemsTable } from "./FulfillmentLineItemsTable";
import { AddFulfillmentLineItemsDrawer } from "../../../../../components/shared/AddFulfillmentLineItemDrawer/AddFulfillmentLineItemsDrawer";
import {
  DateInput,
  DateRange,
  DateRangeInput,
  TimePrecision,
} from "@blueprintjs/datetime";
import { formatDate, formatDateTime } from "../../../../../helpers/DateHelper";
import dayjs from "dayjs";
import { QuickSettings } from "../../../../../components/shared/QuickSettings/QuickSettings";
import { QuickSettingItem } from "../../../../../components/shared/QuickSettings/QuickSettingItem";
import FulfillmentMethodQuickSettingItem from "./FulfillmentMethodQuickSettingItem";
import { DefaultToaster } from "../../../../../components/shared/DefaultToaster/DefaultToaster";
import { ProjectSubnavbar } from "../../../../../components/projects/[projectId]/ProjectSubnavbar";
import { formatAccountName } from "../../../../../helpers/AccountHelper";

const CompleteFulfillment = gql`
  mutation completeFulfillment($fulfillmentId: Int!) {
    completeFulfillment(fulfillmentId: $fulfillmentId) {
      id
    }
  }
`;

type CompleteFulfillmentResult = {
  completeFulfillment: {
    id: string;
  };
};

type CompleteFulfillmentParams = {
  fulfillmentId: number;
};

const CompleteFulfillmentLineItem = gql`
  mutation completeFulfillmentLineItem($fulfillmentLineItemId: Int!) {
    completeFulfillmentLineItem(fulfillmentLineItemId: $fulfillmentLineItemId) {
      id
    }
  }
`;

type CompleteFulfillmentLineItemResult = {
  completeFulfillmentLineItem: {
    id: number;
  };
};

type CompleteFulfillmentLineItemParams = {
  fulfillmentLineItemId: number;
};

const CreateEOD = gql`
  mutation CreateEOD($payload: CreateEODPayloadInput!) {
    createEOD(payload: $payload) {
      id
    }
  }
`;

type CreateEODResult = {
  createEOD: {
    id: number;
  };
};

type CreateEODParams = {
  payload: {
    projectId: number;
    title?: string;
    description?: string;
  };
};

const AttachFulfillmentToEOD = gql`
  mutation AttachFulfillmentToEOD(
    $eodId: Int!
    $fulfillmentId: Int!
    $lineItemIds: [Int!]!
  ) {
    attachFulfillmentToEOD(
      eodId: $eodId
      fulfillmentId: $fulfillmentId
      lineItemIds: $lineItemIds
    ) {
      id
    }
  }
`;

type AttachFulfillmentToEODResult = {
  attachFulfillmentToEOD: {
    id: number;
  };
};

type AttachFulfillmentToEODParams = {
  eodId: number;
  fulfillmentId: number;
  lineItemIds: number[];
};

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

type GetFulfillmentByIdResult = {
  fulfillments: {
    id: number;
    requestedAt: string;
    estimatedHours: number;
    installStartsAt: string;
    installEndsAt: string;
    deliveryStartsAt: string;
    deliveryEndsAt: string;
    fulfillmentMethodType?: FulfillmentMethodType;
    fulfillmentMethodNotes?: string;
    notes: string;
    completedAt?: string;
    completedBy?: {
      id: number;
      name: string;
    };
    lineItems: {
      id: number;
      quantity: number;
      completedAt?: string;
      completedBy?: {
        id: number;
        name: string;
      };
      salesOrderLineItem?: {
        id: number;
        configuration: ProductConfiguration;
        salesOrderLineItemGroup: {
          id: string;
          salesOrder: {
            id: string;
            code: string;
          };
        };
        inventoryItems: {
          id: number;
          prepTicketLineItem: {
            id: number;
            isPrepped: boolean;
          };
          location?: {
            id: number;
            name: string;
          };
          purchaseOrderShipmentLineItem: {
            id: number;
            purchaseOrderLineItem: {
              id: number;
              purchaseOrder: {
                id: number;
                code: string;
              };
            };
          };
        }[];
      };
    }[];
  }[];
};

const GetFulfillmentById = gql`
  ${GetFullConfigurationCodeFragment}

  query GetFulfillmentById($id: Int!) {
    fulfillments(where: { id: { eq: $id } }) {
      id
      requestedAt
      estimatedHours
      installStartsAt
      installEndsAt
      deliveryStartsAt
      deliveryEndsAt
      fulfillmentMethodType
      fulfillmentMethodNotes
      notes
      completedAt
      completedBy {
        id
        name
      }
      lineItems {
        id
        quantity
        completedAt
        completedBy {
          id
          name
        }
        salesOrderLineItem {
          id
          configuration {
            id
            ...GetFullConfigurationCodeFragment
          }
          salesOrderLineItemGroup {
            id
            salesOrder {
              id
              code
            }
          }
          inventoryItems {
            id
            prepTicketLineItem {
              id
              isPrepped
            }
            location {
              id
              name
            }
            purchaseOrderShipmentLineItem {
              id
              purchaseOrderLineItem {
                id
                purchaseOrder {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`;

const AddItemsToFulfillment = gql`
  mutation AddItemsToFulfillment($payload: AddItemsToFulfillmentPayloadInput!) {
    addItemsToFulfillment(payload: $payload) {
      id
      __typename
    }
  }
`;

const UpdateFulfillment = gql`
  mutation UpdateFulfillment($payload: UpdateFulfillmentPayloadInput!) {
    updateFulfillment(payload: $payload) {
      id
    }
  }
`;

const DeleteFulfillmentLineItem = gql`
  mutation DeleteFulfillmentLineItem($id: Int!) {
    deleteFulfillmentLineItem(fulfillmentLineItemId: $id) {
      id
    }
  }
`;

export default function FulfillmentDetails() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string; fulfillmentId: string }>();
  const projectId = parseInt(match.params.projectId);
  const fulfillmentId = parseInt(match.params.fulfillmentId);

  const [addLineItemDrawerOpen, setAddLineItemDrawerOpen] = useState(false);
  const [addInventoryItemsError, setAddInventoryItemsError] = useState<Error>();

  const [requestedAtDate, setRequestedAtDate] = useState<Date | null>(null);
  const [installationDateRange, setInstallationDateRange] = useState<DateRange>(
    [null, null]
  );
  const [deliveryDateRange, setDeliveryDateRange] = useState<DateRange>([
    null,
    null,
  ]);
  const [estimatedHours, setEstimatedHours] = useState<string>("");
  const [notes, setNotes] = useState("");

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GetProjectById, {
    variables: {
      projectId,
    },
  });

  const [deleteFulfillmentLineItem] = useMutation<
    { deleteFulfillmentLineItem: { id: number } },
    { id: number }
  >(DeleteFulfillmentLineItem, {
    refetchQueries: ["GetFulfillmentById"],
  });

  const [addItemsToFulfillment] = useMutation<
    { addItemsToFulfillment: number[] },
    {
      payload: {
        fulfillmentId: number;
        salesOrderLineItems: number[];
        quantities: number[];
      };
    }
  >(AddItemsToFulfillment, {
    refetchQueries: ["GetFulfillmentById"],
  });

  const [updateFulfillment] = useMutation<
    { updateFulfillment: { id: number } },
    {
      payload: {
        fulfillmentId: number;
        requestedAt?: string;
        estimatedHours?: number;
        installStartsAt?: string;
        installEndsAt?: string;
        deliveryStartsAt?: string;
        deliveryEndsAt?: string;
        fulfillmentMethodType?: FulfillmentMethodType;
        fulfillmentMethodNotes?: string;
        notes?: string;
      };
    }
  >(UpdateFulfillment, {
    refetchQueries: ["GetFulfillmentById"],
  });

  const [createEOD] = useMutation<CreateEODResult, CreateEODParams>(CreateEOD);

  const [attachFulfillmentToEOD] = useMutation<
    AttachFulfillmentToEODResult,
    AttachFulfillmentToEODParams
  >(AttachFulfillmentToEOD);

  const [completeFulfillment] = useMutation<
    CompleteFulfillmentResult,
    CompleteFulfillmentParams
  >(CompleteFulfillment, {
    refetchQueries: [GetFulfillmentById],
  });

  const [completeFulfillmentLineItem] = useMutation<
    CompleteFulfillmentLineItemResult,
    CompleteFulfillmentLineItemParams
  >(CompleteFulfillmentLineItem); // We don't have the refetch query here because we call this mutation multiple times then manually refetch

  const getFulfillmentById = useQuery<GetFulfillmentByIdResult, { id: number }>(
    GetFulfillmentById,
    { variables: { id: fulfillmentId } }
  );

  useEffect(() => {
    if (getFulfillmentById.loading) return;
    const fulfillment = getFulfillmentById.data?.fulfillments[0]!;

    setRequestedAtDate(
      fulfillment.requestedAt ? new Date(fulfillment.requestedAt) : null
    );
    setInstallationDateRange([
      fulfillment.installStartsAt
        ? new Date(fulfillment.installStartsAt)
        : null,
      fulfillment.installEndsAt ? new Date(fulfillment.installEndsAt) : null,
    ]);
    setDeliveryDateRange([
      fulfillment.deliveryStartsAt
        ? new Date(fulfillment.deliveryStartsAt)
        : null,
      fulfillment.deliveryEndsAt ? new Date(fulfillment.deliveryEndsAt) : null,
    ]);
    setEstimatedHours(fulfillment.estimatedHours?.toString() ?? "");
    setNotes(fulfillment?.notes ?? "");
  }, [getFulfillmentById.loading, getFulfillmentById.data]);

  async function addInventoryItems(lineItemIds: {
    [lineItemId: number]: number;
  }) {
    try {
      const salesOrderLineItemIds = Object.entries(lineItemIds)
        .filter(([, quantity]) => quantity > 0)
        .map(([id, quantity]) => [parseInt(id), quantity]);

      debugger;

      await addItemsToFulfillment({
        variables: {
          payload: {
            fulfillmentId,
            salesOrderLineItems: salesOrderLineItemIds.map(([id]) => id),
            quantities: salesOrderLineItemIds.map(([, quantity]) => quantity),
          },
        },
      });

      setAddLineItemDrawerOpen(false);
    } catch (err) {
      setAddInventoryItemsError(err);
    }
  }

  const createEODFromItems = useCallback(
    async (lineItemIds: number[]) => {
      const createEODResult = await createEOD({
        variables: {
          payload: {
            projectId,
            title: `Issue with Fulfillment F-${fulfillmentId}`,
          },
        },
      });

      const createdEODId = createEODResult.data!.createEOD.id;

      await attachFulfillmentToEOD({
        variables: {
          eodId: createdEODId,
          fulfillmentId,
          lineItemIds,
        },
      });

      history.push(`/eods/${createdEODId}`);
    },
    [createEOD, attachFulfillmentToEOD]
  );

  const completeLineItems = useCallback(async (lineItemIds: number[]) => {
    await Promise.all(
      lineItemIds.map((id) =>
        completeFulfillmentLineItem({
          variables: {
            fulfillmentLineItemId: id,
          },
        })
      )
    );

    await getFulfillmentById.refetch();

    DefaultToaster.show({
      message: `${lineItemIds.length} line item(s) marked as completed!`,
      intent: "success",
      icon: "tick-circle",
    });
  }, []);

  const loading = getProjectById.loading || getFulfillmentById.loading;
  const error = getProjectById.error || getFulfillmentById.error;
  const fulfillment = getFulfillmentById.data?.fulfillments[0];

  return (
    <>
      <ErrorDialog
        isOpen={!!addInventoryItemsError}
        error={addInventoryItemsError}
        onClose={() => setAddInventoryItemsError(undefined)}
      />

      <AddFulfillmentLineItemsDrawer
        isOpen={addLineItemDrawerOpen}
        fulfillmentId={fulfillmentId}
        projectId={projectId}
        onAdd={addInventoryItems}
        onClose={() => setAddLineItemDrawerOpen(false)}
      />

      <Helmet>
        <title>{`Fulfillment #${fulfillmentId} - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Fulfillments",
            href: `/projects/${projectId}/fulfillments`,
          },
          {
            text: `Fulfillment #${fulfillmentId}`,
            href: `/projects/${projectId}/fulfillments/${fulfillmentId}`,
          },
        ]}
      />

      <ProjectSubnavbar
        title={`Fulfillment F-${fulfillmentId}`}
        actions={
          <>
            <Button
              icon="print"
              minimal
              onClick={() =>
                window.open(
                  `/projects/${projectId}/fulfillments/${fulfillmentId}/printable`
                )
              }
            />

            {fulfillment && !fulfillment.completedAt && (
              <Button
                text="Mark Completed"
                onClick={async () =>
                  completeFulfillment({
                    variables: {
                      fulfillmentId,
                    },
                  })
                }
              />
            )}
            {fulfillment && fulfillment.completedAt && (
              <Tag intent="success" minimal>
                Completed on {formatDate(fulfillment.completedAt)} by{" "}
                {formatAccountName(fulfillment.completedBy!)}
              </Tag>
            )}
          </>
        }
      />

      {!loading && !error && (
        <QuickSettings>
          <FormGroup label="Requested Date">
            <QuickSettingItem>
              <DateInput
                fill
                placeholder="No Date Specified"
                timePrecision={TimePrecision.MINUTE}
                timePickerProps={{ useAmPm: true }}
                formatDate={formatDateTime}
                parseDate={(v) => dayjs(v).toDate()}
                value={requestedAtDate}
                onChange={async (e) => {
                  setRequestedAtDate(e);

                  await updateFulfillment({
                    variables: {
                      payload: {
                        fulfillmentId,
                        requestedAt: e?.toISOString() ?? undefined,
                      },
                    },
                  });
                }}
              />
            </QuickSettingItem>
          </FormGroup>
          <FormGroup label="Install Dates">
            <QuickSettingItem>
              <DateRangeInput
                timePrecision={TimePrecision.MINUTE}
                timePickerProps={{ useAmPm: true }}
                formatDate={formatDateTime}
                parseDate={(v) => dayjs(v).toDate()}
                value={installationDateRange}
                onChange={async (e) => {
                  setInstallationDateRange(e);

                  await updateFulfillment({
                    variables: {
                      payload: {
                        fulfillmentId,
                        installStartsAt: e[0]?.toISOString() ?? undefined,
                        installEndsAt: e[1]?.toISOString() ?? undefined,
                      },
                    },
                  });
                }}
                allowSingleDayRange
              />
            </QuickSettingItem>
          </FormGroup>
          <FormGroup label="Delivery Dates">
            <QuickSettingItem>
              <DateRangeInput
                timePrecision={TimePrecision.MINUTE}
                timePickerProps={{ useAmPm: true }}
                formatDate={formatDateTime}
                parseDate={(v) => dayjs(v).toDate()}
                value={deliveryDateRange}
                onChange={async (e) => {
                  setDeliveryDateRange(e);

                  await updateFulfillment({
                    variables: {
                      payload: {
                        fulfillmentId,
                        deliveryStartsAt: e[0]?.toISOString() ?? undefined,
                        deliveryEndsAt: e[1]?.toISOString() ?? undefined,
                      },
                    },
                  });
                }}
                allowSingleDayRange
              />
            </QuickSettingItem>
          </FormGroup>
          <FormGroup label="Assigned Team">
            <QuickSettingItem>
              <InputGroup readOnly value="???" />
            </QuickSettingItem>
          </FormGroup>
          <FormGroup label="Fulfillment Method">
            <FulfillmentMethodQuickSettingItem
              value={{
                fulfillmentMethodType: fulfillment!.fulfillmentMethodType,
                fulfillmentMethodNotes: fulfillment!.fulfillmentMethodNotes,
              }}
              onChange={async (e) => {
                await updateFulfillment({
                  variables: {
                    payload: {
                      fulfillmentId,
                      fulfillmentMethodType: e?.fulfillmentMethodType,
                      fulfillmentMethodNotes: e?.fulfillmentMethodNotes,
                    },
                  },
                });
              }}
            />
          </FormGroup>
          <FormGroup label="Estimated Hours">
            <QuickSettingItem>
              <NumericInput
                value={estimatedHours}
                onValueChange={(e, v) => setEstimatedHours(v)}
                onBlur={async () => {
                  if (isNaN(parseFloat(estimatedHours))) return;

                  await updateFulfillment({
                    variables: {
                      payload: {
                        fulfillmentId,
                        estimatedHours: parseFloat(estimatedHours),
                      },
                    },
                  });
                }}
                onButtonClick={async (e, v) => {
                  if (isNaN(parseFloat(v))) return;

                  await updateFulfillment({
                    variables: {
                      payload: {
                        fulfillmentId,
                        estimatedHours: parseFloat(v),
                      },
                    },
                  });
                }}
                intent={isNaN(parseFloat(estimatedHours)) ? "danger" : "none"}
              />
            </QuickSettingItem>
          </FormGroup>
        </QuickSettings>
      )}

      <PortalContainer>
        {loading && <Spinner />}

        {error && <ErrorDialog isOpen error={error} />}

        {!loading && !error && (
          <div className="space-y-4">
            <h4 className="font-medium text-base">Incomplete Items</h4>

            <FulfillmentLineItemsTable
              lineItems={
                fulfillment!.lineItems
                  .filter((li) => !li.completedAt)
                  .filter((li) => !!li.salesOrderLineItem) ?? []
              }
              projectId={projectId}
              onRemove={async (e) => {
                await deleteFulfillmentLineItem({
                  variables: {
                    id: e.id,
                  },
                });
              }}
              onAddItem={() => setAddLineItemDrawerOpen(true)}
              onCreateEOD={async (lineItems) =>
                await createEODFromItems(lineItems.map((li) => li.id))
              }
              onComplete={async (lineItems) =>
                await completeLineItems(lineItems.map((li) => li.id))
              }
            />

            <h4 className="font-medium text-base">Complete Items</h4>

            <FulfillmentLineItemsTable
              lineItems={
                fulfillment!.lineItems
                  .filter((li) => !!li.completedAt)
                  .filter((li) => !!li.salesOrderLineItem) ?? []
              }
              projectId={projectId}
              onCreateEOD={async (lineItems) =>
                await createEODFromItems(lineItems.map((li) => li.id))
              }
              readOnly
            />

            <FormGroup label="Notes">
              <TextArea
                fill
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormGroup>
            <Button
              text="Save Notes"
              onClick={async () => {
                await updateFulfillment({
                  variables: {
                    payload: {
                      fulfillmentId,
                      notes,
                    },
                  },
                });
                DefaultToaster.show({
                  message: "Fulfillment notes saved!",
                  intent: "success",
                  icon: "tick-circle",
                });
              }}
            />
          </div>
        )}
      </PortalContainer>
    </>
  );
}
