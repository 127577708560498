import React, { useEffect, useState } from "react";
import { Attachment } from "../../../../types/ApiTypes";
import { EditableText } from "@blueprintjs/core";

export type NameCellProps = {
  attachment: Attachment;
  onUpdateAttachment: (attachment: Attachment) => void;
};

export function NameCell({ attachment, onUpdateAttachment }: NameCellProps) {
  const [value, setValue] = useState(attachment.name);

  useEffect(() => {
    setValue(attachment.name);
  }, [attachment.name]);

  return (
    <EditableText
      value={value}
      onChange={setValue}
      onConfirm={() => onUpdateAttachment({ ...attachment, name: value })}
    />
  );
}
