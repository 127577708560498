import {
  Grid,
  GridColumn,
  GridDetailRowProps,
} from "@progress/kendo-react-grid";
import { useMutation } from "@apollo/client";
import {
  CreateAccountSpiffCode,
  CreateAccountSpiffCodeParams,
  CreateAccountSpiffCodeResult,
  DeleteAccountSpiffCode,
  DeleteAccountSpiffCodeParams,
  DeleteAccountSpiffCodeResult,
} from "./mutations";
import { GetAccounts, GetAccountsResult } from "./queries";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import { useReportDataState } from "../../../hooks/useReportDataState";
import { process } from "@progress/kendo-data-query";
import { Button } from "@blueprintjs/core";
import { AddSpiffCodeDialog } from "./AddSpiffCodeDialog";
import { useState } from "react";

export function AccountDetail(props: GridDetailRowProps) {
  const [deleteAccountSpiffCode] = useMutation<
    DeleteAccountSpiffCodeResult,
    DeleteAccountSpiffCodeParams
  >(DeleteAccountSpiffCode, {
    refetchQueries: [GetAccounts],
  });

  const [createAccountSpiffCode] = useMutation<
    CreateAccountSpiffCodeResult,
    CreateAccountSpiffCodeParams
  >(CreateAccountSpiffCode, {
    refetchQueries: [GetAccounts],
  });

  async function confirmAndDeleteAccountSpiffCode(id: number) {
    if (window.confirm("Are you sure you want to delete this SPIFF code?")) {
      await deleteAccountSpiffCode({ variables: { id } });

      DefaultToaster.show({
        message: "SPIFF Code deleted!",
        icon: "tick-circle",
        intent: "success",
      });
    }
  }

  async function createSpiffCode(code: string, vendorId: number) {
    await createAccountSpiffCode({
      variables: {
        payload: {
          accountId: props.dataItem.id,
          code,
          vendorId,
        },
      },
    });

    setIsAddSpiffCodeOpen(false);

    DefaultToaster.show({
      message: "SPIFF Code created!",
      icon: "tick-circle",
      intent: "success",
    });
  }

  const dataItem = props.dataItem as GetAccountsResult["accounts"][0];

  const [isAddSpiffCodeOpen, setIsAddSpiffCodeOpen] = useState(false);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "vendor.name", dir: "asc" }],
  });

  const spiffCodes = dataItem.spiffCodes.map((c) => ({
    ...c,
    createdAt: new Date(c.createdAt),
  }));

  return (
    <>
      <AddSpiffCodeDialog
        isOpen={isAddSpiffCodeOpen}
        onSubmit={createSpiffCode}
        onClose={() => setIsAddSpiffCodeOpen(false)}
      />

      <div className="border rounded-sm p-2 space-y-2 bg-white">
        <h1 className="font-medium text-base m-0">{dataItem.name}</h1>

        <h3 className="font-medium">SPIFF Codes</h3>

        <Grid
          data={process(spiffCodes, dataState)}
          {...dataState}
          onDataStateChange={(e) => setDataState(e.dataState)}
          sortable
          reorderable
          resizable
        >
          <GridColumn field="vendor.name" title="Vendor" />
          <GridColumn field="code" title="SPIFF Code" />
          <GridColumn field="createdBy.name" title="Created By" />
          <GridColumn
            field="createdAt"
            title="Created At"
            filter="date"
            format="{0:d}"
          />
          <GridColumn
            title="Actions"
            width={150}
            cell={(props) => (
              <td>
                <Button
                  minimal
                  icon="trash"
                  text="Remove"
                  intent="danger"
                  onClick={() =>
                    confirmAndDeleteAccountSpiffCode(props.dataItem.id)
                  }
                  small
                  fill
                />
              </td>
            )}
          />
        </Grid>

        <div>
          <Button
            icon="add-row-bottom"
            text="Add SPIFF Code"
            onClick={() => setIsAddSpiffCodeOpen(true)}
          />
        </div>
      </div>
    </>
  );
}
