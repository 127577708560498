import { PropsWithChildren } from "react";
import styles from "./Table.module.scss";
import classNames from "classnames";

export type BasicTableProps = PropsWithChildren<{
  header?: boolean;
  footer?: boolean;
  borderless?: boolean;
}>;

export function BasicTable({
  header,
  footer,
  borderless,
  children,
}: BasicTableProps) {
  return (
    <div
      className={classNames(
        styles.tableWrapper,
        { [styles.hasHeader]: header },
        { [styles.hasFooter]: footer },
        { [styles.borderless]: borderless }
      )}
    >
      <table className={styles.table}>{children}</table>
    </div>
  );
}
