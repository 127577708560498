import { OptionGroup, OPTION_OPTION_GROUP_FRAGMENT } from "./OptionGroup";
import { gql } from "@apollo/client";
import { CatalogProduct } from "../../../types/ApiTypes";
import { Dispatch, SetStateAction } from "react";
import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import {
  SIFEntry,
  SIFEntryFeature,
} from "../../../hooks/useDecodeSifLineItems";
import produce from "immer";

export const PRODUCT_OPTIONS_PRODUCT_FRAGMENT = gql`
  ${OPTION_OPTION_GROUP_FRAGMENT}

  fragment ProductOptionsProductFragment on Product {
    optionGroups {
      id
      ...OptionOptionGroupFragment
    }
  }
`;

type ProductOptionsProps = {
  configuration: EditableProductConfiguration;
  sifEntry?: SIFEntry;
  onSifEntryChange?: (newValue: SIFEntry) => void;
  setConfiguration: Dispatch<SetStateAction<EditableProductConfiguration>>;
  catalogProduct: CatalogProduct;
};

export function ProductOptions({
  catalogProduct,
  sifEntry,
  onSifEntryChange,
  configuration,
  setConfiguration,
}: ProductOptionsProps) {
  return (
    <div className="p-2 space-y-2">
      {catalogProduct.optionGroups.map((og, i) => (
        <OptionGroup
          key={og.id}
          optionGroup={og}
          sifEntryFeature={sifEntry?.decodings[0].features.find(
            (f) => f.selections[0]?.optionGroupId === og.id
          )}
          onSifEntryFeatureChange={(v: SIFEntryFeature) => {
            const featureIndex = sifEntry!.decodings[0].features.findIndex(
              (f) => f.selections[0]?.optionGroupId === og.id
            );
            onSifEntryChange!(
              produce(sifEntry, (draft) => {
                draft!.decodings[0].features[featureIndex] = v;
              })!
            );
          }}
          configuration={configuration}
          setConfiguration={setConfiguration}
        />
      ))}
    </div>
  );
}
