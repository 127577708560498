import { State } from "@progress/kendo-data-query";
import { parse } from "querystring";
import { useState } from "react";
import { merge } from "lodash";

export function useReportDataState(defaultDataState: State) {
  const { dataState } = parse(window.location.search.substring(1));
  const initialDataState = dataState
    ? merge(
        defaultDataState,
        JSON.parse(decodeURIComponent(dataState as string)) as State
      )
    : defaultDataState;
  return useState<State>(initialDataState);
}
