import { Address } from "../../../types/ApiTypes";
import { useState } from "react";
import {
  Button,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { State } from "country-state-city";

export type AddAddressFormProps = {
  onCreateAddress: (address: Address) => void;
};

export function AddAddressForm({ onCreateAddress }: AddAddressFormProps) {
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const addAddress = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onCreateAddress({
      street1,
      street2,
      city,
      state,
      postalCode,
    });
  };

  return (
    <form onSubmit={addAddress}>
      <FormGroup label="Street 1">
        <InputGroup
          type="text"
          value={street1}
          onChange={(e) => setStreet1(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup label="Street 2">
        <InputGroup
          type="text"
          value={street2}
          onChange={(e) => setStreet2(e.target.value)}
        />
      </FormGroup>
      <div className="flex">
        <FormGroup label="City" className="pr-2 flex-1">
          <InputGroup
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup label="State" className="pr-2 w-32">
          <HTMLSelect value={state} onChange={(e) => setState(e.target.value)}>
            {State.getStatesOfCountry("US").map((state) => (
              <option value={state.name}>{state.name}</option>
            ))}
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Postal Code" className="w-24">
          <InputGroup
            type="text"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
            required
          />
        </FormGroup>
      </div>
      <Button type="submit" intent={Intent.PRIMARY} text="Create Address" />
    </form>
  );
}
