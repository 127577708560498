import React, { PropsWithChildren } from "react";
import styles from "./Printable.module.scss";

export function PrintableHeaderColumnItem({
  label,
  children,
}: PropsWithChildren<{ label?: string }>) {
  return (
    <div className="flex justify-between text-gray-700 text-xs">
      {label && <div className="text-gray-500">{label}:</div>}
      {label ? <span className="text-right">{children}</span> : children}
    </div>
  );
}

export function PrintableHeaderColumn({
  title,
  children,
}: PropsWithChildren<{ title?: string }>) {
  return (
    <div className="divide-y flex-1">
      <h4 className="mb-1 uppercase font-medium text-gray-500 text-xs">
        {title ? title : <>&nbsp;</>}
      </h4>
      <div className="pt-2 space-y-1.5">{children}</div>
    </div>
  );
}

export type PrintableProps = PropsWithChildren<{
  headerColumns?: React.ReactNode | React.ReactNode[];
  title: string;
}>;

export function Printable({ title, headerColumns, children }: PrintableProps) {
  return (
    <main className="space-y-1">
      <section className="flex justify-between items-center mb-4">
        <img src="/logo.svg" alt="" className="w-40" />
        <h1 className="font-medium uppercase text-2xl text-gray-300 text-right">
          {title}
        </h1>
      </section>
      <section className="flex space-x-4 mb-4 pb-2">
        <PrintableHeaderColumn>
          <PrintableHeaderColumnItem>
            <strong>Warehouse of Fixtures</strong>
          </PrintableHeaderColumnItem>
          <PrintableHeaderColumnItem>2720 Market St.</PrintableHeaderColumnItem>
          <PrintableHeaderColumnItem>
            St. Louis, MO 63103
          </PrintableHeaderColumnItem>
          <PrintableHeaderColumnItem>T: 314.534.5900</PrintableHeaderColumnItem>
          <PrintableHeaderColumnItem>F: 314.266.4344</PrintableHeaderColumnItem>
          <PrintableHeaderColumnItem>
            info@stlwarehouse.com
          </PrintableHeaderColumnItem>
        </PrintableHeaderColumn>
        {headerColumns}
      </section>
      <section>{children}</section>
    </main>
  );
}
