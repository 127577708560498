import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  InventoryItem,
  PurchaseOrder,
  PurchaseOrderShipment,
} from "../../../../types/ApiTypes";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";

const GET_PURCHASE_ORDER_INVENTORY_ITEMS = gql`
  query GetPurchaseOrderInventoryItems($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      purchaseOrderShipments(order: { actualArrivalDate: DESC }) {
        id
        actualArrivalDate
      }
      inventoryItems {
        id
      }
    }
  }
`;

export type PurchaseOrderListReceivingStatusCellParams = {
  projectId: number;
  purchaseOrderId: number;
  purchaseOrderLineItemId?: number;
};

export function PurchaseOrderListReceivingStatusCell({
  projectId,
  purchaseOrderId,
  purchaseOrderLineItemId,
}: PurchaseOrderListReceivingStatusCellParams) {
  const history = useHistory();

  const { data, loading, error } = useQuery<{
    purchaseOrders: PurchaseOrder[];
  }>(GET_PURCHASE_ORDER_INVENTORY_ITEMS, {
    variables: {
      purchaseOrderId: purchaseOrderId,
    },
    fetchPolicy: "network-only",
  });

  const purchaseOrder = data?.purchaseOrders[0];
  const inventoryItems = purchaseOrder?.inventoryItems;

  function getIntent() {
    return inventoryItems!.length > 0 ? Intent.SUCCESS : Intent.NONE;
  }

  function getText() {
    return inventoryItems!.length > 0
      ? `${inventoryItems!.length} Items Received ${getActualArrivalDate()}`
      : "Receive";
  }

  function getActualArrivalDate() {
    return dayjs(
      purchaseOrder!.purchaseOrderShipments[0].actualArrivalDate
    ).format("MM/DD/YYYY");
  }

  return (
    <PurchaseOrderStatusCell
      text={loading ? "" : getText()}
      intent={loading ? Intent.NONE : getIntent()}
      loading={loading}
    >
      {!loading && (
        <div className="p-2">
          <div className="border-b pb-2 mb-2 max-w-sm">
            <h3 className="font-bold mb-2">Receiving</h3>
            {inventoryItems!.length === 0 && (
              <div>
                <p className="m-0">
                  No items on the purchase order have been received yet.
                </p>
              </div>
            )}
            {inventoryItems!.length > 0 && (
              <div>
                <p className="m-0">
                  {inventoryItems!.length} items on the purchase order have been
                  received beginning on {getActualArrivalDate()}.
                </p>
              </div>
            )}
          </div>
          <Button
            fill
            rightIcon="chevron-right"
            text={inventoryItems!.length === 0 ? "Receive" : "Manage Inventory"}
            intent={Intent.PRIMARY}
            onClick={() =>
              history.push(
                `/projects/${projectId}/purchase-orders/${purchaseOrderId}/inventory`
              )
            }
          />
        </div>
      )}
    </PurchaseOrderStatusCell>
  );
}
