import React, { useEffect, useState } from "react";
import {
  Button,
  Classes,
  FormGroup,
  Intent,
  Drawer,
  IDrawerProps,
  Position,
  NumericInput,
  Callout,
  Spinner,
  Popover,
  H5,
} from "@blueprintjs/core";
import {
  ProductConfiguration,
  PurchaseOrderUnshippedLineItem,
} from "../../../../../types/ApiTypes";
import { usePurchaseOrderUnshippedLineItems } from "../../../../../hooks/usePurchaseOrderUnshippedLineItems";
import { FlexTable } from "../../../../shared/Table/FlexTable";
import axios from "axios";
import { DateInput } from "@blueprintjs/datetime";
import { DrawerTitleToolbar } from "../../../../shared/DrawerTitleToolbar/DrawerTitleToolbar";
import {
  getFullConfigurationCode,
  getFullConfigurationDescription,
} from "../../../../../helpers/CatalogHelper";

export type RecordShipmentDrawerProps = IDrawerProps & {
  purchaseOrderId?: number;
  onSubmit: () => void;
};

export type RecordShipmentDrawerLineItem = PurchaseOrderUnshippedLineItem & {
  quantityArriving: number;
};

export function RecordPurchaseOrderShipmentDrawer({
  purchaseOrderId,
  isOpen,
  onClose,
  onSubmit,
}: RecordShipmentDrawerProps) {
  const [expectedShipmentDate, setExpectedShipmentDate] = useState<Date>();
  const [actualShipmentDate, setActualShipmentDate] = useState<Date>();
  const [expectedArrivalDate, setExpectedArrivalDate] = useState<Date>();
  const [actualArrivalDate, setActualArrivalDate] = useState<Date>();

  const [loading, setLoading] = useState(false);

  const { lineItems, lineItemsLoading, lineItemsError, refresh } =
    usePurchaseOrderUnshippedLineItems(purchaseOrderId);

  const [recordedLineItems, setRecordedLineItems] = useState<
    RecordShipmentDrawerLineItem[]
  >([]);

  const everythingShipped = !lineItemsLoading && lineItems.length == 0;
  const nothingAllocated =
    recordedLineItems.reduce((i, li) => li.quantityArriving + i, 0) <= 0;

  useEffect(() => {
    refresh();
  }, [isOpen]);

  useEffect(() => {
    setRecordedLineItems(
      lineItems.map((lineItem) => ({
        ...lineItem,
        quantityArriving: lineItem.quantityOrdered - lineItem.quantityShipped,
      }))
    );
  }, [lineItems]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const shipmentDto = {
      purchaseOrderId: purchaseOrderId,
      expectedShipDate: expectedShipmentDate?.toISOString(),
      actualShipDate: actualShipmentDate?.toISOString(),
      expectedArrivalDate: expectedArrivalDate?.toISOString(),
      actualArrivalDate: actualArrivalDate?.toISOString(),
      lineItems: recordedLineItems.map((recordedLineItem) => ({
        purchaseOrderLineItemId: recordedLineItem.purchaseOrderLineItemId,
        quantity: recordedLineItem.quantityArriving,
      })),
    };

    await axios
      .post(`/purchaseordershipments`, shipmentDto)
      .finally(() => setLoading(false));

    onSubmit();
  };

  const columns = [
    {
      Header: "Line",
      accessor: (_, index) => index + 1,
      Cell: ({ value }) => <p className="m-0 text-center">{value}</p>,
      maxWidth: 5,
    },
    {
      Header: "Description",
      Cell: ({ row }) => (
        <div className="space-y-2">
          <p className="m-0 font-bold">
            {getFullConfigurationCode(
              row.original.configuration as ProductConfiguration
            )}
          </p>
          <p className="m-0 text-gray-400">
            {getFullConfigurationDescription(
              row.original.configuration as ProductConfiguration
            )}
          </p>
          {Boolean(row.original.description) && (
            <p className="m-0 text-gray-400">{row.original.description}</p>
          )}
        </div>
      ),
      className: "with-button",
    },
    {
      Header: "Quantity Ordered",
      Cell: ({ row }) => (
        <div>
          <p className="m-0 text-gray-400">{row.original.quantityOrdered}</p>
        </div>
      ),
      maxWidth: 25,
    },
    {
      Header: "Quantity in Other Shipments",
      Cell: ({ row }) => (
        <div>
          <p className="m-0 text-gray-400">{row.original.quantityShipped}</p>
        </div>
      ),
      maxWidth: 25,
    },
    {
      Header: "Quantity in Shipment",
      Cell: ({ row }) => (
        <div>
          <NumericInput
            fill
            value={row.original.quantityArriving}
            onValueChange={(v) => {
              setRecordedLineItems((state) => {
                const i = state.indexOf(row.original);
                return [
                  ...state.slice(0, i),
                  {
                    ...row.original,
                    quantityArriving: v,
                  },
                  ...state.slice(i + 1),
                ];
              });
            }}
            minorStepSize={1}
            min={0}
            max={row.original.quantityOrdered - row.original.quantityShipped}
            disabled={loading}
            intent={nothingAllocated ? Intent.DANGER : Intent.NONE}
            leftIcon={nothingAllocated ? "error" : null}
          />
        </div>
      ),
      className: "with-button",
      maxWidth: 50,
    },
  ];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      canOutsideClickClose={false}
      position={Position.BOTTOM}
      size={"100%"}
      lazy
      usePortal
    >
      <form onSubmit={handleSubmit}>
        <DrawerTitleToolbar title="Record Shipment" onClose={onClose}>
          <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
            <Button icon="help" minimal />
            <div>
              <H5>Recording Shipments</H5>
              <p className="text-sm text-gray-700">
                Use this screen to record a shipment from a vendor. If you know
                the estimated or actual shipping/arrival dates, you can note
                them now, otherwise they can be added later.
              </p>
              <p className="text-sm text-gray-700">
                If only some of the purchase order items will be on this
                shipment, reduce the quantities of the items below, or set them
                to 0 to remove them from the shipment entirely.
              </p>
              <Callout intent={Intent.WARNING} icon={null}>
                Be careful. Once a shipment has been recorded, the items on the
                shipment can not be changed. In order to change them, you'll
                need to mark this shipment as Cancelled and re-create it.
              </Callout>
            </div>
          </Popover>
        </DrawerTitleToolbar>

        {everythingShipped && (
          <div className="p-2">
            <Callout
              icon="tick-circle"
              intent={Intent.SUCCESS}
              title="No more items to ship"
            >
              Everything in this purchase order is already accounted for in a
              shipment.
            </Callout>
          </div>
        )}

        {!everythingShipped && (
          <div>
            <div className="flex items-center mb-2 bg-gray-50 border-b">
              <div className="border-r p-3 pb-0">
                <FormGroup label="1. Expected Shipment Date">
                  <DateInput
                    inputProps={{ leftIcon: "calendar" }}
                    value={expectedShipmentDate}
                    onChange={setExpectedShipmentDate}
                    formatDate={(d) => d.toLocaleDateString()}
                    parseDate={(s) => new Date(s)}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </div>

              <div className="border-r p-3 pb-0">
                <FormGroup label="2. Actual Shipment Date">
                  <DateInput
                    inputProps={{ leftIcon: "calendar" }}
                    value={actualShipmentDate}
                    onChange={setActualShipmentDate}
                    formatDate={(d) => d.toLocaleDateString()}
                    parseDate={(s) => new Date(s)}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </div>

              <div className="border-r p-3 pb-0">
                <FormGroup label="3. Expected Arrival Date">
                  <DateInput
                    inputProps={{ leftIcon: "calendar" }}
                    value={expectedArrivalDate}
                    onChange={setExpectedArrivalDate}
                    formatDate={(d) => d.toLocaleDateString()}
                    parseDate={(s) => new Date(s)}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </div>

              <div className="border-r p-3 pb-0">
                <FormGroup label="4. Actual Arrival Date">
                  <DateInput
                    inputProps={{ leftIcon: "calendar" }}
                    value={actualArrivalDate}
                    onChange={setActualArrivalDate}
                    formatDate={(d) => d.toLocaleDateString()}
                    parseDate={(s) => new Date(s)}
                    placeholder="MM/DD/YYYY"
                  />
                </FormGroup>
              </div>
            </div>

            <div className="p-2">
              {lineItemsLoading && (
                <div className="flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {!lineItemsLoading && nothingAllocated && (
                <Callout
                  icon="error"
                  intent={Intent.DANGER}
                  title="No items allocated"
                  className="mb-4"
                >
                  You haven't allocated any items for this shipment. To record
                  the shipment, allocate at least one item.
                </Callout>
              )}
              {!lineItemsLoading && (
                <FlexTable columns={columns} data={recordedLineItems} />
              )}
            </div>
          </div>
        )}

        {!everythingShipped && (
          <div className="absolute w-full p-2 bg-gray-50 border-t">
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={onClose}
                text="Cancel"
                disabled={loading}
                type="button"
              />

              <Button
                intent={Intent.PRIMARY}
                text={loading ? "Creating Shipment" : "Create"}
                disabled={loading || nothingAllocated}
                type="submit"
              />
            </div>
          </div>
        )}
      </form>
    </Drawer>
  );
}
