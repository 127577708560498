import { PurchaseOrder } from "../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";

const GET_UNSHIPPED_LINE_ITEMS = gql`
  query GetUnshippedLineItems($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      unshippedLineItems {
        id
        description
        quantityOrdered
        quantityShipped
        purchaseOrderId
        purchaseOrderLineItemId
        configuration {
          id
          isCustomConfiguration
          customVendorId
          customCode
          customDescription
          customListPrice
          catalogProductId
          catalogProduct {
            id
            code
          }
          features {
            id
            selections {
              id
              catalogOption {
                id
                code
                description
              }
            }
          }
        }
      }
    }
  }
`;

export function usePurchaseOrderUnshippedLineItems(purchaseOrderId?: number) {
  const getUnshippedLineItems = useQuery<
    { purchaseOrders: PurchaseOrder[] },
    { purchaseOrderId: number }
  >(GET_UNSHIPPED_LINE_ITEMS, {
    variables: { purchaseOrderId: purchaseOrderId ?? -1 },
    skip: !purchaseOrderId,
  });

  return {
    lineItems:
      getUnshippedLineItems.data?.purchaseOrders[0].unshippedLineItems ?? [],
    lineItemsLoading: getUnshippedLineItems.loading,
    lineItemsError: getUnshippedLineItems.error,
    refresh: () => getUnshippedLineItems.refetch(),
  };
}
