import { useHistory, useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import React, { useCallback } from "react";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { Project } from "../../../../types/ApiTypes";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { Spinner } from "@blueprintjs/core";
import { ErrorDialog } from "../../../../components/shared/ErrorDialog/ErrorDialog";
import { UnscheduledFulfillmentsTable } from "./UnscheduledFulfillmentsTable";
import { ScheduledFulfillmentsTable } from "./ScheduledFulfillmentsTable";
import { CompletedFulfillmentsTable } from "./CompletedFulfillmentsTable";

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const CreateFulfillment = gql`
  mutation CreateFulfillment($input: CreateFulfillmentPayloadInput) {
    createFulfillment(payload: $input) {
      id
    }
  }
`;

export default function ProjectFulfillments() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);

  const [createFulfillmentError, setCreateFulfillmentError] =
    React.useState<Error>();

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GetProjectById, {
    variables: {
      projectId,
    },
  });

  const [createFulfillment] = useMutation<
    { createFulfillment: { id: number } },
    { input: { projectId: number } }
  >(CreateFulfillment, {
    refetchQueries: ["GetFulfillments"],
  });

  const addFulfillment = useCallback(async () => {
    try {
      const result = await createFulfillment({
        variables: {
          input: {
            projectId,
          },
        },
      });

      history.push(
        `/projects/${projectId}/fulfillments/${
          result.data!.createFulfillment.id
        }`
      );
    } catch (err) {
      setCreateFulfillmentError(err);
    }
  }, [createFulfillment]);

  const loading = getProjectById.loading;
  const error = getProjectById.error;

  return (
    <>
      <ErrorDialog
        isOpen={!!createFulfillmentError}
        error={createFulfillmentError}
        onClose={() => setCreateFulfillmentError(undefined)}
      />

      <ErrorDialog isOpen={!!error} error={error} />

      <Helmet>
        <title>{`Fulfillments - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Fulfillments",
            href: `/projects/${projectId}/fulfillments`,
          },
        ]}
      />

      <PortalContainer>
        {loading && <Spinner />}

        {!loading && !error && (
          <div className="space-y-4">
            <UnscheduledFulfillmentsTable
              projectId={projectId}
              onCreateFulfillment={addFulfillment}
            />

            <ScheduledFulfillmentsTable projectId={projectId} />

            <CompletedFulfillmentsTable projectId={projectId} />
          </div>
        )}
      </PortalContainer>
    </>
  );
}
