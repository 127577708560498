import { gql, useQuery } from "@apollo/client";
import { InventoryItem, InventoryItemLabel } from "../types/ApiTypes";
import { useEffect, useState } from "react";
import { InventoryItemLabelWithQRCode } from "../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PrintLabelsPdf";
import { getQrCodeDataUrlForLabel } from "../helpers/QRCode";

export const GET_LABELS = gql`
  query GetLabels($inventoryItemId: Int!) {
    inventoryItems(where: { id: { eq: $inventoryItemId } }) {
      id
      id
      configurationId
      configuration {
        id
        catalogProductId
        catalogProduct {
          id
          code
          description
          sellPriceMultiplier {
            id
            sellPriceMultiplier
            discount
            defaultGrossProfit
          }
          optionGroups {
            id
            code
            description
          }
          defaultPrice {
            id
            amount
          }
          vendor {
            id
            freightRules
          }
        }
        features {
          catalogOptionGroupId
          selections {
            id
            catalogOptionId
            catalogOption {
              id
              description
              code
            }
            catalogOptionGroupId
            catalogOptionGroup {
              id
              description
              code
            }
          }
        }
      }
      purchaseOrderShipmentLineItemId
      purchaseOrderShipmentLineItem {
        id
        purchaseOrderLineItemId
        purchaseOrderLineItem {
          id
          sortIndex
        }
        purchaseOrderShipmentId
        purchaseOrderShipment {
          id
          purchaseOrderId
          purchaseOrder {
            id # PO number
            code
            createdAt # PO number
            manufacturerId
            manufacturer {
              id
              name
            }
            personId
            person {
              id
              firstName
              lastName
            }
            companyId
            company {
              id
              name
            }
            salesOrderId
            salesOrder {
              id # SO number
              code
              createdAt # SO number
            }
            projectId
            project {
              id # project number
              createdAt # project number
              primaryContact {
                # customer
                id
                personId
                person {
                  id
                  firstName
                  lastName
                }
                companyId
                company {
                  id
                  name
                }
              }
            }
          }
        }
      }
      inventoryItemLabels {
        id
        numberOfItemsInPackage # number of items in package
        sequenceNumber
        totalLabelsInSequence
      }
    }
  }
`;

export function useInventoryItemLabelsWithQrCodes(inventoryItemId: number) {
  const {
    data,
    loading: labelsLoading,
    error,
  } = useQuery<{
    inventoryItems: InventoryItem[];
  }>(GET_LABELS, {
    variables: {
      inventoryItemId,
    },
  });

  const [labelsWithQrCodes, setLabelsWithQrCodes] =
    useState<InventoryItemLabelWithQRCode[]>();

  useEffect(() => {
    if (labelsLoading) return;

    const inventoryItem = data!.inventoryItems[0];

    async function addQrCode(
      label: InventoryItemLabel
    ): Promise<InventoryItemLabelWithQRCode> {
      const dataUrl = await getQrCodeDataUrlForLabel(label);
      return { ...label, qrCodeDataUrl: dataUrl, inventoryItem };
    }

    Promise.all(inventoryItem.inventoryItemLabels.map(addQrCode))
      .then((r) => setLabelsWithQrCodes(r))
      .catch((err) => console.error(err));
  }, [data, labelsLoading]);

  return {
    loading: labelsLoading || !labelsWithQrCodes,
    error,
    labels: labelsWithQrCodes,
  };
}
