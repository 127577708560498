import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { useHistory, useRouteMatch } from "react-router-dom";
import { QuoteSubnavbar } from "../../../../../components/projects/[projectId]/quotes/[quoteId]/QuoteSubnavbar";
import { QuoteConversionRequirement } from "../../../../../components/projects/[projectId]/quotes/[quoteId]/QuoteConversionRequirement";
import { Button, Icon, Position, Spinner, Tooltip } from "@blueprintjs/core";
import { useQuoteConversionRequirements } from "../../../../../hooks/useQuoteConversionRequirements";
import { gql, useQuery } from "@apollo/client";
import { Quote, QuoteLineItem } from "../../../../../types/ApiTypes";
import { parseInt } from "lodash";
import classNames from "classnames";
import { QuoteConvertedWarning } from "../../../../../components/projects/[projectId]/quotes/[quoteId]/QuoteConvertedWarning";

export const GET_QUOTE_DETAILS = gql`
  query GetQuoteDetails($quoteId: Int!) {
    quotes(where: { id: { eq: $quoteId } }) {
      id
      name
      code
      quoteLineItemGroups {
        id
        name
        quoteLineItems {
          id
          description
          configurationId
          configuration {
            id
            complete
          }
        }
      }
    }
  }
`;

export default function QuoteConversion() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string; quoteId: string }>();
  const projectId = parseInt(match.params.projectId);
  const quoteId = parseInt(match.params.quoteId);

  const getQuoteDetails = useQuery<{ quotes: Quote[] }, { quoteId: number }>(
    GET_QUOTE_DETAILS,
    {
      variables: {
        quoteId: quoteId,
      },
    }
  );

  const {
    getConversionDetails,
    updateConversionRequirement,
    addAttachmentToConversionRequirement,
    removeAttachmentFromConversionRequirement,
    convertQuoteToSalesOrder,
  } = useQuoteConversionRequirements(projectId, quoteId);

  const quote = getConversionDetails.data?.quotes[0];
  const project = getConversionDetails.data?.projects[0];
  const requirements =
    getConversionDetails.data?.quotes[0].conversionRequirements;
  const incompleteRequirements = requirements?.filter((r) => !r.completedAt);
  const hasBeenConverted = !!quote?.salesOrder?.id;

  const projectName = getConversionDetails.loading
    ? `Project #${projectId}`
    : project!.name;

  const quoteName = getConversionDetails.loading
    ? `Quote #${quoteId}`
    : quote!.name;

  if (getQuoteDetails.loading) return <Spinner />;

  const quoteLineItemGroups =
    getQuoteDetails.data?.quotes[0].quoteLineItemGroups!;
  const incompleteLineItems = quoteLineItemGroups
    .reduce(
      (x, group) => x.concat(group.quoteLineItems!),
      new Array<QuoteLineItem>()
    )
    .filter(
      (lineItem) =>
        lineItem.configuration != null &&
        lineItem!.configuration!.complete != true
    );
  const canBeConverted =
    incompleteLineItems?.length == 0 &&
    incompleteRequirements?.length === 0 &&
    !hasBeenConverted;

  return (
    <>
      <Helmet>
        <title>{`${quoteName} Conversion - ${projectName}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getConversionDetails.loading}
        projectPrimaryContact={project?.primaryContact}
        projectName={project?.name ?? ""}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Quotes", href: `/projects/${projectId}/quotes` },
          {
            text: quote ? quote.code : "...",
            href: `/projects/${projectId}/quotes/${quoteId}`,
          },
        ]}
      />

      <QuoteSubnavbar
        title={`${quote?.code} Conversion`}
        actions={
          <>
            {hasBeenConverted && (
              <Button
                text="View Sales Order"
                onClick={() => {
                  history.push(
                    `/projects/${projectId}/sales-orders/${
                      quote!.salesOrder!.id
                    }`
                  );
                }}
              />
            )}
            {canBeConverted && (
              <Button
                text="Convert Quote"
                onClick={async () => {
                  const result = await convertQuoteToSalesOrder({
                    variables: { quoteId },
                  });
                  history.push(
                    `/projects/${projectId}/sales-orders/${
                      result.data!.convertQuoteToSalesOrder.id
                    }`
                  );
                }}
              />
            )}

            {!hasBeenConverted && !canBeConverted && (
              <Tooltip
                position={Position.BOTTOM}
                content="This quote can't be converted until all conversion requirements are completed."
              >
                <Button text="Convert Quote" disabled />
              </Tooltip>
            )}
          </>
        }
      />

      {quote?.salesOrder && (
        <QuoteConvertedWarning
          projectId={projectId}
          salesOrderId={quote.salesOrder.id!}
          salesOrderCode={quote.salesOrder.code}
        />
      )}

      <PortalContainer>
        {!getConversionDetails.loading && (
          <div className="grid grid-cols-1 gap-4">
            <div
              className={classNames(
                "flex flex-col rounded-md border-2 overflow-hidden",
                { "border-green-300": incompleteLineItems.length == 0 }
              )}
            >
              <div
                className={classNames(
                  "border-b p-4 flex items-center",
                  { "bg-gray-50": incompleteLineItems.length != 0 },
                  { "bg-green-50": incompleteLineItems.length == 0 }
                )}
              >
                <h3 className="m-0 font-semibold flex-1">
                  {incompleteLineItems.length == 0
                    ? "All catalog line items have complete product configurations."
                    : incompleteLineItems.length +
                      " line items have incomplete configurations."}
                </h3>
              </div>
              <div className="p-4 flex-1">
                {incompleteLineItems.length == 0 ? (
                  "All catalog line items have complete product configurations."
                ) : (
                  <div>
                    <p>
                      The following line items lack a complete configuration:
                    </p>
                    <ul>
                      {incompleteLineItems.map((lineItem) => (
                        <li key={lineItem.id}>{lineItem.description}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {requirements!.map((r) => (
              <QuoteConversionRequirement
                projectId={projectId}
                requirement={r}
                disabled={hasBeenConverted}
                onChange={async (input) => {
                  await updateConversionRequirement({
                    variables: {
                      input,
                    },
                  });
                }}
                onAddAttachment={async (attachment) => {
                  await addAttachmentToConversionRequirement({
                    variables: {
                      conversionRequirementId: r.id!,
                      attachmentId: attachment.id!,
                    },
                  });
                }}
                onRemoveAttachment={async (attachment) => {
                  await removeAttachmentFromConversionRequirement({
                    variables: {
                      conversionRequirementId: r.id!,
                      attachmentId: attachment.id!,
                    },
                  });
                }}
              />
            ))}
          </div>
        )}
      </PortalContainer>
    </>
  );
}
