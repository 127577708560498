import { Button } from "@blueprintjs/core";
import { useHistory, useRouteMatch } from "react-router-dom";

export type ContactSidebarProps = {
  searchElement?: React.ReactNode;
  children?: React.ReactNode | React.ReactNode[];
};

export function ContactSidebar({
  searchElement,
  children,
}: ContactSidebarProps) {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <>
      <div className="bg-white shadow z-10 p-2 flex flex-col space-y-2">
        <Button
          icon="person"
          text="People"
          alignText="left"
          large
          minimal
          active={match.path.startsWith("/contacts/people")}
          onClick={() => history.push("/contacts/people")}
        />

        <Button
          icon="office"
          text="Companies"
          alignText="left"
          large
          minimal
          active={match.path.startsWith("/contacts/companies")}
          onClick={() => history.push("/contacts/companies")}
        />

        <Button
          icon="truck"
          text="Vendors"
          alignText="left"
          large
          minimal
          active={match.path.startsWith("/contacts/vendors")}
          onClick={() => history.push("/contacts/vendors")}
        />
      </div>

      <div className="bg-gray-50 w-60 border-r flex flex-col">
        {!!searchElement && (
          <div className="bg-white p-2 border-b flex items-center">
            {searchElement}
          </div>
        )}

        <div className="flex-1 overflow-auto">{children}</div>
      </div>
    </>
  );
}
