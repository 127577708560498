import styles from "../../../../../components/shared/Printable/Printable.module.scss";
import {
  Printable,
  PrintableHeaderColumn,
  PrintableHeaderColumnItem,
} from "../../../../../components/shared/Printable/Printable";
import { gql, useQuery } from "@apollo/client";
import { Spinner } from "@blueprintjs/core";
import {
  CompanyAddressType,
  FulfillmentMethodType,
  PersonAddressType,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";
import { formatAddress } from "../../../../../helpers/AddressHelper";
import { formatDate } from "../../../../../helpers/DateHelper";
import { formatFulfillmentMethodType } from "../../../../../helpers/FulfillmentHelper";
import { formatAccountName } from "../../../../../helpers/AccountHelper";
import { Link } from "react-router-dom";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import {
  formatContactName,
  FormatContactNameContact,
} from "../../../../../helpers/ContactHelper";

type GetFulfillmentResult = {
  fulfillments: {
    id: number;
    createdBy: {
      id: number;
      name: string;
    };
    fulfillmentMethodType?: FulfillmentMethodType;
    fulfillmentMethodNotes?: string;
    requestedAt?: string;
    installStartsAt?: string;
    installEndsAt?: string;
    deliveryStartsAt?: string;
    deliveryEndsAt?: string;
    project: {
      id: number;
      shipToAddress?: {
        id: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        postalCode: string;
        addressSiteInformation?: {
          id: number;
          numberOfInsideStairs: number;
          numberOfOutsideStairs: number;
          hasDock: boolean;
          hasFreightElevator: boolean;
          hasPassengerElevator: boolean;
          isCertificateOfInsuranceRequired: boolean;
        };
      };
      salesRepAccount?: {
        id: number;
        name: string;
      };
      primaryContact?: {
        id: number;
        person?: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company?: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
      fulfillmentContact?: {
        id: number;
        person?: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company?: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
    };
    lineItems: {
      id: number;
      salesOrderLineItem: {
        id: number;
        configuration?: ProductConfiguration;
        salesOrderLineItemGroup: {
          id: number;
          salesOrder: {
            id: number;
            code: string;
          };
        };
      };
    }[];
  }[];
};

const GetFulfillment = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetFulfillment($id: Int!) {
    fulfillments(where: { id: { eq: $id } }) {
      id
      createdBy {
        id
        name
      }
      fulfillmentMethodType
      fulfillmentMethodNotes
      requestedAt
      installStartsAt
      installEndsAt
      deliveryStartsAt
      deliveryEndsAt
      project {
        id
        shipToAddress {
          id
          street1
          street2
          city
          state
          postalCode
          addressSiteInformation {
            id
            numberOfInsideStairs
            numberOfOutsideStairs
            hasDock
            hasFreightElevator
            hasPassengerElevator
            isCertificateOfInsuranceRequired
          }
        }
        salesRepAccount {
          id
          name
        }
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            email
            phone
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
        fulfillmentContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            email
            phone
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
      }
      lineItems {
        id
        salesOrderLineItem {
          id
          configuration {
            id
            ...GetFullConfigurationCodeFragment
            ...GetFullConfigurationDescriptionFragment
          }
          salesOrderLineItemGroup {
            id
            salesOrder {
              id
              code
            }
          }
        }
      }
    }
  }
`;

function getPersonPrimaryAddress(
  addresses?: {
    id: number;
    type: PersonAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === PersonAddressType.Primary)?.address;
}

function getCompanyPrimaryAddress(
  addresses?: {
    id: number;
    type: CompanyAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === CompanyAddressType.Primary)?.address;
}

export default function PrintableFulfillment() {
  const getFulfillment = useQuery<GetFulfillmentResult, { id: number }>(
    GetFulfillment,
    { fetchPolicy: "cache-and-network", variables: { id: 19 } }
  );

  const fulfillment = getFulfillment.data?.fulfillments[0];
  const shipToAddress = fulfillment?.project.shipToAddress;
  const addressSiteInformation =
    fulfillment?.project.shipToAddress?.addressSiteInformation;
  const fulfillmentContact = fulfillment?.project.fulfillmentContact;

  return (
    <div className={styles.printoutPage}>
      <div className={styles.printoutPageContent}>
        {getFulfillment.loading ? (
          <Spinner />
        ) : (
          <Printable
            title="Fulfillment"
            headerColumns={
              <>
                <PrintableHeaderColumn title="Customer Info">
                  {fulfillment?.project.primaryContact ? (
                    <>
                      {fulfillment.project.primaryContact.person && (
                        <PrintableHeaderColumnItem label="Contact">
                          {fulfillment.project.primaryContact.person.firstName}
                          &nbsp;
                          {fulfillment.project.primaryContact.person.lastName}
                        </PrintableHeaderColumnItem>
                      )}
                      {fulfillment.project.primaryContact.company && (
                        <PrintableHeaderColumnItem label="Company">
                          {fulfillment.project.primaryContact.company.name}
                        </PrintableHeaderColumnItem>
                      )}
                      <PrintableHeaderColumnItem label="Email">
                        {fulfillment.project.primaryContact.person?.email ??
                          fulfillment.project.primaryContact.company?.email}
                      </PrintableHeaderColumnItem>
                      <PrintableHeaderColumnItem label="Phone">
                        {fulfillment.project.primaryContact.person?.phone ??
                          fulfillment.project.primaryContact.company?.phone}
                      </PrintableHeaderColumnItem>
                      <PrintableHeaderColumnItem label="Address">
                        {formatAddress(
                          getPersonPrimaryAddress(
                            fulfillment.project.primaryContact.person
                              ?.personAddresses
                          ) ??
                            getCompanyPrimaryAddress(
                              fulfillment.project.primaryContact.company
                                ?.companyAddresses
                            )
                        )}
                      </PrintableHeaderColumnItem>
                    </>
                  ) : (
                    <PrintableHeaderColumnItem>
                      <span className="text-red-400">
                        Primary contact not specified.{" "}
                        <Link
                          to={`/projects/${fulfillment!.project.id}/contacts`}
                        >
                          Fix
                        </Link>
                      </span>
                    </PrintableHeaderColumnItem>
                  )}
                </PrintableHeaderColumn>
                <PrintableHeaderColumn title="Fulfillment Contact">
                  {fulfillment?.project.fulfillmentContact ? (
                    <>
                      {fulfillment.project.fulfillmentContact.person && (
                        <PrintableHeaderColumnItem label="Contact">
                          {
                            fulfillment.project.fulfillmentContact.person
                              .firstName
                          }
                          &nbsp;
                          {
                            fulfillment.project.fulfillmentContact.person
                              .lastName
                          }
                        </PrintableHeaderColumnItem>
                      )}
                      {fulfillment.project.fulfillmentContact.company && (
                        <PrintableHeaderColumnItem label="Company">
                          {fulfillment.project.fulfillmentContact.company.name}
                        </PrintableHeaderColumnItem>
                      )}
                      <PrintableHeaderColumnItem label="Email">
                        {fulfillment.project.fulfillmentContact.person?.email ??
                          fulfillment.project.fulfillmentContact.company?.email}
                      </PrintableHeaderColumnItem>
                      <PrintableHeaderColumnItem label="Phone">
                        {fulfillment.project.fulfillmentContact.person?.phone ??
                          fulfillment.project.fulfillmentContact.company?.phone}
                      </PrintableHeaderColumnItem>
                      <PrintableHeaderColumnItem label="Address">
                        {formatAddress(
                          getPersonPrimaryAddress(
                            fulfillment.project.fulfillmentContact.person
                              ?.personAddresses
                          ) ??
                            getCompanyPrimaryAddress(
                              fulfillment.project.fulfillmentContact.company
                                ?.companyAddresses
                            )
                        )}
                      </PrintableHeaderColumnItem>
                    </>
                  ) : (
                    <PrintableHeaderColumnItem>
                      <span className="text-red-400">
                        Fulfillment contact not specified.{" "}
                        <Link
                          to={`/projects/${fulfillment!.project.id}/contacts`}
                        >
                          Fix
                        </Link>
                      </span>
                    </PrintableHeaderColumnItem>
                  )}
                </PrintableHeaderColumn>
                <PrintableHeaderColumn>
                  <PrintableHeaderColumnItem label="Fulfillment #">
                    F-{fulfillment!.id}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Method">
                    {fulfillment?.fulfillmentMethodType
                      ? [
                          formatFulfillmentMethodType(
                            fulfillment.fulfillmentMethodType
                          ),
                          fulfillment.fulfillmentMethodNotes,
                        ]
                          .filter(Boolean)
                          .join(" - ")
                      : "-"}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Requested at">
                    {fulfillment?.requestedAt
                      ? formatDate(fulfillment.requestedAt)
                      : "-"}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Install">
                    {[fulfillment?.installStartsAt, fulfillment?.installEndsAt]
                      .filter(Boolean)
                      .map((d) => formatDate(d!))
                      .join(" - ")}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Delivery">
                    {[
                      fulfillment?.deliveryStartsAt,
                      fulfillment?.deliveryEndsAt,
                    ]
                      .filter(Boolean)
                      .map((d) => formatDate(d!))
                      .join(" - ")}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Written by">
                    {formatAccountName(fulfillment?.createdBy)}
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Sales rep">
                    {fulfillment?.project.salesRepAccount
                      ? formatAccountName(fulfillment?.project.salesRepAccount)
                      : "-"}
                  </PrintableHeaderColumnItem>
                </PrintableHeaderColumn>
              </>
            }
          >
            <div className="space-y-4 text-xs">
              <div className="flex space-x-4">
                <div className="rounded border space-y-1 p-1 flex-1">
                  <h4 className="font-medium">Site Contact</h4>

                  {fulfillmentContact && (
                    <>
                      <p>
                        {formatContactName(
                          fulfillmentContact as FormatContactNameContact
                        )}
                      </p>
                      <p>
                        <strong>Email:</strong>{" "}
                        {fulfillmentContact.company?.email ??
                          fulfillmentContact.person?.email ??
                          "N/A"}
                      </p>
                      <p>
                        <strong>Phone:</strong>{" "}
                        {fulfillmentContact.company?.phone ??
                          fulfillmentContact.person?.phone ??
                          "N/A"}
                      </p>
                    </>
                  )}

                  {!fulfillmentContact && <p>N/A</p>}
                </div>

                <div className="rounded border space-y-1 p-1 flex-1">
                  <h4 className="font-medium">Delivery Address</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: shipToAddress
                        ? formatAddress(shipToAddress).replaceAll(
                            "\n",
                            "<br />"
                          )
                        : "N/A",
                    }}
                  />
                  <p className="font-medium">** See site information below</p>
                </div>
              </div>

              <BasicTable>
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>#</th>
                    <th style={{ width: 100 }}>SO #</th>
                    <th>Product #</th>
                    <th>Description</th>
                  </tr>
                </thead>

                <tbody>
                  {fulfillment?.lineItems.map((item, i) => (
                    <tr key={item.id}>
                      <td>{i + 1}</td>
                      <td>
                        <p>
                          {
                            item.salesOrderLineItem.salesOrderLineItemGroup
                              .salesOrder.code
                          }
                        </p>
                      </td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.salesOrderLineItem.configuration
                              ? getFullConfigurationCode(
                                  item.salesOrderLineItem.configuration
                                ).replaceAll("\n", "<br />")
                              : "N/A",
                          }}
                        />
                      </td>
                      <td>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.salesOrderLineItem.configuration
                              ? getFullConfigurationDescription(
                                  item.salesOrderLineItem.configuration
                                ).replaceAll("\n", "<br />")
                              : "N/A",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </BasicTable>

              <div className="rounded border space-y-1 p-1 flex-1">
                <h4 className="font-medium">Site Information</h4>

                <ul className="list-disc ml-4">
                  <li>
                    Installation will be performed during Normal Business Hours.
                  </li>
                  <li>Non-Union Labor</li>
                </ul>

                {addressSiteInformation && (
                  <>
                    <h4 className="font-medium">Site Conditions</h4>
                    <ul className="list-disc ml-4">
                      <li>
                        <strong>Dock:</strong>{" "}
                        {addressSiteInformation.hasDock ? "Yes" : "No"}
                      </li>
                      <li>
                        <strong>Inside Stairs:</strong>{" "}
                        {addressSiteInformation.numberOfInsideStairs > 0
                          ? addressSiteInformation.numberOfInsideStairs
                          : "No"}
                      </li>
                      <li>
                        <strong>Outside Stairs:</strong>{" "}
                        {addressSiteInformation.numberOfOutsideStairs > 0
                          ? addressSiteInformation.numberOfOutsideStairs
                          : "No"}
                      </li>
                      <li>
                        <strong>Elevator:</strong>{" "}
                        {[
                          addressSiteInformation.hasFreightElevator &&
                            "Freight",
                          addressSiteInformation.hasPassengerElevator &&
                            "Passenger",
                          !addressSiteInformation.hasFreightElevator &&
                            !addressSiteInformation.hasPassengerElevator &&
                            "No",
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </li>
                      <li>
                        <strong>Certificate of Insurance:</strong>{" "}
                        {addressSiteInformation.isCertificateOfInsuranceRequired
                          ? "Required"
                          : "Not Required"}
                      </li>
                    </ul>
                  </>
                )}

                <h4 className="font-medium">Installation Conditions</h4>

                <ul className="list-disc ml-4">
                  <li>
                    Space to be free and clear of construction materials, person
                    items, and existing furniture.
                  </li>
                  <li>Installation quote is based on delivery to job site.</li>
                  <li>
                    Electrical connections are not included. Customer must
                    provide their own electrician.
                  </li>
                  <li>
                    Product will be installed per final floorplan provided by
                    WOF.
                  </li>
                  <li>
                    <strong>
                      Any onsite reconfigurations will be subject to a change
                      order fee and an additional trip charge.
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </Printable>
        )}
      </div>
    </div>
  );
}
