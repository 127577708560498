import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import { Button, Intent } from "@blueprintjs/core";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import {
  InventoryItemInspectionStatus,
  PurchaseOrder,
} from "../../../../types/ApiTypes";
import { useHistory } from "react-router-dom";

const GET_PURCHASE_ORDER_INVENTORY_ITEMS = gql`
  query GetPurchaseOrderInventoryItems($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      inventoryItems {
        id
        mostRecentInspectionStatus
      }
    }
  }
`;

export type PurchaseOrderListInspectionStatusCellProps = {
  projectId: number;
  purchaseOrderId: number;
};

export function PurchaseOrderListInspectionStatusCell({
  projectId,
  purchaseOrderId,
}: PurchaseOrderListInspectionStatusCellProps) {
  const history = useHistory();

  const { data, loading, error } = useQuery<{
    purchaseOrders: PurchaseOrder[];
  }>(GET_PURCHASE_ORDER_INVENTORY_ITEMS, {
    variables: {
      purchaseOrderId: purchaseOrderId,
    },
    fetchPolicy: "network-only",
  });

  const purchaseOrder = data?.purchaseOrders[0];
  const inventoryItems = purchaseOrder?.inventoryItems;

  var inspectedItemsCount = 0;
  var damagedItemsCount = 0;
  var uninspectedItemsCount = 0;

  if (!!inventoryItems) {
    inventoryItems.forEach((inventoryItem) => {
      var status = (inventoryItem.mostRecentInspectionStatus as unknown as string).toUpperCase();

      if (status == "UNINSPECTED")
        uninspectedItemsCount++;
      else {
        inspectedItemsCount++;
        if (status == "MAJORDAMAGE")
          damagedItemsCount++;
        else if (status == "MINORDAMAGE")
          damagedItemsCount++;
      }
    });
  }

  function getIntent() {
    if (damagedItemsCount > 0)
      return Intent.DANGER;

    if (inspectedItemsCount === 0) {
      return Intent.NONE;
    } else if (inspectedItemsCount > 0 && uninspectedItemsCount === 0) {
      return Intent.SUCCESS;
    } else {
      return Intent.WARNING;
    }
  }

  function getText() {
    if (inspectedItemsCount === 0) {
      return "Record Inspection";
    } else {
      return `${inspectedItemsCount}/${
          inventoryItems!.length
      } Items Inspected`;
    }
  }


  function getButtonLabel() {

    if (inspectedItemsCount === 0) {
      return "Begin Inspection";
    } else if (inspectedItemsCount > 0 && uninspectedItemsCount === 0) {
      return "Manage Inventory";
    } else {
      return "Continue Inspection";
    }
  }

  return (
    <PurchaseOrderStatusCell
      text={loading ? "" : getText()}
      intent={loading ? Intent.NONE : getIntent()}
      loading={loading}
    >
      {!loading && (
        <div className="p-2">
          <div className="border-b pb-2 mb-2 max-w-sm">
            <h3 className="font-bold mb-2">Inspection</h3>
            {inspectedItemsCount === 0 && (
              <div>
                <p className="m-0">
                  No items on the purchase order have been inspected.
                </p>
              </div>
            )}
            {inspectedItemsCount > 0 && (
              <div>
                <p className="m-0">
                  {inspectedItemsCount}/{inventoryItems!.length} items on
                  the purchase order have been inspected.
                </p>
                <p className="m-0">
                  {damagedItemsCount} items are damaged.
                </p>
              </div>
            )}
          </div>
          <Button
            fill
            rightIcon="chevron-right"
            text={getButtonLabel()}
            intent={Intent.PRIMARY}
            onClick={() =>
              history.push(
                `/projects/${projectId}/purchase-orders/${purchaseOrderId}/inventory`
              )
            }
          />
        </div>
      )}
    </PurchaseOrderStatusCell>
  );
}
