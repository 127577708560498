import { MenuItem } from "@blueprintjs/core";

type VisibleColumnMenuItemProps = {
  text: string;
  isVisible: boolean;
  onClick: () => void;
};

export function VisibleColumnMenuItem({
  text,
  isVisible,
  onClick,
}: VisibleColumnMenuItemProps) {
  return (
    <MenuItem
      text={text}
      icon={isVisible ? "tick-circle" : "circle"}
      onClick={onClick}
      shouldDismissPopover={false}
    />
  );
}
