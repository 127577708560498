import { PortalContainer } from "../../components/shared/PortalContainer/PortalContainer";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import {
  CommissionReport as CommissionReportType,
  useCommissionReport,
} from "./useCommissionReport";
import {
  Button,
  Callout,
  FormGroup,
  HTMLSelect,
  Spinner,
} from "@blueprintjs/core";
import { HotColumn, HotTable } from "@handsontable/react";
import dayjs from "dayjs";
import { useState } from "react";

const FAKE_REPORT: CommissionReportType = {
  salesRepName: "VM",
  startsAt: "2019-12-31T16:00:00-08:00",
  endsAt: "2020-01-31T16:00:00-08:00",
  createdAt: "2023-01-18T22:46:59.148941Z",
  reportItems: [
    {
      timePaid: "2020-01-28T06:38:19",
      estimateNumber: "1908334",
      customerName: "Meridian Medical",
      projectTotal: 3123.93,
      usedTotal: 0,
      internalServiceTotal: 315,
      purchaseOrderTotal: 1823,
      unrecognizedLineItems: [
        "C1",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2020-01-28T06:38:19",
      estimateNumber: "1908352",
      customerName: "Meridian Medical",
      projectTotal: 10847,
      usedTotal: 205,
      internalServiceTotal: 250,
      purchaseOrderTotal: 6874.34,
      unrecognizedLineItems: [
        "C1",
        "Global",
        "Global",
        "Global",
        "Global",
        "Global",
        "Global",
        "DSA",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2020-01-28T06:38:19",
      estimateNumber: "1910271",
      customerName: "Meridian Medical",
      projectTotal: 350,
      usedTotal: 0,
      internalServiceTotal: 350,
      purchaseOrderTotal: 0,
      unrecognizedLineItems: ["C1"],
    },
    {
      timePaid: "2020-01-13T12:05:14",
      estimateNumber: "1910227",
      customerName: "Meridian Medical",
      projectTotal: 1536,
      usedTotal: 0,
      internalServiceTotal: 1275,
      purchaseOrderTotal: 0,
      unrecognizedLineItems: ["C1", "RENTAL"],
    },
    {
      timePaid: "2019-12-31T11:40:57",
      estimateNumber: "1906150",
      customerName: "Meridian Medical",
      projectTotal: 38422.02,
      usedTotal: 0,
      internalServiceTotal: 2226,
      purchaseOrderTotal: 25002.13,
      unrecognizedLineItems: [
        "C1",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "DSA",
        "Refurbiture",
        "Hat Contract",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2020-01-13T12:32:23",
      estimateNumber: "1909264",
      customerName: "YWCA METRO ST. LOUIS - HEAD START",
      projectTotal: 2381.83,
      usedTotal: 184.36,
      internalServiceTotal: 290,
      purchaseOrderTotal: 1412,
      unrecognizedLineItems: [
        "C1",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "DSA",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "DSA",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2019-12-31T11:40:57",
      estimateNumber: "1910304",
      customerName: "Meridian Medical",
      projectTotal: 4736.48,
      usedTotal: 0,
      internalServiceTotal: 635,
      purchaseOrderTotal: 2325.48,
      unrecognizedLineItems: ["C1", "ACCO"],
    },
    {
      timePaid: "2020-01-13T12:05:14",
      estimateNumber: "1908326",
      customerName: "Meridian Medical",
      projectTotal: 9155.64,
      usedTotal: 0,
      internalServiceTotal: 905,
      purchaseOrderTotal: 5648.44,
      unrecognizedLineItems: [
        "C1",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "98669",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2020-01-13T12:05:14",
      estimateNumber: "1911147",
      customerName: "Meridian Medical",
      projectTotal: 1208.48,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 847.5,
      unrecognizedLineItems: [
        "C1",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
        "Refurbiture",
      ],
    },
    {
      timePaid: "2020-01-13T12:05:14",
      estimateNumber: "1909124",
      customerName: "Meridian Medical",
      projectTotal: 726.42,
      usedTotal: 0,
      internalServiceTotal: 150,
      purchaseOrderTotal: 403.5,
      unrecognizedLineItems: ["C1", "Refurbiture", "Refurbiture"],
    },
    {
      timePaid: "2020-01-23T12:25:24",
      estimateNumber: "1911210",
      customerName: "Meridian Medical",
      projectTotal: 317.05,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 215.48,
      unrecognizedLineItems: ["C1", "Refurbiture", "Convenience Fee"],
    },
    {
      timePaid: "2020-01-22T06:38:42",
      estimateNumber: "1908252",
      customerName: "Meridian Medical",
      projectTotal: 850,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 550,
      unrecognizedLineItems: ["C1", "Refurbiture"],
    },
    {
      timePaid: "2020-01-07T07:05:07",
      estimateNumber: "1911223",
      customerName: "Y.W.C.A",
      projectTotal: 357.14,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 250,
      unrecognizedLineItems: ["C1", "Refurbiture"],
    },
    {
      timePaid: "2020-01-07T07:05:07",
      estimateNumber: "1910209",
      customerName: "Y.W.C.A",
      projectTotal: 291.42,
      usedTotal: 60,
      internalServiceTotal: 71.42,
      purchaseOrderTotal: 162,
      unrecognizedLineItems: ["C1", "Refurbiture"],
    },
    {
      timePaid: "2020-01-13T12:18:47",
      estimateNumber: "DEP1912205",
      customerName: "Furniture Services Group",
      projectTotal: 581.3,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 0,
      unrecognizedLineItems: ["Customer Deposit"],
    },
    {
      timePaid: "2020-01-22T05:56:56",
      estimateNumber: "DEP2001080",
      customerName: "Missouri Botanical Garden",
      projectTotal: 750,
      usedTotal: 0,
      internalServiceTotal: 0,
      purchaseOrderTotal: 0,
      unrecognizedLineItems: ["Customer Deposit"],
    },
  ],
};

export function CommissionReport() {
  const [ranReport, setRanReport] = useState(false);
  const [salesRep, setSalesRep] = useState("");
  const [year, setYear] = useState(dayjs().format("YYYY"));
  const [month, setMonth] = useState(dayjs().format("MM"));

  const startDate = dayjs(`${year}-${month}-01`);
  const endDate = dayjs(startDate).add(1, "month").subtract(1, "day");

  const { loading, error, report, refresh } = useCommissionReport(
    salesRep,
    startDate.toDate(),
    endDate.toDate()
  );

  return (
    <>
      <ProjectSubnavbar
        title={"Commission Report"}
        style={{ top: "unset" }}
        actions={
          <>
            <div className="flex items-center space-x-2">
              <label>Month</label>
              <HTMLSelect
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                disabled={loading}
              >
                <option value="01">January</option>
                <option value="02">February</option>
                <option value="03">March</option>
                <option value="04">April</option>
                <option value="05">May</option>
                <option value="06">June</option>
                <option value="07">July</option>
                <option value="08">August</option>
                <option value="09">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </HTMLSelect>
            </div>

            <div className="flex items-center space-x-2">
              <label>Year</label>
              <HTMLSelect
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled={loading}
              >
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </HTMLSelect>
            </div>

            <div className="flex items-center space-x-2">
              <label>Sales Rep</label>
              <HTMLSelect
                value={salesRep}
                onChange={(e) => setSalesRep(e.target.value)}
                disabled={loading}
              >
                <option disabled value="">
                  Select a sales rep...
                </option>
                <option value="VM">Vickie Miller</option>
              </HTMLSelect>
            </div>

            <Button
              intent="primary"
              text="Run Report"
              disabled={!salesRep || loading}
              loading={loading}
              onClick={() => {
                setRanReport(true);
                refresh();
              }}
            />
          </>
        }
      />

      <PortalContainer>
        {!ranReport && (
          <Callout intent="primary" title="Click Run Report to get started">
            Select a sales rep, month, year, and then click "Run Report" to run
            the commission report.
          </Callout>
        )}

        {loading && (
          <Callout title="Running report..." icon="time">
            <div className="flex space-x-2 items-center">
              <Spinner size={20} />
              <p className="m-0">
                Please wait while the report is generated. This may take a few
                minutes.
              </p>
            </div>
          </Callout>
        )}

        {!loading && error && (
          <Callout intent="danger" title="Error running report">
            {error}
          </Callout>
        )}

        {!loading && !error && report && (
          <HotTable
            data={report.reportItems.map((ri) => ({
              ...ri,
              timePaidDate: dayjs(ri.timePaid).format("MM-DD-YYYY"),
              salesRep: report.salesRepName,
              stage: "Paid",
            }))}
            rowHeaders={true}
            colHeaders={[
              "Time Paid",
              "Estimate Number",
              "Customer Name",
              "Sales Rep",
              "Stage",
              "Project Total",
              "Used Total",
              "Internal Service Total",
              "Purchase Order Total",
            ]}
            manualColumnResize
            manualColumnMove
            columnSorting
            licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          >
            <HotColumn
              data="timePaidDate"
              type="date"
              dateFormat="MM-DD-YYYY"
              readOnly
            />
            <HotColumn data="estimateNumber" readOnly />
            <HotColumn data="customerName" readOnly />
            <HotColumn title="Sales Rep" data="salesRep" readOnly />
            <HotColumn title="Stage" data="stage" readOnly />
            <HotColumn
              data="projectTotal"
              type="numeric"
              numericFormat={{ pattern: "$0.00" }}
              readOnly
            />
            <HotColumn
              data="usedTotal"
              type="numeric"
              numericFormat={{ pattern: "$0.00" }}
              readOnly
            />
            <HotColumn
              data="internalServiceTotal"
              type="numeric"
              numericFormat={{ pattern: "$0.00" }}
              readOnly
            />
            <HotColumn
              data="purchaseOrderTotal"
              type="numeric"
              numericFormat={{ pattern: "$0.00" }}
              readOnly
            />
          </HotTable>
        )}
      </PortalContainer>
    </>
  );
}
