import {
  Printable,
  PrintableHeaderColumn,
  PrintableHeaderColumnItem,
} from "../../../../../components/shared/Printable/Printable";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import styles from "../../../../../components/shared/Printable/Printable.module.scss";
import { gql, useQuery } from "@apollo/client";
import { formatContactName } from "../../../../../helpers/ContactHelper";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { Spinner } from "@blueprintjs/core";
import {
  CompanyAddressType,
  PersonAddressType,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";
import { formatAddress } from "../../../../../helpers/AddressHelper";
import { formatDate } from "../../../../../helpers/DateHelper";
import { formatAccountName } from "../../../../../helpers/AccountHelper";
import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import { formatPrice } from "../../../../../helpers/PriceHelper";

type GetPurchaseOrderDetailsResult = {
  purchaseOrders: {
    id: number;
    code: string;
    requestedShipDate: string;
    manufacturerId: number;
    vendor: {
      id: number;
      name: string;
    };
    createdBy: {
      id: number;
      name: string;
    };
    salesOrder: {
      id: number;
      code: string;
    };
    lineItems: {
      id: number;
      sortIndex: number;
      quantity: number;
      configuration?: ProductConfiguration;
      description: string;
      purchasePrice: number;
    }[];
    project: {
      id: number;
      salesRepAccount?: {
        id: number;
        name: string;
        spiffCodes: {
          id: number;
          code: string;
          vendorId: number;
        }[];
      };
      primaryContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
      fulfillmentContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
          email: string;
          phone: string;
          personAddresses: {
            id: number;
            type: PersonAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
        company: {
          id: number;
          name: string;
          email: string;
          phone: string;
          companyAddresses: {
            id: number;
            type: CompanyAddressType;
            address: {
              id: number;
              street1: string;
              street2: string;
              city: string;
              state: string;
              postalCode: string;
            };
          }[];
        };
      };
    };
  }[];
};

type GetPurchaseOrderDetailsParams = {
  purchaseOrderId: number;
};

const GetPurchaseOrderDetails = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetPurchaseOrderDetails($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      code
      requestedShipDate
      manufacturerId
      vendor {
        id
        name
      }
      salesOrder {
        id
        code
      }
      createdBy {
        id
        name
      }
      project {
        id
        salesRepAccount {
          id
          name
          spiffCodes {
            id
            vendorId
            code
          }
        }
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            phone
            email
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            phone
            email
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
        fulfillmentContact {
          id
          person {
            id
            firstName
            lastName
            phone
            email
            personAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
          company {
            id
            name
            phone
            email
            companyAddresses {
              id
              type
              address {
                id
                street1
                street2
                city
                state
                postalCode
              }
            }
          }
        }
      }
      lineItems {
        id
        sortIndex
        quantity
        configuration {
          id
          ...GetFullConfigurationCodeFragment
          ...GetFullConfigurationDescriptionFragment
        }
        description
        purchasePrice
      }
    }
  }
`;

function getPersonPrimaryAddress(
  addresses?: {
    id: number;
    type: PersonAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === PersonAddressType.Primary)?.address;
}

function getCompanyPrimaryAddress(
  addresses?: {
    id: number;
    type: CompanyAddressType;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    };
  }[]
):
  | {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
    }
  | undefined {
  return addresses?.find((a) => a.type === CompanyAddressType.Primary)?.address;
}

export function PurchaseOrderPrintable() {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const getPurchaseOrderDetails = useQuery<
    GetPurchaseOrderDetailsResult,
    GetPurchaseOrderDetailsParams
  >(GetPurchaseOrderDetails, {
    fetchPolicy: "cache-and-network",
    variables: { purchaseOrderId: parseInt(purchaseOrderId) },
  });

  if (getPurchaseOrderDetails.loading) {
    return (
      <PortalContainer>
        <Spinner />
      </PortalContainer>
    );
  }

  const purchaseOrder = getPurchaseOrderDetails.data!.purchaseOrders[0];

  const subtotalAmount = purchaseOrder.lineItems.reduce(
    (subtotal, lineItem) =>
      subtotal + lineItem.purchasePrice * lineItem.quantity,
    0
  );

  const spiffCode = purchaseOrder.project.salesRepAccount?.spiffCodes.find(
    (s) => s.vendorId === purchaseOrder.vendor.id
  )?.code;

  return (
    <div className={styles.printoutPage}>
      <div className={styles.printoutPageContent}>
        <Helmet>
          <title>
            {[
              purchaseOrder.code,
              purchaseOrder.project.primaryContact
                ? formatContactName(purchaseOrder.project.primaryContact)
                : undefined,
            ]
              .filter(Boolean)
              .join(" - ")}
          </title>
        </Helmet>

        <Printable
          title={purchaseOrder.code}
          headerColumns={
            <>
              <PrintableHeaderColumn title="Customer Info">
                {purchaseOrder?.project.primaryContact ? (
                  <>
                    {purchaseOrder.project.primaryContact.person && (
                      <PrintableHeaderColumnItem label="Contact">
                        {purchaseOrder.project.primaryContact.person.firstName}
                        &nbsp;
                        {purchaseOrder.project.primaryContact.person.lastName}
                      </PrintableHeaderColumnItem>
                    )}
                    {purchaseOrder.project.primaryContact.company && (
                      <PrintableHeaderColumnItem label="Company">
                        {purchaseOrder.project.primaryContact.company.name}
                      </PrintableHeaderColumnItem>
                    )}
                    <PrintableHeaderColumnItem label="Email">
                      {purchaseOrder.project.primaryContact.person?.email ??
                        purchaseOrder.project.primaryContact.company?.email}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Phone">
                      {purchaseOrder.project.primaryContact.person?.phone ??
                        purchaseOrder.project.primaryContact.company?.phone}
                    </PrintableHeaderColumnItem>
                    <PrintableHeaderColumnItem label="Address">
                      {formatAddress(
                        getPersonPrimaryAddress(
                          purchaseOrder.project.primaryContact.person
                            ?.personAddresses
                        ) ??
                          getCompanyPrimaryAddress(
                            purchaseOrder.project.primaryContact.company
                              ?.companyAddresses
                          )
                      )}
                    </PrintableHeaderColumnItem>
                  </>
                ) : (
                  <PrintableHeaderColumnItem>
                    <span className="text-red-400">
                      Primary contact not specified.{" "}
                      <Link
                        to={`/projects/${purchaseOrder!.project.id}/contacts`}
                      >
                        Fix
                      </Link>
                    </span>
                  </PrintableHeaderColumnItem>
                )}
              </PrintableHeaderColumn>

              <PrintableHeaderColumn title="Shipping Contact">
                <>
                  <PrintableHeaderColumnItem label="Contact">
                    John Smith
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Company">
                    Warehouse of Fixtures
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Email">
                    fulfillments@stlwarehouse.com
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Phone">
                    555-555-5555
                  </PrintableHeaderColumnItem>
                  <PrintableHeaderColumnItem label="Address">
                    2720 Market St.
                    <br />
                    St. Louis, MO, 63103
                    <br />
                  </PrintableHeaderColumnItem>
                </>
              </PrintableHeaderColumn>

              <PrintableHeaderColumn>
                <PrintableHeaderColumnItem label="PO #">
                  {purchaseOrder.code}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Vendor">
                  {purchaseOrder.vendor.name}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Req. ship date">
                  {purchaseOrder.requestedShipDate
                    ? formatDate(purchaseOrder.requestedShipDate)
                    : "-"}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Written by">
                  {formatAccountName(purchaseOrder.createdBy)}
                </PrintableHeaderColumnItem>
                <PrintableHeaderColumnItem label="Sales rep">
                  {purchaseOrder.project.salesRepAccount
                    ? formatAccountName(purchaseOrder.project.salesRepAccount)
                    : "-"}
                </PrintableHeaderColumnItem>
                {spiffCode && (
                  <PrintableHeaderColumnItem label="SPIFF Code">
                    {spiffCode}
                  </PrintableHeaderColumnItem>
                )}
              </PrintableHeaderColumn>
            </>
          }
        >
          <div className="space-y-4 text-xs">
            <h1 className="text-lg uppercase font-bold">
              ATTN: {purchaseOrder.vendor.name}
            </h1>

            <BasicTable>
              <thead>
                <tr>
                  <th style={{ width: 40 }}>#</th>
                  <th style={{ width: 40 }}>QTY</th>
                  <th style={{ width: 120 }}>Code</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {[...purchaseOrder.lineItems]
                  .sort((a, b) => a.sortIndex - b.sortIndex)
                  .map((purchaseOrderLineItem, index) => (
                    <tr key={purchaseOrderLineItem.id}>
                      <td>{index + 1}</td>
                      <td>{purchaseOrderLineItem.quantity}</td>
                      <td>
                        {purchaseOrderLineItem.configuration && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getFullConfigurationCode(
                                purchaseOrderLineItem.configuration
                              ).replaceAll("\n", "<br />"),
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {purchaseOrderLineItem.configuration && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: getFullConfigurationDescription(
                                purchaseOrderLineItem.configuration
                              ).replaceAll("\n", "<br />"),
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {formatPrice(purchaseOrderLineItem.purchasePrice)}
                      </td>
                      <td>
                        {formatPrice(
                          purchaseOrderLineItem.purchasePrice *
                            purchaseOrderLineItem.quantity
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </BasicTable>

            <div className="flex space-x-4">
              <div className="rounded border space-y-1 p-1 flex-1">
                <h4 className="font-medium">Memo</h4>
                {purchaseOrder.salesOrder && (
                  <p>Sales Order #: {purchaseOrder.salesOrder.code}</p>
                )}
              </div>
              <div className="border-2 rounded divide-y w-52">
                <div className="divide-x flex items-center">
                  <div className="w-24 p-1 font-medium">Subtotal</div>
                  <div className="p-1">{formatPrice(subtotalAmount)}</div>
                </div>
                <div className="divide-x flex items-center">
                  <div className="w-24 p-1 font-medium">Tax</div>
                  <div className="p-1">--</div>
                </div>
                <div className="divide-x flex items-center bg-gray-100">
                  <div className="w-24 p-1 font-medium">Grand Total</div>
                  <div className="p-1">{formatPrice(subtotalAmount)}</div>
                </div>
              </div>
            </div>
          </div>
        </Printable>
      </div>
    </div>
  );
}
