import { NonIdealState, Spinner } from "@blueprintjs/core";
import { AddPersonButton } from "./AddPersonButton";
import { CompanyContactItem } from "./CompanyContactItem";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ContactRole,
  UpdateCompanyContactPayload,
} from "../../../types/ApiTypes";

type GetCompanyContactsResult = {
  companies: {
    id: number;
    contacts: {
      id: number;
      person: {
        id: number;
        firstName: string;
        lastName: string;
      };
      role: ContactRole;
    }[];
  }[];
};

const GetCompanyContacts = gql`
  query GetCompanyContacts($companyId: Int!) {
    companies(where: { id: { eq: $companyId } }) {
      id
      contacts {
        id
        person {
          id
          firstName
          lastName
        }
        role
      }
    }
  }
`;

const AddPersonToCompany = gql`
  mutation AddPersonToCompany(
    $personId: Int!
    $companyId: Int!
    $role: ContactRole!
  ) {
    addPersonToCompany(
      personId: $personId
      companyId: $companyId
      role: $role
    ) {
      id
    }
  }
`;

const UpdateCompanyContact = gql`
  mutation UpdateCompanyContact($payload: UpdateCompanyContactPayloadInput!) {
    updateCompanyContact(payload: $payload) {
      id
    }
  }
`;

const RemovePersonFromCompany = gql`
  mutation RemovePersonFromCompany($companyContactId: Int!) {
    removePersonFromCompany(companyContactId: $companyContactId) {
      id
    }
  }
`;

export type CompanyContactListProps = {
  companyId: number;
};

export function CompanyContactList({ companyId }: CompanyContactListProps) {
  const getCompanyContacts = useQuery<
    GetCompanyContactsResult,
    { companyId: number }
  >(GetCompanyContacts, {
    variables: {
      companyId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [addPersonToCompany] = useMutation<
    { addPersonToCompany: { id: number } },
    { companyId: number; personId: number; role: ContactRole }
  >(AddPersonToCompany, {
    refetchQueries: ["GetCompanyContacts"],
  });

  const [updateCompanyContact] = useMutation<
    { updateCompanyContact: { id: number } },
    { payload: UpdateCompanyContactPayload }
  >(UpdateCompanyContact, {
    refetchQueries: ["GetCompanyContacts"],
  });

  const [removePersonFromCompany] = useMutation<
    { removePersonFromCompany: { id: number } },
    { companyContactId: number }
  >(RemovePersonFromCompany, {
    refetchQueries: ["GetCompanyContacts"],
  });

  return (
    <div className="flex flex-col border rounded overflow-hidden">
      <div className="flex-1 max-h-60 overflow-y-auto">
        {getCompanyContacts.loading && (
          <div className="p-20 text-center">
            <Spinner size={20} />
          </div>
        )}
        {!getCompanyContacts.loading &&
          getCompanyContacts.data!.companies[0].contacts.length === 0 && (
            <div className="p-5">
              <NonIdealState icon="user" title="No Contacts" />
            </div>
          )}
        {!getCompanyContacts.loading &&
          getCompanyContacts.data!.companies[0].contacts.length > 0 &&
          getCompanyContacts.data!.companies[0].contacts.map((c) => (
            <CompanyContactItem
              key={c.id}
              contact={c}
              onChange={async (v) => {
                await updateCompanyContact({
                  variables: {
                    payload: {
                      id: c.id!,
                      role: v.role,
                    },
                  },
                });
              }}
              onRemove={async () => {
                await removePersonFromCompany({
                  variables: {
                    companyContactId: c.id,
                  },
                });
              }}
            />
          ))}
      </div>
      <div className="flex border-t items-center justify-between p-1 bg-gray-50">
        <AddPersonButton
          onAddPerson={async (person) => {
            await addPersonToCompany({
              variables: {
                companyId,
                personId: person.id,
                role: ContactRole.Other,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
