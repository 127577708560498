import { MetadataItem } from "../../../types/ApiTypes";
import { FlexTable } from "../Table/FlexTable";
import { useMemo } from "react";
import { PropertyCell } from "./PropertyCell";
import { ValueCell } from "./ValueCell";
import { TableFooter } from "../Table/TableFooter";
import { Button, Intent } from "@blueprintjs/core";

export type MetadataSchemaType = "employee" | "text" | "attachment" | "date";

export type MetadataSchemaItem = {
  label: string;
  type: MetadataSchemaType;
};

export type MetadataEditorProps = {
  metadataItems: MetadataItem[];
  schema: MetadataSchemaItem[];
  onUpdateItem: (item: MetadataItem) => void;
  onCreateItem: (item: MetadataItem) => void;
  onRemoveItem: (item: MetadataItem) => void;
};

export function MetadataEditor({
  metadataItems,
  schema,
  onUpdateItem,
  onCreateItem,
  onRemoveItem,
}: MetadataEditorProps) {
  const existingItemLabels = metadataItems.map((i) => i.key);

  const addItem = () => {
    onCreateItem({
      key: "",
      value: "",
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Property",
        accessor: (a: MetadataItem) => a.key,
        Cell: ({ value, row }) => (
          <PropertyCell
            value={value}
            onChange={(p) => onUpdateItem({ ...row.original, key: p })}
            availableProperties={schema
              .map((s) => s.label)
              .filter((l) => !existingItemLabels.includes(l))}
          />
        ),
        maxWidth: 30,
        className: "with-button",
      },
      {
        Header: "Value",
        accessor: (a: MetadataItem) => a.value,
        Cell: ({ value, row }) => (
          <ValueCell
            value={value}
            schemaType={
              schema.find((s) => s.label === row.original.key)?.type || "text"
            }
            onChange={(p) => onUpdateItem({ ...row.original, value: p })}
          />
        ),
        className: "with-button",
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <Button
            icon="trash"
            intent={Intent.DANGER}
            onClick={() => onRemoveItem(row.original)}
            minimal
            fill
          />
        ),
        maxWidth: 3,
        className: "with-button",
      },
    ],
    []
  );

  return (
    <div>
      <FlexTable columns={columns} data={metadataItems} footer />
      <TableFooter className="p-1">
        <Button icon="add-row-bottom" text="Add Field" onClick={addItem} />
      </TableFooter>
    </div>
  );
}
