import { Drawer, DrawerProps } from "@blueprintjs/core";
import { ConfigureProduct } from "./ConfigureProduct";
import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import { SIFEntry } from "../../../hooks/useDecodeSifLineItems";

type ConfigureProductDrawerProps = DrawerProps & {
  productConfiguration?: EditableProductConfiguration;
  sifEntry?: SIFEntry;
  onSifEntryChange?: (newValue: SIFEntry) => void;
  onProductConfigurationChange: (
    newValue: EditableProductConfiguration
  ) => void;
};

export function ConfigureProductDrawer({
  productConfiguration,
  sifEntry,
  onSifEntryChange,
  onProductConfigurationChange,
  onClose,
  ...drawerProps
}: ConfigureProductDrawerProps) {
  return (
    <Drawer size="25%" onClose={onClose} {...drawerProps}>
      {productConfiguration && (
        <ConfigureProduct
          productConfiguration={productConfiguration}
          sifEntry={sifEntry}
          onSifEntryChange={onSifEntryChange}
          onProductConfigurationChange={onProductConfigurationChange}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
}
