import { gql, useQuery } from "@apollo/client";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Popover, Spinner, Tag } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { process } from "@progress/kendo-data-query";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { ReceiveShipmentDrawer } from "../../components/shared/ReceiveShipmentDialog/ReceiveShipmentDrawer";
import { useState } from "react";

type GetPurchaseOrderReceivingReportResult = {
  purchaseOrderShipments: {
    id: number;
    expectedShipDate?: string;
    expectedArrivalDate?: string;
    actualShipDate?: string;
    actualArrivalDate?: string;
    lineItems: {
      id: number;
      inventoryItems: {
        location?: {
          id: number;
          name: string;
        };
        createdBy: {
          id: number;
          name: string;
        };
      }[];
    }[];
    purchaseOrder: {
      id: number;
      code: string;
      manufacturerId: number;
      vendor: {
        id: number;
        name: string;
      };
      salesOrder: {
        id: number;
        code: string;
        requestedFulfillmentDate?: string;
      };
      project: {
        id: number;
        primaryContact?: FormatContactNameContact;
      };
    };
  }[];
};

const GetPurchaseOrderReceivingReport = gql`
  ${FormatContactNameContactFragment}

  query GetReceivingReport {
    purchaseOrderShipments {
      id
      expectedShipDate
      expectedArrivalDate
      actualShipDate
      actualArrivalDate
      lineItems {
        id
        inventoryItems {
          id
          createdBy {
            id
            name
          }
        }
      }
      purchaseOrder {
        id
        code
        manufacturerId
        vendor {
          id
          name
        }
        salesOrder {
          id
          code
          requestedFulfillmentDate
        }
        project {
          id
          primaryContact {
            id
            ...FormatContactNameContactFragment
          }
        }
      }
    }
  }
`;

export default function ReceivingDashboard() {
  const getPurchaseOrderReceivingReport =
    useQuery<GetPurchaseOrderReceivingReportResult>(
      GetPurchaseOrderReceivingReport,
      { fetchPolicy: "cache-and-network" }
    );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "expectedArrivalDate", dir: "desc" }],
  });

  const [shipmentToReceive, setShipmentToReceive] =
    useState<typeof shipments[0]>();

  const shipments =
    getPurchaseOrderReceivingReport.data?.purchaseOrderShipments.map((s) => ({
      ...s,
      primaryContactName: formatContactName(
        s.purchaseOrder.project.primaryContact
      ),
      requestedFulfillmentDate: s.purchaseOrder.salesOrder
        .requestedFulfillmentDate
        ? new Date(s.purchaseOrder.salesOrder.requestedFulfillmentDate)
        : undefined,
      expectedShipDate: s.expectedShipDate
        ? new Date(s.expectedShipDate)
        : undefined,
      expectedArrivalDate: s.expectedArrivalDate
        ? new Date(s.expectedArrivalDate)
        : undefined,
      actualShipDate: s.actualShipDate ? new Date(s.actualShipDate) : undefined,
      actualArrivalDate: s.actualArrivalDate
        ? new Date(s.actualArrivalDate)
        : undefined,
      numberOfItemsReceived: s.lineItems.reduce(
        (a, b) => a + b.inventoryItems.length,
        0
      ),
      receivedByName: s.lineItems
        .reduce(
          (a, b) => [...a, ...b.inventoryItems.map((i) => i.createdBy.name)],
          [] as string[]
        )
        .filter((v, i, a) => a.indexOf(v) === i)
        .join(", "),
      stockedLocation: s.lineItems
        .reduce(
          (a, b) => [
            ...a,
            ...b.inventoryItems.map((i) => i.location?.name ?? ""),
          ],
          [] as string[]
        )
        .filter((a) => a.length === 0)
        .filter((v, i, a) => a.indexOf(v) === i)
        .join(", "),
    })) ?? [];

  return (
    <>
      <ReceiveShipmentDrawer
        isOpen={!!shipmentToReceive}
        shipmentId={shipmentToReceive?.id}
        onReceived={async () => {
          await getPurchaseOrderReceivingReport.refetch();
          setShipmentToReceive(undefined);
        }}
        onClose={() => setShipmentToReceive(undefined)}
      />

      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Receiving"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getPurchaseOrderReceivingReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(shipments, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="id"
                title="Shipment #"
                width={200}
                cell={(props: GridCellProps) => {
                  const dataItem =
                    props.dataItem as GetPurchaseOrderReceivingReportResult["purchaseOrderShipments"][0];

                  return (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.purchaseOrder.project.id}/purchase-orders/${dataItem.purchaseOrder.id}/shipments`}
                        text={`SHIP-${dataItem.id}`}
                      />
                    </td>
                  );
                }}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="purchaseOrder.salesOrder.code"
                title="SO #"
                width={200}
                cell={(props: GridCellProps) => {
                  const dataItem =
                    props.dataItem as GetPurchaseOrderReceivingReportResult["purchaseOrderShipments"][0];

                  return (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.purchaseOrder.project.id}/sales-orders/${dataItem.purchaseOrder.salesOrder.id}`}
                        text={dataItem.purchaseOrder.salesOrder.code}
                      />
                    </td>
                  );
                }}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="purchaseOrder.code"
                title="PO #"
                width={200}
                cell={(props: GridCellProps) => {
                  const dataItem =
                    props.dataItem as GetPurchaseOrderReceivingReportResult["purchaseOrderShipments"][0];

                  return (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.purchaseOrder.project.id}/purchase-orders/${dataItem.purchaseOrder.id}`}
                        text={dataItem.purchaseOrder.code}
                      />
                    </td>
                  );
                }}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="primaryContactName"
                title="Customer"
                width={250}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="purchaseOrder.vendor.name"
                title="Vendor"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="requestedFulfillmentDate"
                title="Requested Fulfillment Date"
                filter="date"
                format="{0:d}"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="expectedShipDate"
                title="Expected Ship Date"
                filter="date"
                format="{0:d}"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="expectedArrivalDate"
                title="Expected Arrival Date"
                filter="date"
                format="{0:d}"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="actualShipDate"
                title="Actual Ship Date"
                filter="date"
                format="{0:d}"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="actualArrivalDate"
                title="Actual Arrival Date"
                filter="date"
                format="{0:d}"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="numberOfItemsReceived"
                title="# Items Received"
                filter="numeric"
                width={200}
                cell={(props: GridCellProps) => {
                  const dataItem = props.dataItem as typeof shipments[0];

                  return (
                    <td>
                      <div className="flex items-center">
                        <span className="flex-1">
                          {dataItem.numberOfItemsReceived}
                        </span>
                        <Button
                          small
                          text="Receive Shipment"
                          rightIcon="arrow-right"
                          onClick={() => setShipmentToReceive(dataItem)}
                        />
                      </div>
                    </td>
                  );
                }}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="receivedByName"
                title="Received By"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="stockedLocation"
                title="Stocked Location"
                width={200}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
