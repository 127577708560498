import { Drawer, DrawerProps } from "@blueprintjs/core";
import { ConfigureCustomProduct } from "./ConfigureCustomProduct";
import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";

type ConfigureCustomProductDrawerProps = DrawerProps & {
  productConfiguration?: EditableProductConfiguration;
  onProductConfigurationChange: (
    newValue: EditableProductConfiguration
  ) => void;
};

export function ConfigureCustomProductDrawer({
  productConfiguration,
  onProductConfigurationChange,
  onClose,
  ...drawerProps
}: ConfigureCustomProductDrawerProps) {
  return (
    <Drawer size="25%" onClose={onClose} {...drawerProps}>
      {productConfiguration && (
        <ConfigureCustomProduct
          productConfiguration={productConfiguration}
          onProductConfigurationChange={onProductConfigurationChange}
          onClose={onClose}
        />
      )}
    </Drawer>
  );
}
