import { DrawerTitleToolbar } from "../DrawerTitleToolbar/DrawerTitleToolbar";
import {
  Button,
  Classes,
  Collapse,
  Dialog,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
  Spinner,
} from "@blueprintjs/core";
import { PRODUCT_INFO_PRODUCT_FRAGMENT, ProductInfo } from "./ProductInfo";
import {
  PRODUCT_OPTIONS_PRODUCT_FRAGMENT,
  ProductOptions,
} from "./ProductOptions";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import {
  CalculateConfigurationPricePayloadInput,
  CatalogProduct,
  ConfigurationPriceResult,
  ProductAndOptionPricingResult,
} from "../../../types/ApiTypes";
import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import produce from "immer";
import { SuggestedPriceDetails } from "./SuggestedPriceDetails";
import _ from "lodash";
import {
  getFullConfigurationCode,
  getFullEditableConfigurationCode,
  getFullEditableConfigurationDescription,
} from "../../../helpers/CatalogHelper";
import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import { SIFEntry } from "../../../hooks/useDecodeSifLineItems";

const GET_PRODUCT_DETAILS = gql`
  ${PRODUCT_INFO_PRODUCT_FRAGMENT}
  ${PRODUCT_OPTIONS_PRODUCT_FRAGMENT}

  query GetProductDetails($productId: Int!) {
    catalogProducts(where: { id: { eq: $productId } }) {
      id
      code
      vendor {
        id
        freightRules
      }
      ...ProductInfoProductFragment
      ...ProductOptionsProductFragment
    }
  }
`;

const CALCULATE_CONFIGURATION_PRICE = gql`
  query CalculateConfigurationPrice(
    $payload: CalculateConfigurationPricePayloadInput!
  ) {
    calculateConfigurationPrice(payload: $payload) {
      productListPrice
      optionsListPrice
      totalListPrice
      vendorAdjustedListPrice
      suggestedSellPrice
      vendorCost
      grossProfit
      vendorListPriceAdjustment
    }
  }
`;

const GET_CATALOG_PRODUCT_INFO = gql`
  query GetCatalogProductInfo(
    $productId: Int!
    $calculateProductAndOptionPricingPayload: CalculateProductAndOptionPricingPayloadInput
  ) {
    catalogProducts(where: { id: { eq: $productId } }) {
      id
      vendor {
        id
      }
    }
    calculateProductAndOptionPricing(
      payload: $calculateProductAndOptionPricingPayload
    ) {
      totalListPrice
    }
  }
`;

type ConfigureProductProps = {
  productConfiguration: EditableProductConfiguration;
  sifEntry?: SIFEntry;
  onSifEntryChange?: (newValue: SIFEntry) => void;
  onProductConfigurationChange: (
    newValue: EditableProductConfiguration
  ) => void;
  onClose: ((event: SyntheticEvent<HTMLElement, Event>) => void) | undefined;
};

export function ConfigureProduct({
  productConfiguration,
  onSifEntryChange,
  sifEntry,
  onProductConfigurationChange,
  onClose,
}: ConfigureProductProps) {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [optionsOpen, setOptionsOpen] = useState(true);
  const [pricingOpen, setPricingOpen] = useState(true);
  const [freightRulesOpen, setFreightRulesOpen] = useState(true);
  const [confirmConvertOpen, setConfirmConvertOpen] = useState(false);

  const [configuration, setConfiguration] =
    useState<EditableProductConfiguration>({
      ...productConfiguration,
    });

  const catalogOptionIds = configuration
    ? _.flatten(
        configuration.features.map((f) =>
          f.selections
            .filter((s) => !!s.catalogOptionId)
            .map((s) => s.catalogOptionId!)
        )
      )
    : undefined;

  const calculateConfigurationPrice = useQuery<
    { calculateConfigurationPrice: ConfigurationPriceResult },
    { payload: CalculateConfigurationPricePayloadInput }
  >(CALCULATE_CONFIGURATION_PRICE, {
    variables: {
      payload: {
        catalogProductId: configuration.catalogProductId!,
        catalogOptionIds: catalogOptionIds!,
      },
    },
    skip: !configuration.catalogProductId || !catalogOptionIds,
  });

  const calculatedConfigurationPrice =
    calculateConfigurationPrice.data?.calculateConfigurationPrice;

  console.log(configuration);

  const getProductDetails = useQuery<
    { catalogProducts: CatalogProduct[] },
    { productId: number }
  >(GET_PRODUCT_DETAILS, {
    variables: {
      productId: productConfiguration.catalogProductId!,
    },
    skip: configuration.isCustomConfiguration,
  });

  const getCatalogProductInfo = useCallback(
    async (configuration: EditableProductConfiguration) => {
      const response = await apolloClient.query<
        {
          catalogProducts: CatalogProduct[];
          calculateProductAndOptionPricing: ProductAndOptionPricingResult;
        },
        {
          productId: number;
          calculateProductAndOptionPricingPayload: CalculateConfigurationPricePayloadInput;
        }
      >({
        query: GET_CATALOG_PRODUCT_INFO,
        variables: {
          productId: configuration.catalogProductId!,
          calculateProductAndOptionPricingPayload: {
            catalogProductId: configuration.catalogProductId!,
            catalogOptionIds: _.flatten(
              configuration.features.map((f) =>
                f.selections.map((s) => s.catalogOptionId!)
              )
            ).filter((i) => !!i),
          },
        },
      });

      return {
        catalogProduct: response.data.catalogProducts[0],
        totalListPrice:
          response.data.calculateProductAndOptionPricing.totalListPrice,
      };
    },
    [apolloClient]
  );

  const convertToCustomConfiguration = useCallback(async () => {
    const code = getFullEditableConfigurationCode(configuration, sifEntry);
    const description = getFullEditableConfigurationDescription(
      configuration,
      sifEntry
    );
    const { catalogProduct, totalListPrice } = await getCatalogProductInfo(
      configuration
    );

    if (sifEntry) {
      onSifEntryChange!(
        produce(sifEntry, (draft) => {
          draft.decodings.forEach((decoding) =>
            decoding.features.forEach((feature) =>
              feature.unresolvedSelections.forEach(
                (s) => (s.isDismissed = true)
              )
            )
          );
        })
      );
    }

    onProductConfigurationChange({
      ...configuration,
      isCustomConfiguration: true,
      customCode: code,
      customDescription: description,
      customVendorId: catalogProduct.vendor.id,
      customListPrice: totalListPrice,
    });
  }, [configuration, getCatalogProductInfo]);

  const catalogProduct = getProductDetails.data?.catalogProducts[0];

  useEffect(() => {
    if (!catalogProduct) return;

    setConfiguration((s) =>
      produce(s, (draft) => {
        draft.features = catalogProduct.optionGroups.map((og) => {
          const existingFeature = draft.features.find(
            (f) => f.catalogOptionGroupId === og.id && f.selections.length > 0
          );
          return (
            existingFeature ?? {
              catalogOptionGroupId: og.id,
              description: og.description,
              selections: [
                {
                  catalogOptionGroupId: og.id,
                  catalogOptionGroupCode: og.code,
                },
              ],
            }
          );
        });
      })
    );

    setLoading(false);
  }, [catalogProduct]);

  const unresolvedDecodingIssues = !!sifEntry
    ? _.flatten(
        sifEntry.decodings[0].features.map((f) =>
          f.unresolvedSelections.filter((s) => !s.isDismissed)
        )
      )
    : [];

  return (
    <>
      <Dialog
        title="Convert to Custom Configuration"
        isOpen={confirmConvertOpen}
        onClose={() => setConfirmConvertOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Converting this product to a custom configuration will take all
            current option selections and copy them to a new custom
            configuration where you will be able to edit the product's vendor,
            list price, code, and description in a free-form text box.
          </p>
          <p>
            This action cannot be reversed. Are you sure you want to continue?
          </p>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Cancel"
              onClick={() => setConfirmConvertOpen(false)}
            />
            <Button
              intent={Intent.PRIMARY}
              text="Convert to Custom Configuration"
              onClick={async () => {
                await convertToCustomConfiguration();
                setConfirmConvertOpen(false);
              }}
            />
          </div>
        </div>
      </Dialog>

      <DrawerTitleToolbar
        title={
          getProductDetails.loading
            ? "Configure Product"
            : `Configure ${catalogProduct?.code}`
        }
        onClose={onClose}
      >
        <Popover position={Position.BOTTOM} minimal>
          <Button minimal icon="more" className="mr-4" />
          <Menu>
            <MenuItem
              icon="new-object"
              text="Convert to Custom Configuration"
              onClick={() => setConfirmConvertOpen(true)}
            />
          </Menu>
        </Popover>
        <Button
          intent={Intent.PRIMARY}
          text="Save Changes"
          onClick={() => {
            onProductConfigurationChange(configuration);
            if (onClose) onClose(null!);
          }}
        />
      </DrawerTitleToolbar>

      <div className="bg-gray-50 h-full overflow-y-auto">
        {loading && (
          <div className="p-4 flex items-center justify-center">
            <Spinner />
          </div>
        )}

        {!loading && (
          <div className="flex flex-col divide-y mb-20">
            {unresolvedDecodingIssues.length > 0 && (
              <div className="p-2">
                <div className="p-2 rounded bg-red-50 mt-1 flex">
                  <Icon icon="warning-sign" className="text-red-400 mr-2" />
                  <div className="flex-1">
                    <p className="m-0 text-red-600">
                      This line item has{" "}
                      <strong>
                        {unresolvedDecodingIssues.length} SIF Decoding Issues
                      </strong>{" "}
                      which need to be resolved below. If the issues can't be
                      resolved, you can convert this line item to a custom
                      configuration and resolve them manually.
                    </p>
                  </div>
                </div>
              </div>
            )}

            <ProductInfo catalogProduct={catalogProduct!} />

            <div className="px-2 py-1 shadow-sm bg-white flex items-center justify-between">
              <h2 className="text-sm uppercase tracking-wide font-medium text-gray-700">
                Options
              </h2>
              <Button
                icon={optionsOpen ? "chevron-down" : "chevron-right"}
                onClick={() => setOptionsOpen((s) => !s)}
                minimal
                small
              />
            </div>

            <Collapse isOpen={optionsOpen}>
              <ProductOptions
                catalogProduct={catalogProduct!}
                sifEntry={sifEntry}
                onSifEntryChange={onSifEntryChange}
                configuration={configuration}
                setConfiguration={setConfiguration}
              />
              <div className="px-2 mb-4 text-right">
                <Button
                  icon="new-object"
                  text="Convert to Custom Configuration"
                  onClick={() => setConfirmConvertOpen(true)}
                />
              </div>
            </Collapse>

            <div className="px-2 py-1 shadow-sm bg-white flex items-center justify-between">
              <h2 className="text-sm uppercase tracking-wide font-medium text-gray-700">
                Pricing
              </h2>
              <Button
                icon={pricingOpen ? "chevron-down" : "chevron-right"}
                onClick={() => setPricingOpen((s) => !s)}
                minimal
                small
              />
            </div>

            <Collapse isOpen={pricingOpen}>
              {calculateConfigurationPrice.loading ||
              !calculatedConfigurationPrice ? (
                <div className="p-2">
                  <Spinner size={20} />
                </div>
              ) : (
                <SuggestedPriceDetails
                  productListPrice={
                    calculatedConfigurationPrice.productListPrice
                  }
                  optionsListPrice={
                    calculatedConfigurationPrice.optionsListPrice
                  }
                  totalListPrice={calculatedConfigurationPrice.totalListPrice}
                  vendorAdjustedListPrice={
                    calculatedConfigurationPrice.vendorAdjustedListPrice
                  }
                  vendorCost={calculatedConfigurationPrice.vendorCost}
                  grossProfit={calculatedConfigurationPrice.grossProfit}
                  suggestedSalesPrice={
                    calculatedConfigurationPrice.suggestedSellPrice
                  }
                  vendorListPriceAdjustment={
                    calculatedConfigurationPrice.vendorListPriceAdjustment
                  }
                />
              )}
            </Collapse>

            <div className="px-2 py-1 shadow-sm bg-white flex items-center justify-between">
              <h2 className="text-sm uppercase tracking-wide font-medium text-gray-700">
                Freight Rules
              </h2>
              <Button
                icon={freightRulesOpen ? "chevron-down" : "chevron-right"}
                onClick={() => setFreightRulesOpen((s) => !s)}
                minimal
                small
              />
            </div>

            <Collapse isOpen={freightRulesOpen}>
              <div className="p-2">
                <div className="rounded shadow bg-white border border-gray-200 p-2">
                  {(catalogProduct?.vendor.freightRules ?? "").length > 0 ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: catalogProduct!.vendor.freightRules
                          .replaceAll("\n", "<br />")
                          .replaceAll("\\n", "<br />"),
                      }}
                    />
                  ) : (
                    <p className="m-0">No Freight Rules Provided</p>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </div>
    </>
  );
}
