import {
  Button,
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
} from "@blueprintjs/core";
import React from "react";

type DeviantValueWarningProps = {
  readOnly?: boolean;
  isDeviant: boolean;
  label: string;
  expectedValue: string;
  onApplyRecommendedValue: () => void;
};

export function DeviantValueWarning({
  readOnly,
  isDeviant,
  label,
  expectedValue,
  onApplyRecommendedValue,
}: DeviantValueWarningProps) {
  if (!isDeviant) {
    return null;
  }

  return (
    <Popover
      popoverClassName="bg-white p-3"
      interactionKind={PopoverInteractionKind.HOVER}
      position={Position.BOTTOM}
      minimal
    >
      <Button small minimal icon="warning-sign" intent={Intent.WARNING} />
      <div>
        <p>
          The recommended {label} for this product is{" "}
          <span className="p-1 rounded bg-yellow-100 font-bold text-yellow-600">
            {expectedValue}
          </span>
          .
        </p>
        {!readOnly && (
          <Button
            text="Set to Recommended Value"
            onClick={() => onApplyRecommendedValue()}
          />
        )}
      </div>
    </Popover>
  );
}
