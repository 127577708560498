import { gql, useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Icon, Spinner } from "@blueprintjs/core";

const GET_PRODUCT_VENDOR = gql`
  query GetProductVendor($catalogProductId: Int!) {
    catalogProducts(where: { id: { eq: $catalogProductId } }) {
      id
      vendor {
        id
        freightRules
      }
    }
  }
`;

type GetProductVendorResult = {
  catalogProducts: {
    id: string;
    vendor: {
      id: string;
      freightRules?: string;
    };
  }[];
};

const GET_VENDOR = gql`
  query GetProductVendor($vendorId: Int!) {
    vendors(where: { id: { eq: $vendorId } }) {
      id
      freightRules
    }
  }
`;

type GetVendorResult = {
  vendors: {
    id: string;
    freightRules?: string;
  }[];
};

function useFreightRules(
  hasConfiguration: boolean,
  isCustomConfiguration?: boolean,
  customVendorId?: number,
  catalogProductId?: number
) {
  const apolloClient = useApolloClient();
  const [freightRules, setFreightRules] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (hasConfiguration && isCustomConfiguration && customVendorId) {
      apolloClient
        .query<GetVendorResult, { vendorId: number }>({
          query: GET_VENDOR,
          variables: { vendorId: customVendorId },
        })
        .then((result) => setFreightRules(result.data.vendors[0].freightRules))
        .finally(() => setLoading(false));
    } else if (hasConfiguration && !isCustomConfiguration && catalogProductId) {
      apolloClient
        .query<GetProductVendorResult, { catalogProductId: number }>({
          query: GET_PRODUCT_VENDOR,
          variables: { catalogProductId },
        })
        .then((result) =>
          setFreightRules(result.data.catalogProducts[0].vendor.freightRules)
        )
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [isCustomConfiguration, customVendorId, catalogProductId, apolloClient]);

  return { freightRules, loading };
}

type FreightRulesProps = {
  hasConfiguration: boolean;
  isCustomConfiguration?: boolean;
  customVendorId?: number;
  catalogProductId?: number;
};

export function FreightRules({
  hasConfiguration,
  isCustomConfiguration,
  customVendorId,
  catalogProductId,
}: FreightRulesProps) {
  const { freightRules, loading } = useFreightRules(
    hasConfiguration,
    isCustomConfiguration,
    customVendorId,
    catalogProductId
  );

  if (loading) {
    return (
      <div className="p-2 rounded bg-gray-100  flex justify-center">
        <Spinner size={14} />
      </div>
    );
  }

  if (!freightRules) {
    return (
      <div className="p-2 rounded bg-white border flex">
        <Icon icon="box" className="text-gray-400 mr-2" />
        <div className="flex-1">
          <p className="m-0 text-gray-600">
            No freight rules have been specified.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-2 rounded bg-red-50  flex">
      <Icon icon="box" className="text-red-400 mr-2" />
      <div className="flex-1">
        <p
          className="m-0 text-red-600"
          dangerouslySetInnerHTML={{
            __html: freightRules
              .replaceAll("\n", "<br />")
              .replaceAll("\\n", "<br />"),
          }}
        />
      </div>
    </div>
  );
}
