import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router-dom";
import { Account, Contact } from "../../../../types/ApiTypes";
import { Helmet } from "react-helmet";
import React, { useCallback } from "react";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { ProjectSubnavbar } from "../../../../components/projects/[projectId]/ProjectSubnavbar";
import { Button } from "@blueprintjs/core";
import { EODsTable } from "./EODsTable";

const CreateEOD = gql`
  mutation CreateEOD($payload: CreateEODPayloadInput!) {
    createEOD(payload: $payload) {
      id
    }
  }
`;

type CreateEODResult = {
  createEOD: {
    id: string;
  };
};

type CreateEODParams = {
  payload: {
    projectId: number;
    title?: string;
    description?: string;
  };
};

const GetProjectAndEODs = gql`
  query GetProjectAndEODs($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }

    eods(where: { projectId: { eq: $projectId } }) {
      id
      title
      project {
        id
      }
      salesOrder {
        id
        code
        project {
          id
        }
      }
      mostRecentComment {
        id
        message
        createdBy {
          id
          name
        }
        createdAt
      }
      resolvedAt
      resolvedBy {
        id
        name
      }
      createdBy {
        id
        name
      }
    }
  }
`;

type GetProjectAndEODsResult = {
  projects: {
    id: number;
    name: string;
    primaryContact?: Contact;
  }[];

  eods: {
    id: number;
    title: string;
    project?: {
      id: number;
    };
    salesOrder?: {
      id: number;
      code: string;
      project: {
        id: number;
      };
    };
    mostRecentComment?: {
      id: number;
      message: string;
      createdBy: Account;
      createdAt: string;
    };
    resolvedBy?: Account;
    resolvedAt?: string;
    createdBy: Account;
  }[];
};

export default function ProjectEODs() {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();

  const getProjectAndEODs = useQuery<
    GetProjectAndEODsResult,
    { projectId: number }
  >(GetProjectAndEODs, {
    variables: {
      projectId: parseInt(projectId),
    },
    fetchPolicy: "cache-and-network",
  });

  const [createEOD] = useMutation<CreateEODResult, CreateEODParams>(CreateEOD, {
    refetchQueries: [GetProjectAndEODs],
  });

  const newEOD = useCallback(async () => {
    const result = await createEOD({
      variables: {
        payload: {
          projectId: parseInt(projectId),
        },
      },
    });
    history.push(`/eods/${result.data!.createEOD.id}`);
  }, [createEOD]);

  const project = getProjectAndEODs.data?.projects[0];

  return (
    <>
      <Helmet>
        <title>{`EODs - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectAndEODs.loading}
        projectName={project?.name ?? ""}
        projectPrimaryContact={project?.primaryContact}
        projectId={parseInt(projectId)}
        additionalHeaderItems={[
          {
            text: "EODs",
            href: `/projects/${projectId}/eods`,
          },
        ]}
      />

      <ProjectSubnavbar
        title="EODs"
        loading={getProjectAndEODs.loading}
        actions={
          <>
            <Button
              intent="primary"
              text="New EOD"
              icon="add"
              onClick={newEOD}
            />
          </>
        }
      />

      <PortalContainer>
        <EODsTable eods={getProjectAndEODs.data?.eods ?? []} />
      </PortalContainer>
    </>
  );
}
