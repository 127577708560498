import { useCallback, useEffect, useState } from "react";
import { CatalogManufacturer } from "../../../types/ApiTypes";

export function useFavoriteManufacturers() {
  const [favoriteManufacturers, setFavoriteManufacturers] = useState<
    CatalogManufacturer[]
  >([]);

  const loadFavorites = useCallback(() => {
    const favoriteManufacturersJson = window.localStorage.getItem(
      "favorite_manufacturers"
    );
    if (!favoriteManufacturersJson) return;
    setFavoriteManufacturers(
      JSON.parse(favoriteManufacturersJson) as CatalogManufacturer[]
    );
  }, [setFavoriteManufacturers]);

  const saveFavorites = useCallback((favorites) => {
    window.localStorage.setItem(
      "favorite_manufacturers",
      JSON.stringify(favorites)
    );
  }, []);

  const addFavoriteManufacturer = useCallback(
    (manufacturer: CatalogManufacturer) => {
      if (
        favoriteManufacturers.findIndex((m) => m.id === manufacturer.id) > -1
      ) {
        return;
      }

      setFavoriteManufacturers((state) => {
        const newState = [...state, manufacturer];
        saveFavorites(newState);
        return newState;
      });
    },
    [saveFavorites, favoriteManufacturers, setFavoriteManufacturers]
  );

  const removeFavoriteManufacturer = useCallback(
    (manufacturer: CatalogManufacturer) => {
      if (
        favoriteManufacturers.findIndex((m) => m.id === manufacturer.id) === -1
      ) {
        return;
      }

      setFavoriteManufacturers((state) => {
        const newState = state.filter((s) => s.id !== manufacturer.id);
        saveFavorites(newState);
        return newState;
      });
    },
    [saveFavorites, favoriteManufacturers, setFavoriteManufacturers]
  );

  useEffect(() => {
    loadFavorites();
  }, [loadFavorites]);

  return {
    favoriteManufacturers: favoriteManufacturers.sort((a, b) =>
      a.name > b.name ? 1 : -1
    ),
    addFavoriteManufacturer,
    removeFavoriteManufacturer,
  };
}
