import { Button, Classes, Dialog, DialogProps, Icon } from "@blueprintjs/core";
import { useState } from "react";

type ManagerApprovalDialogProps = DialogProps & {
  actionLabel?: string;
  onManagerApprovalGranted: () => void;
};

export function ManagerApprovalDialog({
  actionLabel,
  onManagerApprovalGranted,
  ...dialogProps
}: ManagerApprovalDialogProps) {
  const [code, setCode] = useState("");

  return (
    <Dialog
      title="Manager Approval Required"
      icon={<Icon icon="shield" color="#fab" />}
      {...dialogProps}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onManagerApprovalGranted();
        }}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className="border-2 border-dashed border-red-300 rounded p-4 space-y-4">
            <p className="m-0">
              Manager approval is required {actionLabel ?? "for this action"}.
              To continue, please enter a manager override code below...
            </p>

            <input
              type="password"
              className="p-2 bg-white rounded w-full text-lg text-center focus:ring-2"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter Manager Code"
              autoFocus
            />
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Cancel"
              type="button"
              onClick={() => dialogProps.onClose?.(null!)}
            />
            <Button
              text="Continue"
              intent="primary"
              type="submit"
              disabled={!code}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
