import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import { useEffect } from "react";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import dayjs from "dayjs";
import { PersonContactList } from "../../../components/contacts/people/PersonContactList";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UpdatePersonPayloadInput } from "../../../types/ApiTypes";
import { PersonAddressList } from "../../../components/contacts/people/PersonAddressList";
import { gql, useMutation, useQuery } from "@apollo/client";
import { BasicTable } from "../../../components/shared/Table/BasicTable";
import { EntityLink } from "../../../components/reports/EntityLink";

type GetPersonByIdResult = {
  people: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    updatedAt: string;
    eods: {
      id: number;
      title: string;
    }[];
  }[];
};

const GetPersonById = gql`
  query GetPersonById($id: Int!) {
    people(where: { id: { eq: $id } }) {
      id
      firstName
      lastName
      email
      phone
      updatedAt
      eods {
        id
        title
      }
    }
  }
`;

const UpdatePerson = gql`
  mutation UpdatePerson($payload: UpdatePersonPayloadInput!) {
    updatePerson(payload: $payload) {
      id
    }
  }
`;

const DeletePerson = gql`
  mutation DeletePerson($id: Int!) {
    deletePerson(personId: $id) {
      id
    }
  }
`;

type PersonFormFields = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phoneExtension: string;
};

export type PersonDetailsRouteParams = {
  personId: string;
};

export function PersonDetails() {
  const history = useHistory();
  const { personId } = useParams<PersonDetailsRouteParams>();

  const getPersonById = useQuery<GetPersonByIdResult, { id: number }>(
    GetPersonById,
    {
      variables: {
        id: parseInt(personId),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [updatePerson] = useMutation<
    { updatePerson: { id: number } },
    { payload: UpdatePersonPayloadInput }
  >(UpdatePerson, {
    refetchQueries: ["GetPersonById", "GetPeople"],
  });

  const [deletePerson] = useMutation<
    { deletePerson: { id: number } },
    { id: number }
  >(DeletePerson, {
    refetchQueries: ["GetPersonById", "GetPeople"],
  });

  const formik = useFormik<PersonFormFields>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      phoneExtension: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("First name is required"),
      email: Yup.string()
        .email("Email is invalid")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      phoneExtension: Yup.string(),
    }),
    onSubmit: async (values) => {
      await updatePerson({
        variables: {
          payload: {
            id: parseInt(personId),
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: (
              values.phone.replaceAll("_", "") +
              " " +
              values.phoneExtension.replaceAll("_", "")
            ).trim(),
          },
        },
      });

      DefaultToaster.show({
        message: "Person changes saved",
        intent: "success",
        icon: "tick",
      });
    },
  });

  useEffect(() => {
    if (getPersonById.loading) return;
    const person = getPersonById.data!.people[0];

    formik.resetForm({
      values: {
        firstName: person.firstName ?? "",
        lastName: person.lastName ?? "",
        email: person.email ?? "",
        phone: person.phone.split(" ")[0] ?? "",
        phoneExtension: person.phone.split(" ")[1] ?? "",
      },
    });
  }, [getPersonById.loading, getPersonById.data]);

  const removePerson = async () => {
    await deletePerson();
    history.replace("/contacts/people");
  };

  useEffect(() => {
    if (formik.dirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [formik.dirty]);

  return (
    <>
      <Prompt
        when={formik.dirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      {!getPersonById.loading && (
        <form className="p-5 flex items-start" onSubmit={formik.handleSubmit}>
          <div className="flex flex-1">
            <div className="w-36 h-36 rounded-lg bg-gray-700 flex items-center justify-center">
              <p className="m-0 text-6xl text-white font-semibold">
                {formik.values.firstName.toUpperCase().substring(0, 1)}
              </p>
            </div>

            <div className="flex-1 max-w-lg ml-10">
              <div className="flex">
                <FormGroup label="First Name" className="flex-1 pr-2">
                  <InputGroup
                    id="firstName"
                    name="firstName"
                    large
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                </FormGroup>

                <FormGroup label="Last Name" className="flex-1 pl-2">
                  <InputGroup
                    id="lastName"
                    name="lastName"
                    large
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </FormGroup>
              </div>

              <p className="text-gray-400 mb-5">
                Last updated{" "}
                {dayjs(getPersonById.data!.people[0].updatedAt).format(
                  "MM/DD/YYYY"
                )}
              </p>

              <div className="flex mb-4">
                <FormGroup label="Email" className="flex-1 pr-2">
                  <InputGroup
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    large
                  />
                </FormGroup>

                <FormGroup label="Phone" className="flex-1 pl-2">
                  <ControlGroup fill>
                    <HTMLSelect large className="w-24">
                      <option value="">+1</option>
                    </HTMLSelect>
                    <InputMask
                      id="phone"
                      name="phone"
                      mask={"999-999-9999"}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                    <InputMask
                      id="phoneExtension"
                      name="phoneExtension"
                      mask={"9999"}
                      value={formik.values.phoneExtension}
                      onChange={formik.handleChange}
                      className="w-28"
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                  </ControlGroup>
                </FormGroup>
              </div>

              <FormGroup label="Addresses">
                <PersonAddressList personId={parseInt(personId)} />
              </FormGroup>

              <FormGroup label="Companies">
                <PersonContactList personId={parseInt(personId)} />
              </FormGroup>

              <FormGroup label="EODs">
                <div className="flex flex-col border rounded overflow-hidden">
                  <BasicTable header footer>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getPersonById.data!.people[0].eods.map((eod) => (
                        <tr key={eod.id}>
                          <td>
                            <EntityLink
                              url={`/eods/${eod.id}`}
                              text={`EOD-${eod.id}`}
                            />
                          </td>
                          <td>{eod.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </BasicTable>
                </div>
              </FormGroup>
            </div>
          </div>

          <Button
            intent={Intent.PRIMARY}
            text="Save Changes"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            loading={formik.isSubmitting}
          />
          <Popover position={Position.BOTTOM_RIGHT} minimal>
            <Button icon="more" className="ml-2" minimal />
            <Menu>
              <MenuItem
                text="Delete Person"
                icon="trash"
                intent={Intent.DANGER}
                onClick={removePerson}
              />
            </Menu>
          </Popover>
        </form>
      )}
    </>
  );
}
