import {
  EditableLineItem,
  EditableLineItemGroup,
  EditableProductConfiguration,
  LineItemEditorState,
} from "./LineItemEditorTypes";
import { CreateProductConfigurationPayloadInput } from "../../../../../types/ApiTypes";
import { WritableDraft } from "immer/dist/types/types-external";
import { flatten } from "lodash";

export function recalculateLineItemSortIndex(
  draft: WritableDraft<
    LineItemEditorState<EditableLineItemGroup<EditableLineItem>>
  >
) {
  const allLineItems = flatten(draft.lineItemGroups.map((g) => g.lineItems));
  const unremovedLineItems = allLineItems.filter((li) => !li.isRemoved);
  const removedLineItems = allLineItems.filter((li) => li.isRemoved);

  removedLineItems
    .filter((li) => li.isRemoved)
    .forEach((li) => {
      li.sortIndex = -1;
    });

  unremovedLineItems.forEach((li, i) => {
    li.sortIndex = i;
    li.isModified = true;
  });
}

export function buildCreateProductConfigurationPayload(
  configuration?: EditableProductConfiguration
): CreateProductConfigurationPayloadInput | undefined {
  if (!configuration) return undefined;

  if (configuration.isCustomConfiguration) {
    return {
      isCustomConfiguration: true,
      customCode: configuration.customCode,
      customDescription: configuration.customDescription,
      customListPrice: configuration.customListPrice,
      customVendorId: configuration.customVendorId,
    };
  } else {
    return {
      isCustomConfiguration: false,
      productId: configuration!.catalogProductId!,
      createFeaturePayloads: configuration!.features.map((feature) => ({
        optionGroupId: feature.catalogOptionGroupId,
        createFeatureSelections: feature.selections
          .filter((sel) => !!sel.catalogOptionId && !!sel.catalogOptionCode)
          .map((selection) => ({
            optionGroupId: selection.catalogOptionGroupId,
            optionGroupCode: selection.catalogOptionGroupCode,
            optionId: selection.catalogOptionId!,
            optionCode: selection.catalogOptionCode!,
          })),
      })),
    };
  }
}
