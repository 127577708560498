import React from "react";
import classNames from "classnames";
import styles from "./Table.module.scss";

export type TableFooterProps = {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
};

export function TableFooter({ children, className }: TableFooterProps) {
  return (
    <div className={classNames(styles.tableFooter, className)}>{children}</div>
  );
}
