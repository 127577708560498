import { LineItemGroup } from "./LineItemGroup";
import { Button } from "@blueprintjs/core";
import React from "react";
import {
  EditableLineItem,
  EditableLineItemGroup,
  LineItemEditorActions,
  LineItemEditorState,
} from "./reducers/LineItemEditor/LineItemEditorTypes";
import { ConfigureProductDrawer } from "../ConfigureProduct/ConfigureProductDrawer";
import { ConfigureCustomProductDrawer } from "../ConfigureProduct/ConfigureCustomProductDrawer";
import { applyConfigurationChange } from "./helpers/LineItemEditorHelpers";
import { useApolloClient } from "@apollo/client";

export type LineItemEditorDefaultColumn =
  | "product"
  | "description"
  | "quantity"
  | "vendorListPrice"
  | "discountListPriceMultiplier"
  | "purchasePrice"
  | "grossProfitPercent"
  | "suggestedSellPrice"
  | "actualSellPrice"
  | "tax"
  | "totalSellPrice";

type LineItemEditorProps<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
> = {
  readOnly?: boolean;
  groupsReadOnly?: boolean;
  state: TS;
  dispatch: React.Dispatch<LineItemEditorActions | TA>;
  groupFooterActions?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
    lineItemGroupIndex: number;
  }) => React.ReactNode;
  footerActions?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
  }) => React.ReactNode;
  visibleDefaultColumns?: LineItemEditorDefaultColumn[];
  additionalColumnHeaders?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
  }) => React.ReactNode;
  additionalColumns?: (props: {
    state: TS;
    dispatch: React.Dispatch<LineItemEditorActions | TA>;
    readOnly: boolean;
    lineItemGroupIndex: number;
    lineItemIndex: number;
  }) => React.ReactNode;
  shipToAddressId?: number;
  fulfillmentContactId?: number;
};

export function LineItemEditor<
  TS extends LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  TA
>({
  readOnly = false,
  groupsReadOnly = false,
  state,
  dispatch,
  groupFooterActions,
  footerActions,
  visibleDefaultColumns = [
    "product",
    "description",
    "quantity",
    "vendorListPrice",
    "discountListPriceMultiplier",
    "purchasePrice",
    "grossProfitPercent",
    "suggestedSellPrice",
    "actualSellPrice",
    "tax",
    "totalSellPrice",
  ],
  additionalColumnHeaders,
  additionalColumns,
  shipToAddressId,
  fulfillmentContactId,
}: LineItemEditorProps<TS, TA>) {
  const apolloClient = useApolloClient();

  const FooterActions = footerActions?.({ state, dispatch, readOnly });

  const lineItemBeingEdited = state.editingConfiguration
    ? state.lineItemGroups[state.editingConfiguration.lineItemGroupIndex]
        .lineItems[state.editingConfiguration.lineItemIndex]
    : undefined;

  return (
    <>
      {lineItemBeingEdited?.configuration &&
        !lineItemBeingEdited?.configuration.isCustomConfiguration && (
          <ConfigureProductDrawer
            isOpen
            onClose={() => dispatch({ type: "endEditingConfiguration" })}
            sifEntry={lineItemBeingEdited.sifEntry}
            onSifEntryChange={(newValue) => {
              dispatch({
                type: "setLineItemSifEntry",
                lineItemGroupIndex:
                  state.editingConfiguration!.lineItemGroupIndex,
                lineItemIndex: state.editingConfiguration!.lineItemIndex,
                sifEntry: newValue,
              });
            }}
            productConfiguration={lineItemBeingEdited.configuration}
            onProductConfigurationChange={async (c) => {
              await applyConfigurationChange({
                configuration: c,
                lineItemGroupIndex:
                  state.editingConfiguration!.lineItemGroupIndex,
                lineItemIndex: state.editingConfiguration!.lineItemIndex,
                state,
                dispatch,
                apolloClient,
                shipToAddressId,
                fulfillmentContactId,
              });

              dispatch({ type: "endEditingConfiguration" });
            }}
          />
        )}

      {lineItemBeingEdited?.configuration &&
        lineItemBeingEdited?.configuration.isCustomConfiguration && (
          <ConfigureCustomProductDrawer
            isOpen
            onClose={() => dispatch({ type: "endEditingConfiguration" })}
            productConfiguration={lineItemBeingEdited.configuration}
            onProductConfigurationChange={async (c) => {
              await applyConfigurationChange({
                configuration: c,
                lineItemGroupIndex:
                  state.editingConfiguration!.lineItemGroupIndex,
                lineItemIndex: state.editingConfiguration!.lineItemIndex,
                state,
                dispatch,
                apolloClient,
                shipToAddressId,
                fulfillmentContactId,
              });

              dispatch({ type: "endEditingConfiguration" });
            }}
          />
        )}

      <div className="space-y-2">
        {state.lineItemGroups.map((_, quoteLineItemGroupIndex) => (
          <LineItemGroup<TS, TA>
            visibleDefaultColumns={visibleDefaultColumns}
            state={state}
            dispatch={dispatch}
            lineItemGroupIndex={quoteLineItemGroupIndex}
            readOnly={readOnly}
            groupsReadOnly={groupsReadOnly}
            groupFooterActions={groupFooterActions}
            additionalColumnHeaders={additionalColumnHeaders}
            additionalColumns={additionalColumns}
            fulfillmentContactId={fulfillmentContactId}
            shipToAddressId={shipToAddressId}
          />
        ))}

        <div className="flex items-center space-x-1">
          {!groupsReadOnly && (
            <Button
              icon="add-row-bottom"
              text="Add Line Item Group"
              onClick={() => dispatch({ type: "addLineItemGroup" })}
            />
          )}

          {FooterActions}
        </div>
      </div>
    </>
  );
}
