import { IconName } from "@blueprintjs/core";

export enum TimelineEventCategory {
  Create = "Create",
  Message = "Message",
  Update = "Update",
  Move = "Move",
  Delete = "Delete",
}

export type TimelineEvent = {
  id: number;
  category: TimelineEventCategory;
  employee?: string;
  description: string;
  date: string;
};

export type Timeline = {
  events: TimelineEvent[];
};

export type Employee = {
  id: number;
  firstName: string;
  lastName: string;
};

export type PersonCompanyContact = {
  person: Person;
  company: Company;
  role: string;
};

type PersonAddress = {
  id: number;
  personId: number;
  person: Person;
  addressId: number;
  address: Address;
  type: PersonAddressType;
};

export enum PersonAddressType {
  Primary = "Primary",
  Billing = "Billing",
  Fulfillment = "Fulfillment",
  Other = "Other",
}

export type Person = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  personAddresses: PersonAddress[];
  updatedAt?: string;
  createdAt?: string;
  contacts?: PersonCompanyContact[];
};

export enum CompanyType {
  Corporation = "Corporation",
  University = "University",
  NonProfit = "NonProfit",
  Other = "Other",
}

type CompanyAddress = {
  id: number;
  companyId: number;
  company: Company;
  addressId: number;
  address: Address;
  type: CompanyAddressType;
};

export enum CompanyAddressType {
  Primary = "Primary",
  Billing = "Billing",
  Fulfillment = "Fulfillment",
  Other = "Other",
}

export enum VendorAddressType {
  Primary = "Primary",
  Billing = "Billing",
  Fulfillment = "Fulfillment",
  Other = "Other",
}

export type Company = {
  id: number;
  name: string;
  displayName?: string;
  email: string;
  phone: string;
  companyAddresses: CompanyAddress[];
  parentCompanyId?: number;
  parentCompany?: Company;
  type: CompanyType;
  contacts?: CompanyContact[];
  updatedAt?: string;
  createdAt?: string;
};

export type CompanyContact = {
  id?: number;
  role?: ContactRole;
  companyId: number;
  company?: Company;
  personId: number;
  person?: Person;
};

export type OrderContact = {
  id?: number;
  orderId?: string;
  personId: number;
  companyId: number;
  person?: Person;
  company?: Company;
  role?: number;
};

export type Order = {
  id: number;
  orderNumber: string;
  primaryContact: OrderContact;
  createdAt: string;
};

export type OrderListFilter = {
  startDate?: string;
  endDate?: string;
  customerId?: number;
  salesRepId?: number;
  orderNumber?: string;
  includeArchived?: boolean;
};

export type OrderLineItem = {
  id?: number;
  orderId?: number;
  lineItemGroupId?: number;
  quantity?: number;
  sellPrice?: number;
  salesTax?: number;
  description?: string;
  notes?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type OrderLineItemGroup = {
  id?: number;
  orderId?: number;
  name?: string;
  sortingIndex?: number;
};

export type CatalogSection = {
  id: number;
  description: string;
  parentSectionId?: number;
};

export type ProductSellPriceMultiplier = {
  id: number;
  productId: number;
  product: CatalogProduct;
  defaultGrossProfit: number;
  discount: number;
  sellPriceMultiplier: number;
};

export type Catalog = {
  id: string;
  name: string;
  manufacturer: CatalogManufacturer;
};

export type CatalogProduct = {
  id: number;
  code: string;
  description: string;
  listPrice: number;
  defaultPrice: ProductPrice;
  sellPriceMultiplier: ProductSellPriceMultiplier;
  catalog?: Catalog;
  vendor: Vendor;
  taxCode: string;
  optionGroups: CatalogOptionGroup[];
};

export type ProductPrice = {
  id: number;
  zone: string;
  amount: number;
  productId: number;
};

export type CatalogOption = {
  id: number;
  code: string;
  isEnabled: boolean;
  description: string;
  price: number;
  defaultPrice?: CatalogOptionPrice;
  optionGroups: CatalogOptionGroup[];
  hasChildren: boolean;
};

export type CatalogOptionPrice = {
  id: number;
  amount: number;
  zone: string;
};

export type CatalogOptionGroup = {
  id: number;
  code: string;
  isEnabled: boolean;
  description: string;
  options: CatalogOption[];
  hasChildren: boolean;
};

export type CatalogManufacturer = {
  id: number;
  code: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export type Vendor = {
  id: number;
  name: string;
  listPriceAdjustment: number;
  freightRules: string;
  pricingZone: string;
  listPriceMultiplier: number;
  grossProfitPercent: number;
  catalogManufacturerId: number;
  notes: string;
};

export type CreateOrderRequest = {
  primaryContactPersonId: number;
  primaryContactCompanyId: number;
  salespersonEmployeeId: number;
};

export type Contact = {
  id: number;
  personId: number;
  person: Person;
  companyId: number;
  company: Company;
  createdAt: string;
  updatedAt: string;
};

export enum ContactRole {
  Primary = "Primary",
  Billing = "Billing",
  Fulfillment = "Fulfillment",
  Other = "Other",
  VendorSubmission = "VendorSubmission",
}

export type CreateProjectContactPayloadInput = {
  projectId: number;
  personId: number;
  companyId?: number;
  role: ContactRole;
};

export type DeleteProjectContactPayloadInput = {
  contactId: number;
};

export type ProjectContact = {
  id: number;
  role: ContactRole;
  personId: number;
  person: Person;
  companyId: number;
  company: Company;
};

export type Project = {
  id: number;
  name: string;
  primaryContactId?: number;
  primaryContact?: Contact;
  billingContactId?: number;
  billingContact?: Contact;
  fulfillmentContactId?: number;
  fulfillmentContact?: Contact;
  salesRepAccountId?: number;
  salesRepAccount: Account;
  designerAccountId?: number;
  designerAccount: Account;
  customerServiceRepAccountId?: number;
  customerServiceRepAccount: Account;
  shipToAddressId?: number;
  shipToAddress?: Address;
  billingAddressId?: number;
  billingAddress?: Address;
  taxOriginAddressId?: number;
  taxOriginAddress?: Address;
  taxDestinationAddressId?: number;
  taxDestinationAddress?: Address;
  createdAt: string;
  updatedAt: string;
  attachments: Attachment[];
};

export type QuoteVersionGroup = {
  id?: number;
  name: string;
  projectId: number;
  hasConvertedQuote?: boolean;
  createdAt?: string;
  updatedAt?: string;
  quotes?: Quote[];
  project?: Project;
};

export type Quote = {
  id?: number;
  code?: string;
  name: string;
  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;
  salesOrder?: SalesOrder;
  quoteVersionGroupId: number;
  quoteVersionGroup?: QuoteVersionGroup;
  quickbooksEstimateId?: string;
  createdAt?: string;
  updatedAt?: string;
  conversionRequirements?: ConversionRequirement[];
  quoteLineItemGroups?: QuoteLineItemGroup[];
};

export enum SalesOrderStatus {
  Open = "Open",
  ClosedWin = "ClosedWin",
  ClosedLoss = "ClosedLoss",
}

export type SalesOrder = {
  id?: number;
  status: SalesOrderStatus;
  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;
  name: string;
  code: string;

  quickbooksSalesOrderId: string;
  totalAmount: number;
  purchaseOrders: PurchaseOrder[];
  salesOrderLineItemGroups?: SalesOrderLineItemGroup[];
  projectId: number;
  project: Project;
  quoteId: number;
  quote: Quote;
  createdAt?: string;
  updatedAt?: string;
};

export type SalesOrderReport = {
  id: number;
  code: string;
  projectId: number;
  quoteId: number;
  quoteCode: string;
  status: SalesOrderStatus;
  totalAmount: number;
  totalQuantity: number;
  conversionDate: string;
  installationDate: string;
  preparationDate: string;
  createdAt: string;
  eODs: {
    id: number;
    title: string;
    resolvedAt: string;
    createdBy: {
      name: string;
    };
    projectId: number;
  }[];
  primaryContactPersonId?: number;
  primaryContactPersonName?: string;
  primaryContactCompanyId?: number;
  primaryContactCompanyName?: string;
  billingContactPersonId?: number;
  billingContactPersonName?: string;
  billingContactCompanyId?: number;
  billingContactCompanyName?: string;
  fulfillmentContactPersonId?: number;
  fulfillmentContactPersonName?: string;
  fulfillmentContactCompanyId?: number;
  fulfillmentContactCompanyName?: string;
  salesRepName?: string;
  designerName?: string;
  customerServiceRepName?: string;
};

export type QuoteLineItemGroup = {
  id?: number;
  sortIndex: number;
  name: string;
  quoteId: number;
  createdAt?: string;
  updatedAt?: string;
  quoteLineItems?: QuoteLineItem[];
};

export type QuoteLineItem = {
  id?: number;
  sortIndex: number;
  description: string;
  internalDescription: string;
  quantity: number;
  discountingStructure: string;
  listPriceMultiplier: number;
  grossProfitPercent: number;
  sellPrice: number;
  quoteLineItemGroupId?: number;
  configurationId?: number;
  configuration?: ProductConfiguration;
  holdId?: number;
  hold?: InventoryItemHold;
  claim?: InventoryItemClaim;
  taxAmount: number;
  taxCode?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type ProductConfiguration = {
  id: number;
  code: string;
  complete: boolean;
  catalogProductId?: number;
  catalogProduct?: CatalogProduct;
  features: ProductFeature[];
  productAndOptionPricing: ProductAndOptionPricingResult;
  isCustomConfiguration: boolean;
  customVendorId?: number;
  customVendor?: Vendor;
  customCode?: string;
  customDescription?: string;
  customListPrice?: number;
  vendor: Vendor;
  createdAt: string;
  updatedAt: string;
};

export type ProductFeature = {
  id: number;
  complete: boolean;
  configurationId: number;
  configuration: ProductConfiguration;
  catalogOptionGroupId: number;
  catalogOptionGroup: CatalogOptionGroup;
  selections: ProductFeatureSelection[];
};

export type ProductFeatureSelection = {
  id: number;
  featureId: number;
  feature: ProductFeature;
  catalogOptionGroupId: number;
  catalogOptionId: number;
  catalogOptionGroup: CatalogOptionGroup;
  catalogOption: CatalogOption;
  sortOrder: number;
  createdAt: string;
  updatedAt: string;
  createdById: number;
};

export enum QuoteStatus {
  Open = "Open",
  ClosedAccepted = "ClosedAccepted",
  ClosedRejected = "ClosedRejected",
  ClosedRetracted = "ClosedRetracted",
}

export type QuoteReportItem = {
  id: number;
  projectId: number;
  code: string;
  primaryContactPersonId?: number;
  primaryContactPersonName?: string;
  primaryContactCompanyId?: number;
  primaryContactCompanyName?: string;
  billingContactPersonId?: number;
  billingContactPersonName?: string;
  billingContactCompanyId?: number;
  billingContactCompanyName?: string;
  fulfillmentContactPersonId?: number;
  fulfillmentContactPersonName?: string;
  fulfillmentContactCompanyId?: number;
  fulfillmentContactCompanyName?: string;
  totalQuantity: number;
  totalAmount: number;
  salesRepName?: string;
  designerName?: string;
  customerServiceRepName?: string;
  createdAt: string;
};

export type DateRangeFilter = {
  min?: Date;
  max?: Date;
};

export type IntRangeFilter = {
  min?: number;
  max?: number;
};

export type QuoteReportFilterInput = {
  id?: number;
  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
  billingContactPersonId?: number;
  billingContactCompanyId?: number;
  fulfillmentContactPersonId?: number;
  fulfillmentContactCompanyId?: number;
  createdAt?: DateRangeFilter;
  totalAmount?: IntRangeFilter;
};

export type SortDirection = "ASC" | "DESC";

export type QuoteReportSortInput = {
  id?: SortDirection;
  primaryContactPersonName?: SortDirection;
  primaryContactCompanyName?: SortDirection;
  billingContactPersonName?: SortDirection;
  billingContactCompanyName?: SortDirection;
  fulfillmentContactPersonName?: SortDirection;
  fulfillmentContactCompanyName?: SortDirection;
  totalQuantity?: SortDirection;
  totalAmount?: SortDirection;
};

export type QuoteLineItemOptionSelection = {
  id?: number;
  quoteLineItemId?: number;
  catalogOptionGroupId: number;
  catalogOptionId: number;
  catalogOption?: CatalogOption;
  catalogOptionGroup?: CatalogOptionGroup;
};

export type SalesOrderLineItemGroup = {
  id?: number;
  sortIndex: number;
  name: string;
  salesOrderId: number;
  salesOrder?: SalesOrder;
  salesOrderLineItems?: SalesOrderLineItem[];
  createdAt?: string;
  updatedAt?: string;
};

export type SalesOrderLineItem = {
  id?: number;
  sortIndex: number;
  description: string;
  quantity: number;
  discountingStructure: string;
  listPriceMultiplier: number;
  grossProfitPercent: number;
  sellPrice: number;
  salesOrderLineItemGroupId?: number;
  salesOrderLineItemGroup?: SalesOrderLineItemGroup;
  catalogProductId?: number;
  catalogProduct?: CatalogProduct;
  catalogOptionSelections: SalesOrderLineItemOptionSelection[];
  claimId?: number;
  claim?: InventoryItemClaim;
  configurationId?: number;
  configuration?: ProductConfiguration;
  inventoryItemId?: number;
  taxAmount: number;
  taxCode?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type SalesOrderLineItemOptionSelection = {
  id?: number;
  salesOrderLineItemId?: number;
  catalogOptionGroupId: number;
  catalogOptionId: number;
  catalogOption?: CatalogOption;
};

export type PurchaseOrderLineItemOptionSelection = {
  id?: number;
  purchaseOrderLineItemId?: number;
  catalogOptionGroupId: number;
  catalogOptionId: number;
  catalogOption?: CatalogOption;
};

export enum InventoryItemInspectionStatus {
  Uninspected = "Uninspected",
  NoDamage = "NoDamage",
  MinorDamage = "MinorDamage",
  MajorDamage = "MajorDamage",
}

export type InventoryItemReport = {
  id: number;
  used: boolean;
  quantity: number;
  quantityHeld: number;
  listPrice: number;
  createdAt: string;
  inspectionStatus: InventoryItemInspectionStatus;
  location: Bay;
  catalogProduct: CatalogProduct;
  manufacturerName: string;
  modelName: string;
};

export type InventoryItem = {
  id: number;
  used: boolean;
  listPrice: number;
  purchaseOrderShipmentLineItemId: number;
  purchaseOrderShipmentLineItem: PurchaseOrderShipmentLineItem;
  mostRecentInspectionStatus: InventoryItemInspectionStatus;
  inventoryItemLabels: InventoryItemLabel[];
  inventoryItemAttachments: InventoryItemAttachment[];
  location?: Bay;
  createdAt: string;
  updatedAt: string;
  configuration: ProductConfiguration;
};

export type InventoryItemAttachment = {
  id: number;
  attachmentId: number;
  attachment: Attachment;
  inventoryItemId: number;
  inventoryItem: InventoryItem;
};

export type InventoryItemHold = {
  id: number;
  itemId: number;
  item: InventoryItem;
  quantity: number;
  expiryDate: string;
};

export type InventoryItemClaim = {
  id: number;
  itemId: number;
  item: InventoryItem;
  quantity: number;
};

export type InventoryItemLabel = {
  id: number;
  inventoryItemId: number;
  inventoryItem: InventoryItem;
  sequenceNumber: number;
  totalLabelsInSequence: number;
  numberOfItemsInPackage: number;
  createdAt: string;
};

export type ReceivingLogLineItem = {
  id: number;
  receivingLogId: number;
  purchaseOrderLineItemId: number;
  quantityReceived: number;
  quantityExpected: number;
  notes: string;
  createdAt: string;
};

export type ReceivingLog = {
  id: number;
  purchaseOrderId: number;
  lineItems?: ReceivingLogLineItem[];
  createdAt: number;
};

export type CreateReceivingLogLineItemDto = {
  purchaseOrderLineItemId: number;
  quantityReceived: number;
  quantityExpected: number;
  notes: string;
};

export type CreateReceivingLogDto = {
  purchaseOrderId: number;
  lineItems?: CreateReceivingLogLineItemDto[];
};

export type PurchaseOrder = {
  receivingLog: ReceivingLog;
  id: number;
  code: string;
  requestedShipDate?: string;
  projectId?: number;
  project?: Project;
  manufacturerId?: number;
  manufacturer?: CatalogManufacturer;
  vendor?: Vendor;
  personId?: number;
  person?: Person;
  companyId?: number;
  company: Company;
  mostRecentSubmission?: PurchaseOrderSubmission;
  purchaseOrderSubmission?: PurchaseOrderSubmission;
  purchaseOrderConfirmation?: PurchaseOrderConfirmation;
  estimatedShipDateAcknowledgement?: EstimatedShipDateAcknowledgement;
  actualShipDateAcknowledgement?: ActualShipDateAcknowledgement;
  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;
  salesOrderId?: number;
  salesOrder?: SalesOrder;
  createdAt?: string;
  updatedAt?: string;
  inventoryItems: InventoryItem[];
  purchaseOrderShipments: PurchaseOrderShipment[];
  lineItems: PurchaseOrderLineItem[];
  unshippedLineItems: PurchaseOrderUnshippedLineItem[];
  quickbooksPurchaseOrderId?: string;
};

export type PurchaseOrderLineItem = {
  id?: number;
  sortIndex: number;
  description: string;
  quantity: number;
  listPriceMultiplier: number;
  purchasePrice: number;
  purchaseOrderId: number;
  salesOrderLineItemId?: number;
  salesOrderLineItem?: SalesOrderLineItem;
  inventoryItemId?: number;
  createdAt?: string;
  updatedAt?: string;
  inventoryItems?: InventoryItem[];
  shipments?: PurchaseOrderShipment[];
  configuration: ProductConfiguration;
  purchaseOrder: PurchaseOrder;
};

export type PurchaseOrderUnshippedLineItem = {
  id?: number;
  configuration: ProductConfiguration;
  description: string;
  quantityOrdered: number;
  quantityShipped: number;
  purchaseOrderId: number;
  purchaseOrderLineItemId: number;
};

export type PurchaseOrderShipment = {
  id: number;
  purchaseOrderId: number;
  purchaseOrder: PurchaseOrder;
  trackingNumber?: string;
  expectedShipDate?: string;
  actualShipDate?: string;
  expectedArrivalDate?: string;
  actualArrivalDate?: string;
  purchaseOrderShipmentAttachments: PurchaseOrderShipmentAttachment[];
  notes: PurchaseOrderShipmentNote[];
  inventoryItems?: InventoryItem[];
  lineItems: PurchaseOrderShipmentLineItem[];
};

export type PurchaseOrderShipmentAttachment = {
  id: number;
  purchaseOrderShipmentId: number;
  purchaseOrderShipment: PurchaseOrderShipment;
  attachmentId: number;
  attachment: Attachment;
};

export type PurchaseOrderShipmentNote = {
  id: number;
  note: string;
  purchaseOrderShipmentId: number;
  createdAt: string;
};

export type PurchaseOrderShipmentLineItem = {
  id: number;
  purchaseOrderShipmentId: number;
  purchaseOrderShipment: PurchaseOrderShipment;
  purchaseOrderLineItemId: number;
  purchaseOrderLineItem: PurchaseOrderLineItem;
  inventoryItems: InventoryItem[];
  quantity: number;
};

export type Bay = {
  id: number;
  name: string;
};

export type Attachment = {
  id?: number;
  name: string;
  projectId?: number;
  contentType: string;
  createdAt?: string;
  updatedAt?: string;
};

export type CreateProjectAttachment = {
  attachment: Attachment;
  uploadUrl: string;
};

export type ConversionRequirement = {
  id: number;
  title: string;
  description: string;
  notes: string;
  quoteId: number;
  conversionRequirementAttachments: ConversionRequirementAttachment[];
  completedAt?: string;
  createdAt: string;
  updatedAt: string;
};

export type ConversionRequirementAttachment = {
  id: number;
  attachmentId: number;
  attachment: Attachment;
  conversionRequirementId: number;
  conversionRequirement: ConversionRequirement;
};

export enum MetadataItemType {
  PurchaseOrder = "PurchaseOrder",
  Project = "Project",
  QuoteGroup = "QuoteGroup",
}

export type MetadataItem = {
  id?: number;
  entityId?: number;
  key?: string;
  value?: string;
  type?: MetadataItemType;
  createdAt?: string;
  updatedAt?: string;
};

export type Address = {
  id?: number;
  street1: string;
  street2: string;
  city: string;
  state: string;
  postalCode: string;
  createdAt?: string;
  updatedAt?: string;
};

export enum PurchaseOrderSubmissionMethod {
  Email = "Email",
  Fax = "Fax",
  Phone = "Phone",
  Other = "Other",
}

export enum PurchaseOrderSubmissionResult {
  ErrorWhenSending = "ErrorWhenSending",
  Confirmed = "Confirmed",
  ChangesNeeded = "ChangesNeeded",
}

export type PurchaseOrderSubmission = {
  id?: number;
  purchaseOrderId: number;
  submissionMethod: number;
  submissionDestination: string;
  result?: PurchaseOrderSubmissionResult;
  notes: string;
  createdAt?: string;
  resultReceivedAt?: string;
  submissionAttachmentId?: number;
  submissionAttachment?: Attachment;
  confirmationAttachmentId?: number;
  confirmationAttachment?: Attachment;
};

export enum PurchaseOrderConfirmationStatus {
  Confirmed = "Confirmed",
  ReceivedWithEditsNeeded = "ReceivedWithEditsNeeded",
  Received = "Received",
}

export type PurchaseOrderConfirmation = {
  id?: number;
  purchaseOrderId: number;
  status: PurchaseOrderConfirmationStatus;
  confirmationPrintoutId?: number;
  notes: string;
  createdAt?: string;
  updatedAt?: string;
};

export type PurchaseOrderInventoryItem = {
  id: number;
  purchaseOrderShipmentLineItemId: number;
  purchaseOrderShipmentLineItem: PurchaseOrderShipmentLineItem;
  mostRecentInspectionStatus: InventoryItemInspectionStatus;
  location?: Bay;
  createdAt: string;
  deletedAt: string;
};

export type EstimatedShipDateAcknowledgementLineItem = {
  id: number;
  estimatedShipDateAcknowledgementId: number;
  purchaseOrderLineItemId: number;
  quantity: number;
  estimatedShipDate?: string;
  notes: string;
  createdAt: string;
};

export type EstimatedShipDateAcknowledgement = {
  id: number;
  purchaseOrderId: number;
  lineItems?: EstimatedShipDateAcknowledgementLineItem[];
  createdAt: number;
};

export type CreateEstimatedShipDateAcknowledgementLineItemDto = {
  purchaseOrderLineItemId: number;
  quantity: number;
  estimatedShipDate?: string;
  notes: string;
};

export type CreateEstimatedShipDateAcknowledgementDto = {
  purchaseOrderId: number;
  lineItems: CreateEstimatedShipDateAcknowledgementLineItemDto[];
};

export type ActualShipDateAcknowledgementLineItem = {
  id: number;
  actualShipDateAcknowledgementId: number;
  purchaseOrderLineItemId: number;
  quantity: number;
  actualShipDate?: string;
  notes: string;
  createdAt: string;
};

export type ActualShipDateAcknowledgement = {
  id: number;
  purchaseOrderId: number;
  lineItems?: ActualShipDateAcknowledgementLineItem[];
  createdAt: number;
};

export type CreateActualShipDateAcknowledgementLineItemDto = {
  purchaseOrderLineItemId: number;
  quantity: number;
  actualShipDate?: string;
  notes: string;
};

export type CreateActualShipDateAcknowledgementDto = {
  purchaseOrderId: number;
  lineItems: CreateActualShipDateAcknowledgementLineItemDto[];
};

export type UpdateConversionRequirementInput = {
  id: number;
  notes?: string;
  completed?: boolean;
};

export type UpdatePurchaseOrderShipmentInput = {
  id: number;
  trackingNumber?: string;
  purchaseOrderId?: number;
  expectedShipDate?: string;
  actualShipDate?: string;
  expectedArrivalDate?: string;
  actualArrivalDate?: string;
};

export type InventoryItemInspection = {
  id: number;
  inventoryItemId: number;
  inventoryItem: InventoryItem;
  status: InventoryItemInspectionStatus;
  notes: string;
  createdAt: string;
};

export type UpdateQuoteLineItemPayloadInput = {
  id: number;
  sortIndex?: number;
  quoteLineItemGroupId?: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  listPriceMultiplier?: number;
  grossProfitPercent?: number;
  sellPrice?: number;
  inventoryItemId?: number;
  taxCode?: string;
  configuration?: CreateProductConfigurationPayloadInput;
};

export type CreateProductConfigurationSelectionPayloadInput = {
  optionGroupId: number;
  optionId: number;
  indexOfParent: number;
};

export type CreateProductConfigurationPayloadInput = {
  isCustomConfiguration: boolean;
  customVendorId?: number;
  customCode?: string;
  customDescription?: string;
  customListPrice?: number;
  productId?: number;
  createFeaturePayloads?: CreateProductFeaturePayload[];
};

export type CreateProductFeaturePayload = {
  optionGroupId: number;
  createFeatureSelections: CreateFeatureSelectionPayload[];
};

export type CreateFeatureSelectionPayload = {
  optionGroupId: number;
  optionGroupCode: string;
  optionId: number;
  optionCode: string;
};

export type CreateQuoteLineItemPayloadInput = {
  sortIndex: number;
  quoteLineItemGroupId: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  listPriceMultiplier?: number;
  grossProfitPercent?: number;
  sellPrice?: number;
  inventoryItemId?: number;
  taxCode?: string;
  configuration?: CreateProductConfigurationPayloadInput;
};

export type CreateQuoteLineItemsPayloadInput = {
  quoteLineItemGroupId: number;
  lineItems: {
    taxCode?: string;
    description?: string;
    quantity?: number;
    sellPrice?: number;
    catalogProductId?: number;
    catalogProductCode?: string;
    inventoryItemId?: number;
    options: { key: string; value: string }[];
  }[];
};

export type UpdateSalesOrderLineItemPayloadInput = {
  id: number;
  sortIndex?: number;
  salesOrderLineItemGroupId?: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  discountingStructure?: string;
  listPriceMultiplier?: number;
  grossProfitPercent?: number;
  sellPrice?: number;
  inventoryItemId?: number;
  taxCode?: string;
  configuration?: CreateProductConfigurationPayloadInput;
};

export type CreateSalesOrderLineItemPayloadInput = {
  salesOrderLineItemGroupId: number;
  sortIndex: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  discountingStructure?: string;
  listPriceMultiplier: number;
  grossProfitPercent: number;
  sellPrice: number;
  taxCode?: string;
  configuration?: CreateProductConfigurationPayloadInput;
  inventoryItemId?: number;
};

export type UpdatePurchaseOrderLineItemPayloadInput = {
  id: number;
  sortIndex?: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  discountingStructure?: string;
  listPriceMultiplier?: number;
  purchasePrice?: number;
  configuration?: CreateProductConfigurationPayloadInput;
};

export type CreatePurchaseOrderLineItemPayloadInput = {
  sortIndex: number;
  description?: string;
  internalDescription?: string;
  quantity?: number;
  discountingStructure?: string;
  listPriceMultiplier: number;
  purchasePrice: number;
  configuration?: CreateProductConfigurationPayloadInput;
};

export enum PurchaseOrderReportShipmentStatus {
  AwaitingInfo = "AwaitingInfo",
  ExpectedToShip = "ExpectedToShip",
  Shipped = "Shipped",
  ExpectedToArrive = "ExpectedToArrive",
  Arrived = "Arrived",
}

export type PurchaseOrderReportItem = {
  id: number;
  orderNumber: string;
  vendor: string;
  customerPersonName: string;
  customerCompanyName: string;
  quantity: number;
  totalAmount: number;
  receivedShipments: number;
  totalShipments: number;
  shipmentStatus: PurchaseOrderReportShipmentStatus;
  shipmentStatusDate: string;
  submissionDate: string;
  submissionResult: PurchaseOrderSubmissionResult;
  submissionResultDate: string;
  createdAt: string;
};

export type CreatePurchaseOrderPayloadInput = {
  manufacturerId?: number;
  personId?: number;
  companyId?: number;
};

export type CreateProjectPayloadInput = {
  name: string;
  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
};

export type UpdateProjectPayloadInput = {
  projectId: number;
  name?: string;
  primaryContactId?: number;
  billingContactId?: number;
  fulfillmentContactId?: number;
  salesRepAccountId?: number;
  designerAccountId?: number;
  customerServiceRepAccountId?: number;
  shipToAddressId?: number;
  billingAddressId?: number;
  taxOriginAddressId?: number;
  taxDestinationAddressId?: number;
};

export type CreatePersonPayloadInput = {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
};

export type CreateCompanyPayloadInput = {
  name: string;
  email?: string;
  phone?: string;
  type?: CompanyType;
  defaultSalesRepId?: number;
  defaultDesignerId?: number;
  defaultCustomerServiceRepId?: number;
};

export type CreateVendorPayloadInput = {
  name: string;
  email?: string;
  phone?: string;
  type?: CompanyType;
};

export type UpdateCompanyPayloadInput = {
  id: number;
  name?: string;
  email?: string;
  phone?: string;
  type?: CompanyType;
  parentCompanyId?: number;
  defaultSalesRepId?: number;
  defaultDesignerId?: number;
  defaultCustomerServiceRepId?: number;
};

export type UpdatePersonPayloadInput = {
  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
};

export type CreateDraftPayloadInput = {
  entityId?: number;
  type: string;
  title: string;
  icon: IconName;
  data: string;
};

export type UpdateDraftPayloadInput = {
  id: number;
  entityId?: number;
  type?: string;
  title?: string;
  icon?: IconName;
  data?: string;
};

export type DraftType =
  | "edit-quote"
  | "edit-sales-order"
  | "edit-purchase-order"
  | "create-quote";

export type Draft = {
  id: number;
  entityId?: number;
  type: DraftType;
  title: string;
  icon: IconName;
  createdAt: string;
  updatedAt: string;
  data: string;
  createdById: number;
};

export type CreateQuoteLineItemGroupPayloadInput = {
  name: string;
  sortIndex: number;
  temporaryId: number;
};

export type UpdateQuoteLineItemGroupPayloadInput = {
  sortIndex?: number;
  quoteLineItemGroupId: number;
  name?: string;
};

export type CommitQuoteDraftPayloadInput = {
  quoteId: number;

  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
  billingAddressId?: number;
  shipToAddressId?: number;
  salesRepAccountId?: number;

  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;

  createQuoteLineItemPayloads: CreateQuoteLineItemPayloadInput[];
  updateQuoteLineItemPayloads: UpdateQuoteLineItemPayloadInput[];
  deleteQuoteLineItemPayloads: number[];

  createQuoteLineItemGroupPayloads: CreateQuoteLineItemGroupPayloadInput[];
  updateQuoteLineItemGroupPayloads: UpdateQuoteLineItemGroupPayloadInput[];
  deleteQuoteLineItemGroupPayloads: number[];
};

export type CommitCreateQuoteDraftPayloadInput = {
  quoteName: string;
  salesRepAccountId?: number;
  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
  billingAddressId?: number;
  fulfillmentAddressId?: number;
  createQuoteLineItemPayloads: CreateQuoteLineItemPayloadInput[];
  createQuoteLineItemGroupPayloads: CreateQuoteLineItemGroupPayloadInput[];
  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;
};

export type CommitQuoteDraftResultPayload = {
  createdQuoteLineItems: QuoteLineItem[];
  updatedQuoteLineItems: QuoteLineItem[];
  deletedQuoteLineItems: QuoteLineItem[];

  createdQuoteLineItemGroups: QuoteLineItemGroup[];
  updatedQuoteLineItemGroups: QuoteLineItemGroup[];
  deletedQuoteLineItemGroups: QuoteLineItemGroup[];
};

export type CommitCreateQuoteDraftResultPayload = {
  createdProject: Project;
  createdQuoteVersionGroup: QuoteVersionGroup;
  createdQuote: Quote;
  createdQuoteLineItems: QuoteLineItem[];
  createdQuoteLineItemGroups: QuoteLineItemGroup[];
};

export type CreateSalesOrderLineItemGroupPayloadInput = {
  sortIndex: number;
  name: string;
  temporaryId: number;
};

export type UpdateSalesOrderLineItemGroupPayloadInput = {
  salesOrderLineItemGroupId: number;
  sortIndex?: number;
  name?: string;
};

export type CommitSalesOrderDraftPayloadInput = {
  salesOrderId: number;

  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
  billingAddressId?: number;
  shipToAddressId?: number;
  salesRepAccountId?: number;

  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;

  createSalesOrderLineItemPayloads: CreateSalesOrderLineItemPayloadInput[];
  updateSalesOrderLineItemPayloads: UpdateSalesOrderLineItemPayloadInput[];
  deleteSalesOrderLineItemPayloads: number[];

  createSalesOrderLineItemGroupPayloads: CreateSalesOrderLineItemGroupPayloadInput[];
  updateSalesOrderLineItemGroupPayloads: UpdateSalesOrderLineItemGroupPayloadInput[];
  deleteSalesOrderLineItemGroupPayloads: number[];
};

export type CommitSalesOrderDraftResultPayload = {
  createdSalesOrderLineItems: SalesOrderLineItem[];
  updatedSalesOrderLineItems: SalesOrderLineItem[];
  deletedSalesOrderLineItems: SalesOrderLineItem[];

  createdSalesOrderLineItemGroups: SalesOrderLineItemGroup[];
  updatedSalesOrderLineItemGroups: SalesOrderLineItemGroup[];
  deletedSalesOrderLineItemGroups: SalesOrderLineItemGroup[];
};

export type CommitPurchaseOrderDraftPayloadInput = {
  purchaseOrderId: number;

  primaryContactPersonId?: number;
  primaryContactCompanyId?: number;
  billingAddressId?: number;
  shipToAddressId?: number;
  salesRepAccountId?: number;

  requestedFulfillmentDate?: string;
  requestedFulfillmentDateNotes?: string;
  requestedFulfillmentDateFlagged?: boolean;
  requestedShipDate?: string;

  createPurchaseOrderLineItemPayloads: CreatePurchaseOrderLineItemPayloadInput[];
  updatePurchaseOrderLineItemPayloads: UpdatePurchaseOrderLineItemPayloadInput[];
  deletePurchaseOrderLineItemPayloads: number[];
};

export type CommitPurchaseOrderDraftResultPayload = {
  createdPurchaseOrderLineItems: PurchaseOrderLineItem[];
  updatedPurchaseOrderLineItems: PurchaseOrderLineItem[];
  deletedPurchaseOrderLineItems: PurchaseOrderLineItem[];
};

export type UpdateSalesOrderPayloadInput = {
  id: number;
  originTaxAddressId?: number;
  destinationTaxAddressId?: number;
};

export type UpdateQuotePayloadInput = {
  id: number;
  originTaxAddressId?: number;
  destinationTaxAddressId?: number;
};

export type CreateAddressPayloadInput = {
  street1: string;
  street2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
};

export type ConfigurationPriceResult = {
  productListPrice: number;
  optionsListPrice: number;
  totalListPrice: number;
  vendorAdjustedListPrice: number;
  suggestedSellPrice: number;
  vendorCost: number;
  grossProfit: number;
  vendorListPriceAdjustment: number;
};

export type ProductAndOptionPricingResult = {
  productListPrice: number;
  optionsListPrice: number;
  totalListPrice: number;
};

export type CalculateConfigurationPricePayloadInput = {
  catalogProductId: number;
  catalogOptionIds: number[];
};

export type UpdateVendorPayloadInput = {
  id: number;
  name?: string;
  email?: string;
  phone?: string;
  type?: CompanyType;
  parentVendorId?: number;
  freightRules?: string;
  listPriceAdjustment?: number;
  pricingZone?: string;
  listPriceMultiplier?: number;
  grossProfitPercent?: number;
  notes?: string;
};

export type CreateProductSellPriceMultiplierPayload = {
  productId: number;
  defaultGrossProfit?: number;
  discount?: number;
  sellPriceMultiplier?: number;
};

export type UpdateProductSellPriceMultiplierPayload = {
  id: number;
  productId?: number;
  defaultGrossProfit?: number;
  discount?: number;
  sellPriceMultiplier?: number;
};

export type UpdatePrepTicketLineItemPayload = {
  prepTicketLineItemId: number;
  isPrepped?: boolean;
};

export type CreatePrepTicketLineItemPayload = {
  prepTicketId: number;
  inventoryItemId: number;
  isPrepped?: boolean;
};

export type Account = {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  auth0Id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreatePurchaseOrderSubmissionPayload = {
  purchaseOrderId: number;
  submissionMethod: PurchaseOrderSubmissionMethod;
  submissionDestination: string;
  result?: PurchaseOrderSubmissionResult;
  notes: string;
  resultReceivedAt?: string;
  submissionAttachmentId?: number;
};

export type UpdateCompanyAddressPayload = {
  id: number;
  type?: CompanyAddressType;
  notes?: string;
};

export type UpdateVendorAddressPayload = {
  id: number;
  type?: VendorAddressType;
};

export type UpdatePersonAddressPayload = {
  id: number;
  type?: PersonAddressType;
  notes?: string;
};

export type UpdateCompanyContactPayload = {
  id: number;
  role?: ContactRole;
};

export type UpdateVendorContactPayload = {
  id: number;
  role?: ContactRole;
};

export enum FulfillmentMethodType {
  WillCall = "WillCall",
  InternalCrew = "InternalCrew",
  ExternalCrew = "ExternalCrew",
  FreightShipment = "FreightShipment",
}
