import { CatalogProduct } from "../types/ApiTypes";
import { ApolloClient, gql } from "@apollo/client";

const GetProductTaxCode = gql`
  query GetProductTaxCode($productId: Int!) {
    catalogProducts(where: { id: { eq: $productId } }) {
      id
      taxCode
    }
  }
`;

export async function getTaxCode(
  configuration: {
    isCustomConfiguration?: boolean;
    catalogProductId?: number;
  },
  apolloClient: ApolloClient<any>
) {
  if (configuration.isCustomConfiguration) {
    return "PA3000100";
  }

  const getProductTaxCode = await apolloClient.query<
    { catalogProducts: CatalogProduct[] },
    { productId: number }
  >({
    query: GetProductTaxCode,
    variables: { productId: configuration.catalogProductId! },
  });

  return getProductTaxCode.data.catalogProducts[0].taxCode;
}
