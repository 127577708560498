import { gql } from "@apollo/client";

export type GetAccountsResult = {
  accounts: {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
    auth0Id: string;
    spiffCodes: {
      id: number;
      vendor: {
        id: number;
        name: string;
      };
      code: string;
      createdBy: {
        id: number;
        name: string;
      };
      createdAt: Date;
    }[];
  }[];
};

export const GetAccounts = gql`
  query GetAccounts {
    accounts {
      id
      name
      email
      phoneNumber
      auth0Id
      spiffCodes {
        id
        vendor {
          id
          name
        }
        code
        createdBy {
          id
          name
        }
        createdAt
      }
    }
  }
`;

export type GetVendorsResult = {
  vendors: {
    id: number;
    name: string;
  }[];
};

export const GetVendors = gql`
  query GetVendors {
    vendors(order: { name: ASC }) {
      id
      name
    }
  }
`;
