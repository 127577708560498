import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Account, ProductConfiguration } from "../../types/ApiTypes";
import { Helmet } from "react-helmet";
import React, { useCallback, useEffect, useState } from "react";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import {
  Button,
  EditableText,
  FormGroup,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  TextArea,
} from "@blueprintjs/core";
import { Avatar } from "@progress/kendo-react-layout";
import { formatDateTime } from "../../helpers/DateHelper";
import { DefaultToaster } from "../../components/shared/DefaultToaster/DefaultToaster";
import { ProjectDetailItem } from "./ProjectDetailItem";
import { SalesOrderDetailItem } from "./SalesOrderDetailItem";
import {
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { GetFullConfigurationCodeFragment } from "../../helpers/CatalogHelper";
import {
  FulfillmentActivityItem,
  FulfillmentActivityItemProps,
} from "./FulfillmentActivityItem";
import {
  CommentActivityItem,
  CommentActivityItemProps,
} from "./CommentActivityItem";
import { AttachFulfillmentDialog } from "./AttachFulfillmentDialog";
import {
  PrepTicketActivityItem,
  PrepTicketActivityItemProps,
} from "./PrepTicketActivityItem";
import { AttachPrepTicketDialog } from "./AttachPrepTicketDialog";
import { PersonDetailItem } from "./PersonDetailItem";
import { CompanyDetailItem } from "./CompanyDetailItem";

type AttachPrepTicketToEODResult = {
  attachPrepTicketToEOD: {
    id: number;
  };
};

type AttachPrepTicketToEODParams = {
  eodId: number;
  prepTicketId: number;
  lineItemIds: number[];
};

const AttachPrepTicketToEOD = gql`
  mutation attachPrepTicketToEOD(
    $eodId: Int!
    $prepTicketId: Int!
    $lineItemIds: [Int!]!
  ) {
    attachPrepTicketToEOD(
      eodId: $eodId
      prepTicketId: $prepTicketId
      lineItemIds: $lineItemIds
    ) {
      id
    }
  }
`;

type AttachFulfillmentToEODResult = {
  attachFulfillmentToEOD: {
    id: number;
  };
};

type AttachFulfillmentToEODParams = {
  eodId: number;
  fulfillmentId: number;
  lineItemIds: number[];
};

const AttachFulfillmentToEOD = gql`
  mutation attachFulfillmentToEOD(
    $eodId: Int!
    $fulfillmentId: Int!
    $lineItemIds: [Int!]!
  ) {
    attachFulfillmentToEOD(
      eodId: $eodId
      fulfillmentId: $fulfillmentId
      lineItemIds: $lineItemIds
    ) {
      id
    }
  }
`;

const ResolveEOD = gql`
  mutation ResolveEOD($id: Int!) {
    resolveEOD(id: $id) {
      id
    }
  }
`;

type ResolveEODResult = {
  resolveEOD: {
    id: number;
  };
};

type ResolveEODParams = {
  id: number;
};

const CreateComment = gql`
  mutation CreateComment($payload: CreateEODCommentPayloadInput!) {
    createEODComment(payload: $payload) {
      id
    }
  }
`;

type CreateCommentResult = {
  createEODComment: {
    id: number;
  };
};

type CreateCommentParams = {
  payload: {
    eODId: number;
    message: string;
  };
};

const UpdateEOD = gql`
  mutation UpdateEOD($payload: UpdateEODPayloadInput!) {
    updateEOD(payload: $payload) {
      id
    }
  }
`;

type UpdateEODResult = {
  updateEOD: {
    id: number;
  };
};

type UpdateEODParams = {
  payload: {
    id: number;
    title?: string;
    description?: string;
    projectId?: number;
    salesOrderId?: number;
    personId?: number;
    companyId?: number;
  };
};

const GetEOD = gql`
  ${FormatContactNameContactFragment}
  ${GetFullConfigurationCodeFragment}

  query GetEOD($eodId: Int!) {
    eods(where: { id: { eq: $eodId } }) {
      id
      title
      description
      project {
        id
      }
      person {
        id
        firstName
        lastName
      }
      company {
        id
        name
      }
      salesOrder {
        id
        code
        project {
          id
        }
      }
      comments {
        id
        message
        createdBy {
          id
          name
        }
        createdAt
        __typename
      }
      prepTickets {
        id
        prepTicket {
          id
          project {
            id
            primaryContact {
              id
              ...FormatContactNameContactFragment
            }
          }
        }
        lineItems {
          id
          prepTicketLineItem {
            id
          }
        }
        createdAt
        createdBy {
          id
          name
        }
        __typename
      }
      fulfillments {
        id
        fulfillment {
          id
          project {
            id
            primaryContact {
              id
              ...FormatContactNameContactFragment
            }
          }
          deliveryStartsAt
          deliveryEndsAt
          requestedAt
          installStartsAt
          installEndsAt
          __typename
        }
        lineItems {
          id
          fulfillmentLineItem {
            id
            salesOrderLineItem {
              id
              configuration {
                id
                ...GetFullConfigurationCodeFragment
              }
              salesOrderLineItemGroup {
                id
                salesOrder {
                  id
                  code
                }
              }
              inventoryItems {
                id
                location {
                  id
                  name
                }
                purchaseOrderShipmentLineItem {
                  id
                  purchaseOrderLineItem {
                    id
                    purchaseOrder {
                      id
                      code
                    }
                  }
                }
              }
            }
          }
        }
        __typename
        createdBy {
          id
          name
        }
        createdAt
      }
      resolvedAt
      resolvedBy {
        id
        name
      }
      createdBy {
        id
        name
      }
      createdAt
    }

    currentAccount {
      id
      name
    }
  }
`;

type GetEODResult = {
  eods: {
    id: number;
    title: string;
    description: string;
    person?: {
      id: number;
      firstName: string;
      lastName: string;
    };
    company?: {
      id: number;
      name: string;
    };
    project?: {
      id: number;
    };
    salesOrder?: {
      id: number;
      code: string;
      project: {
        id: number;
      };
    };
    comments: {
      id: number;
      message: string;
      createdBy: Account;
      createdAt: string;
      __typename: "EODComment";
    }[];
    prepTickets: {
      id: number;
      prepTicket: {
        id: number;
        project: {
          id: number;
          primaryContact?: FormatContactNameContact;
        };
      };
      lineItems: {
        id: number;
        prepTicketLineItem: {
          id: number;
        };
      }[];
      createdAt: string;
      createdBy: Account;
      __typename: "EODPrepTicket";
    }[];
    fulfillments: {
      id: number;
      fulfillment: {
        id: number;
        project: {
          id: number;
          primaryContact?: FormatContactNameContact;
        };
        deliveryStartsAt?: string;
        deliveryEndsAt?: string;
        requestedAt?: string;
        installStartsAt?: string;
        installEndsAt?: string;
      };
      lineItems: {
        id: number;
        fulfillmentLineItem: {
          id: number;
          salesOrderLineItem: {
            id: number;
            configuration: ProductConfiguration;
            salesOrderLineItemGroup: {
              id: number;
              salesOrder: {
                id: number;
                code: string;
              };
            };
            inventoryItems: {
              id: number;
              location?: {
                id: number;
                name: string;
              };
              purchaseOrderShipmentLineItem: {
                id: number;
                purchaseOrderLineItem: {
                  id: number;
                  purchaseOrder: {
                    id: number;
                    code: string;
                  };
                };
              };
            }[];
          };
        };
      }[];
      createdAt: string;
      createdBy: {
        id: number;
        name: string;
      };
      __typename: "EODFulfillment";
    }[];
    resolvedBy?: Account;
    resolvedAt?: string;
    createdBy: Account;
    createdAt: string;
  }[];
  currentAccount: Account;
};

export default function EODDetails() {
  const { eodId } = useParams<{ eodId: string }>();

  const getEOD = useQuery<GetEODResult, { eodId: number }>(GetEOD, {
    variables: {
      eodId: parseInt(eodId),
    },
    fetchPolicy: "cache-and-network",
  });

  const [attachFulfillmentToEOD] = useMutation<
    AttachFulfillmentToEODResult,
    AttachFulfillmentToEODParams
  >(AttachFulfillmentToEOD, { refetchQueries: [GetEOD] });

  const [attachPrepTicketToEOD] = useMutation<
    AttachPrepTicketToEODResult,
    AttachPrepTicketToEODParams
  >(AttachPrepTicketToEOD, { refetchQueries: [GetEOD] });

  const [updateEOD] = useMutation<UpdateEODResult, UpdateEODParams>(UpdateEOD, {
    refetchQueries: [GetEOD],
  });

  const [resolveEOD] = useMutation<ResolveEODResult, ResolveEODParams>(
    ResolveEOD,
    {
      refetchQueries: [GetEOD],
    }
  );

  const [createComment] = useMutation<CreateCommentResult, CreateCommentParams>(
    CreateComment,
    {
      refetchQueries: [GetEOD],
    }
  );

  const eod = getEOD.data?.eods[0];
  const currentAccount = getEOD.data?.currentAccount;

  const eodComments = (eod?.comments ?? []).map((comment) => ({
    ...comment,
    createdAt: new Date(comment.createdAt),
  }));

  const eodFulfillments = (eod?.fulfillments ?? []).map((fulfillment) => ({
    ...fulfillment,
    createdAt: new Date(fulfillment.createdAt),
  }));

  const eodPrepTickets = (eod?.prepTickets ?? []).map((prepTicket) => ({
    ...prepTicket,
    createdAt: new Date(prepTicket.createdAt),
  }));

  const activityItems = [...eodComments, ...eodFulfillments, ...eodPrepTickets];

  const [title, setTitle] = useState(eod?.title ?? "");
  const [description, setDescription] = useState(eod?.description ?? "");
  const [comment, setComment] = useState("");
  const [isAttachFulfillmentOpen, setIsAttachFulfillmentOpen] = useState(false);
  const [isAttachPrepTicketOpen, setIsAttachPrepTicketOpen] = useState(false);

  useEffect(() => {
    setTitle(eod?.title ?? "");
    setDescription(eod?.description ?? "");
  }, [eod?.title, eod?.description]);

  const saveChanges = useCallback(async () => {
    await updateEOD({
      variables: {
        payload: {
          id: parseInt(eodId),
          title,
          description,
        },
      },
    });

    DefaultToaster.show({
      message: "EOD updated successfully!",
      icon: "tick-circle",
      intent: "success",
    });
  }, [title, description, eodId, updateEOD]);

  const addComment = useCallback(async () => {
    await createComment({
      variables: {
        payload: {
          eODId: parseInt(eodId),
          message: comment,
        },
      },
    });
    setComment("");
  }, [createComment, eodId, comment]);

  const resolve = useCallback(async () => {
    await resolveEOD({
      variables: {
        id: parseInt(eodId),
      },
    });
    DefaultToaster.show({
      message: "EOD marked as resolved!",
      icon: "tick-circle",
      intent: "success",
    });
  }, [resolveEOD, eodId]);

  return (
    <>
      <AttachFulfillmentDialog
        onAttach={async (fulfillmentId, lineItemIds) => {
          await attachFulfillmentToEOD({
            variables: {
              eodId: parseInt(eodId),
              fulfillmentId,
              lineItemIds: lineItemIds,
            },
          });
          setIsAttachFulfillmentOpen(false);
        }}
        isOpen={isAttachFulfillmentOpen}
        onClose={() => setIsAttachFulfillmentOpen(false)}
      />

      <AttachPrepTicketDialog
        onAttach={async (prepTicketId, lineItemIds) => {
          await attachPrepTicketToEOD({
            variables: {
              eodId: parseInt(eodId),
              prepTicketId,
              lineItemIds: lineItemIds,
            },
          });
          setIsAttachPrepTicketOpen(false);
        }}
        isOpen={isAttachPrepTicketOpen}
        onClose={() => setIsAttachPrepTicketOpen(false)}
      />

      <Helmet>
        <title>{`EOD-${eodId}`}</title>
      </Helmet>

      <ProjectSubnavbar
        title={eod?.title ?? ""}
        loading={getEOD.loading}
        style={{ top: "unset" }}
        actions={
          <>
            {eod && !eod.resolvedAt && (
              <Button text="Mark Resolved" icon="tick" onClick={resolve} />
            )}
            <Button
              intent="primary"
              text="Save Changes"
              onClick={saveChanges}
            />
          </>
        }
      />

      <div className="flex" style={{ height: "calc(100% - 77px)" }}>
        <div className="space-y-4 flex-1 p-2 overflow-y-auto">
          <EditableText
            value={title}
            onChange={setTitle}
            className="w-full text-2xl bg-gray-100 p-2 rounded-md"
          />

          <FormGroup label="EOD Description">
            <TextArea
              rows={10}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full"
            />
          </FormGroup>

          <h4 className="font-medium text-base">Activity</h4>

          <div className="space-y-2">
            {activityItems
              .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
              .map((activityItem) => {
                switch (activityItem.__typename) {
                  case "EODComment":
                    return (
                      <CommentActivityItem
                        {...(activityItem as CommentActivityItemProps)}
                      />
                    );
                  case "EODFulfillment":
                    return (
                      <FulfillmentActivityItem
                        {...(activityItem as FulfillmentActivityItemProps)}
                      />
                    );
                  case "EODPrepTicket":
                    return (
                      <PrepTicketActivityItem
                        {...(activityItem as PrepTicketActivityItemProps)}
                      />
                    );
                }
              })}

            <div className="flex space-x-2">
              <div className="p-1">
                <Avatar>{currentAccount?.name.substring(0, 1)}</Avatar>
              </div>
              <div className="rounded border p-3 flex-1 bg-white shadow space-y-1">
                <textarea
                  className="borderless text-base resize-none w-full"
                  placeholder="Leave a comment..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div className="flex items-center justify-between">
                  <Popover minimal position="bottom-left">
                    <Button icon="paperclip" minimal />
                    <Menu>
                      <MenuDivider title="Attach..." />
                      <MenuItem
                        text="Fulfillment"
                        onClick={() => setIsAttachFulfillmentOpen(true)}
                      />
                      <MenuItem
                        text="Prep Ticket"
                        onClick={() => setIsAttachPrepTicketOpen(true)}
                      />
                    </Menu>
                  </Popover>
                  <Button text="Comment" onClick={addComment} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-96 bg-gray-50 border-l space-y-4 p-2 overflow-y-auto">
          <h4 className="font-medium text-base">Details</h4>

          <div className="space-y-2">
            <div className="flex space-x-2 items-center">
              <p className="m-0 font-medium text-xs w-20">Status</p>

              <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center">
                {eod && eod.resolvedAt && (
                  <>
                    <div className="w-2 h-2 rounded-full bg-green-500 inline-block" />
                    <span>
                      Closed {formatDateTime(new Date(eod.resolvedAt))}
                    </span>
                  </>
                )}

                {eod && !eod.resolvedAt && (
                  <>
                    <div className="w-2 h-2 rounded-full bg-red-300 inline-block" />
                    <span>Unresolved</span>
                  </>
                )}
              </div>
            </div>

            {eod?.resolvedBy && (
              <div className="flex space-x-2 items-center">
                <p className="m-0 font-medium text-xs w-20">Closed By</p>
                <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center">
                  {eod?.resolvedBy.name}
                </div>
              </div>
            )}

            <div className="flex space-x-2 items-center">
              <p className="m-0 font-medium text-xs w-20">Reported By</p>
              <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center">
                {eod?.createdBy.name}
              </div>
            </div>

            <div className="flex space-x-2 items-center">
              <p className="m-0 font-medium text-xs w-20">Reported On</p>
              <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center">
                {eod ? formatDateTime(new Date(eod.createdAt)) : ""}
              </div>
            </div>
          </div>

          <h4 className="font-medium text-base">Associations</h4>

          <div className="space-y-2">
            {eod && (
              <>
                <PersonDetailItem
                  eod={eod}
                  onSelectPerson={async (personId) =>
                    await updateEOD({
                      variables: {
                        payload: { id: parseInt(eodId), personId },
                      },
                    })
                  }
                />

                <CompanyDetailItem
                  eod={eod}
                  onSelectCompany={async (companyId) =>
                    await updateEOD({
                      variables: {
                        payload: { id: parseInt(eodId), companyId },
                      },
                    })
                  }
                />

                <SalesOrderDetailItem
                  eod={eod}
                  onSelectSalesOrder={async (salesOrderId) =>
                    await updateEOD({
                      variables: {
                        payload: { id: parseInt(eodId), salesOrderId },
                      },
                    })
                  }
                />

                <ProjectDetailItem
                  eod={eod}
                  onSelectProject={async (projectId) =>
                    await updateEOD({
                      variables: {
                        payload: { id: parseInt(eodId), projectId },
                      },
                    })
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
