import { Drawer, DrawerProps } from "@blueprintjs/core";
import { ReceiveShipment } from "./ReceiveShipment";

type ReceiveShipmentDrawerProps = DrawerProps & {
  shipmentId?: number;
  onReceived: () => void;
};

export function ReceiveShipmentDrawer({
  shipmentId,
  onReceived,
  ...drawerProps
}: ReceiveShipmentDrawerProps) {
  return (
    <Drawer position="bottom" size="100%" usePortal lazy {...drawerProps}>
      {shipmentId && (
        <ReceiveShipment
          shipmentId={shipmentId}
          onReceived={onReceived}
          onClose={() => drawerProps.onClose?.(null!)}
        />
      )}
    </Drawer>
  );
}
