import { Button, FormGroup, InputGroup, Intent } from "@blueprintjs/core";
import { gql, useMutation } from "@apollo/client";
import { Company, CreateCompanyPayloadInput } from "../../../types/ApiTypes";
import { useState } from "react";

const CREATE_COMPANY = gql`
  mutation CreateCompany($payload: CreateCompanyPayloadInput) {
    createCompany(payload: $payload) {
      id
      name
    }
  }
`;

type InlineCreateCompanyFormProps = {
  onCompanyCreated: (company: Company) => void;
};

export function InlineCreateCompanyForm({
  onCompanyCreated,
}: InlineCreateCompanyFormProps) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [createCompany] = useMutation<
    { createCompany: Company },
    { payload: CreateCompanyPayloadInput }
  >(CREATE_COMPANY);

  async function handleSubmit(e) {
    e.preventDefault();

    const createPersonResult = await createCompany({
      variables: {
        payload: {
          name,
          email,
          phone,
        },
      },
    });

    onCompanyCreated(createPersonResult.data!.createCompany);
  }

  return (
    <div>
      <div className="p-2 border-b">
        <h4 className="text-center font-medium text-gray-700">
          Create a Company
        </h4>
      </div>
      <div className="p-3 w-96">
        <form onSubmit={handleSubmit}>
          <FormGroup label="Company Name" className="flex-1 pr-1">
            <InputGroup
              type="text"
              placeholder="Company Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <div className="flex">
            <FormGroup label="Email" className="flex-1 pr-1">
              <InputGroup
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup label="Phone" className="flex-1 pl-1">
              <InputGroup
                type="text"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              text="Create Company"
              intent={Intent.PRIMARY}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
