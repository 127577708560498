import React, { useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  IDialogProps,
  FileInput,
} from "@blueprintjs/core";

export type UploadAttachmentDialogProps = IDialogProps & {
  onSubmit: (file: File) => void;
};

export function UploadAttachmentDialog({
  isOpen,
  onClose,
  onSubmit,
}: UploadAttachmentDialogProps) {
  const [file, setFile] = useState<File>();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) return;
    onSubmit(file);
    onClose && onClose(e);
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      setFile(e.currentTarget.files[0]);
    }
  };

  return (
    <Dialog title="Upload Attachment" isOpen={isOpen} lazy usePortal>
      <form onSubmit={handleSubmit}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Attachment">
            <FileInput
              text={file ? file.name : "Choose a file...."}
              hasSelection={!!file}
              onInputChange={handleInputChange}
              fill
              large
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={onClose} text="Cancel" type="button" minimal />
            <Button
              intent={Intent.PRIMARY}
              text="Upload"
              type="submit"
              disabled={!file}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
