import { InputGroup } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { MetadataSchemaType } from "./MetadataEditor";
import { AttachmentValueCell } from "./AttachmentValueCell";

export type ValueCellProps = {
  value: string;
  schemaType: MetadataSchemaType;
  onChange: (newValue: string) => void;
};

export function ValueCell({
  value: initialValue,
  schemaType,
  onChange,
}: ValueCellProps) {
  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  if (schemaType === "attachment") {
    return (
      <AttachmentValueCell
        value={value}
        onChange={(a) => {
          setValue(a);
          onChange(a);
        }}
      />
    );
  } else {
    return (
      <InputGroup
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onChange(value)}
      />
    );
  }
}
