import { Intent, NumericInput } from "@blueprintjs/core";
import React from "react";

export type QuantityCellProps = {
  value: number;
  maximumValue?: number;
  disabled?: boolean;
  onChange: (newValue: number) => void;
  onConfirm: (newValue: number) => void;
};

export function QuantityCell({
  value,
  maximumValue,
  disabled,
  onChange,
  onConfirm,
}: QuantityCellProps) {
  return (
    <NumericInput
      value={value}
      max={maximumValue}
      onValueChange={(e) => onChange(e)}
      onButtonClick={(e) => onConfirm(e)}
      onBlur={() => onConfirm(value)}
      disabled={disabled}
      fill
    />
  );
}
