import { gql, useQuery } from "@apollo/client";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Popover, Spinner, Tag } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { process } from "@progress/kendo-data-query";
import { useReportDataState } from "../../hooks/useReportDataState";
import { formatAccountName } from "../../helpers/AccountHelper";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { Account } from "../../types/ApiTypes";
import { formatDate } from "../../helpers/DateHelper";
import React from "react";

type GetEODsResult = {
  eods: {
    id: number;
    title: string;
    project?: {
      id: number;
    };
    salesOrder?: {
      id: number;
      code: string;
      project: {
        id: number;
      };
    };
    mostRecentComment?: {
      id: number;
      message: string;
      createdBy: Account;
      createdAt: string;
    };
    resolvedBy?: Account;
    resolvedAt?: string;
    createdBy: Account;
    createdAt?: string;
  }[];
};

const GetPrepReport = gql`
  query GetEODs {
    eods {
      id
      title
      project {
        id
      }
      salesOrder {
        id
        code
        project {
          id
        }
      }
      mostRecentComment {
        id
        message
        createdBy {
          id
          name
        }
        createdAt
      }
      resolvedAt
      resolvedBy {
        id
        name
      }
      createdBy {
        id
        name
      }
      createdAt
    }
  }
`;

export default function EODDashboard() {
  const getEODsResult = useQuery<GetEODsResult>(GetPrepReport, {
    fetchPolicy: "cache-and-network",
  });

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const eods = getEODsResult.data != undefined ? getEODsResult.data.eods : [];

  const eodItems = eods.map((eod) => ({
    ...eod,
    createdByName: formatAccountName(eod.createdBy),
    statusText: eod.resolvedAt
      ? `Resolved ${formatDate(
          new Date(eod.resolvedAt)
        )} by ${formatAccountName(eod.resolvedBy)}`
      : eod.createdAt
      ? `Opened on ${formatDate(new Date(eod.createdAt))}`
      : `Open`,
    mostRecentCommentText: eod.mostRecentComment
      ? `On ${formatDate(eod.mostRecentComment.createdAt)}, ${formatAccountName(
          eod.mostRecentComment.createdBy
        )}: ${eod.mostRecentComment.message}`
      : "",
  }));

  return (
    <>
      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="EODs"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getEODsResult.loading ? (
            <Spinner />
          ) : (
            <Grid
              data={process(eodItems, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="id"
                title="EOD #"
                width={130}
                cell={(props) => (
                  <td>
                    <EntityLink
                      url={`/eods/${props.dataItem.id}`}
                      text={`EOD-${props.dataItem.id}`}
                    />
                  </td>
                )}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="project.id"
                title="Project #"
                width={200}
                cell={(props) => {
                  const dataItem = props.dataItem as typeof eodItems[0];

                  return dataItem.project ? (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.project.id}/quotes`}
                        text={`PR-${dataItem.project.id}`}
                      />
                    </td>
                  ) : (
                    <td>Not Specified</td>
                  );
                }}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="salesOrder.code"
                title="SO #"
                width={200}
                cell={(props) => {
                  const dataItem = props.dataItem as typeof eodItems[0];

                  return dataItem.salesOrder ? (
                    <td>
                      <EntityLink
                        url={`/projects/${dataItem.salesOrder.project.id}/sales-orders/${dataItem.salesOrder.id}`}
                        text={dataItem.salesOrder.code}
                      />
                    </td>
                  ) : (
                    <td>Not Specified</td>
                  );
                }}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="title"
                title="Title"
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="statusText"
                title="Status"
                width={300}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="createdByName"
                title="Reported By"
                width={200}
              />

              <GridColumn
                columnMenu={ReportColumnMenu}
                field="mostRecentCommentText"
                title="Most Recent Comment"
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
