import React, { useMemo } from "react";
import dayjs from "dayjs";
import {
  Button,
  ButtonGroup,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
  Tag,
} from "@blueprintjs/core";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Contact, Quote, QuoteVersionGroup } from "../../../../types/ApiTypes";
import { FlexTable } from "../../../shared/Table/FlexTable";
import { NameCell } from "./NameCell";
import { StatusCell } from "./StatusCell";
import {
  EntityCodeType,
  getEntityCode,
} from "../../../../helpers/EntityCodeHelper";
import { BasicTable } from "../../../shared/Table/BasicTable";
import { formatDate } from "../../../../helpers/DateHelper";
import { formatContactName } from "../../../../helpers/ContactHelper";

export type QuotesTableProps = {
  quotes: {
    id: number;
    code: string;
    name: string;
    salesOrder?: {
      id: number;
      code: string;
      createdAt: string;
    };
    quoteVersionGroup: {
      id: number;
      project: {
        id: number;
        primaryContact?: Contact;
      };
    };
    updatedAt: string;
    createdAt: string;
  }[];
};

export function QuotesTable({ quotes }: QuotesTableProps) {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = match.params.projectId;

  return (
    <BasicTable header footer>
      <thead>
        <tr>
          <th>Quote #</th>
          <th>Name</th>
          <th>Primary Contact</th>
          <th>SO #</th>
          <th>Converted On</th>
          <th>Created</th>
          <th style={{ width: 200 }}>
            <span className="sr-only">Actions</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {quotes.map((quote) => (
          <tr key={quote.id}>
            <td className="space-x-2">
              <Link to={`/projects/${projectId}/quotes/${quote.id}`}>
                {quote.code}
              </Link>
              {quote.salesOrder && (
                <Tag intent="success" minimal>
                  Converted
                </Tag>
              )}
            </td>
            <td>{quote.name}</td>
            <td>
              {formatContactName(
                quote.quoteVersionGroup.project.primaryContact
              )}
            </td>
            <td>
              {quote.salesOrder ? (
                <Link
                  to={`/projects/${projectId}/sales-orders/${quote.salesOrder.id}`}
                >
                  {quote.salesOrder.code}
                </Link>
              ) : (
                "Not Converted"
              )}
            </td>
            <td>
              {quote.salesOrder
                ? formatDate(quote.salesOrder?.createdAt)
                : "Not Converted"}
            </td>
            <td>{formatDate(quote.createdAt)}</td>
            <td>
              <Button
                rightIcon="arrow-right"
                text="View Quote"
                onClick={() =>
                  history.push(`/projects/${projectId}/quotes/${quote.id}`)
                }
                small
                fill
              />
            </td>
          </tr>
        ))}
      </tbody>
    </BasicTable>
  );
}
