import { formatDateTime } from "../../../../helpers/DateHelper";
import { formatContactName } from "../../../../helpers/ContactHelper";
import { Button } from "@blueprintjs/core";
import React from "react";
import { TableHeader } from "../../../../components/shared/Table/TableHeader";
import { TableFooter } from "../../../../components/shared/Table/TableFooter";
import { gql, useQuery } from "@apollo/client";
import { getPreppedStatus } from "./helpers";
import { formatAccountName } from "../../../../helpers/AccountHelper";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../../../components/reports/EntityLink";
import { useReportDataState } from "../../../../hooks/useReportDataState";
import { FulfillmentMethodType } from "../../../../types/ApiTypes";
import { formatFulfillmentMethodType } from "../../../../helpers/FulfillmentHelper";

const GetUnscheduledFulfillments = gql`
  query GetUnscheduledFulfillments($projectId: Int!) {
    fulfillments(
      where: {
        projectId: { eq: $projectId }
        or: [
          { installStartsAt: { eq: null } }
          { installEndsAt: { eq: null } }
          { deliveryStartsAt: { eq: null } }
          { deliveryEndsAt: { eq: null } }
          { estimatedHours: { eq: null } }
          { completedAt: { eq: null } }
        ]
      }
    ) {
      id
      requestedAt
      installStartsAt
      installEndsAt
      deliveryStartsAt
      deliveryEndsAt
      estimatedHours
      fulfillmentMethodType
      fulfillmentMethodNotes
      lineItems {
        id
        salesOrderLineItemId
        salesOrderLineItem {
          id
          inventoryItems {
            id  
            prepTicketLineItem {
              id
              isPrepped
            }
          }
        }
      }
      project {
        id
        salesRepAccount {
          id
          name
        }
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            __typename
          }
          company {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

type GetUnscheduledFulfillmentsResult = {
  fulfillments: {
    id: number;
    requestedAt?: Date;
    installStartsAt?: Date;
    installEndsAt?: Date;
    deliveryStartsAt?: Date;
    deliveryEndsAt?: Date;
    estimatedHours?: number;
    fulfillmentMethodType?: FulfillmentMethodType;
    fulfillmentMethodNotes?: string;
    lineItems: {
      id: number;
      salesOrderLineItemId?: number;
      salesOrderLineItem?: {
        inventoryItems?: {
          prepTicketLineItem?: {
            id: number;
            isPrepped: boolean;
          };
        }[];
      };
    }[];
    project: {
      id: number;
      salesRepAccount?: {
        id: number;
        name: string;
      };
      primaryContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
        };
        company: {
          id: number;
          name: string;
        };
      };
    };
  }[];
};

type UnscheduledFulfillmentsTableProps = {
  projectId: number;
  onCreateFulfillment: () => void;
};

export function UnscheduledFulfillmentsTable({
  projectId,
  onCreateFulfillment,
}: UnscheduledFulfillmentsTableProps) {
  const getUnscheduledFulfillments = useQuery<
    GetUnscheduledFulfillmentsResult,
    { projectId: number }
  >(GetUnscheduledFulfillments, {
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const fulfillments = (
    getUnscheduledFulfillments.data?.fulfillments ?? []
  ).map((f) => ({
    ...f,
    requestedAt: f.requestedAt ? new Date(f.requestedAt) : undefined,
    installStartsAt: f.installStartsAt
      ? new Date(f.installStartsAt)
      : undefined,
    installEndsAt: f.installEndsAt ? new Date(f.installEndsAt) : undefined,
    deliveryStartsAt: f.deliveryStartsAt
      ? new Date(f.deliveryStartsAt)
      : undefined,
    deliveryEndsAt: f.deliveryEndsAt ? new Date(f.deliveryEndsAt) : undefined,
    primaryContactName: formatContactName(f.project.primaryContact),
    salesRepName: formatAccountName(f.project.salesRepAccount),
    fulfillmentMethod: f.fulfillmentMethodType
      ? [
          formatFulfillmentMethodType(f.fulfillmentMethodType),
          f.fulfillmentMethodNotes,
        ]
          .filter(Boolean)
          .join(" - ")
      : "",
  }));

  return (
    <div>
      <TableHeader className="p-3">
        <h2 className="text-md font-semibold">Unscheduled Fulfillments</h2>
      </TableHeader>

      <Grid
        data={process(fulfillments, dataState)}
        {...dataState}
        onDataStateChange={(e) => setDataState(e.dataState)}
        sortable
        reorderable
        resizable
      >
        <GridColumn
          columnMenu={ReportColumnMenu}
          field="id"
          title="Fulfillment #"
          width={130}
          cell={(props) => (
            <td>
              <EntityLink
                url={`/projects/${props.dataItem.project.id}/fulfillments/${props.dataItem.id}`}
                text={`F-${props.dataItem.id}`}
              />
            </td>
          )}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="date"
          cell={(props) => (
            <td>{formatDateTime(props.dataItem[props.field!])}</td>
          )}
          field="requestedAt"
          title="Requested Date"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="date"
          cell={(props) => (
            <td>{formatDateTime(props.dataItem[props.field!])}</td>
          )}
          field="installStartsAt"
          title="Install Start"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="date"
          cell={(props) => (
            <td>{formatDateTime(props.dataItem[props.field!])}</td>
          )}
          field="installEndsAt"
          title="Install End"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="date"
          cell={(props) => (
            <td>{formatDateTime(props.dataItem[props.field!])}</td>
          )}
          field="deliveryStartsAt"
          title="Delivery Start"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="date"
          field="deliveryEndsAt"
          title="Delivery Complete"
          width={200}
          cell={(props) => (
            <td>{formatDateTime(props.dataItem[props.field!])}</td>
          )}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          field="team"
          title="Team"
          width={200}
        />

        <GridColumn
          filter="numeric"
          columnMenu={ReportColumnMenu}
          field="estimatedHours"
          title="Estimated Hours"
          width={150}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          field="primaryContactName"
          title="Client"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          field="salesRepName"
          title="Sales Rep"
          width={200}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          filter="boolean"
          field="team"
          title="In Full"
          width={100}
        />

        <GridColumn
          columnMenu={ReportColumnMenu}
          field="fulfillmentMethod"
          title="Fulfillment Method"
          width={200}
        />

        <GridColumn
          filterable={false}
          sortable={false}
          groupable={false}
          title="Prepped"
          cell={(props) => (
            <td>{getPreppedStatus(props.dataItem.lineItems)}</td>
          )}
          width={150}
        />
      </Grid>

      <TableFooter className="p-1">
        <Button
          icon="add"
          text="New Fulfillment"
          intent="primary"
          onClick={onCreateFulfillment}
        />
      </TableFooter>
    </div>
  );
}
