import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { Callout } from "@blueprintjs/core";
import { useRouteMatch } from "react-router-dom";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { SalesOrdersTable } from "../../../../components/projects/[projectId]/sales-orders/SalesOrdersTable";
import { Contact, Project } from "../../../../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";
import { ErrorDialog } from "../../../../components/shared/ErrorDialog/ErrorDialog";
import { FormatContactNameContactFragment } from "../../../../helpers/ContactHelper";

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const GetProjectSalesOrders = gql`
  ${FormatContactNameContactFragment}

  query GetProjectSalesOrders($projectId: Int!) {
    salesOrders(where: { projectId: { eq: $projectId } }) {
      id
      code
      name
      quoteId
      project {
        id
        primaryContact {
          ...FormatContactNameContactFragment
        }
      }
      quote {
        id
        code
      }
      createdAt
      updatedAt
    }
  }
`;

type GetProjectSalesOrdersResult = {
  salesOrders: {
    id: number;
    code: string;
    name: string;
    project: {
      id: number;
      primaryContact?: Contact;
    };
    quote: {
      id: number;
      code: string;
    };
    createdAt: string;
  }[];
};

export default function SalesOrders() {
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const [fetchError, setFetchError] = useState<Error>();

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GetProjectById, {
    variables: {
      projectId,
    },
    onError: setFetchError,
  });

  const getProjectSalesOrders = useQuery<
    GetProjectSalesOrdersResult,
    { projectId: number }
  >(GetProjectSalesOrders, {
    variables: { projectId },
  });

  return (
    <>
      <ErrorDialog isOpen={Boolean(fetchError)} error={fetchError} />

      <Helmet>
        <title>{`Sales Orders - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Sales Orders", href: `/projects/${projectId}/sales-orders` },
        ]}
      />

      <PortalContainer>
        {!getProjectSalesOrders.loading &&
          getProjectSalesOrders.data!.salesOrders.length === 0 && (
            <Callout title="No Sales Orders">
              This project does not have any sales orders. You can create a
              sales order from a quote.
            </Callout>
          )}

        {!getProjectSalesOrders.loading &&
          getProjectSalesOrders.data!.salesOrders.length > 0 && (
            <SalesOrdersTable
              salesOrders={getProjectSalesOrders.data!.salesOrders}
            />
          )}
      </PortalContainer>
    </>
  );
}
