import {
  QuickSettingItem,
  QuickSettingsAction,
} from "../../../../../components/shared/QuickSettings/QuickSettingItem";
import { FulfillmentMethodType } from "../../../../../types/ApiTypes";
import React, { useEffect, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  HTMLSelect,
  InputGroup,
} from "@blueprintjs/core";
import { formatFulfillmentMethodType } from "../../../../../helpers/FulfillmentHelper";

type FulfillmentMethodQuickSettingItemProps = {
  value: {
    fulfillmentMethodType?: FulfillmentMethodType;
    fulfillmentMethodNotes?: string;
  };
  onChange(e?: {
    fulfillmentMethodType: FulfillmentMethodType;
    fulfillmentMethodNotes: string;
  }): void;
};

export default function FulfillmentMethodQuickSettingItem({
  value,
  onChange,
}: FulfillmentMethodQuickSettingItemProps) {
  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [fulfillmentMethodType, setFulfillmentMethodType] =
    useState<FulfillmentMethodType>(
      value?.fulfillmentMethodType ?? FulfillmentMethodType.InternalCrew
    );
  const [fulfillmentMethodNotes, setFulfillmentMethodNotes] = useState<string>(
    value?.fulfillmentMethodNotes ?? ""
  );

  useEffect(() => {
    setFulfillmentMethodType(
      value?.fulfillmentMethodType ?? FulfillmentMethodType.InternalCrew
    );
    setFulfillmentMethodNotes(value?.fulfillmentMethodNotes ?? "");
  }, [value]);

  return (
    <>
      <Dialog
        title="Change Fulfillment Method"
        isOpen={isChangeOpen}
        onClose={() => setIsChangeOpen(false)}
        lazy
        usePortal
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup label="Fulfillment Method Type">
            <HTMLSelect
              fill
              value={fulfillmentMethodType}
              onChange={(e) =>
                setFulfillmentMethodType(
                  e.target.value as FulfillmentMethodType
                )
              }
            >
              <option value={FulfillmentMethodType.InternalCrew}>
                Internal Crew
              </option>
              <option value={FulfillmentMethodType.ExternalCrew}>
                External Crew
              </option>
              <option value={FulfillmentMethodType.FreightShipment}>
                Freight Shipment
              </option>
              <option value={FulfillmentMethodType.WillCall}>
                Will Call/Pickup
              </option>
            </HTMLSelect>
          </FormGroup>

          <FormGroup label="Fulfillment Method Notes">
            <InputGroup
              value={fulfillmentMethodNotes}
              onChange={(e) => setFulfillmentMethodNotes(e.target.value)}
              rightElement={
                fulfillmentMethodNotes.length > 0 ? (
                  <Button
                    icon="cross"
                    minimal
                    onClick={() => setFulfillmentMethodNotes("")}
                  />
                ) : undefined
              }
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={() => setIsChangeOpen(false)} />
            <Button
              text="Save Changes"
              intent="primary"
              onClick={() => {
                onChange({
                  fulfillmentMethodType,
                  fulfillmentMethodNotes,
                });
                setIsChangeOpen(false);
              }}
            />
          </div>
        </div>
      </Dialog>

      <QuickSettingItem
        actions={
          <>
            <QuickSettingsAction
              text="Change"
              onClick={() => setIsChangeOpen(true)}
            />
          </>
        }
      >
        <div>
          {value.fulfillmentMethodType ? (
            <div>
              <p className="m-0 font-bold">
                {formatFulfillmentMethodType(value.fulfillmentMethodType)}
              </p>
              {value.fulfillmentMethodNotes && (
                <p className="m-0">{value.fulfillmentMethodNotes}</p>
              )}
            </div>
          ) : (
            <div>
              <p className="m-0 font-bold">Not Selected</p>
            </div>
          )}
        </div>
      </QuickSettingItem>
    </>
  );
}
