import { useEffect, useState } from "react";
import { CatalogProduct, CatalogSection } from "../../../types/ApiTypes";
import { ITreeNode } from "@blueprintjs/core";
import axios from "axios";

export type SectionedCatalogProduct = CatalogProduct & {
  parentSectionId: number;
};

export type CatalogNodeData = (SectionedCatalogProduct | CatalogSection) & {
  type: "product" | "section";
};

export function useProductTreeData(manufacturerId: number) {
  const [loading, setLoading] = useState(true);
  const [catalogProducts, setCatalogProducts] = useState<{
    [key: string]: SectionedCatalogProduct;
  }>({});
  const [catalogSections, setCatalogSections] = useState<{
    [key: string]: CatalogSection;
  }>({});
  const [contents, setContents] = useState<ITreeNode<CatalogNodeData>[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<number[]>([]);
  const [selectedNode, setSelectedNode] = useState<
    ITreeNode<CatalogNodeData> | undefined
  >();

  function onSelect(node: ITreeNode<CatalogNodeData>) {
    const data = node.nodeData as CatalogNodeData;

    if (data.type === "product") {
      setSelectedNode(node);
    } else if (data.type === "section") {
      if (node.isExpanded) {
        onCollapse(node);
      } else {
        onExpand(node);
      }
    }
  }

  async function onExpand(node: ITreeNode<CatalogNodeData>) {
    const sectionResponse = await axios.get(
      `/catalog/sections/${node.id}/sections`
    );
    const productResponse = await axios.get(
      `/catalog/sections/${node.id}/products`
    );

    setCatalogSections((s) => {
      const sections: CatalogSection[] = sectionResponse.data;
      sections.forEach((section) => (s[section.id] = section));
      return s;
    });

    setCatalogProducts((s) => {
      const products: CatalogProduct[] = productResponse.data;
      products.forEach(
        (product) =>
          (s[product.id] = { ...product, parentSectionId: node.id as number })
      );
      return s;
    });

    setExpandedNodes((e) => [...e, node.id as number]);
  }

  function onCollapse(node: ITreeNode<CatalogNodeData>) {
    setExpandedNodes((e) => e.filter((n) => n !== node.id));
  }

  async function fetchRootSections() {
    const optionsRes = await axios.get(`/catalog/${manufacturerId}/sections`);
    const rootSections = optionsRes.data as CatalogSection[];

    setCatalogSections((cs) => {
      rootSections.forEach((section) => (cs[section.id] = section));
      return cs;
    });
  }

  useEffect(() => {
    function buildChildNodes(
      parentSection: CatalogSection
    ): ITreeNode<CatalogNodeData>[] {
      const sections: CatalogSection[] = Object.values(catalogSections).filter(
        (o) => o.parentSectionId === parentSection.id
      );
      const products: SectionedCatalogProduct[] = Object.values(
        catalogProducts
      ).filter((o) => o.parentSectionId === parentSection.id);

      const sectionTreeNodes: ITreeNode<CatalogNodeData>[] = sections.map(
        (s) => ({
          id: s.id,
          label: s.description,
          hasCaret: true,
          icon: expandedNodes.includes(s.id) ? "folder-open" : "folder-close",
          isExpanded: expandedNodes.includes(s.id),
          childNodes: buildChildNodes(s),
          nodeData: { type: "section", ...(s as CatalogSection) },
        })
      );

      const productTreeNodes: ITreeNode<CatalogNodeData>[] = products.map(
        (p) => ({
          id: p.id,
          label: p.description,
          hasCaret: false,
          icon: "tag",
          isExpanded: false,
          isSelected: selectedNode && selectedNode.id === p.id,
          nodeData: { type: "product", ...(p as SectionedCatalogProduct) },
        })
      );

      return [...sectionTreeNodes, ...productTreeNodes];
    }

    function buildTreeContents() {
      const rootSections = Object.values(catalogSections).filter(
        (o) => o.parentSectionId === null
      );

      const rootTreeNodes: ITreeNode<CatalogNodeData>[] = rootSections.map(
        (s) => ({
          id: s.id,
          label: s.description,
          hasCaret: true,
          icon: expandedNodes.includes(s.id) ? "folder-open" : "folder-close",
          isExpanded: expandedNodes.includes(s.id),
          childNodes: buildChildNodes(s),
          nodeData: { type: "section", ...(s as CatalogSection) },
        })
      );

      setContents(rootTreeNodes);
    }

    if (Object.keys(catalogSections).length === 0) {
      fetchRootSections()
        .then(() => buildTreeContents())
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    } else {
      buildTreeContents();
    }
  }, [
    catalogProducts,
    catalogSections,
    expandedNodes,
    selectedNode,
    manufacturerId,
  ]);

  return { loading, contents, selectedNode, onExpand, onCollapse, onSelect };
}
