import { IItemRendererProps, Select } from "@blueprintjs/select";
import { Button, MenuItem } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";

type AddCompanyButtonCompany = {
  id: number;
  name: string;
};

const GetCompanies = gql`
  query GetCompanies {
    companies(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetCompaniesResult = {
  companies: {
    id: number;
    name: string;
  }[];
};

const PersonSelect = Select.ofType<AddCompanyButtonCompany>();

export type AddCompanyButtonProps = {
  onAddCompany: (company: AddCompanyButtonCompany) => void;
};

export function AddCompanyButton({ onAddCompany }: AddCompanyButtonProps) {
  const getCompanies = useQuery<GetCompaniesResult>(GetCompanies, {
    fetchPolicy: "cache-and-network",
  });

  const filterCompany = (query: string, item: AddCompanyButtonCompany) => {
    const normalizedQuery = query.toLowerCase().trim();
    if (normalizedQuery.length === 0) return true;

    const normalizedName = item.name.toLowerCase().trim();
    return normalizedName.includes(normalizedQuery);
  };

  const renderCompany = (
    item: AddCompanyButtonCompany,
    props: IItemRendererProps
  ) => {
    return (
      <MenuItem
        text={item.name}
        active={props.modifiers.active}
        disabled={props.modifiers.disabled}
        onClick={props.handleClick}
      />
    );
  };

  return (
    <PersonSelect
      items={getCompanies.data?.companies ?? []}
      itemPredicate={filterCompany}
      itemRenderer={renderCompany}
      onItemSelect={(company: AddCompanyButtonCompany) => onAddCompany(company)}
    >
      <Button icon="plus" />
    </PersonSelect>
  );
}
