import { Button, InputGroup, Intent, NonIdealState } from "@blueprintjs/core";
import { ContactSidebar } from "../../../components/contacts/ContactSidebar";
import { ContactSidebarItem } from "../../../components/contacts/ContactSidebarItem";
import { Route, useHistory, useParams } from "react-router-dom";
import { PersonDetails } from "./[personId]";
import { useCallback, useState } from "react";
import { CreatePersonPayloadInput } from "../../../types/ApiTypes";
import { gql, useMutation, useQuery } from "@apollo/client";

const CreatePerson = gql`
  mutation CreatePerson($payload: CreatePersonPayloadInput!) {
    createPerson(payload: $payload) {
      id
    }
  }
`;

const GetPeople = gql`
  query GetPeople {
    people(order: { firstName: ASC }) {
      id
      firstName
      lastName
    }
  }
`;

type GetPeopleResult = {
  people: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
};

export type PeopleRouteParams = {
  personId?: string;
};

export function People() {
  const { personId } = useParams<PeopleRouteParams>();
  const history = useHistory();
  const [search, setSearch] = useState("");

  const getPeople = useQuery<GetPeopleResult>(GetPeople, {
    fetchPolicy: "cache-and-network",
  });

  const [createPerson] = useMutation<
    { createPerson: { id: number } },
    { payload: CreatePersonPayloadInput }
  >(CreatePerson, {
    refetchQueries: ["GetPeople"],
  });

  const people = getPeople.data?.people ?? [];

  const filterPeople = useCallback(() => {
    const normalizedSearch = search.toLowerCase().trim();
    if (normalizedSearch.length === 0) return [...people];
    return people.filter((c) => {
      const normalizedName = `${c.firstName} ${c.lastName}`
        .toLowerCase()
        .trim();
      return normalizedName.includes(normalizedSearch);
    });
  }, [search, people]);

  const addPerson = useCallback(async () => {
    const newPerson = await createPerson({
      variables: {
        payload: {
          firstName: "New",
          lastName: "User",
          email: "",
          phone: "",
        },
      },
    });

    history.push(`/contacts/people/${newPerson.data!.createPerson.id}`);
  }, [createPerson, history]);

  return (
    <div className="flex h-full items-stretch">
      <ContactSidebar
        searchElement={
          <>
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search people"
              className="flex-1 mr-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button minimal icon="add" onClick={addPerson} />
          </>
        }
      >
        {!getPeople.loading &&
          filterPeople().map((person) => (
            <ContactSidebarItem
              active={!!personId && personId === person.id!.toString()}
              title={`${person.firstName} ${person.lastName}`}
              key={person.id}
              onClick={() => history.push(`/contacts/people/${person.id}`)}
            />
          ))}
      </ContactSidebar>

      <div className="flex-1">
        <Route path="/contacts/people/:personId" component={PersonDetails} />

        <Route
          path="/contacts/people"
          exact
          render={() => (
            <NonIdealState
              icon="user"
              title="No Person Selected"
              action={
                <Button
                  icon="add"
                  text="New Person"
                  intent={Intent.PRIMARY}
                  onClick={addPerson}
                />
              }
            />
          )}
        />
      </div>
    </div>
  );
}
