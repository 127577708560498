import { Classes, Dialog, DialogProps } from "@blueprintjs/core";
import { ManageInventoryItemAttachments } from "./ManageInventoryItemAttachments";

type ManageInventoryItemAttachmentsDialogProps = DialogProps & {
  inventoryItemId?: number;
};

export function ManageInventoryItemAttachmentsDialog({
  inventoryItemId,
  ...props
}: ManageInventoryItemAttachmentsDialogProps) {
  return (
    <Dialog title="Inventory Item Attachments" {...props}>
      <div className={Classes.DIALOG_BODY}>
        {inventoryItemId && (
          <ManageInventoryItemAttachments inventoryItemId={inventoryItemId!} />
        )}
      </div>
    </Dialog>
  );
}
