import { InventoryItemLabel } from "../types/ApiTypes";
import { InventoryItemLabelWithQRCode } from "../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PrintLabelsPdf";
import QRCode from "qrcode";

export function getQrCodeStringForLabel(label: InventoryItemLabel): string {
  return `https://web.wof-erp.com/qrcode/${label.id}`;
}

export function getQrCodeDataUrlForLabel(
  label: InventoryItemLabel
): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    QRCode.toDataURL(getQrCodeStringForLabel(label), (err, dataUrl) => {
      if (err) reject(err);
      else resolve(dataUrl);
    });
  });
}
