import classNames from "classnames";
import { OptionSelection } from "./OptionSelection";
import { CatalogOptionGroup } from "../../../types/ApiTypes";
import { gql } from "@apollo/client";
import React, { Dispatch, SetStateAction } from "react";
import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";
import { SIFEntryFeature } from "../../../hooks/useDecodeSifLineItems";
import { Button, Icon } from "@blueprintjs/core";
import produce from "immer";

export const OPTION_OPTION_GROUP_FRAGMENT = gql`
  fragment OptionOptionGroupFragment on OptionGroup {
    code
    description
  }
`;

type OptionProps = {
  configuration: EditableProductConfiguration;
  sifEntryFeature?: SIFEntryFeature;
  onSifEntryFeatureChange?: (newValue: SIFEntryFeature) => void;
  setConfiguration: Dispatch<SetStateAction<EditableProductConfiguration>>;
  optionGroup: CatalogOptionGroup;
};

export function OptionGroup({
  optionGroup,
  sifEntryFeature,
  onSifEntryFeatureChange,
  configuration,
  setConfiguration,
}: OptionProps) {
  const feature = configuration.features.find(
    (f) => f.catalogOptionGroupId === optionGroup.id
  )!;
  const selections = feature.selections;
  const missingSelection =
    selections.filter((s) => !s.catalogOptionId).length > 0;
  const unresolvedSelections = sifEntryFeature?.unresolvedSelections;

  return (
    <div
      className={classNames(
        "rounded-md shadow bg-white ring-1 overflow-hidden",
        {
          "ring-gray-200": !missingSelection,
          "ring-red-200": missingSelection,
        }
      )}
    >
      <div className="border-b p-2 flex items-center h-10">
        <h3 className="text-sm font-medium flex-1">
          {optionGroup.description}
        </h3>
        {missingSelection && (
          <p className="p-1 rounded bg-red-100 text-red-700 m-0">
            Missing Selection
          </p>
        )}
      </div>
      <div className="divide-y space-y-0">
        {selections.map((s, index) => (
          <OptionSelection
            rootOptionGroupId={optionGroup.id}
            selection={s}
            selectionIndex={index}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        ))}

        {unresolvedSelections &&
          unresolvedSelections.filter((sf) => !sf.isDismissed).length > 0 && (
            <div className="p-2 bg-red-50">
              <p className="m-0 text-red-600 font-bold">
                The following values in the SIF file could not be resolved:
              </p>
            </div>
          )}

        {unresolvedSelections?.map((sf, i) => (
          <>
            {!sf.isDismissed && (
              <div className="p-1 pl-3 space-x-2 mt-1 flex items-center">
                <Icon icon="warning-sign" className="text-red-400 mr-2" />
                <div className="flex-1">
                  <p className="m-0 text-red-600">
                    {sf.optionDescription} ({sf.optionCode})
                  </p>
                </div>
                <Button
                  minimal
                  icon="cross"
                  text="Dismiss"
                  onClick={() =>
                    onSifEntryFeatureChange!(
                      produce(sifEntryFeature, (draft) => {
                        draft!.unresolvedSelections[i].isDismissed = true;
                      })!
                    )
                  }
                />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
