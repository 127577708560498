import { Button, InputGroup, Intent, NonIdealState } from "@blueprintjs/core";
import { ContactSidebar } from "../../../components/contacts/ContactSidebar";
import { ContactSidebarItem } from "../../../components/contacts/ContactSidebarItem";
import { Route, useHistory, useParams } from "react-router-dom";
import { VendorCompanyDetails } from "./[vendorId]";
import { useCallback, useState } from "react";
import { CompanyType, CreateVendorPayloadInput } from "../../../types/ApiTypes";
import { gql, useMutation, useQuery } from "@apollo/client";

const CreateVendor = gql`
  mutation CreateVendor($payload: CreateVendorPayloadInput!) {
    createVendor(payload: $payload) {
      id
    }
  }
`;

const GetVendors = gql`
  query GetVendors {
    vendors(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetVendorsResult = {
  vendors: {
    id: string;
    name: string;
  }[];
};

export type VendorCompaniesRouteParams = {
  vendorId?: string;
};

export function VendorCompanies() {
  const { vendorId } = useParams<VendorCompaniesRouteParams>();
  const history = useHistory();
  const [search, setSearch] = useState("");

  const getVendors = useQuery<GetVendorsResult>(GetVendors, {
    fetchPolicy: "cache-and-network",
  });

  const [createVendor] = useMutation<
    { createVendor: { id: number } },
    { payload: CreateVendorPayloadInput }
  >(CreateVendor, {
    refetchQueries: ["GetVendors"],
  });

  const companies = getVendors.data?.vendors ?? [];

  const filterVendors = useCallback(() => {
    const normalizedSearch = search.toLowerCase().trim();
    if (normalizedSearch.length === 0) return [...companies];
    return companies.filter((c) => {
      const normalizedName = c.name.toLowerCase().trim();
      return normalizedName.includes(normalizedSearch);
    });
  }, [search, companies]);

  const addVendor = useCallback(async () => {
    const newVendor = await createVendor({
      variables: {
        payload: {
          name: "New Vendor",
          type: CompanyType.Other,
          email: "",
          phone: "",
        },
      },
    });

    history.push(`/contacts/vendors/${newVendor.data?.createVendor.id}`);
  }, [createVendor, history]);

  return (
    <div className="flex h-full items-stretch">
      <ContactSidebar
        searchElement={
          <>
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search vendors"
              className="flex-1 mr-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button minimal icon="add" onClick={addVendor} />
          </>
        }
      >
        {!getVendors.loading &&
          filterVendors().map((vendor) => (
            <ContactSidebarItem
              active={!!vendorId && vendorId === vendor.id!.toString()}
              title={vendor.name}
              key={vendor.id}
              onClick={() => history.push(`/contacts/vendors/${vendor.id}`)}
            />
          ))}
      </ContactSidebar>

      <div className="flex-1">
        <Route
          path="/contacts/vendors/:vendorId"
          component={VendorCompanyDetails}
        />

        <Route
          path="/contacts/vendors"
          exact
          render={() => (
            <NonIdealState
              icon="truck"
              title="No Vendor Selected"
              action={
                <Button
                  icon="add"
                  text="New Vendor"
                  intent={Intent.PRIMARY}
                  onClick={addVendor}
                />
              }
            />
          )}
        />
      </div>
    </div>
  );
}
