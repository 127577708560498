import { DrawerTitleToolbar } from "../DrawerTitleToolbar/DrawerTitleToolbar";
import {
  Button,
  Checkbox,
  NonIdealState,
  NumericInput,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import {
  getFullConfigurationCode,
  GetFullConfigurationCodeFragment,
  getFullConfigurationDescription,
  GetFullConfigurationDescriptionFragment,
} from "../../../helpers/CatalogHelper";
import { ProductConfiguration } from "../../../types/ApiTypes";
import { TableHeader } from "../Table/TableHeader";
import { BasicTable } from "../Table/BasicTable";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

type GetFulfillmentAndSalesOrderLineItemsResult = {
  fulfillments: {
    id: number;
    lineItems: {
      id: number;
      salesOrderLineItemId: number;
    }[];
  }[];
  salesOrderLineItems: {
    id: number;
    quantity: number;
    configuration: ProductConfiguration;
    salesOrderLineItemGroup: {
      id: number;
      salesOrder: {
        id: number;
        code: string;
      };
    };
  }[];
};

const GetFulfillmentAndSalesOrderLineItems = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetFulfillmentAndSalesOrderLineItems(
    $fulfillmentId: Int!
    $projectId: Int!
  ) {
    fulfillments(where: { id: { eq: $fulfillmentId } }) {
      id
      lineItems {
        id
        salesOrderLineItemId
      }
    }

    salesOrderLineItems(
      where: {
        salesOrderLineItemGroup: {
          salesOrder: { projectId: { eq: $projectId } }
        }
        deletedAt: { eq: null }
      }
    ) {
      id
      quantity
      configuration {
        id
        ...GetFullConfigurationCodeFragment
        ...GetFullConfigurationDescriptionFragment
      }
      salesOrderLineItemGroup {
        id
        salesOrder {
          id
          code
        }
      }
    }
  }
`;

type AddFulfillmentLineItemProps = {
  projectId: number;
  fulfillmentId: number;
  onAdd: (lineItems: { [lineItemId: number]: number }) => void;
  onClose: () => void;
};

export function AddFulfillmentLineItems({
  projectId,
  fulfillmentId,
  onAdd,
  onClose,
}: AddFulfillmentLineItemProps) {
  const [selectedLineItems, setSelectedLineItems] = useState<{
    [lineItemId: number]: number;
  }>({});

  const getFulfillmentAndSalesOrderLineItems = useQuery<
    GetFulfillmentAndSalesOrderLineItemsResult,
    { projectId: number; fulfillmentId: number }
  >(GetFulfillmentAndSalesOrderLineItems, {
    fetchPolicy: "cache-and-network",
    variables: { projectId, fulfillmentId },
  });

  const lineItems =
    getFulfillmentAndSalesOrderLineItems.data?.salesOrderLineItems ?? [];

  useEffect(() => {
    setSelectedLineItems(
      lineItems.reduce((acc, li) => {
        acc[li.id] = li.quantity;
        return acc;
      }, {} as { [lineItemId: number]: number })
    );
  }, [lineItems]);

  return (
    <>
      <DrawerTitleToolbar title="Add Line Items" onClose={onClose}>
        <Button
          text="Add Items"
          intent="primary"
          onClick={() => onAdd(selectedLineItems)}
        />
      </DrawerTitleToolbar>

      <div className="p-2 space-y-2 overflow-y-auto">
        {lineItems.length > 0 ? (
          <div>
            <TableHeader className="p-1 pl-3 justify-between">
              <h2 className="text-md font-semibold">
                Project Sales Order Line Items
              </h2>
            </TableHeader>

            <BasicTable header>
              <thead>
                <tr>
                  <th>SO #</th>
                  <th>Product Code</th>
                  <th>Product Description</th>
                  <th>Original Quantity</th>
                  <th>Quantity to Add</th>
                </tr>
              </thead>

              <tbody>
                {lineItems.map((salesOrderLineItem) => (
                  <tr key={salesOrderLineItem.id}>
                    <td>
                      <Link
                        to={`/projects/${projectId}/sales-orders/${salesOrderLineItem.salesOrderLineItemGroup.salesOrder.id}`}
                      >
                        {
                          salesOrderLineItem.salesOrderLineItemGroup.salesOrder
                            .code
                        }
                      </Link>
                    </td>

                    <td
                      dangerouslySetInnerHTML={{
                        __html: getFullConfigurationCode(
                          salesOrderLineItem.configuration
                        ).replaceAll("\n", " "),
                      }}
                    />

                    <td
                      dangerouslySetInnerHTML={{
                        __html: getFullConfigurationDescription(
                          salesOrderLineItem.configuration
                        ).replaceAll("\n", " "),
                      }}
                    />

                    <td>{salesOrderLineItem.quantity}</td>

                    <td>
                      <NumericInput
                        value={selectedLineItems[salesOrderLineItem.id] ?? 0}
                        onValueChange={(value) => {
                          setSelectedLineItems({
                            ...selectedLineItems,
                            [salesOrderLineItem.id]: value,
                          });
                        }}
                        max={salesOrderLineItem.quantity}
                        min={0}
                        fill
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          </div>
        ) : (
          <div className="py-10">
            <NonIdealState
              icon="tick-circle"
              title="No Line Items"
              description="All sales order line items have been added to this fulfillment."
              action={<Button text="Close" onClick={onClose} />}
            />
          </div>
        )}
      </div>
    </>
  );
}
