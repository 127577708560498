import { useCallback, useEffect, useState } from "react";
import { QuoteVersionGroup } from "../types/ApiTypes";
import axios from "axios";
import { useApiError } from "./useApiError";

export type UseProjectQuoteVersionGroupsArgs = {
  projectId: number;
};

export function useProjectQuoteVersionGroups({
  projectId,
}: UseProjectQuoteVersionGroupsArgs) {
  const handleUpdateError = useApiError("update version group");
  const handleDeleteError = useApiError("delete version group");

  const [quoteVersionGroups, setQuoteVersionGroups] = useState<
    QuoteVersionGroup[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();

  const duplicateQuoteVersionGroup = useCallback(
    (quoteVersionGroup: QuoteVersionGroup) => {
      axios
        .post(`quoteversiongroups/${quoteVersionGroup.id}/duplicate`)
        .then((r) => r.data as QuoteVersionGroup)
        .then((newQuoteVersionGroup) => {
          setQuoteVersionGroups((state) => [...state, newQuoteVersionGroup]);
        })
        .catch(handleUpdateError);
    },
    [projectId]
  );

  const createQuoteVersionGroup = useCallback(
    (quoteVersionGroup: QuoteVersionGroup) => {
      axios
        .post("quoteversiongroups", quoteVersionGroup)
        .then((r) => r.data as QuoteVersionGroup)
        .then((newQuoteVersionGroup) => {
          setQuoteVersionGroups((state) => [...state, newQuoteVersionGroup]);
        })
        .catch(handleUpdateError);
    },
    [projectId]
  );

  const updateQuoteVersionGroup = useCallback(
    (quoteVersionGroup: QuoteVersionGroup) => {
      setQuoteVersionGroups([
        ...quoteVersionGroups.map((qvg) =>
          qvg.id == quoteVersionGroup.id ? quoteVersionGroup : qvg
        ),
      ]);

      axios
        .put(`quoteversiongroups/${quoteVersionGroup.id}`, quoteVersionGroup)
        .then((r) => r.data as QuoteVersionGroup)
        .then((updatedQuoteVersionGroup) => {
          setQuoteVersionGroups([
            ...quoteVersionGroups.map((qvg) =>
              qvg.id == updatedQuoteVersionGroup.id
                ? updatedQuoteVersionGroup
                : qvg
            ),
          ]);
        })
        .catch(handleUpdateError);
    },
    [projectId, quoteVersionGroups]
  );

  const deleteQuoteVersionGroup = useCallback(
    (quoteVersionGroupId: number) => {
      setQuoteVersionGroups([
        ...quoteVersionGroups.filter((qvg) => qvg.id !== quoteVersionGroupId),
      ]);

      axios
        .delete(`quoteversiongroups/${quoteVersionGroupId}`)
        .catch(handleDeleteError);
    },
    [projectId, quoteVersionGroups]
  );

  const refresh = useCallback(() => {
    if (!projectId) return;

    axios
      .get(`projects/${projectId}/quoteversiongroups`)
      .then((r) => r.data as QuoteVersionGroup[])
      .then(setQuoteVersionGroups)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [projectId]);

  useEffect(() => {
    refresh();
  }, [refresh, projectId]);

  return {
    quoteVersionGroups,
    loading,
    error,
    refresh,
    duplicateQuoteVersionGroup,
    createQuoteVersionGroup,
    updateQuoteVersionGroup,
    deleteQuoteVersionGroup,
  };
}
