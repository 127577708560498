import { UpdateVendorPayloadInput, Vendor } from "../../../types/ApiTypes";
import React, { useCallback, useEffect, useState } from "react";
import { Button, FormGroup, Intent, NumericInput } from "@blueprintjs/core";

type VendorPricingItemProps = {
  vendor: Vendor;
  onChange: (payload: UpdateVendorPayloadInput) => void;
  loading: boolean;
};

export function VendorPricingItem({
  vendor,
  onChange,
  loading,
}: VendorPricingItemProps) {
  const [listPriceAdjustment, setListPriceAdjustment] = useState(
    vendor.listPriceAdjustment.toString()
  );

  useEffect(() => {
    setListPriceAdjustment(vendor.listPriceAdjustment.toString());
  }, [vendor.listPriceAdjustment]);

  const resetChanges = useCallback(() => {
    setListPriceAdjustment(vendor.listPriceAdjustment.toString());
  }, [listPriceAdjustment, setListPriceAdjustment, vendor.listPriceAdjustment]);

  const saveChanges = useCallback(() => {
    onChange({
      id: vendor.id,
      listPriceAdjustment: parseFloat(listPriceAdjustment),
    });
  }, [listPriceAdjustment, onChange]);

  const dirty = listPriceAdjustment !== vendor.listPriceAdjustment.toString();

  return (
    <div className="rounded-md border bg-white divide-y overflow-hidden">
      <div className="p-2 bg-gray-50 flex items-center justify-between">
        <h1 className="text-lg font-medium m-0">{vendor.name}</h1>
        {dirty && (
          <div className="flex items-center space-x-2">
            <Button
              minimal
              text="Reset"
              onClick={resetChanges}
              disabled={loading}
            />
            <Button
              intent={Intent.PRIMARY}
              text="Save Changes"
              loading={loading}
              disabled={loading}
              onClick={saveChanges}
            />
          </div>
        )}
      </div>
      <div className="p-2">
        <FormGroup
          inline
          label="List Price Adjustment"
          style={{ margin: 0, width: "100%" }}
        >
          <NumericInput
            value={listPriceAdjustment}
            onValueChange={(e, x) => setListPriceAdjustment(x)}
            stepSize={0.01}
            minorStepSize={0.01}
            majorStepSize={0.1}
            min={0}
            className="m-0"
          />
        </FormGroup>
      </div>
    </div>
  );
}
