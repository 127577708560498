import React, { useEffect, useState } from "react";
import {
  Contact,
  QuoteVersionGroup as ApiQuoteVersionGroup,
} from "../../../../types/ApiTypes";
import { TableHeader } from "../../../shared/Table/TableHeader";
import {
  Button,
  ButtonGroup,
  Collapse,
  EditableText,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { TableFooter } from "../../../shared/Table/TableFooter";
import { useQuoteVersionGroupQuotes } from "../../../../hooks/useQuoteVersionGroupQuotes";
import { QuotesTable } from "./QuotesTable";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { FormatContactNameContactFragment } from "../../../../helpers/ContactHelper";

export type QuoteVersionGroupProps = {
  quoteVersionGroup: ApiQuoteVersionGroup;
  onChange: (qvg: ApiQuoteVersionGroup) => void;
  onDelete: () => void;
  onSettings: () => void;
};

const GetQuoteVersionGroupQuotes = gql`
  ${FormatContactNameContactFragment}

  query GetQuoteVersionGroupQuotes($quoteVersionGroupId: Int!) {
    quotes(
      where: {
        quoteVersionGroupId: { eq: $quoteVersionGroupId }
        deletedAt: { eq: null }
      }
    ) {
      id
      code
      name
      salesOrder {
        id
        code
        createdAt
      }
      quoteVersionGroup {
        id
        project {
          id
          primaryContact {
            ...FormatContactNameContactFragment
          }
        }
      }
      updatedAt
      createdAt
    }
  }
`;

type GetQuoteVersionGroupQuotesResult = {
  quotes: {
    id: number;
    code: string;
    name: string;
    salesOrder?: {
      id: number;
      code: string;
      createdAt: string;
    };
    quoteVersionGroup: {
      id: number;
      project: {
        id: number;
        primaryContact?: Contact;
      };
    };
    updatedAt: string;
    createdAt: string;
  }[];
};

export function QuoteVersionGroup({
  quoteVersionGroup,
  onChange,
  onSettings,
  onDelete,
}: QuoteVersionGroupProps) {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [groupName, setGroupName] = useState(quoteVersionGroup.name);

  const { createQuote } = useQuoteVersionGroupQuotes({
    projectId: quoteVersionGroup.projectId,
    quoteVersionGroupId: quoteVersionGroup.id!,
  });

  const getQuoteVersionGroupQuotes = useQuery<
    GetQuoteVersionGroupQuotesResult,
    { quoteVersionGroupId: number }
  >(GetQuoteVersionGroupQuotes, {
    variables: {
      quoteVersionGroupId: quoteVersionGroup.id!,
    },
  });

  const quotes = getQuoteVersionGroupQuotes.data?.quotes ?? [];

  useEffect(() => {
    setGroupName(quoteVersionGroup.name);
  }, [quoteVersionGroup.name]);

  const addQuote = async () => {
    const defaultName = `${quoteVersionGroup.name} Version ${
      quotes.length + 1
    }`;

    const newQuote = await createQuote({
      name: defaultName,
      quoteVersionGroupId: quoteVersionGroup.id!,
    });

    history.push(
      `/projects/${quoteVersionGroup.projectId}/quotes/${newQuote.id}`
    );
  };

  return (
    <>
      <div key={quoteVersionGroup.id} className="mb-4">
        <TableHeader className="p-1" collapsed={collapsed}>
          <Button
            minimal
            icon={collapsed ? "chevron-right" : "chevron-down"}
            className="mr-2"
            onClick={() => setCollapsed(!collapsed)}
          />

          <h4 className="text-md font-semibold m-0 flex-1">
            <EditableText
              value={groupName}
              onChange={setGroupName}
              onConfirm={(value) => {
                onChange({
                  ...quoteVersionGroup,
                  name: value,
                });
              }}
            />
          </h4>
        </TableHeader>

        <Collapse isOpen={!collapsed}>
          {!getQuoteVersionGroupQuotes.loading && quotes.length === 0 && (
            <div className="flex items-center justify-center p-4 border">
              <p className="text-gray-400">No Quotes</p>
            </div>
          )}

          {!getQuoteVersionGroupQuotes.loading && quotes.length > 0 && (
            <QuotesTable quotes={quotes} />
          )}

          <TableFooter className="p-1">
            {!quoteVersionGroup.hasConvertedQuote && (
              <Button
                icon="add"
                intent="primary"
                text="New Quote"
                className="mr-2"
                onClick={addQuote}
              />
            )}
            {quoteVersionGroup.hasConvertedQuote && (
              <Tooltip content="A new quote can't be added to this group because a quote in the group has already been converted.">
                <Button icon="add" text="New Quote" className="mr-2" disabled />
              </Tooltip>
            )}
          </TableFooter>
        </Collapse>
      </div>
    </>
  );
}
