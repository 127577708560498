import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GetSalesDashboard,
  GetSalesDashboardParams,
  GetSalesDashboardResult,
} from "./queries";
import { Button, Callout, Icon, Spinner, Tooltip } from "@blueprintjs/core";
import greetingTime from "greeting-time";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatContactName } from "../../../helpers/ContactHelper";
import {
  encodePriceAsInteger,
  formatPrice,
} from "../../../helpers/PriceHelper";
import { EntityLink } from "../../../components/reports/EntityLink";
import { ReportColumnMenu } from "../../../components/reports/ReportColumnMenu";
import { useReportDataState } from "../../../hooks/useReportDataState";
import { process } from "@progress/kendo-data-query";
import {
  CreateCompany,
  CreateCompanyParams,
  CreateCompanyResult,
  CreateQuote,
  CreateQuoteParams,
  CreateQuoteResult,
} from "./mutations";
import { Link, useHistory } from "react-router-dom";
import { formatDate } from "../../../helpers/DateHelper";
import { ContactRole } from "../../../types/ApiTypes";

export function SalesDashboard() {
  const { user } = useAuth0();
  const history = useHistory();

  const getSalesDashboard = useQuery<
    GetSalesDashboardResult,
    GetSalesDashboardParams
  >(GetSalesDashboard, {
    fetchPolicy: "cache-and-network",
    variables: { auth0Id: user!.sub! },
    skip: !user?.sub,
  });

  const [createCompany] = useMutation<CreateCompanyResult, CreateCompanyParams>(
    CreateCompany,
    { refetchQueries: [GetSalesDashboard] }
  );

  const [createQuote] = useMutation<CreateQuoteResult, CreateQuoteParams>(
    CreateQuote,
    { refetchQueries: [GetSalesDashboard] }
  );

  const [quotesDataState, setQuotesDataState] = useReportDataState({
    sort: [{ field: "code", dir: "desc" }],
  });

  const [salesOrdersDataState, setSalesOrdersDataState] = useReportDataState({
    sort: [{ field: "code", dir: "desc" }],
  });

  if (getSalesDashboard.loading) {
    return (
      <div className="p-4 bg-gray-50 h-full overflow-auto">
        <Spinner />
      </div>
    );
  }

  const account = getSalesDashboard.data!.accounts[0];

  const companies = getSalesDashboard.data!.companies;

  const quotes = getSalesDashboard
    .data!.quotes.filter((q) => !q.salesOrder)
    .map((q) => ({
      ...q,
      clientName: formatContactName(q.quoteVersionGroup.project.primaryContact),
      totalAmountDecimal: q.totalAmount / 100,
      requestedFulfillmentDate: q.requestedFulfillmentDate
        ? new Date(q.requestedFulfillmentDate)
        : "–",
      salesRep: q.quoteVersionGroup.project.salesRepAccount?.name,
      createdAt: new Date(q.createdAt),
    }));

  const salesOrders = getSalesDashboard.data!.salesOrders.map((so) => ({
    ...so,
    clientName: formatContactName(so.project.primaryContact),
    totalAmountDecimal: so.totalAmount / 100,
    requestedFulfillmentDate: so.requestedFulfillmentDate
      ? new Date(so.requestedFulfillmentDate)
      : "–",
    salesRep: so.project.salesRepAccount?.name,
    createdAt: new Date(so.createdAt),
  }));

  async function createQuoteForCompany(
    companyId: number,
    companyName: string,
    personId: number
  ) {
    const result = await createQuote({
      variables: {
        payload: {
          salesRepAccountId: account.id,
          quoteName: `${companyName} ${formatDate(new Date())}`,
          primaryContactPersonId: personId,
          primaryContactCompanyId: companyId,
          createQuoteLineItemPayloads: [],
          createQuoteLineItemGroupPayloads: [],
        },
      },
    });

    history.push(
      `/projects/${result.data?.commitCreateQuoteDraft.createdProject.id}/quotes/${result.data?.commitCreateQuoteDraft.createdQuote.id}`
    );
  }

  async function createQuoteForSalesRep() {
    const result = await createQuote({
      variables: {
        payload: {
          quoteName: "Untitled Quote",
          salesRepAccountId: account.id,
          createQuoteLineItemPayloads: [],
          createQuoteLineItemGroupPayloads: [],
        },
      },
    });

    history.push(
      `/projects/${result.data?.commitCreateQuoteDraft.createdProject.id}/quotes/${result.data?.commitCreateQuoteDraft.createdQuote.id}`
    );
  }

  async function createCompanyForSalesRep() {
    const createdCompany = await createCompany({
      variables: {
        payload: {
          name: "New Company",
          defaultSalesRepId: account.id,
        },
      },
    });

    history.push(
      `/contacts/companies/${createdCompany.data?.createCompany.id}`
    );
  }

  return (
    <div className="bg-gray-50 h-full relative">
      <div
        style={{
          backgroundImage:
            "url(https://images.unsplash.com/photo-1511576661531-b34d7da5d0bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format)",
        }}
        className="bg-cover bg-center bg-no-repeat h-1/3"
      />

      <div className="p-6 space-y-4 z-20" style={{ marginTop: -200 }}>
        <div className="px-4">
          <h1 className="text-4xl text-white font-medium m-0">
            {greetingTime(new Date())}, {account.name.split(" ")[0]}!
          </h1>
        </div>

        <div className="flex space-x-4">
          <div className="w-full space-y-4">
            <div className="bg-white p-4 rounded shadow space-y-4">
              <div className="flex items-center space-x-4">
                <h2 className="text-base font-medium flex-1">Your Quotes</h2>

                <EntityLink url={`/sales/quotes`} text={"View All Quotes"} />

                <Button
                  text="New Quote"
                  intent="success"
                  icon="plus"
                  onClick={createQuoteForSalesRep}
                />
              </div>

              {quotes.length > 0 ? (
                <div className="space-y-4">
                  <div className="rounded overflow-hidden">
                    <Grid
                      data={process(quotes, quotesDataState)}
                      {...quotesDataState}
                      onDataStateChange={(e) => setQuotesDataState(e.dataState)}
                      filterable
                      sortable
                    >
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="code"
                        title="Code"
                        cell={(props) => (
                          <td>
                            <EntityLink
                              url={`/projects/${props.dataItem.quoteVersionGroup.project.id}/quotes/${props.dataItem.id}`}
                              text={props.dataItem.code}
                            />
                          </td>
                        )}
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="clientName"
                        title="Customer"
                        filter="numeric"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="salesRep"
                        title="Sales Rep"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="totalQuantity"
                        title="Total Quantity"
                        filter="numeric"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="totalAmountDecimal"
                        title="Total Amount"
                        filter="numeric"
                        cell={(props) => (
                          <td>
                            {formatPrice(
                              encodePriceAsInteger(
                                props.dataItem["totalAmountDecimal"]
                              )
                            )}
                          </td>
                        )}
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="requestedFulfillmentDate"
                        title="Requested Date"
                        format="{0:d}"
                        filter="date"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="createdBy.name"
                        title="Created By"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="createdAt"
                        title="Created Date"
                        format="{0:d}"
                        filter="date"
                      />
                    </Grid>
                  </div>
                  <p className="m-0 text-xs text-gray-400">
                    Showing orders that are created by you or where you are the
                    assigned sales rep.
                  </p>
                </div>
              ) : (
                <Callout title="No Quotes">
                  You don't have any active, unconverted quotes at this time.
                </Callout>
              )}
            </div>

            <div className="bg-white p-4 rounded shadow space-y-4">
              <div className="flex items-center space-x-4">
                <h2 className="text-base font-medium flex-1">Your Orders</h2>

                <EntityLink
                  url={`/sales/sales-orders`}
                  text={"View All Orders"}
                />
              </div>

              {salesOrders.length > 0 ? (
                <div className="space-y-4">
                  <div className="rounded overflow-hidden">
                    <Grid
                      data={process(salesOrders, salesOrdersDataState)}
                      {...salesOrdersDataState}
                      onDataStateChange={(e) =>
                        setSalesOrdersDataState(e.dataState)
                      }
                      filterable
                      sortable
                    >
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="code"
                        title="Code"
                        cell={(props) => (
                          <td>
                            <EntityLink
                              url={`/projects/${props.dataItem.project.id}/quotes/${props.dataItem.id}`}
                              text={props.dataItem.code}
                            />
                          </td>
                        )}
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="clientName"
                        title="Customer"
                        filter="numeric"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="salesRep"
                        title="Sales Rep"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="totalQuantity"
                        title="Total Quantity"
                        filter="numeric"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="totalAmountDecimal"
                        title="Total Amount"
                        filter="numeric"
                        cell={(props) => (
                          <td>
                            {formatPrice(
                              encodePriceAsInteger(
                                props.dataItem["totalAmountDecimal"]
                              )
                            )}
                          </td>
                        )}
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="requestedFulfillmentDate"
                        title="Requested Date"
                        format="{0:d}"
                        filter="date"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="createdBy.name"
                        title="Created By"
                      />
                      <GridColumn
                        columnMenu={ReportColumnMenu}
                        field="createdAt"
                        title="Created Date"
                        format="{0:d}"
                        filter="date"
                      />
                    </Grid>
                  </div>

                  <p className="m-0 text-xs text-gray-400">
                    Showing orders that are created by you or where you are the
                    assigned sales rep.
                  </p>
                </div>
              ) : (
                <Callout title="No Orders">
                  You don't have any active orders at this time.
                </Callout>
              )}
            </div>
          </div>

          <div className="bg-white p-4 rounded shadow w-1/3 space-y-4">
            <div className="flex items-center space-x-4">
              <h2 className="text-base font-medium flex-1">Your Clients</h2>

              <EntityLink
                url={`/contacts/companies`}
                text={"View All Companies"}
              />

              <Button
                icon="new-person"
                text="New Client"
                intent="success"
                onClick={createCompanyForSalesRep}
              />
            </div>

            {companies.length > 0 ? (
              <div className="space-y-4">
                <div className="space-y-2">
                  {companies.map((c) => (
                    <div key={c.id} className="border rounded divide-y">
                      <div className="p-2 flex items-center space-x-4">
                        <div className="space-y-1 flex-1">
                          <div className="flex items-center justify-between">
                            <h4 className="text-sm font-medium m-0">
                              {c.name}
                            </h4>
                            <EntityLink
                              url={`/contacts/companies/${c.id}`}
                              text={"View Details"}
                            />
                          </div>
                          <div className="flex items-center space-x-2 text-gray-500">
                            <Icon icon="envelope" />
                            <p className="m-0 pr-4">{c.email ?? "No Email"}</p>
                            <Icon icon="phone" />
                            <p className="m-0">{c.phone ?? "No Phone"}</p>
                          </div>
                        </div>
                      </div>
                      <div className="p-1 flex items-center space-x-4 bg-gray-50">
                        {!!c.contacts.find(
                          (c) => c.role === ContactRole.Primary
                        ) ? (
                          <Button
                            text="Start New Quote"
                            onClick={() =>
                              createQuoteForCompany(
                                c.id,
                                c.name,
                                c.contacts.find(
                                  (c) => c.role === ContactRole.Primary
                                )!.person.id
                              )
                            }
                          />
                        ) : (
                          <Tooltip
                            content="Can't create a quote for this client because they don't have a primary contact."
                            position="bottom"
                          >
                            <Button text="Start New Quote" disabled />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <p className="m-0 text-xs text-gray-400">
                  Showing companies that are created by you or where you are the
                  assigned sales rep.
                </p>
              </div>
            ) : (
              <Callout title="No Clients">
                You don't have any active clients at this time.
              </Callout>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
