import { Checkbox, FormGroup, TextArea } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  ConversionRequirement,
  Attachment,
  UpdateConversionRequirementInput,
} from "../../../../../types/ApiTypes";
import { ChooseAttachmentInput } from "../../../../shared/ChooseAttachmentInput";
import axios from "axios";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { AttachmentsInput } from "../../../../shared/AttachmentsInput/AttachmentsInput";

export type QuoteConversionRequirementParams = {
  projectId: string;
};

export type QuoteConversionRequirementProps = {
  projectId: number;
  disabled?: boolean;
  requirement: ConversionRequirement;
  onChange: (r: UpdateConversionRequirementInput) => void;
  onAddAttachment: (a: Attachment) => void;
  onRemoveAttachment: (a: Attachment) => void;
};

export function QuoteConversionRequirement({
  projectId,
  disabled,
  requirement,
  onAddAttachment,
  onRemoveAttachment,
  onChange,
}: QuoteConversionRequirementProps) {
  const [notes, setNotes] = useState(requirement.notes);
  const [completed, setCompleted] = useState(!!requirement.completedAt);

  const toggleCompleted = (e: any) => {
    const checked: boolean = (e.target as any).checked;
    setCompleted(checked);
    onChange({ id: requirement.id!, completed: checked });
  };

  const saveNotes = () => {
    onChange({ id: requirement.id!, notes });
  };

  return (
    <div
      className={classNames(
        "flex flex-col rounded-md border-2 overflow-hidden",
        { "border-green-300": completed }
      )}
    >
      <div
        className={classNames(
          "border-b p-4 flex items-center",
          { "bg-gray-50": !completed },
          { "bg-green-50": completed }
        )}
      >
        <Checkbox
          style={{ margin: 0 }}
          checked={completed}
          onChange={(e) => toggleCompleted(e)}
          disabled={disabled}
          large
        />
        <h3 className="m-0 font-semibold flex-1">{requirement.title}</h3>
      </div>

      <div className="p-4 flex-1">{requirement.description}</div>

      <div className="p-4 border-t">
        <FormGroup label="Notes">
          <TextArea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onBlur={() => saveNotes()}
            disabled={disabled}
            fill
          />
        </FormGroup>
        <FormGroup label="Attachment" style={{ marginBottom: 0 }}>
          <div className="max-w-sm">
            <AttachmentsInput
              projectId={projectId}
              attachments={requirement.conversionRequirementAttachments.map(
                (a) => a.attachment
              )}
              onAddAttachment={onAddAttachment}
              onRemoveAttachment={onRemoveAttachment}
              disabled={disabled}
            />
          </div>
        </FormGroup>
      </div>
    </div>
  );
}
