import { Avatar } from "@progress/kendo-react-layout";
import { formatDate, formatDateTime } from "../../helpers/DateHelper";
import {
  formatContactName,
  FormatContactNameContact,
} from "../../helpers/ContactHelper";
import { BasicTable } from "../../components/shared/Table/BasicTable";
import { getFullConfigurationCode } from "../../helpers/CatalogHelper";
import React from "react";
import { Account, ProductConfiguration } from "../../types/ApiTypes";
import { Link } from "react-router-dom";
import { Icon } from "@blueprintjs/core";

export type PrepTicketActivityItemProps = {
  id: number;
  prepTicket: {
    id: number;
    project: {
      id: number;
      primaryContact?: FormatContactNameContact;
    };
  };
  lineItems: {
    id: number;
    prepTicketLineItem: {
      id: number;
    };
  }[];
  createdAt: Date;
  createdBy: Account;
  __typename: "EODPrepTicket";
};

export function PrepTicketActivityItem(
  prepTicket: PrepTicketActivityItemProps
) {
  return (
    <div className="flex space-x-2">
      <div className="p-1">
        <Avatar>{prepTicket.createdBy.name.substring(0, 1)}</Avatar>
      </div>
      <div className="rounded border p-3 flex-1 bg-white shadow space-y-1">
        <div className="space-x-2 text-xs">
          <span className="font-medium text-gray-700">
            {prepTicket.createdBy.name}
          </span>
          <span className="text-gray-500">
            {formatDateTime(prepTicket.createdAt)}
          </span>
        </div>
        <div key={prepTicket.id} className="border rounded divide-y">
          <div className="p-2 bg-gray-50 flex space-x-4 items-center">
            <Link
              to={`/projects/${prepTicket.prepTicket.project.id}/prep-tickets/${prepTicket.prepTicket.id}`}
              target="_blank"
            >
              <h5 className="font-medium text-base w-20 flex items-center space-x-1">
                <span>PT-{prepTicket.prepTicket.id}</span>
                <Icon icon="arrow-top-right" iconSize={12} />
              </h5>
            </Link>

            <div>
              <h6 className="text-sm font-medium text-gray-500 m-0">Client</h6>
              <p className="m-0">
                {prepTicket.prepTicket.project.primaryContact
                  ? formatContactName(
                      prepTicket.prepTicket.project.primaryContact
                    )
                  : "N/A"}
              </p>
            </div>
          </div>
          {prepTicket.lineItems && (
            <BasicTable header footer>
              <thead>
                <tr>
                  <th>SO #</th>
                  <th>PO #</th>
                  <th>Inventory #</th>
                  <th>Product Code</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {prepTicket.lineItems.map((lineItem) => (
                  <tr key={lineItem.id}>
                    <td>{lineItem.prepTicketLineItem.id}</td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>
          )}
        </div>
      </div>
    </div>
  );
}
