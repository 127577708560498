import React, { useState } from "react";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import { CreateProjectPayloadInput, Project } from "../../../types/ApiTypes";
import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import {
  ContactInputGroup,
  ContactInputGroupContact,
} from "../../shared/ContactInputGroup";

const CREATE_PROJECT = gql`
  mutation CreateProject($input: CreateProjectPayloadInput) {
    createProject(payload: $input) {
      id
    }
  }
`;

export type CreateProjectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function CreateProjectDialog({
  isOpen,
  onClose,
}: CreateProjectDialogProps) {
  const history = useHistory();

  const [createProject] = useMutation<
    { createProject: Project },
    { input: CreateProjectPayloadInput }
  >(CREATE_PROJECT, {
    refetchQueries: ["GetProjects"],
  });

  const [primaryContact, setPrimaryContact] =
    useState<ContactInputGroupContact>();

  const [createLoading, setCreateLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const [name, setName] = useState("");

  async function onCreate() {
    setCreateLoading(true);

    try {
      const createProjectResult = await createProject({
        variables: {
          input: {
            name,
            primaryContactPersonId: primaryContact?.person?.id,
            primaryContactCompanyId: primaryContact?.company?.id,
          },
        },
      });

      history.push(
        `/projects/${createProjectResult.data!.createProject.id!}/quotes`
      );
    } catch (err) {
      setError(err);
    } finally {
      setCreateLoading(false);
    }
  }

  return (
    <Dialog title="Create a Project" isOpen={isOpen} isCloseButtonShown={false}>
      <div className="bg-white border-b p-4">
        {error && (
          <Callout
            intent={Intent.DANGER}
            title="Error creating project"
            className="mb-4"
          >
            {error.message}
          </Callout>
        )}

        <p className="mb-4">
          To create a new project, start by providing a name and optionally
          designating a primary contact for the project. You can fill out
          additional details about the project once it's created.
        </p>

        <FormGroup label="Project Name">
          <InputGroup
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Project Name"
            large
            fill
            required
          />
        </FormGroup>
        <FormGroup label="Primary Contact (Optional)">
          <ContactInputGroup
            value={primaryContact}
            onChange={setPrimaryContact}
          />
        </FormGroup>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" onClick={onClose} disabled={createLoading} />
          <Button
            intent={Intent.PRIMARY}
            text="Create"
            disabled={name.length === 0}
            loading={createLoading}
            onClick={onCreate}
          />
        </div>
      </div>
    </Dialog>
  );
}
