import {
  Intent,
  Popover,
  PopoverInteractionKind,
  Position,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import React, { PropsWithChildren } from "react";

export type PurchaseOrderStatusCellProps = PropsWithChildren<{
  text: string;
  intent: Intent;
  loading?: boolean;
  onClick?: () => void;
}>;

export function PurchaseOrderStatusCell({
  text,
  intent,
  loading,
  children,
}: PurchaseOrderStatusCellProps) {
  return (
    <Popover
      targetClassName="w-full"
      position={Position.BOTTOM}
      interactionKind={PopoverInteractionKind.HOVER}
    >
      <Tag className="cursor-pointer" large intent={intent} minimal fill>
        {loading && <Spinner size={18} />}
        {!loading && text}
      </Tag>
      {children}
    </Popover>
  );
}
