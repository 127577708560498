import { useAuth0 } from "@auth0/auth0-react";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";

function useToken() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    getAccessTokenSilently().then(setToken);
  }, []);

  return token;
}

type CustomApolloProviderProps = PropsWithChildren<any>;

export function CustomApolloProvider({ children }: CustomApolloProviderProps) {
  const token = useToken();

  // useRef instead of useMemo to make sure client is not re-created randomly
  const clientRef = useRef<any>(null);

  if (token && !clientRef.current) {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers = { authorization: token ? `Bearer ${token}` : "" };
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_API_URL + "/graphql",
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    clientRef.current = new ApolloClient(
      new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
      })
    );
  }

  if (!clientRef.current) {
    // Now we have to wait until client is initialized with token, so you might want to add some spinner
    return null;
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
}
