import { Button, Classes, Dialog, Intent } from "@blueprintjs/core";

export type DeleteConfirmationDialogProps = {
  isOpen: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onClose: () => void;
};

export function DeleteConfirmationDialog({
  title,
  message,
  isOpen,
  onConfirm,
  onClose,
}: DeleteConfirmationDialogProps) {
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      icon="trash"
      isCloseButtonShown={false}
    >
      <div className={Classes.DIALOG_BODY}>{message}</div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" minimal onClick={onClose} />
          <Button
            text="Delete"
            icon="trash"
            intent={Intent.DANGER}
            onClick={onConfirm}
          />
        </div>
      </div>
    </Dialog>
  );
}
