import { CatalogProduct } from "../../../types/ApiTypes";
import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { InputGroup, MenuDivider, MenuItem, Spinner } from "@blueprintjs/core";

export const SEARCH_PRODUCTS = gql`
  query SearchCatalogProducts($search: String!) {
    catalogProducts(
      where: {
        or: [
          { description: { contains: $search } }
          { code: { contains: $search } }
        ]
      }
    ) {
      id
      code
      description
      vendor {
        id
        name
      }
    }
  }
`;

export type SearchProductsProps = {
  onChoose: (product: CatalogProduct) => void;
};

export function SearchProducts({ onChoose }: SearchProductsProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const searchProducts = useQuery<{ catalogProducts: CatalogProduct[] }>(
    SEARCH_PRODUCTS,
    {
      variables: {
        search: searchQuery,
      },
      skip: searchQuery.length <= 3,
    }
  );

  return (
    <>
      <InputGroup
        type="search"
        leftIcon="search"
        placeholder="Search products..."
        value={searchQuery}
        rightElement={
          searchProducts.loading ? <Spinner size={15} /> : undefined
        }
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {!searchProducts.loading && searchQuery.length > 3 && (
        <>
          <MenuDivider title="Search Results" />
          {searchProducts.data!.catalogProducts.length === 0 && (
            <MenuItem text={`No results for "${searchQuery}"`} disabled />
          )}
          {searchProducts.data!.catalogProducts.length > 0 && (
            <>
              {searchProducts
                .data!.catalogProducts.slice(0, 10)
                .map((product) => (
                  <MenuItem
                    text={product.description}
                    label={[
                      product.code,
                      product.vendor.name
                        ? `(${product.vendor.name})`
                        : undefined,
                    ]
                      .filter((a) => !!a)
                      .join(" ")}
                    key={product.id}
                    shouldDismissPopover={false}
                    onClick={() => onChoose(product)}
                  />
                ))}
            </>
          )}
        </>
      )}
    </>
  );
}
