import React from "react";
import dayjs from "dayjs";
import { Attachment } from "../../../../types/ApiTypes";
import { FlexTable } from "../../../shared/Table/FlexTable";
import { NameCell } from "./NameCell";
import { Button, Intent } from "@blueprintjs/core";

export type ProjectAttachmentsTableProps = {
  attachments: Attachment[];
  onChange: (attachment: Attachment) => void;
  onRemove: (attachment: Attachment) => void;
};

export function ProjectAttachmentsTable({
  attachments,
  onChange,
  onRemove,
}: ProjectAttachmentsTableProps) {
  const columns = [
    {
      Header: "Name",
      accessor: (a: Attachment) => a.name,
      Cell: ({ row }) => (
        <NameCell attachment={row.original} onUpdateAttachment={onChange} />
      ),
    },
    {
      Header: "Created",
      accessor: (a: Attachment) => a.createdAt,
      Cell: ({ value }) => dayjs(value).format("MM/DD/YYYY"),
      maxWidth: 25,
    },
    {
      Header: "Updated",
      accessor: (a: Attachment) => a.updatedAt,
      Cell: ({ value }) => dayjs(value).format("MM/DD/YYYY"),
      maxWidth: 25,
    },
    {
      id: "actions",
      Header: "",
      Cell: ({ row }) => (
        <Button
          icon="trash"
          minimal
          intent={Intent.DANGER}
          onClick={() => onRemove(row.original)}
        />
      ),
      maxWidth: 4,
      className: "with-button",
    },
  ];

  return <FlexTable columns={columns} data={attachments} footer />;
}
