import React from "react";
import { ProjectSubnavbar } from "../../ProjectSubnavbar";
import { Icon } from "@blueprintjs/core";
import { NavLink, useParams } from "react-router-dom";

export type SalesOrderSubnavbarProps = {
  title: string;
  loading?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
};

export type SalesOrderRouteParams = {
  projectId: string;
  salesOrderId: string;
};

export function SalesOrderSubnavbar({
  title,
  loading,
  actions,
}: SalesOrderSubnavbarProps) {
  const { projectId, salesOrderId } = useParams<SalesOrderRouteParams>();

  return (
    <ProjectSubnavbar
      title={title}
      actions={actions}
      loading={loading}
      tabs={
        <ul>
          <li>
            <NavLink
              to={`/projects/${projectId}/sales-orders/${salesOrderId}`}
              exact
            >
              <Icon icon="add-row-bottom" />
              Line Items
            </NavLink>
          </li>
        </ul>
      }
    />
  );
}
