import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { gql, useQuery } from "@apollo/client";
import { Button, Popover, Spinner } from "@blueprintjs/core";
import React, { useMemo, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { QuoteReportItem } from "../../types/ApiTypes";
import { encodePriceAsInteger, formatPrice } from "../../helpers/PriceHelper";
import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { useReportDataState } from "../../hooks/useReportDataState";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { CreateQuoteDialog } from "../../components/shared/CreateQuoteDialog/CreateQuoteDialog";

const GetQuoteReport = gql`
  query GetQuoteReport {
    quoteReport {
      id
      projectId
      primaryContactPersonName
      primaryContactPersonId
      primaryContactCompanyName
      primaryContactCompanyId
      billingContactPersonName
      billingContactPersonId
      billingContactCompanyName
      billingContactCompanyId
      fulfillmentContactPersonName
      fulfillmentContactPersonId
      fulfillmentContactCompanyName
      fulfillmentContactCompanyId
      totalQuantity
      totalAmount
      salesRepName
      customerServiceRepName
      designerName
      code
      createdAt
    }
  }
`;

export default function QuotesReport() {
  const [isCreateQuoteOpen, setIsCreateQuoteOpen] = useState(false);

  const getQuoteReport =
    useQuery<{ quoteReport: QuoteReportItem[] }>(GetQuoteReport);

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  // map quote report items into a friendlier format for kendo data grid
  const quoteReportItems = useMemo(
    () =>
      getQuoteReport.data?.quoteReport.map((quoteReportItem) => ({
        ...quoteReportItem,
        projectCode: `P-${quoteReportItem.projectId}`,
        totalAmount: quoteReportItem.totalAmount / 100,
        createdAt: new Date(quoteReportItem.createdAt),
      })) ?? [],
    [getQuoteReport.data]
  );

  return (
    <>
      <CreateQuoteDialog
        isOpen={isCreateQuoteOpen}
        onClose={() => setIsCreateQuoteOpen(false)}
      />
      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Quotes"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
              <Button
                intent="primary"
                icon="add"
                text="New Quote"
                onClick={() => setIsCreateQuoteOpen(true)}
              />
            </>
          }
        />

        <div className="h-full relative">
          {getQuoteReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={process(quoteReportItems, dataState)}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              sortable
              reorderable
              resizable
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="id"
                title="Quote #"
                width={200}
                cell={(props) => (
                  <td>
                    <EntityLink
                      url={`/projects/${props.dataItem.projectId}/quotes/${props.dataItem.id}`}
                      text={props.dataItem.code}
                    />
                  </td>
                )}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="projectCode"
                title="Project #"
                cell={(props) => (
                  <td>
                    <EntityLink
                      url={`/projects/${props.dataItem.projectId}/quotes`}
                      text={props.dataItem.projectCode}
                    />
                  </td>
                )}
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="status"
                title="Status"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="status"
                title="Attachments"
                width={100}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="primaryContactPersonName"
                title="Primary Contact Person"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="primaryContactCompanyName"
                title="Primary Contact Company"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="billingContactPersonName"
                title="Billing Contact Person"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="billingContactCompanyName"
                title="Billing Contact Company"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="fulfillmentContactPersonName"
                title="Fulfillment Contact Person"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="fulfillmentContactCompanyName"
                title="Fulfillment Contact Company"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="deliveryAddress"
                title="Delivery Address"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="totalQuantity"
                title="Total Quantity"
                filter="numeric"
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="totalAmount"
                title="Total Amount"
                filter="numeric"
                cell={(props) => (
                  <td>
                    {props.field && props.dataItem[props.field]
                      ? formatPrice(
                          encodePriceAsInteger(props.dataItem[props.field])
                        )
                      : undefined}
                  </td>
                )}
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="closePercent"
                title="Close %"
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="decisionDate"
                title="Decision Date"
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="installDate"
                title="Install Date"
                width={150}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="salesRepName"
                title="Sales Rep"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="customerServiceRepName"
                title="Customer Service Rep"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="designerName"
                title="Designer"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="projectManagerName"
                title="Project Manager"
                width={200}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                filter="date"
                format="{0:d}"
                field="createdAt"
                title="Created"
                width={150}
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
