import produce from "immer";
import { Vendor } from "../../../types/ApiTypes";
import { useReducer } from "react";
import exp from "constants";

export type EditableVendor = {
  id: number;
  name: string;
  pricingZone: string;
  listPriceMultiplier: number;
  grossProfitPercent: number;
  freightRules: string;
  notes: string;
  modifiedFields: {
    name: boolean;
    pricingZone: boolean;
    listPriceMultiplier: boolean;
    grossProfitPercent: boolean;
    freightRules: boolean;
    notes: boolean;
  };
  isModified: boolean;
};

type VendorEditorState = {
  vendors: EditableVendor[];
  isModified: boolean;
};

type VendorEditorActions =
  | {
      type: "initialize";
      vendors: EditableVendor[];
    }
  | {
      type: "setName";
      vendorIndex: number;
      name: string;
    }
  | {
      type: "setPricingZone";
      vendorIndex: number;
      pricingZone: string;
    }
  | {
      type: "setListPriceMultiplier";
      vendorIndex: number;
      listPriceMultiplier: number;
    }
  | {
      type: "setGrossProfitPercent";
      vendorIndex: number;
      grossProfitPercent: number;
    }
  | {
      type: "setFreightRules";
      vendorIndex: number;
      freightRules: string;
    }
  | {
      type: "setNotes";
      vendorIndex: number;
      notes: string;
    };

function reducer(
  state: VendorEditorState,
  action: VendorEditorActions
): VendorEditorState {
  switch (action.type) {
    case "initialize":
      return { vendors: action.vendors, isModified: false };
    case "setName":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.name = action.name;
        vendor.modifiedFields.name = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    case "setPricingZone":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.pricingZone = action.pricingZone;
        vendor.modifiedFields.pricingZone = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    case "setListPriceMultiplier":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.listPriceMultiplier = action.listPriceMultiplier;
        vendor.modifiedFields.listPriceMultiplier = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    case "setGrossProfitPercent":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.grossProfitPercent = action.grossProfitPercent;
        vendor.modifiedFields.grossProfitPercent = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    case "setFreightRules":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.freightRules = action.freightRules;
        vendor.modifiedFields.freightRules = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    case "setNotes":
      return produce(state, (draft) => {
        const vendor = draft.vendors[action.vendorIndex];
        vendor.notes = action.notes;
        vendor.modifiedFields.notes = true;
        vendor.isModified = true;
        draft.isModified = true;
      });
    default:
      return state;
  }
}

export function createEditableVendor(vendor: Vendor): EditableVendor {
  return {
    id: vendor.id,
    name: vendor.name,
    pricingZone: vendor.pricingZone,
    listPriceMultiplier: vendor.listPriceMultiplier,
    grossProfitPercent: vendor.grossProfitPercent,
    freightRules: vendor.freightRules,
    notes: vendor.notes,
    modifiedFields: {
      name: false,
      pricingZone: false,
      listPriceMultiplier: false,
      grossProfitPercent: false,
      freightRules: false,
      notes: false,
    },
    isModified: false,
  };
}

export function useVendorListReducer() {
  return useReducer(reducer, { vendors: [], isModified: false });
}
