import { Attachment } from "../../../types/ApiTypes";
import { Button, Icon } from "@blueprintjs/core";
import { ChooseAttachmentDialog } from "../ChooseAttachmentDialog/ChooseAttachmentDialog";
import { useState } from "react";
import { DeleteConfirmationDialog } from "../DeleteConfirmationDialog/DeleteConfirmationDialog";
import { getAttachmentDownloadUrl } from "../../../helpers/AttachmentsHelper";

export type AttachmentsInputProps = {
  single?: boolean;
  projectId?: number;
  disabled?: boolean;
  attachments: Attachment[];
  onAddAttachment: (attachment: Attachment) => void;
  onRemoveAttachment: (attachment: Attachment) => void;
};

export function AttachmentsInput({
  single,
  projectId,
  disabled,
  attachments,
  onAddAttachment,
  onRemoveAttachment,
}: AttachmentsInputProps) {
  const [chooseAttachmentOpen, setChooseAttachmentOpen] = useState(false);
  const [confirmRemoveAttachment, setConfirmRemoveAttachment] =
    useState<Attachment>();

  return (
    <>
      <DeleteConfirmationDialog
        isOpen={!!confirmRemoveAttachment}
        title={`Remove ${confirmRemoveAttachment?.name}`}
        message={
          "Are you sure you want to remove this attachment? You may not be able to add it back."
        }
        onConfirm={() => {
          onRemoveAttachment(confirmRemoveAttachment!);
          setConfirmRemoveAttachment(undefined);
        }}
        onClose={() => {
          setConfirmRemoveAttachment(undefined);
        }}
      />

      <ChooseAttachmentDialog
        isOpen={chooseAttachmentOpen}
        projectId={projectId}
        onChooseAttachment={(a) => {
          onAddAttachment(a);
          setChooseAttachmentOpen(false);
        }}
        onClose={() => setChooseAttachmentOpen(false)}
      />

      <div className="rounded border bg-white overflow-hidden">
        {attachments.length === 0 && (
          <div className="py-4 text-center">
            <p className="m-0 text-gray-500">
              No {single ? "attachment" : "attachments"}
            </p>
          </div>
        )}
        {attachments.length !== 0 && (
          <div className="divide-y">
            {attachments.map((a) => (
              <div className="py-1 px-1 pl-2 flex items-center" key={a.id}>
                <Icon icon="paperclip" className="mr-2" />
                <p className="m-0 flex-1">{a.name}</p>
                <Button
                  icon="download"
                  onClick={() => {
                    window.open(getAttachmentDownloadUrl(a));
                  }}
                  minimal
                  small
                />
                {!disabled && (
                  <Button
                    icon="trash"
                    onClick={() => setConfirmRemoveAttachment(a)}
                    minimal
                    small
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {!disabled && (!single || attachments.length === 0) && (
          <div className="border-t bg-gray-50 p-1">
            <Button
              icon="plus"
              onClick={() => setChooseAttachmentOpen(true)}
              minimal
              small
            />
          </div>
        )}
      </div>
    </>
  );
}
