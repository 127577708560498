import { Select } from "@blueprintjs/select";
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Icon, MenuItem } from "@blueprintjs/core";
import {
  formatContactName,
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../helpers/ContactHelper";
import { Link } from "react-router-dom";

const GetCompanies = gql`
  query GetCompanies {
    companies(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetCompaniesResult = {
  companies: {
    id: number;
    name: string;
  }[];
};

type CompanyDetailItemProps = {
  eod: {
    id: number;
    company?: {
      id: number;
      name: string;
    };
  };
  onSelectCompany: (companyId: number) => void;
};

const CompanySelect = Select.ofType<GetCompaniesResult["companies"][0]>();

export function CompanyDetailItem({
  eod,
  onSelectCompany,
}: CompanyDetailItemProps) {
  const getCompanies = useQuery<GetCompaniesResult>(GetCompanies, {
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className="flex space-x-2 items-center">
      <p className="m-0 font-medium text-xs w-20">Company</p>

      <CompanySelect
        items={getCompanies.data?.companies ?? []}
        onItemSelect={(item) => onSelectCompany(item!.id)}
        itemRenderer={(item, props) => (
          <MenuItem
            text={item.name}
            onClick={props.handleClick}
            active={props.modifiers.active}
          />
        )}
        itemPredicate={(query, item) => {
          const normalizedQuery = query.trim().toLowerCase();
          const contactName = item.name.toLowerCase();
          return contactName.toString().includes(normalizedQuery);
        }}
        popoverProps={{
          minimal: true,
          position: "bottom-left",
          className: "flex-1",
          targetClassName: "w-full",
        }}
      >
        <div className="p-1 border rounded text-sm font-medium text-gray-700 flex-1 bg-white space-x-2 px-2 flex items-center hover:bg-gray-50 cursor-pointer">
          <span className="flex-1">
            {eod.company ? eod.company.name : "Not Specified"}
          </span>
          <Icon icon="chevron-down" />
        </div>
      </CompanySelect>

      {eod.company && (
        <Link to={`/contacts/companies/${eod.company.id}`} target="_blank">
          <Icon icon="circle-arrow-right" />
        </Link>
      )}
    </div>
  );
}
