import { BasicTable } from "../../../../../components/shared/Table/BasicTable";
import { getFullConfigurationCode } from "../../../../../helpers/CatalogHelper";
import { ProductConfiguration } from "../../../../../types/ApiTypes";
import { Link } from "react-router-dom";
import {
  Button,
  Callout,
  Checkbox,
  Menu,
  MenuItem,
  Popover,
  Position,
  Tag,
} from "@blueprintjs/core";
import { uniq } from "lodash";
import React, { useState } from "react";
import { TableFooter } from "../../../../../components/shared/Table/TableFooter";

type FulfillmentLineItemsTableLineItem = {
  id: number;
  quantity: number;
  salesOrderLineItem?: {
    id: number;
    configuration: ProductConfiguration;
    salesOrderLineItemGroup: {
      id: string;
      salesOrder: {
        id: string;
        code: string;
      };
    };
    inventoryItems: {
      id: number;
      prepTicketLineItem: {
        id: number;
        isPrepped: boolean;
      };
      location?: {
        id: number;
        name: string;
      };
      purchaseOrderShipmentLineItem: {
        id: number;
        purchaseOrderLineItem: {
          id: number;
          purchaseOrder: {
            id: number;
            code: string;
          };
        };
      };
    }[];
  };
};

function getPurchaseOrders(lineItem: {
  id: number;
  inventoryItems: {
    id: number;
    purchaseOrderShipmentLineItem: {
      id: number;
      purchaseOrderLineItem: {
        id: number;
        purchaseOrder: {
          id: number;
          code: string;
        };
      };
    };
  }[];
}) {
  const allPurchaseOrders = lineItem.inventoryItems.map(
    (inventoryItem) =>
      inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderLineItem
        .purchaseOrder
  );
  return uniq(allPurchaseOrders);
}

type FulfillmentLineItemsTableProps = {
  lineItems: FulfillmentLineItemsTableLineItem[];
  projectId: number;
  onAddItem?: () => void;
  onComplete?: (lineItems: FulfillmentLineItemsTableLineItem[]) => void;
  onCreateEOD: (lineItems: FulfillmentLineItemsTableLineItem[]) => void;
  onRemove?: (lineItem: FulfillmentLineItemsTableLineItem) => void;
  readOnly?: boolean;
};

export function FulfillmentLineItemsTable({
  lineItems,
  projectId,
  onCreateEOD,
  onComplete,
  onAddItem,
  onRemove,
  readOnly = false,
}: FulfillmentLineItemsTableProps) {
  const [selectedLineItems, setSelectedLineItems] = useState<
    FulfillmentLineItemsTableLineItem[]
  >([]);

  return (
    <div>
      <BasicTable footer>
        <thead>
          <tr>
            <th style={{ width: 30 }}>
              <Checkbox
                style={{ margin: 0 }}
                indeterminate={
                  selectedLineItems.length > 0 &&
                  selectedLineItems.length !== lineItems.length
                }
                checked={
                  selectedLineItems.length > 0 &&
                  selectedLineItems.length === lineItems.length
                }
                onClick={() => {
                  if (selectedLineItems.length === lineItems.length) {
                    setSelectedLineItems([]);
                  } else {
                    setSelectedLineItems(lineItems);
                  }
                }}
              />
            </th>
            <th>#</th>
            <th>SO #</th>
            <th>PO #</th>
            <th>Inventory #</th>
            <th>Product Code</th>
            <th>Location</th>
            <th>Quantity</th>
            <th># Boxes</th>
            <th>Prepped</th>
            <th>Pulled</th>
            <th>Assembled</th>
            {!readOnly && (
              <th>
                <span className="sr-only">Actions</span>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {lineItems.map((lineItem, index) => (
            <tr key={lineItem.id}>
              <td>
                <Checkbox
                  style={{ margin: 0 }}
                  checked={selectedLineItems.includes(lineItem)}
                  onClick={() => {
                    if (selectedLineItems.includes(lineItem)) {
                      setSelectedLineItems(
                        selectedLineItems.filter(
                          (selectedLineItem) => selectedLineItem !== lineItem
                        )
                      );
                    } else {
                      setSelectedLineItems([...selectedLineItems, lineItem]);
                    }
                  }}
                />
              </td>
              <td>{index + 1}</td>
              <td>
                {lineItem.salesOrderLineItem && (
                  <Link
                    to={`/projects/${projectId}/sales-orders/${lineItem.salesOrderLineItem.salesOrderLineItemGroup.salesOrder.id}`}
                  >
                    {
                      lineItem.salesOrderLineItem.salesOrderLineItemGroup
                        .salesOrder.code
                    }
                  </Link>
                )}
              </td>
              <td>
                {lineItem.salesOrderLineItem &&
                  getPurchaseOrders(lineItem.salesOrderLineItem).map((po) => (
                    <Link
                      to={`/projects/${projectId}/purchase-orders/${po.id}`}
                    >
                      {po.code}
                    </Link>
                  ))}
              </td>
              <td>
                {lineItem.salesOrderLineItem?.inventoryItems
                  .map((i) => `INV-${i.id}`)
                  .join(", ")}
              </td>
              <td>
                {lineItem.salesOrderLineItem &&
                  getFullConfigurationCode(
                    lineItem.salesOrderLineItem.configuration
                  )}
              </td>
              <td>
                {lineItem.salesOrderLineItem?.inventoryItems
                  .map((i) => i.location?.name)
                  .filter(Boolean)
                  .join(", ")}
              </td>
              <td>{lineItem.quantity}</td>
              <td>--</td>
              <td>
                --
                {/*{lineItem.inventoryItem?.prepTicketLineItem?.isPrepped ? (*/}
                {/*  <Link*/}
                {/*    to={`/projects/${projectId}/prep-tickets/${*/}
                {/*      lineItem.inventoryItem!.prepTicketLineItem!.prepTicket.id*/}
                {/*    }`}*/}
                {/*  >*/}
                {/*    <Tag fill minimal icon="tick" intent="success">*/}
                {/*      Prepped (Ticket #*/}
                {/*      {lineItem.inventoryItem!.prepTicketLineItem!.prepTicket.id})*/}
                {/*    </Tag>*/}
                {/*  </Link>*/}
                {/*) : (*/}
                {/*  <Tag fill minimal icon="cross" intent="warning">*/}
                {/*    Not Prepped*/}
                {/*  </Tag>*/}
                {/*)}*/}
              </td>
              <td>--</td>
              <td>--</td>
              {!readOnly && (
                <td className="w-10">
                  <Popover
                    minimal
                    position={Position.BOTTOM_RIGHT}
                    targetClassName="w-full"
                  >
                    <Button icon="more" fill small minimal />
                    <Menu>
                      <MenuItem
                        icon="minus"
                        intent="danger"
                        text="Remove from Fulfillment"
                        onClick={() => onRemove!(lineItem)}
                      />
                    </Menu>
                  </Popover>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </BasicTable>
      <TableFooter className="p-1 space-x-1">
        {!readOnly && (
          <Button text="Add Fulfillment Item" icon="plus" onClick={onAddItem} />
        )}
        <Button
          text="Create EOD from Selected Items"
          disabled={selectedLineItems.length === 0}
          onClick={() => {
            onCreateEOD(selectedLineItems);
            setSelectedLineItems([]);
          }}
        />
        {!readOnly && (
          <Button
            text="Mark Completed"
            disabled={selectedLineItems.length === 0}
            onClick={() => {
              onComplete!(selectedLineItems);
              setSelectedLineItems([]);
            }}
          />
        )}
      </TableFooter>
    </div>
  );
}
