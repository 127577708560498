import { Dispatch, SetStateAction, useEffect, useState } from "react";

export function useUserPreference<T>(
  preferenceKey: string,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    const storedValue = window.localStorage.getItem(preferenceKey);
    if (!storedValue) return;

    try {
      setValue(JSON.parse(storedValue) as T);
    } catch (err) {
      // no-op
    }
  }, [preferenceKey]);

  useEffect(() => {
    window.localStorage.setItem(preferenceKey, JSON.stringify(value));
  }, [value, preferenceKey]);

  return [value, setValue];
}
