import { gql } from "@apollo/client";

export type DeleteAccountSpiffCodeResult = {
  deleteAccountSpiffCode: {
    id: number;
  };
};

export type DeleteAccountSpiffCodeParams = {
  id: number;
};

export const DeleteAccountSpiffCode = gql`
  mutation DeleteAccountSpiffCode($id: Int!) {
    deleteAccountSpiffCode(accountSpiffCodeId: $id) {
      id
    }
  }
`;

export type CreateAccountSpiffCodeParams = {
  payload: {
    accountId: number;
    vendorId: number;
    code: string;
  };
};

export type CreateAccountSpiffCodeResult = {
  createAccountSpiffCode: {
    id: number;
  };
};

export const CreateAccountSpiffCode = gql`
  mutation CreateAccountSpiffCode(
    $payload: CreateAccountSpiffCodePayloadInput!
  ) {
    createAccountSpiffCode(payload: $payload) {
      id
    }
  }
`;
