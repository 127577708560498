import { Prompt, useHistory, useParams } from "react-router-dom";
import {
  Button,
  ControlGroup,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  Popover,
  Position,
} from "@blueprintjs/core";
import { useEffect } from "react";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import dayjs from "dayjs";
import { CompanyInputGroup } from "../../../components/projects/CreateProjectDialog/CompanyInputGroup";
import {
  CompanyType,
  UpdateCompanyPayloadInput,
} from "../../../types/ApiTypes";
import { CompanyContactList } from "../../../components/contacts/companies/CompanyContactList";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CompanyAddressList } from "../../../components/contacts/companies/CompanyAddressList";
import { gql, useMutation, useQuery } from "@apollo/client";
import { BasicTable } from "../../../components/shared/Table/BasicTable";
import { EntityLink } from "../../../components/reports/EntityLink";
import {
  AccountInputGroup,
  AccountInputGroupAccount,
} from "../../../components/shared/AccountInputGroup";

type GetCompaniesResult = {
  companies: {
    id: number;
    name: string;
  }[];
};

const GetCompanies = gql`
  query GetCompanies {
    companies(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetCompanyByIdResult = {
  companies: {
    id: string;
    name: string;
    email: string;
    phone: string;
    type: CompanyType;
    defaultSalesRep: {
      id: number;
      name: string;
      email: string;
    };
    defaultDesigner: {
      id: number;
      name: string;
      email: string;
    };
    defaultCustomerServiceRep: {
      id: number;
      name: string;
      email: string;
    };
    parentCompany?: {
      id: number;
      name: string;
    };
    eods: {
      id: number;
      title: string;
    }[];
    updatedAt: string;
  }[];
};

const GetCompanyById = gql`
  query GetCompanyById($id: Int!) {
    companies(where: { id: { eq: $id } }) {
      id
      name
      email
      phone
      type
      defaultSalesRep {
        id
        name
        email
      }
      defaultDesigner {
        id
        name
        email
      }
      defaultCustomerServiceRep {
        id
        name
        email
      }
      eods {
        id
        title
      }
      parentCompany {
        id
        name
      }
      updatedAt
    }
  }
`;

const UpdateCompany = gql`
  mutation UpdateCompany($payload: UpdateCompanyPayloadInput!) {
    updateCompany(payload: $payload) {
      id
    }
  }
`;

const DeleteCompany = gql`
  mutation DeleteCompany($id: Int!) {
    deleteCompany(companyId: $id) {
      id
    }
  }
`;

type CompanyFormFields = {
  name: string;
  email: string;
  phone: string;
  phoneExtension: string;
  parentCompany?: {
    id: number;
    name: string;
  };
  type: CompanyType;
  defaultSalesRep?: AccountInputGroupAccount;
  defaultDesigner?: AccountInputGroupAccount;
  defaultCustomerServiceRep?: AccountInputGroupAccount;
};

export type CompanyDetailsRouteParams = {
  companyId: string;
};

export function CompanyDetails() {
  const history = useHistory();
  const { companyId } = useParams<CompanyDetailsRouteParams>();

  const getCompanyById = useQuery<GetCompanyByIdResult, { id: number }>(
    GetCompanyById,
    {
      variables: {
        id: parseInt(companyId),
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const getCompanies = useQuery<GetCompaniesResult>(GetCompanies, {
    fetchPolicy: "cache-and-network",
  });

  const [updateCompany] = useMutation<
    { updateCompany: { id: number } },
    { payload: UpdateCompanyPayloadInput }
  >(UpdateCompany, {
    refetchQueries: ["GetCompanyById", "GetCompanies"],
  });

  const [deleteCompany] = useMutation<
    { deleteCompany: { id: number } },
    { id: number }
  >(DeleteCompany, {
    refetchQueries: ["GetCompanyById", "GetCompanies"],
  });

  const formik = useFormik<CompanyFormFields>({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      phoneExtension: "",
      parentCompany: undefined,
      type: CompanyType.Corporation,
      defaultSalesRep: undefined,
      defaultDesigner: undefined,
      defaultCustomerServiceRep: undefined,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      email: Yup.string().email("Email is invalid"),
      phone: Yup.string(),
      phoneExtension: Yup.string(),
      parentCompany: Yup.object().nullable(),
      type: Yup.string().required("Type is required"),
    }),
    onSubmit: async (values) => {
      await updateCompany({
        variables: {
          payload: {
            id: parseInt(companyId),
            name: values.name,
            email: values.email,
            phone: (
              values.phone.replaceAll("_", "") +
              " " +
              values.phoneExtension.replaceAll("_", "")
            ).trim(),
            type: values.type,
            parentCompanyId: values.parentCompany?.id,
            defaultSalesRepId: values.defaultSalesRep?.id,
            defaultDesignerId: values.defaultDesigner?.id,
            defaultCustomerServiceRepId: values.defaultCustomerServiceRep?.id,
          },
        },
      });

      DefaultToaster.show({
        message: "Company changes saved",
        intent: "success",
        icon: "tick",
      });
    },
  });

  useEffect(() => {
    if (getCompanyById.loading) return;
    const company = getCompanyById.data!.companies[0];

    formik.resetForm({
      values: {
        name: company.name ?? "",
        email: company.email ?? "",
        phone:
          company.phone && company.phone.length > 0
            ? company.phone.split(" ")[0]
            : "",
        phoneExtension:
          company.phone && company.phone.length > 0
            ? company.phone.split(" ")[1]
            : "",
        parentCompany: company.parentCompany,
        type: company.type ?? CompanyType.Corporation,
        defaultSalesRep: company.defaultSalesRep,
        defaultDesigner: company.defaultDesigner,
        defaultCustomerServiceRep: company.defaultCustomerServiceRep,
      },
    });
  }, [getCompanyById.loading, getCompanyById.data]);

  const removeCompany = async () => {
    await deleteCompany({
      variables: {
        id: parseInt(companyId),
      },
    });

    history.replace("/contacts/companies");
  };

  useEffect(() => {
    if (formik.dirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [formik.dirty]);

  return (
    <>
      <Prompt
        when={formik.dirty}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      {!getCompanyById.loading && (
        <form className="p-5 flex items-start" onSubmit={formik.handleSubmit}>
          <div className="flex flex-1">
            <div className="w-36 h-36 rounded-lg bg-gray-700 flex items-center justify-center">
              <p className="m-0 text-6xl text-white font-semibold">
                {formik.values.name.toUpperCase().substring(0, 1)}
              </p>
            </div>

            <div className="flex-1 max-w-lg ml-10">
              <FormGroup
                label="Company Name"
                helperText={formik.errors.name}
                intent={formik.errors.name ? Intent.DANGER : Intent.NONE}
              >
                <InputGroup
                  large
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
              </FormGroup>

              <p className="text-gray-400 mb-5">
                Last updated{" "}
                {dayjs(getCompanyById.data!.companies[0].updatedAt).format(
                  "MM/DD/YYYY"
                )}
              </p>

              <div className="mb-4">
                <FormGroup
                  label="Parent Company"
                  helperText={formik.errors.parentCompany}
                  intent={
                    formik.errors.parentCompany ? Intent.DANGER : Intent.NONE
                  }
                >
                  <CompanyInputGroup
                    companies={getCompanies.data?.companies ?? []}
                    loading={getCompanies.loading}
                    value={formik.values.parentCompany}
                    onChange={(e) => formik.setFieldValue("parentCompany", e)}
                  />
                </FormGroup>
              </div>

              <div className="mb-4">
                <FormGroup
                  label="Company Type"
                  helperText={formik.errors.type}
                  intent={formik.errors.type ? Intent.DANGER : Intent.NONE}
                >
                  <HTMLSelect
                    id="type"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    fill
                    large
                  >
                    <option value="" disabled>
                      Select a type...
                    </option>
                    <option value={CompanyType.Corporation}>Corporation</option>
                    <option value={CompanyType.University}>University</option>
                    <option value={CompanyType.NonProfit}>Non-Profit</option>
                    <option value={CompanyType.Other}>Other</option>
                  </HTMLSelect>
                </FormGroup>
              </div>

              <div className="flex mb-4">
                <FormGroup
                  label="Email"
                  className="flex-1 pr-2"
                  helperText={formik.errors.email}
                  intent={formik.errors.email ? Intent.DANGER : Intent.NONE}
                >
                  <InputGroup
                    id="email"
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    large
                  />
                </FormGroup>

                <FormGroup
                  label="Phone"
                  className="flex-1 pl-2"
                  helperText={
                    formik.errors.phone ?? formik.errors.phoneExtension
                  }
                  intent={
                    formik.errors.phone || formik.errors.phoneExtension
                      ? Intent.DANGER
                      : Intent.NONE
                  }
                >
                  <ControlGroup fill>
                    <HTMLSelect large className="w-24">
                      <option value="">+1</option>
                    </HTMLSelect>
                    <InputMask
                      id="phone"
                      name="phone"
                      mask={"999-999-9999"}
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                    <InputMask
                      mask={"9999"}
                      id="phoneExtension"
                      name="phoneExtension"
                      value={formik.values.phoneExtension}
                      onChange={formik.handleChange}
                      className="w-28"
                    >
                      {(inputProps) => (
                        <InputGroup type="tel" {...inputProps} large />
                      )}
                    </InputMask>
                  </ControlGroup>
                </FormGroup>
              </div>

              <FormGroup label="Default Sales Rep">
                <AccountInputGroup
                  value={formik.values.defaultSalesRep}
                  onChange={(v) => formik.setFieldValue("defaultSalesRep", v)}
                />
              </FormGroup>

              <FormGroup label="Default Designer">
                <AccountInputGroup
                  value={formik.values.defaultDesigner}
                  onChange={(v) => formik.setFieldValue("defaultDesigner", v)}
                />
              </FormGroup>

              <FormGroup label="Default Customer Service Rep">
                <AccountInputGroup
                  value={formik.values.defaultCustomerServiceRep}
                  onChange={(v) =>
                    formik.setFieldValue("defaultCustomerServiceRep", v)
                  }
                />
              </FormGroup>

              <FormGroup label="Addresses">
                <CompanyAddressList companyId={parseInt(companyId)} />
              </FormGroup>

              <FormGroup label="Contacts">
                <CompanyContactList companyId={parseInt(companyId)} />
              </FormGroup>

              <FormGroup label="EODs">
                <div className="flex flex-col border rounded overflow-hidden">
                  <BasicTable header footer>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCompanyById.data!.companies[0].eods.map((eod) => (
                        <tr key={eod.id}>
                          <td>
                            <EntityLink
                              url={`/eods/${eod.id}`}
                              text={`EOD-${eod.id}`}
                            />
                          </td>
                          <td>{eod.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </BasicTable>
                </div>
              </FormGroup>
            </div>
          </div>

          <Button
            intent={Intent.PRIMARY}
            text="Save Changes"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
            loading={formik.isSubmitting}
          />

          <Popover position={Position.BOTTOM_RIGHT} minimal>
            <Button icon="more" className="ml-2" minimal />
            <Menu>
              <MenuItem
                text="Delete Company"
                icon="trash"
                intent={Intent.DANGER}
                onClick={removeCompany}
              />
            </Menu>
          </Popover>
        </form>
      )}
    </>
  );
}
