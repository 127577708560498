import { MetadataItemType, MetadataItem } from "../types/ApiTypes";
import { useEffect, useState } from "react";
import { useApiError } from "./useApiError";
import axios from "axios";

export function useMetadata(entityType: MetadataItemType, entityId: number) {
  const [metadataItems, setMetadataItems] = useState<MetadataItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const handleFetchError = useApiError("fetch metadata");
  const handleUpdateError = useApiError("update metadata");
  const handleDeleteError = useApiError("delete metadata");

  const getEndpoint = () => {
    switch (entityType) {
      case MetadataItemType.PurchaseOrder:
        return "/purchaseorders";
      case MetadataItemType.Project:
        return "/projects";
      case MetadataItemType.QuoteGroup:
        return "/quoteversiongroups";
    }
  };

  const refresh = () => {
    axios
      .get(`${getEndpoint()}/${entityId}/metadata`)
      .then((r) => r.data as MetadataItem[])
      .then((m) => setMetadataItems(m))
      .catch((err) => setError(err))
      .catch((err) => handleFetchError(err))
      .finally(() => setLoading(false));
  };

  const createItem = (item: MetadataItem) => {
    item.entityId = entityId;
    item.type = entityType;

    axios
      .post(`/metadata`, item)
      .then((r) => r.data as MetadataItem)
      .then((item) => setMetadataItems((state) => [...state, item]))
      .catch((err) => handleUpdateError(err));
  };

  const updateItem = (item: MetadataItem) => {
    setMetadataItems((state) => [
      ...state.map((m) => (m.id === item.id ? item : m)),
    ]);

    axios
      .put(`/metadata/${item.id}`, item)
      .catch((err) => handleUpdateError(err));
  };

  const removeItem = (item: MetadataItem) => {
    setMetadataItems((state) => [...state.filter((m) => m.id !== item.id)]);

    axios.delete(`/metadata/${item.id}`).catch((err) => handleDeleteError(err));
  };

  useEffect(() => {
    refresh();
  }, [entityType, entityId]);

  return {
    metadataItems,
    loading,
    error,
    refresh,
    createItem,
    updateItem,
    removeItem,
  };
}
