import {
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuProps,
  GridColumnMenuSort,
} from "@progress/kendo-react-grid";

export const ReportColumnMenu = (props: GridColumnMenuProps) => (
  <div>
    {props.sortable !== false && <GridColumnMenuSort {...props} />}
    {props.filterable !== false && <GridColumnMenuFilter {...props} />}
    {props.groupable !== false && <GridColumnMenuGroup {...props} />}
  </div>
);
