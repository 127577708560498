import { gql, useQuery } from "@apollo/client";
import { Project } from "../../../types/ApiTypes";
import { Intent, Spinner, Tag } from "@blueprintjs/core";
import { useState } from "react";

function CatalogOption({
  id,
  code,
  description,
  optionGroups,
}: {
  id: string;
  code: string;
  description: string;
  optionGroups: { id: string; description: string; code: string }[];
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr key={id} className={"border-b-2 border-yellow-200 pl-5"}>
        <td className="pl-1">{id}</td>
        <td className="px-2">
          <Tag>{code}</Tag>
        </td>
        <td
          onClick={() => setOpen(!open)}
          className={"font-bold uppercase sm:font-sans text-yellow-800"}
        >
          {description}
        </td>
      </tr>
      {open && (
        <tr key={id + "_details"}>
          <td className={"2xl:bg-yellow-200"}></td>
          <td colSpan={2}>
            {optionGroups.length > 0 ? (
              <table>
                {optionGroups.map((optionGroup) => (
                  <CatalogOptionGroup
                    key={optionGroup.id}
                    id={optionGroup.id}
                    description={optionGroup.description}
                    code={optionGroup.code}
                  />
                ))}
              </table>
            ) : (
              <Tag intent={Intent.SUCCESS}>No Further Options</Tag>
            )}
          </td>
        </tr>
      )}
    </>
  );
}

const GET_CATALOG_OPTION_GROUP_REPORT = gql`
  query CatalogOptionGroupReport($id: Int!) {
    catalogOptionGroupReport(id: $id) {
      id
      code
      description
      options {
        id
        code
        description
        optionGroups {
          id
          code
          description
        }
      }
    }
  }
`;

function CatalogOptionGroup({
  id,
  code,
  description,
}: {
  id: string;
  code: string;
  description: string;
}) {
  const [open, setOpen] = useState(false);

  const getCatalogOptionGroupReport = useQuery<
    {
      catalogOptionGroupReport: {
        id: string;
        code: string;
        description: string;
        options: {
          id: string;
          code: string;
          description: string;
          optionGroups: {
            id: string;
            code: string;
            description: string;
          }[];
        }[];
      };
    },
    {
      id: string;
    }
  >(GET_CATALOG_OPTION_GROUP_REPORT, {
    variables: {
      id: id,
    },
    skip: !open,
  });

  return (
    <>
      <tr key={id} className={"border-b-2 border-purple-200 pl-5"}>
        <td className="pl-1">{id}</td>
        <td className="px-2">
          <Tag>{code}</Tag>
        </td>
        <td
          onClick={() => setOpen(!open)}
          className={"font-bold uppercase sm:font-sans text-purple-800"}
        >
          {description}
        </td>
      </tr>
      {open && (
        <tr key={id + "_details"}>
          <td className={"2xl:bg-purple-200"}></td>
          <td colSpan={2}>
            {getCatalogOptionGroupReport.loading ? (
              <Spinner />
            ) : (
              <table>
                {getCatalogOptionGroupReport.data!.catalogOptionGroupReport.options.map(
                  (option) => (
                    <CatalogOption
                      key={option.id}
                      id={option.id}
                      code={option.code}
                      description={option.description}
                      optionGroups={option.optionGroups}
                    />
                  )
                )}
              </table>
            )}
          </td>
        </tr>
      )}
    </>
  );
}

const GET_CATALOG_PRODUCT_REPORT = gql`
  query CatalogProductReport($id: Int!) {
    catalogProductReport(id: $id) {
      id
      code
      optionGroups {
        id
        code
        description
      }
    }
  }
`;

function CatalogProduct({
  id,
  code,
  description,
}: {
  id: string;
  code: string;
  description: string;
}) {
  const [open, setOpen] = useState(false);

  const getCatalogProductReport = useQuery<
    {
      catalogProductReport: {
        id: string;
        code: string;
        optionGroups: {
          id: string;
          code: string;
          description: string;
        }[];
      };
    },
    {
      id: string;
    }
  >(GET_CATALOG_PRODUCT_REPORT, {
    variables: {
      id: id,
    },
    skip: !open,
  });

  return (
    <>
      <tr key={id} className={"border-b-2 border-red-200 pl-5"}>
        <td className="pl-1">{id}</td>
        <td className="px-2">
          <Tag>{code}</Tag>
        </td>
        <td
          onClick={() => setOpen(!open)}
          className={"font-bold uppercase sm:font-sans text-red-800"}
        >
          {description}
        </td>
      </tr>
      {open && (
        <tr key={id + "_details"}>
          <td className={"2xl:bg-red-200"}></td>
          <td colSpan={2}>
            {getCatalogProductReport.loading ? (
              <Spinner />
            ) : (
              <table className="w-full">
                {getCatalogProductReport.data!.catalogProductReport.optionGroups
                  .length > 0 ? (
                  getCatalogProductReport.data!.catalogProductReport.optionGroups.map(
                    (og) => (
                      <CatalogOptionGroup
                        key={og.id}
                        id={og.id}
                        description={og.description}
                        code={og.code}
                      />
                    )
                  )
                ) : (
                  <Tag intent={Intent.NONE}>No Options</Tag>
                )}
              </table>
            )}
          </td>
        </tr>
      )}
    </>
  );
}

const GET_CATALOG_REPORT = gql`
  query CatalogReport($id: Int!) {
    catalogReport(id: $id) {
      id
      code
      name
      products {
        id
        code
        description
      }
    }
  }
`;

function Catalog({
  id,
  code,
  name,
}: {
  id: string;
  code: string;
  name: string;
}) {
  const [open, setOpen] = useState(false);

  const getCatalogReport = useQuery<
    {
      catalogReport: {
        id: string;
        code: string;
        name: string;
        products: {
          id: string;
          code: string;
          description: string;
        }[];
      };
    },
    {
      id: string;
    }
  >(GET_CATALOG_REPORT, {
    variables: {
      id: id,
    },
    skip: !open,
  });

  return (
    <>
      <tr key={id} className={"border-b-2 border-green-200 pl-5"}>
        <td className={"px-2"}>{id}</td>
        <td className="px-2">
          <Tag>{code}</Tag>
        </td>
        <td onClick={() => setOpen(!open)} className={"text-green-800"}>
          {name}
        </td>
      </tr>
      {open && (
        <tr key={id + "_details"}>
          <td className={"2xl:bg-green-200"}></td>
          <td colSpan={2}>
            {getCatalogReport.loading ? (
              <Spinner />
            ) : (
              <table className="w-full">
                {getCatalogReport.data!.catalogReport.products!.map(
                  (product) => (
                    <CatalogProduct
                      key={product.id}
                      id={product.id}
                      code={product.code}
                      description={product.description}
                    />
                  )
                )}
              </table>
            )}
          </td>
        </tr>
      )}
    </>
  );

  /*
  <ul>
          {products.map((product) => (
            <li key={product.id} className="ml-14">
              <h3>
                {product.id}. {product.code}
              </h3>
            </li>
          ))}
        </ul>
   */
}

function CatalogManufacturer({
  id,
  name,
  code,
  catalogs,
}: {
  id: string;
  name: string;
  code: string;
  catalogs: { id: string; name: string; code: string }[];
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr key={id} className={"border-b-2 border-blue-200 pl-5"}>
        <td className="px-2">{id}</td>
        <td className="px-2">
          <Tag>{code}</Tag>
        </td>
        <td
          onClick={() => setOpen(!open)}
          className={"font-bold uppercase sm:font-sans text-blue-800"}
        >
          {name}
        </td>
      </tr>
      {open && (
        <tr key={id + " details"}>
          <td className={"2xl:bg-blue-200"}></td>
          <td colSpan={2}>
            <table className="w-full">
              {catalogs.map((catalog) => (
                <Catalog
                  key={catalog.id}
                  id={catalog.id}
                  name={catalog.name}
                  code={catalog.code}
                />
              ))}
            </table>
          </td>
        </tr>
      )}
    </>
  );
}

const GET_CATALOGS_REPORT = gql`
  query CatalogReport {
    catalogManufacturers {
      id
      code
      name
      catalogs {
        id
        code
        name
      }
    }
  }
`;

export function Catalogs() {
  const getCatalogsReport = useQuery<
    {
      catalogManufacturers: {
        id: string;
        code: string;
        name: string;
        catalogs: {
          id: string;
          code: string;
          name: string;
        }[];
      }[];
    },
    {}
  >(GET_CATALOGS_REPORT, {});

  return (
    <div className="w-full">
      {getCatalogsReport.loading ? (
        <div>
          <br />
          <br />
          <br />
          <Spinner />
        </div>
      ) : (
        <table className="w-full">
          {getCatalogsReport.data!.catalogManufacturers.map((manufacturer) => (
            <CatalogManufacturer
              key={manufacturer.id}
              id={manufacturer.id}
              name={manufacturer.name}
              code={manufacturer.code}
              catalogs={manufacturer.catalogs}
            />
          ))}
        </table>
      )}
    </div>
  );
}
