import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { Button, Classes, Popover, Position } from "@blueprintjs/core";
import { useProjectQuoteVersionGroups } from "../../../../hooks/useProjectQuoteVersionGroups";
import { QuoteVersionGroup } from "../../../../components/projects/[projectId]/quotes/QuoteVersionGroup";
import { AddQuoteVersionGroupDialog } from "../../../../components/projects/[projectId]/quotes/AddQuoteVersionGroupDialog";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DeleteConfirmationDialog } from "../../../../components/shared/DeleteConfirmationDialog/DeleteConfirmationDialog";
import {
  Project,
  QuoteVersionGroup as ApiQuoteVersionGroup,
} from "../../../../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export default function Quotes() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const [addQuoteVersionGroupOpen, setAddQuoteVersionGroupOpen] =
    useState(false);
  const [deleteVersionGroupOpen, setDeleteVersionGroupOpen] = useState(false);
  const [versionGroupToDelete, setVersionGroupToDelete] =
    useState<ApiQuoteVersionGroup>();

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const {
    quoteVersionGroups,
    loading: quoteVersionGroupsLoading,
    error: quoteVersionGroupsError,
    createQuoteVersionGroup,
    updateQuoteVersionGroup,
    deleteQuoteVersionGroup,
    refresh,
  } = useProjectQuoteVersionGroups({ projectId });

  const confirmDeleteQuoteVersionGroup = (
    quoteVersionGroup: ApiQuoteVersionGroup
  ) => {
    setVersionGroupToDelete(quoteVersionGroup);
    setDeleteVersionGroupOpen(true);
  };

  return (
    <>
      <AddQuoteVersionGroupDialog
        projectId={projectId}
        isOpen={addQuoteVersionGroupOpen}
        onSubmit={createQuoteVersionGroup}
        onClose={() => setAddQuoteVersionGroupOpen(false)}
      />

      <DeleteConfirmationDialog
        isOpen={deleteVersionGroupOpen}
        title="Delete Quote Version Group"
        message="Are you sure you want to delete this quote version group? Quotes in the version group will no longer be accessible."
        onConfirm={() => {
          deleteQuoteVersionGroup(versionGroupToDelete!.id!);
          setVersionGroupToDelete(undefined);
          setDeleteVersionGroupOpen(false);
        }}
        onClose={() => {
          setVersionGroupToDelete(undefined);
          setDeleteVersionGroupOpen(false);
        }}
      />

      <Helmet>
        <title>{`Quotes - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Quotes", href: `/projects/${projectId}/quotes` },
        ]}
      />

      <PortalContainer>
        {!quoteVersionGroupsLoading &&
          quoteVersionGroups.map((quoteVersionGroup) => (
            <QuoteVersionGroup
              key={quoteVersionGroup.id}
              quoteVersionGroup={quoteVersionGroup}
              onChange={updateQuoteVersionGroup}
              onDelete={() => confirmDeleteQuoteVersionGroup(quoteVersionGroup)}
              onSettings={() =>
                history.push(
                  `/projects/${projectId}/quotes/quote-group/${quoteVersionGroup.id}/settings`
                )
              }
            />
          ))}

        <div className="flex items-center">
          <Button
            icon="folder-new"
            text="New Quote Group"
            className="mr-2"
            onClick={() => setAddQuoteVersionGroupOpen(true)}
          />
          <Popover
            minimal
            position={Position.BOTTOM_LEFT}
            popoverClassName={Classes.POPOVER_CONTENT}
          >
            <Button icon="help" minimal />
            <div className="w-96 p-4">
              <p>
                Organize quotes which are alternative options for the customer
                together into separate groups. For example, Quote Group 1
                "Reception Options" contains 3 different alternative Quotes from
                which the customer may choose. In addition Quote Group 2
                "Conference Room Options" contains 2 different alternative
                quotes. The customer may only choose one quote from a quote
                group.
              </p>
            </div>
          </Popover>
        </div>
      </PortalContainer>
    </>
  );
}
