import { MaybeElement, InputGroup, Intent, Tag } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { encodePriceAsInteger } from "../../../../helpers/PriceHelper";

function formatPrice(price: number) {
  return (price / 100).toFixed(2);
}

export type PriceCellProps = {
  value: number;
  disabled?: boolean;
  rightElement?: JSX.Element;
  onChange: (newValue: number) => void;
};

export function PriceCell({
  value,
  disabled,
  rightElement,
  onChange,
}: PriceCellProps) {
  const [validationError, setValidationError] = useState(false);
  const [valueAsString, setValueAsString] = useState(formatPrice(value));
  useEffect(() => setValueAsString(formatPrice(value)), [value]);

  return (
    <InputGroup
      value={valueAsString}
      disabled={disabled}
      onChange={(e) => setValueAsString(e.target.value)}
      onBlur={() => {
        const parsedValue = encodePriceAsInteger(parseFloat(valueAsString));
        if (isNaN(parsedValue)) {
          setValidationError(true);
        } else {
          setValidationError(false);
          setValueAsString(formatPrice(parsedValue));
          onChange(parsedValue);
        }
      }}
      leftIcon="dollar"
      rightElement={
        validationError ? (
          <Tag intent={Intent.DANGER}>Invalid Price</Tag>
        ) : (
          rightElement
        )
      }
      intent={validationError ? Intent.DANGER : Intent.NONE}
      fill
    />
  );
}
