import { Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";

type EntityLinkProps = {
  url: string;
  text: string;
};

export function EntityLink({ url, text }: EntityLinkProps) {
  return (
    <Link
      to={url}
      className="flex items-center space-x-2"
      style={{ color: "#106ba3" }}
    >
      <span>{text}</span>
      <Icon icon="arrow-top-right" iconSize={10} />
    </Link>
  );
}
