import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import React from "react";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { Project } from "../../../../types/ApiTypes";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { Button, Spinner, Tag } from "@blueprintjs/core";
import { BasicTable } from "../../../../components/shared/Table/BasicTable";
import { TableFooter } from "../../../../components/shared/Table/TableFooter";

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const CreatePrepTicket = gql`
  mutation CreatePrepTicket($projectId: Int!) {
    createPrepTicket(payload: { projectId: $projectId }) {
      id
    }
  }
`;

const GetPrepTickets = gql`
  query GetPrepTickets($projectId: Int!) {
    prepTickets(where: { projectId: { eq: $projectId } }) {
      id
      estimatedHours
      lineItems {
        id
        isPrepped
        inventoryItem {
          id
          location {
            id
            name
          }
          purchaseOrderShipmentLineItem {
            id
            purchaseOrderShipment {
              id
              purchaseOrder {
                id
                salesOrder {
                  id
                  code
                }
              }
            }
          }
        }
      }
      createdAt
    }
  }
`;

type GetPrepTicketsResult = {
  prepTickets: {
    id: number;
    estimatedHours?: number;
    lineItems: {
      id: number;
      isPrepped: boolean;
      inventoryItem: {
        id: number;
        location?: {
          id: number;
          name: string;
        };
        purchaseOrderShipmentLineItem: {
          id: number;
          purchaseOrderShipment: {
            id: number;
            purchaseOrder: {
              id: number;
              salesOrder: {
                id: number;
                code: string;
              };
            };
          };
        };
      };
    }[];
    createdAt: string;
  }[];
};

export function getPrepStatus(
  prepTicketLineItems: {
    id: number;
    isPrepped: boolean;
  }[]
) {
  const preppedCount = prepTicketLineItems.filter((i) => i.isPrepped).length;
  const totalCount = prepTicketLineItems.length;

  if (preppedCount === 0) {
    return (
      <Tag minimal fill intent="danger" icon="cross">
        Incomplete
      </Tag>
    );
  } else if (preppedCount < totalCount) {
    return (
      <Tag minimal fill intent="warning" icon="cross">
        In Progress
      </Tag>
    );
  } else {
    return (
      <Tag minimal fill intent="success" icon="tick">
        Complete
      </Tag>
    );
  }
}

export function getItemsPrepped(
  prepTicketLineItems: {
    id: number;
    isPrepped: boolean;
  }[]
) {
  const preppedCount = prepTicketLineItems.filter((i) => i.isPrepped).length;
  const totalCount = prepTicketLineItems.length;

  if (preppedCount === 0) {
    return (
      <Tag minimal fill intent="danger">
        {preppedCount}/{totalCount}
      </Tag>
    );
  } else if (preppedCount < totalCount) {
    return (
      <Tag minimal fill intent="warning">
        {preppedCount}/{totalCount}
      </Tag>
    );
  } else {
    return (
      <Tag minimal fill intent="success">
        {preppedCount}/{totalCount}
      </Tag>
    );
  }
}

function getSalesOrders(
  projectId: number,
  prepTicketLineItems: {
    id: number;
    inventoryItem: {
      id: number;
      purchaseOrderShipmentLineItem: {
        id: number;
        purchaseOrderShipment: {
          id: number;
          purchaseOrder: {
            id: number;
            salesOrder: {
              id: number;
              code: string;
            };
          };
        };
      };
    };
  }[]
) {
  return prepTicketLineItems
    .map(
      (i) =>
        i.inventoryItem.purchaseOrderShipmentLineItem.purchaseOrderShipment
          .purchaseOrder.salesOrder
    )
    .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
}

function getStorageLocations(
  prepTicketLineItems: {
    id: number;
    inventoryItem: {
      id: number;
      location?: {
        id: number;
        name: string;
      };
    };
  }[]
) {
  return prepTicketLineItems
    .map((i) => i.inventoryItem.location)
    .filter((v) => !!v)
    .filter((v, i, a) => a.findIndex((t) => t!.id === v!.id) === i)
    .map((v) => v!);
}

export default function ProjectPrepTickets() {
  const history = useHistory();
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GetProjectById, {
    variables: {
      projectId,
    },
  });

  const getPrepTickets = useQuery<GetPrepTicketsResult, { projectId: number }>(
    GetPrepTickets,
    { variables: { projectId }, fetchPolicy: "cache-and-network" }
  );

  const [createPrepTicket] = useMutation<
    { createPrepTicket: { id: number } },
    { projectId: number }
  >(CreatePrepTicket, {
    refetchQueries: ["GetPrepTickets"],
  });

  return (
    <>
      <Helmet>
        <title>{`Prep Tickets - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Prep Tickets",
            href: `/projects/${projectId}/prep-tickets`,
          },
        ]}
      />

      <PortalContainer>
        {getPrepTickets.loading && <Spinner />}

        {!getPrepTickets.loading && (
          <div>
            <BasicTable footer>
              <thead>
                <tr>
                  <th>Prep Ticket #</th>
                  <th>Sales Orders</th>
                  <th>Prep Status</th>
                  <th>Items Prepped</th>
                  <th>Fulfillment Date</th>
                  <th>Prep By Date</th>
                  <th>Est. Prep Time</th>
                  <th>Assigned To</th>
                  <th>Prepped By</th>
                  <th>Fulfillment Method</th>
                  <th>Storage Location</th>
                  <th>Prep Notes</th>
                  <th style={{ width: 200 }}>
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {getPrepTickets.data?.prepTickets.map((prepTicket) => (
                  <tr key={prepTicket.id}>
                    <td>
                      <Link
                        to={`/projects/${projectId}/prep-tickets/${prepTicket.id}`}
                      >
                        {prepTicket.id}
                      </Link>
                    </td>
                    <td className="space-x-2">
                      {getSalesOrders(projectId, prepTicket.lineItems).map(
                        (s) => (
                          <Link
                            key={s.id}
                            to={`/projects/${projectId}/sales-orders/${s.id}`}
                          >
                            {s.code}
                          </Link>
                        )
                      )}
                    </td>
                    <td>{getPrepStatus(prepTicket.lineItems)}</td>
                    <td>{getItemsPrepped(prepTicket.lineItems)}</td>
                    <td>???</td>
                    <td>???</td>
                    <td>{prepTicket.estimatedHours ?? "--"}</td>
                    <td>--</td>
                    <td>--</td>
                    <td>???</td>
                    <td>
                      {getStorageLocations(prepTicket.lineItems)
                        .map((s) => s.name)
                        .join(", ")}
                    </td>
                    <td>--</td>
                    <td>
                      <Button
                        small
                        fill
                        rightIcon="arrow-right"
                        text="View Prep Ticket"
                        onClick={() =>
                          history.push(
                            `/projects/${projectId}/prep-tickets/${prepTicket.id}`
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </BasicTable>

            <TableFooter className="p-1">
              <Button
                text="New Prep Ticket"
                icon="add"
                intent="primary"
                onClick={async () => {
                  const result = await createPrepTicket({
                    variables: {
                      projectId,
                    },
                  });
                  history.push(
                    `/projects/${projectId}/prep-tickets/${result.data!
                      .createPrepTicket.id!}`
                  );
                }}
              />
            </TableFooter>
          </div>
        )}
      </PortalContainer>
    </>
  );
}
