import {
  Button,
  InputGroup,
  Menu,
  MenuItem,
  Spinner,
  Tag,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { InventoryItem } from "../../../types/ApiTypes";
import { useState } from "react";
import {
  EntityCodeType,
  getEntityCode,
} from "../../../helpers/EntityCodeHelper";
import {
  getFullConfigurationCode,
  getFullConfigurationDescription,
} from "../../../helpers/CatalogHelper";

export const GET_INVENTORY_ITEMS = gql`
  query GetInventoryItems {
    sellableInventoryItems {
      id
      catalogProductId
      catalogProduct {
        id
        description
        code
      }
    }
  }
`;

export type ChooseFromInventoryPanelProps = {
  onChoose: (item: InventoryItem) => void;
  onGoBack: () => void;
};

export function ChooseFromInventoryPanel({
  onChoose,
  onGoBack,
}: ChooseFromInventoryPanelProps) {
  const getInventoryItems =
    useQuery<{ sellableInventoryItems: InventoryItem[] }>(GET_INVENTORY_ITEMS);

  const [query, setQuery] = useState("");

  function getFilteredInventoryItems() {
    const normalizedQuery = query.toLowerCase().trim();

    if (normalizedQuery.length === 0)
      return getInventoryItems.data!.sellableInventoryItems;

    return getInventoryItems.data!.sellableInventoryItems.filter((i) => {
      const normalizedId = i.id.toString();
      const normalizedInventoryCode = getEntityCode(
        i.id,
        EntityCodeType.InventoryItem
      ).toLowerCase();
      const normalizedDescription = getFullConfigurationDescription(
        i.configuration
      )
        .toLowerCase()
        .trim();
      const normalizedProductCode = getFullConfigurationCode(i.configuration)
        .toLowerCase()
        .trim();

      return (
        normalizedId.includes(normalizedQuery) ||
        normalizedInventoryCode.includes(normalizedQuery) ||
        normalizedDescription.includes(normalizedQuery) ||
        normalizedProductCode.includes(normalizedQuery)
      );
    });
  }

  const filteredInventoryItems = getInventoryItems.loading
    ? []
    : getFilteredInventoryItems();

  return (
    <div>
      <div className="p-1 flex items-center border-b">
        <Button
          icon="chevron-left"
          onClick={onGoBack}
          className="mr-2"
          minimal
          small
        />
        <h2 className="font-medium">Choose from Inventory</h2>
      </div>
      <div className="p-1">
        <InputGroup
          type="search"
          leftIcon="search"
          placeholder="Search inventory..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      {getInventoryItems.loading && (
        <div className="p-1 flex items-center justify-center">
          <Spinner size={24} />
        </div>
      )}
      {!getInventoryItems.loading && (
        <Menu>
          {filteredInventoryItems.map((inventoryItem) => (
            <MenuItem
              key={inventoryItem.id}
              text={
                <>
                  <Tag minimal className="mr-2">
                    {getEntityCode(
                      inventoryItem.id,
                      EntityCodeType.InventoryItem
                    )}
                  </Tag>
                  {getFullConfigurationDescription(inventoryItem.configuration)}
                </>
              }
              label={getFullConfigurationCode(inventoryItem.configuration)}
              onClick={() => onChoose(inventoryItem)}
            />
          ))}
          {filteredInventoryItems.length === 0 && (
            <MenuItem disabled text={`No results for "${query}"`} />
          )}
        </Menu>
      )}
    </div>
  );
}
