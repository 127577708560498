import { Classes, Dialog, DialogProps } from "@blueprintjs/core";
import { CreateFulfillment } from "./CreateFulfillment";

type CreateFulfillmentDialogProps = DialogProps & {};

export function CreateFulfillmentDialog({
  ...dialogProps
}: CreateFulfillmentDialogProps) {
  return (
    <Dialog {...dialogProps} title="Create Fulfillment">
      <div className={Classes.DIALOG_BODY}>
        <CreateFulfillment
          onClose={() => {
            if (dialogProps.onClose) dialogProps.onClose(null!);
          }}
        />
      </div>
    </Dialog>
  );
}
