import produce from "immer";
import {
  EditableLineItem,
  EditableLineItemGroup,
  LineItemEditorActions,
  LineItemEditorState,
} from "./LineItemEditorTypes";
import { flatten } from "lodash";
import { recalculateLineItemSortIndex } from "./LineItemEditorHelpers";

export function setPrimaryContact(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setPrimaryContact") return state;
  return produce(state, (draft) => {
    draft.quickSettings.primaryContact = action.primaryContact;
    draft.quickSettings.isModified = true;
  });
}

export function setBillingAddress(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setBillingAddress") return state;
  return produce(state, (draft) => {
    draft.quickSettings.billingAddress = action.billingAddress;
    draft.quickSettings.isModified = true;
  });
}

export function setShipToAddress(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setShipToAddress") return state;
  return produce(state, (draft) => {
    draft.quickSettings.shipToAddress = action.shipToAddress;
    draft.quickSettings.isModified = true;
  });
}

export function setSalesRepAccount(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setSalesRepAccount") return state;
  return produce(state, (draft) => {
    draft.quickSettings.salesRepAccount = action.salesRepAccount;
    draft.quickSettings.isModified = true;
  });
}

export function setRequestedFulfillmentDate(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setRequestedFulfillmentDate") return state;
  return produce(state, (draft) => {
    draft.quickSettings.requestedFulfillmentDate =
      action.requestedFulfillmentDate;
    draft.quickSettings.isModified = true;
  });
}

export function setRequestedFulfillmentDateNotes(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setRequestedFulfillmentDateNotes") return state;
  return produce(state, (draft) => {
    draft.quickSettings.requestedFulfillmentDateNotes =
      action.requestedFulfillmentDateNotes;
    draft.quickSettings.isModified = true;
  });
}

export function setRequestedFulfillmentDateFlagged(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setRequestedFulfillmentDateFlagged") return state;
  return produce(state, (draft) => {
    draft.quickSettings.requestedFulfillmentDateFlagged =
      action.requestedFulfillmentDateFlagged;
    draft.quickSettings.isModified = true;
  });
}

export function addSelectedItem(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "addSelectedItem") return state;

  return produce(state, (draft) => {
    draft.selectedItems.push({
      lineItemGroupIndex: action.lineItemGroupIndex,
      lineItemIndex: action.lineItemIndex,
    });
  });
}

export function removeSelectedItem(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "removeSelectedItem") return state;

  return produce(state, (draft) => {
    draft.selectedItems = draft.selectedItems.filter(
      (si) =>
        si.lineItemGroupIndex !== action.lineItemGroupIndex ||
        si.lineItemIndex !== action.lineItemIndex
    );
  });
}

export function importSIF(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "importSIF") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    action.lineItems.forEach((li) =>
      lineItemGroup.lineItems.push({
        id: undefined,
        sortIndex: lineItemGroup.lineItems.length,
        sifEntry: li.sifEntry,
        quantity: li.quantity,
        listPriceMultiplier: 1,
        grossProfitPercent: 0,
        sellPrice: li.sellPrice,
        description: li.description,
        internalDescription: "",
        isCreated: true,
        isModified: false,
        isRemoved: false,
        taxCode: "PA3000100",
        taxAmount: 0,
        lineItemGroupId: state.lineItemGroups[action.lineItemGroupIndex].id,
        configuration: li.configuration
          ? {
              isCustomConfiguration: false,
              catalogProductId: li.configuration.catalogProductId,
              catalogProductCode: li.configuration.catalogProductCode,
              catalogProductDescription:
                li.configuration.catalogProductDescription,
              features: li.configuration.features
                .filter((feature) => feature.selections.length != 0)
                .map((feature) => ({
                  description:
                    feature.selections[0].catalogOptionGroupDescription,
                  catalogOptionGroupId:
                    feature.selections[0].catalogOptionGroupId,
                  selections: feature.selections.map((selection) => ({
                    catalogOptionGroupId: selection.catalogOptionGroupId,
                    catalogOptionGroupCode: selection.catalogOptionGroupCode,
                    catalogOptionGroupDescription:
                      selection.catalogOptionGroupDescription,
                    catalogOptionId: selection.catalogOptionId,
                    catalogOptionCode: selection.catalogOptionCode,
                    catalogOptionDescription:
                      selection.catalogOptionDescription,
                  })),
                })),
            }
          : undefined,
        modifiedFields: {
          quantity: true,
          listPriceMultiplier: true,
          grossProfitPercent: true,
          sellPrice: true,
          product: true,
          description: true,
          tax: false,
        },
      })
    );
  });
}

export function setLineItemGroupName(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemGroupName") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    lineItemGroup.name = action.name;
    lineItemGroup.isModified = true;
    lineItemGroup.modifiedFields.name = true;
  });
}

export function collapseGroup(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "collapseGroup") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups[action.lineItemGroupIndex].isOpen = false;
  });
}

export function expandGroup(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "expandGroup") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups[action.lineItemGroupIndex].isOpen = true;
  });
}

export function expandAllGroups(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "expandAllGroups") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups.forEach((g) => (g.isOpen = true));
  });
}

export function collapseAllGroups(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "collapseAllGroups") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups.forEach((g) => (g.isOpen = false));
  });
}

export function moveLineItemGroupUp(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
) {
  if (action.type !== "moveLineItemGroupUp") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    draft.lineItemGroups.splice(action.lineItemGroupIndex, 1);
    draft.lineItemGroups.splice(
      action.lineItemGroupIndex - 1,
      0,
      lineItemGroup
    );

    draft.lineItemGroups.forEach((g, i) => {
      g.sortIndex = i;
      g.isModified = true;
    });

    recalculateLineItemSortIndex(draft);
  });
}

export function moveLineItemGroupDown(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
) {
  if (action.type !== "moveLineItemGroupDown") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    draft.lineItemGroups.splice(action.lineItemGroupIndex, 1);
    draft.lineItemGroups.splice(
      action.lineItemGroupIndex + 1,
      0,
      lineItemGroup
    );

    draft.lineItemGroups.forEach((g, i) => {
      g.sortIndex = i;
      g.isModified = true;
    });

    recalculateLineItemSortIndex(draft);
  });
}

export function removeLineItemGroup(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "removeLineItemGroup") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    if (lineItemGroup.isCreated) {
      draft.lineItemGroups.splice(action.lineItemGroupIndex, 1);
    } else {
      lineItemGroup.isRemoved = true;
    }

    recalculateLineItemSortIndex(draft);
  });
}

export function undoRemoveLineItemGroup(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "undoRemoveLineItemGroup") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups[action.lineItemGroupIndex].isRemoved = false;
    recalculateLineItemSortIndex(draft);
  });
}

export function removeLineItemConfiguration(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "removeLineItemConfiguration") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.configuration = undefined;
    lineItem.modifiedFields.product = true;
    lineItem.isModified = true;
  });
}

export function setLineItemConfiguration(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemConfiguration") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.configuration = action.configuration;

    lineItem.modifiedFields.product = true;
    lineItem.isModified = true;
  });
}

export function setLineItemSifEntry(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemSifEntry") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];
    lineItem.sifEntry = action.sifEntry;
  });
}

export function setLineItemDescription(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemDescription") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.description = action.description;
    lineItem.modifiedFields.description = true;
    lineItem.isModified = true;
  });
}

export function setLineItemInternalDescription(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemInternalDescription") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.internalDescription = action.internalDescription;
    lineItem.modifiedFields.description = true;
    lineItem.isModified = true;
  });
}

export function setLineItemListPriceMultiplier(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemListPriceMultiplier") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.listPriceMultiplier = action.listPriceMultiplier;
    lineItem.modifiedFields.listPriceMultiplier = true;
    lineItem.isModified = true;
  });
}

export function setLineItemQuantity(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemQuantity") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.quantity = action.quantity;
    lineItem.modifiedFields.quantity = true;
    lineItem.isModified = true;
  });
}

export function duplicateLineItem(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "duplicateLineItem") return state;

  const lineItems = state.lineItemGroups[action.lineItemGroupIndex].lineItems!;

  return {
    ...state,
    lineItemGroups: [
      ...state.lineItemGroups.slice(0, action.lineItemGroupIndex),
      {
        ...state.lineItemGroups[action.lineItemGroupIndex],
        lineItems: [
          ...lineItems.slice(0, action.lineItemIndex),
          lineItems[action.lineItemIndex],
          {
            ...lineItems[action.lineItemIndex],
            id: undefined,
            isCreated: true,
            isModified: false,
          },
          ...lineItems.slice(action.lineItemIndex + 1),
        ],
      },
      ...state.lineItemGroups.slice(action.lineItemGroupIndex + 1),
    ],
  };
}

export function moveLineItemUp(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
) {
  if (action.type !== "moveLineItemUp") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItems = lineItemGroup.lineItems;
    const lineItem = lineItems[action.lineItemIndex];

    lineItems[action.lineItemIndex] = lineItems[action.lineItemIndex - 1];
    lineItems[action.lineItemIndex - 1] = lineItem;

    const allLineItems = flatten(draft.lineItemGroups.map((g) => g.lineItems));

    allLineItems.forEach((li, index) => {
      li.sortIndex = index;
      li.isModified = true;
    });
  });
}

export function moveLineItemDown(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
) {
  if (action.type !== "moveLineItemDown") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItems = lineItemGroup.lineItems;
    const lineItem = lineItems[action.lineItemIndex];

    lineItems[action.lineItemIndex] = lineItems[action.lineItemIndex + 1];
    lineItems[action.lineItemIndex + 1] = lineItem;

    const allLineItems = flatten(draft.lineItemGroups.map((g) => g.lineItems));

    allLineItems.forEach((li, index) => {
      li.sortIndex = index;
      li.isModified = true;
    });
  });
}

export function removeLineItem(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "removeLineItem") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItems = lineItemGroup.lineItems;
    const lineItem = lineItems[action.lineItemIndex];

    if (lineItem.isCreated) {
      lineItems.splice(action.lineItemIndex, 1);
    } else {
      lineItem.isRemoved = true;
    }

    recalculateLineItemSortIndex(draft);
  });
}

export function undoRemoveLineItem(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "undoRemoveLineItem") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItems = lineItemGroup.lineItems;
    const lineItem = lineItems[action.lineItemIndex];
    lineItem.isRemoved = false;

    recalculateLineItemSortIndex(draft);
  });
}

export function beginEditingConfiguration(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "beginEditingConfiguration") return state;

  return produce(state, (draft) => {
    draft.editingConfiguration = {
      lineItemGroupIndex: action.lineItemGroupIndex,
      lineItemIndex: action.lineItemIndex,
    };
  });
}

export function endEditingConfiguration(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "endEditingConfiguration") return state;

  return produce(state, (draft) => {
    draft.editingConfiguration = undefined;
  });
}

export function setLineItemGrossProfitPercent(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemGrossProfitPercent") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.grossProfitPercent = action.grossProfitPercent;
    lineItem.modifiedFields.grossProfitPercent = true;
    lineItem.isModified = true;
  });
}

export function setLineItemTaxCode(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemTaxCode") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.taxCode = action.taxCode;
    lineItem.modifiedFields.tax = true;
    lineItem.isModified = true;
  });
}

export function setLineItemSellPrice(
  state: LineItemEditorState<EditableLineItemGroup<EditableLineItem>>,
  action: LineItemEditorActions
): LineItemEditorState<EditableLineItemGroup<EditableLineItem>> {
  if (action.type !== "setLineItemSellPrice") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];
    const lineItem = lineItemGroup.lineItems[action.lineItemIndex];

    lineItem.sellPrice = action.sellPrice;
    lineItem.modifiedFields.sellPrice = true;
    lineItem.isModified = true;
  });
}
