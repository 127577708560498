import { useParams, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProjectNavbar } from "../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import React, { useState } from "react";
import { useProjectAttachments } from "../../../hooks/useProjectAttachments";
import { ProjectAttachmentsTable } from "../../../components/projects/[projectId]/attachments/ProjectAttachmentsTable";
import { TableFooter } from "../../../components/shared/Table/TableFooter";
import { Button } from "@blueprintjs/core";
import { UploadAttachmentDialog } from "../../../components/projects/[projectId]/attachments/UploadAttachmentDialog";
import { create } from "domain";
import { Attachment, Project } from "../../../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

export type AttachmentsRouteParams = {
  projectId: string;
};

export function Attachments() {
  const params = useParams<AttachmentsRouteParams>();
  const projectId = parseInt(params.projectId);
  const [uploadAttachmentOpen, setUploadAttachmentOpen] = useState(false);

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const {
    projectAttachments,
    loading: attachmentsLoading,
    createAttachment,
    updateAttachment,
    removeAttachment,
  } = useProjectAttachments(projectId);

  const uploadAttachment = async (file: File) => {
    const attachment: Attachment = {
      name: file.name,
      contentType: file.type,
      projectId,
    };

    await createAttachment(attachment, file);
  };

  return (
    <>
      <UploadAttachmentDialog
        isOpen={uploadAttachmentOpen}
        onSubmit={(f) => uploadAttachment(f)}
        onClose={() => setUploadAttachmentOpen(false)}
      />

      <Helmet>
        <title>{`Attachments - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Attachments", href: `/projects/${projectId}/attachments` },
        ]}
      />

      <PortalContainer>
        {!getProjectById.loading && !attachmentsLoading && (
          <>
            <ProjectAttachmentsTable
              attachments={projectAttachments!}
              onChange={updateAttachment}
              onRemove={removeAttachment}
            />
            <TableFooter className="p-1">
              <Button
                icon="upload"
                text="Upload Attachment"
                onClick={() => setUploadAttachmentOpen(true)}
              />
            </TableFooter>
          </>
        )}
      </PortalContainer>
    </>
  );
}
