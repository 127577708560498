import { Icon, IconName } from "@blueprintjs/core";
import { NavLink } from "react-router-dom";
import styles from "./ProjectNavbar.module.scss";
import React from "react";

export type ProjectNavbarItemProps = {
  icon: IconName;
  label: string;
  href: string;
};

export function ProjectNavbarItem({
  icon,
  label,
  href,
}: ProjectNavbarItemProps) {
  return (
    <NavLink to={href} className={styles.projectNavItem}>
      <p>{label}</p>
    </NavLink>
  );
}
