import {
  Button,
  Classes,
  Dialog,
  DialogProps,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import { useState } from "react";
import axios from "axios";
import { useApiError } from "../../../../../../hooks/useApiError";

export type CancelPurchaseOrderShipmentDialogProps = DialogProps & {
  purchaseOrderShipmentId?: number;
  onSubmit: () => void;
};

export function CancelPurchaseOrderShipmentDialog({
  purchaseOrderShipmentId,
  isOpen,
  onSubmit,
  onClose,
}: CancelPurchaseOrderShipmentDialogProps) {
  const [cancellationReason, setCancellationReason] = useState("");
  const handleCancellationError = useApiError("cancel shipment");

  function cancelShipment(e) {
    e.preventDefault();
    if (!purchaseOrderShipmentId || cancellationReason === "") return;

    axios
      .post(`/purchaseordershipments/${purchaseOrderShipmentId}/cancel`, {
        cancellationReason,
      })
      .then(() => onSubmit())
      .catch((err) => handleCancellationError(err));
  }

  return (
    <Dialog isOpen={isOpen} isCloseButtonShown={false} title="Cancel Shipment">
      <form onSubmit={cancelShipment}>
        <div className={Classes.DIALOG_BODY}>
          <p>
            Please provide a reason for cancelling this shipment. Once a
            shipment is cancelled, it can no longer be edited or received. A new
            shipment will need to be made to replace it.
          </p>
          <FormGroup label="Cancellation Reason">
            <TextArea
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              fill
              required
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              type="submit"
              text="Cancel Shipment"
              intent={Intent.DANGER}
              disabled={cancellationReason.length === 0}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
