import {
  Button,
  Callout,
  Classes,
  Collapse,
  Dialog,
  DialogProps,
  Intent,
} from "@blueprintjs/core";
import { useUserPreference } from "../../../hooks/useUserPreference";
import { useCallback, useEffect } from "react";
import { DefaultToaster } from "../DefaultToaster/DefaultToaster";

export type ErrorDialogProps = DialogProps & {
  error?: Error;
};

export function ErrorDialog({ error, ...dialogProps }: ErrorDialogProps) {
  const [moreInfoExpanded, setMoreInfoExpanded] = useUserPreference(
    "expandMoreInfo",
    false
  );

  const copyToClipboard = useCallback(async () => {
    if (!error) return;
    if (error.stack) {
      await navigator.clipboard.writeText(error.stack);
    } else {
      await navigator.clipboard.writeText(error.message + " (No Stack Trace)");
    }
    DefaultToaster.show({
      message: "Error details copied to clipboard.",
      icon: "tick",
      intent: "success",
    });
  }, [error]);

  return (
    <Dialog title={dialogProps.title ?? "An error occurred"} {...dialogProps}>
      <div className={Classes.DIALOG_BODY}>
        {error && (
          <div className="space-y-4">
            <Callout intent={Intent.DANGER} icon="error">
              {error.message}
            </Callout>

            <Button
              small
              minimal
              icon={moreInfoExpanded ? "chevron-down" : "chevron-right"}
              text="More information"
              onClick={() => setMoreInfoExpanded((s) => !s)}
            />

            <Collapse isOpen={moreInfoExpanded}>
              <div className="space-y-4">
                <h4>
                  Please contact support with the following information...
                </h4>
                <div className="bg-white border rounded overflow-hidden">
                  <pre className="p-2 overflow-auto max-h-60">
                    <code>{error.stack}</code>
                  </pre>
                  <div className="border-t p-1">
                    <Button
                      icon="clipboard"
                      onClick={copyToClipboard}
                      minimal
                    />
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={dialogProps.onClose} text="Dismiss" />
        </div>
      </div>
    </Dialog>
  );
}
