import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Callout,
  Checkbox,
  FormGroup,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import {
  PurchaseOrder,
  SalesOrder,
  SalesOrderLineItem,
  Vendor,
} from "../../../../../../types/ApiTypes";
import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

function getAllSalesOrderLineItems(salesOrder: SalesOrder) {
  const salesOrderLineItems: SalesOrderLineItem[] = [];

  salesOrder.salesOrderLineItemGroups!.forEach((group) => {
    group.salesOrderLineItems!.forEach((line) =>
      salesOrderLineItems.push(line)
    );
  });

  return salesOrderLineItems;
}

function getUniqueSalesOrderVendors(salesOrders: SalesOrder[]) {
  const vendorMap: { [vendorId: string]: Vendor } = {};

  salesOrders.forEach((so) => {
    const lineItems = getAllSalesOrderLineItems(so);
    lineItems.forEach((line) => {
      if (line.configuration?.vendor) {
        vendorMap[line.configuration.vendor.id] = line.configuration.vendor;
      }
    });
  });

  return Object.values(vendorMap);
}

function getVendorsWithoutPurchaseOrders(
  allVendors: Vendor[],
  purchaseOrderVendors: Vendor[]
) {
  return allVendors.filter(
    (v) => purchaseOrderVendors.findIndex((pov) => pov.id === v.id) === -1
  );
}

const GET_SALES_ORDER_INFO = gql`
  query GetSalesOrderInfo($salesOrderId: Int!) {
    salesOrders(where: { id: { eq: $salesOrderId } }) {
      id
      salesOrderLineItemGroups {
        id
        salesOrderLineItems {
          id
          configurationId
          configuration {
            id
            vendor {
              id
              name
            }
            catalogProductId
            catalogProduct {
              id
            }
          }
        }
      }
    }

    purchaseOrders(where: { salesOrderId: { eq: $salesOrderId } }) {
      id
      manufacturerId
      projectId
      vendor {
        id
        name
      }
    }
  }
`;

const GENERATE_PURCHASE_ORDERS = gql`
  mutation GeneratePurchaseOrders($salesOrderId: Int!, $vendorIds: [Int!]!) {
    generatePurchaseOrdersFromSalesOrder(
      salesOrderId: $salesOrderId
      vendorIds: $vendorIds
    ) {
      id
    }
  }
`;

type GeneratePurchaseOrdersFormProps = {
  salesOrderId: number;
  onSubmit: () => void;
};

export function GeneratePurchaseOrdersForm({
  salesOrderId,
  onSubmit,
}: GeneratePurchaseOrdersFormProps) {
  const history = useHistory();

  const getSalesOrderInfo = useQuery<
    { purchaseOrders: PurchaseOrder[]; salesOrders: SalesOrder[] },
    { salesOrderId: number }
  >(GET_SALES_ORDER_INFO, {
    variables: {
      salesOrderId,
    },
    fetchPolicy: "network-only",
  });

  const [generatePurchaseOrders] = useMutation<
    { generatePurchaseOrders: PurchaseOrder[] },
    { salesOrderId: number; vendorIds: number[] }
  >(GENERATE_PURCHASE_ORDERS, {
    refetchQueries: ["GetPurchaseOrderReport", "GetProjectPurchaseOrders"],
  });

  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]);
  const [error, setError] = useState<Error>();
  const [generating, setGenerating] = useState(false);

  const { salesOrders, purchaseOrders } = getSalesOrderInfo.data ?? {};
  const salesOrderVendors = getUniqueSalesOrderVendors(salesOrders ?? []);
  const purchaseOrderVendors = (purchaseOrders ?? []).map((po) => po.vendor!);
  const vendorsWithoutPurchaseOrders = getVendorsWithoutPurchaseOrders(
    salesOrderVendors,
    purchaseOrderVendors
  );

  const toggleCheckAll = useCallback(() => {
    if (selectedVendors.length === 0) {
      setSelectedVendors([...vendorsWithoutPurchaseOrders]);
    } else {
      setSelectedVendors([]);
    }
  }, [selectedVendors, vendorsWithoutPurchaseOrders]);

  const toggleCheck = useCallback(
    (vendor: Vendor) => {
      if (selectedVendors.findIndex((v) => v.id === vendor.id) > -1) {
        setSelectedVendors(selectedVendors.filter((sv) => sv.id !== vendor.id));
      } else {
        setSelectedVendors([...selectedVendors, vendor]);
      }
    },
    [selectedVendors]
  );

  const handleSubmit = useCallback(async () => {
    setError(undefined);
    setGenerating(true);

    try {
      await generatePurchaseOrders({
        variables: {
          vendorIds: selectedVendors.map((v) => v.id),
          salesOrderId,
        },
      });
    } catch (err) {
      setError(err);
    } finally {
      setGenerating(false);
    }

    onSubmit();
  }, [selectedVendors]);

  if (getSalesOrderInfo.loading) {
    return (
      <div className="flex items-center justify-center p-5">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {error && (
        <Callout
          intent={Intent.DANGER}
          title="Unable to generate purchase orders"
        >
          {error.message}
        </Callout>
      )}

      <FormGroup label="Choose which vendors you'd like to generate purchase orders for...">
        <div className="bg-white rounded-md overflow-hidden divide-y">
          <div className="flex items-center">
            <div className="p-2">
              <Checkbox
                style={{ margin: 0 }}
                indeterminate={
                  selectedVendors.length > 0 &&
                  vendorsWithoutPurchaseOrders.length !== selectedVendors.length
                }
                checked={selectedVendors.length > 0}
                onClick={toggleCheckAll}
                disabled={vendorsWithoutPurchaseOrders.length === 0}
              />
            </div>
            <h4 className="p-2 flex-1 font-medium">Vendor Name</h4>
          </div>

          {vendorsWithoutPurchaseOrders.map((vendor) => (
            <div key={vendor.id} className="flex items-center bg-gray-50">
              <div className="p-2">
                <Checkbox
                  style={{ margin: 0 }}
                  checked={
                    selectedVendors.findIndex((v) => v.id === vendor.id) !== -1
                  }
                  onClick={() => toggleCheck(vendor)}
                />
              </div>
              <p className="p-2 flex-1 m-0">{vendor.name}</p>
            </div>
          ))}

          {vendorsWithoutPurchaseOrders.length === 0 && (
            <div className="flex items-center bg-gray-50">
              <p className="p-2 text-center m-0 text-gray-600">
                There are no vendors available.
              </p>
            </div>
          )}
        </div>
      </FormGroup>

      <FormGroup
        label="The following vendors already have purchase orders..."
        helperText="To re-generate a purchase order, first delete the existing purchase order."
      >
        <div className="bg-white rounded-md overflow-hidden divide-y">
          {(purchaseOrders ?? []).map((po) => (
            <div key={po.id} className="flex items-center bg-gray-50">
              <p className="p-2 flex-1 m-0">{po.vendor!.name}</p>
              <Button
                small
                minimal
                rightIcon="chevron-right"
                text="View PO"
                intent={Intent.PRIMARY}
                className="mr-1"
                onClick={() =>
                  history.push(
                    `/projects/${po.projectId}/purchase-orders/${po.id}`
                  )
                }
              />
            </div>
          ))}
        </div>
      </FormGroup>

      <div className="flex space-x-2">
        <Button
          type="submit"
          intent={Intent.PRIMARY}
          text="Generate Purchase Orders"
          disabled={selectedVendors.length === 0 || generating}
          onClick={handleSubmit}
          loading={generating}
        />
      </div>
    </div>
  );
}
