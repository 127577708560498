import { Prompt } from "react-router-dom";
import { Button, Icon } from "@blueprintjs/core";

type UnsavedChangesWarningProps = {
  resourceType: string;
  saving?: boolean;
  onSave: () => void;
};

export function UnsavedChangesWarning({
  resourceType,
  saving,
  onSave,
}: UnsavedChangesWarningProps) {
  return (
    <>
      <Prompt
        message={`You have unsaved changes on this ${resourceType}. Are you sure you want to leave the page and discard them?`}
      />

      <div
        className="sticky z-10 top-0 bg-indigo-50 text-indigo-700 border-b border-t border-indigo-700 py-2 px-2 pl-4 flex items-center space-x-4"
        style={{ top: 77 }}
      >
        <Icon icon="edit" />

        <span className="flex-1">
          You have made changes to this {resourceType}.
        </span>

        <Button
          text={saving ? "Saving Changes..." : "Save Changes"}
          loading={saving}
          disabled={saving}
          onClick={onSave}
        />
      </div>
    </>
  );
}
