import { CatalogManufacturer, CatalogProduct } from "../../../types/ApiTypes";
import { useState } from "react";
import {
  Button,
  Icon,
  Intent,
  Menu,
  MenuItem,
  Spinner,
} from "@blueprintjs/core";
import { ProductTree } from "./ProductTree";
import { gql, useQuery } from "@apollo/client";
import { useFavoriteManufacturers } from "./useFavoriteManufacturers";

const GET_MANUFACTURERS = gql`
  query GetManufacturers {
    catalogManufacturers {
      id
      name
    }
  }
`;

function useCatalogManufacturers() {
  const getManufacturers =
    useQuery<{ catalogManufacturers: CatalogManufacturer[] }>(
      GET_MANUFACTURERS
    );

  return {
    loading: getManufacturers.loading,
    error: getManufacturers.error,
    manufacturers: getManufacturers.data?.catalogManufacturers,
  };
}

export type ChooseFromCatalogPanelProps = {
  rootLevel?: boolean;
  onChoose: (product: CatalogProduct) => void;
  onGoBack: () => void;
};

export function ChooseFromCatalogPanel({
  rootLevel = false,
  onChoose,
  onGoBack,
}) {
  const [selectedProduct, setSelectedProduct] = useState<CatalogProduct>();
  const [selectedManufacturer, setSelectedManufacturer] =
    useState<CatalogManufacturer>();
  const {
    favoriteManufacturers,
    addFavoriteManufacturer,
    removeFavoriteManufacturer,
  } = useFavoriteManufacturers();

  const { loading, manufacturers } = useCatalogManufacturers();

  if (!selectedManufacturer) {
    return (
      <div>
        <div className="p-1 flex items-center border-b">
          {!rootLevel && (
            <Button
              icon="chevron-left"
              onClick={onGoBack}
              className="mr-2"
              minimal
              small
            />
          )}
          <h2 className="font-medium">Choose from Catalog</h2>
        </div>
        <div className="max-h-96 overflow-y-auto">
          {loading && <Spinner />}

          <div className="divide-y">
            {favoriteManufacturers.length > 0 && (
              <div className="space-y-1 p-1">
                {favoriteManufacturers.map((manufacturer) => (
                  <div className="flex items-center">
                    <Button
                      icon="book"
                      alignText="left"
                      text={
                        manufacturer.name === ""
                          ? "Missing Manufacturer Name"
                          : manufacturer.name
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedManufacturer(manufacturer);
                      }}
                      fill
                      minimal
                    />
                    <Button
                      minimal
                      icon="star"
                      onClick={() => removeFavoriteManufacturer(manufacturer)}
                    />
                  </div>
                ))}
              </div>
            )}
            {!loading && (
              <div className="space-y-1 p-1">
                {manufacturers!
                  .filter(
                    (m) =>
                      favoriteManufacturers.findIndex(
                        (fm) => fm.id === m.id
                      ) === -1
                  )
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((manufacturer) => (
                    <div className="flex items-center">
                      <Button
                        icon="book"
                        alignText="left"
                        text={
                          manufacturer.name === ""
                            ? "<Missing Manufacturer Name>"
                            : manufacturer.name
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedManufacturer(manufacturer);
                        }}
                        fill
                        minimal
                      />
                      <Button
                        minimal
                        icon="star-empty"
                        onClick={() => addFavoriteManufacturer(manufacturer)}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="p-1 flex items-center border-b">
          <Button
            icon="chevron-left"
            onClick={() => setSelectedManufacturer(undefined)}
            className="mr-2"
            minimal
            small
          />
          <h2 className="font-medium">
            Choose from {selectedManufacturer.name}
          </h2>
        </div>
        <div className="max-h-96 overflow-y-auto">
          <ProductTree
            manufacturerId={selectedManufacturer.id}
            onChoose={(p) => setSelectedProduct(p)}
          />
        </div>
        <div className="p-1 border-t flex items-center justify-end">
          <Button
            text="Choose Product"
            intent={Intent.PRIMARY}
            disabled={!selectedProduct}
            onClick={() => onChoose(selectedProduct!)}
          />
        </div>
      </div>
    );
  }
}
