import { formatDateTime } from "../../../../helpers/DateHelper";
import { formatContactName } from "../../../../helpers/ContactHelper";
import { FulfillmentMethodType } from "../../../../types/ApiTypes";
import { NonIdealState } from "@blueprintjs/core";
import React from "react";
import { TableHeader } from "../../../../components/shared/Table/TableHeader";
import { gql, useQuery } from "@apollo/client";
import { getPreppedStatus } from "./helpers";
import { formatAccountName } from "../../../../helpers/AccountHelper";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useReportDataState } from "../../../../hooks/useReportDataState";
import { EntityLink } from "../../../../components/reports/EntityLink";
import { ReportColumnMenu } from "../../../../components/reports/ReportColumnMenu";
import { formatFulfillmentMethodType } from "../../../../helpers/FulfillmentHelper";

const GetCompletedFulfillments = gql`
  query GetCompletedFulfillments($projectId: Int!) {
    fulfillments(
      where: {
        projectId: { eq: $projectId }
        and: [{ completedAt: { neq: null } }]
      }
    ) {
      id
      requestedAt
      installStartsAt
      installEndsAt
      deliveryStartsAt
      deliveryEndsAt
      estimatedHours
      fulfillmentMethodType
      fulfillmentMethodNotes
      lineItems {
        id
        salesOrderLineItemId
        salesOrderLineItem {
          id
          inventoryItems {
            id  
            prepTicketLineItem {
              id
              isPrepped
            }
          }
        }
      }
      project {
        id
        salesRepAccount {
          id
          name
        }
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            __typename
          }
          company {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

type GetCompletedFulfillmentsResult = {
  fulfillments: {
    id: number;
    requestedAt?: Date;
    installStartsAt?: Date;
    installEndsAt?: Date;
    deliveryStartsAt?: Date;
    deliveryEndsAt?: Date;
    estimatedHours?: number;
    fulfillmentMethodType?: FulfillmentMethodType;
    fulfillmentMethodNotes?: string;
      lineItems: {
          id: number;
          salesOrderLineItemId?: number;
          salesOrderLineItem?: {
              inventoryItems?: {
                  prepTicketLineItem?: {
                      id: number;
                      isPrepped: boolean;
                  };
              }[];
          };
      }[];
    project: {
      id: number;
      salesRepAccount?: {
        id: number;
        name: string;
      };
      primaryContact?: {
        id: number;
        person: {
          id: number;
          firstName: string;
          lastName: string;
        };
        company: {
          id: number;
          name: string;
        };
      };
    };
  }[];
};

type CompletedFulfillmentsTableProps = {
  projectId: number;
};

export function CompletedFulfillmentsTable({
  projectId,
}: CompletedFulfillmentsTableProps) {
  const getCompletedFulfillments = useQuery<
    GetCompletedFulfillmentsResult,
    { projectId: number }
  >(GetCompletedFulfillments, {
    variables: { projectId },
    fetchPolicy: "cache-and-network",
  });

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const fulfillments = (getCompletedFulfillments.data?.fulfillments ?? []).map(
    (f) => ({
      ...f,
      requestedAt: f.requestedAt ? new Date(f.requestedAt) : undefined,
      installStartsAt: f.installStartsAt
        ? new Date(f.installStartsAt)
        : undefined,
      installEndsAt: f.installEndsAt ? new Date(f.installEndsAt) : undefined,
      deliveryStartsAt: f.deliveryStartsAt
        ? new Date(f.deliveryStartsAt)
        : undefined,
      deliveryEndsAt: f.deliveryEndsAt ? new Date(f.deliveryEndsAt) : undefined,
      primaryContactName: formatContactName(f.project.primaryContact),
      salesRepName: formatAccountName(f.project.salesRepAccount),
      fulfillmentMethod: f.fulfillmentMethodType
        ? [
            formatFulfillmentMethodType(f.fulfillmentMethodType),
            f.fulfillmentMethodNotes,
          ]
            .filter(Boolean)
            .join(" - ")
        : "",
    })
  );

  return (
    <div>
      <TableHeader className="p-3">
        <h2 className="text-md font-semibold">Completed Fulfillments</h2>
      </TableHeader>

      {!getCompletedFulfillments.loading && fulfillments.length === 0 ? (
        <div className="border border-t-0 rounded-b p-4">
          <NonIdealState title="No Completed Fulfillments" />
        </div>
      ) : (
        <Grid
          data={process(fulfillments, dataState)}
          {...dataState}
          onDataStateChange={(e) => setDataState(e.dataState)}
          sortable
          reorderable
          resizable
        >
          <GridColumn
            columnMenu={ReportColumnMenu}
            field="id"
            title="Fulfillment #"
            width={130}
            cell={(props) => (
              <td>
                <EntityLink
                  url={`/projects/${props.dataItem.project.id}/fulfillments/${props.dataItem.id}`}
                  text={`F-${props.dataItem.id}`}
                />
              </td>
            )}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="date"
            cell={(props) => (
              <td>{formatDateTime(props.dataItem[props.field!])}</td>
            )}
            field="requestedAt"
            title="Requested Date"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="date"
            cell={(props) => (
              <td>{formatDateTime(props.dataItem[props.field!])}</td>
            )}
            field="installStartsAt"
            title="Install Start"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="date"
            cell={(props) => (
              <td>{formatDateTime(props.dataItem[props.field!])}</td>
            )}
            field="installEndsAt"
            title="Install End"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="date"
            cell={(props) => (
              <td>{formatDateTime(props.dataItem[props.field!])}</td>
            )}
            field="deliveryStartsAt"
            title="Delivery Start"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="date"
            field="deliveryEndsAt"
            title="Delivery Complete"
            width={200}
            cell={(props) => (
              <td>{formatDateTime(props.dataItem[props.field!])}</td>
            )}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            field="team"
            title="Team"
            width={200}
          />

          <GridColumn
            filter="numeric"
            columnMenu={ReportColumnMenu}
            field="estimatedHours"
            title="Estimated Hours"
            width={150}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            field="primaryContactName"
            title="Client"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            field="salesRepName"
            title="Sales Rep"
            width={200}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            filter="boolean"
            field="team"
            title="In Full"
            width={100}
          />

          <GridColumn
            columnMenu={ReportColumnMenu}
            field="fulfillmentMethod"
            title="Fulfillment Method"
            width={200}
          />

          <GridColumn
            filterable={false}
            sortable={false}
            groupable={false}
            title="Prepped"
            cell={(props) => (
              <td>{getPreppedStatus(props.dataItem.lineItems)}</td>
            )}
            width={150}
          />
        </Grid>
      )}
    </div>
  );
}
