import { useParams } from "react-router-dom";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { ProjectSubnavbar } from "../../../../components/projects/[projectId]/ProjectSubnavbar";
import { QuickSettings } from "../../../../components/shared/QuickSettings/QuickSettings";
import { QuickSettingItem } from "../../../../components/shared/QuickSettings/QuickSettingItem";
import {
  Button,
  FormGroup,
  Icon,
  Intent,
  Position,
  Tooltip,
} from "@blueprintjs/core";
import { LineItemEditor } from "../../../../components/shared/LineItemEditor/LineItemEditor";
import React, { Reducer, useReducer } from "react";
import {
  SalesOrderEditorActions,
  SalesOrderEditorState,
} from "../../../../components/shared/LineItemEditor/reducers/SalesOrderEditor/SalesOrderEditorTypes";
import * as actions from "../../../../components/shared/LineItemEditor/reducers/SalesOrderEditor/SalesOrderEditorActions";
import * as baseActions from "../../../../components/shared/LineItemEditor/reducers/LineItemEditor/LineItemEditorActions";
import { UnsavedChangesWarning } from "../../../../components/shared/LineItemEditor/UnsavedChangesWarning";
import { formatPrice } from "../../../../helpers/PriceHelper";
import classNames from "classnames";
import { ContactInputGroup } from "../../../../components/shared/ContactInputGroup";
import { DateInput } from "@blueprintjs/datetime";
import { formatDate } from "../../../../helpers/DateHelper";
import { AccountInputGroup } from "../../../../components/shared/AccountInputGroup";

export function InvoiceDetails() {
  const { invoiceId } = useParams<{ invoiceId: string }>();

  const [state, dispatch] = useReducer<
    Reducer<SalesOrderEditorState, SalesOrderEditorActions>,
    SalesOrderEditorState
  >(
    (state, action) =>
      (actions[action.type] ?? baseActions[action.type])(state, action),
    {
      quickSettings: { isModified: false },
      salesOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    },
    () => ({
      quickSettings: { isModified: false },
      salesOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    })
  );

  const isModified =
    state.quickSettings.isModified ||
    state.lineItemGroups.some(
      (lig) =>
        lig.isModified ||
        lig.isCreated ||
        lig.isRemoved ||
        lig.lineItems.some(
          (li) => li.isModified || li.isCreated || li.isRemoved
        )
    );

  return (
    <>
      <ProjectSubnavbar
        title={`INVOICE-${invoiceId}`}
        style={{ top: "unset" }}
        leftActions={
          <>
            <div className="flex items-center space-x-2 bg-green-200 text-green-600 text-sm font-medium py-1 px-2 rounded">
              <Icon icon={"tick-circle"} />
              <span>Paid in Full</span>
            </div>
          </>
        }
        actions={
          <>
            <Button icon="print" minimal />
            <Button text="Record Payment" />
          </>
        }
      />

      <div className="bg-gray-50 p-2 flex space-x-2">
        <div className="w-3/4 bg-white shadow-sm border p-4 space-y-4">
          <div className="-m-2 mb-0">
            <QuickSettings>
              <FormGroup label="Bill To">
                <QuickSettingItem>
                  <ContactInputGroup onChange={() => {}} />
                </QuickSettingItem>
              </FormGroup>
              <FormGroup label="Salesperson">
                <QuickSettingItem>
                  <AccountInputGroup onChange={() => {}} />
                </QuickSettingItem>
              </FormGroup>
            </QuickSettings>
          </div>

          <LineItemEditor<SalesOrderEditorState, SalesOrderEditorActions>
            readOnly={false}
            state={state}
            dispatch={dispatch}
          />

          <div className="flex justify-end">
            <div className="space-y-1 w-80 rounded border py-2">
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Total</p>
                <p className="font-bold m-0">{formatPrice(50000)}</p>
              </div>
              <div className="flex items-center space-x-6 pr-2">
                <p className="w-40 m-0 text-right">Payments Applied</p>
                <p>{formatPrice(50000)}</p>
              </div>
              <div className="py-1">
                <hr />
              </div>
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Balance Due</p>
                <p className="font-bold m-0">{formatPrice(0)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-1/4 bg-white shadow-sm border p-4 space-y-4">
          <h1 className="font-medium m-0 text-sm border-b pb-2 text-indigo-600 flex items-center justify-between">
            <span>Customer Info</span>
            <Button small outlined text="Edit" />
          </h1>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Name</p>
              <p className="text-right m-0 text-sm">John Smith</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Phone</p>
              <p className="text-right m-0 text-sm">6187194992</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Email</p>
              <p className="text-right m-0 text-sm">john@1by3.co</p>
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Open Balance</p>
              <p className="text-right m-0 text-sm">{formatPrice(125000)}</p>
            </div>
          </div>

          <h1 className="font-medium m-0 text-sm border-b py-2 text-indigo-600">
            Invoice Details
          </h1>

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Invoice Date</p>
              <DateInput
                inputProps={{ small: true }}
                value={new Date()}
                formatDate={formatDate}
              />
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Due Date</p>
              <DateInput
                inputProps={{ small: true }}
                value={new Date()}
                formatDate={formatDate}
              />
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Paid in Full Date</p>
              <DateInput
                inputProps={{ small: true }}
                value={new Date()}
                formatDate={formatDate}
              />
            </div>
            <div className="flex items-center justify-between">
              <p className="m-0 text-sm font-medium">Rev. Recognition Date</p>
              <DateInput
                inputProps={{ small: true }}
                value={new Date()}
                formatDate={formatDate}
              />
            </div>
          </div>

          <h1 className="font-medium m-0 text-sm border-b py-2 text-indigo-600">
            Related Items
          </h1>

          <div className="space-y-2">
            <Button
              icon="document"
              text="Sales Order SO-0001"
              alignText="left"
              rightIcon="arrow-top-right"
              minimal
              fill
            />
            <Button
              icon="document"
              text="Sales Order SO-0002"
              alignText="left"
              rightIcon="arrow-top-right"
              minimal
              fill
            />
          </div>

          <h1 className="font-medium m-0 text-sm border-b py-2 text-indigo-600 flex items-center justify-between">
            <span>Payments</span>
            <Button small outlined text="Record Payment" />
          </h1>

          <div className="space-y-2">
            <Button
              icon="dollar"
              alignText="left"
              rightIcon="arrow-top-right"
              minimal
              fill
            >
              <div className="flex items-center justify-between">
                <span>Payment on 4/5/2023</span>
                <span className="text-gray-500">{formatPrice(50000)}</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
