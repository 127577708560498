import React, { useCallback, useEffect, useState } from "react";
import {
  ContactInputGroup,
  ContactInputGroupContact,
} from "../../../../components/shared/ContactInputGroup";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import {
  Contact,
  CreateProjectContactPayloadInput,
  Project,
  ContactRole,
  UpdateProjectPayloadInput,
} from "../../../../types/ApiTypes";
import { DefaultToaster } from "../../../../components/shared/DefaultToaster/DefaultToaster";
import { gql, useMutation } from "@apollo/client";

const UpdateProject = gql`
  mutation UpdateProject($input: UpdateProjectPayloadInput) {
    updateProject(payload: $input) {
      id
    }
  }
`;

const CreateContact = gql`
  mutation CreateContact($input: CreateProjectContactPayloadInput!) {
    createProjectContact(payload: $input) {
      id
    }
  }
`;

const DeleteContact = gql`
  mutation DeleteContact($id: Int!) {
    deleteProjectContact(payload: { contactId: $id }) {
      id
    }
  }
`;

type MainProjectContactsProps = {
  project: {
    id: number;
    primaryContact?: Contact;
    billingContact?: Contact;
    fulfillmentContact?: Contact;
  };
};

export function MainProjectContacts({ project }: MainProjectContactsProps) {
  const [primaryContact, setPrimaryContact] = useState<
    ContactInputGroupContact | undefined
  >(project.primaryContact);
  const [billingContact, setBillingContact] = useState<
    ContactInputGroupContact | undefined
  >(project.billingContact);
  const [fulfillmentContact, setFulfillmentContact] = useState<
    ContactInputGroupContact | undefined
  >(project.fulfillmentContact);

  const [updateProject] = useMutation<
    { updateProject: Project },
    { input: UpdateProjectPayloadInput }
  >(UpdateProject, {
    refetchQueries: ["GetProjectById"],
  });

  const [createContact] = useMutation<
    { createProjectContact: Contact },
    { input: CreateProjectContactPayloadInput }
  >(CreateContact);

  const [deleteContact] = useMutation<
    { deleteProjectContact: Contact },
    { id: number }
  >(DeleteContact);

  const saveMainContactChanges = useCallback(async () => {
    let primaryContactId: number | undefined;
    let billingContactId: number | undefined;
    let fulfillmentContactId: number | undefined;

    if (
      project.primaryContact?.person?.id !== primaryContact?.person?.id ||
      project.primaryContact?.company?.id !== primaryContact?.company?.id
    ) {
      if (project.primaryContact) {
        await deleteContact({
          variables: {
            id: project.primaryContact.id,
          },
        });
      }

      if (primaryContact) {
        const { data } = await createContact({
          variables: {
            input: {
              projectId: project.id,
              personId: primaryContact!.person!.id,
              companyId: primaryContact!.company?.id,
              role: ContactRole.Primary,
            },
          },
        });

        primaryContactId = data!.createProjectContact.id;
      } else {
        primaryContactId = -1;
      }
    }

    if (
      project.billingContact?.person?.id !== billingContact?.person?.id ||
      project.billingContact?.company?.id !== billingContact?.company?.id
    ) {
      if (project.billingContact) {
        await deleteContact({
          variables: {
            id: project.billingContact.id,
          },
        });
      }

      if (billingContact) {
        const { data } = await createContact({
          variables: {
            input: {
              projectId: project.id,
              personId: billingContact!.person!.id,
              companyId: billingContact!.company?.id,
              role: ContactRole.Billing,
            },
          },
        });

        billingContactId = data!.createProjectContact.id;
      } else {
        billingContactId = -1;
      }
    }

    if (
      project.fulfillmentContact?.person?.id !==
        fulfillmentContact?.person?.id ||
      project.fulfillmentContact?.company?.id !==
        fulfillmentContact?.company?.id
    ) {
      if (project.fulfillmentContact) {
        await deleteContact({
          variables: {
            id: project.fulfillmentContact.id,
          },
        });
      }

      if (fulfillmentContact) {
        const { data } = await createContact({
          variables: {
            input: {
              projectId: project.id,
              personId: fulfillmentContact!.person!.id,
              companyId: fulfillmentContact!.company?.id,
              role: ContactRole.Fulfillment,
            },
          },
        });

        fulfillmentContactId = data!.createProjectContact.id;
      } else {
        fulfillmentContactId = -1;
      }
    }

    if (primaryContactId || billingContactId || fulfillmentContactId) {
      await updateProject({
        variables: {
          input: {
            projectId: project.id,
            primaryContactId,
            billingContactId,
            fulfillmentContactId,
          },
        },
      });
    }

    DefaultToaster.show({
      message: "Project contacts saved!",
      intent: Intent.SUCCESS,
      icon: "tick-circle",
    });
  }, [project, primaryContact, billingContact, fulfillmentContact]);

  return (
    <div className="p-10 overflow-y-auto">
      <div className="md:w-1/2">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Main Contacts
              </h3>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <FormGroup label="Primary Contact">
                  <ContactInputGroup
                    value={primaryContact}
                    onChange={setPrimaryContact}
                  />
                </FormGroup>
                <FormGroup label="Billing Contact">
                  <ContactInputGroup
                    value={billingContact}
                    onChange={setBillingContact}
                  />
                </FormGroup>
                <FormGroup label="Fulfillment Contact">
                  <ContactInputGroup
                    value={fulfillmentContact}
                    onChange={setFulfillmentContact}
                  />
                </FormGroup>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <Button
                  intent={Intent.PRIMARY}
                  large
                  text="Save"
                  onClick={saveMainContactChanges}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
