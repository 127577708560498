import { ProjectSubnavbar } from "../../../components/projects/[projectId]/ProjectSubnavbar";
import React, { useCallback, useEffect, useState } from "react";
import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import {
  Button,
  InputGroup,
  Intent,
  NumericInput,
  Spinner,
  TextArea,
} from "@blueprintjs/core";
import { useVendors } from "./useVendors";
import { BasicTable } from "../../../components/shared/Table/BasicTable";
import styles from "./Vendors.module.scss";
import classNames from "classnames";
import {
  createEditableVendor,
  EditableVendor,
  useVendorListReducer,
} from "./useVendorListReducer";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import { Prompt } from "react-router-dom";

export function Vendors() {
  const [vendorQuery, setVendorQuery] = useState("");
  const { vendors, loading, updateVendor } = useVendors();
  const [state, dispatch] = useVendorListReducer();

  useEffect(() => {
    if (loading || !vendors) return;
    dispatch({
      type: "initialize",
      vendors: vendors.map(createEditableVendor),
    });
  }, [vendors, loading]);

  useEffect(() => {
    if (state.isModified) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [state.isModified]);

  const saveVendorChanges = useCallback(async () => {
    const updateVendorPromises = state.vendors
      .filter((vendor) => vendor.isModified)
      .map((vendor) =>
        updateVendor({
          variables: {
            input: {
              id: vendor.id,
              name: vendor.name,
              pricingZone: vendor.pricingZone,
              listPriceMultiplier: vendor.listPriceMultiplier,
              grossProfitPercent: vendor.grossProfitPercent,
              freightRules: vendor.freightRules,
              notes: vendor.notes,
            },
          },
        })
      );

    await Promise.all(updateVendorPromises);

    DefaultToaster.show({
      message: `${updateVendorPromises.length} vendor(s) updated!`,
      icon: "tick-circle",
      intent: "success",
    });
  }, [state, updateVendor]);

  const filterVendor = useCallback(
    (vendor: EditableVendor) => {
      const normalizedQuery = vendorQuery.toLowerCase().trim();
      if (normalizedQuery.length === 0) return true;

      const normalizedVendorName = vendor.name.toLowerCase().trim();
      return normalizedVendorName.includes(normalizedQuery);
    },
    [vendorQuery]
  );

  return (
    <>
      <Prompt
        when={state.isModified}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <ProjectSubnavbar
        title="Vendor Management"
        actions={
          <div className="flex items-center space-x-2">
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search vendors..."
              value={vendorQuery}
              onChange={(e) => setVendorQuery(e.target.value)}
            />
            <Button
              intent={Intent.PRIMARY}
              text="Save Changes"
              disabled={!state.isModified}
              onClick={saveVendorChanges}
            />
          </div>
        }
        style={{ top: 0 }}
      />
      <PortalContainer>
        {loading && (
          <div className="flex items-center justify-center p-10">
            <Spinner />
          </div>
        )}
        {!loading && vendors && (
          <BasicTable>
            <thead>
              <tr>
                <th style={{ width: 300 }}>Vendor Name</th>
                <th style={{ width: 200 }}>Pricing Zone</th>
                <th style={{ width: 200 }}>Discount List Price Multiplier</th>
                <th style={{ width: 200 }}>Gross Profit %</th>
                <th>Freight Rules</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {state.vendors.filter(filterVendor).map((vendor, i) => (
                <tr>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.name,
                    })}
                  >
                    <InputGroup
                      value={vendor.name}
                      onChange={(e) =>
                        dispatch({
                          type: "setName",
                          vendorIndex: i,
                          name: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.pricingZone,
                    })}
                  >
                    <InputGroup
                      value={vendor.pricingZone}
                      onChange={(e) =>
                        dispatch({
                          type: "setPricingZone",
                          vendorIndex: i,
                          pricingZone: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.listPriceMultiplier,
                    })}
                  >
                    <NumericInput
                      defaultValue={vendor.listPriceMultiplier}
                      onValueChange={(e) =>
                        dispatch({
                          type: "setListPriceMultiplier",
                          vendorIndex: i,
                          listPriceMultiplier: e,
                        })
                      }
                      majorStepSize={1}
                      stepSize={0.1}
                      minorStepSize={0.01}
                      fill
                    />
                  </td>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.grossProfitPercent,
                    })}
                  >
                    <NumericInput
                      defaultValue={vendor.grossProfitPercent}
                      onValueChange={(e) =>
                        dispatch({
                          type: "setGrossProfitPercent",
                          vendorIndex: i,
                          grossProfitPercent: e,
                        })
                      }
                      stepSize={1}
                      minorStepSize={0.1}
                      fill
                    />
                  </td>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.freightRules,
                    })}
                  >
                    <TextArea
                      className={styles.expandableTextArea}
                      value={vendor.freightRules.replaceAll("\\n", "\n")}
                      onChange={(e) =>
                        dispatch({
                          type: "setFreightRules",
                          vendorIndex: i,
                          freightRules: e.target.value,
                        })
                      }
                      onBlur={(e) => e.target.scrollTo(0, 0)}
                      fill
                    />
                  </td>
                  <td
                    className={classNames({
                      "bg-yellow-50": vendor.modifiedFields.notes,
                    })}
                  >
                    <TextArea
                      className={styles.expandableTextArea}
                      value={vendor.notes.replaceAll("\\n", "\n")}
                      onChange={(e) =>
                        dispatch({
                          type: "setNotes",
                          vendorIndex: i,
                          notes: e.target.value,
                        })
                      }
                      onBlur={(e) => e.target.scrollTo(0, 0)}
                      fill
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </BasicTable>
        )}
      </PortalContainer>
    </>
  );
}
