import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { PortalContainer } from "../../../../components/shared/PortalContainer/PortalContainer";
import { Callout } from "@blueprintjs/core";
import { useRouteMatch } from "react-router-dom";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import { PurchaseOrdersTable } from "../../../../components/projects/[projectId]/purchase-orders/PurchaseOrdersTable";
import { Project, PurchaseOrder } from "../../../../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";
import { RecordPurchaseOrderShipmentDrawer } from "../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/RecordPurchaseOrderShipmentDrawer";

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

const GET_PROJECT_PURCHASE_ORDERS = gql`
  query GetProjectPurchaseOrders($projectId: Int!) {
    purchaseOrders(where: { projectId: { eq: $projectId } }) {
      id
      code
      projectId
      project {
        id
        name
      }
      manufacturerId
      manufacturer {
        id
        name
      }
      companyId
      company {
        id
        name
      }
      personId
      person {
        id
        firstName
        lastName
      }
      mostRecentSubmission {
        id
        resultReceivedAt
        result
        createdAt
        submissionMethod
        submissionDestination
        submissionAttachment {
          id
          name
        }
        confirmationAttachment {
          id
          name
        }
        notes
      }
      createdAt
      lineItems {
        id
        description
        listPriceMultiplier
        purchasePrice
        quantity
        sortIndex
        configurationId
        configuration {
          id
          catalogProductId
          catalogProduct {
            id
            code
            description
            catalog {
              id
              name
            }
            sellPriceMultiplier {
              id
              sellPriceMultiplier
              discount
              defaultGrossProfit
            }
            optionGroups {
              id
              code
              description
            }
            defaultPrice {
              id
              amount
            }
            vendor {
              id
              freightRules
            }
          }
          features {
            catalogOptionGroupId
            selections {
              id
              catalogOptionId
              catalogOption {
                id
                description
                code
              }
              catalogOptionGroupId
              catalogOptionGroup {
                id
                description
                code
              }
            }
          }
        }
      }
    }
  }
`;

export default function PurchaseOrders() {
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const [
    purchaseOrderToCreateShipmentFor,
    setPurchaseOrderToCreateShipmentFor,
  ] = useState<number>();

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const getProjectPurchaseOrders = useQuery<
    { purchaseOrders: PurchaseOrder[] },
    { projectId: number }
  >(GET_PROJECT_PURCHASE_ORDERS, {
    variables: {
      projectId,
    },
  });

  const purchaseOrders = getProjectPurchaseOrders.data?.purchaseOrders;

  return (
    <>
      <Helmet>
        <title>{`Purchase Orders - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Purchase Orders",
            href: `/projects/${projectId}/purchase-orders`,
          },
        ]}
      />

      <PortalContainer>
        {!getProjectPurchaseOrders.loading && purchaseOrders!.length === 0 && (
          <Callout title="No Purchase Orders">
            This project does not have any purchase orders.
          </Callout>
        )}

        {!getProjectPurchaseOrders.loading && purchaseOrders!.length > 0 && (
          <PurchaseOrdersTable
            purchaseOrders={purchaseOrders!}
            onRefreshPurchaseOrders={() => getProjectPurchaseOrders.refetch()}
          />
        )}
      </PortalContainer>
    </>
  );
}
