import React, { useEffect, useState } from "react";
import { Button, FormGroup, HTMLSelect, MenuItem } from "@blueprintjs/core";
import {
  Bay,
  InventoryItemInspectionStatus,
  Person,
  ProductConfiguration,
  PurchaseOrderInventoryItem,
  PurchaseOrderShipmentLineItem,
} from "../../../../../types/ApiTypes";
import { FlexTable } from "../../../../shared/Table/FlexTable";
import {
  getInspectionStatusIntent,
  getInspectionStatusText,
} from "../../../../../helpers/InventoryItemHelpers";
import { IItemRendererProps, Select } from "@blueprintjs/select";
import axios from "axios";
import {
  EntityCodeType,
  getEntityCode,
} from "../../../../../helpers/EntityCodeHelper";
import {
  getFullConfigurationCode,
  getFullConfigurationDescription,
} from "../../../../../helpers/CatalogHelper";

export type PurchaseOrderIventoryItemBaySelector = {
  inventoryItem: PurchaseOrderInventoryItem;
  refreshInventoryItems: () => void;
  bays: Bay[];
};

const BaySelect = Select.ofType<Bay>();

function PurchaseOrderInventoryItemBaySelector({
  inventoryItem,
  bays,
  refreshInventoryItems,
}: PurchaseOrderIventoryItemBaySelector) {
  const [bay, setBay] = useState<string>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inventoryItem.location != null) {
      setBay(inventoryItem!.location!.id!.toString());
    }
  }, [inventoryItem.location]);

  const filterBay = (query: string, item: Bay) => {
    const normalizedQuery = query.toUpperCase().trim();
    if (normalizedQuery.length === 0) return true;

    const normalizedName = `${item.name}`.toUpperCase().trim();
    return normalizedName.includes(normalizedQuery);
  };

  const renderBay = (item: Bay, props: IItemRendererProps) => {
    return (
      <MenuItem
        text={`${item.name}`}
        active={props.modifiers.active}
        disabled={props.modifiers.disabled}
        onClick={props.handleClick}
      />
    );
  };

  const onSelectBay = async (bay: Bay) => {
    setLoading(true);

    try {
      var result = await axios.post(
        `/inventoryitems/${inventoryItem.id}/stocking`,
        {
          bay: bay.id,
        }
      );

      refreshInventoryItems();
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaySelect
      items={bays}
      itemPredicate={filterBay}
      itemRenderer={renderBay}
      onItemSelect={(bay: Bay) => onSelectBay(bay)}
      disabled={loading}
      inputProps={{ fill: true }}
      popoverProps={{ targetClassName: "w-full" }}
    >
      <Button
        text={inventoryItem!.location?.name || "Unstocked"}
        icon="office"
        disabled={loading}
        fill
      />
    </BaySelect>
  );
}

export type PurchaseOrderInventoryListItem = {
  id: number;
  configuration: ProductConfiguration;
  mostRecentInspectionStatus: InventoryItemInspectionStatus;
  inventoryItemAttachments: {
    id: number;
  }[];
  location?: {
    id: number;
    name: string;
  };
  purchaseOrderShipmentLineItem: {
    id: number;
    purchaseOrderLineItem: {
      id: number;
      quantity: number;
      configuration: ProductConfiguration;
      sortIndex: number;
    };
  };
};

export type PurchaseOrderInventoryListProps = {
  inventoryItems: PurchaseOrderInventoryListItem[];
  refreshInventoryItems: () => void;
  onPrintLabels: (inventoryItem: PurchaseOrderInventoryListItem) => void;
  onInspect: (inventoryItem: PurchaseOrderInventoryListItem) => void;
  onManageAttachments: (inventoryItem: PurchaseOrderInventoryListItem) => void;
  bays: Bay[];
};

export function PurchaseOrderInventoryItemsList({
  inventoryItems,
  refreshInventoryItems,
  onPrintLabels,
  onInspect,
  onManageAttachments,
  bays,
}: PurchaseOrderInventoryListProps) {
  const columns = [
    {
      Header: "Inventory #",
      Cell: ({ row }) => (
        <strong>
          {getEntityCode(row.original.id, EntityCodeType.InventoryItem)}
        </strong>
      ),
      maxWidth: 15,
    },
    {
      Header: "Line",
      accessor: (i: PurchaseOrderInventoryListItem) =>
        i.purchaseOrderShipmentLineItem.purchaseOrderLineItem.sortIndex + 1,
      maxWidth: 5,
    },
    {
      Header: "Code",
      Cell: ({ row }) => {
        const original = row.original as PurchaseOrderInventoryListItem;

        return (
          <p
            className="m-0 font-bold"
            dangerouslySetInnerHTML={{
              __html: getFullConfigurationCode(
                original.purchaseOrderShipmentLineItem.purchaseOrderLineItem
                  .configuration
              ).replaceAll("\n", "<br/>"),
            }}
          />
        );
      },
      maxWidth: 25,
    },
    {
      Header: "Description",
      Cell: ({ row }) => {
        const original = row.original as PurchaseOrderInventoryListItem;

        return (
          <p
            className="m-0"
            dangerouslySetInnerHTML={{
              __html: getFullConfigurationDescription(
                original.purchaseOrderShipmentLineItem.purchaseOrderLineItem
                  .configuration
              ).replaceAll("\n", "<br/>"),
            }}
          />
        );
      },
    },
    {
      Header: "Attachments",
      id: "attachments",
      Cell: ({ row }) => (
        <Button
          text="Manage Attachments"
          icon="paperclip"
          onClick={() => onManageAttachments(row.original)}
          fill
        />
      ),
      maxWidth: 25,
      className: "with-button",
    },
    {
      Header: "Status",
      id: "status",
      Cell: ({ row }) => (
        <Button
          text={getInspectionStatusText(row.original)}
          intent={getInspectionStatusIntent(row.original)}
          icon="clipboard"
          onClick={() => onInspect(row.original)}
          fill
        />
      ),
      maxWidth: 25,
      className: "with-button",
    },
    {
      Header: "Location",
      id: "location",
      Cell: ({ row }) => (
        <PurchaseOrderInventoryItemBaySelector
          inventoryItem={row.original}
          refreshInventoryItems={refreshInventoryItems}
          bays={bays}
        />
      ),
      maxWidth: 25,
      className: "with-button",
    },
    {
      id: "actions",
      Cell: ({ row }) => (
        <Button
          text="View/Print Labels"
          icon="print"
          onClick={() => onPrintLabels(row.original)}
          fill
        />
      ),
      maxWidth: 25,
      className: "with-button",
    },
  ];

  return (
    <>
      <FlexTable columns={columns} data={inventoryItems} />
    </>
  );
}
