import React, { useEffect, useState } from "react";
import { PurchaseOrderStatusCell } from "./PurchaseOrderStatusCell";
import {
  PurchaseOrder,
  PurchaseOrderShipment,
  PurchaseOrderSubmission,
  PurchaseOrderSubmissionResult,
} from "../../../../types/ApiTypes";
import {
  getSubmissionStatusIntent,
  getSubmissionStatusText,
} from "../../../../helpers/PurchaseOrderHelpers";
import { Button, Intent } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { formatDate } from "../../../../helpers/DateHelper";
import {
  AnnotatedPurchaseOrderShipment,
  getAnnotatedShipments,
  PurchaseOrderShipmentTimeline,
} from "./PurchaseOrderShipmentTimeline";
import { RecordPurchaseOrderShipmentDrawer } from "./[purchaseOrderId]/RecordPurchaseOrderShipmentDrawer";

const GET_PURCHASE_ORDER_SHIPMENTS = gql`
  query GetPurchaseOrderInventoryItem($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      purchaseOrderShipments(where: { cancelledAt: { eq: null } }) {
        id
        expectedShipDate
        actualShipDate
        expectedArrivalDate
        actualArrivalDate
      }
    }
  }
`;

export type PurchaseOrdersListSubmissionStatusCellProps = {
  purchaseOrder: PurchaseOrder;
};

export function PurchaseOrdersListShippingStatusCell({
  purchaseOrder,
}: PurchaseOrdersListSubmissionStatusCellProps) {
  const [isRecordShipmentOpen, setIsRecordShipmentOpen] = useState(false);
  const [oneWasElapsed, setOneWasElapsed] = useState<boolean>(false);
  const [annotatedShipments, setAnnotatedShipments] = useState<
    AnnotatedPurchaseOrderShipment[]
  >([]);

  const { data, loading, error } = useQuery<{
    purchaseOrders: PurchaseOrder[];
  }>(GET_PURCHASE_ORDER_SHIPMENTS, {
    variables: {
      purchaseOrderId: purchaseOrder.id,
    },
    fetchPolicy: "network-only",
  });

  const history = useHistory();

  const shipments = data?.purchaseOrders[0]?.purchaseOrderShipments;
  const hasShipments = shipments != undefined && shipments.length > 0;

  useEffect(() => {
    if (!data) return;

    var results = getAnnotatedShipments(shipments!);

    setAnnotatedShipments(results.results);
    setOneWasElapsed(results.oneWasElapsed);
  }, [data]);

  const text = hasShipments ? `${shipments?.length} Shipments` : `No Shipments`;

  const intent = !hasShipments
    ? Intent.NONE
    : oneWasElapsed
    ? Intent.DANGER
    : Intent.SUCCESS;

  return (
    <>
      <RecordPurchaseOrderShipmentDrawer
        isOpen={isRecordShipmentOpen}
        purchaseOrderId={purchaseOrder.id}
        onSubmit={() => {
          setIsRecordShipmentOpen(false);
        }}
        onClose={() => {
          setIsRecordShipmentOpen(false);
        }}
      />

      <PurchaseOrderStatusCell intent={intent} loading={loading} text={text}>
        <div className="p-2 space-y-2">
          <div>
            <h3 className="font-bold mb-2">Shipments</h3>
            {shipments && (
              <PurchaseOrderShipmentTimeline shipments={annotatedShipments} />
            )}
          </div>

          <Button
            fill
            rightIcon="chevron-right"
            intent="primary"
            text={"Record Shipment"}
            onClick={() => setIsRecordShipmentOpen(true)}
          />

          <Button
            fill
            rightIcon="chevron-right"
            text={"Manage Shipments"}
            onClick={() => {
              history.push(
                `/projects/${purchaseOrder.projectId}/purchase-orders/${purchaseOrder.id}/shipments`
              );
            }}
          />
        </div>
      </PurchaseOrderStatusCell>
    </>
  );
}
