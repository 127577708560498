import { ProjectSubnavbar } from "../../../components/projects/[projectId]/ProjectSubnavbar";
import { NavLink } from "react-router-dom";
import React, { useCallback, useState } from "react";
import { UpdateVendorPayloadInput, Vendor } from "../../../types/ApiTypes";
import { PortalContainer } from "../../../components/shared/PortalContainer/PortalContainer";
import { InputGroup, Spinner } from "@blueprintjs/core";
import { DefaultToaster } from "../../../components/shared/DefaultToaster/DefaultToaster";
import { useVendors } from "./useVendors";
import { VendorPricingItem } from "../../../components/admin/pricing/VendorPricingItem";

export function VendorPricing() {
  const [vendorQuery, setVendorQuery] = useState("");
  const { vendors, loading, updateVendor, updateVendorLoading } = useVendors();

  const saveVendorChanges = useCallback(
    async (payload: UpdateVendorPayloadInput) => {
      await updateVendor({
        variables: {
          input: payload,
        },
      });
      DefaultToaster.show({
        message: "Vendor details updated",
        intent: "success",
        icon: "tick-circle",
      });
    },
    [updateVendor]
  );

  const filterVendor = useCallback(
    (vendor: Vendor) => {
      const normalizedQuery = vendorQuery.toLowerCase().trim();
      if (normalizedQuery.length === 0) return true;

      const normalizedVendorName = vendor.name.toLowerCase().trim();
      return normalizedVendorName.includes(normalizedQuery);
    },
    [vendorQuery]
  );

  return (
    <>
      <ProjectSubnavbar
        title="Vendor Pricing"
        tabs={
          <ul>
            <li>
              <NavLink to="/admin/pricing/vendors" exact>
                Vendors
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/pricing/products" exact>
                Products
              </NavLink>
            </li>
          </ul>
        }
        actions={
          <>
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search vendors..."
              value={vendorQuery}
              onChange={(e) => setVendorQuery(e.target.value)}
            />
          </>
        }
      />
      <PortalContainer>
        {loading && (
          <div className="flex items-center justify-center p-10">
            <Spinner />
          </div>
        )}
        {!loading && vendors && (
          <div className="grid gap-4 grid-cols-3 ">
            {vendors.filter(filterVendor).map((vendor) => (
              <VendorPricingItem
                key={vendor.id}
                vendor={vendor}
                onChange={saveVendorChanges}
                loading={updateVendorLoading}
              />
            ))}
          </div>
        )}
      </PortalContainer>
    </>
  );
}
