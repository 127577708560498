import { Tag } from "@blueprintjs/core";

type InventoryItem = {
  prepTicketLineItem?: {
    id: number;
    isPrepped: boolean;
  };
};

export function getPreppedStatus(
  fulfillmentLineItems: {
    id: number;
    salesOrderLineItemId?: number;
    salesOrderLineItem?: {
      inventoryItems?: InventoryItem[];
    };
  }[]
) {
  console.log(fulfillmentLineItems);

  var inventoryItems = new Array<InventoryItem>();
  for(var i = fulfillmentLineItems.length - 1; i >= 0; i--) {
    var lineItem = fulfillmentLineItems[i];
    if (lineItem.salesOrderLineItem != undefined && lineItem.salesOrderLineItem.inventoryItems != undefined) {
      inventoryItems = inventoryItems.concat(lineItem.salesOrderLineItem.inventoryItems);
    }
  }
  const preppedInventoryItems = inventoryItems.filter((lineItem) => lineItem?.prepTicketLineItem?.isPrepped);

  const preppedCount = preppedInventoryItems.length;

  const totalCount = inventoryItems.length;

  return preppedCount === totalCount ? (
    <Tag minimal icon="tick" intent="success">
      {preppedCount} / {totalCount} Prepped
    </Tag>
  ) : (
    <Tag minimal icon="cross" intent="warning">
      {preppedCount} / {totalCount} Prepped
    </Tag>
  );
}
