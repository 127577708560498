import { Button, Menu, MenuDivider, Popover } from "@blueprintjs/core";
import { VisibleColumnMenuItem } from "../../../../../components/shared/LineItemEditor/VisibleColumnMenuItem";
import React from "react";
import { LineItemEditorDefaultColumn } from "../../../../../components/shared/LineItemEditor/LineItemEditor";

export type SalesOrderEditorColumn = LineItemEditorDefaultColumn;

type SalesOrderVisibleColumnMenuProps = {
  visibleColumns: SalesOrderEditorColumn[];
  onShowColumn: (column: SalesOrderEditorColumn) => void;
  onHideColumn: (column: SalesOrderEditorColumn) => void;
};

export function SalesOrderVisibleColumnMenu({
  visibleColumns,
  onHideColumn,
  onShowColumn,
}: SalesOrderVisibleColumnMenuProps) {
  return (
    <Popover position="bottom" minimal>
      <Button icon="column-layout" minimal />
      <Menu>
        <MenuDivider title="Visible Columns" />
        <VisibleColumnMenuItem
          text="Product"
          isVisible={visibleColumns.includes("product")}
          onClick={() =>
            visibleColumns.includes("product")
              ? onHideColumn("product")
              : onShowColumn("product")
          }
        />
        <VisibleColumnMenuItem
          text="Description"
          isVisible={visibleColumns.includes("description")}
          onClick={() =>
            visibleColumns.includes("description")
              ? onHideColumn("description")
              : onShowColumn("description")
          }
        />
        <VisibleColumnMenuItem
          text="Quantity"
          isVisible={visibleColumns.includes("quantity")}
          onClick={() =>
            visibleColumns.includes("quantity")
              ? onHideColumn("quantity")
              : onShowColumn("quantity")
          }
        />
        <VisibleColumnMenuItem
          text="Vendor List Price"
          isVisible={visibleColumns.includes("vendorListPrice")}
          onClick={() =>
            visibleColumns.includes("vendorListPrice")
              ? onHideColumn("vendorListPrice")
              : onShowColumn("vendorListPrice")
          }
        />
        <VisibleColumnMenuItem
          text="Discount List Price Multiplier"
          isVisible={visibleColumns.includes("discountListPriceMultiplier")}
          onClick={() =>
            visibleColumns.includes("discountListPriceMultiplier")
              ? onHideColumn("discountListPriceMultiplier")
              : onShowColumn("discountListPriceMultiplier")
          }
        />
        <VisibleColumnMenuItem
          text="Purchase Price"
          isVisible={visibleColumns.includes("purchasePrice")}
          onClick={() =>
            visibleColumns.includes("purchasePrice")
              ? onHideColumn("purchasePrice")
              : onShowColumn("purchasePrice")
          }
        />
        <VisibleColumnMenuItem
          text="Gross Profit %"
          isVisible={visibleColumns.includes("grossProfitPercent")}
          onClick={() =>
            visibleColumns.includes("grossProfitPercent")
              ? onHideColumn("grossProfitPercent")
              : onShowColumn("grossProfitPercent")
          }
        />
        <VisibleColumnMenuItem
          text="Suggested Sell Price"
          isVisible={visibleColumns.includes("suggestedSellPrice")}
          onClick={() =>
            visibleColumns.includes("suggestedSellPrice")
              ? onHideColumn("suggestedSellPrice")
              : onShowColumn("suggestedSellPrice")
          }
        />
        <VisibleColumnMenuItem
          text="Actual Sell Price"
          isVisible={visibleColumns.includes("actualSellPrice")}
          onClick={() =>
            visibleColumns.includes("actualSellPrice")
              ? onHideColumn("actualSellPrice")
              : onShowColumn("actualSellPrice")
          }
        />
        <VisibleColumnMenuItem
          text="Tax"
          isVisible={visibleColumns.includes("tax")}
          onClick={() =>
            visibleColumns.includes("tax")
              ? onHideColumn("tax")
              : onShowColumn("tax")
          }
        />
        <VisibleColumnMenuItem
          text="Total Sell Price"
          isVisible={visibleColumns.includes("totalSellPrice")}
          onClick={() =>
            visibleColumns.includes("totalSellPrice")
              ? onHideColumn("totalSellPrice")
              : onShowColumn("totalSellPrice")
          }
        />
      </Menu>
    </Popover>
  );
}
