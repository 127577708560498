import {useState} from "react";
import {GridExpandChangeEvent} from "@progress/kendo-react-grid";

export function useExpandableRows() {
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  function onExpandChange(event: GridExpandChangeEvent) {
    const newExpandedRows = new Set(expandedRows);
    if (event.dataItem) {
      if (event.dataItem.expanded) {
        newExpandedRows.delete(event.dataItem.id);
      } else {
        newExpandedRows.add(event.dataItem.id);
      }
    }
    setExpandedRows(newExpandedRows);
  }

  return {
    expandedRows,
    isExpanded: (id) => expandedRows.has(id),
    onExpandChange,
  };
}
