import { NonIdealState, Spinner } from "@blueprintjs/core";
import { AddCompanyButton } from "./AddCompanyButton";
import { PersonContactItem } from "./PersonContactItem";
import {
  ContactRole,
  UpdateCompanyContactPayload,
} from "../../../types/ApiTypes";
import { gql, useMutation, useQuery } from "@apollo/client";

export type PersonContactListProps = {
  personId: number;
};

type GetCompanyContactsResult = {
  people: {
    id: number;
    contacts: {
      id: number;
      company: {
        id: number;
        name: string;
      };
      role: ContactRole;
    }[];
  }[];
};

const GetCompanyContacts = gql`
  query GetCompanyContacts($personId: Int!) {
    people(where: { id: { eq: $personId } }) {
      id
      contacts {
        id
        company {
          id
          name
        }
        role
      }
    }
  }
`;

const AddPersonToCompany = gql`
  mutation AddPersonToCompany(
    $personId: Int!
    $companyId: Int!
    $role: ContactRole!
  ) {
    addPersonToCompany(
      personId: $personId
      companyId: $companyId
      role: $role
    ) {
      id
    }
  }
`;

const UpdateCompanyContact = gql`
  mutation UpdateCompanyContact($payload: UpdateCompanyContactPayloadInput!) {
    updateCompanyContact(payload: $payload) {
      id
    }
  }
`;

const RemovePersonFromCompany = gql`
  mutation RemovePersonFromCompany($companyContactId: Int!) {
    removePersonFromCompany(companyContactId: $companyContactId) {
      id
    }
  }
`;

export function PersonContactList({ personId }: PersonContactListProps) {
  const getCompanyContacts = useQuery<
    GetCompanyContactsResult,
    { personId: number }
  >(GetCompanyContacts, {
    variables: {
      personId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [addPersonToCompany] = useMutation<
    { addPersonToCompany: { id: number } },
    { companyId: number; personId: number; role: ContactRole }
  >(AddPersonToCompany, {
    refetchQueries: ["GetCompanyContacts"],
  });

  const [updateCompanyContact] = useMutation<
    { updateCompanyContact: { id: number } },
    { payload: UpdateCompanyContactPayload }
  >(UpdateCompanyContact, {
    refetchQueries: ["GetCompanyContacts"],
  });

  const [removePersonFromCompany] = useMutation<
    { removePersonFromCompany: { id: number } },
    { companyContactId: number }
  >(RemovePersonFromCompany, {
    refetchQueries: ["GetCompanyContacts"],
  });

  return (
    <div className="flex flex-col border rounded overflow-hidden">
      <div className="flex-1 max-h-60 overflow-y-auto">
        {getCompanyContacts.loading && (
          <div className="p-20 text-center">
            <Spinner size={20} />
          </div>
        )}
        {!getCompanyContacts.loading &&
          getCompanyContacts.data!.people[0].contacts.length === 0 && (
            <div className="p-5">
              <NonIdealState icon="user" title="No Companies" />
            </div>
          )}
        {!getCompanyContacts.loading &&
          getCompanyContacts.data!.people[0].contacts.length > 0 &&
          getCompanyContacts.data!.people[0].contacts.map((c) => (
            <PersonContactItem
              key={c.id}
              contact={c}
              onChange={async (v) => {
                await updateCompanyContact({
                  variables: {
                    payload: {
                      id: c.id!,
                      role: v.role,
                    },
                  },
                });
              }}
              onRemove={async () => {
                await removePersonFromCompany({
                  variables: {
                    companyContactId: c.id,
                  },
                });
              }}
            />
          ))}
      </div>
      <div className="flex border-t items-center justify-between p-1 bg-gray-50">
        <AddCompanyButton
          onAddCompany={async (company) => {
            await addPersonToCompany({
              variables: {
                personId,
                companyId: company.id,
                role: ContactRole.Other,
              },
            });
          }}
        />
      </div>
    </div>
  );
}
