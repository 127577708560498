import React from "react";
import classNames from "classnames";
import styles from "./Table.module.scss";
import { useTable, useFlexLayout } from "react-table";

export type FlexTableProps = {
  columns: any[];
  data: any[];
  header?: boolean;
  footer?: boolean;
};

export function FlexTable({ columns, data, header, footer }: FlexTableProps) {
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div
      className={classNames(
        styles.tableWrapper,
        { [styles.hasHeader]: header },
        { [styles.hasFooter]: footer }
      )}
    >
      <table className={classNames(styles.table)} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    className: (column as any).headerClassName,
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        className: (cell.column as any).className,
                      })}
                    >
                      {
                        // Render the cell contents
                        cell.render("Cell")
                      }
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
