import {
  Button,
  Callout,
  Classes,
  Dialog,
  DialogProps,
  Icon,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Quote, SalesOrder } from "../../../../types/ApiTypes";
import { useApiError } from "../../../../hooks/useApiError";
import { DefaultToaster } from "../../../shared/DefaultToaster/DefaultToaster";

const GetSalesOrderDetails = gql`
  query GetSalesOrderDetails($salesOrderId: Int!) {
    salesOrders(where: { id: { eq: $salesOrderId } }) {
      id
      quickbooksSalesOrderId
      code
    }
  }
`;

const ExportSalesOrder = gql`
  mutation ExportSalesOrder($salesOrderId: Int!) {
    exportSalesOrderToQuickbooksEnterprise(salesOrderId: $salesOrderId) {
      salesOrderId
    }
  }
`;

type ExportSalesOrderToQuickbooksDialogProps = DialogProps & {
  salesOrderId: number;
};

export function ExportSalesOrderToQuickbooksDialog({
  salesOrderId,
  ...dialogProps
}: ExportSalesOrderToQuickbooksDialogProps) {
  const handleExportError = useApiError("export sales order");

  const getSalesOrderDetails = useQuery<
    { salesOrders: SalesOrder[] },
    { salesOrderId: number }
  >(GetSalesOrderDetails, {
    variables: {
      salesOrderId,
    },
  });

  const [exportSalesOrder, { loading: exportingSalesOrder }] = useMutation<
    { id: number },
    { salesOrderId: number }
  >(ExportSalesOrder, {
    onError: handleExportError,
    refetchQueries: [
      {
        query: GetSalesOrderDetails,
        variables: { salesOrderId },
      },
    ],
  });

  async function exportSalesOrderToQuickbooks() {
    await exportSalesOrder({ variables: { salesOrderId } });
    DefaultToaster.show({
      icon: "tick-circle",
      intent: "success",
      message: "Sales Order exported to Quickbooks successfully!",
    });
  }

  return (
    <Dialog title="Export Sales Order to Quickbooks" {...dialogProps}>
      {getSalesOrderDetails.loading && (
        <div className="h-40 flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!getSalesOrderDetails.loading && (
        <>
          <div className={Classes.DIALOG_BODY}>
            <h4 className="font-medium uppercase tracking-wide text-gray-500 mb-2">
              Export Status
            </h4>
            {getSalesOrderDetails.data?.salesOrders[0]
              .quickbooksSalesOrderId ? (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getSalesOrderDetails.data?.salesOrders[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-700">
                        Estimate #
                        {
                          getSalesOrderDetails.data?.salesOrders[0]
                            .quickbooksSalesOrderId
                        }
                      </p>
                    </div>
                    <Icon icon="tick-circle" className="text-green-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getSalesOrderDetails.data?.salesOrders[0].code} has been
                  exported as SO #
                  {
                    getSalesOrderDetails.data?.salesOrders[0]
                      .quickbooksSalesOrderId
                  }
                  . If you need to re-export this sales order, please contact
                  support.
                </p>
              </div>
            ) : (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getSalesOrderDetails.data?.salesOrders[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center opacity-50">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-500">Not Exported</p>
                    </div>
                    <Icon icon="help" className="text-gray-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getSalesOrderDetails.data?.salesOrders[0].code} has not been
                  exported. To export this sales order to Quickbooks, click the
                  button below.
                </p>
              </div>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!getSalesOrderDetails.data?.salesOrders[0]
                .quickbooksSalesOrderId ? (
                <>
                  <Button
                    text="Cancel"
                    onClick={dialogProps.onClose}
                    disabled={exportingSalesOrder}
                  />
                  <Button
                    text={
                      exportingSalesOrder
                        ? "Exporting..."
                        : "Export to Quickbooks"
                    }
                    intent={Intent.PRIMARY}
                    onClick={exportSalesOrderToQuickbooks}
                    disabled={exportingSalesOrder}
                  />
                </>
              ) : (
                <Button
                  text="Close"
                  onClick={dialogProps.onClose}
                  disabled={exportingSalesOrder}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
}
