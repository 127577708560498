import { gql, useMutation, useQuery } from "@apollo/client";
import { useApiError } from "../../../hooks/useApiError";
import { Vendor } from "../../../types/ApiTypes";

const GET_VENDORS = gql`
  query GetVendors {
    vendors(order: { name: ASC }) {
      id
      name
      listPriceAdjustment
    }
  }
`;

const UPDATE_VENDOR = gql`
  mutation UpdateVendor($input: UpdateVendorPayloadInput) {
    updateVendor(payload: $input) {
      id
    }
  }
`;

export function useVendors() {
  const handleFetchError = useApiError("fetch vendors");
  const handleUpdateError = useApiError("update vendor");

  const getVendors = useQuery<{ vendors: Vendor[] }>(GET_VENDORS, {
    onError: handleFetchError,
  });

  const [updateVendor, updateVendorMutation] = useMutation<{
    updateVendor: Vendor;
  }>(UPDATE_VENDOR, {
    refetchQueries: [
      {
        query: GET_VENDORS,
      },
    ],
    onError: handleUpdateError,
  });

  return {
    vendors: getVendors.data?.vendors,
    updateVendor,
    loading: getVendors.loading,
    updateVendorLoading: updateVendorMutation.loading,
    error: getVendors.error ?? updateVendorMutation.error,
  };
}
