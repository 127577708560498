import { PurchaseOrder } from "../types/ApiTypes";
import { gql, useQuery } from "@apollo/client";

const GET_PURCHASE_ORDER_BY_ID = gql`
  query GetPurchaseOrderById($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      code
      vendor {
        id
        name
      }
      projectId
      project {
        id
        name
        shipToAddress {
          id
          street1
          street2
          city
          state
          postalCode
        }
      }
      manufacturerId
      manufacturer {
        id
        name
      }
      companyId
      company {
        id
        name
      }
      personId
      person {
        id
        firstName
        lastName
      }
      createdAt
    }
  }
`;

export function usePurchaseOrder(purchaseOrderId: number) {
  const getPurchaseOrderById = useQuery<
    { purchaseOrders: PurchaseOrder[] },
    { purchaseOrderId: number }
  >(GET_PURCHASE_ORDER_BY_ID, {
    variables: {
      purchaseOrderId,
    },
    fetchPolicy: "no-cache",
  });

  return {
    purchaseOrder: getPurchaseOrderById.data?.purchaseOrders[0],
    loading: getPurchaseOrderById.loading,
    error: getPurchaseOrderById.error,
    refresh: () => getPurchaseOrderById.refetch(),
  };
}
