import { PurchaseOrderEditorState } from "../reducers/PurchaseOrderEditor/PurchaseOrderEditorTypes";
import {
  CommitPurchaseOrderDraftPayloadInput,
  CreatePurchaseOrderLineItemPayloadInput,
  UpdatePurchaseOrderLineItemPayloadInput,
} from "../../../../types/ApiTypes";
import _ from "lodash";
import { EditablePurchaseOrderLineItem } from "../reducers/PurchaseOrderEditor/PurchaseOrderEditorTypes";
import { buildCreateProductConfigurationPayload } from "../reducers/LineItemEditor/LineItemEditorHelpers";

export function buildCreatePurchaseOrderLineItemPayload(
  lineItem: EditablePurchaseOrderLineItem
): CreatePurchaseOrderLineItemPayloadInput {
  return {
    sortIndex: lineItem.sortIndex,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    purchasePrice: Math.round(lineItem.purchasePrice),
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildUpdatePurchaseOrderLineItemPayload(
  lineItem: EditablePurchaseOrderLineItem
): UpdatePurchaseOrderLineItemPayloadInput {
  return {
    id: lineItem.id!,
    sortIndex: lineItem.sortIndex,
    purchasePrice: lineItem.purchasePrice,
    description: lineItem.description,
    internalDescription: lineItem.internalDescription,
    quantity: lineItem.quantity,
    listPriceMultiplier: lineItem.listPriceMultiplier,
    configuration: buildCreateProductConfigurationPayload(
      lineItem.configuration
    ),
  };
}

export function buildCommitPurchaseOrderDraftPayload(
  purchaseOrderId: number,
  state: PurchaseOrderEditorState
): CommitPurchaseOrderDraftPayloadInput {
  const createPurchaseOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => li.isCreated)
        .map(buildCreatePurchaseOrderLineItemPayload)
    )
  );
  const updatePurchaseOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems
        .filter((li) => !li.isCreated && li.isModified)
        .map(buildUpdatePurchaseOrderLineItemPayload)
    )
  );
  const deletePurchaseOrderLineItems = _.flatten(
    state.lineItemGroups.map((g) =>
      g.lineItems.filter((li) => li.isRemoved).map((li) => li.id!)
    )
  );

  return {
    purchaseOrderId,
    requestedFulfillmentDate:
      state.quickSettings.requestedFulfillmentDate?.toISOString(),
    requestedFulfillmentDateNotes:
      state.quickSettings.requestedFulfillmentDateNotes,
    requestedFulfillmentDateFlagged:
      state.quickSettings.requestedFulfillmentDateFlagged,
    requestedShipDate: state.requestedShipDate?.toISOString(),
    primaryContactPersonId:
      state.quickSettings.primaryContact?.person?.id ?? -1,
    primaryContactCompanyId:
      state.quickSettings.primaryContact?.company?.id ?? -1,
    billingAddressId: state.quickSettings.billingAddress?.id ?? -1,
    shipToAddressId: state.quickSettings.shipToAddress?.id ?? -1,
    salesRepAccountId: state.quickSettings.salesRepAccount?.id ?? -1,
    createPurchaseOrderLineItemPayloads: createPurchaseOrderLineItems,
    updatePurchaseOrderLineItemPayloads: updatePurchaseOrderLineItems,
    deletePurchaseOrderLineItemPayloads: deletePurchaseOrderLineItems,
  };
}
