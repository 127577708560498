import { gql } from "@apollo/client";
import {
  FormatContactNameContact,
  FormatContactNameContactFragment,
} from "../../../helpers/ContactHelper";
import { ContactRole } from "../../../types/ApiTypes";

export type GetSalesDashboardParams = {
  auth0Id: string;
};

export type GetSalesDashboardResult = {
  accounts: {
    id: number;
    name: string;
  }[];

  companies: {
    id: number;
    name: string;
    email: string;
    phone: string;
    contacts: {
      id: number;
      role: ContactRole;
      person: {
        id: number;
      };
    }[];
  }[];

  quotes: {
    id: number;
    code: string;
    salesOrder?: { id: number };
    quoteVersionGroup: {
      id: number;
      project: {
        id: number;
        primaryContact?: FormatContactNameContact;
        salesRepAccount?: { id: number; name: string };
      };
    };
    requestedFulfillmentDate?: string;
    totalAmount: number;
    totalQuantity: number;
    createdAt: string;
    createdBy: {
      id: number;
      name: string;
    };
  }[];

  salesOrders: {
    id: number;
    code: string;
    project: {
      id: number;
      primaryContact?: FormatContactNameContact;
      salesRepAccount?: { id: number; name: string };
    };
    requestedFulfillmentDate?: string;
    totalAmount: number;
    totalQuantity: number;
    createdAt: string;
    createdBy: {
      id: number;
      name: string;
    };
  }[];
};

export const GetSalesDashboard = gql`
  ${FormatContactNameContactFragment}

  query GetSalesDashboard($auth0Id: String!) {
    accounts(where: { auth0Id: { eq: $auth0Id } }) {
      id
      name
    }

    companies(
      order: { name: ASC }
      where: {
        or: [
          { defaultSalesRep: { auth0Id: { eq: $auth0Id } } }
          { createdBy: { auth0Id: { eq: $auth0Id } } }
        ]
      }
    ) {
      id
      name
      email
      phone
      contacts {
        id
        role
        person {
          id
        }
      }
    }

    quotes(
      where: {
        quoteVersionGroup: {
          project: {
            or: [
              { salesRepAccount: { auth0Id: { eq: $auth0Id } } }
              { createdBy: { auth0Id: { eq: $auth0Id } } }
            ]
          }
        }
      }
    ) {
      id
      code
      salesOrder {
        id
      }
      quoteVersionGroup {
        id
        project {
          id
          primaryContact {
            id
            ...FormatContactNameContactFragment
          }
          salesRepAccount {
            id
            name
          }
        }
      }
      requestedFulfillmentDate
      totalAmount
      totalQuantity
      createdAt
      createdBy {
        id
        name
      }
    }

    salesOrders(
      where: {
        project: {
          or: [
            { salesRepAccount: { auth0Id: { eq: $auth0Id } } }
            { createdBy: { auth0Id: { eq: $auth0Id } } }
          ]
        }
      }
    ) {
      id
      code
      project {
        id
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
        salesRepAccount {
          id
          name
        }
      }
      requestedFulfillmentDate
      totalAmount
      totalQuantity
      createdAt
      createdBy {
        id
        name
      }
    }
  }
`;
