import React, { useCallback, useState } from "react";
import {
  Button,
  Classes,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
  IDialogProps,
  Icon,
} from "@blueprintjs/core";
import { QuoteVersionGroup } from "../../../../types/ApiTypes";
import { AddQuoteVersionGroupForm } from "./AddQuoteVersionGroupForm";

export type AddQuoteVersionGroupDialogProps = IDialogProps & {
  projectId: number;
  onSubmit: (quoteVersionGroup: QuoteVersionGroup) => void;
};

export function AddQuoteVersionGroupDialog({
  projectId,
  isOpen,
  onSubmit,
  ...dialogProps
}: AddQuoteVersionGroupDialogProps) {
  return (
    <Dialog
      title="Add Quote Version Group"
      isOpen={isOpen}
      isCloseButtonShown={false}
      lazy
      usePortal
    >
      <AddQuoteVersionGroupForm
        projectId={projectId}
        onSubmit={onSubmit}
        onClose={() => {
          if (dialogProps.onClose) dialogProps.onClose(null!);
        }}
      />
    </Dialog>
  );
}
