import React, { useCallback, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, Classes, Dialog, FormGroup, Popover } from "@blueprintjs/core";
import { PersonInputGroup } from "../projects/CreateProjectDialog/PersonInputGroup";
import { CompanyInputGroup } from "../projects/CreateProjectDialog/CompanyInputGroup";
import { InlineCreatePersonForm } from "../projects/CreateProjectDialog/InlineCreatePersonForm";
import { InlineCreateCompanyForm } from "../projects/CreateProjectDialog/InlineCreateCompanyForm";
import {
  QuickSettingItem,
  QuickSettingsAction,
} from "./QuickSettings/QuickSettingItem";
import { useHistory } from "react-router-dom";

export type ContactInputGroupPerson = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type ContactInputGroupCompany = {
  id: number;
  name: string;
};

export type ContactInputGroupContact = {
  person?: ContactInputGroupPerson;
  company?: ContactInputGroupCompany;
};

type GetPeopleAndCompaniesResult = {
  people: ContactInputGroupPerson[];
  companies: ContactInputGroupCompany[];
};

const GetPeopleAndCompanies = gql`
  query GetPeopleAndCompanies {
    people(order: { firstName: ASC }) {
      id
      firstName
      lastName
      email
      phone
    }
    companies(order: { name: ASC }) {
      id
      name
    }
  }
`;

function getLabel(contact?: ContactInputGroupContact) {
  if (!!contact?.person && !!contact?.company) {
    return `${contact?.company.name} (${contact?.person.firstName} ${contact?.person.lastName})`;
  } else if (!contact?.person && !!contact?.company) {
    return `${contact?.company.name}`;
  } else if (!!contact?.person && !contact?.company) {
    return `${contact?.person.firstName} ${contact?.person.lastName}`;
  } else {
    return "No Contact Selected";
  }
}

type ContactInputGroupProps = {
  value?: ContactInputGroupContact;
  onChange: (value?: ContactInputGroupContact) => void;
};

export function ContactInputGroup({ value, onChange }: ContactInputGroupProps) {
  const history = useHistory();

  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [isCreatePersonOpen, setIsCreatePersonOpen] = useState(false);
  const [isCreateCompanyOpen, setIsCreateCompanyOpen] = useState(false);

  const getPeopleAndCompanies = useQuery<GetPeopleAndCompaniesResult>(
    GetPeopleAndCompanies,
    { skip: !isChangeOpen, fetchPolicy: "cache-and-network" }
  );

  const updatePerson = useCallback(
    (person?: ContactInputGroupPerson) => {
      if (!value?.company && !person) onChange(undefined);
      else onChange({ ...value, person });
    },
    [value, onChange]
  );

  const updateCompany = useCallback(
    (company?: ContactInputGroupCompany) => {
      if (!value?.person && !company) onChange(undefined);
      else onChange({ ...value, company });
    },
    [value, onChange]
  );

  return (
    <>
      <Dialog
        title="Change Contact"
        isOpen={isChangeOpen}
        onClose={() => setIsChangeOpen(false)}
        lazy
        usePortal
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <FormGroup
            label="Person"
            helperText={
              <Popover
                isOpen={isCreatePersonOpen}
                onClose={() => setIsCreatePersonOpen(false)}
                position="right"
                minimal
              >
                <Button
                  minimal
                  small
                  text="Create Person"
                  icon="plus"
                  onClick={() => setIsCreatePersonOpen(true)}
                />
                <InlineCreatePersonForm
                  onPersonCreated={(p) => {
                    updatePerson(p);
                    setIsCreatePersonOpen(false);
                  }}
                />
              </Popover>
            }
          >
            <PersonInputGroup
              value={value?.person}
              people={getPeopleAndCompanies.data?.people ?? []}
              onChange={updatePerson}
              loading={getPeopleAndCompanies.loading}
            />
          </FormGroup>

          <FormGroup
            label="Company"
            helperText={
              <Popover
                isOpen={isCreateCompanyOpen}
                onClose={() => setIsCreateCompanyOpen(false)}
                position="right"
                minimal
              >
                <Button
                  minimal
                  small
                  text="Create Company"
                  icon="plus"
                  onClick={() => setIsCreateCompanyOpen(true)}
                />
                <InlineCreateCompanyForm
                  onCompanyCreated={(c) => {
                    updateCompany(c);
                    setIsCreateCompanyOpen(false);
                  }}
                />
              </Popover>
            }
          >
            <CompanyInputGroup
              value={value?.company}
              onChange={updateCompany}
              companies={getPeopleAndCompanies.data?.companies ?? []}
              loading={getPeopleAndCompanies.loading}
            />
          </FormGroup>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Done" onClick={() => setIsChangeOpen(false)} />
          </div>
        </div>
      </Dialog>

      <QuickSettingItem
        actions={
          <>
            <QuickSettingsAction
              text="Change"
              onClick={() => setIsChangeOpen(true)}
            />
            {value && (value.person || value.company) && (
              <>
                <QuickSettingsAction
                  text="Remove"
                  onClick={() => onChange(undefined)}
                />
                <QuickSettingsAction
                  text="View"
                  onClick={() => {
                    if (value.person) {
                      history.push(`/contacts/people/${value.person.id}`);
                    } else if (value.company) {
                      history.push(`/contacts/companies/${value.company.id}`);
                    }
                  }}
                />
              </>
            )}
          </>
        }
      >
        {!value && (
          <div>
            <p className="m-0 text-gray-400">No Contact Selected</p>
          </div>
        )}

        {value && (
          <div>
            {value.person && (
              <p className="m-0 font-bold">
                {value.person.firstName} {value.person.lastName}
              </p>
            )}
            {value.company && <p className="m-0">{value.company.name}</p>}
          </div>
        )}
      </QuickSettingItem>
    </>
  );
}
