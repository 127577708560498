import {
  Button,
  Callout,
  Classes,
  Dialog,
  DialogProps,
  Icon,
  Intent,
  Spinner,
} from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Quote } from "../../../../types/ApiTypes";
import { useApiError } from "../../../../hooks/useApiError";
import { DefaultToaster } from "../../../shared/DefaultToaster/DefaultToaster";

const GET_QUOTE_DETAILS = gql`
  query GetQuoteDetails($quoteId: Int!) {
    quotes(where: { id: { eq: $quoteId } }) {
      id
      quickbooksEstimateId
      code
    }
  }
`;

const EXPORT_QUOTE = gql`
  mutation ExportQuote($quoteId: Int!) {
    exportQuoteToQuickbooksEnterprise(quoteId: $quoteId) {
      estimateId
    }
  }
`;

type ExportQuoteToQuickbooksDialogProps = DialogProps & {
  quoteId: number;
};

export function ExportQuoteToQuickbooksDialog({
  quoteId,
  ...dialogProps
}: ExportQuoteToQuickbooksDialogProps) {
  const handleExportError = useApiError("export quote");

  const getQuoteDetails = useQuery<{ quotes: Quote[] }, { quoteId: number }>(
    GET_QUOTE_DETAILS,
    {
      variables: {
        quoteId,
      },
    }
  );

  const [exportQuote, { loading: exportingQuote }] = useMutation<
    { id: number },
    { quoteId: number }
  >(EXPORT_QUOTE, {
    onError: handleExportError,
    refetchQueries: [
      {
        query: GET_QUOTE_DETAILS,
        variables: { quoteId },
      },
    ],
  });

  async function exportQuoteToQuickbooks() {
    await exportQuote({ variables: { quoteId } });
    DefaultToaster.show({
      icon: "tick-circle",
      intent: "success",
      message: "Quote exported to Quickbooks successfully!",
    });
  }

  return (
    <Dialog title="Export Quote to Quickbooks" {...dialogProps}>
      {getQuoteDetails.loading && (
        <div className="h-40 flex items-center justify-center">
          <Spinner />
        </div>
      )}

      {!getQuoteDetails.loading && (
        <>
          <div className={Classes.DIALOG_BODY}>
            <h4 className="font-medium uppercase tracking-wide text-gray-500 mb-2">
              Export Status
            </h4>
            {getQuoteDetails.data?.quotes[0].quickbooksEstimateId ? (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getQuoteDetails.data?.quotes[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-700">
                        Estimate #
                        {getQuoteDetails.data?.quotes[0].quickbooksEstimateId}
                      </p>
                    </div>
                    <Icon icon="tick-circle" className="text-green-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getQuoteDetails.data?.quotes[0].code} has been exported as
                  Estimate #
                  {getQuoteDetails.data?.quotes[0].quickbooksEstimateId}. If you
                  need to re-export this quote, please contact support.
                </p>
              </div>
            ) : (
              <div className="space-y-2">
                <div className="flex items-center divide-x border rounded bg-white">
                  <div className="flex-1 p-4 flex items-center">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">WOF ERP</h4>
                      <p className="m-0 text-gray-700">
                        {getQuoteDetails.data?.quotes[0].code}
                      </p>
                    </div>
                  </div>
                  <div className="flex-1 p-4 flex items-center opacity-50">
                    <div className="flex-1">
                      <h4 className="font-medium text-indigo-600">
                        Quickbooks
                      </h4>
                      <p className="m-0 text-gray-500">Not Exported</p>
                    </div>
                    <Icon icon="help" className="text-gray-500" />
                  </div>
                </div>
                <p className="text-sm m-0 text-gray-500">
                  {getQuoteDetails.data?.quotes[0].code} has not been exported.
                  To export this quote to Quickbooks, click the button below.
                </p>
              </div>
            )}
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              {!getQuoteDetails.data?.quotes[0].quickbooksEstimateId ? (
                <>
                  <Button
                    text="Cancel"
                    onClick={dialogProps.onClose}
                    disabled={exportingQuote}
                  />
                  <Button
                    text={
                      exportingQuote ? "Exporting..." : "Export to Quickbooks"
                    }
                    intent={Intent.PRIMARY}
                    onClick={exportQuoteToQuickbooks}
                    disabled={exportingQuote}
                  />
                </>
              ) : (
                <Button
                  text="Close"
                  onClick={dialogProps.onClose}
                  disabled={exportingQuote}
                />
              )}
            </div>
          </div>
        </>
      )}
    </Dialog>
  );
}
