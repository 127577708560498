import { CatalogProduct } from "../../../types/ApiTypes";
import { Icon, Intent, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";
import { SearchProducts } from "./SearchProducts";

const USED_INVENTORY_PRODUCT: CatalogProduct = {
  id: 960704,
  code: "USED",
  description: "Used Inventory",
  listPrice: 0,
} as CatalogProduct;

export type RecentProductsPanelProps = {
  allowInventoryItems: boolean;
  recentProducts: CatalogProduct[];
  onChoose: (product: CatalogProduct) => void;
  onClearRecentProducts: () => void;
  onChooseFromCatalog: () => void;
  onChooseFromInventory: () => void;
  onChooseService: () => void;
  onChooseCustomProduct: () => void;
};

export function RecentProductsPanel({
  allowInventoryItems,
  recentProducts,
  onChoose,
  onClearRecentProducts,
  onChooseFromCatalog,
  onChooseFromInventory,
  onChooseService,
  onChooseCustomProduct,
}: RecentProductsPanelProps) {
  return (
    <Menu>
      <SearchProducts onChoose={(product) => onChoose(product)} />

      <>
        <MenuDivider title="Recent Products" />

        {recentProducts.length === 0 && (
          <MenuItem disabled text="No Recent Products" />
        )}

        {recentProducts.length > 0 && (
          <>
            {recentProducts.map((product) => (
              <MenuItem
                key={product.id}
                label={[
                  product.code,
                  product.vendor?.name ? `(${product.vendor.name})` : undefined,
                ]
                  .filter((a) => !!a)
                  .join(" ")}
                text={product.description}
                onClick={() => onChoose(product)}
                shouldDismissPopover={false}
              />
            ))}
            <MenuItem
              intent={Intent.PRIMARY}
              text="Clear Recent Products"
              onClick={onClearRecentProducts}
              shouldDismissPopover={false}
            />
          </>
        )}
      </>

      <MenuDivider />

      <MenuItem
        text="Choose from Catalog"
        icon="book"
        labelElement={<Icon icon="chevron-right" />}
        shouldDismissPopover={false}
        onClick={() => onChooseFromCatalog()}
      />

      <MenuItem
        text="Create Custom Product"
        icon="new-object"
        onClick={() => onChooseCustomProduct()}
      />

      <MenuItem
        text="Choose Service"
        icon="truck"
        labelElement={<Icon icon="chevron-right" />}
        shouldDismissPopover={false}
        onClick={() => onChooseService()}
      />

      <MenuItem
        text="Used Inventory"
        icon="box"
        onClick={() => onChoose(USED_INVENTORY_PRODUCT)}
      />

      {allowInventoryItems && (
        <MenuItem
          text="Choose from Inventory"
          icon="box"
          labelElement={<Icon icon="chevron-right" />}
          shouldDismissPopover={false}
          onClick={() => onChooseFromInventory()}
        />
      )}
    </Menu>
  );
}
