import { DrawerTitleToolbar } from "../DrawerTitleToolbar/DrawerTitleToolbar";
import {
  Button,
  FormGroup,
  HTMLSelect,
  Intent,
  NumericInput,
  TextArea,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { SyntheticEvent, useState } from "react";

import { EditableProductConfiguration } from "../LineItemEditor/reducers/LineItemEditor/LineItemEditorTypes";

const GET_VENDORS = gql`
  query GetVendors {
    vendors {
      id
      name
    }
  }
`;

type GetVendorsResult = {
  vendors: {
    id: string;
    name: string;
  }[];
};

type ConfigureCustomProductProps = {
  productConfiguration: EditableProductConfiguration;
  onProductConfigurationChange: (
    newValue: EditableProductConfiguration
  ) => void;
  onClose: ((event: SyntheticEvent<HTMLElement, Event>) => void) | undefined;
};

export function ConfigureCustomProduct({
  productConfiguration,
  onProductConfigurationChange,
  onClose,
}: ConfigureCustomProductProps) {
  const [configuration, setConfiguration] =
    useState<EditableProductConfiguration>({
      ...productConfiguration,
    });

  const getVendors = useQuery<GetVendorsResult>(GET_VENDORS);

  return (
    <>
      <DrawerTitleToolbar title="Configure Custom Product" onClose={onClose}>
        <Button
          intent={Intent.PRIMARY}
          text="Save Changes"
          onClick={() => {
            onProductConfigurationChange(configuration);
            if (onClose) onClose(null!);
          }}
        />
      </DrawerTitleToolbar>

      <div className="bg-gray-50 h-full overflow-y-auto">
        <div className="flex flex-col divide-y mb-20">
          <div className="px-2 py-1 shadow-sm bg-white flex items-center justify-between">
            <h2 className="text-sm uppercase tracking-wide font-medium text-gray-700">
              Custom Product Details
            </h2>
          </div>

          <div className="p-2 space-y-2">
            <FormGroup label="Vendor">
              <HTMLSelect
                value={configuration.customVendorId?.toString() ?? ""}
                onChange={(e) =>
                  setConfiguration((s) => ({
                    ...s,
                    customVendorId: parseInt(e.target.value),
                  }))
                }
                fill
              >
                {getVendors?.data?.vendors.map((v) => (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                ))}
                <option value="" disabled>
                  Select a vendor...
                </option>
              </HTMLSelect>
            </FormGroup>

            <FormGroup label="List Price">
              <NumericInput
                leftIcon="dollar"
                defaultValue={(configuration.customListPrice ?? 0) / 100}
                onValueChange={(v) =>
                  setConfiguration((s) => ({
                    ...s,
                    customListPrice: Math.round(v * 100),
                  }))
                }
                majorStepSize={1}
                stepSize={0.1}
                minorStepSize={0.01}
                fill
              />
            </FormGroup>

            <FormGroup label="Product Code">
              <TextArea
                value={configuration.customCode}
                onChange={(e) =>
                  setConfiguration((s) => ({
                    ...s,
                    customCode: e.target.value,
                  }))
                }
                className="font-mono"
                style={{ height: 200 }}
                fill
              />
            </FormGroup>

            <FormGroup label="Product Description">
              <TextArea
                value={configuration.customDescription}
                onChange={(e) =>
                  setConfiguration((s) => ({
                    ...s,
                    customDescription: e.target.value,
                  }))
                }
                className="font-mono"
                style={{ height: 200 }}
                fill
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </>
  );
}
