import { Icon } from "@blueprintjs/core";
import React from "react";
import { formatPrice } from "../../../helpers/PriceHelper";
import { CatalogProduct } from "../../../types/ApiTypes";

export type SuggestPriceDetailsProps = {
  productListPrice: number;
  optionsListPrice: number;
  totalListPrice: number;
  vendorAdjustedListPrice: number;
  vendorCost: number;
  grossProfit: number;
  suggestedSalesPrice: number;
  vendorListPriceAdjustment: number;
};

export function SuggestedPriceDetails({
  productListPrice,
  optionsListPrice,
  totalListPrice,
  vendorAdjustedListPrice,
  vendorCost,
  grossProfit,
  suggestedSalesPrice,
  vendorListPriceAdjustment,
}: SuggestPriceDetailsProps) {
  return (
    <div>
      <div className="flex justify-between pb-0 p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Product List Price</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(productListPrice)}
        </p>
      </div>
      <div className="flex justify-between pb-0 p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Options List Price</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(optionsListPrice)}
        </p>
      </div>
      <div className="flex justify-between pb-0 p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Total List Price</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(totalListPrice)}
        </p>
      </div>
      <div className="flex justify-between border-b p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Vendor Adjusted List Price</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(vendorAdjustedListPrice)}
        </p>
      </div>
      <div className="flex justify-between pb-0 p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Vendor Adjustment Used</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {vendorListPriceAdjustment * 100}%
        </p>
      </div>
      {/*<div className="flex justify-between pb-0 p-4 bg-gray-50">*/}
      {/*  <div>*/}
      {/*    <p className="font-bold mb-1">Vendor Discount Used</p>*/}
      {/*  </div>*/}
      {/*  <p className="text-right font-semibold text-gray-600 m-0">*/}
      {/*    {catalogProduct.sellPriceMultiplier.discount}*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div className="flex justify-between border-b p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Vendor Cost</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(vendorCost)}
        </p>
      </div>
      {/*<div className="flex justify-between pb-0 p-4 bg-gray-50">*/}
      {/*  <div>*/}
      {/*    <p className="font-bold mb-1">Gross Profit Margin</p>*/}
      {/*  </div>*/}
      {/*  <p className="text-right font-semibold text-gray-600 m-0">*/}
      {/*    {catalogProduct.sellPriceMultiplier.defaultGrossProfit * 100}%*/}
      {/*  </p>*/}
      {/*</div>*/}
      <div className="flex justify-between border-b p-4 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Gross Profit</p>
        </div>
        <p className="text-right font-semibold text-gray-600 m-0">
          {formatPrice(grossProfit)}
        </p>
      </div>
      <div className="flex justify-between p-4 pb-0 bg-gray-50">
        <div>
          <p className="font-bold mb-1">Sales Price</p>
        </div>
        <p className="text-right font-semibold text-gray-600 text-lg m-0">
          {formatPrice(suggestedSalesPrice)}
        </p>
      </div>
      <div className="p-4 bg-gray-50">
        <div className="rounded bg-yellow-100 p-3 flex">
          <Icon icon="warning-sign" className="text-yellow-500" />
          <p className="text-xs ml-2 m-0">
            Caution, vendor freight charges are not included in these
            calculations.
          </p>
        </div>
      </div>
    </div>
  );
}
