import React, { useEffect, useMemo, useState } from "react";
import {
    Button,
    Popover,
    Spinner,
} from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import {
    Person,
    PurchaseOrderReportItem,
    PurchaseOrderReportShipmentStatus,
    PurchaseOrderSubmissionResult,
} from "../../types/ApiTypes";
import { formatDate } from "../../helpers/DateHelper";
import {encodePriceAsInteger, formatPrice} from "../../helpers/PriceHelper";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {process} from "@progress/kendo-data-query";
import {ReportColumnMenu} from "../../components/reports/ReportColumnMenu";
import {EntityLink} from "../../components/reports/EntityLink";
import {useReportDataState} from "../../hooks/useReportDataState";
import {ProjectSubnavbar} from "../../components/projects/[projectId]/ProjectSubnavbar";
import {ShareReportMenu} from "../../components/reports/ShareReportMenu";

const GET_PURCHASE_ORDERS = gql`
  query GetPurchaseOrderReport {
    purchaseOrderReport {
      id
      orderNumber
      vendor
      projectId
      customerPersonName
      customerCompanyName
      quantity
      totalAmount
      receivedShipments
      totalShipments
      shipmentStatus
      shipmentStatusDate
      submissionDate
      submissionResult
      submissionResultDate
      createdAt
    }
  }
`;

const formatSubmissionStatus = (entry) =>
    entry.submissionDate == null
        ? "Unsubmitted"
        : entry.submissionResultDate == null
            ? "Submitted " + formatDate(entry.submissionDate!)
            : entry.submissionResult == PurchaseOrderSubmissionResult.Confirmed
                ? "Confirmed " + formatDate(entry.submissionResultDate!)
                : entry.submissionResult ==
                PurchaseOrderSubmissionResult.ErrorWhenSending
                    ? "Send Error"
                    : "Changes Needed";

const formatShipmentStatus = (entry) =>
    entry.shipmentStatus == PurchaseOrderReportShipmentStatus.AwaitingInfo
        ? "Awaiting Info"
        : entry.shipmentStatus ==
        PurchaseOrderReportShipmentStatus.ExpectedToShip
            ? "Expected to Ship on " + formatDate(entry.shipmentStatusDate)
            : entry.shipmentStatus == PurchaseOrderReportShipmentStatus.Shipped
                ? "Shipped on " + formatDate(entry.shipmentStatusDate)
                : entry.shipmentStatus ==
                PurchaseOrderReportShipmentStatus.ExpectedToArrive
                    ? "Expected to Arrive on " + formatDate(entry.shipmentStatusDate)
                    : entry.shipmentStatus == PurchaseOrderReportShipmentStatus.Arrived
                        ? "Arrived on " + formatDate(entry.shipmentStatusDate)
                        : "Error";

export function PurchasingPurchaseOrders() {


    const getPurchaseOrders = useQuery<{ purchaseOrderReport: PurchaseOrderReportItem[] }>(GET_PURCHASE_ORDERS);

    const [dataState, setDataState] = useReportDataState({
        sort: [{field: "id", dir: "desc"}]
    });


    const reportLines = useMemo(() => (getPurchaseOrders.data?.purchaseOrderReport.map((entry) => ({
        ...entry,
        totalAmount: entry.totalAmount / 100,
        createdAt: new Date(entry.createdAt),
        submissionStatus: formatSubmissionStatus(entry),
        shipmentStatus: formatShipmentStatus(entry),
        receivedShipments: entry.receivedShipments + " of " + entry.totalShipments,
    }))) ?? [], [getPurchaseOrders.data]);



    return (
        <div className="flex flex-col h-full">
            <ProjectSubnavbar
                title="Purchase Orders"
                style={{ top: "unset" }}
                actions={
                    <>
                        <Popover minimal>
                            <Button minimal icon="share" text="Share Report" />
                            <ShareReportMenu dataState={dataState} />
                        </Popover>
                    </>
                }
            />

            <div className="h-full relative">
                {getPurchaseOrders.loading ? (
                    <Spinner />
                ) : (
                    <Grid
                        className="absolute top-0 bottom-0 w-full"
                        data={process(reportLines, dataState)}
                        {...dataState}
                        onDataStateChange={(e) => setDataState(e.dataState)}
                        sortable
                        reorderable
                        resizable
                    >
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="id"
                            title="Purchase Order#"
                            width={160}
                            cell={(props) => (
                                <td>
                                    <EntityLink
                                        url={props.dataItem.projectId ? `/projects/${props.dataItem.projectId}/purchase-orders/${props.dataItem.id}` : `/purchase-orders/${props.dataItem.id}`}
                                        text={props.dataItem.orderNumber}
                                    />
                                </td>
                            )}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="vendor"
                            title="Vendor"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="customerPersonName"
                            title="Customer"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="customerCompanyName"
                            title="Customer Company"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="submissionStatus"
                            title="Submission Status"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="shipmentStatus"
                            title="Shipment Status"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="receivedShipments"
                            title="Shipments Received"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="quantity"
                            title="Quantity"
                            width={200}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            field="totalAmount"
                            title="Total Amount"
                            filter="numeric"
                            cell={(props) => (
                                <td>
                                    {props.field && props.dataItem[props.field]
                                        ? formatPrice(
                                            encodePriceAsInteger(props.dataItem[props.field])
                                        )
                                        : undefined}
                                </td>
                            )}
                            width={150}
                        />
                        <GridColumn
                            columnMenu={ReportColumnMenu}
                            filter="date"
                            format="{0:d}"
                            field="createdAt"
                            title="Creation Date"
                            width={150}
                        />
                    </Grid>
                )}
            </div>
        </div>
    );
}