import { createContext, PropsWithChildren, useState } from "react";
import { ManagerApprovalDialog } from "../../components/shared/ManagerApprovalDialog/ManagerApprovalDialog";

export const ManagerApprovalContext = createContext({
  requestManagerApproval: (onGranted: () => void) => {},
});

export function ManagerApprovalProvider({ children }: PropsWithChildren<{}>) {
  const [isManagerApprovalDialogOpen, setIsManagerApprovalDialogOpen] =
    useState(false);
  const [managerApprovalGrantedCallback, setManagerApprovalGrantedCallback] =
    useState<() => {}>();

  function requestManagerApproval(onGranted) {
    setManagerApprovalGrantedCallback(() => onGranted);
    setIsManagerApprovalDialogOpen(true);
  }

  return (
    <>
      <ManagerApprovalDialog
        isOpen={isManagerApprovalDialogOpen}
        onClose={() => {
          setIsManagerApprovalDialogOpen(false);
          setManagerApprovalGrantedCallback(undefined);
        }}
        onManagerApprovalGranted={() => {
          managerApprovalGrantedCallback?.();
          setIsManagerApprovalDialogOpen(false);
          setManagerApprovalGrantedCallback(undefined);
        }}
      />

      <ManagerApprovalContext.Provider value={{ requestManagerApproval }}>
        {children}
      </ManagerApprovalContext.Provider>
    </>
  );
}
