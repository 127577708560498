import { gql, useMutation } from "@apollo/client";

export type SIFEntryFeature = {
  selections: {
    optionGroupId: number;
    optionGroupCode: string;
    optionGroupDescription: string;
    optionId: number;
    optionCode: string;
    optionDescription: string;
  }[];
  unresolvedSelections: {
    optionGroupCode: string;
    optionGroupDescription: string;
    optionCode: string;
    optionDescription: string;
    isDismissed: boolean;
  }[];
};

export type SIFEntry = {
  productNumber: string;
  customDescription: string;
  sellDiscountRate: number;
  manufacturerCode: string;
  catalogCode: string;
  listPrice: number;
  purchasePrice: number;
  marginPercent: number;
  quantity: number;
  selections: {
    group: string;
    option: string;
    indexOfParent: number;
  }[];
  completeDecodingsCount: number;
  decodings: {
    manufacturerId: number;
    catalogId: number;
    catalogProductCode: string;
    catalogProductDescription: string;
    catalogProductId: number;
    decodingErrorsCount: number;
    features: SIFEntryFeature[];
  }[];
};

const DECODE_SIF_LINE_ITEMS = gql`
  mutation DecodeSIFLineItems($contents: String!) {
    decodeSifLineItems(sif: $contents) {
      productNumber
      customDescription
      sellDiscountRate
      manufacturerCode
      catalogCode
      listPrice
      purchasePrice
      marginPercent
      quantity
      selections {
        optionGroup
        option
        indexOfParent
      }
      completeDecodingsCount
      decodings {
        manufacturerId
        catalogId
        catalogProductId
        catalogProductCode
        catalogProductDescription
        decodingErrorsCount
        code
        description
        features {
          selections {
            optionGroupId
            optionGroupCode
            optionGroupDescription
            optionId
            optionCode
            optionDescription
            sortOrder
          }
          unresolvedSelections {
            optionGroupCode
            optionGroupDescription
            optionCode
            optionDescription
            sortOrder
          }
        }
      }
    }
  }
`;

export function useDecodeSifLineItems() {
  return useMutation<
    {
      decodeSifLineItems: SIFEntry[];
    },
    { contents: string }
  >(DECODE_SIF_LINE_ITEMS);
}
