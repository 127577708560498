import {
  InventoryItem,
  InventoryItemInspectionStatus,
} from "../types/ApiTypes";
import dayjs from "dayjs";
import { Intent } from "@blueprintjs/core";

export function getInspectionStatusText(item: InventoryItem) {
  switch (item.mostRecentInspectionStatus) {
    case InventoryItemInspectionStatus.Uninspected:
      return "Not Inspected";
    case InventoryItemInspectionStatus.NoDamage:
      return "Undamaged";
    case InventoryItemInspectionStatus.MinorDamage:
      return "Minor Damage";
    case InventoryItemInspectionStatus.MajorDamage:
      return "Major Damage";
    default:
      return "Error";
  }
}

export function getInspectionStatusIntent(item: InventoryItem): Intent {
  switch (item.mostRecentInspectionStatus) {
    case InventoryItemInspectionStatus.Uninspected:
      return Intent.NONE;
    case InventoryItemInspectionStatus.NoDamage:
      return Intent.SUCCESS;
    case InventoryItemInspectionStatus.MinorDamage:
      return Intent.WARNING;
    case InventoryItemInspectionStatus.MajorDamage:
      return Intent.DANGER;
    default:
      return Intent.DANGER;
  }
}
