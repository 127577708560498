import { useCallback } from "react";
import { DefaultToaster } from "../components/shared/DefaultToaster/DefaultToaster";
import { Intent } from "@blueprintjs/core";

export function useApiError(resourceName: string) {
  const handleApiError = useCallback(
    (err: Error) => {
      DefaultToaster.show({
        message: `Unable to ${resourceName} due to a network error.`,
        icon: "error",
        intent: Intent.DANGER,
      });
      return Promise.reject(err);
    },
    [resourceName]
  );

  return handleApiError;
}
