import classNames from "classnames";

export type ContactSidebarItemProps = {
  title: string;
  subtitle?: string | React.ReactNode;
  active?: boolean;
  onClick?: () => void;
};

export function ContactSidebarItem({
  title,
  subtitle,
  active = false,
  onClick,
}: ContactSidebarItemProps) {
  return (
    <button
      className={classNames(
        "block w-full text-left p-2 flex items-center border-b",
        { "bg-white": active }
      )}
      onClick={onClick}
      type="button"
    >
      <div className="w-10 bg-gray-600 h-10 border-none rounded mr-2 flex items-center justify-center">
        <p className="m-0 font-semibold text-lg text-center text-white">
          {title.substring(0, 1)}
        </p>
      </div>
      <div className="flex-1">
        <h3 className="font-semibold m-0">{title}</h3>
        {!!subtitle && <p className="m-0">{subtitle}</p>}
      </div>
    </button>
  );
}
