import produce from "immer";
import {
  SalesOrderEditorActions,
  SalesOrderEditorState,
} from "./SalesOrderEditorTypes";
import { recalculateLineItemSortIndex } from "../LineItemEditor/LineItemEditorHelpers";

export function addLineItem(
  state: SalesOrderEditorState,
  action: SalesOrderEditorActions
): SalesOrderEditorState {
  if (action.type !== "addLineItem") return state;

  return produce(state, (draft) => {
    const lineItemGroup = draft.lineItemGroups[action.lineItemGroupIndex];

    lineItemGroup.lineItems.push({
      id: undefined,
      sortIndex: lineItemGroup.lineItems.length,
      quantity: 1,
      listPriceMultiplier: 1,
      grossProfitPercent: 20,
      sellPrice: 0,
      description: "",
      internalDescription: "",
      isCreated: true,
      isModified: false,
      isRemoved: false,
      lineItemGroupId: lineItemGroup.id,
      taxCode: "",
      taxAmount: 0,
      modifiedFields: {
        quantity: false,
        listPriceMultiplier: false,
        grossProfitPercent: false,
        sellPrice: false,
        product: false,
        description: false,
        tax: false,
      },
    });

    recalculateLineItemSortIndex(draft);
  });
}

export function addLineItemGroup(
  state: SalesOrderEditorState,
  action: SalesOrderEditorActions
): SalesOrderEditorState {
  if (action.type !== "addLineItemGroup") return state;

  return produce(state, (draft) => {
    draft.lineItemGroups.push({
      id: -(state.lineItemGroups.length + 1),
      sortIndex: state.lineItemGroups.length,
      name: "New Sales Order Line Item Group",
      lineItems: [],
      isOpen: true,
      modifiedFields: {
        name: true,
      },
      isModified: true,
      isCreated: true,
      isRemoved: false,
    });
  });
}

export function initializeWithSalesOrder(
  state: SalesOrderEditorState,
  action: SalesOrderEditorActions
): SalesOrderEditorState {
  if (action.type !== "initializeWithSalesOrder") return state;

  return {
    quickSettings: {
      isModified: false,
      primaryContact: action.salesOrder.project.primaryContact,
      billingAddress: action.salesOrder.project.billingAddress,
      shipToAddress: action.salesOrder.project.shipToAddress,
      salesRepAccount: action.salesOrder.project.salesRepAccount,
      requestedFulfillmentDate: action.salesOrder.requestedFulfillmentDate
        ? new Date(action.salesOrder.requestedFulfillmentDate)
        : undefined,
      requestedFulfillmentDateNotes:
        action.salesOrder.requestedFulfillmentDateNotes,
      requestedFulfillmentDateFlagged:
        action.salesOrder.requestedFulfillmentDateFlagged,
    },
    salesOrderCode: action.salesOrder.code,
    selectedItems: [],
    lineItemGroups: [...action.salesOrder.salesOrderLineItemGroups]
      .sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))
      .map((lig) => ({
        id: lig.id!,
        sortIndex: lig.sortIndex,
        name: lig.name,
        lineItems: [...lig.salesOrderLineItems]
          .sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1))
          .map((li) => ({
            id: li.id!,
            sortIndex: li.sortIndex,
            lineItemGroupId: lig.id,
            discountingStructure: li.discountingStructure,
            listPriceMultiplier: li.listPriceMultiplier,
            grossProfitPercent: li.grossProfitPercent,
            sellPrice: li.sellPrice,
            quantity: li.quantity,
            description: li.description,
            internalDescription: li.internalDescription,
            taxAmount: li.taxAmount,
            taxCode: li.taxCode,
            configuration: li.configuration
              ? {
                  id: li.configuration.id,
                  isCustomConfiguration: li.configuration.isCustomConfiguration,
                  customCode: li.configuration.customCode,
                  customDescription: li.configuration.customDescription,
                  customListPrice: li.configuration.customListPrice,
                  customVendorId: li.configuration.customVendorId,
                  catalogProductCode:
                    li.configuration.catalogProduct?.code ?? "",
                  catalogProductDescription:
                    li.configuration.catalogProduct?.description ?? "",
                  catalogProductId: li.configuration.catalogProductId,
                  features: li.configuration.features.map((feature) => ({
                    catalogOptionGroupId: feature.catalogOptionGroupId,
                    description: feature.catalogOptionGroup.description,
                    selections: feature.selections.map((selection) => ({
                      catalogOptionId: selection.catalogOptionId,
                      catalogOptionCode: selection.catalogOption.code,
                      catalogOptionDescription:
                        selection.catalogOption.description,
                      catalogOptionGroupId: selection.catalogOptionGroupId,
                      catalogOptionGroupCode: selection.catalogOptionGroup.code,
                      catalogOptionGroupDescription:
                        selection.catalogOptionGroup.description,
                    })),
                  })),
                }
              : undefined,
            modifiedFields: {
              product: false,
              description: false,
              listPriceMultiplier: false,
              grossProfitPercent: false,
              sellPrice: false,
              quantity: false,
              tax: false,
            },
            isModified: false,
            isCreated: false,
            isRemoved: false,
          })),
        isOpen: true,
        isCreated: false,
        isModified: false,
        isRemoved: false,
        modifiedFields: { name: false },
      })),
  };
}
