import { Button, InputGroup, Intent, NonIdealState } from "@blueprintjs/core";
import { ContactSidebar } from "../../../components/contacts/ContactSidebar";
import { ContactSidebarItem } from "../../../components/contacts/ContactSidebarItem";
import { Route, useHistory, useParams } from "react-router-dom";
import { CompanyDetails } from "./[companyId]";
import { useCallback, useState } from "react";
import {
  CompanyType,
  CreateCompanyPayloadInput,
} from "../../../types/ApiTypes";
import { gql, useMutation, useQuery } from "@apollo/client";

const CreateCompany = gql`
  mutation CreateCompany($payload: CreateCompanyPayloadInput!) {
    createCompany(payload: $payload) {
      id
    }
  }
`;

const GetCompanies = gql`
  query GetCompanies {
    companies(order: { name: ASC }) {
      id
      name
    }
  }
`;

type GetCompaniesResult = {
  companies: {
    id: string;
    name: string;
  }[];
};

export type CompaniesRouteParams = {
  companyId?: string;
};

export function Companies() {
  const { companyId } = useParams<CompaniesRouteParams>();
  const history = useHistory();
  const [search, setSearch] = useState("");

  const getCompanies = useQuery<GetCompaniesResult>(GetCompanies, {
    fetchPolicy: "cache-and-network",
  });

  const [createCompany] = useMutation<
    { createCompany: { id: number } },
    { payload: CreateCompanyPayloadInput }
  >(CreateCompany, {
    refetchQueries: ["GetCompanies"],
  });

  const companies = getCompanies.data?.companies ?? [];

  const filterCompanies = useCallback(() => {
    const normalizedSearch = search.toLowerCase().trim();
    if (normalizedSearch.length === 0) return [...companies];
    return companies.filter((c) => {
      const normalizedName = c.name.toLowerCase().trim();
      return normalizedName.includes(normalizedSearch);
    });
  }, [search, companies]);

  const addCompany = useCallback(async () => {
    const newCompany = await createCompany({
      variables: {
        payload: {
          name: "New Company",
          type: CompanyType.Other,
          email: "",
          phone: "",
        },
      },
    });

    history.push(`/contacts/companies/${newCompany.data?.createCompany.id}`);
  }, [createCompany, history]);

  return (
    <div className="flex h-full items-stretch">
      <ContactSidebar
        searchElement={
          <>
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search companies"
              className="flex-1 mr-2"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button minimal icon="add" onClick={addCompany} />
          </>
        }
      >
        {!getCompanies.loading &&
          filterCompanies().map((company) => (
            <ContactSidebarItem
              active={!!companyId && companyId === company.id!.toString()}
              title={company.name}
              key={company.id}
              onClick={() => history.push(`/contacts/companies/${company.id}`)}
            />
          ))}
      </ContactSidebar>

      <div className="flex-1">
        <Route
          path="/contacts/companies/:companyId"
          component={CompanyDetails}
        />

        <Route
          path="/contacts/companies"
          exact
          render={() => (
            <NonIdealState
              icon="office"
              title="No Company Selected"
              action={
                <Button
                  icon="add"
                  text="New Company"
                  intent={Intent.PRIMARY}
                  onClick={addCompany}
                />
              }
            />
          )}
        />
      </div>
    </div>
  );
}
