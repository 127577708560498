import { ProjectSubnavbar } from "../../components/projects/[projectId]/ProjectSubnavbar";
import { Button, Icon, Intent, Popover, Spinner, Tag } from "@blueprintjs/core";
import { ShareReportMenu } from "../../components/reports/ShareReportMenu";
import { useReportDataState } from "../../hooks/useReportDataState";
import { gql, useQuery } from "@apollo/client";
import {
  FormatContactNameContactFragment,
  FormatContactNameContact,
  formatContactName,
} from "../../helpers/ContactHelper";
import { DataResult, process } from "@progress/kendo-data-query";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDetailRowProps,
  GridExpandChangeEvent,
} from "@progress/kendo-react-grid";
import { ReportColumnMenu } from "../../components/reports/ReportColumnMenu";
import { EntityLink } from "../../components/reports/EntityLink";
import { formatAccountName } from "../../helpers/AccountHelper";
import { Account, PurchaseOrderSubmissionResult } from "../../types/ApiTypes";
import { PropsWithChildren, useEffect, useState } from "react";
import { uniqBy } from "lodash";
import { GeneratePurchaseOrdersDialog } from "../../components/projects/[projectId]/sales-orders/[salesOrderId]/GeneratePurchaseOrders/GeneratePurchaseOrdersDialog";
import { formatDate } from "../../helpers/DateHelper";
import { RecordPurchaseOrderShipmentDrawer } from "../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/RecordPurchaseOrderShipmentDrawer";
import { Link } from "react-router-dom";
import { GenerateInventoryLabelsDialog } from "../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/shipments/GenerateInventoryLabelsDialog";
import { PurchaseOrderSubmissionDialog } from "../../components/projects/[projectId]/purchase-orders/PurchaseOrderSubmissionDialog";
import { PurchaseOrderSubmissionResultDialog } from "../../components/projects/[projectId]/purchase-orders/PurchaseOrderSubmissionResultDialog";
import { ReceiveShipmentDrawer } from "../../components/shared/ReceiveShipmentDialog/ReceiveShipmentDrawer";
import { useExpandableRows } from "../../hooks/useExpandableRows";

type GetSalesOrderPurchasingReportResult = {
  salesOrders: {
    id: number;
    code: string;
    purchaseOrders: {
      id: number;
      code: string;
      manufacturerId: number;
      vendor: {
        id: number;
        name: string;
      };
      mostRecentSubmission?: {
        id: number;
        result?: PurchaseOrderSubmissionResult;
        resultReceivedAt?: string;
        createdAt: string;
      };
      purchaseOrderShipments: {
        id: number;
        expectedArrivalDate?: string;
        actualArrivalDate?: string;
        createdAt: string;
      }[];
      lineItems: {
        id: number;
      }[];
      inventoryItems: {
        id: number;
        createdAt: string;
      }[];
      createdAt: string;
    }[];
    project: {
      id: number;
      primaryContact: FormatContactNameContact;
      salesRepAccount: Account;
      designerAccount: Account;
      customerServiceRepAccount: Account;
    };
  }[];
};

const GetSalesOrderPurchasingReport = gql`
  ${FormatContactNameContactFragment}

  query GetSalesOrderPurchasingReport {
    salesOrders {
      id
      code
      purchaseOrders {
        id
        code
        manufacturerId
        lineItems {
          id
        }
        vendor {
          id
          name
        }
        mostRecentSubmission {
          id
          result
          resultReceivedAt
          createdAt
        }
        purchaseOrderShipments {
          id
          expectedArrivalDate
          actualArrivalDate
          createdAt
        }
        inventoryItems {
          id
          createdAt
        }
        createdAt
      }
      project {
        id
        primaryContact {
          id
          ...FormatContactNameContactFragment
        }
        salesRepAccount {
          id
          name
        }
        designerAccount {
          id
          name
        }
        customerServiceRepAccount {
          id
          name
        }
      }
    }
  }
`;

type ReportDataItem = GetSalesOrderPurchasingReportResult["salesOrders"][0] & {
  primaryContactName: string;
  salesRepName: string;
  designerName: string;
  customerServiceRepName: string;
};

function DetailComponent({
  onRefetch,
  ...props
}: GridDetailRowProps & { onRefetch: () => {} }) {
  const [
    purchaseOrderIdToRecordShipmentFor,
    setPurchaseOrderIdToRecordShipmentFor,
  ] = useState<number>();

  const [
    purchaseOrderIdToRecordSubmissionFor,
    setPurchaseOrderIdToRecordSubmissionFor,
  ] = useState<number>();

  const [
    purchaseOrderSubmissionIdToRecordResultFor,
    setPurchaseOrderSubmissionIdToRecordResultFor,
  ] = useState<number>();

  const [shipmentIdToReceive, setShipmentIdToReceive] = useState<number>();

  const purchaseOrders = (props.dataItem as ReportDataItem).purchaseOrders.map(
    (po) => ({
      ...po,
      vendorName: po.vendor.name,
      projectId: props.dataItem.project.id,
    })
  );

  return (
    <>
      <RecordPurchaseOrderShipmentDrawer
        isOpen={!!purchaseOrderIdToRecordShipmentFor}
        onSubmit={() => {
          onRefetch();
          setPurchaseOrderIdToRecordShipmentFor(undefined);
        }}
        onClose={() => {
          setPurchaseOrderIdToRecordShipmentFor(undefined);
        }}
        purchaseOrderId={purchaseOrderIdToRecordShipmentFor}
      />

      <PurchaseOrderSubmissionDialog
        isOpen={!!purchaseOrderIdToRecordSubmissionFor}
        onSubmit={() => {
          onRefetch();
          setPurchaseOrderIdToRecordSubmissionFor(undefined);
        }}
        onClose={() => {
          setPurchaseOrderIdToRecordSubmissionFor(undefined);
        }}
        purchaseOrderId={purchaseOrderIdToRecordSubmissionFor}
      />

      <PurchaseOrderSubmissionResultDialog
        isOpen={!!purchaseOrderSubmissionIdToRecordResultFor}
        onSubmit={() => {
          onRefetch();
          setPurchaseOrderSubmissionIdToRecordResultFor(undefined);
        }}
        onClose={() => {
          setPurchaseOrderSubmissionIdToRecordResultFor(undefined);
        }}
        purchaseOrderSubmissionId={purchaseOrderSubmissionIdToRecordResultFor}
      />

      <ReceiveShipmentDrawer
        isOpen={!!shipmentIdToReceive}
        onReceived={() => {
          onRefetch();
          setShipmentIdToReceive(undefined);
        }}
        onClose={() => {
          setShipmentIdToReceive(undefined);
        }}
        shipmentId={shipmentIdToReceive}
      />

      <div>
        <h2 className="mb-2 font-medium">Purchase Orders</h2>

        <Grid data={purchaseOrders}>
          <GridColumn
            cell={(props) => {
              const dataItem = props.dataItem as typeof purchaseOrders[0];

              if (dataItem.inventoryItems.length > 0) {
                return (
                  <td>
                    <Icon icon="tick-circle" intent="success" />
                  </td>
                );
              }

              if (dataItem.mostRecentSubmission) {
                return (
                  <td>
                    <Icon icon="circle" intent="warning" />
                  </td>
                );
              }

              return (
                <td>
                  <Icon icon="circle" intent="none" />
                </td>
              );
            }}
            width={40}
          />

          <GridColumn
            field="code"
            title="PO #"
            cell={(props) => (
              <td>
                <EntityLink
                  url={`/projects/${props.dataItem.projectId}/purchase-orders/${props.dataItem.id}`}
                  text={props.dataItem.code}
                />
              </td>
            )}
          />

          <GridColumn field="vendorName" title="Vendor" />

          <GridColumn
            title="1. Creation"
            cell={(props) => {
              const dataItem = props.dataItem as typeof purchaseOrders[0];

              return (
                <td className="box-border">
                  <Tag fill minimal intent="success">
                    Created {formatDate(dataItem.createdAt)}
                  </Tag>
                </td>
              );
            }}
          />

          <GridColumn
            title="2. Submission"
            cell={(props) => {
              const dataItem = props.dataItem as typeof purchaseOrders[0];

              return (
                <td className="box-border space-y-1">
                  {!dataItem.mostRecentSubmission && (
                    <>
                      <Tag fill minimal intent="none">
                        Not Submitted
                      </Tag>
                      <Button
                        small
                        outlined
                        text="Record Submission"
                        fill
                        onClick={() =>
                          setPurchaseOrderIdToRecordSubmissionFor(dataItem.id)
                        }
                      />
                    </>
                  )}

                  {dataItem.mostRecentSubmission &&
                    !dataItem.mostRecentSubmission.result && (
                      <>
                        <Tag fill minimal intent="warning">
                          Submitted{" "}
                          {formatDate(dataItem.mostRecentSubmission.createdAt)}
                        </Tag>
                        <Button
                          small
                          outlined
                          text="Record Result"
                          fill
                          onClick={() =>
                            setPurchaseOrderSubmissionIdToRecordResultFor(
                              dataItem.mostRecentSubmission!.id
                            )
                          }
                        />
                      </>
                    )}

                  {dataItem.mostRecentSubmission &&
                    dataItem.mostRecentSubmission.result &&
                    dataItem.mostRecentSubmission.result ===
                      PurchaseOrderSubmissionResult.ChangesNeeded && (
                      <>
                        <Tag fill minimal intent="danger">
                          Changes Needed (
                          {formatDate(
                            dataItem.mostRecentSubmission.resultReceivedAt!
                          )}
                          )
                        </Tag>
                        <Button
                          small
                          outlined
                          text="Record Submission"
                          fill
                          onClick={() =>
                            setPurchaseOrderIdToRecordSubmissionFor(dataItem.id)
                          }
                        />
                      </>
                    )}

                  {dataItem.mostRecentSubmission &&
                    dataItem.mostRecentSubmission.result &&
                    dataItem.mostRecentSubmission.result ===
                      PurchaseOrderSubmissionResult.ErrorWhenSending && (
                      <>
                        <Tag fill minimal intent="danger">
                          Error Sending (
                          {formatDate(
                            dataItem.mostRecentSubmission.resultReceivedAt!
                          )}
                          )
                        </Tag>
                        <Button
                          small
                          outlined
                          text="Record Submission"
                          fill
                          onClick={() =>
                            setPurchaseOrderIdToRecordSubmissionFor(dataItem.id)
                          }
                        />
                      </>
                    )}

                  {dataItem.mostRecentSubmission &&
                    dataItem.mostRecentSubmission.result &&
                    dataItem.mostRecentSubmission.result ===
                      PurchaseOrderSubmissionResult.Confirmed && (
                      <Tag fill minimal intent="success">
                        Confirmed (
                        {formatDate(
                          dataItem.mostRecentSubmission.resultReceivedAt!
                        )}
                        )
                      </Tag>
                    )}
                </td>
              );
            }}
          />

          <GridColumn
            title="3. Shipping"
            cell={(props) => {
              const dataItem = props.dataItem as typeof purchaseOrders[0];

              return (
                <td className="space-y-1 box-border">
                  {dataItem.purchaseOrderShipments.length === 0 && (
                    <Link
                      className="w-full"
                      to={`/projects/${props.dataItem.projectId}/purchase-orders/${props.dataItem.id}/shipments`}
                    >
                      <Tag minimal fill intent="none">
                        No Shipments Recorded
                      </Tag>
                    </Link>
                  )}

                  {dataItem.purchaseOrderShipments.map((shipment) => (
                    <div
                      key={shipment.id}
                      className="flex items-center space-x-2 border rounded p-1"
                    >
                      <Link
                        to={`/projects/${dataItem.projectId}/purchase-orders/${dataItem.id}/shipments`}
                      >
                        SHIP-{shipment.id}
                      </Link>

                      {shipment.actualArrivalDate && (
                        <Tag minimal className="flex-1" intent="success">
                          Arrived {formatDate(shipment.actualArrivalDate)}
                        </Tag>
                      )}

                      {!shipment.actualArrivalDate &&
                        shipment.expectedArrivalDate && (
                          <Tag minimal className="flex-1" intent="warning">
                            Expected {formatDate(shipment.expectedArrivalDate)}
                          </Tag>
                        )}

                      {!shipment.actualArrivalDate &&
                        !shipment.expectedArrivalDate && (
                          <Tag minimal className="flex-1" intent="none">
                            Created {formatDate(shipment.createdAt)}
                          </Tag>
                        )}

                      <Button
                        small
                        outlined
                        text="Receive"
                        onClick={() => setShipmentIdToReceive(shipment.id)}
                      />
                    </div>
                  ))}

                  <Button
                    text="Record Shipments"
                    className="w-full"
                    onClick={() =>
                      setPurchaseOrderIdToRecordShipmentFor(props.dataItem.id)
                    }
                    small
                    outlined
                  />
                </td>
              );
            }}
          />

          <GridColumn
            title="4. Receiving"
            cell={(props) => {
              const dataItem = props.dataItem as typeof purchaseOrders[0];
              const earliestCreatedInventoryItem = [
                ...dataItem.inventoryItems,
              ].sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )[0];

              return (
                <td className="box-border">
                  {dataItem.inventoryItems.length === 0 && (
                    <Link
                      className="w-full"
                      to={`/projects/${props.dataItem.projectId}/purchase-orders/${props.dataItem.id}/inventory`}
                    >
                      <Tag minimal fill intent="none">
                        No Items Received
                      </Tag>
                    </Link>
                  )}

                  {dataItem.inventoryItems.length > 0 && (
                    <Link
                      className="w-full"
                      to={`/projects/${props.dataItem.projectId}/purchase-orders/${props.dataItem.id}/inventory`}
                    >
                      <Tag minimal fill intent="success">
                        {dataItem.inventoryItems.length} Item
                        {dataItem.inventoryItems.length > 1 ? "s" : ""} Received
                        (Starting{" "}
                        {formatDate(earliestCreatedInventoryItem.createdAt)})
                      </Tag>
                    </Link>
                  )}
                </td>
              );
            }}
          />
        </Grid>
      </div>
    </>
  );
}

export default function PurchasingDashboard() {
  const getSalesOrderPurchasingReport =
    useQuery<GetSalesOrderPurchasingReportResult>(
      GetSalesOrderPurchasingReport,
      { fetchPolicy: "cache-and-network" }
    );

  const [dataState, setDataState] = useReportDataState({
    sort: [{ field: "id", dir: "desc" }],
  });

  const { isExpanded, onExpandChange } = useExpandableRows();

  const salesOrders =
    getSalesOrderPurchasingReport.data?.salesOrders.map(
      (so) =>
        ({
          ...so,
          primaryContactName: formatContactName(so.project.primaryContact),
          salesRepName: formatAccountName(so.project.salesRepAccount),
          designerName: formatAccountName(so.project.designerAccount),
          customerServiceRepName: formatAccountName(
            so.project.customerServiceRepAccount
          ),
          expanded: isExpanded(so.id),
        } as ReportDataItem)
    ) ?? [];

  const [salesOrderToGenerate, setSalesOrderToGenerate] =
    useState<GetSalesOrderPurchasingReportResult["salesOrders"][0]>();

  return (
    <>
      <GeneratePurchaseOrdersDialog
        salesOrderId={salesOrderToGenerate?.id}
        isOpen={Boolean(salesOrderToGenerate)}
        onSubmit={() => {
          window.open(
            `/projects/${salesOrderToGenerate?.project.id}/purchase-orders`,
            "_blank"
          );
          setSalesOrderToGenerate(undefined);
        }}
        onClose={() => setSalesOrderToGenerate(undefined)}
      />

      <div className="flex flex-col h-full">
        <ProjectSubnavbar
          title="Purchasing"
          style={{ top: "unset" }}
          actions={
            <>
              <Popover minimal>
                <Button minimal icon="share" text="Share Report" />
                <ShareReportMenu dataState={dataState} />
              </Popover>
            </>
          }
        />

        <div className="h-full relative">
          {getSalesOrderPurchasingReport.loading ? (
            <Spinner />
          ) : (
            <Grid
              className="absolute top-0 bottom-0 w-full"
              data={salesOrders}
              {...dataState}
              onDataStateChange={(e) => setDataState(e.dataState)}
              detail={(props) => (
                <DetailComponent
                  {...props}
                  onRefetch={getSalesOrderPurchasingReport.refetch}
                />
              )}
              filterable
              sortable
              reorderable
              resizable
              expandField="expanded"
              onExpandChange={onExpandChange}
            >
              <GridColumn
                columnMenu={ReportColumnMenu}
                field="id"
                title="SO #"
                width={200}
                cell={(props) => (
                  <td>
                    <EntityLink
                      url={`/projects/${props.dataItem.project.id}/sales-orders/${props.dataItem.id}`}
                      text={props.dataItem.code}
                    />
                  </td>
                )}
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Primary Contact"
                field="primaryContactName"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Sales Rep"
                field="salesRepName"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Designer"
                field="designerName"
              />
              <GridColumn
                columnMenu={ReportColumnMenu}
                title="Customer Service Rep"
                field="customerServiceRepName"
              />
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
