import { Button, NonIdealState, Spinner } from "@blueprintjs/core";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CompanyAddressItem } from "./CompanyAddressItem";
import { ChooseAddressDialog } from "../../shared/AddressInputGroup/ChooseAddressDialog";
import { useState } from "react";
import {
  Address,
  CompanyAddressType,
  UpdateCompanyAddressPayload,
} from "../../../types/ApiTypes";

type GetCompanyAddressesResult = {
  companies: {
    id: number;
    companyAddresses: {
      id: number;
      type: CompanyAddressType;
      companyId: number;
      address: {
        id: number;
        street1: string;
        street2: string;
        city: string;
        state: string;
        postalCode: string;
        notes: string;
        addressSiteInformation?: {
          id: number;
          hasDock: boolean;
          numberOfInsideStairs: number;
          numberOfOutsideStairs: number;
          hasFreightElevator: boolean;
          hasPassengerElevator: boolean;
          isCertificateOfInsuranceRequired: boolean;
        };
      };
    }[];
  }[];
};

const GetCompanyAddresses = gql`
  query GetCompanyAddresses($id: Int!) {
    companies(where: { id: { eq: $id } }) {
      id
      companyAddresses {
        id
        type
        companyId
        address {
          id
          street1
          street2
          city
          state
          postalCode
          notes
          addressSiteInformation {
            id
            hasDock
            numberOfInsideStairs
            numberOfOutsideStairs
            hasFreightElevator
            hasPassengerElevator
            isCertificateOfInsuranceRequired
          }
        }
      }
    }
  }
`;

const AddAddressToCompany = gql`
  mutation AddAddressToCompany(
    $addressId: Int!
    $companyId: Int!
    $type: CompanyAddressType!
  ) {
    addAddressToCompany(
      addressId: $addressId
      companyId: $companyId
      type: $type
    ) {
      id
    }
  }
`;

const RemoveAddressFromCompany = gql`
  mutation RemoveAddressFromCompany($companyAddressId: Int!) {
    removeAddressFromCompany(companyAddressId: $companyAddressId) {
      id
    }
  }
`;

const UpdateCompanyAddress = gql`
  mutation UpdateCompanyAddress($payload: UpdateCompanyAddressPayloadInput!) {
    updateCompanyAddress(payload: $payload) {
      id
    }
  }
`;

export type CompanyAddressListProps = {
  companyId: number;
};

export function CompanyAddressList({ companyId }: CompanyAddressListProps) {
  const [isChooseOpen, setIsChooseOpen] = useState(false);

  const getCompanyAddresses = useQuery<
    GetCompanyAddressesResult,
    { id: number }
  >(GetCompanyAddresses, {
    variables: {
      id: companyId,
    },
    fetchPolicy: "cache-and-network",
  });

  const [addAddressToCompany] = useMutation<
    {
      addAddressToCompany: { id: number };
    },
    {
      addressId: number;
      companyId: number;
      type: CompanyAddressType;
    }
  >(AddAddressToCompany, {
    refetchQueries: ["GetCompanyAddresses"],
  });

  const [removeAddressFromCompany] = useMutation<
    {
      removeAddressFromCompany: { id: number };
    },
    {
      companyAddressId: number;
    }
  >(RemoveAddressFromCompany, {
    refetchQueries: ["GetCompanyAddresses"],
  });

  const [updateCompanyAddress] = useMutation<
    {
      updateCompanyAddress: { id: number };
    },
    {
      payload: UpdateCompanyAddressPayload;
    }
  >(UpdateCompanyAddress, {
    refetchQueries: ["GetCompanyAddresses"],
  });

  return (
    <>
      <ChooseAddressDialog
        isOpen={isChooseOpen}
        onClose={() => setIsChooseOpen(false)}
        onChoose={async (address: Address) => {
          await addAddressToCompany({
            variables: {
              companyId,
              addressId: address.id!,
              type: CompanyAddressType.Other,
            },
          });
          setIsChooseOpen(false);
        }}
      />

      <div className="flex flex-col border rounded overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          {getCompanyAddresses.loading && (
            <div className="p-20 text-center">
              <Spinner size={20} />
            </div>
          )}
          {!getCompanyAddresses.loading &&
            getCompanyAddresses.data!.companies[0].companyAddresses.length ===
              0 && (
              <div className="p-5">
                <NonIdealState icon="map" title="No Addresses" />
              </div>
            )}
          {!getCompanyAddresses.loading &&
            getCompanyAddresses.data!.companies[0].companyAddresses.length >
              0 &&
            getCompanyAddresses.data!.companies[0].companyAddresses.map((c) => (
              <CompanyAddressItem
                key={c.id}
                companyAddress={c}
                onChange={async (payload: UpdateCompanyAddressPayload) => {
                  await updateCompanyAddress({
                    variables: { payload },
                  });
                }}
                onRemove={async () => {
                  await removeAddressFromCompany({
                    variables: {
                      companyAddressId: c.id,
                    },
                  });
                }}
              />
            ))}
        </div>
        <div className="flex border-t items-center justify-between p-1 bg-gray-50">
          <Button icon="plus" onClick={() => setIsChooseOpen(true)} />
        </div>
      </div>
    </>
  );
}
