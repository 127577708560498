import { IItemRendererProps, Suggest } from "@blueprintjs/select";
import { MenuItem } from "@blueprintjs/core";
import { useState } from "react";

export type PropertyCellProps = {
  value: string;
  onChange: (newValue: string) => void;
  availableProperties: string[];
};

export function PropertyCell({
  value,
  onChange,
  availableProperties,
}: PropertyCellProps) {
  const renderItem = (item: string, itemProps: IItemRendererProps) => {
    return (
      <MenuItem
        text={item}
        active={itemProps.modifiers.active}
        disabled={itemProps.modifiers.disabled}
        onClick={itemProps.handleClick}
      />
    );
  };

  const filterItem = (query: string, item: string) => {
    const normalizedQuery = query.trim().toLowerCase();
    const normalizedItem = item.trim().toLowerCase();
    return normalizedItem.includes(normalizedQuery);
  };

  return (
    <Suggest
      selectedItem={value}
      inputProps={{ placeholder: "Property" }}
      items={availableProperties}
      itemRenderer={renderItem}
      itemPredicate={filterItem}
      noResults={() => <MenuItem disabled title="No Result" />}
      inputValueRenderer={(i) => i}
      popoverProps={{ minimal: true }}
      onItemSelect={onChange}
      fill
    />
  );
}
