import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import React from "react";
import { Spinner } from "@blueprintjs/core";
import { gql, useQuery } from "@apollo/client";
import { Contact, Account } from "../../../../types/ApiTypes";

import { MainProjectContacts } from "./MainProjectContacts";
import { TeamProjectContacts } from "./TeamProjectContacts";

export type ProjectContactsRouteParams = {
  projectId: string;
};

type GetProjectByIdResult = {
  projects: {
    id: number;
    name: string;
    primaryContact?: Contact;
    billingContact?: Contact;
    fulfillmentContact?: Contact;
    salesRepAccount?: {
      id: number;
      name: string;
      email: string;
    };
    designerAccount?: {
      id: number;
      name: string;
      email: string;
    };
    customerServiceRepAccount?: {
      id: number;
      name: string;
      email: string;
    };
  }[];
};

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      billingContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      fulfillmentContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      salesRepAccount {
        id
        name
        email
      }
      designerAccount {
        id
        name
        email
      }
      customerServiceRepAccount {
        id
        name
        email
      }
    }
  }
`;

export function ProjectContacts() {
  const params = useParams<ProjectContactsRouteParams>();
  const projectId = parseInt(params.projectId);

  const getProjectById = useQuery<GetProjectByIdResult, { projectId: number }>(
    GetProjectById,
    { variables: { projectId } }
  );

  return (
    <>
      <Helmet>
        <title>{`Contacts - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Contacts", href: `/projects/${projectId}/contacts` },
        ]}
      />

      <div className="bg-gray-100 h-full">
        {getProjectById.loading ? (
          <Spinner />
        ) : (
          <>
            <MainProjectContacts project={getProjectById.data!.projects[0]} />
            <TeamProjectContacts project={getProjectById.data!.projects[0]} />
          </>
        )}
      </div>
    </>
  );
}
