import React, { Reducer, useEffect, useReducer, useState } from "react";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { useHistory, useParams } from "react-router-dom";
import {
  Account,
  Address,
  CommitSalesOrderDraftPayloadInput,
  CompanyAddressType,
  Contact,
  PersonAddressType,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { flatten, parseInt } from "lodash";
import {
  Button,
  FormGroup,
  Intent,
  Position,
  Spinner,
  Tooltip,
} from "@blueprintjs/core";
import { SalesOrderSubnavbar } from "../../../../../components/projects/[projectId]/sales-orders/[salesOrderId]/SalesOrderSubnavbar";
import { formatPrice } from "../../../../../helpers/PriceHelper";
import { GeneratePurchaseOrdersDialog } from "../../../../../components/projects/[projectId]/sales-orders/[salesOrderId]/GeneratePurchaseOrders/GeneratePurchaseOrdersDialog";
import classNames from "classnames";
import {
  SalesOrderEditorActions,
  SalesOrderEditorState,
} from "../../../../../components/shared/LineItemEditor/reducers/SalesOrderEditor/SalesOrderEditorTypes";
import { QuickSettings } from "../../../../../components/shared/QuickSettings/QuickSettings";
import { DefaultToaster } from "../../../../../components/shared/DefaultToaster/DefaultToaster";
import { QuickSettingItem } from "../../../../../components/shared/QuickSettings/QuickSettingItem";
import { DateInput } from "@blueprintjs/datetime";
import { formatDate } from "../../../../../helpers/DateHelper";
import FufillmentDateNotes from "../../../../../components/shared/FufillmentDateNotes";
import { ExportSalesOrderToQuickbooksDialog } from "../../../../../components/projects/[projectId]/sales-orders/ExportSalesOrderToQuickbooksDialog";
import { FormatContactNameContactFragment } from "../../../../../helpers/ContactHelper";
import {
  GetFullConfigurationCodeFragment,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import * as baseActions from "../../../../../components/shared/LineItemEditor/reducers/LineItemEditor/LineItemEditorActions";
import * as actions from "../../../../../components/shared/LineItemEditor/reducers/SalesOrderEditor/SalesOrderEditorActions";
import { ErrorDialog } from "../../../../../components/shared/ErrorDialog/ErrorDialog";
import { UnsavedChangesWarning } from "../../../../../components/shared/LineItemEditor/UnsavedChangesWarning";
import { LineItemEditor } from "../../../../../components/shared/LineItemEditor/LineItemEditor";
import { buildCommitSalesOrderDraftPayload } from "../../../../../components/shared/LineItemEditor/helpers/SalesOrderEditorHelpers";
import { Helmet } from "react-helmet";
import {
  SalesOrderEditorColumn,
  SalesOrderVisibleColumnMenu,
} from "./SalesOrderVisibleColumnMenu";
import {
  ContactInputGroup,
  ContactInputGroupContact,
} from "../../../../../components/shared/ContactInputGroup";
import { AddressInputGroup } from "../../../../../components/shared/AddressInputGroup/AddressInputGroup";
import { AccountInputGroup } from "../../../../../components/shared/AccountInputGroup";
import { updatePrimaryContactAndApplyDefaults } from "../../../../../components/shared/LineItemEditor/helpers/LineItemEditorHelpers";

type RecalculateSalesOrderTaxParams = {
  salesOrderId: number;
};

const RecalculateSalesOrderTax = gql`
  mutation RecalculateSalesOrderTax($salesOrderId: Int!) {
    recalculateSalesOrderTax(salesOrderId: $salesOrderId)
  }
`;

type CommitSalesOrderDraftParams = {
  input: CommitSalesOrderDraftPayloadInput;
};

type CommitSalesOrderDraftResult = {
  commitSalesOrderDraft: {
    createdSalesOrderLineItems: {
      id: number;
    }[];
  };
};

const CommitSalesOrderDraft = gql`
  mutation CommitSalesOrderDraft($input: CommitSalesOrderDraftPayloadInput!) {
    commitSalesOrderDraft(payload: $input) {
      createdSalesOrderLineItems {
        id
      }
    }
  }
`;

type GetSalesOrderDetailsParams = {
  salesOrderId: number;
};

type GetSalesOrderDetailsResult = {
  salesOrders: {
    id: number;
    code: string;
    quickbooksSalesOrderId?: string;
    requestedFulfillmentDate?: string;
    requestedFulfillmentDateNotes?: string;
    requestedFulfillmentDateFlagged?: boolean;
    project: {
      id: number;
      taxOriginAddressId?: number;
      taxDestinationAddressId?: number;
      primaryContact?: Contact;
      fulfillmentContact?: Contact;
      billingAddress?: Address;
      shipToAddress?: Address;
      salesRepAccount?: Account;
    };
    salesOrderLineItemGroups: {
      id: number;
      name: string;
      sortIndex: number;
      salesOrderLineItems: {
        id: number;
        sortIndex: number;
        discountingStructure: string;
        listPriceMultiplier: number;
        grossProfitPercent: number;
        sellPrice: number;
        taxAmount: number;
        taxCode: string;
        quantity: number;
        description: string;
        internalDescription: string;
        configuration?: ProductConfiguration;
      }[];
    }[];
  }[];
};

export const GetSalesOrderDetails = gql`
  ${FormatContactNameContactFragment}
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetSalesOrderDetails($salesOrderId: Int!) {
    salesOrders(where: { id: { eq: $salesOrderId } }) {
      id
      code
      quickbooksSalesOrderId
      requestedFulfillmentDate
      requestedFulfillmentDateNotes
      requestedFulfillmentDateFlagged
      project {
        id
        taxOriginAddressId
        taxDestinationAddressId
        primaryContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
          }
          company {
            id
            name
          }
          ...FormatContactNameContactFragment
        }
        fulfillmentContact {
          id
          person {
            id
            firstName
            lastName
            email
            phone
          }
          company {
            id
            name
          }
          ...FormatContactNameContactFragment
        }
        billingAddress {
          id
          street1
          street2
          city
          state
          postalCode
        }
        shipToAddress {
          id
          street1
          street2
          city
          state
          postalCode
        }
        salesRepAccount {
          id
          name
          email
        }
      }
      salesOrderLineItemGroups {
        id
        name
        sortIndex
        salesOrderLineItems(order: { sortIndex: ASC }) {
          id
          sortIndex
          discountingStructure
          listPriceMultiplier
          grossProfitPercent
          sellPrice
          taxAmount
          taxCode
          quantity
          description
          internalDescription
          configuration {
            id
            isCustomConfiguration
            customCode
            customDescription
            customListPrice
            customVendorId
            catalogProductId
            catalogProduct {
              id
              code
              description
            }
            features {
              id
              catalogOptionGroupId
              catalogOptionGroup {
                id
                description
              }
              selections {
                id
                catalogOptionId
                catalogOption {
                  id
                  code
                  description
                }
                catalogOptionGroupId
                catalogOptionGroup {
                  id
                  code
                  description
                }
              }
            }
            vendor {
              id
              name
              freightRules
            }
            ...GetFullConfigurationCodeFragment
            ...GetFullConfigurationDescriptionFragment
          }
        }
      }
    }
  }
`;

export default function SalesOrderDetails() {
  const history = useHistory();
  const apolloClient = useApolloClient();

  const { projectId, salesOrderId } =
    useParams<{ projectId: string; salesOrderId: string }>();

  const [
    generatePurchaseOrdersSalesOrderId,
    setGeneratePurchaseOrdersSalesOrderId,
  ] = useState<number>();
  const [saveError, setSaveError] = useState<Error>();
  const [isExportSalesOrderOpen, setIsExportSalesOrderOpen] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState<
    SalesOrderEditorColumn[]
  >([
    "product",
    "description",
    "quantity",
    "vendorListPrice",
    "discountListPriceMultiplier",
    "purchasePrice",
    "grossProfitPercent",
    "suggestedSellPrice",
    "actualSellPrice",
    "tax",
    "totalSellPrice",
  ]);

  const [state, dispatch] = useReducer<
    Reducer<SalesOrderEditorState, SalesOrderEditorActions>,
    SalesOrderEditorState
  >(
    (state, action) =>
      (actions[action.type] ?? baseActions[action.type])(state, action),
    {
      quickSettings: { isModified: false },
      salesOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    },
    () => ({
      quickSettings: { isModified: false },
      salesOrderCode: "",
      lineItemGroups: [],
      selectedItems: [],
    })
  );

  const isModified =
    state.quickSettings.isModified ||
    state.lineItemGroups.some(
      (lig) =>
        lig.isModified ||
        lig.isCreated ||
        lig.isRemoved ||
        lig.lineItems.some(
          (li) => li.isModified || li.isCreated || li.isRemoved
        )
    );

  const getSalesOrderDetails = useQuery<
    GetSalesOrderDetailsResult,
    GetSalesOrderDetailsParams
  >(GetSalesOrderDetails, {
    variables: {
      salesOrderId: parseInt(salesOrderId),
    },
    fetchPolicy: "cache-and-network",
  });

  const [commitSalesOrderDraft, { loading: committingSalesOrderDraft }] =
    useMutation<CommitSalesOrderDraftResult, CommitSalesOrderDraftParams>(
      CommitSalesOrderDraft,
      { refetchQueries: [GetSalesOrderDetails] }
    );

  const [recalculateSalesOrderTax] = useMutation<
    { recalculateSalesOrderTax: boolean },
    RecalculateSalesOrderTaxParams
  >(RecalculateSalesOrderTax, {
    refetchQueries: [GetSalesOrderDetails],
  });

  useEffect(() => {
    if (getSalesOrderDetails.loading) return;
    dispatch({
      type: "initializeWithSalesOrder",
      salesOrder: getSalesOrderDetails.data!.salesOrders[0],
    });
  }, [getSalesOrderDetails.data, getSalesOrderDetails.loading]);

  async function saveChanges() {
    try {
      const payload = buildCommitSalesOrderDraftPayload(
        parseInt(salesOrderId),
        state
      );
      await commitSalesOrderDraft({ variables: { input: payload } });
    } catch (err) {
      setSaveError(err);
    }
  }

  async function recalculateTax() {
    if (isModified) {
      await saveChanges();
    }

    await recalculateSalesOrderTax({
      variables: { salesOrderId: parseInt(salesOrderId) },
    });

    DefaultToaster.show({
      message: "Sales tax recalculated!",
      intent: "success",
      icon: "tick-circle",
    });
  }

  if (getSalesOrderDetails.loading) {
    return (
      <PortalContainer>
        <Spinner />
      </PortalContainer>
    );
  }

  const salesOrder = getSalesOrderDetails.data!.salesOrders[0];

  const missingTaxAddress =
    !salesOrder?.project.taxOriginAddressId ||
    !salesOrder?.project.taxDestinationAddressId;

  const allLineItems = salesOrder?.salesOrderLineItemGroups
    ? flatten(
        salesOrder.salesOrderLineItemGroups.map((g) => g.salesOrderLineItems!)
      )
    : [];
  const subtotal = allLineItems
    .map((li) => li.sellPrice * li.quantity)
    .reduce((x, y) => x + y, 0);
  const tax = allLineItems.map((li) => li.taxAmount).reduce((x, y) => x + y, 0);
  const grandTotal = subtotal + tax;

  return (
    <>
      <Helmet>
        <title>{salesOrder.code}</title>
      </Helmet>

      <ErrorDialog
        isOpen={!!getSalesOrderDetails.error}
        error={getSalesOrderDetails.error}
      />

      <ErrorDialog
        isOpen={!!saveError}
        error={saveError}
        onClose={() => setSaveError(undefined)}
      />

      <ExportSalesOrderToQuickbooksDialog
        isOpen={isExportSalesOrderOpen}
        salesOrderId={parseInt(salesOrderId)}
        onClose={() => setIsExportSalesOrderOpen(false)}
      />

      <GeneratePurchaseOrdersDialog
        isOpen={Boolean(generatePurchaseOrdersSalesOrderId)}
        salesOrderId={generatePurchaseOrdersSalesOrderId}
        onSubmit={() => {
          setGeneratePurchaseOrdersSalesOrderId(undefined);
          history.push(`/projects/${projectId}/purchase-orders`);
        }}
        onClose={() => setGeneratePurchaseOrdersSalesOrderId(undefined)}
      />

      <ProjectNavbar
        projectLoading={getSalesOrderDetails.loading}
        projectName={`P-${salesOrder.project.id}`}
        projectPrimaryContact={salesOrder.project.primaryContact}
        projectId={parseInt(projectId)}
        additionalHeaderItems={[
          { text: "Sales Orders", href: `/projects/${projectId}/sales-orders` },
          {
            text: salesOrder?.code ?? "...",
            href: `/projects/${projectId}/sales-orders/${salesOrderId}`,
          },
        ]}
      />

      <SalesOrderSubnavbar
        title={salesOrder.code}
        loading={getSalesOrderDetails.loading}
        actions={
          <>
            <Button
              text="Generate Purchase Orders"
              onClick={async () => {
                if (isModified) await saveChanges();
                setGeneratePurchaseOrdersSalesOrderId(salesOrder.id);
              }}
            />
            <Button
              text={
                salesOrder?.quickbooksSalesOrderId
                  ? "Exported to Quickbooks"
                  : "Export to Quickbooks"
              }
              icon={
                salesOrder?.quickbooksSalesOrderId ? "tick-circle" : "export"
              }
              intent={salesOrder?.quickbooksSalesOrderId ? "success" : "none"}
              onClick={async () => {
                if (isModified) await saveChanges();
                setIsExportSalesOrderOpen(true);
              }}
            />
            <Tooltip
              content="Print or Export Sales Order"
              position={Position.BOTTOM}
            >
              <Button
                icon="print"
                minimal
                onClick={async () => {
                  if (isModified) await saveChanges();
                  window.open(
                    `/projects/${projectId}/sales-orders/${salesOrderId}/printable`
                  );
                }}
              />
            </Tooltip>

            <SalesOrderVisibleColumnMenu
              visibleColumns={visibleColumns}
              onShowColumn={(c) => {
                if (!visibleColumns.includes(c))
                  setVisibleColumns([...visibleColumns, c]);
              }}
              onHideColumn={(c) => {
                if (visibleColumns.includes(c))
                  setVisibleColumns(visibleColumns.filter((x) => x !== c));
              }}
            />
          </>
        }
      />

      {isModified && (
        <UnsavedChangesWarning
          resourceType={"sales order"}
          onSave={saveChanges}
          saving={committingSalesOrderDraft}
        />
      )}

      <QuickSettings>
        <FormGroup label="Primary Contact">
          <ContactInputGroup
            value={state.quickSettings.primaryContact}
            onChange={(v) =>
              updatePrimaryContactAndApplyDefaults({
                state,
                dispatch,
                primaryContact: v,
                apolloClient,
              })
            }
          />
        </FormGroup>

        <FormGroup label="Billing Address">
          <AddressInputGroup
            value={state.quickSettings.billingAddress}
            onChange={(v) =>
              dispatch({ type: "setBillingAddress", billingAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Ship To Address">
          <AddressInputGroup
            value={state.quickSettings.shipToAddress}
            onChange={(v) =>
              dispatch({ type: "setShipToAddress", shipToAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Sales Rep">
          <AccountInputGroup
            value={state.quickSettings.salesRepAccount}
            onChange={(v) =>
              dispatch({ type: "setSalesRepAccount", salesRepAccount: v })
            }
          />
        </FormGroup>

        <FormGroup label="Requested Fulfillment Date">
          <QuickSettingItem>
            <div className="space-x-1">
              <DateInput
                value={state.quickSettings.requestedFulfillmentDate}
                onChange={(v) =>
                  dispatch({
                    type: "setRequestedFulfillmentDate",
                    requestedFulfillmentDate: v,
                  })
                }
                parseDate={(d) => new Date(d)}
                formatDate={formatDate}
              />

              <FufillmentDateNotes
                flagged={state.quickSettings.requestedFulfillmentDateFlagged}
                body={state.quickSettings.requestedFulfillmentDateNotes}
                onSubmit={(status) => {
                  dispatch({
                    type: "setRequestedFulfillmentDateFlagged",
                    requestedFulfillmentDateFlagged: status.flagged,
                  });
                  dispatch({
                    type: "setRequestedFulfillmentDateNotes",
                    requestedFulfillmentDateNotes: status.body,
                  });
                }}
              />
            </div>
          </QuickSettingItem>
        </FormGroup>
      </QuickSettings>

      <PortalContainer>
        <div className="space-y-2">
          <LineItemEditor<SalesOrderEditorState, SalesOrderEditorActions>
            visibleDefaultColumns={visibleColumns}
            readOnly={false}
            state={state}
            dispatch={dispatch}
            shipToAddressId={salesOrder.project.shipToAddress?.id}
            fulfillmentContactId={
              salesOrder.project.fulfillmentContact?.id ??
              salesOrder.project.primaryContact?.id
            }
          />

          <div className="flex justify-end">
            <div className="space-y-1 w-80 rounded border py-2">
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Subtotal</p>
                <p className="font-bold m-0">{formatPrice(subtotal)}</p>
              </div>
              <div className="flex items-center space-x-6 pr-2">
                <p className="w-40 m-0 text-right">Tax</p>
                <p
                  className={classNames("font-bold m-0 flex-1", {
                    "text-yellow-600":
                      !getSalesOrderDetails.loading && missingTaxAddress,
                  })}
                >
                  {formatPrice(tax)}
                </p>
                {!getSalesOrderDetails.loading && missingTaxAddress ? (
                  <Tooltip
                    content="Tax can't be calculated because origin or destination address is missing."
                    position={Position.BOTTOM}
                  >
                    <Button
                      minimal
                      small
                      icon="warning-sign"
                      intent={Intent.WARNING}
                      onClick={() =>
                        history.push(`/projects/${projectId}/settings`)
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip content="Recalculate Tax" position={Position.BOTTOM}>
                    <Button
                      minimal
                      small
                      icon="refresh"
                      onClick={recalculateTax}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="py-1">
                <hr />
              </div>
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Grand Total</p>
                <p className="font-bold m-0">{formatPrice(grandTotal)}</p>
              </div>
            </div>
          </div>
        </div>
      </PortalContainer>
    </>
  );
}
