import React from "react";
import styles from "./ProjectSubnavbar.module.scss";
import classNames from "classnames";
import { Spinner } from "@blueprintjs/core";

export type ProjectSubnavbarProps = {
  title: string;
  loading?: boolean;
  leftActions?: React.ReactNode | React.ReactNode[];
  actions?: React.ReactNode | React.ReactNode[];
  tabs?: React.ReactNode | React.ReactNode[];
  className?: string;
  style?: React.CSSProperties;
};

export function ProjectSubnavbar({
  title,
  loading = false,
  actions,
  leftActions,
  tabs,
  className,
  style,
}: ProjectSubnavbarProps) {
  return (
    <div
      className={classNames(styles.projectSubnavbar, className)}
      style={style}
    >
      <h3>{title}</h3>

      {leftActions && (
        <div className="flex items-center space-x-2 px-4">{leftActions}</div>
      )}

      <span className="flex-1" />

      <div className="flex items-center">
        {loading && (
          <div className="flex items-center mr-2">
            <Spinner size={20} />
            <p className="m-0 ml-2">Loading...</p>
          </div>
        )}
        <div
          className={classNames("flex items-center space-x-2", {
            "mr-2": !tabs,
            "mr-10": !!tabs,
          })}
        >
          {actions}
        </div>
        {tabs && <div className="flex">{tabs}</div>}
      </div>
    </div>
  );
}
