import React, { Reducer, useEffect, useReducer, useState } from "react";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { useHistory, useParams } from "react-router-dom";
import { QuoteSubnavbar } from "../../../../../components/projects/[projectId]/quotes/[quoteId]/QuoteSubnavbar";
import {
  Account,
  Address,
  CommitQuoteDraftPayloadInput,
  Contact,
  ProductConfiguration,
} from "../../../../../types/ApiTypes";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { flatten, parseInt } from "lodash";
import {
  Button,
  Callout,
  Classes,
  Dialog,
  FormGroup,
  Intent,
  Position,
  Spinner,
  Tooltip,
} from "@blueprintjs/core";
import { ExportQuoteToQuickbooksDialog } from "../../../../../components/projects/[projectId]/quotes/ExportQuoteToQuickbooksDialog";
import { FormatContactNameContactFragment } from "../../../../../helpers/ContactHelper";
import { QuoteConvertedWarning } from "../../../../../components/projects/[projectId]/quotes/[quoteId]/QuoteConvertedWarning";
import {
  GetFullConfigurationCodeFragment,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";
import {
  QuoteEditorActions,
  QuoteEditorState,
} from "../../../../../components/shared/LineItemEditor/reducers/QuoteEditor/QuoteEditorTypes";
import * as baseActions from "../../../../../components/shared/LineItemEditor/reducers/LineItemEditor/LineItemEditorActions";
import * as actions from "../../../../../components/shared/LineItemEditor/reducers/QuoteEditor/QuoteEditorActions";
import { buildCommitQuoteDraftPayload } from "../../../../../components/shared/LineItemEditor/helpers/QuoteEditorHelpers";
import { ErrorDialog } from "../../../../../components/shared/ErrorDialog/ErrorDialog";
import { QuickSettings } from "../../../../../components/shared/QuickSettings/QuickSettings";
import { QuickSettingItem } from "../../../../../components/shared/QuickSettings/QuickSettingItem";
import { DateInput } from "@blueprintjs/datetime";
import FufillmentDateNotes from "../../../../../components/shared/FufillmentDateNotes";
import { DefaultToaster } from "../../../../../components/shared/DefaultToaster/DefaultToaster";
import { formatDate } from "../../../../../helpers/DateHelper";
import {
  calculateSIFSalesPrice,
  formatPrice,
} from "../../../../../helpers/PriceHelper";
import classNames from "classnames";
import { UnsavedChangesWarning } from "../../../../../components/shared/LineItemEditor/UnsavedChangesWarning";
import { LineItemEditor } from "../../../../../components/shared/LineItemEditor/LineItemEditor";
import { useDecodeSifLineItems } from "../../../../../hooks/useDecodeSifLineItems";
import { Helmet } from "react-helmet";
import {
  QuoteEditorColumn,
  QuoteVisibleColumnMenu,
} from "./QuoteVisibleColumnMenu";
import { ContactInputGroup } from "../../../../../components/shared/ContactInputGroup";
import { AddressInputGroup } from "../../../../../components/shared/AddressInputGroup/AddressInputGroup";
import { AccountInputGroup } from "../../../../../components/shared/AccountInputGroup";
import { updatePrimaryContactAndApplyDefaults } from "../../../../../components/shared/LineItemEditor/helpers/LineItemEditorHelpers";

type RecalculateQuoteTaxParams = {
  quoteId: number;
};

const RecalculateQuoteTax = gql`
  mutation RecalculateQuoteTax($quoteId: Int!) {
    recalculateQuoteTax(quoteId: $quoteId)
  }
`;

type CommitQuoteDraftParams = {
  input: CommitQuoteDraftPayloadInput;
};

type CommitQuoteDraftResult = {
  commitQuoteDraft: {
    createdQuoteLineItems: {
      id: number;
    }[];
  };
};

const CommitQuoteDraft = gql`
  mutation CommitQuoteDraft($input: CommitQuoteDraftPayloadInput!) {
    commitQuoteDraft(payload: $input) {
      createdQuoteLineItems {
        id
      }
    }
  }
`;

type GetQuoteDetailsParams = {
  quoteId: number;
};

type GetQuoteDetailsResult = {
  quotes: {
    id: number;
    code: string;
    requestedFulfillmentDate?: string;
    requestedFulfillmentDateNotes?: string;
    requestedFulfillmentDateFlagged?: boolean;
    quickbooksEstimateId?: string;
    quoteVersionGroup: {
      id: number;
      project: {
        id: number;
        taxOriginAddressId?: number;
        taxDestinationAddressId?: number;
        primaryContact?: Contact;
        fulfillmentContact?: Contact;
        billingAddress?: Address;
        shipToAddress?: Address;
        salesRepAccount?: Account;
      };
    };
    salesOrder?: {
      id: number;
      code: string;
    };
    quoteLineItemGroups: {
      id: number;
      name: string;
      sortIndex: number;
      quoteLineItems: {
        id: number;
        sortIndex: number;
        discountingStructure: string;
        listPriceMultiplier: number;
        grossProfitPercent: number;
        sellPrice: number;
        taxAmount: number;
        taxCode: string;
        quantity: number;
        description: string;
        internalDescription: string;
        configuration?: ProductConfiguration;
      }[];
    }[];
  }[];
};

const GetQuoteDetails = gql`
  ${FormatContactNameContactFragment}
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetQuoteDetails($quoteId: Int!) {
    quotes(where: { id: { eq: $quoteId } }) {
      id
      code
      quickbooksEstimateId
      requestedFulfillmentDate
      requestedFulfillmentDateNotes
      requestedFulfillmentDateFlagged
      quoteVersionGroup {
        id
        project {
          id
          taxOriginAddressId
          taxDestinationAddressId
          primaryContact {
            id
            person {
              id
              firstName
              lastName
              email
              phone
            }
            company {
              id
              name
            }
            ...FormatContactNameContactFragment
          }
          fulfillmentContact {
            id
            person {
              id
              firstName
              lastName
              email
              phone
            }
            company {
              id
              name
            }
            ...FormatContactNameContactFragment
          }
          billingAddress {
            id
            street1
            street2
            city
            state
            postalCode
          }
          shipToAddress {
            id
            street1
            street2
            city
            state
            postalCode
          }
          salesRepAccount {
            id
            name
            email
          }
        }
      }
      salesOrder {
        id
        code
      }
      quoteLineItemGroups(order: { sortIndex: ASC }) {
        id
        name
        sortIndex
        quoteLineItems(order: { sortIndex: ASC }) {
          id
          sortIndex
          discountingStructure
          listPriceMultiplier
          grossProfitPercent
          sellPrice
          taxAmount
          taxCode
          quantity
          description
          internalDescription
          configuration {
            id
            isCustomConfiguration
            customCode
            customDescription
            customListPrice
            customVendorId
            catalogProductId
            catalogProduct {
              id
              code
              description
            }
            features {
              id
              catalogOptionGroupId
              catalogOptionGroup {
                id
                description
              }
              selections {
                id
                catalogOptionId
                catalogOption {
                  id
                  code
                  description
                }
                catalogOptionGroupId
                catalogOptionGroup {
                  id
                  code
                  description
                }
              }
            }
            vendor {
              id
              name
              freightRules
            }
            ...GetFullConfigurationCodeFragment
            ...GetFullConfigurationDescriptionFragment
          }
        }
      }
    }
  }
`;

function chooseFile(): Promise<File> {
  return new Promise((resolve) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    document.body.appendChild(fileInput);

    fileInput.addEventListener("change", (e) => {
      const files = (e.target as HTMLInputElement).files;
      if (files != null && files.length > 0) {
        resolve(files[0]);
      }
      fileInput.remove();
    });

    fileInput.click();
  });
}

function readFileAsText(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        resolve(reader.result as string);
      },
      false
    );

    reader.readAsText(file);
  });
}

export default function QuoteDetails() {
  const history = useHistory();
  const apolloClient = useApolloClient();
  const [decodeSifLineItems] = useDecodeSifLineItems();

  const { projectId, quoteId } =
    useParams<{ projectId: string; quoteId: string }>();

  const [isEditOverridden, setIsEditOverridden] = useState(false);
  const [isExportQuoteOpen, setIsExportQuoteOpen] = useState(false);
  const [isCurrentlyImporting, setIsCurrentlyImporting] = useState(false);
  const [importSIFForLineItemGroupIndex, setImportSIFForLineItemGroupIndex] =
    useState<number>();
  const [saveError, setSaveError] = useState<Error>();

  const [visibleColumns, setVisibleColumns] = useState<QuoteEditorColumn[]>([
    "product",
    "description",
    "quantity",
    "vendorListPrice",
    "discountListPriceMultiplier",
    "purchasePrice",
    "grossProfitPercent",
    "suggestedSellPrice",
    "actualSellPrice",
    "tax",
    "totalSellPrice",
  ]);

  const [state, dispatch] = useReducer<
    Reducer<QuoteEditorState, QuoteEditorActions>,
    QuoteEditorState
  >(
    (state, action) =>
      (actions[action.type] ?? baseActions[action.type])(state, action),
    {
      quickSettings: { isModified: false },
      quoteCode: "",
      lineItemGroups: [],
      selectedItems: [],
    },
    () => ({
      quickSettings: { isModified: false },
      quoteCode: "",
      lineItemGroups: [],
      selectedItems: [],
    })
  );

  const isModified =
    state.quickSettings.isModified ||
    state.lineItemGroups.some(
      (lig) =>
        lig.isModified ||
        lig.isCreated ||
        lig.isRemoved ||
        lig.lineItems.some(
          (li) => li.isModified || li.isCreated || li.isRemoved
        )
    );

  const getQuoteDetails = useQuery<
    GetQuoteDetailsResult,
    GetQuoteDetailsParams
  >(GetQuoteDetails, {
    variables: {
      quoteId: parseInt(quoteId),
    },
    fetchPolicy: "cache-and-network",
  });

  const [commitQuoteDraft, { loading: committingQuoteDraft }] = useMutation<
    CommitQuoteDraftResult,
    CommitQuoteDraftParams
  >(CommitQuoteDraft, { refetchQueries: [GetQuoteDetails] });

  const [recalculateQuoteTax] = useMutation<
    { recalculateQuoteTax: boolean },
    RecalculateQuoteTaxParams
  >(RecalculateQuoteTax, {
    refetchQueries: [GetQuoteDetails],
  });

  useEffect(() => {
    if (getQuoteDetails.loading) return;
    dispatch({
      type: "initializeWithQuote",
      quote: getQuoteDetails.data!.quotes[0],
    });
  }, [getQuoteDetails.data, getQuoteDetails.loading]);

  async function saveChanges() {
    try {
      const payload = buildCommitQuoteDraftPayload(parseInt(quoteId), state);
      await commitQuoteDraft({ variables: { input: payload } });
    } catch (err) {
      setSaveError(err);
    }
  }

  async function recalculateTax() {
    if (isModified) {
      await saveChanges();
    }

    await recalculateQuoteTax({ variables: { quoteId: parseInt(quoteId) } });

    DefaultToaster.show({
      message: "Quote sales tax recalculated!",
      intent: "success",
      icon: "tick-circle",
    });
  }

  async function importSIF(lineItemGroupIndex: number) {
    const file = await chooseFile();
    const contents = await readFileAsText(file);

    setIsCurrentlyImporting(true);

    const decoding = await decodeSifLineItems({
      variables: {
        contents: contents,
      },
    });

    const lines = decoding.data!.decodeSifLineItems;

    dispatch({
      type: "importSIF",
      lineItemGroupIndex,
      lineItems: lines.map((line) => ({
        sifEntry: line,
        quantity: line.quantity,
        description: line.customDescription,
        sellPrice: calculateSIFSalesPrice(line),
        configuration:
          line.decodings.length > 0
            ? {
                catalogProductCode: line.decodings[0].catalogProductCode,
                catalogProductDescription:
                  line.decodings[0].catalogProductDescription,
                catalogProductId: line.decodings[0].catalogProductId,

                features: line.decodings[0].features.map((feature) => ({
                  selections: feature.selections.map((selection) => ({
                    catalogOptionId: selection.optionId,
                    catalogOptionCode: selection.optionCode,
                    catalogOptionDescription: selection.optionDescription,
                    catalogOptionGroupId: selection.optionGroupId,
                    catalogOptionGroupCode: selection.optionGroupCode,
                    catalogOptionGroupDescription:
                      selection.optionGroupDescription,
                  })),
                })),
              }
            : undefined,
      })),
    });

    setIsCurrentlyImporting(false);
  }

  if (getQuoteDetails.loading) {
    return (
      <PortalContainer>
        <Spinner />
      </PortalContainer>
    );
  }

  const quote = getQuoteDetails.data!.quotes[0];

  const missingTaxAddress =
    !quote?.quoteVersionGroup!.project!.taxOriginAddressId ||
    !quote?.quoteVersionGroup!.project!.taxDestinationAddressId;
  const allLineItems = quote?.quoteLineItemGroups
    ? flatten(quote.quoteLineItemGroups.map((g) => g.quoteLineItems!))
    : [];
  const subtotal = allLineItems
    .map((li) => li.sellPrice * li.quantity)
    .reduce((x, y) => x + y, 0);
  const tax = allLineItems.map((li) => li.taxAmount).reduce((x, y) => x + y, 0);
  const grandTotal = subtotal + tax;

  return (
    <>
      <Helmet>
        <title>{quote.code}</title>
      </Helmet>

      <ErrorDialog
        isOpen={!!getQuoteDetails.error}
        error={getQuoteDetails.error}
      />

      <ErrorDialog
        isOpen={!!saveError}
        error={saveError}
        onClose={() => setSaveError(undefined)}
      />

      <Dialog
        title="Import SIF"
        style={{ paddingBottom: 0 }}
        isOpen={importSIFForLineItemGroupIndex !== undefined}
        onClose={() => setImportSIFForLineItemGroupIndex(undefined)}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>
            Choose a SIF file from your computer to import it. Note that import
            can take a few minutes for larger SIF files.
          </p>
          <Callout intent={Intent.PRIMARY} icon={null} className="mb-4">
            Please make sure you've chosen the <strong>CAP Studio SIF</strong>{" "}
            format in the CAP Export Center, otherwise we may not be able to
            import it.
          </Callout>
          <Button
            icon="document"
            text="Choose SIF File"
            onClick={() => importSIF(importSIFForLineItemGroupIndex!)}
          />
        </div>
      </Dialog>

      <ExportQuoteToQuickbooksDialog
        isOpen={isExportQuoteOpen}
        quoteId={parseInt(quoteId)}
        onClose={() => setIsExportQuoteOpen(false)}
      />

      <ProjectNavbar
        projectLoading={getQuoteDetails.loading}
        projectName={`P-${quote.quoteVersionGroup.project.id}`}
        projectPrimaryContact={quote.quoteVersionGroup.project.primaryContact}
        projectId={parseInt(projectId)}
        additionalHeaderItems={[
          { text: "Quotes", href: `/projects/${projectId}/quotes` },
          {
            text: quote.code ?? "...",
            href: `/projects/${projectId}/quotes/${quoteId}`,
          },
        ]}
      />

      <QuoteSubnavbar
        title={quote.code ?? ""}
        loading={getQuoteDetails.loading || committingQuoteDraft}
        actions={
          <>
            <Button
              text={
                quote.quickbooksEstimateId
                  ? "Exported to Quickbooks"
                  : "Export to Quickbooks"
              }
              icon={quote.quickbooksEstimateId ? "tick-circle" : "export"}
              intent={quote.quickbooksEstimateId ? "success" : "none"}
              onClick={async () => {
                if (isModified) await saveChanges();
                setIsExportQuoteOpen(true);
              }}
            />

            <Tooltip content="Print or Export Quote" position={Position.BOTTOM}>
              <Button
                icon="print"
                minimal
                onClick={async () => {
                  if (isModified) await saveChanges();
                  window.open(
                    `/projects/${projectId}/quotes/${quoteId}/printable`
                  );
                }}
              />
            </Tooltip>

            <QuoteVisibleColumnMenu
              visibleColumns={visibleColumns}
              onShowColumn={(c) => {
                if (!visibleColumns.includes(c))
                  setVisibleColumns([...visibleColumns, c]);
              }}
              onHideColumn={(c) => {
                if (visibleColumns.includes(c))
                  setVisibleColumns(visibleColumns.filter((x) => x !== c));
              }}
            />
          </>
        }
      />

      {!isEditOverridden && !!quote.salesOrder && (
        <QuoteConvertedWarning
          projectId={parseInt(projectId)}
          salesOrderId={quote.salesOrder.id}
          salesOrderCode={quote.salesOrder.code}
          onOverrideEdit={() => {
            setIsEditOverridden(true);

            DefaultToaster.show({
              message: "This quote can now be edited.",
              icon: "tick-circle",
              intent: "success",
            });
          }}
        />
      )}

      {isModified && (
        <UnsavedChangesWarning
          resourceType={"quote"}
          onSave={saveChanges}
          saving={committingQuoteDraft}
        />
      )}

      <QuickSettings>
        <FormGroup label="Primary Contact">
          <ContactInputGroup
            value={state.quickSettings.primaryContact}
            onChange={(v) =>
              updatePrimaryContactAndApplyDefaults({
                state,
                dispatch,
                primaryContact: v,
                apolloClient,
              })
            }
          />
        </FormGroup>

        <FormGroup label="Billing Address">
          <AddressInputGroup
            value={state.quickSettings.billingAddress}
            onChange={(v) =>
              dispatch({ type: "setBillingAddress", billingAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Ship To Address">
          <AddressInputGroup
            value={state.quickSettings.shipToAddress}
            onChange={(v) =>
              dispatch({ type: "setShipToAddress", shipToAddress: v })
            }
          />
        </FormGroup>

        <FormGroup label="Sales Rep">
          <AccountInputGroup
            value={state.quickSettings.salesRepAccount}
            onChange={(v) =>
              dispatch({ type: "setSalesRepAccount", salesRepAccount: v })
            }
          />
        </FormGroup>

        <FormGroup label="Requested Fulfillment Date">
          <QuickSettingItem>
            <div className="space-x-1">
              <DateInput
                value={state.quickSettings.requestedFulfillmentDate}
                onChange={(v) =>
                  dispatch({
                    type: "setRequestedFulfillmentDate",
                    requestedFulfillmentDate: v,
                  })
                }
                parseDate={(d) => new Date(d)}
                formatDate={formatDate}
              />

              <FufillmentDateNotes
                flagged={state.quickSettings.requestedFulfillmentDateFlagged}
                body={state.quickSettings.requestedFulfillmentDateNotes}
                onSubmit={(status) => {
                  dispatch({
                    type: "setRequestedFulfillmentDateFlagged",
                    requestedFulfillmentDateFlagged: status.flagged,
                  });
                  dispatch({
                    type: "setRequestedFulfillmentDateNotes",
                    requestedFulfillmentDateNotes: status.body,
                  });
                }}
              />
            </div>
          </QuickSettingItem>
        </FormGroup>
      </QuickSettings>

      <PortalContainer>
        <div className="space-y-2">
          <LineItemEditor<QuoteEditorState, QuoteEditorActions>
            visibleDefaultColumns={visibleColumns}
            readOnly={!isEditOverridden && !!quote.salesOrder}
            state={state}
            dispatch={dispatch}
            groupFooterActions={({ lineItemGroupIndex }) => (
              <>
                <Button
                  icon="import"
                  text="Import SIF"
                  loading={isCurrentlyImporting}
                  onClick={async () => {
                    if (isModified) await saveChanges();
                    setImportSIFForLineItemGroupIndex(lineItemGroupIndex);
                  }}
                />
              </>
            )}
            fulfillmentContactId={
              quote.quoteVersionGroup.project.fulfillmentContact?.id ??
              quote.quoteVersionGroup.project.primaryContact?.id
            }
            shipToAddressId={quote.quoteVersionGroup.project.shipToAddress?.id}
          />

          <div className="flex justify-end">
            <div className="space-y-1 w-80 rounded border py-2">
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Subtotal</p>
                <p className="font-bold m-0">{formatPrice(subtotal)}</p>
              </div>
              <div className="flex items-center space-x-6 pr-2">
                <p className="w-40 m-0 text-right">Tax</p>
                <p
                  className={classNames("font-bold m-0 flex-1", {
                    "text-yellow-600":
                      !getQuoteDetails.loading && missingTaxAddress,
                  })}
                >
                  {formatPrice(tax)}
                </p>
                {!getQuoteDetails.loading && missingTaxAddress ? (
                  <Tooltip
                    content="Tax can't be calculated because origin or destination address is missing."
                    position={Position.BOTTOM}
                  >
                    <Button
                      minimal
                      small
                      icon="warning-sign"
                      intent={Intent.WARNING}
                      onClick={() =>
                        history.push(`/projects/${projectId}/settings`)
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip content="Recalculate Tax" position={Position.BOTTOM}>
                    <Button
                      minimal
                      small
                      icon="refresh"
                      onClick={recalculateTax}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="py-1">
                <hr />
              </div>
              <div className="flex items-center space-x-6">
                <p className="w-40 m-0  text-right">Grand Total</p>
                <p className="font-bold m-0">{formatPrice(grandTotal)}</p>
              </div>
            </div>
          </div>
        </div>
      </PortalContainer>
    </>
  );
}
