import {
  Button,
  Checkbox,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  MenuItem,
  NumericInput,
  Popover,
  Position,
  TextArea,
} from "@blueprintjs/core";
import {
  PersonAddressType,
  UpdatePersonAddressPayload,
} from "../../../types/ApiTypes";
import { formatAddress } from "../../../helpers/AddressHelper";
import { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { DefaultToaster } from "../../shared/DefaultToaster/DefaultToaster";

type UpdateAddressParams = {
  payload: UpdatePersonAddressPayload;
};

type UpdateAddressResult = {
  updatePersonAddress: {
    id: number;
  };
};

const UpdateAddress = gql`
  mutation UpdateAddress($payload: UpdatePersonAddressPayloadInput!) {
    updatePersonAddress(payload: $payload) {
      id
    }
  }
`;

type UpdateAddressSiteInformationPayload = {
  addressId: number;
  hasDock?: boolean;
  numberOfInsideStairs?: number;
  numberOfOutsideStairs?: number;
  hasFreightElevator?: boolean;
  hasPassengerElevator?: boolean;
  isCertificateOfInsuranceRequired?: boolean;
};

const UpdateAddressSiteInformation = gql`
  mutation UpdateAddressSiteInformation(
    $payload: UpdateAddressSiteInformationPayloadInput!
  ) {
    updateAddressSiteInformation(payload: $payload) {
      id
    }
  }
`;

export type PersonAddressItemProps = {
  personAddress: {
    id: number;
    type: PersonAddressType;
    personId: number;
    address: {
      id: number;
      street1: string;
      street2: string;
      city: string;
      state: string;
      postalCode: string;
      notes: string;
      addressSiteInformation?: {
        id: number;
        hasDock: boolean;
        numberOfInsideStairs: number;
        numberOfOutsideStairs: number;
        hasFreightElevator: boolean;
        hasPassengerElevator: boolean;
        isCertificateOfInsuranceRequired: boolean;
      };
    };
  };

  onRemove: () => void;
  onChange: (payload: UpdatePersonAddressPayload) => void;
};

export function PersonAddressItem({
  personAddress,
  onChange,
  onRemove,
}: PersonAddressItemProps) {
  const [insideStairs, setInsideStairs] = useState<string>(
    personAddress.address.addressSiteInformation?.numberOfInsideStairs.toString() ??
      "0"
  );
  const [outsideStairs, setOutsideStairs] = useState<string>(
    personAddress.address.addressSiteInformation?.numberOfOutsideStairs.toString() ??
      "0"
  );
  const [hasLoadingDock, setHasLoadingDock] = useState<boolean>(
    personAddress.address.addressSiteInformation?.hasDock ?? false
  );
  const [hasPassengerElevator, setHasPassengerElevator] = useState<boolean>(
    personAddress.address.addressSiteInformation?.hasPassengerElevator ?? false
  );
  const [hasFreightElevator, setHasFreightElevator] = useState<boolean>(
    personAddress.address.addressSiteInformation?.hasFreightElevator ?? false
  );
  const [certificateOfInsuranceRequired, setCertificateOfInsuranceRequired] =
    useState<boolean>(
      personAddress.address.addressSiteInformation
        ?.isCertificateOfInsuranceRequired ?? false
    );
  const [notes, setNotes] = useState<string>(personAddress.address.notes ?? "");

  const [updateAddressSiteInformation] = useMutation<
    { updateAddressSiteMutation: { id: number } },
    { payload: UpdateAddressSiteInformationPayload }
  >(UpdateAddressSiteInformation);

  const [updateAddress] = useMutation<UpdateAddressResult, UpdateAddressParams>(
    UpdateAddress
  );

  async function saveNotes() {
    await updateAddress({
      variables: {
        payload: {
          id: personAddress.id,
          notes,
        },
      },
    });

    DefaultToaster.show({
      message: "Address notes saved!",
      intent: "success",
      icon: "tick-circle",
    });
  }

  return (
    <div className={"border-b divide-y"}>
      <div className="flex p-2">
        <div className="flex-1">
          <address
            className="not-italic"
            dangerouslySetInnerHTML={{
              __html: formatAddress(personAddress.address).replaceAll(
                "\n",
                "<br />"
              ),
            }}
          />
        </div>
        <HTMLSelect
          value={personAddress.type.toString()}
          onChange={(e) =>
            onChange({
              id: personAddress.id,
              type: e.target.value as PersonAddressType,
            })
          }
          className="mr-2"
        >
          <option value="">Choose Type</option>
          <option value="Primary">Primary</option>
          <option value="Billing">Billing</option>
          <option value="Fulfillment">Fulfillment</option>
          <option value="Other">Other</option>
        </HTMLSelect>
        <Popover position={Position.BOTTOM_RIGHT} minimal>
          <Button minimal icon="more" />
          <Menu>
            <MenuItem
              text="Remove from Person"
              icon="minus"
              intent={Intent.DANGER}
              onClick={() => onRemove()}
            />
          </Menu>
        </Popover>
      </div>

      <div className="p-2 bg-gray-50 space-y-2">
        <div className="space-y-1">
          <FormGroup label="Notes" style={{ margin: 0 }}>
            <TextArea
              fill
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormGroup>

          <Button small text="Save Changes" onClick={saveNotes} />
        </div>

        {personAddress.type === PersonAddressType.Fulfillment && (
          <>
            <h4 className="font-medium uppercase">Site Information</h4>
            <div className="flex space-x-2">
              <FormGroup label="# Inside Stairs" className="flex-1">
                <NumericInput
                  value={insideStairs}
                  onValueChange={async (e, v) => {
                    setInsideStairs(v);

                    await updateAddressSiteInformation({
                      variables: {
                        payload: {
                          addressId: personAddress.address.id,
                          numberOfInsideStairs: parseInt(v),
                        },
                      },
                    });
                  }}
                  stepSize={1}
                  minorStepSize={1}
                  majorStepSize={1}
                  min={0}
                  fill
                />
              </FormGroup>
              <FormGroup label="# Outside Stairs" className="flex-1">
                <NumericInput
                  value={outsideStairs}
                  onValueChange={async (e, v) => {
                    setOutsideStairs(v);

                    await updateAddressSiteInformation({
                      variables: {
                        payload: {
                          addressId: personAddress.address.id,
                          numberOfOutsideStairs: parseInt(v),
                        },
                      },
                    });
                  }}
                  stepSize={1}
                  minorStepSize={1}
                  majorStepSize={1}
                  min={0}
                  fill
                />
              </FormGroup>
            </div>
            <Checkbox
              label="Has Loading Dock"
              checked={hasLoadingDock}
              onChange={async (e) => {
                const checked = (e.target as any).checked;
                setHasLoadingDock(checked);

                await updateAddressSiteInformation({
                  variables: {
                    payload: {
                      addressId: personAddress.address.id,
                      hasDock: checked,
                    },
                  },
                });
              }}
            />
            <Checkbox
              label="Has Freight Elevator"
              checked={hasFreightElevator}
              onChange={async (e) => {
                const checked = (e.target as any).checked;
                setHasFreightElevator(checked);

                await updateAddressSiteInformation({
                  variables: {
                    payload: {
                      addressId: personAddress.address.id,
                      hasFreightElevator: checked,
                    },
                  },
                });
              }}
            />
            <Checkbox
              label="Has Passenger Elevator"
              checked={hasPassengerElevator}
              onChange={async (e) => {
                const checked = (e.target as any).checked;
                setHasPassengerElevator(checked);

                await updateAddressSiteInformation({
                  variables: {
                    payload: {
                      addressId: personAddress.address.id,
                      hasPassengerElevator: checked,
                    },
                  },
                });
              }}
            />
            <Checkbox
              label="Certificate of Insurance Required"
              checked={certificateOfInsuranceRequired}
              onChange={async (e) => {
                const checked = (e.target as any).checked;
                setCertificateOfInsuranceRequired(checked);

                await updateAddressSiteInformation({
                  variables: {
                    payload: {
                      addressId: personAddress.address.id,
                      isCertificateOfInsuranceRequired: checked,
                    },
                  },
                });
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
