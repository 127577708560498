import { Contact } from "../types/ApiTypes";
import { gql } from "@apollo/client";

export const FormatContactNameContactFragment = gql`
  fragment FormatContactNameContactFragment on Contact {
    id
    person {
      id
      firstName
      lastName
    }
    company {
      id
      name
    }
  }
`;

export type FormatContactNameContact = {
  id: number;
  person: {
    id: number;
    firstName: string;
    lastName: string;
  };
  company: {
    id: number;
    name: string;
  };
};

export function formatContactName(contact?: FormatContactNameContact) {
  if (!contact) {
    return "N/A";
  }

  if (contact.company && !contact.person) {
    throw new Error(
      "A contact was passed that did not contain an associated person."
    );
  }

  if (contact.person && contact.company) {
    return `${contact.company.name} (${contact.person.firstName} ${contact.person.lastName})`;
  }

  return `${contact.person.firstName} ${contact.person.lastName}`;
}

export function formatContactPersonWithoutCompany(
  contact?: FormatContactNameContact
) {
  if (!contact) {
    return "Unspecified";
  }

  return `${contact.person.firstName} ${contact.person.lastName}`;
}

export function formatContactCompanyName(contact?: FormatContactNameContact) {
  if (!contact) {
    return "Unspecified";
  }

  if (!contact.company) {
    return "Unspecified";
  }

  return `${contact.company.name}`;
}
