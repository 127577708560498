import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormGroup, InputGroup } from "@blueprintjs/core";
import {
  ContactInputGroup,
  ContactInputGroupContact,
} from "../ContactInputGroup";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import {
  CreateQuoteLineItemGroupPayloadInput,
  CreateQuoteLineItemPayloadInput,
} from "../../../types/ApiTypes";

type CommitCreateQuoteDraftParams = {
  quoteName: string;
  createQuoteLineItemGroupPayloads?: CreateQuoteLineItemGroupPayloadInput[];
  createQuoteLineItemPayloads?: CreateQuoteLineItemPayloadInput[];
};

type CommitCreateQuoteDraftResult = {
  commitCreateQuoteDraft: {
    createdProject: {
      id: number;
    };
    createdQuote: {
      id: number;
    };
  };
};

const CommitCreateQuoteDraft = gql`
  mutation CreateQuote(
    $quoteName: String!
    $createQuoteLineItemGroupPayloads: [CreateQuoteLineItemGroupPayloadInput!]
    $createQuoteLineItemPayloads: [CreateQuoteLineItemPayloadInput!]
  ) {
    commitCreateQuoteDraft(
      payload: {
        quoteName: $quoteName
        createQuoteLineItemPayloads: $createQuoteLineItemPayloads
        createQuoteLineItemGroupPayloads: $createQuoteLineItemGroupPayloads
      }
    ) {
      createdProject {
        id
      }
      createdQuote {
        id
      }
    }
  }
`;

type CreateQuoteFormFields = {
  name: string;
};

export function CreateQuote({ onClose }: { onClose: () => void }) {
  const history = useHistory();

  const [commitCreateQuoteDraft] = useMutation<
    CommitCreateQuoteDraftResult,
    CommitCreateQuoteDraftParams
  >(CommitCreateQuoteDraft);

  const formik = useFormik<CreateQuoteFormFields>({
    initialValues: {
      name: "Entire Project",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      const result = await commitCreateQuoteDraft({
        variables: {
          quoteName: values.name,
          createQuoteLineItemPayloads: [
            {
              sortIndex: 0,
              quoteLineItemGroupId: 1,
              listPriceMultiplier: 1,
              grossProfitPercent: 20,
              sellPrice: 0,
              quantity: 1,
              description: "",
            },
          ],
          createQuoteLineItemGroupPayloads: [
            { sortIndex: 0, name: "Other Items", temporaryId: 1 },
          ],
        },
      });

      const projectId = result.data?.commitCreateQuoteDraft.createdProject.id;
      const quoteId = result.data?.commitCreateQuoteDraft.createdQuote.id;

      history.push(`/projects/${projectId}/quotes/${quoteId}`);
      onClose();
    },
  });

  return (
    <form className="space-y-2" onSubmit={formik.handleSubmit}>
      <div className="rounded border bg-white p-2">
        <FormGroup
          label="Quote Name"
          intent={formik.errors.name ? "warning" : "none"}
          helperText={formik.errors.name}
        >
          <InputGroup
            id="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
            intent={formik.errors.name ? "warning" : "none"}
          />
        </FormGroup>
      </div>

      <div className="text-right">
        <Button intent="primary" text="Create Quote" type="submit" />
      </div>
    </form>
  );
}
