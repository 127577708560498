import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router-dom";
import { ProjectNavbar } from "../../../../../components/projects/[projectId]/ProjectNavbar";
import { PortalContainer } from "../../../../../components/shared/PortalContainer/PortalContainer";
import { usePurchaseOrder } from "../../../../../hooks/usePurchaseOrder";
import { PurchaseOrderSubnavbar } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderSubnavbar";
import { Callout, Intent, Spinner } from "@blueprintjs/core";
import { usePurchaseOrderInventoryItems } from "../../../../../hooks/usePurchaseOrderInventoryItems";
import { PurchaseOrderInventoryItemsList } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PurchaseOrderInventoryItemsList";
import { PrintLabelsDrawer } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/PrintLabelsDrawer";
import {
  Bay,
  InventoryItemInspectionStatus,
  ProductConfiguration,
  Project,
  PurchaseOrderInventoryItem,
} from "../../../../../types/ApiTypes";
import { InventoryItemInspectionDialog } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/inventory/InventoryItemInspectionDialog";
import { ManageInventoryItemAttachmentsDialog } from "../../../../../components/projects/[projectId]/purchase-orders/[purchaseOrderId]/inventory/ManageInventoryItemAttachmentsDialog";
import { gql, useQuery } from "@apollo/client";
import {
  GetFullConfigurationCodeFragment,
  GetFullConfigurationDescriptionFragment,
} from "../../../../../helpers/CatalogHelper";

type GetBaysResult = {
  bays: Bay[];
};

const GetBays = gql`
  query GetBays {
    bays {
      id
      name
    }
  }
`;

const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
    }
  }
`;

type GetPurchaseOrderInventoryItemsInput = {
  purchaseOrderId: number;
};

type GetPurchaseOrderInventoryItemsResult = {
  purchaseOrders: {
    id: number;
    inventoryItems: {
      id: number;
      configuration: ProductConfiguration;
      mostRecentInspectionStatus: InventoryItemInspectionStatus;
      inventoryItemAttachments: {
        id: number;
      }[];
      location?: {
        id: number;
        name: string;
      };
      purchaseOrderShipmentLineItem: {
        id: number;
        purchaseOrderLineItem: {
          id: number;
          quantity: number;
          configuration: ProductConfiguration;
          sortIndex: number;
        };
      };
    }[];
  }[];
};

const GetPurchaseOrderInventoryItems = gql`
  ${GetFullConfigurationCodeFragment}
  ${GetFullConfigurationDescriptionFragment}

  query GetPurchaseOrderInventoryItems($purchaseOrderId: Int!) {
    purchaseOrders(where: { id: { eq: $purchaseOrderId } }) {
      id
      inventoryItems {
        id
        configuration {
          id
          ...GetFullConfigurationCodeFragment
          ...GetFullConfigurationDescriptionFragment
        }
        mostRecentInspectionStatus
        inventoryItemAttachments {
          id
        }
        location {
          id
          name
        }
        purchaseOrderShipmentLineItem {
          id
          purchaseOrderLineItem {
            id
            quantity
            configuration {
              id
              ...GetFullConfigurationCodeFragment
              ...GetFullConfigurationDescriptionFragment
            }
            sortIndex
          }
        }
      }
    }
  }
`;

export function ProjectPurchaseOrderInventoryItems() {
  const match = useRouteMatch<{ projectId: string; purchaseOrderId: string }>();
  const projectId = parseInt(match.params.projectId);
  const purchaseOrderId = parseInt(match.params.purchaseOrderId);
  const [inventoryItemToPrintLabelsFor, setInventoryItemToPrintLabelsFor] =
    useState<
      GetPurchaseOrderInventoryItemsResult["purchaseOrders"][0]["inventoryItems"][0]
    >();
  const [inventoryItemToInspect, setInventoryItemToInspect] =
    useState<
      GetPurchaseOrderInventoryItemsResult["purchaseOrders"][0]["inventoryItems"][0]
    >();
  const [
    inventoryItemToManageAttachments,
    setInventoryItemToManageAttachments,
  ] =
    useState<
      GetPurchaseOrderInventoryItemsResult["purchaseOrders"][0]["inventoryItems"][0]
    >();

  const getProjectById = useQuery<
    { projects: Project[] },
    { projectId: number }
  >(GET_PROJECT_BY_ID, {
    variables: {
      projectId,
    },
  });

  const getBays = useQuery<GetBaysResult>(GetBays);

  const { purchaseOrder } = usePurchaseOrder(purchaseOrderId);

  const getPurchaseOrderInventoryItems = useQuery<
    GetPurchaseOrderInventoryItemsResult,
    GetPurchaseOrderInventoryItemsInput
  >(GetPurchaseOrderInventoryItems, {
    variables: { purchaseOrderId },
    fetchPolicy: "cache-and-network",
  });

  const inventoryItems = getPurchaseOrderInventoryItems.loading
    ? []
    : getPurchaseOrderInventoryItems.data!.purchaseOrders[0].inventoryItems;

  const hasInventoryItems =
    !getProjectById.loading &&
    !getBays.loading &&
    !getPurchaseOrderInventoryItems.loading &&
    inventoryItems.length > 0;

  const projectName = getProjectById.loading
    ? `Project #${projectId}`
    : getProjectById.data!.projects[0].name;

  const purchaseOrderName = purchaseOrder
    ? `${purchaseOrder!.code} (${purchaseOrder!.vendor!.name})`
    : "";

  return (
    <>
      <PrintLabelsDrawer
        isOpen={!!inventoryItemToPrintLabelsFor}
        inventoryItemId={inventoryItemToPrintLabelsFor?.id}
        onClose={() => setInventoryItemToPrintLabelsFor(undefined)}
      />

      <InventoryItemInspectionDialog
        projectId={projectId}
        inventoryItem={inventoryItemToInspect}
        isOpen={!!inventoryItemToInspect}
        onSubmit={async () => {
          setInventoryItemToInspect(undefined);
          await getPurchaseOrderInventoryItems.refetch();
        }}
        onClose={() => setInventoryItemToInspect(undefined)}
      />

      <ManageInventoryItemAttachmentsDialog
        isOpen={!!inventoryItemToManageAttachments}
        inventoryItemId={inventoryItemToManageAttachments?.id}
        onClose={() => setInventoryItemToManageAttachments(undefined)}
      />

      <Helmet>
        <title>{`Inventory - ${purchaseOrderName} - ${projectName}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          {
            text: "Purchase Orders",
            href: `/projects/${projectId}/purchase-orders`,
          },
          {
            text: purchaseOrderName,
            href: `/projects/${projectId}/purchase-orders/${purchaseOrderId}`,
          },
        ]}
      />

      <PurchaseOrderSubnavbar title="Purchase Order Inventory" />

      <PortalContainer>
        {getPurchaseOrderInventoryItems.loading && <Spinner />}

        {!hasInventoryItems && !getPurchaseOrderInventoryItems.loading && (
          <div className="p-2">
            <Callout
              icon="tick-circle"
              intent={Intent.WARNING}
              title="Awaiting shipments"
            >
              Nothing in this purchase order has arrived yet. Please check the
              shipments page to see what is currently on its way.
            </Callout>
          </div>
        )}

        {!getPurchaseOrderInventoryItems.loading && hasInventoryItems && (
          <PurchaseOrderInventoryItemsList
            inventoryItems={inventoryItems}
            refreshInventoryItems={async () =>
              getPurchaseOrderInventoryItems.refetch()
            }
            bays={getBays.data!.bays}
            onPrintLabels={(i) => setInventoryItemToPrintLabelsFor(i)}
            onInspect={(i) => setInventoryItemToInspect(i)}
            onManageAttachments={(i) => setInventoryItemToManageAttachments(i)}
          />
        )}
      </PortalContainer>
    </>
  );
}
