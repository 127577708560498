import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProjectNavbar } from "../../../../components/projects/[projectId]/ProjectNavbar";
import React, { useCallback, useEffect, useState } from "react";
import {
  Address,
  Contact,
  UpdateProjectPayloadInput,
} from "../../../../types/ApiTypes";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, FormGroup, Intent } from "@blueprintjs/core";
import { AddressInputGroup } from "../../../../components/shared/AddressInputGroup/AddressInputGroup";
import { DefaultToaster } from "../../../../components/shared/DefaultToaster/DefaultToaster";

const UpdateProject = gql`
  mutation UpdateProject($input: UpdateProjectPayloadInput) {
    updateProject(payload: $input) {
      id
    }
  }
`;

const GetProjectById = gql`
  query GetProjectById($projectId: Int!) {
    projects(where: { id: { eq: $projectId } }) {
      id
      name
      primaryContact {
        id
        person {
          id
          firstName
          lastName
        }
        company {
          id
          name
        }
      }
      shipToAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
      billingAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
      taxOriginAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
      taxDestinationAddress {
        id
        street1
        street2
        city
        state
        postalCode
      }
    }
  }
`;

type GetProjectByIdResult = {
  projects: {
    id: number;
    name: string;
    primaryContact: Contact;
    shipToAddress: Address;
    billingAddress: Address;
    taxOriginAddress: Address;
    taxDestinationAddress: Address;
  }[];
};

export type ProjectSettingsRouteParams = {
  projectId: string;
};

export function ProjectSettings() {
  const params = useParams<ProjectSettingsRouteParams>();
  const projectId = parseInt(params.projectId);

  const [shipToAddress, setShipToAddress] = useState<Address | undefined>();
  const [billingAddress, setBillingAddress] = useState<Address | undefined>();
  const [taxOriginAddress, setTaxOriginAddress] = useState<
    Address | undefined
  >();
  const [taxDestinationAddress, setTaxDestinationAddress] = useState<
    Address | undefined
  >();

  const getProjectById = useQuery<GetProjectByIdResult, { projectId: number }>(
    GetProjectById,
    {
      variables: {
        projectId,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [updateProject] = useMutation<
    { updateProject: { id: string } },
    { input: UpdateProjectPayloadInput }
  >(UpdateProject, {
    refetchQueries: ["GetProjectById"],
  });

  useEffect(() => {
    if (getProjectById.loading) return;
    setShipToAddress(getProjectById.data?.projects[0].shipToAddress);
    setBillingAddress(getProjectById.data?.projects[0].billingAddress);
    setTaxOriginAddress(getProjectById.data?.projects[0].taxOriginAddress);
    setTaxDestinationAddress(
      getProjectById.data?.projects[0].taxDestinationAddress
    );
  }, [getProjectById.loading, getProjectById.data]);

  const saveChanges = useCallback(async () => {
    await updateProject({
      variables: {
        input: {
          projectId,
          shipToAddressId: shipToAddress?.id ?? -1,
          billingAddressId: billingAddress?.id ?? -1,
          taxOriginAddressId: taxOriginAddress?.id ?? -1,
          taxDestinationAddressId: taxDestinationAddress?.id ?? -1,
        },
      },
    });

    DefaultToaster.show({
      message: "Project updated!",
      intent: Intent.SUCCESS,
      icon: "tick-circle",
    });
  }, [
    projectId,
    updateProject,
    shipToAddress,
    billingAddress,
    taxOriginAddress,
    taxDestinationAddress,
  ]);

  return (
    <>
      <Helmet>
        <title>{`Settings - Project #${projectId}`}</title>
      </Helmet>

      <ProjectNavbar
        projectLoading={getProjectById.loading}
        projectName={getProjectById.data?.projects[0].name ?? ""}
        projectPrimaryContact={getProjectById.data?.projects[0].primaryContact}
        projectId={projectId}
        additionalHeaderItems={[
          { text: "Settings", href: `/projects/${projectId}/settings` },
        ]}
      />

      <div className="bg-gray-100 h-full">
        <div className="p-10 overflow-y-auto">
          <div className="md:w-1/2">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Addresses
                  </h3>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <FormGroup label="Ship to Address">
                      <AddressInputGroup
                        value={shipToAddress}
                        onChange={setShipToAddress}
                      />
                    </FormGroup>

                    <FormGroup label="Billing Address">
                      <AddressInputGroup
                        value={billingAddress}
                        onChange={setBillingAddress}
                      />
                    </FormGroup>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <Button
                      intent={Intent.PRIMARY}
                      large
                      text="Save"
                      type="submit"
                      onClick={saveChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-10 overflow-y-auto">
          <div className="md:w-1/2">
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Tax
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    This information will be used to calculate sales tax for
                    quotes and sales orders within the project.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <FormGroup label="Origin Address">
                      <AddressInputGroup
                        value={taxOriginAddress}
                        onChange={setTaxOriginAddress}
                      />
                    </FormGroup>

                    <FormGroup label="Destination Address">
                      <AddressInputGroup
                        value={taxDestinationAddress}
                        onChange={setTaxDestinationAddress}
                      />
                    </FormGroup>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <Button
                      intent={Intent.PRIMARY}
                      large
                      text="Save"
                      onClick={saveChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
