import { PropsWithChildren } from "react";
import styles from "./QuickSettings.module.scss";
import classNames from "classnames";

type QuickSettingsProps = PropsWithChildren<{ className?: string }>;

export function QuickSettings({ children, className }: QuickSettingsProps) {
  return (
    <div
      className={classNames(
        "m-2 flex space-x-2",
        styles.quickSettings,
        className
      )}
    >
      {children}
    </div>
  );
}
