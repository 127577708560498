import { gql } from "@apollo/client";
import {
  CommitCreateQuoteDraftPayloadInput,
  CreateCompanyPayloadInput,
} from "../../../types/ApiTypes";

export type CreateCompanyParams = {
  payload: CreateCompanyPayloadInput;
};

export type CreateCompanyResult = {
  createCompany: {
    id: number;
  };
};

export const CreateCompany = gql`
  mutation CreateCompany($payload: CreateCompanyPayloadInput!) {
    createCompany(payload: $payload) {
      id
    }
  }
`;

export type CreateQuoteParams = {
  payload: CommitCreateQuoteDraftPayloadInput;
};

export type CreateQuoteResult = {
  commitCreateQuoteDraft: {
    createdProject: { id: number };
    createdQuote: { id: number };
  };
};

export const CreateQuote = gql`
  mutation CreateQuote($payload: CommitCreateQuoteDraftPayloadInput!) {
    commitCreateQuoteDraft(payload: $payload) {
      createdProject {
        id
      }
      createdQuote {
        id
      }
    }
  }
`;
