import {
  Button,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Popover,
  Position,
} from "@blueprintjs/core";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./PortalNavbar.module.scss";

export function UserMenuItem() {
  const { user, logout } = useAuth0();

  return (
    <div className={styles.userMenuItem}>
      <Popover minimal position={Position.BOTTOM_RIGHT}>
        <Button minimal>
          <img src={user?.picture} alt={user?.name} />
        </Button>
        <Menu>
          <li className="bp3-menu-header">
            <h6 className="bp3-heading">{user?.email}</h6>
          </li>
          <MenuDivider />
          <MenuItem icon="log-out" text="Logout" onClick={() => logout()} />
        </Menu>
      </Popover>
    </div>
  );
}
