import { Drawer, DrawerProps } from "@blueprintjs/core";
import { AddFulfillmentLineItems } from "./AddFulfillmentLineItems";

type AddFullfillmentLineItemDrawerProps = DrawerProps & {
  fulfillmentId: number;
  projectId: number;
  onAdd: (lineItems: { [lineItemId: number]: number }) => void;
};

export function AddFulfillmentLineItemsDrawer({
  fulfillmentId,
  projectId,
  onAdd,
  ...drawerProps
}: AddFullfillmentLineItemDrawerProps) {
  return (
    <Drawer {...drawerProps} position="bottom" size="100%">
      <AddFulfillmentLineItems
        fulfillmentId={fulfillmentId}
        projectId={projectId}
        onAdd={onAdd}
        onClose={() => drawerProps.onClose!(null!)}
      />
    </Drawer>
  );
}
